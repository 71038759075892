import React, { useState } from "react";
import { useMutation, useQuery, useQueryClient } from "@tanstack/react-query";
import {
  exportTasks,
  importTasks,
  getTaskTemplates,
  saveTaskTemplate,
} from "@/queries/tasks";

export const TaskActions = ({ onClose }) => {
  const queryClient = useQueryClient();
  const [templateName, setTemplateName] = useState("");
  const [importFile, setImportFile] = useState(null);

  const { data: templates } = useQuery(["taskTemplates"], getTaskTemplates);

  const exportMutation = useMutation({
    mutationFn: exportTasks,
    onSuccess: (data) => {
      // Create and download export file
      const blob = new Blob([JSON.stringify(data, null, 2)], {
        type: "application/json",
      });
      const url = window.URL.createObjectURL(blob);
      const a = document.createElement("a");
      a.href = url;
      a.download = `tasks-export-${
        new Date().toISOString().split("T")[0]
      }.json`;
      a.click();
      window.URL.revokeObjectURL(url);
    },
  });

  const importMutation = useMutation({
    mutationFn: importTasks,
    onSuccess: () => {
      queryClient.invalidateQueries(["tasks"]);
      onClose();
    },
  });

  const saveTemplateMutation = useMutation({
    mutationFn: saveTaskTemplate,
    onSuccess: () => {
      queryClient.invalidateQueries(["taskTemplates"]);
      setTemplateName("");
    },
  });

  const handleImportFile = (e) => {
    const file = e.target.files[0];
    if (file) {
      const reader = new FileReader();
      reader.onload = (e) => {
        try {
          const data = JSON.parse(e.target.result);
          importMutation.mutate(data);
        } catch (error) {
          alert("Invalid JSON file");
        }
      };
      reader.readAsText(file);
    }
  };

  return (
    <div className="bg-purple-900 p-6 rounded-lg">
      <div className="flex justify-between items-center mb-6">
        <h2 className="text-white text-xl font-bold">Task Actions</h2>
        <button onClick={onClose} className="text-purple-200 hover:text-white">
          ×
        </button>
      </div>

      <div className="grid grid-cols-1 md:grid-cols-2 gap-6">
        <div className="bg-purple-800 p-4 rounded-lg">
          <h3 className="text-purple-200 font-medium mb-4">Export/Import</h3>

          <button
            onClick={() => exportMutation.mutate()}
            className="w-full mb-4 px-4 py-2 bg-purple-700 text-white rounded hover:bg-purple-600"
          >
            Export Tasks
          </button>

          <div className="border-t border-purple-700 pt-4">
            <label className="block text-purple-200 mb-2">Import Tasks</label>
            <input
              type="file"
              accept=".json"
              onChange={handleImportFile}
              className="w-full text-purple-200"
            />
          </div>
        </div>

        <div className="bg-purple-800 p-4 rounded-lg">
          <h3 className="text-purple-200 font-medium mb-4">Task Templates</h3>

          <div className="mb-4">
            <input
              type="text"
              placeholder="Template Name"
              value={templateName}
              onChange={(e) => setTemplateName(e.target.value)}
              className="w-full p-2 rounded bg-purple-700 text-white placeholder-purple-300"
            />
            <button
              onClick={() =>
                saveTemplateMutation.mutate({ name: templateName })
              }
              className="mt-2 w-full px-4 py-2 bg-purple-700 text-white rounded hover:bg-purple-600"
              disabled={!templateName.trim()}
            >
              Save Current View as Template
            </button>
          </div>

          <div className="border-t border-purple-700 pt-4">
            <h4 className="text-purple-200 mb-2">Saved Templates</h4>
            <div className="space-y-2">
              {templates?.map((template) => (
                <div
                  key={template.id}
                  className="flex items-center justify-between bg-purple-700 p-2 rounded"
                >
                  <span className="text-white">{template.name}</span>
                  <button
                    onClick={() => {
                      /* Apply template logic */
                    }}
                    className="text-purple-200 hover:text-white"
                  >
                    Apply
                  </button>
                </div>
              ))}
            </div>
          </div>
        </div>
      </div>
    </div>
  );
};

export default TaskActions;
