import { toast } from "react-toastify";

// Add time field specific error handlers
export const validateTimeField = (value, field) => {
  if (!value) return null;

  try {
    const parsed = parseTimeValue(value);
    if (!parsed) {
      return `Invalid time format for ${field}`;
    }
    return null;
  } catch (error) {
    return error.message;
  }
};

export const validateTimeSequence = (times, fields) => {
  const errors = {};

  for (let i = 0; i < fields.length - 1; i++) {
    const current = parseTimeValue(times[fields[i]]);
    const next = parseTimeValue(times[fields[i + 1]]);

    if (current && next && compareTimeValues(current, next) > 0) {
      errors[fields[i + 1]] = `Must be after ${fields[i]}`;
    }
  }

  return errors;
};

export const handleTimeValidationError = (
  error,
  field,
  setValidationErrors
) => {
  setValidationErrors((prev) => ({
    ...prev,
    [field]: error.message || "Invalid time format",
  }));
};

export const clearTimeFieldError = (field, setValidationErrors) => {
  setValidationErrors((prev) => {
    const newErrors = { ...prev };
    delete newErrors[field];
    return newErrors;
  });
};

export const handleValidationError = (
  error,
  setValidationErrors,
  fieldPrefix = ""
) => {
  if (error.inner) {
    const errors = {};
    error.inner.forEach((err) => {
      // Special handling for time fields
      if (err.path.endsWith("_time") || err.path.endsWith("_time_value")) {
        errors[err.path] = "Please enter a valid time (e.g. 2:30 PM or 14:30)";
      } else {
        errors[err.path] = err.message;
      }
    });
    setValidationErrors(errors);

    // Show toast for first error
    if (error.inner[0]) {
      toast.error(error.inner[0].message);
    }
  } else {
    setValidationErrors({ submit: error.message || "Validation failed" });
    toast.error(error.message || "Validation failed");
  }
};

export const handleSubmissionError = (error, setValidationErrors) => {
  console.error("Submission error:", error);
  const errorMessage = error.message || "Failed to submit advance";

  // Check if error is related to time fields
  if (error.message?.toLowerCase().includes("time")) {
    setValidationErrors((prev) => ({
      ...prev,
      timeError: errorMessage,
    }));
  } else {
    setValidationErrors((prev) => ({
      ...prev,
      submit: errorMessage,
    }));
  }

  toast.error(errorMessage);
};

export const clearValidationErrors = (setValidationErrors) => {
  setValidationErrors({});
};
