import React, { forwardRef } from "react";
import PropTypes from "prop-types";
import classNames from "classnames";
import Ripple from "../Common/Ripple";

// Button variants
const variantStyles = {
  primary: "bg-blue-400 text-cave-white hover:bg-blue-500 focus-visible:outline-blue-500",
  secondary: "bg-cave-white border border-gray-500 hover:bg-gray-100 text-cave-black focus-visible:outline-gray-500",
  danger: "bg-cave-red text-white hover:bg-cave-red/80 dark:bg-cave-red dark:hover:bg-cave-red/80 focus-visible:outline-cave-red",
  success: "bg-cave-green text-white hover:bg-cave-green/80 dark:bg-cave-green dark:hover:bg-cave-green/80 focus-visible:outline-cave-green",
  warning: "bg-yellow-500 text-white hover:bg-yellow-600 dark:bg-yellow-600 dark:hover:bg-yellow-700 focus-visible:outline-yellow-500",
  info: "bg-indigo-500 text-white hover:bg-indigo-600 dark:bg-indigo-600 dark:hover:bg-indigo-700 focus-visible:outline-indigo-500",
};

// Button sizes
const sizeStyles = {
  icon: "h-10 w-10",
  sm: "text-xs px-4 py-2 gap-1",
  md: "text-sm px-7 py-2 gap-2",
  lg: "text-base px-8 py-4 gap-3",
};

export const Button = forwardRef(
  (
    {
      variant = "primary",
      size = "md",
      isLoading = false,
      disabled = false,
      startIcon,
      endIcon,
      fullWidth = false,
      withRipple = true,
      className,
      children,
      onClick,
      type = "button",
      rounded = false,
      ...rest
    },
    ref
  ) => {
    const variantClass = variantStyles[variant] || variantStyles.primary;

    const sizeClass = sizeStyles[size] || sizeStyles.md;

    const buttonClasses = classNames(
      "relative inline-flex items-center justify-center font-semibold shadow-sm transition-all duration-200 ease-in-out",
      "focus-visible:outline focus-visible:outline-2 focus-visible:outline-offset-2 uppercase",
      sizeClass,
      variantClass,
      rounded ? "rounded-full" : "rounded-md",
      fullWidth ? "w-full" : "",
      disabled || isLoading ? "opacity-60 cursor-not-allowed" : "opacity-100",
      withRipple && "overflow-hidden",
      className
    );

    return (
      <button
        ref={ref}
        type={type}
        disabled={disabled || isLoading}
        className={buttonClasses}
        onClick={onClick}
        {...rest}
      >
        {isLoading && (
          <span className="absolute inset-0 flex items-center justify-center">
            <svg
              className="animate-spin h-5 w-5 text-current"
              xmlns="http://www.w3.org/2000/svg"
              fill="none"
              viewBox="0 0 24 24"
            >
              <circle
                className="opacity-25"
                cx="12"
                cy="12"
                r="10"
                stroke="currentColor"
                strokeWidth="4"
              />
              <path
                className="opacity-75"
                fill="currentColor"
                d="M4 12a8 8 0 018-8V0C5.373 0 0 5.373 0 12h4zm2 5.291A7.962 7.962 0 014 12H0c0 3.042 1.135 5.824 3 7.938l3-2.647z"
              />
            </svg>
          </span>
        )}

        <span className={isLoading ? "invisible" : "flex items-center"}>
          {startIcon && <span className="inline-flex mr-1">{startIcon}</span>}
          {children}
          {endIcon && <span className="inline-flex ml-1">{endIcon}</span>}
        </span>

        {withRipple && <Ripple color="#fff" duration={850} />}
      </button>
    );
  }
);

Button.displayName = "Button";

Button.propTypes = {
  variant: PropTypes.oneOf(["primary", "secondary", "danger", "success", "warning", "info"]),
  size: PropTypes.oneOf(["sm", "md", "lg"]),
  isLoading: PropTypes.bool,
  disabled: PropTypes.bool,
  startIcon: PropTypes.node,
  endIcon: PropTypes.node,
  fullWidth: PropTypes.bool,
  withRipple: PropTypes.bool,
  className: PropTypes.string,
  children: PropTypes.node,
  onClick: PropTypes.func,
  type: PropTypes.string,
};

export default Button;
