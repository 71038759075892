import React, { useEffect, useState } from "react";
import { Header } from "@/components/Header";
import { BreadCrumbs } from "@/components/BreadCrumbs";
import { useNavigate } from "react-router-dom";
import { getReqs, getReqsSettings } from "@/queries/reqs";
import { useQuery } from "@tanstack/react-query";
import Button from "@/components/Button";
import ReqAdd from "./ReqAdd.jsx";
import { usePageTitle } from "@/utils/pagetitle";
import LoadingPulse from "@/components/shared/LoadingPulse";

export const ReqHome = () => {
  const navigate = useNavigate();
  const {
    data: reqs,
    isLoading,
    error,
  } = useQuery(["reqs"], getReqs, getReqsSettings);
  usePageTitle(`/c${String.fromCharCode(92)}reqs`);

  if (isLoading) {
    return <LoadingPulse />;
  }
  if (error) return <div>Error: {error.message}</div>;

  return (
    <>
      <Header />
      <div className="flex flex-col px-6 py-8 mx-auto min-h-screen lg:py-0">
        <BreadCrumbs links={[{ text: "IT Requests", url: "/reqs" }]} />

        <div className="flex-grow flex flex-col items-center justify-center px-6">
          <Button
            className="bg-cave-white text-black shadow-md rounded-lg p-4 mb-8 hover:bg-gray-100 cursor-pointer font-medium"
            onClick={() => navigate("/reqs/add")}
          >
            <span className="text-black font-medium">Create IT Request</span>
          </Button>

          <div className="w-full max-w-4xl">
            {isLoading ? (
              <LoadingPulse />
            ) : error ? (
              <p className="text-red-500 text-center">
                Error getting requests: {error.message}
              </p>
            ) : (
              <ul className="grid grid-cols-1 md:grid-cols-2 lg:grid-cols-3 gap-4">
                {reqs?.length > 0 ? (
                  reqs.map((req) => (
                    <li
                      key={req.id}
                      className="bg-cave-white text-black shadow-md rounded-lg p-4 hover:bg-gray-100 cursor-pointer"
                      onClick={() => navigate(`/reqs/${req.id}`)}
                    >
                      <div className="text-lg font-semibold">{req.title}</div>
                      <div className="text-black mt-2">{req.description}</div>
                      <div className="text-sm font-medium mt-3 italic">
                        Status: {req.status}
                      </div>
                    </li>
                  ))
                ) : (
                  <p className="dark:text-cave-white text-center col-span-full">
                    No requests found.
                  </p>
                )}
              </ul>
            )}
          </div>
        </div>
      </div>
    </>
  );
};
