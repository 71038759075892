import { toast } from "react-toastify";
import { create } from "zustand";

import { patchEvent, patchOffer, patchEventAccounting } from "@/queries/events";

export const FIELD_UPDATE_ANIMATION_TIME = 2500;

export interface EventOfferForm {
  name: string;
  date: string;
  artists: number[];
  state: string;
  age_requirement: string;
  abbr: string;
  description: string;
  door_time: string;
  opener_time: string;
  support_time: string;
  headliner_time: string;
  curfew_time: string;
  company: string;
  venue_id: number | null;
  has_insurance: boolean;
  has_credit_card_fees: boolean;
  has_ticket_commission: boolean;
  has_ascap: boolean;
  has_bmi: boolean;
  has_sesac: boolean;
  offer_type: string;
  offer_agent: string;
  offer_headliner: string;
  offer_support_1: string;
  offer_support_1_label: string;
  offer_support_2: string;
  offer_support_2_label: string;
  offer_opener: string;
  offer_billing: string;
  offer_stipulations: string;
  offer_avails_1: string;
  offer_avails_2: string;
  offer_has_rent: boolean;
  offer_has_comp_tax: boolean;
  offer_has_gmr: boolean;
  offer_gmr: number | null;
  offer_minimum_rent: number | null;
  offer_rent_per_paid: number | null;
  offer_percentage: number;
  facility_fee: number | null;
  offer_notes: string;
  deal_notes: string;
  confirmation_notes: string;
  production_adv: string;
  local_marketing: string;
  ticketing: string;
  tour_admat: string;
  tour_marketing: string;
  contracts: string;
  offer_onsale_info: string;
  offer_presale_info: string;
  onsale_info: string;
  presale_info: string;
  concessions: number | null;
  merch: number | null;
  rebate: number | null;
  charity: string;
  drop_count: number | null;
  platinum_lift: number | null;
  vip_lift: number | null;
  credit_card_fees: number | null;
  ticket_commission: number | null;
  insurance_amount: number | null;
  offer_comp_tax: number | null;
  hold_tm: number | null;
  hold_etix: number | null;
  hold_venue: number | null;
  hold_fan_club: number | null;
  hold_outlets: number | null;
  hold_vip: number | null;
  mammoth_percentage: number | null;
  building_percentage: number | null;
  who_sells_merch: string;
  artist_soft_merch_percentage: number | null;
  artist_hard_merch_percentage: number | null;
  buyers: number[];

  venue: Record<string, any>;
}

interface EventOfferStore {
  eventOfferForm: EventOfferForm;
  isFormDirty: boolean;
  modifiedFields: Set<keyof EventOfferForm>;
  isSaving: boolean;
  saveErrors: Record<string, string>;
  pendingFields: Set<keyof EventOfferForm>;
  savedFields: Set<keyof EventOfferForm>;

  initializeEventOfferForm: (data: Partial<EventOfferForm>) => void;
  resetDirtyState: () => void;
  clearEventOfferForm: () => void;
  setLocalField: <K extends keyof EventOfferForm>(
    field: K,
    value: EventOfferForm[K]
  ) => void;
  setMultipleFields: (fields: Partial<EventOfferForm>) => void;

  patchEventField: <K extends keyof EventOfferForm>(
    eventId: number,
    field: K,
    value: EventOfferForm[K]
  ) => Promise<any>;

  patchOfferField: <K extends keyof EventOfferForm>(
    offerId: number,
    field: K,
    value: EventOfferForm[K]
  ) => Promise<any>;

  patchAccountingField: <K extends keyof EventOfferForm>(
    eventId: number,
    field: K,
    value: EventOfferForm[K]
  ) => Promise<any>;

  saveEventChanges: (eventId: number) => Promise<any>;
  saveOfferChanges: (offerId: number) => Promise<any>;
  saveAccountingChanges: (eventId: number) => Promise<any>;

  isFieldPending: (field: keyof EventOfferForm) => boolean;
  isFieldSaved: (field: keyof EventOfferForm) => boolean;
  isFieldModified: (field: keyof EventOfferForm) => boolean;
}

const EVENT_FIELDS: Set<keyof EventOfferForm> = new Set([
  "name",
  "date",
  "artists",
  "state",
  "age_requirement",
  "abbr",
  "description",
  "door_time",
  "opener_time",
  "support_time",
  "headliner_time",
  "curfew_time",
  "company",
  "venue_id",
  "offer_notes",
  "deal_notes",
  "confirmation_notes",
  "buyers",
  "charity",
]);

const OFFER_FIELDS: Set<keyof EventOfferForm> = new Set([
  "offer_type",
  "offer_agent",
  "offer_headliner",
  "offer_support_1",
  "offer_support_2",
  "offer_support_1_label",
  "offer_support_2_label",
  "offer_opener",
  "offer_billing",
  "offer_stipulations",
  "offer_avails_1",
  "offer_avails_2",
  "offer_percentage",
  "production_adv",
  "local_marketing",
  "ticketing",
  "tour_admat",
  "tour_marketing",
  "contracts",
  "offer_onsale_info",
  "offer_presale_info",
  "onsale_info",
  "presale_info",
  "offer_minimum_rent",
  "offer_rent_per_paid",
  "offer_has_gmr",
  "offer_gmr",
]);

const ACCOUNTING_FIELDS: Set<keyof EventOfferForm> = new Set([
  "concessions",
  "merch",
  "rebate",
  "drop_count",
  "platinum_lift",
  "vip_lift",
  "has_ascap",
  "has_bmi",
  "has_sesac",
  "has_insurance",
  "has_credit_card_fees",
  "credit_card_fees",
  "has_ticket_commission",
  "ticket_commission",
  "insurance_amount",
  "offer_comp_tax",
  "offer_has_comp_tax",
  "offer_has_rent",
  "facility_fee",
  "hold_tm",
  "hold_etix",
  "hold_venue",
  "hold_fan_club",
  "hold_outlets",
  "hold_vip",
  "mammoth_percentage",
  "building_percentage",
  "who_sells_merch",
  "artist_soft_merch_percentage",
  "artist_hard_merch_percentage",
]);

export const useEventOfferStore = create<EventOfferStore>((set, get) => ({
  eventOfferForm: {
    name: "",
    date: "",
    artists: [],
    state: "",
    age_requirement: "",
    abbr: "",
    description: "",
    door_time: "",
    opener_time: "",
    support_time: "",
    headliner_time: "",
    curfew_time: "",
    company: "mammoth",
    venue_id: null,
    has_insurance: false,
    has_credit_card_fees: false,
    has_ticket_commission: false,
    has_ascap: false,
    has_bmi: false,
    has_sesac: false,
    offer_type: "",
    offer_agent: "",
    offer_headliner: "",
    offer_support_1: "",
    offer_support_1_label: "",
    offer_support_2: "",
    offer_support_2_label: "",
    offer_opener: "",
    offer_billing: "",
    offer_stipulations: "",
    offer_avails_1: "",
    offer_avails_2: "",
    offer_has_rent: false,
    offer_has_comp_tax: false,
    offer_has_gmr: false,
    offer_gmr: null,
    offer_minimum_rent: null,
    offer_rent_per_paid: null,
    offer_percentage: 0,
    facility_fee: null,
    offer_notes: "",
    deal_notes: "",
    confirmation_notes: "",
    production_adv: "production@mammothlive.com",
    local_marketing: "",
    ticketing: "ticketing@mammothlive.com",
    tour_admat: "",
    tour_marketing: "",
    contracts: "contracts@mammothlive.com",
    offer_onsale_info: "",
    offer_presale_info: "",
    onsale_info: "",
    presale_info: "",
    concessions: null,
    merch: null,
    rebate: null,
    charity: "",
    drop_count: null,
    platinum_lift: null,
    vip_lift: null,
    credit_card_fees: null,
    ticket_commission: null,
    insurance_amount: null,
    offer_comp_tax: null,
    hold_tm: null,
    hold_etix: null,
    hold_venue: null,
    hold_fan_club: null,
    hold_outlets: null,
    hold_vip: null,
    mammoth_percentage: null,
    building_percentage: null,
    who_sells_merch: "",
    artist_soft_merch_percentage: null,
    artist_hard_merch_percentage: null,
    buyers: [],
    venue: {},
  },
  isFormDirty: false,
  modifiedFields: new Set(),
  isSaving: false,
  saveErrors: {},
  pendingFields: new Set(),
  savedFields: new Set(),

  initializeEventOfferForm: (data) => {
    set((state) => ({
      eventOfferForm: {
        ...state.eventOfferForm,
        ...data,
      },
      modifiedFields: new Set(),
      isFormDirty: false,
      saveErrors: {},
    }));
  },
  clearEventOfferForm: () => {
    set({
      eventOfferForm: {
        name: "",
        date: "",
        artists: [],
        state: "",
        age_requirement: "",
        abbr: "",
        description: "",
        door_time: "",
        opener_time: "",
        support_time: "",
        headliner_time: "",
        curfew_time: "",
        company: "mammoth",
        venue_id: null,
        has_insurance: false,
        has_credit_card_fees: false,
        has_ticket_commission: false,
        has_ascap: false,
        has_bmi: false,
        has_sesac: false,
        offer_type: "",
        offer_agent: "",
        offer_headliner: "",
        offer_support_1: "",
        offer_support_1_label: "",
        offer_support_2: "",
        offer_support_2_label: "",
        offer_opener: "",
        offer_billing: "",
        offer_stipulations: "",
        offer_avails_1: "",
        offer_avails_2: "",
        offer_has_rent: false,
        offer_has_comp_tax: false,
        offer_has_gmr: false,
        offer_gmr: null,
        offer_minimum_rent: null,
        offer_rent_per_paid: null,
        offer_percentage: 0,
        facility_fee: null,
        offer_notes: "",
        deal_notes: "",
        confirmation_notes: "",
        production_adv: "",
        local_marketing: "",
        ticketing: "",
        tour_admat: "",
        tour_marketing: "",
        contracts: "",
        offer_onsale_info: "",
        offer_presale_info: "",
        onsale_info: "",
        presale_info: "",
        concessions: null,
        merch: null,
        rebate: null,
        charity: "",
        drop_count: null,
        platinum_lift: null,
        vip_lift: null,
        credit_card_fees: null,
        ticket_commission: null,
        insurance_amount: null,
        offer_comp_tax: null,
        hold_tm: null,
        hold_etix: null,
        hold_venue: null,
        hold_fan_club: null,
        hold_outlets: null,
        hold_vip: null,
        mammoth_percentage: null,
        building_percentage: null,
        who_sells_merch: "",
        artist_soft_merch_percentage: null,
        artist_hard_merch_percentage: null,
        buyers: [],
        venue: {},
      },
      modifiedFields: new Set(),
      isFormDirty: false,
      isSaving: false,
      saveErrors: {},
      pendingFields: new Set(),
      savedFields: new Set(),
    });
  },
  resetDirtyState: () => {
    set({
      isFormDirty: false,
      modifiedFields: new Set(),
      saveErrors: {},
    });
  },

  setLocalField: (field, value) => {
    set((state) => {
      const modifiedFields = new Set(state.modifiedFields);
      modifiedFields.add(field);

      return {
        eventOfferForm: {
          ...state.eventOfferForm,
          [field]: value,
        },
        modifiedFields,
        isFormDirty: true,
      };
    });
  },

  setMultipleFields: (fields) => {
    set((state) => {
      const modifiedFields = new Set(state.modifiedFields);
      Object.keys(fields).forEach((key) => {
        modifiedFields.add(key as keyof EventOfferForm);
      });

      return {
        eventOfferForm: {
          ...state.eventOfferForm,
          ...fields,
        },
        modifiedFields,
        isFormDirty: true,
      };
    });
  },

  patchEventField: async (eventId, field, value) => {
    const previousValue = get().eventOfferForm[field];

    // Set modified and pending flags
    set((state) => {
      const pendingFields = new Set(state.pendingFields);
      const modifiedFields = new Set(state.modifiedFields);
      pendingFields.add(field);
      modifiedFields.add(field);

      return {
        eventOfferForm: {
          ...state.eventOfferForm,
          [field]: value,
        },
        pendingFields,
        modifiedFields,
        isSaving: true,
      };
    });

    // Set timeout to clear modifiedFields after animation time
    setTimeout(() => {
      set((state) => {
        const modifiedFields = new Set(state.modifiedFields);
        modifiedFields.delete(field);
        return { modifiedFields };
      });
    }, FIELD_UPDATE_ANIMATION_TIME);

    try {
      const result = await patchEvent(eventId, { [field]: value });

      // Update state - clear pending and set saved
      set((state) => {
        const pendingFields = new Set(state.pendingFields);
        pendingFields.delete(field);

        const savedFields = new Set(state.savedFields);
        savedFields.add(field);

        return {
          pendingFields,
          savedFields,
          isSaving: pendingFields.size > 0,
        };
      });

      // Clear saved flag after animation time
      setTimeout(() => {
        set((state) => {
          const savedFields = new Set(state.savedFields);
          savedFields.delete(field);
          return { savedFields };
        });
      }, FIELD_UPDATE_ANIMATION_TIME);

      return result;
    } catch (error) {
      // Error handling - revert changes
      set((state) => {
        const pendingFields = new Set(state.pendingFields);
        pendingFields.delete(field);

        const modifiedFields = new Set(state.modifiedFields);
        modifiedFields.delete(field);

        return {
          eventOfferForm: {
            ...state.eventOfferForm,
            [field]: previousValue,
          },
          pendingFields,
          modifiedFields,
          isSaving: pendingFields.size > 0,
          saveErrors: {
            ...state.saveErrors,
            [field]: error instanceof Error ? error.message : "Unknown error",
          },
        };
      });

      toast.error(
        `Failed to update ${field}: ${error instanceof Error ? error.message : "Unknown error"}`
      );
      throw error;
    }
  },

  patchOfferField: async (offerId, field, value) => {
    const previousValue = get().eventOfferForm[field];

    // Set modified and pending flags
    set((state) => {
      const pendingFields = new Set(state.pendingFields);
      const modifiedFields = new Set(state.modifiedFields);
      pendingFields.add(field);
      modifiedFields.add(field);

      return {
        eventOfferForm: {
          ...state.eventOfferForm,
          [field]: value,
        },
        pendingFields,
        modifiedFields,
        isSaving: true,
      };
    });

    // Set timeout to clear modifiedFields after animation time
    setTimeout(() => {
      set((state) => {
        const modifiedFields = new Set(state.modifiedFields);
        modifiedFields.delete(field);
        return { modifiedFields };
      });
    }, FIELD_UPDATE_ANIMATION_TIME);

    try {
      const result = await patchOffer(offerId, { [field]: value });

      // Update state - clear pending and set saved
      set((state) => {
        const pendingFields = new Set(state.pendingFields);
        pendingFields.delete(field);

        const savedFields = new Set(state.savedFields);
        savedFields.add(field);

        return {
          pendingFields,
          savedFields,
          isSaving: pendingFields.size > 0,
        };
      });

      // Clear saved flag after animation time
      setTimeout(() => {
        set((state) => {
          const savedFields = new Set(state.savedFields);
          savedFields.delete(field);
          return { savedFields };
        });
      }, FIELD_UPDATE_ANIMATION_TIME);

      return result;
    } catch (error) {
      // Error handling - revert changes
      set((state) => {
        const pendingFields = new Set(state.pendingFields);
        pendingFields.delete(field);

        const modifiedFields = new Set(state.modifiedFields);
        modifiedFields.delete(field);

        return {
          eventOfferForm: {
            ...state.eventOfferForm,
            [field]: previousValue,
          },
          pendingFields,
          modifiedFields,
          isSaving: pendingFields.size > 0,
          saveErrors: {
            ...state.saveErrors,
            [field]: error instanceof Error ? error.message : "Unknown error",
          },
        };
      });

      toast.error(
        `Failed to update ${field}: ${error instanceof Error ? error.message : "Unknown error"}`
      );
      throw error;
    }
  },

  patchAccountingField: async (eventId, field, value) => {
    const previousValue = get().eventOfferForm[field];

    // Set modified and pending flags
    set((state) => {
      const pendingFields = new Set(state.pendingFields);
      const modifiedFields = new Set(state.modifiedFields);
      pendingFields.add(field);
      modifiedFields.add(field);

      return {
        eventOfferForm: {
          ...state.eventOfferForm,
          [field]: value,
        },
        pendingFields,
        modifiedFields,
        isSaving: true,
      };
    });

    // Set timeout to clear modifiedFields after animation time
    setTimeout(() => {
      set((state) => {
        const modifiedFields = new Set(state.modifiedFields);
        modifiedFields.delete(field);
        return { modifiedFields };
      });
    }, FIELD_UPDATE_ANIMATION_TIME);

    try {
      const result = await patchEventAccounting(eventId, { [field]: value });

      // Update state - clear pending and set saved
      set((state) => {
        const pendingFields = new Set(state.pendingFields);
        pendingFields.delete(field);

        const savedFields = new Set(state.savedFields);
        savedFields.add(field);

        return {
          pendingFields,
          savedFields,
          isSaving: pendingFields.size > 0,
        };
      });

      // Clear saved flag after animation time
      setTimeout(() => {
        set((state) => {
          const savedFields = new Set(state.savedFields);
          savedFields.delete(field);
          return { savedFields };
        });
      }, FIELD_UPDATE_ANIMATION_TIME);

      return result;
    } catch (error) {
      // Error handling - revert changes
      set((state) => {
        const pendingFields = new Set(state.pendingFields);
        pendingFields.delete(field);

        const modifiedFields = new Set(state.modifiedFields);
        modifiedFields.delete(field);

        return {
          eventOfferForm: {
            ...state.eventOfferForm,
            [field]: previousValue,
          },
          pendingFields,
          modifiedFields,
          isSaving: pendingFields.size > 0,
          saveErrors: {
            ...state.saveErrors,
            [field]: error instanceof Error ? error.message : "Unknown error",
          },
        };
      });

      toast.error(
        `Failed to update ${field}: ${error instanceof Error ? error.message : "Unknown error"}`
      );
      throw error;
    }
  },

  isFieldPending: (field) => {
    return get().pendingFields.has(field);
  },

  isFieldSaved: (field) => {
    return get().savedFields.has(field);
  },

  isFieldModified: (field) => {
    return get().modifiedFields.has(field);
  },

  saveEventChanges: async (eventId) => {
    const state = get();
    const fieldsToUpdate: Partial<EventOfferForm> = {};
    const pendingFields = new Set<keyof EventOfferForm>();
    const originalValues: Partial<EventOfferForm> = {};
    const modifiedFieldsList: Array<keyof EventOfferForm> = [];

    state.modifiedFields.forEach((field) => {
      if (EVENT_FIELDS.has(field)) {
        fieldsToUpdate[field] = state.eventOfferForm[field];
        pendingFields.add(field);
        originalValues[field] = state.eventOfferForm[field];
        modifiedFieldsList.push(field);
      }
    });

    if (Object.keys(fieldsToUpdate).length === 0) return;

    // Update pending fields and add to modified fields
    set((state) => ({
      pendingFields: new Set([...state.pendingFields, ...pendingFields]),
      modifiedFields: new Set([...state.modifiedFields, ...modifiedFieldsList]),
      isSaving: true,
    }));

    // Set timeout to clear modifiedFields
    modifiedFieldsList.forEach((field) => {
      setTimeout(() => {
        set((state) => {
          const modifiedFields = new Set(state.modifiedFields);
          modifiedFields.delete(field);
          return { modifiedFields };
        });
      }, FIELD_UPDATE_ANIMATION_TIME);
    });

    try {
      const result = await patchEvent(eventId, fieldsToUpdate);

      // Update state on success
      set((state) => {
        const newPendingFields = new Set(state.pendingFields);
        const newSavedFields = new Set(state.savedFields);

        Object.keys(fieldsToUpdate).forEach((field) => {
          const typedField = field as keyof EventOfferForm;
          newPendingFields.delete(typedField);
          newSavedFields.add(typedField);
        });

        return {
          pendingFields: newPendingFields,
          savedFields: newSavedFields,
          isSaving: newPendingFields.size > 0,
          saveErrors: {},
        };
      });

      // Clear saved fields after animation time
      Object.keys(fieldsToUpdate).forEach((field) => {
        setTimeout(() => {
          set((state) => {
            const savedFields = new Set(state.savedFields);
            savedFields.delete(field as keyof EventOfferForm);
            return { savedFields };
          });
        }, FIELD_UPDATE_ANIMATION_TIME);
      });

      return result;
    } catch (error) {
      // Handle error - revert changes
      set((state) => {
        const pendingFields = new Set(state.pendingFields);
        const modifiedFields = new Set(state.modifiedFields);

        Object.keys(fieldsToUpdate).forEach((field) => {
          const typedField = field as keyof EventOfferForm;
          pendingFields.delete(typedField);
          modifiedFields.delete(typedField);
        });

        return {
          eventOfferForm: {
            ...state.eventOfferForm,
            ...originalValues,
          },
          pendingFields,
          modifiedFields,
          isSaving: pendingFields.size > 0,
          saveErrors: {
            ...state.saveErrors,
            event: error instanceof Error ? error.message : "Unknown error",
          },
        };
      });

      toast.error(
        `Failed to save event changes: ${error instanceof Error ? error.message : "Unknown error"}`
      );
      throw error;
    }
  },

  saveOfferChanges: async (offerId) => {
    const state = get();
    const fieldsToUpdate: Partial<EventOfferForm> = {};
    const pendingFields = new Set<keyof EventOfferForm>();
    const originalValues: Partial<EventOfferForm> = {};
    const modifiedFieldsList: Array<keyof EventOfferForm> = [];

    state.modifiedFields.forEach((field) => {
      if (OFFER_FIELDS.has(field)) {
        fieldsToUpdate[field] = state.eventOfferForm[field];
        pendingFields.add(field);
        originalValues[field] = state.eventOfferForm[field];
        modifiedFieldsList.push(field);
      }
    });

    if (Object.keys(fieldsToUpdate).length === 0) return;

    // Update pending fields and add to modified fields
    set((state) => ({
      pendingFields: new Set([...state.pendingFields, ...pendingFields]),
      modifiedFields: new Set([...state.modifiedFields, ...modifiedFieldsList]),
      isSaving: true,
    }));

    // Set timeout to clear modifiedFields
    modifiedFieldsList.forEach((field) => {
      setTimeout(() => {
        set((state) => {
          const modifiedFields = new Set(state.modifiedFields);
          modifiedFields.delete(field);
          return { modifiedFields };
        });
      }, FIELD_UPDATE_ANIMATION_TIME);
    });

    try {
      const result = await patchOffer(offerId, fieldsToUpdate);

      // Update state on success
      set((state) => {
        const newPendingFields = new Set(state.pendingFields);
        const newSavedFields = new Set(state.savedFields);

        Object.keys(fieldsToUpdate).forEach((field) => {
          const typedField = field as keyof EventOfferForm;
          newPendingFields.delete(typedField);
          newSavedFields.add(typedField);
        });

        return {
          pendingFields: newPendingFields,
          savedFields: newSavedFields,
          isSaving: newPendingFields.size > 0,
          saveErrors: {},
        };
      });

      // Clear saved fields after animation time
      Object.keys(fieldsToUpdate).forEach((field) => {
        setTimeout(() => {
          set((state) => {
            const savedFields = new Set(state.savedFields);
            savedFields.delete(field as keyof EventOfferForm);
            return { savedFields };
          });
        }, FIELD_UPDATE_ANIMATION_TIME);
      });

      return result;
    } catch (error) {
      // Handle error - revert changes
      set((state) => {
        const pendingFields = new Set(state.pendingFields);
        const modifiedFields = new Set(state.modifiedFields);

        Object.keys(fieldsToUpdate).forEach((field) => {
          const typedField = field as keyof EventOfferForm;
          pendingFields.delete(typedField);
          modifiedFields.delete(typedField);
        });

        return {
          eventOfferForm: {
            ...state.eventOfferForm,
            ...originalValues,
          },
          pendingFields,
          modifiedFields,
          isSaving: pendingFields.size > 0,
          saveErrors: {
            ...state.saveErrors,
            offer: error instanceof Error ? error.message : "Unknown error",
          },
        };
      });

      toast.error(
        `Failed to save offer changes: ${error instanceof Error ? error.message : "Unknown error"}`
      );
      throw error;
    }
  },

  saveAccountingChanges: async (eventId) => {
    const state = get();
    const fieldsToUpdate: Partial<EventOfferForm> = {};
    const pendingFields = new Set<keyof EventOfferForm>();
    const originalValues: Partial<EventOfferForm> = {};
    const modifiedFieldsList: Array<keyof EventOfferForm> = [];

    state.modifiedFields.forEach((field) => {
      if (ACCOUNTING_FIELDS.has(field)) {
        fieldsToUpdate[field] = state.eventOfferForm[field];
        pendingFields.add(field);
        originalValues[field] = state.eventOfferForm[field];
        modifiedFieldsList.push(field);
      }
    });

    if (Object.keys(fieldsToUpdate).length === 0) return;

    // Update pending fields and add to modified fields
    set((state) => ({
      pendingFields: new Set([...state.pendingFields, ...pendingFields]),
      modifiedFields: new Set([...state.modifiedFields, ...modifiedFieldsList]),
      isSaving: true,
    }));

    // Set timeout to clear modifiedFields
    modifiedFieldsList.forEach((field) => {
      setTimeout(() => {
        set((state) => {
          const modifiedFields = new Set(state.modifiedFields);
          modifiedFields.delete(field);
          return { modifiedFields };
        });
      }, FIELD_UPDATE_ANIMATION_TIME);
    });

    try {
      const result = await patchEventAccounting(eventId, fieldsToUpdate);

      // Update state on success
      set((state) => {
        const newPendingFields = new Set(state.pendingFields);
        const newSavedFields = new Set(state.savedFields);

        Object.keys(fieldsToUpdate).forEach((field) => {
          const typedField = field as keyof EventOfferForm;
          newPendingFields.delete(typedField);
          newSavedFields.add(typedField);
        });

        return {
          pendingFields: newPendingFields,
          savedFields: newSavedFields,
          isSaving: newPendingFields.size > 0,
          saveErrors: {},
        };
      });

      // Clear saved fields after animation time
      Object.keys(fieldsToUpdate).forEach((field) => {
        setTimeout(() => {
          set((state) => {
            const savedFields = new Set(state.savedFields);
            savedFields.delete(field as keyof EventOfferForm);
            return { savedFields };
          });
        }, FIELD_UPDATE_ANIMATION_TIME);
      });

      return result;
    } catch (error) {
      // Handle error - revert changes
      set((state) => {
        const pendingFields = new Set(state.pendingFields);
        const modifiedFields = new Set(state.modifiedFields);

        Object.keys(fieldsToUpdate).forEach((field) => {
          const typedField = field as keyof EventOfferForm;
          pendingFields.delete(typedField);
          modifiedFields.delete(typedField);
        });

        return {
          eventOfferForm: {
            ...state.eventOfferForm,
            ...originalValues,
          },
          pendingFields,
          modifiedFields,
          isSaving: pendingFields.size > 0,
          saveErrors: {
            ...state.saveErrors,
            accounting:
              error instanceof Error ? error.message : "Unknown error",
          },
        };
      });

      toast.error(
        `Failed to save accounting changes: ${error instanceof Error ? error.message : "Unknown error"}`
      );
      throw error;
    }
  },
}));
