import React from "react";

export default function PinIcon({ className }: { className?: string }) {
  return (
    <>
      <svg
        className={className}
        width="8"
        height="9"
        viewBox="0 0 8 9"
        fill="none"
        xmlns="http://www.w3.org/2000/svg"
      >
        <path
          d="M3.53149 8.2299V5.91797H4.41223V8.2299L3.97186 9.00054L3.53149 8.2299Z"
          fill="#EC4641"
        />
        <path
          d="M7.55014 5.91743H0.449219L3.44492 0H4.55444L7.55014 5.91743Z"
          fill="#EC4641"
        />
        <path
          d="M1.63281 1C1.63281 0.447715 2.08053 0 2.63281 0H5.36676C5.91904 0 6.36676 0.447715 6.36676 1V5.91743H1.63281V1Z"
          fill="#EC4641"
        />
      </svg>
    </>
  );
}
