import React, { useState } from "react";
import { useQuery, useMutation, useQueryClient } from "@tanstack/react-query";
import {
  getTaskComments,
  addTaskComment,
  getTaskActivity,
} from "@/queries/tasks";
import TaskTimeTracking from "./TaskTimeTracking";

export const TaskDetails = ({ task, onClose }) => {
  const [newComment, setNewComment] = useState("");
  const queryClient = useQueryClient();

  const { data: comments, isLoading: loadingComments } = useQuery(
    ["taskComments", task.id],
    () => getTaskComments(task.id)
  );

  const { data: activities, isLoading: loadingActivities } = useQuery(
    ["taskActivity", task.id],
    () => getTaskActivity(task.id)
  );

  const addCommentMutation = useMutation({
    mutationFn: () => addTaskComment(task.id, newComment),
    onSuccess: () => {
      queryClient.invalidateQueries(["taskComments", task.id]);
      queryClient.invalidateQueries(["taskActivity", task.id]);
      setNewComment("");
    },
  });

  const handleAddComment = (e) => {
    e.preventDefault();
    if (newComment.trim()) {
      addCommentMutation.mutate();
    }
  };

  return (
    <div className="bg-purple-900 p-4 rounded-lg max-h-[80vh] overflow-y-auto">
      <div className="flex justify-between items-center mb-4">
        <h2 className="text-white text-xl font-bold">Task Details</h2>
        <button onClick={onClose} className="text-purple-200 hover:text-white">
          ×
        </button>
      </div>

      <div className="grid grid-cols-1 md:grid-cols-2 gap-4">
        <div className="bg-purple-800 p-4 rounded-lg">
          <h3 className="text-purple-200 font-medium mb-3">Comments</h3>
          <div className="space-y-3 mb-4 max-h-60 overflow-y-auto">
            {loadingComments ? (
              <p className="text-purple-300">Loading comments...</p>
            ) : (
              comments?.map((comment) => (
                <div key={comment.id} className="bg-purple-700 p-3 rounded">
                  <p className="text-white">{comment.text}</p>
                  <div className="text-purple-300 text-sm mt-1">
                    By {comment.author} •{" "}
                    {new Date(comment.created_at).toLocaleString()}
                  </div>
                </div>
              ))
            )}
          </div>
          <form onSubmit={handleAddComment} className="mt-3">
            <textarea
              value={newComment}
              onChange={(e) => setNewComment(e.target.value)}
              className="w-full p-2 rounded bg-purple-700 text-white placeholder-purple-300"
              placeholder="Add a comment..."
              rows="2"
            />
            <button
              type="submit"
              className="mt-2 px-4 py-2 bg-purple-600 text-white rounded hover:bg-purple-500"
              disabled={!newComment.trim()}
            >
              Add Comment
            </button>
          </form>
        </div>

        <div className="bg-purple-800 p-4 rounded-lg">
          <h3 className="text-purple-200 font-medium mb-3">Activity Log</h3>
          <div className="space-y-3 max-h-80 overflow-y-auto">
            {loadingActivities ? (
              <p className="text-purple-300">Loading activity...</p>
            ) : (
              activities?.map((activity) => (
                <div key={activity.id} className="flex items-start gap-3">
                  <div className="w-2 h-2 mt-2 rounded-full bg-purple-500" />
                  <div>
                    <p className="text-white">{activity.description}</p>
                    <p className="text-purple-300 text-sm">
                      {new Date(activity.timestamp).toLocaleString()}
                    </p>
                  </div>
                </div>
              ))
            )}
          </div>
        </div>
      </div>
    </div>
  );
};

export default TaskDetails;
