import React from "react";
import Select from "react-select";
import { useFormContext, useController } from "react-hook-form";

export const FormMultiSelect = (props) => {
  const { name, label, options, helpText, required, className, styles } = props;
  const {
    formState: { errors },
    control,
  } = useFormContext();
  const errorMessage = errors[props.name]?.message?.toString();

  const {
    field: { value, onChange, onBlur, ref, ...restSelField },
  } = useController({ name: name, control: control });

  return (
    <div className="my-3">
      <label
        htmlFor={name}
        className="block mb-2 text-sm font-medium text-cave-black dark:text-cave-white"
      >
        {label}
      </label>
      <Select
        name={name}
        options={options}
        value={value}
        onChange={onChange}
        onBlur={onBlur}
        isMulti={true}
        ref={ref}
        required={required}
        className={className}
        styles={styles}
        {...restSelField}
        {...(props.classNamePrefix && {
          classNamePrefix: props.classNamePrefix,
        })}
      />
      {errorMessage && (
        <p className="mt-1 text-sm text-red-600 dark:text-red-400">
          {errorMessage}
        </p>
      )}
      {helpText && (
        <p className="mt-1 text-sm text-gray-500 dark:text-gray-400">
          {helpText}
        </p>
      )}
    </div>
  );
};

export default FormMultiSelect;
