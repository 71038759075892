import { Dialog, Transition } from "@headlessui/react";
import {
  XMarkIcon,
  PlusIcon,
  UserGroupIcon,
  UsersIcon,
} from "@heroicons/react/24/outline";
import { useQuery, useMutation, useQueryClient } from "@tanstack/react-query";
import {
  ColDef,
  GridApi,
  ICellRendererParams,
  GetQuickFilterTextParams,
} from "ag-grid-community";
import { AgGridReact } from "ag-grid-react";
import dayjs from "dayjs";
import React, {
  useState,
  useEffect,
  useRef,
  Fragment,
  useMemo,
  useCallback,
} from "react";
import { useNavigate } from "react-router-dom";
import Select from "react-select";
import AsyncSelect from "react-select/async";
import * as yup from "yup";

import { usePerms } from "@/components/Auth";
import { BreadCrumbs } from "@/components/BreadCrumbs";
import EventState from "@/components/EventState";
import { EventsLoading } from "@/components/Events/EventsLoading";
import {
  FormBuilder,
  Input,
  TextInput,
  Datepicker,
  FormSelect,
  FormMultiSelect,
  selectOptionToValue,
} from "@/components/Form";
import { FormCreatableSelect } from "@/components/Form/CreatableSelect";
import { Header } from "@/components/Header";
import {
  getArtists,
  getArtistsSettings,
  createArtist,
} from "@/queries/artists";
import {
  getEventsList,
  getEventsSettings,
  getEventRoles,
  getEventPeople,
  createEventPeople,
  deleteEventPeople,
  createEvent,
  getEventState,
  postEventState,
  getEventsListStaffing,
} from "@/queries/events";
import {
  getStaff,
  getPeopleSettings,
  deletePersonFromEvent as removePersonFromEvent,
} from "@/queries/people";
import { getVenues, getVenuesSettings, createVenue } from "@/queries/venues";
import { usePageTitle } from "@/utils/pagetitle";
import { companyChoices, stateChoices } from "@/utils/states";
import "ag-grid-community/styles/ag-grid.css";
import "ag-grid-community/styles/ag-theme-alpine.css";
import Button from "../Button";
import DashboardSearch from "../Common/DashboardSearch";
import { DashboardLoading } from "./DashboardLoading";
import { LoadingIndicator } from "../shared/LoadingIndicator";

import { toast } from "react-toastify";

import AccountingDashboardTasks from "../AccountingDashboard/AccountingDashboardTasks";
import AppButton from "../Common/AppButton";
import { CompactStateSwitcher } from "../EventState/CompactStateSwitcher";
import DashboardTableBottomTabs from "./DashboardBottomTabs";
import { CaveEvent } from "@/types/event";
import { USER_ROLES } from "@/types/user";
import { useUser } from "@/hooks/useUser";
import { matchHighlightFormatter } from "@/helpers";

// Types
interface Person {
  id: string;
  first_name: string;
  last_name: string;
  role_name?: string;
  role_id?: number;
}

interface Event {
  id: number;
  name: string;
  date: string;
  venue_name: string;
  venue_city: string;
  venue_state: string;
  event_image_url: string;
  tickets_sold: number;
  tickets_total: number;
  state: string;
  staff?: any[];
  staff_count?: number;
}

interface Role {
  id: number;
  name: string;
  active: boolean;
}

interface PeopleSearchBarProps {
  value: any;
  handleSelect: (personId: string | null, personName: string) => void;
  placeholder: string;
}

interface SearchInputProps {
  value: string;
  onChange: React.ChangeEventHandler<HTMLInputElement>;
  placeholder: string;
}

interface BadgeProps {
  children: React.ReactNode;
  color?: "blue" | "green" | "red" | "yellow" | "purple" | "gray";
}

interface ErrorsProps {
  errors: string[];
  setErrors: React.Dispatch<React.SetStateAction<string[]>>;
}

interface StateSwitcherProps {
  eventId: number;
}

interface ManageStaffCellRendererProps extends ICellRendererParams {
  openStaffDialog: (eventId: number, data: any) => void;
}

interface StaffDialogProps {
  isOpen: boolean;
  event: CaveEvent | null;
  closeModal: () => void;
  eventId: number | null;
  roles: { id: number; name: string }[];
}

interface AddEventDialogProps {
  isOpen: boolean;
  closeModal: () => void;
}

interface EventsGridProps {
  events: Event[];
  searchTerm: string;
  onGridReady: (params: { api: GridApi }) => void;
  columnDefs: ColDef[];
  defaultColDef: any;
  getQuickFilterText: (params: GetQuickFilterTextParams) => string;
}

// Components
const SearchInput: React.FC<SearchInputProps> = ({
  value,
  onChange,
  placeholder,
}) => (
  <div className="relative">
    <div className="absolute inset-y-0 left-0 flex items-center pl-3 pointer-events-none">
      <svg
        className="w-4 h-4 text-gray-500"
        aria-hidden="true"
        xmlns="http://www.w3.org/2000/svg"
        fill="none"
        viewBox="0 0 20 20"
      >
        <path
          stroke="currentColor"
          strokeLinecap="round"
          strokeLinejoin="round"
          strokeWidth="2"
          d="m19 19-4-4m0-7A7 7 0 1 1 1 8a7 7 0 0 1 14 0Z"
        />
      </svg>
    </div>
    <input
      type="search"
      value={value}
      onChange={onChange}
      className="block w-full p-2 pl-10 text-sm text-gray-900 border border-gray-300 rounded-lg bg-gray-50 focus:ring-blue-500 focus:border-blue-500 dark:bg-gray-700 dark:border-gray-600 dark:placeholder-gray-400 dark:text-white dark:focus:ring-blue-500 dark:focus:border-blue-500"
      placeholder={placeholder}
    />
  </div>
);

const Badge: React.FC<BadgeProps> = ({ children, color = "blue" }) => {
  const colorClasses: { [key in BadgeProps["color"]]: string } = {
    blue: "bg-blue-100 text-blue-800",
    green: "bg-green-100 text-green-800",
    red: "bg-red-100 text-red-800",
    yellow: "bg-yellow-100 text-yellow-800",
    purple: "bg-purple-100 text-purple-800",
    gray: "bg-gray-100 text-gray-800",
  };
  return (
    <span
      className={`${colorClasses[color]} text-xs font-medium mr-2 px-2.5 py-0.5 rounded-full`}
    >
      {children}
    </span>
  );
};

const Errors: React.FC<ErrorsProps> = ({ errors, setErrors }) => {
  if (errors.length === 0) return null;
  return (
    <div>
      <div
        className="bg-red-100 border-l-4 border-red-500 text-red-700 px-4 py-3 mb-3"
        role="alert"
      >
        {errors}
      </div>
      <Button onClick={() => setErrors([])}>Try Again</Button>
    </div>
  );
};

const PeopleSearchBar: React.FC<PeopleSearchBarProps> = ({
  value,
  handleSelect,
  placeholder,
}) => {
  const { data: searchResults, isLoading } = useQuery(
    ["peopleSearch"],
    async () => {
      const url = new URL(`${window.location.origin}/api/people/`);

      const response = await fetch(url);
      if (!response.ok) {
        throw new Error("Failed to fetch people");
      }

      const data = await response.json();
      console.log("data", data);
      return data
        .filter((d: any) => d?.first_name)
        .map((person: any) => ({
          value: person.id,
          label:
            `${person.first_name || ""} ${person.last_name || ""}`.trim() ||
            person.title ||
            "Unnamed Person",
        }));
    },
    {
      staleTime: 30000,
      keepPreviousData: true,
    }
  );
  return (
    <Select
      isLoading={isLoading}
      options={searchResults || []}
      onChange={(selectedOption) =>
        handleSelect(
          selectedOption ? selectedOption.value : null,
          selectedOption ? selectedOption.label : ""
        )
      }
      value={value}
      placeholder={placeholder}
      menuPlacement="auto"
      menuPosition="fixed"
      menuPortalTarget={document.body}
      styles={{
        menuPortal: (base) => ({ ...base, zIndex: 9999 }),
        control: (base) => ({
          ...base,
          borderRadius: "0.375rem",
          borderColor: "#d1d5db",
          boxShadow: "none",
          "&:hover": { borderColor: "#9ca3af" },
        }),
        option: (base, state) => ({
          ...base,
          backgroundColor: state.isSelected
            ? "#4f46e5"
            : state.isFocused
              ? "#e0e7ff"
              : "white",
          color: state.isSelected ? "white" : "#1f2937",
          "&:hover": {
            backgroundColor: state.isSelected ? "#4f46e5" : "#e0e7ff",
          },
        }),
      }}
      className="mt-2 w-full"
      noOptionsMessage={() => "Enter a few letters"}
      isClearable
    />
  );
};

const getSearchTerm = (api: GridApi | null): string => {
  try {
    if (api && api.gridOptionsWrapper && api.gridOptionsWrapper.gridOptions) {
      return api.gridOptionsWrapper.gridOptions.quickFilterText || "";
    }
    return "";
  } catch (e) {
    console.error("Error accessing quickFilterText:", e);
    return "";
  }
};

export const highlightMatches = (
  text: any,
  searchTerm: string
): React.ReactNode => {
  if (!searchTerm || !text) return text;
  const searchLower = searchTerm.toLowerCase();
  const textLower = text.toString().toLowerCase();
  if (!textLower.includes(searchLower)) return text;
  const parts: React.ReactNode[] = [];
  let lastIndex = 0;
  let index = textLower.indexOf(searchLower);
  while (index !== -1) {
    if (index > lastIndex) {
      parts.push(text.substring(lastIndex, index));
    }
    parts.push(
      <span className="bg-yellow-200" key={index}>
        {text.substring(index, index + searchTerm.length)}
      </span>
    );
    lastIndex = index + searchTerm.length;
    index = textLower.indexOf(searchLower, lastIndex);
  }
  if (lastIndex < text.length) {
    parts.push(text.substring(lastIndex));
  }
  return parts;
};

export const ImageNameCellRenderer: React.FC<ICellRendererParams> = (props) => {
  const { value, data, api, colDef } = props;
  const q = colDef?.searchTerm;
  const searchTerm = getSearchTerm(api);
  const goToEvent = () => window.open(`/events/${data.id}`, "_blank");
  return (
    <div
      className="flex items-center h-full gap-2 cursor-pointer group"
      onClick={goToEvent}
    >
      {data.event_image_url ? (
        <img
          src={data.event_image_url}
          alt={value}
          className="w-10 h-10 rounded-full object-cover"
        />
      ) : (
        <div className="w-10 h-10 rounded-full bg-gray-200 flex items-center justify-center">
          <span className="text-gray-500 text-xs">No img</span>
        </div>
      )}
      <a
        href={`/events/${data.id}`}
        target="_blank"
        className="font-medium group-hover:underline"
        rel="noreferrer"
        dangerouslySetInnerHTML={{
          __html: matchHighlightFormatter(value, q),
        }}
      ></a>
    </div>
  );
};

export const DateCellRenderer: React.FC<ICellRendererParams> = (props) => {
  const { value, api } = props;
  const searchTerm = getSearchTerm(api);
  let formattedDate = "TBD";
  if (value) {
    formattedDate = dayjs(value).format("MMMM D, YYYY");
  }
  return (
    <div className="h-full flex items-center">
      <span>{highlightMatches(formattedDate, searchTerm)}</span>
    </div>
  );
};

export const VenueCellRenderer: React.FC<ICellRendererParams> = (props) => {
  const { data, api } = props;
  const searchTerm = getSearchTerm(api);
  if (!data.venue_name) return <span className="text-gray-400">-</span>;
  const location = `${data.venue_city}, ${data.venue_state}`;
  return (
    <div className="flex items-center -mt-0.5 h-full overflow-visible">
      <div>
        <div className="font-medium truncate overflow-ellipsis">
          {highlightMatches(data.venue_name, searchTerm)}
        </div>
        <div className="text-gray-500 text-sm whitespace-normal">
          {highlightMatches(location, searchTerm)}
        </div>
      </div>
    </div>
  );
};

export const StaffCellRenderer: React.FC<ICellRendererParams> = (props) => {
  const { data, api } = props;
  const containerRef = useRef<HTMLDivElement | null>(null);
  const searchTerm = getSearchTerm(api);
  const [isVisible, setIsVisible] = useState<boolean>(false);

  useEffect(() => {
    const observer = new IntersectionObserver(
      ([entry]) => {
        if (entry.isIntersecting) {
          setIsVisible(true);
          observer.disconnect();
        }
      },
      { threshold: 0.1 }
    );
    if (containerRef.current) observer.observe(containerRef.current);
    return () => observer.disconnect();
  }, []);

  const {
    data: eventPeople,
    isLoading,
    error,
  } = useQuery(["eventPeople", data.id], () => getEventPeople(data.id), {
    enabled: isVisible && !!data.id,
    staleTime: 5 * 60 * 1000,
    refetchOnMount: false,
  });

  if (isLoading) {
    return (
      <div
        ref={containerRef}
        className="flex items-center justify-center h-full w-full pt-2"
      >
        <div className="flex h-full items-center space-x-1">
          <div className="w-2 h-2 bg-blue-500 rounded-full animate-bounce"></div>
          <div
            className="w-2 h-2 bg-blue-500 rounded-full animate-bounce"
            style={{ animationDelay: "0.2s" }}
          ></div>
          <div
            className="w-2 h-2 bg-blue-500 rounded-full animate-bounce"
            style={{ animationDelay: "0.4s" }}
          ></div>
        </div>
      </div>
    );
  }

  if (error) {
    return (
      <div ref={containerRef} className="text-red-500 text-sm">
        Error loading staff
      </div>
    );
  }

  const staffCount = eventPeople?.length || 0;

  return (
    <div ref={containerRef} className="w-full py-1">
      {staffCount === 0 ? (
        <div className="text-gray-400 italic text-xs py-2">
          No staff assigned
        </div>
      ) : (
        <ul className="space-y-1">
          {eventPeople!.slice(0, 5).map((person: Person) => {
            const fullName = `${person.first_name} ${person.last_name}`;
            return (
              <li
                key={person.id}
                className="text-sm text-gray-700 whitespace-normal py-0.5"
              >
                <span className="font-medium">
                  {highlightMatches(person.role_name, searchTerm)}:
                </span>{" "}
                {highlightMatches(fullName, searchTerm)}
              </li>
            );
          })}
          {eventPeople!.length > 5 && (
            <li className="text-xs text-blue-600 italic py-0.5">
              +{eventPeople!.length - 5} more staff
            </li>
          )}
        </ul>
      )}
    </div>
  );
};

export const ManageStaffCellRenderer: React.FC<ManageStaffCellRendererProps> = (
  props
) => {
  const { data, openStaffDialog } = props;
  const handleManageStaff = () => {
    openStaffDialog(data.id, data);
  };
  return (
    <div className="flex items-center justify-center h-full">
      <Button variant="success" onClick={handleManageStaff}>
        <UsersIcon className="w-3 h-3 mr-1" />
        Manage
      </Button>
    </div>
  );
};

export const TicketsCellRenderer: React.FC<ICellRendererParams> = (props) => {
  const { data } = props;
  return (
    <div className="flex items-center justify-center h-full">
      <div className="text-gray-600 text-xs">
        {data.tickets_sold} / {data.tickets_total}
      </div>
    </div>
  );
};

export const StatusCellRenderer: React.FC<ICellRendererParams> = (props) => {
  const { data } = props;
  const { roleUserIsUsing, user } = useUser();
  const canChangeState =
    [USER_ROLES.STAFFING, USER_ROLES.ADMIN].includes(roleUserIsUsing) ||
    user?.first_name + " " + user?.last_name === "Russ Martin";
  return (
    <div className="flex h-full items-center justify-center">
      <CompactStateSwitcher
        onStateChange={() => toast.success("State Updated")}
        eventId={data.id}
        isDisabled={!canChangeState}
      />
    </div>
  );
};

const StaffDialog: React.FC<StaffDialogProps> = ({
  isOpen,
  closeModal,
  event,
  eventId,
  roles,
}) => {
  const queryClient = useQueryClient();
  const [selectedPersonOption, setSelectedPersonOption] = useState<any>(null);
  const [selectedPerson, setSelectedPerson] = useState<string | null>(null);
  const [selectedPersonName, setSelectedPersonName] = useState("");
  const [selectedRole, setSelectedRole] = useState<any>(null);
  const [peeopleToggledForRemoval, setPeopleToggledForRemoval] = useState<
    string[]
  >([]);
  const {
    data: eventPeople,
    isLoading,
    error,
  } = useQuery(
    ["eventPeople", eventId],
    () => getEventPeople(eventId as number),
    {
      enabled: !!eventId && isOpen,
      refetchOnMount: false,
      staleTime: 5 * 60 * 1000,
    }
  );

  const addMutation = useMutation({
    mutationFn: (data: any) => createEventPeople(eventId as number, data),
    onMutate: async (newData) => {
      await queryClient.cancelQueries(["eventPeople", eventId]);
      await queryClient.cancelQueries(["events", "list"]);

      const previousPeople = queryClient.getQueryData(["eventPeople", eventId]);
      const previousEvents = queryClient.getQueryData(["events", "list"]);

      const roleData = roles.find((r) => r.id === newData.role_id);

      const optimisticPerson = {
        id: `temp-${Date.now()}`,
        person_id: newData.person_id,
        first_name: selectedPersonName.split(" ")[0],
        last_name: selectedPersonName.split(" ").slice(1).join(" "),
        role_id: newData.role_id,
        role_name: roleData?.name || "New Role",
      };

      queryClient.setQueryData(["eventPeople", eventId], (old: any) => [
        ...(old || []),
        optimisticPerson,
      ]);

      queryClient.setQueryData(["events", "list"], (oldEvents: any) => {
        if (!oldEvents) return oldEvents;

        return oldEvents.map((event: any) => {
          if (event.id === eventId) {
            const newStaffMember = {
              id: optimisticPerson.id,
              name: `${optimisticPerson.first_name} ${optimisticPerson.last_name}`,
              role: optimisticPerson.role_name,
            };

            return {
              ...event,
              staff: [...(event.staff || []), newStaffMember],
              staff_count: (event.staff_count || 0) + 1,
            };
          }
          return event;
        });
      });

      return { previousPeople, previousEvents };
    },
    onSuccess: () => {
      queryClient.invalidateQueries(["eventPeople", eventId]);
      queryClient.invalidateQueries(["events", "list"]);
      toast.success("Staff member added");
      resetSelection();
    },
    onError: (_, __, context) => {
      queryClient.setQueryData(
        ["eventPeople", eventId],
        context?.previousPeople
      );
      queryClient.setQueryData(["events", "list"], context?.previousEvents);
      toast.error("Failed to add staff member");
    },
  });

  const deleteMutation = useMutation({
    mutationFn: (personId: string) =>
      removePersonFromEvent(eventId as number, personId),
    onMutate: async (personId) => {
      await queryClient.cancelQueries(["eventPeople", eventId]);
      await queryClient.cancelQueries(["events", "list"]);

      const previousPeople = queryClient.getQueryData(["eventPeople", eventId]);
      const previousEvents = queryClient.getQueryData(["events", "list"]);

      queryClient.setQueryData(["eventPeople", eventId], (old: any) =>
        old ? old.filter((person: any) => person.id !== personId) : []
      );

      queryClient.setQueryData(["events", "list"], (oldEvents: any) => {
        if (!oldEvents) return oldEvents;

        return oldEvents.map((event: any) => {
          if (event.id === eventId) {
            return {
              ...event,
              staff: (event.staff || []).filter((s: any) => s.id !== personId),
              staff_count: Math.max(0, (event.staff_count || 0) - 1),
            };
          }
          return event;
        });
      });

      return { previousPeople, previousEvents };
    },
    onSuccess: () => {
      queryClient.invalidateQueries(["eventPeople", eventId]);
      queryClient.invalidateQueries(["events", "list"]);
      toast.success("Staff member removed");
    },
    onError: (_, __, context) => {
      queryClient.setQueryData(
        ["eventPeople", eventId],
        context?.previousPeople
      );
      queryClient.setQueryData(["events", "list"], context?.previousEvents);
      toast.error("Failed to remove staff member");
    },
  });

  const handlePersonSelect = (personId: string | null, personName: string) => {
    if (personId && personName) {
      const numericId = personId;
      setSelectedPerson(numericId);
      setSelectedPersonName(personName);
      setSelectedPersonOption({ value: personId, label: personName });
    } else {
      resetSelection();
    }
  };

  const handleRoleSelect = (selectedOption: any) => {
    setSelectedRole(selectedOption);
  };

  const handleAddPerson = () => {
    if (selectedPerson && selectedRole) {
      addMutation.mutate({
        person_id: selectedPerson,
        role_id: selectedRole.value,
        description: "",
      });
    }
  };

  const handleRemovePerson = (personId: string) => {
    deleteMutation.mutate(personId);
  };

  const resetSelection = () => {
    setSelectedPerson(null);
    setSelectedPersonName("");
    setSelectedRole(null);
    setSelectedPersonOption(null);
  };

  const roleOptions = roles.map((role) => ({
    value: role.id,
    label: role.name,
  }));

  const eventName = event?.name || "Loading...";
  return (
    <Transition appear show={isOpen} as={Fragment}>
      <Dialog as="div" className="relative z-50" onClose={closeModal}>
        <Transition.Child
          as={Fragment}
          enter="ease-out duration-300"
          enterFrom="opacity-0"
          enterTo="opacity-100"
          leave="ease-in duration-200"
          leaveFrom="opacity-100"
          leaveTo="opacity-0"
        >
          <div className="fixed inset-0 bg-black bg-opacity-25" />
        </Transition.Child>
        <div className="fixed inset-0 overflow-y-auto">
          <div className="flex min-h-full items-center justify-center p-4 text-center">
            <Transition.Child
              as={Fragment}
              enter="ease-out duration-300"
              enterFrom="opacity-0 scale-95"
              enterTo="opacity-100 scale-100"
              leave="ease-in duration-200"
              leaveFrom="opacity-100 scale-100"
              leaveTo="opacity-0 scale-95"
            >
              <Dialog.Panel className="w-full max-w-3xl transform overflow-hidden rounded-lg bg-white dark:bg-gray-800 p-6 text-left align-middle shadow-xl transition-all">
                <div className="flex justify-between items-center mb-4">
                  <Dialog.Title
                    as="h3"
                    className="text-lg font-medium leading-6 text-gray-900 dark:text-white"
                  >
                    Manage Staff - {eventName}
                  </Dialog.Title>
                  <button
                    type="button"
                    className="text-gray-400 hover:text-gray-500 focus:outline-none"
                    onClick={closeModal}
                  >
                    <XMarkIcon className="h-6 w-6" aria-hidden="true" />
                  </button>
                </div>
                <div className="mb-6">
                  <div className="grid grid-cols-1 md:grid-cols-3 gap-4">
                    <div className="md:col-span-2">
                      <label className="block text-sm font-medium text-gray-700 dark:text-gray-300 mb-1">
                        Search Staff
                      </label>
                      <PeopleSearchBar
                        value={selectedPersonOption}
                        placeholder="Search for staff member"
                        handleSelect={handlePersonSelect}
                      />
                    </div>
                    <div>
                      <label className="block text-sm font-medium text-gray-700 dark:text-gray-300 mb-1 mt-1">
                        Role
                      </label>
                      <Select
                        options={roleOptions}
                        value={selectedRole}
                        onChange={handleRoleSelect}
                        placeholder="Select role"
                        isDisabled={!selectedPerson}
                        menuPlacement="auto"
                        menuPosition="fixed"
                        menuPortalTarget={document.body}
                        styles={{
                          menuPortal: (base) => ({ ...base, zIndex: 9999 }),
                          control: (base) => ({
                            ...base,
                            borderRadius: "0.375rem",
                            borderColor: "#d1d5db",
                            boxShadow: "none",
                            "&:hover": { borderColor: "#9ca3af" },
                          }),
                        }}
                        className="w-full"
                      />
                    </div>
                  </div>
                  <div className="mt-4">
                    <button
                      type="button"
                      onClick={handleAddPerson}
                      disabled={!selectedPerson || !selectedRole}
                      className={`inline-flex justify-center rounded-md border border-transparent ${
                        !selectedPerson || !selectedRole
                          ? "bg-gray-300 text-gray-500 cursor-not-allowed"
                          : "bg-blue-600 text-white hover:bg-blue-700 focus:outline-none focus-visible:ring-2 focus-visible:ring-blue-500 focus-visible:ring-offset-2"
                      } px-4 py-2 text-sm font-medium`}
                    >
                      <PlusIcon className="h-5 w-5 mr-2" />
                      Add Staff Member
                    </button>
                  </div>
                </div>
                <div className="mt-6">
                  <h4 className="text-md font-medium text-gray-900 dark:text-white mb-3">
                    Current Staff
                  </h4>
                  {isLoading ? (
                    <div className="animate-pulse">
                      <LoadingIndicator />
                    </div>
                  ) : error ? (
                    <div className="text-red-500">
                      Error loading staff members
                    </div>
                  ) : eventPeople && eventPeople.length > 0 ? (
                    <div className="overflow-y-auto max-h-60 border border-gray-200 dark:border-gray-700 rounded-lg">
                      <table className="min-w-full divide-y divide-gray-200 dark:divide-gray-700">
                        <thead className="bg-gray-50 dark:bg-gray-700">
                          <tr>
                            <th className="px-4 py-3 text-left text-xs font-medium text-gray-500 dark:text-gray-300 uppercase tracking-wider">
                              Name
                            </th>
                            <th className="px-4 py-3 text-left text-xs font-medium text-gray-500 dark:text-gray-300 uppercase tracking-wider">
                              Role
                            </th>
                            <th className="px-4 py-3 text-right text-xs font-medium text-gray-500 dark:text-gray-300 uppercase tracking-wider">
                              Actions
                            </th>
                          </tr>
                        </thead>
                        <tbody className="bg-white dark:bg-gray-800 divide-y divide-gray-200 dark:divide-gray-700">
                          {eventPeople.map((person: Person) => (
                            <tr
                              key={person.id}
                              className="hover:bg-gray-50 dark:hover:bg-gray-700"
                            >
                              <td className="px-4 py-3 whitespace-nowrap">
                                <div className="text-sm font-medium text-gray-900 dark:text-white">
                                  {person.first_name} {person.last_name}
                                </div>
                              </td>
                              <td className="px-4 py-3 whitespace-nowrap">
                                <Badge
                                  color={
                                    person.role_name
                                      ?.toLowerCase()
                                      .includes("manager")
                                      ? "purple"
                                      : person.role_name
                                            ?.toLowerCase()
                                            .includes("tech")
                                        ? "blue"
                                        : person.role_name
                                              ?.toLowerCase()
                                              .includes("security")
                                          ? "red"
                                          : "gray"
                                  }
                                >
                                  {person.role_name}
                                </Badge>
                              </td>
                              <td className="px-4 py-3 whitespace-nowrap text-right text-sm font-medium">
                                {peeopleToggledForRemoval.includes(
                                  person.id
                                ) ? (
                                  <div className="flex justify-end gap-4 items-center">
                                    <button
                                      onClick={() =>
                                        setPeopleToggledForRemoval((prev) =>
                                          prev.filter((p) => p !== person.id)
                                        )
                                      }
                                      className="text-gray-600 hover:text-gray-900 dark:text-gray-400 dark:hover:text-gray-300"
                                    >
                                      Cancel
                                    </button>
                                    <button
                                      onClick={() =>
                                        handleRemovePerson(person.id)
                                      }
                                      className="text-red-600 hover:text-red-900 dark:text-red-400 dark:hover:text-red-300"
                                    >
                                      Confirm
                                    </button>
                                  </div>
                                ) : (
                                  <button
                                    onClick={() =>
                                      setPeopleToggledForRemoval((prev) => [
                                        ...prev,
                                        person.id,
                                      ])
                                    }
                                    className="text-red-600 hover:text-red-900 dark:text-red-400 dark:hover:text-red-300"
                                  >
                                    Remove
                                  </button>
                                )}
                              </td>
                            </tr>
                          ))}
                        </tbody>
                      </table>
                    </div>
                  ) : (
                    <div className="text-center py-10 text-gray-500 dark:text-gray-400 bg-gray-50 dark:bg-gray-700 rounded-lg">
                      <UserGroupIcon className="h-12 w-12 mx-auto text-gray-400" />
                      <p className="mt-2">No staff members assigned yet</p>
                      <p className="text-sm">
                        Use the form above to add staff to this event
                      </p>
                    </div>
                  )}
                </div>
                <div className="mt-6 flex justify-end">
                  <Button
                    type="button"
                    className="px-4 py-2 text-sm font-medium"
                    variant="secondary"
                    onClick={closeModal}
                  >
                    Close
                  </Button>
                </div>
              </Dialog.Panel>
            </Transition.Child>
          </div>
        </div>
      </Dialog>
    </Transition>
  );
};

const AddEventDialog: React.FC<AddEventDialogProps> = ({
  isOpen,
  closeModal,
}) => {
  const queryClient = useQueryClient();
  const venues = useQuery(["venues"], () => getVenues(), getVenuesSettings);
  const artists = useQuery(["artists"], getArtists, getArtistsSettings);
  const [errors, setErrors] = useState<string[]>([]);
  const navigate = useNavigate();
  const mutation = useMutation({
    mutationFn: (data: any) => {
      const cleanedData = {
        ...data,
        seating_chart_id: null,
        date: data.date || null,
        company: data.company || "mammoth",
        state: "draft",
      };
      const requiredFields = ["name", "venue_id"];
      const missingFields = requiredFields.filter(
        (field) => !cleanedData[field]
      );
      if (missingFields.length > 0) {
        throw new Error(`Missing required fields: ${missingFields.join(", ")}`);
      }
      return createEvent(cleanedData);
    },
    onSuccess: (response) => {
      queryClient.invalidateQueries(["events"]);
      closeModal();
      if (Array.isArray(response) && response.length > 0 && response[0].id) {
        toast.success("Event created successfully");
      } else if (response && response.id) {
        toast.success("Event created successfully");
      } else {
        toast.error("Failed to create event. Please try again.");
        console.error("Event creation response is missing ID:", response);
        setErrors(["Failed to create event. Please try again."]);
      }
    },
    onError: (error) => {
      console.error("Failed to create event:", error);
      setErrors(["Failed to create event. Please try again."]);
    },
  });
  const addSchema = yup.object().shape({
    name: yup.string().required(),
    date: yup.string(),
    description: yup.string(),
  });
  const handleSubmit = (data: any) => {
    data = selectOptionToValue("company", data);
    data = selectOptionToValue("venue_id", data);
    if (data.artists) {
      data.artists = data.artists
        .filter((artist: any) => artist)
        .map((artist: any) => artist.value);
    }
    data.state = "draft";
    if (!data.name) {
      setErrors(["Event name is required"]);
      return;
    }
    if (!data.venue_id) {
      setErrors(["Venue is required"]);
      return;
    }
    if (data.date === "" && data.state !== "draft") {
      setErrors([
        "Events can only be in Draft state if they do not have a date",
      ]);
      return;
    } else {
      setErrors([]);
    }
    mutation.mutate(data);
  };
  const venueOptions =
    venues.data?.map((venue: any) => ({
      value: venue.id,
      label: venue.name,
    })) || [];
  const artistOptions =
    artists.data?.map((artist: any) => ({
      value: artist.id,
      label: artist.name,
    })) || [];
  return (
    <Transition appear show={isOpen} as={Fragment}>
      <Dialog as="div" className="relative z-50" onClose={closeModal}>
        <Transition.Child
          as={Fragment}
          enter="ease-out duration-300"
          enterFrom="opacity-0"
          enterTo="opacity-100"
          leave="ease-in duration-200"
          leaveFrom="opacity-100"
          leaveTo="opacity-0"
        >
          <div className="fixed inset-0 bg-black bg-opacity-25" />
        </Transition.Child>
        <div className="fixed inset-0 overflow-y-auto">
          <div className="flex min-h-full items-center justify-center p-4 text-center">
            <Transition.Child
              as={Fragment}
              enter="ease-out duration-300"
              enterFrom="opacity-0 scale-95"
              enterTo="opacity-100 scale-100"
              leave="ease-in duration-200"
              leaveFrom="opacity-100 scale-100"
              leaveTo="opacity-0 scale-95"
            >
              <Dialog.Panel className="w-full max-w-2xl transform overflow-hidden rounded-lg bg-white dark:bg-gray-800 p-6 text-left align-middle shadow-xl transition-all">
                <div className="flex justify-between items-center mb-4">
                  <Dialog.Title
                    as="h3"
                    className="text-lg font-medium leading-6 text-gray-900 dark:text-white"
                  >
                    Add New Event
                  </Dialog.Title>
                  <button
                    type="button"
                    className="text-gray-400 hover:text-gray-500 focus:outline-none"
                    onClick={closeModal}
                  >
                    <XMarkIcon className="h-6 w-6" aria-hidden="true" />
                  </button>
                </div>
                {errors.length > 0 && (
                  <Errors errors={errors} setErrors={setErrors} />
                )}
                <div className="mt-4">
                  <FormBuilder onSubmit={handleSubmit} schema={addSchema}>
                    <div className="space-y-4">
                      <div>
                        <Input
                          name="name"
                          label="Event Name"
                          autoFocus={true}
                          className="w-full px-3 py-2 border border-gray-300 rounded-md shadow-sm focus:outline-none focus:ring-indigo-500 focus:border-indigo-500"
                        />
                      </div>
                      <div className="grid grid-cols-1 md:grid-cols-2 gap-4">
                        <div>
                          <Datepicker
                            name="date"
                            label="Event Date"
                            className="w-full px-3 py-2 border border-gray-300 rounded-md shadow-sm focus:outline-none focus:ring-indigo-500 focus:border-indigo-500"
                          />
                        </div>
                        <div>
                          <FormSelect
                            name="company"
                            label="Company"
                            options={companyChoices}
                            defaultValue={
                              window.location.search.includes("company=touring")
                                ? "mammoth_touring"
                                : window.location.search.includes(
                                      "company=block-booking"
                                    )
                                  ? "mammoth_bb"
                                  : "mammoth"
                            }
                            required={true}
                            data-testid="company-select"
                          />
                        </div>
                      </div>
                      <div>
                        <FormCreatableSelect
                          name="venue_id"
                          label="Venue"
                          options={venueOptions}
                          required={true}
                          data-testid="venue-select"
                          placeholder="Type: Venue Name to select or create new"
                          onCreateOption={async (name: string) => {
                            try {
                              const newVenue = await createVenue({
                                name,
                                active: true,
                                state: "active",
                              });
                              const newOption = {
                                value: newVenue.id,
                                label: newVenue.name,
                              };
                              queryClient.setQueryData(
                                ["venues"],
                                (old: any) => [...(old || []), newVenue]
                              );
                              return newOption;
                            } catch (error) {
                              console.error("Failed to create venue:", error);
                              return null;
                            }
                          }}
                        />
                      </div>
                      <div>
                        <FormCreatableSelect
                          name="artists"
                          label="Headliner(s)"
                          options={artistOptions}
                          required={true}
                          isMulti={true}
                          data-testid="artist-select"
                          placeholder="Type: Artist Name to select or create new"
                          onCreateOption={async (name: string) => {
                            try {
                              const newArtist = await createArtist({
                                name,
                                active: true,
                                state: "active",
                              });
                              const newOption = {
                                value: newArtist.id,
                                label: newArtist.name,
                              };
                              queryClient.setQueryData(
                                ["artists"],
                                (old: any) => [...(old || []), newArtist]
                              );
                              return newOption;
                            } catch (error) {
                              console.error("Failed to create artist:", error);
                              return null;
                            }
                          }}
                        />
                      </div>
                      <div>
                        <TextInput
                          name="description"
                          label="Description"
                          className="w-full px-3 py-2 border border-gray-300 rounded-md shadow-sm focus:outline-none focus:ring-indigo-500 focus:border-indigo-500"
                        />
                      </div>
                      <div className="flex justify-end pt-4 space-x-4">
                        <Button
                          type="button"
                          onClick={closeModal}
                          variant="secondary"
                        >
                          Cancel
                        </Button>
                        <Button variant="primary" type="submit">
                          Add Event
                        </Button>
                      </div>
                    </div>
                  </FormBuilder>
                </div>
              </Dialog.Panel>
            </Transition.Child>
          </div>
        </div>
      </Dialog>
    </Transition>
  );
};

const EventsGrid: React.FC<EventsGridProps> = React.memo(
  ({
    events,
    searchTerm,
    onGridReady,
    columnDefs,
    defaultColDef,
    getQuickFilterText,
  }) => (
    <div className="ag-theme-alpine ag-theme-alpine-dark h-[500px] overflow-y-auto border border-gray-200 dark:border-gray-700 rounded">
      <AgGridReact
        columnDefs={columnDefs}
        rowData={events}
        defaultColDef={defaultColDef}
        onGridReady={onGridReady}
        quickFilterText={searchTerm}
        getQuickFilterText={getQuickFilterText}
        rowHeight={75}
        headerHeight={40}
        className="ag-grid-green"
        rowClass="border-b border-gray-200 dark:border-gray-700"
        rowBuffer={20}
        immutableData={true}
        getRowId={(params) => params.data.id.toString()}
        suppressRowVirtualisation={false}
        pagination={true}
        paginationPageSize={100}
        overlayNoRowsTemplate="<span class='p-4 text-gray-500'>No events found</span>"
      />
    </div>
  )
);
EventsGrid.displayName = "EventsGrid";

const StaffingDashboard: React.FC = () => {
  const { user, roleUserIsUsing } = useUser();
  const queryClient = useQueryClient();
  const events = useQuery<Event[]>(
    ["events", "list"],
    () => getEventsListStaffing(),
    getEventsSettings
  );
  const tabMenuItems = [
    { label: "Calendar", href: "/calendar" },
    { label: "Agencies", href: "/agencies" },
    { label: "Venues", href: "/venues" },
    { label: "Reports", href: "/reports" },
    { label: "Artists", href: "/artists" },
    { label: "Co-Pros", href: "/co-pros" },
  ];
  const people = useQuery(["staff"], () => getStaff(), getPeopleSettings);
  const roles = useQuery<Role[]>(["event-roles"], () => getEventRoles());
  const navigate = useNavigate();
  const { perms } = usePerms();
  const [gridApi, setGridApi] = useState<GridApi | null>(null);
  const [searchTerm, setSearchTerm] = useState<string>("");
  const [staffDialogOpen, setStaffDialogOpen] = useState<boolean>(false);
  const [addEventDialogOpen, setAddEventDialogOpen] = useState<boolean>(false);
  const [selectedEventId, setSelectedEventId] = useState<number | null>(null);
  const [selectedEvent, setSelectedEvent] = useState<Event | null>(null);
  const [assignmentFilter, setAssignmentFilter] = useState<
    "all" | "assigned" | "unassigned"
  >("all");
  usePageTitle(events.isSuccess ? "/events" : "/");
  const authorizedRoles = ["admin", "event_manager"];
  const isAuthorized = perms.data && authorizedRoles.includes(perms.data.role);
  const onlyMarketing = user?.data?.role === "marketing";
  const filteredEvents = (events?.data ?? []).filter((ev) => {
    if (assignmentFilter === "all") return true;
    const isAssigned = ev?.staff_count > 0;
    return assignmentFilter === "assigned" ? isAssigned : !isAssigned;
  });
  const openStaffDialog = (eventId: number, data: any) => {
    setSelectedEventId(eventId);
    setSelectedEvent(data);
    setStaffDialogOpen(true);
  };
  const closeStaffDialog = () => {
    setStaffDialogOpen(false);
    setTimeout(() => {
      setSelectedEventId(null);
    }, 500);
  };
  const openAddEventDialog = () => {
    setAddEventDialogOpen(true);
  };
  const closeAddEventDialog = () => {
    setAddEventDialogOpen(false);
  };

  const eventRoleChoices = roles.data
    ? roles.data
        .filter((role) => role.active)
        .map((role) => ({ id: role.id, name: role.name }))
    : [];
  const columnDefs: ColDef[] = useMemo(() => {
    const baseColumns = [
      {
        field: "name",
        headerName: "Event Name",
        cellRenderer: ImageNameCellRenderer,
        searchTerm,
        flex: 2,
        minWidth: 170,
        sortable: true,
        filter: true,
        headerClass: "ag-header-cell-sorted",
      },
      {
        field: "date",
        headerName: "Date",
        cellRenderer: DateCellRenderer,
        flex: 1,
        minWidth: 130,
        sortable: true,
        filter: true,
        headerClass: "ag-header-cell-sorted",
      },
      {
        field: "venue_name",
        headerName: "Venue",
        cellRenderer: (params) => {
          const { data } = params;
          if (!data.venue_name) return <span className="text-gray-400">-</span>;
          const location = `${data.venue_city}, ${data.venue_state}`;
          return (
            <div className="flex items-center -mt-0.5 h-full overflow-visible">
              <div>
                <div
                  dangerouslySetInnerHTML={{
                    __html: matchHighlightFormatter(
                      data.venue_name,
                      searchTerm
                    ),
                  }}
                  className="font-medium truncate overflow-ellipsis"
                ></div>
                <div
                  dangerouslySetInnerHTML={{
                    __html: matchHighlightFormatter(location, searchTerm),
                  }}
                  className="text-gray-500 text-sm whitespace-normal"
                ></div>
              </div>
            </div>
          );
        },
        flex: 1.5,
        minWidth: 170,
        sortable: true,
        filter: true,
        headerClass: "ag-header-cell-sorted",
      },
      {
        headerName: "Staff",
        cellRenderer: (params) => {
          const staff = params.data.staff || [];
          const staffCount = staff?.length || 0;

          return (
            <div className="w-full py-1">
              {staffCount === 0 ? (
                <div className="text-gray-400 italic text-xs py-2">
                  No staff assigned
                </div>
              ) : (
                <ul className="space-y-1">
                  {staff!.slice(0, 5).map((person: any, i) => {
                    const fullName = `${person.name}`;
                    return (
                      <li
                        key={person.id + i}
                        className="text-sm text-gray-700 whitespace-normal py-0.5"
                      >
                        <span className="font-medium">
                          {person.role}
                          {/* {highlightMatches(person.role, searchTerm)}: */}
                        </span>{" "}
                        {highlightMatches(fullName, searchTerm)}
                      </li>
                    );
                  })}
                  {staff!.length > 5 && (
                    <li className="text-xs text-blue-600 italic py-0.5">
                      +{staff!.length - 5} more staff
                    </li>
                  )}
                </ul>
              )}
            </div>
          );
        },
        flex: 1.5,
        minWidth: 180,
        sortable: false,
        filter: false,
        headerClass: "ag-header-cell-sorted",
        valueGetter: (params) => {
          return params.data.staff.map((st: any) => st.name).join(", ");
        },
        autoHeight: true,
      },
      {
        headerName: "Tickets",
        cellRenderer: TicketsCellRenderer,
        flex: 0.8,
        minWidth: 100,
        sortable: true,
        filter: false,
        headerClass: "ag-header-cell-sorted",
      },
      {
        field: "state",
        headerName: "Status",
        cellRenderer: StatusCellRenderer,
        flex: 1,
        minWidth: 110,
        sortable: true,
        filter: true,
        headerClass: "ag-header-cell-sorted",
      },
    ];
    const actionsColumn = {
      headerName: "Actions",
      cellRenderer: ManageStaffCellRenderer,
      cellRendererParams: { openStaffDialog },
      flex: 1,
      minWidth: 120,
      sortable: false,
      filter: false,
      headerClass: "ag-header-cell-sorted",
    };
    if (
      [USER_ROLES.STAFFING, USER_ROLES.ADMIN].includes(roleUserIsUsing) ||
      user?.first_name + " " + user?.last_name === "Russ Martin"
    ) {
      baseColumns.splice(4, 0, actionsColumn);
    }
    return baseColumns;
  }, [openStaffDialog]);
  const defaultColDef = useMemo(
    () => ({
      sortable: true,
      filter: true,
      resizable: true,
      cellClass: "py-2",
    }),
    []
  );
  const onGridReady = useCallback((params: { api: GridApi }) => {
    setGridApi(params.api);
  }, []);
  const getQuickFilterText = useCallback(
    (params: GetQuickFilterTextParams): string => {
      if (!params.data) return "";
      const { name, venue_name, venue_city, venue_state } = params.data;
      const date = params.data.date
        ? dayjs(params.data.date).format("MMMM D, YYYY")
        : "TBD";
      return `${name || ""} ${date || ""} ${venue_name || ""} ${venue_city || ""} ${venue_state || ""}`;
    },
    []
  );

  if (events.isLoading || people.isLoading || roles.isLoading) {
    return <DashboardLoading />;
  }
  return (
    <>
      <div className="flex flex-col px-6 py-8 mx-auto lg:py-0">
        <div className="w-full flex flex-wrap md:flex-nowrap justify-between items-center gap-4 mb-6">
          <BreadCrumbs
            links={[{ text: "Staffing Dashboard", url: "/dashboard" }]}
          />
          <div className="flex gap-3">
            <Button onClick={() => navigate("/events")} variant="primary">
              <svg
                xmlns="http://www.w3.org/2000/svg"
                className="h-5 w-5 mr-2"
                viewBox="0 0 20 20"
                fill="currentColor"
              >
                <path d="M5 3a2 2 0 00-2 2v2a2 2 0 002 2h2a2 2 0 002-2V5a2 2 0 00-2-2H5zM5 11a2 2 0 00-2 2v2a2 2 0 002 2h2a2 2 0 002-2v-2a2 2 0 00-2-2H5zM11 5a2 2 0 012-2h2a2 2 0 012 2v2a2 2 0 01-2 2h-2a2 2 0 01-2-2V5zM11 13a2 2 0 012-2h2a2 2 0 012 2v2a2 2 0 01-2 2h-2a2 2 0 01-2-2v-2z" />
              </svg>
              Card View
            </Button>
            {perms.data?.create_events && (
              <Button variant="success" onClick={openAddEventDialog}>
                <PlusIcon className="h-5 w-5 mr-2" />
                Add Event
              </Button>
            )}
          </div>
        </div>
        <div className="my-2 w-full">
          <div className="mb-4">
            <DashboardSearch
              value={searchTerm}
              onChange={(e) => setSearchTerm(e.target.value)}
              placeholder="Search events by name, venue, or date"
            />
          </div>
          <div className="flex space-x-4 mb-4 text-white">
            <label>
              <input
                type="radio"
                name="assignmentFilter"
                value="all"
                checked={assignmentFilter === "all"}
                onChange={() => setAssignmentFilter("all")}
              />{" "}
              All
            </label>
            <label>
              <input
                type="radio"
                name="assignmentFilter"
                value="assigned"
                checked={assignmentFilter === "assigned"}
                onChange={() => setAssignmentFilter("assigned")}
              />{" "}
              Assigned
            </label>
            <label>
              <input
                type="radio"
                name="assignmentFilter"
                value="unassigned"
                checked={assignmentFilter === "unassigned"}
                onChange={() => setAssignmentFilter("unassigned")}
              />{" "}
              Not Assigned
            </label>
          </div>
          <EventsGrid
            events={filteredEvents || []}
            searchTerm={searchTerm}
            onGridReady={onGridReady}
            columnDefs={columnDefs}
            defaultColDef={defaultColDef}
            getQuickFilterText={getQuickFilterText}
          />
          <DashboardTableBottomTabs tabMenuItems={tabMenuItems} />
        </div>
      </div>
      <AccountingDashboardTasks
        canAddTasks={
          [USER_ROLES.ADMIN, USER_ROLES.STAFFING].includes(roleUserIsUsing) ||
          user?.first_name + " " + user?.last_name === "Russ Martin"
        }
      />
      <StaffDialog
        isOpen={staffDialogOpen}
        closeModal={closeStaffDialog}
        event={selectedEvent}
        eventId={selectedEventId}
        roles={eventRoleChoices}
      />
      <AddEventDialog
        isOpen={addEventDialogOpen}
        closeModal={closeAddEventDialog}
      />
    </>
  );
};

export default StaffingDashboard;
