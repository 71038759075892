// Cache parsed time values
const timeCache = new Map();

export const validateField = (value, type, format) => {
  if (!value && !format) return true; // Empty non-formatted fields are valid

  switch (format) {
    case "time":
      return !!parseTimeValue(value);

    case "email":
      return /^[^\s@]+@[^\s@]+\.[^\s@]+$/.test(value);

    case "phone":
      return /^[\d\s\-\(\)]+$/.test(value);

    case "boolean":
      return (
        typeof value === "boolean" ||
        ["yes", "no"].includes(value.toLowerCase())
      );

    case "number":
      return !isNaN(parseInt(value)) && parseInt(value) >= 0;

    case "currency":
      return !isNaN(parseFloat(value)) && parseFloat(value) >= 0;

    case "url":
      try {
        new URL(value);
        return true;
      } catch {
        return false;
      }

    default:
      return true;
  }
};

export const validateRow = (row, value) => {
  if (!row.editable) return null;

  // Check required fields
  if (row.required && !value) {
    return `${row.header} is required`;
  }

  // Check format-specific validation
  if (row.format) {
    const formatError = validateField(value, row.type, row.format);
    if (!formatError) {
      return `Invalid ${row.format} format for ${row.header}`;
    }
  }

  return null;
};

export const parseTimeValue = (value) => {
  // Standardize time parsing across all components
  const cacheKey = String(value);
  if (timeCache.has(cacheKey)) {
    return timeCache.get(cacheKey);
  }

  // Handle null/undefined/empty
  if (!value) {
    timeCache.set(cacheKey, null);
    return null;
  }
  try {
    if (!value) return null;

    // Handle time objects
    if (typeof value === "object") {
      if (value instanceof Date) {
        return value.toTimeString().slice(0, 5);
      }
      if (value.getHours && value.getMinutes) {
        return `${value.getHours().toString().padStart(2, "0")}:${value
          .getMinutes()
          .toString()
          .padStart(2, "0")}`;
      }
      throw new Error("Invalid time object");
    }

    // Convert to string and clean up
    value = String(value).trim().toLowerCase();

    // Handle special cases
    if (value === "noon") return "12:00";
    if (value === "midnight") return "00:00";
    if (value === "now") {
      const now = new Date();
      return `${now.getHours().toString().padStart(2, "0")}:${now
        .getMinutes()
        .toString()
        .padStart(2, "0")}`;
    }

    // Try parsing as HH:MM[:SS]
    const timeMatch = value.match(
      /^([0-1]?[0-9]|2[0-3]):([0-5][0-9])(?::[0-5][0-9])?$/
    );
    if (timeMatch) {
      const [_, hours, minutes] = timeMatch;
      return `${hours.padStart(2, "0")}:${minutes}`;
    }

    // Try parsing 12h time with more variations
    const time12Match = value.match(
      /^(\d{1,2})(?::(\d{2}))?\s*(pm|am|p|a|noon|midnight)?$/i
    );
    if (time12Match) {
      let [_, hours, minutes = "00", period] = time12Match;
      hours = parseInt(hours);

      // Handle special words
      if (period === "noon") {
        hours = 12;
        period = "pm";
      } else if (period === "midnight") {
        hours = 12;
        period = "am";
      }

      // For bare numbers 1-11 without period, assume PM
      if (!period && hours >= 1 && hours <= 11) {
        period = "pm";
      }

      // Convert to 24h
      if (
        (period?.toLowerCase().startsWith("p") && hours !== 12) ||
        (!period && hours >= 1 && hours <= 11)
      ) {
        hours += 12;
      } else if (
        (period?.toLowerCase().startsWith("a") && hours === 12) ||
        (!period && hours === 12)
      ) {
        hours = 0;
      }

      // Validate final hours and minutes
      if (
        hours >= 0 &&
        hours <= 23 &&
        parseInt(minutes) >= 0 &&
        parseInt(minutes) <= 59
      ) {
        return `${hours.toString().padStart(2, "0")}:${minutes}`;
      }
    }

    // Try parsing relative times
    const relativeMatch = value.match(/^(\+|-)(\d+)(h|m)$/);
    if (relativeMatch) {
      const [_, sign, amount, unit] = relativeMatch;
      const now = new Date();
      if (unit === "h") {
        now.setHours(
          now.getHours() + (sign === "+" ? 1 : -1) * parseInt(amount)
        );
      } else {
        now.setMinutes(
          now.getMinutes() + (sign === "+" ? 1 : -1) * parseInt(amount)
        );
      }
      return `${now.getHours().toString().padStart(2, "0")}:${now
        .getMinutes()
        .toString()
        .padStart(2, "0")}`;
    }

    throw new Error("Could not parse time value");
  } catch (error) {
    console.error("Time parsing error:", error);
    throw new Error(`Invalid time format: ${error.message}`);
  }
};

export const formatTimeForDisplay = (value) => {
  if (!value) return "";

  // If already in display format, return as is
  if (typeof value === "string" && !value.includes(":")) {
    return value;
  }

  try {
    // Parse the time value
    const [hours, minutes] = value.split(":");
    const hour = parseInt(hours);

    // Convert to 12-hour format
    let period = "AM";
    let displayHour = hour;

    if (hour >= 12) {
      period = "PM";
      displayHour = hour === 12 ? 12 : hour - 12;
    }
    if (hour === 0) {
      displayHour = 12;
    }

    // Handle special cases
    if (hour === 12 && minutes === "00") return "Noon";
    if (hour === 0 && minutes === "00") return "Midnight";

    return `${displayHour}:${minutes} ${period}`;
  } catch (e) {
    console.warn("Error formatting time:", e);
    return value;
  }
};

export const isValidTime = (value) => {
  try {
    return parseTimeValue(value) !== null;
  } catch (e) {
    return false;
  }
};

export const compareTimeValues = (time1, time2) => {
  const t1 = parseTimeValue(time1);
  const t2 = parseTimeValue(time2);

  if (!t1 || !t2) return 0;

  const [h1, m1] = t1.split(":").map(Number);
  const [h2, m2] = t2.split(":").map(Number);

  return h1 * 60 + m1 - (h2 * 60 + m2);
};

export const addMinutes = (time, minutes) => {
  const parsed = parseTimeValue(time);
  if (!parsed) return null;

  const [hours, mins] = parsed.split(":").map(Number);
  const totalMinutes = hours * 60 + mins + minutes;

  const newHours = Math.floor(totalMinutes / 60) % 24;
  const newMinutes = totalMinutes % 60;

  return `${newHours.toString().padStart(2, "0")}:${newMinutes
    .toString()
    .padStart(2, "0")}`;
};

export const timeDifference = (time1, time2) => {
  const t1 = parseTimeValue(time1);
  const t2 = parseTimeValue(time2);

  if (!t1 || !t2) return null;

  const [h1, m1] = t1.split(":").map(Number);
  const [h2, m2] = t2.split(":").map(Number);

  const diff = Math.abs(h1 * 60 + m1 - (h2 * 60 + m2));
  const hours = Math.floor(diff / 60);
  const minutes = diff % 60;

  return { hours, minutes };
};
