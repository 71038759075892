import React, { useEffect, useState } from "react";
import { Header } from "@/components/Header";
import { TwitterHandle } from "@/components/TwitterHandle";
import { LoadingPulse } from "@/components/shared/LoadingPulse";
import {
  useQuery,
  useMutation,
  useQueryClient,
  QueryClient,
} from "@tanstack/react-query";
import ImageDisplay from "@/components/Images/ImageDisplay";
import {
  getVenue,
  getVenuePeople,
  toggleVenueMainPOC,
  getVenueEvents,
  getVenuePastEvents,
  getVenueLinks,
  deleteVenueLink,
  getVenueLinkSettings,
  getVenueComments,
  getVenueCommentSettings,
  createVenueComment,
  updateVenueComment,
  deleteVenueComment,
  getVenueFiles,
  getVenueFileSettings,
  deleteVenueFile,
  getVenueImages,
  getVenueImageSettings,
  deleteVenueImage,
  createVenueImages,
} from "@/queries/venues";
import { useRecordVisit } from "@/queries/visit";
import { useParams, useNavigate } from "react-router-dom";
import { EventTable } from "@/components/EventDisplay/EventTable";
import { BreadCrumbs } from "@/components/BreadCrumbs";
import People from "@/components/People/People";
import LinkDisplay from "@/components/Links/LinkDisplay";
import CommentDisplay from "@/components/Comments/CommentDisplay";
import FileDisplay from "@/components/Files/FileDisplay";
import { useUser, usePerms } from "@/components/Auth";
import { usePageTitle } from "@/utils/pagetitle";

export const VenueDetail = () => {
  const { id } = useParams();
  const { perms } = usePerms();
  const { user } = useUser();
  const navigate = useNavigate();
  const venue = useQuery(["venue-detail", id], () => getVenue(id));
  const people = useQuery(["venue", "people", id], () => getVenuePeople(id));
  const events = useQuery(["venue", "events", id], () => getVenueEvents(id));
  const past_events = useQuery(["venue", "events", "past", id], () =>
    getVenuePastEvents(id)
  );
  const links = useQuery(
    ["venue", "links", id],
    () => getVenueLinks(id),
    getVenueLinkSettings
  );
  const comments = useQuery(
    ["venue", "comments", id],
    () => getVenueComments(id),
    getVenueCommentSettings
  );
  const files = useQuery(
    ["venue", "files", id],
    () => getVenueFiles(id),
    getVenueFileSettings
  );

  const images = useQuery(
    ["venue", "images", id],
    () => getVenueImages(id),
    getVenueImageSettings
  );

  const queryClient = useQueryClient();
  const visitMutation = useRecordVisit();
  usePageTitle(
    venue.isSuccess
      ? `/c${String.fromCharCode(92)}${venue.data.name}`
      : `/c${String.fromCharCode(92)}ave_good...`
  );

  // Comments
  const commentMutation = useMutation({
    mutationFn: (data) => {
      console.log("---> creating comment");
      console.dir(data);

      return createVenueComment(id, data);
    },
    onSuccess: async (data) => {
      queryClient.invalidateQueries(["venue", "comments", id]);
    },
  });

  const editCommentMutation = useMutation({
    mutationFn: (data) => {
      console.log("---> editing comment", data);
      return updateVenueComment(data.id, { body: data.body });
    },
    onSuccess: async () => {
      queryClient.invalidateQueries(["venue", "comments", id]);
    },
  });

  const deleteCommentMutation = useMutation({
    mutationFn: (commentId) => {
      console.log("---> deleting comment", commentId);
      return deleteVenueComment(commentId);
    },
    onSuccess: async () => {
      queryClient.invalidateQueries(["venue", "comments", id]);
    },
  });

  const handleCommentSubmit = (data) => {
    commentMutation.mutate(data);
  };

  const handleEditComment = (commentId, data) => {
    editCommentMutation.mutate({ id: commentId, body: data.body });
  };

  const handleDeleteComment = (commentId) => {
    deleteCommentMutation.mutate(commentId);
  };

  // Toggle main POC flag
  const mutation = useMutation({
    mutationFn: (id) => {
      console.log("---> toggling person", id);

      return toggleVenueMainPOC(id);
    },
    onSuccess: async () => {
      queryClient.invalidateQueries(["venue", "people"]);
    },
  });

  useEffect(() => {
    if (venue.isSuccess) {
      visitMutation.mutate({
        itemID: venue.data.id,
        itemType: "venue",
        itemURL: `/venues/${venue.data.id}`,
      });
    }
  }, [venue.isSuccess]);

  if (venue.isLoading || people.isLoading) {
    return <LoadingPulse />;
  }

  // Handle permissions
  let canEdit = false;

  if (perms.data.edit_venues) {
    canEdit = true;
  }

  // Venue operators attached to the venue can also edit
  people.data.forEach((person) => {
    if (person.user === user.data.id && user.data.role === "venue_operation") {
      canEdit = true;
    }
  });

  return (
    <>
      <Header />
      <div
        className="
        flex flex-col px-6 py-8 mx-auto min-h-screen lg:py-0
    "
      >
        <BreadCrumbs
          links={[
            { text: "Venues", url: "/venues" },
            { text: venue.data.name },
          ]}
        />

        <div className="py-6 md:flex md:items-center md:justify-between">
          <div className="min-w-0 flex-1">
            <h2 className="text-2xl font-bold leading-7 dark:text-cave-white sm:truncate sm:text-3xl sm:tracking-tight">
              {images.data && images.data.length > 0 && (
                <img width="200" height="200" src={images.data[0].image_url} />
              )}

              {venue.data.name}
            </h2>
            <h3 className="my-2 text-gray-600 dark:text-gray-400">
              {venue.data.address}
            </h3>
            <h3 className="my-2 text-gray-600 dark:text-gray-400">
              {venue.data.city}, {venue.data.state} {venue.data.zipcode}
            </h3>
            <TwitterHandle url={venue.data.twitter} />
          </div>
          <div className="mt-4 flex md:ml-4 md:mt-0 justify-start align-top">
            {canEdit && (
              <button
                type="button"
                onClick={() => {
                  navigate(`/venues/edit/${venue.data.id}`);
                }}
                className="ml-3 inline-flex items-center rounded-md bg-blue-500 px-3 py-2 text-sm font-semibold shadow-sm hover:bg-blue-400 focus-visible:outline focus-visible:outline-2 focus-visible:outline-offset-2 focus-visible:outline-blue-500 text-cave-white"
              >
                Edit Venue
              </button>
            )}
          </div>
        </div>

        <div className="py-6 text-gray-300">
          {venue.data.description && (
            <p className="mb-4">{venue.data.description}</p>
          )}

          <table className="table-auto w-3/4 dark:text-cave-white">
            <tbody>
              <tr className="">
                <th className="text-left mr-8 p-2 w-1/4 dark:text-cave-white">
                  Capacity
                </th>
                <td className="dark:text-cave-white">{venue.data.capacity}</td>
              </tr>
              <tr className="">
                <th className="text-left mr-8 p-2 w-1/4">URL</th>
                <td className="dark:text-cave-white">
                  <a
                    target="_blank"
                    href={venue.data.url}
                    className="text-blue-500 underline hover:text-blue-700"
                  >
                    {venue.data.url}
                  </a>
                </td>
              </tr>
              <tr className="">
                <th className="text-left mr-8 p-2  w-1/44">Email</th>
                <td className="dark:text-cave-white">{venue.data.email}</td>
              </tr>
              <tr className="">
                <th className="text-left mr-8 p-2  w-1/4">Phone</th>
                <td className="dark:text-cave-white">{venue.data.phone}</td>
              </tr>
              <tr className="">
                <th className="text-left mr-8 p-2  w-1/4">Sales Tax Percent</th>
                <td className="dark:text-cave-white">{venue.data.sales_tax}</td>
              </tr>
              <tr className="">
                <th className="text-left mr-8 p-2  w-1/4">
                  State Witholding Percent
                </th>
                <td className="dark:text-cave-white">
                  {venue.data.witholding_percentage}
                </td>
              </tr>
              <tr className="">
                <th className="text-left mr-8 p-2  w-1/4">Rent Rate</th>
                <td className="dark:text-cave-white">{venue.data.rent_rate}</td>
              </tr>
              <tr className="">
                <th className="text-left mr-8 p-2  w-1/4">The Deal</th>
                <td className="dark:text-cave-white">{venue.data.the_deal}</td>
              </tr>
              <tr className="">
                <th className="text-left mr-8 p-2  w-1/4">Market</th>
                <td className="dark:text-cave-white">{venue.data.market}</td>
              </tr>
              <tr className="0">
                <th className="text-left mr-8 p-2  w-1/4">Region</th>
                <td className="dark:text-cave-white">{venue.data.region}</td>
              </tr>
              <tr className="">
                <th className="text-left mr-8 p-2  w-1/4">Partner</th>
                <td className="dark:text-cave-white">{venue.data.partner}</td>
              </tr>
              <tr className="0">
                <th className="text-left mr-8 p-2  w-1/4">Ticketing</th>
                <td className="dark:text-cave-white">{venue.data.ticketing}</td>
              </tr>
            </tbody>
          </table>
        </div>

        <div className="py-6">
          <ImageDisplay
            images={images}
            entityType="venues"
            id={venue.data.id}
            editable={perms.data.edit_venues}
          />
          <People
            people={people}
            toggleMainPOC={mutation}
            addURL={`/venues/people/add/${venue.data.id}`}
            itemType="venue"
            editable={perms.data.edit_venues}
          />
        </div>

        <div className="py-6">
          <h3 className="text-xl font-semibold mb-6 dark:text-cave-white">
            Events
          </h3>
          {events.isLoading && <LoadingPulse />}
          {events.isSuccess && <EventTable events={events.data} />}
        </div>

        <div className="py-6">
          <h3 className="text-xl font-semibold mb-6 dark:text-cave-white">
            Past Events
          </h3>
          {past_events.isLoading && <LoadingPulse />}
          {past_events.isSuccess && (
            <EventTable events={past_events.data} past={true} />
          )}
        </div>

        <div className="py-6">
          <LinkDisplay
            links={links}
            addURL={`/venues/link/add/${id}`}
            deleteQuery={deleteVenueLink}
            editable={perms.data.edit_venues}
          />
        </div>

        <div className="py-6">
          <FileDisplay
            files={files}
            id={id}
            entityType="venue"
            editable={canEdit}
            perms={perms}
          />
        </div>

        <div className="py-6">
          <CommentDisplay
            comments={comments}
            handleAddComment={handleCommentSubmit}
            handleEditComment={handleEditComment}
            handleDeleteComment={handleDeleteComment}
          />
        </div>
      </div>
    </>
  );
};
