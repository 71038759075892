import { useQueryClient } from "@tanstack/react-query";
import { AgGridReact } from "ag-grid-react";
import { InputSwitch } from "primereact/inputswitch";
import { useState, useEffect, useRef, useCallback, useMemo } from "react";
import { toast } from "react-toastify";

import { offerEditableBgClass, offerReadOnlyBgClass } from "@/constants/offer";
import { useEventOfferStore } from "@/store/eventOfferStore";
import {
  amountDisplay,
  amountStrToInt,
  formatInputToMoney,
} from "@/utils/money";

export const FIELD_UPDATE_ANIMATION_TIME = 2000;

const VariablesAgGrid = ({ variables, event, eventId }) => {
  const queryClient = useQueryClient();
  const [updatedProperties, setUpdatedProperties] = useState([]);
  const [rowData, setRowData] = useState([]);
  const [pendingToggles, setPendingToggles] = useState({});
  const gridRef = useRef(null);

  // Use Zustand store
  const {
    eventOfferForm,
    patchEventField,
    patchAccountingField,
    patchOfferField,
    initializeEventOfferForm,
    pendingFields,
    isFieldModified,
    modifiedFields,
  } = useEventOfferStore();

  // Map fields to server properties
  const fieldToServerPropMap = useMemo(
    () => ({
      ascap: "has_ascap",
      bmi: "has_bmi",
      sesac: "has_sesac",
      offer_comp_tax: "offer_has_comp_tax",
      offer_gmr: "offer_has_gmr",
      insurance_amount: "has_insurance",
      rent: "offer_has_rent",
      credit_card_fees: "has_credit_card_fees",
      ticket_commission: "has_ticket_commission",
    }),
    []
  );

  // Initialize the store with event data on mount
  useEffect(() => {
    if (!event) return;

    // Create the initial state from event data
    const initialState = {
      has_ascap: event.has_ascap,
      has_bmi: event.has_bmi,
      has_sesac: event.has_sesac,
      offer_has_comp_tax: event.offer_has_comp_tax,
      offer_comp_tax: event.offer_comp_tax,
      offer_has_gmr: event.offer_has_gmr,
      offer_gmr: event.offer_gmr,
      has_insurance: event.has_insurance,
      offer_has_rent: event.offer_has_rent,
      has_credit_card_fees: event.has_credit_card_fees,
      credit_card_fees: event.credit_card_fees,
      has_ticket_commission: event.has_ticket_commission,
      ticket_commission: event.ticket_commission,
    };

    // Only initialize if the store doesn't have these values yet
    let needsInit = false;
    for (const key in initialState) {
      if (
        !eventOfferForm[key] ||
        eventOfferForm[key] !== 0 ||
        eventOfferForm[key] !== false ||
        eventOfferForm[key] !== initialState[key]
      ) {
        needsInit = true;
        break;
      }
    }

    if (needsInit) {
      initializeEventOfferForm(initialState);
    }
  }, [event, initializeEventOfferForm]);

  // Clear a specific pending toggle once server data confirms the change
  const clearPendingToggle = useCallback((field) => {
    setPendingToggles((prev) => {
      const newToggles = { ...prev };
      delete newToggles[field];
      return newToggles;
    });
  }, []);

  // Get value with priority: pendingToggles > eventOfferForm > event prop
  const getValue = useCallback(
    (field, defaultValue) => {
      // First check pendingToggles for immediate UI feedback
      if (pendingToggles[field] !== undefined) {
        return pendingToggles[field];
      }

      // Then check store
      const storeKey = fieldToServerPropMap[field] || field;
      if (eventOfferForm[storeKey] !== undefined) {
        return eventOfferForm[storeKey];
      }

      // Fallback to props
      return defaultValue;
    },
    [pendingToggles, eventOfferForm, fieldToServerPropMap]
  );
  const gatherEditableFields = useMemo(() => {
    const allPossibleEditableFields = [
      "offer_comp_tax",
      "credit_card_fees",
      "ticket_commission",
    ];
    return allPossibleEditableFields.filter((field) => {
      return eventOfferForm[fieldToServerPropMap[field]] !== false;
    });
  }, [
    eventOfferForm.offer_has_comp_tax,
    eventOfferForm.offer_has_gmr,
    eventOfferForm.has_credit_card_fees,
    eventOfferForm.has_ticket_commission,
  ]);

  // Build row data from combined sources (store, props, pending state)
  useEffect(() => {
    if (!variables || !event) return;
    const newRowData = [
      {
        header: "ASCAP",
        field: "ascap",
        value: amountDisplay(variables.ascap),
        editable: false,
        editableToggle: true,
        toggleValue: eventOfferForm.has_ascap,
        isChanged: isFieldModified("ascap") || isFieldModified("has_ascap"),
      },
      {
        header: "BMI",
        field: "bmi",
        value: amountDisplay(variables.bmi),
        editable: false,
        editableToggle: true,
        toggleValue: eventOfferForm.has_bmi,
        isChanged: isFieldModified("bmi") || isFieldModified("has_bmi"),
      },
      {
        header: "SESAC",
        field: "sesac",
        value: amountDisplay(variables.sesac),
        editable: false,
        editableToggle: true,
        toggleValue: eventOfferForm.has_sesac,
        isChanged: isFieldModified("sesac") || isFieldModified("has_sesac"),
      },
      {
        header: "COMP TAX",
        format: "money",
        field: "offer_comp_tax",
        // Use value from store if available, otherwise from props
        value: amountDisplay(
          eventOfferForm.offer_comp_tax || event.offer_comp_tax
        ),
        editable: gatherEditableFields.includes("offer_comp_tax"),
        toggleValue: eventOfferForm.offer_has_comp_tax,
        editableToggle: true,
        isChanged:
          isFieldModified("offer_comp_tax") ||
          isFieldModified("has_offer_comp_tax"),
      },
      {
        header: "GMR",
        format: "money",
        field: "offer_gmr",
        value: amountDisplay(eventOfferForm.offer_gmr || variables.gmr),
        editable: false,
        toggleValue: eventOfferForm.offer_has_gmr,
        editableToggle: true,
        isChanged: isFieldModified("gmr") || isFieldModified("has_gmr"),
      },
      {
        header: "INSURANCE",
        field: "insurance_amount",
        value: amountDisplay(variables.insurance_amount),
        toggleValue: eventOfferForm.has_insurance,
        editable: false,
        editableToggle: true,
        msg: "55¢/head x capacity",
        isChanged:
          isFieldModified("insurance_amount") ||
          isFieldModified("has_insurance_amount"),
      },
      {
        header: "RENT",
        field: "rent",
        value: !getValue("rent", event.offer_has_rent)
          ? "FLAT"
          : amountDisplay(event.tickets_total * 100),
        editable: false,
        editableToggle: true,
        toggleValue: eventOfferForm.offer_has_rent,
        msg: "$1/per",
        isChanged:
          isFieldModified("rent") ||
          isFieldModified("has_rent") ||
          isFieldModified("rent_rate"),
      },
      {
        header: "CREDIT CARDS",
        format: "money",
        field: "credit_card_fees",
        value: amountDisplay(
          eventOfferForm.credit_card_fees || variables.credit_card_fees
        ),
        editable: gatherEditableFields.includes("credit_card_fees"),
        toggleValue: eventOfferForm.has_credit_card_fees,
        editableToggle: true,
        msg: "3.50%",
        isChanged:
          isFieldModified("credit_card_fees") ||
          isFieldModified("has_credit_card_fees"),
      },
      {
        header: "TICKET COMMISSION",
        format: "money",
        field: "ticket_commission",
        value: amountDisplay(
          eventOfferForm.ticket_commission || variables.ticket_commission
        ),
        editable: gatherEditableFields.includes("ticket_commission"),
        toggleValue: eventOfferForm.has_ticket_commission,
        editableToggle: true,
        msg: "3%/CAP",
        isChanged:
          isFieldModified("ticket_commission") ||
          isFieldModified("has_ticket_commission"),
      },
    ];

    setRowData(newRowData);
  }, [eventOfferForm, getValue, modifiedFields, gatherEditableFields]);

  const calculateTotal = useMemo(() => {
    if (!variables) return "$0.00";
    return variables.variables_total;
  }, [variables]);

  // Handle update for editable fields (using Zustand store)
  const handleUpdateVariables = useCallback(
    (field, value) => {
      // Add field to updated properties for animation
      setUpdatedProperties((prev) => [...prev, field]);

      // Reset animation after timeout
      setTimeout(() => {
        setUpdatedProperties((prev) => prev.filter((prop) => prop !== field));
      }, FIELD_UPDATE_ANIMATION_TIME);
      if (field === "offer_comp_tax") {
        patchOfferField(eventId, "offer_comp_tax", amountStrToInt(value))
          .then(() => {
            queryClient.invalidateQueries([
              "variables-offer-pdf",
              eventId?.toString(),
            ]);
            queryClient.invalidateQueries([
              "expenses-offer-pdf",
              eventId?.toString(),
            ]);
            queryClient.invalidateQueries([
              "event-rollups",
              eventId?.toString(),
            ]);
          })
          .catch((error) => {
            toast.error(`Failed to update ${field}`);
          });
      }

      if (field === "offer_gmr") {
        patchEventField(eventId, field, amountStrToInt(value))
          .then(() => {
            queryClient.invalidateQueries([
              "variables-offer-pdf",
              eventId?.toString(),
            ]);
            queryClient.invalidateQueries([
              "expenses-offer-pdf",
              eventId?.toString(),
            ]);
            queryClient.invalidateQueries([
              "event-rollups",
              eventId?.toString(),
            ]);
          })
          .catch((error) => {
            toast.error(`Failed to update ${field}`);
          });
      } else if (
        field === "credit_card_fees" ||
        field === "ticket_commission"
      ) {
        patchAccountingField(eventId, field, amountStrToInt(value));
      }
    },
    [eventId, patchEventField, patchAccountingField, queryClient]
  );

  // Handle toggle changes (using Zustand store)
  const handleToggleChange = useCallback(
    (field, newValue) => {
      // Set local pending state immediately for smooth UI
      setPendingToggles((prev) => ({
        ...prev,
        [field]: newValue,
      }));

      // Add to updated properties for animation
      setUpdatedProperties((prev) => [...prev, field]);

      // Reset animation after timeout
      setTimeout(() => {
        setUpdatedProperties((prev) => prev.filter((prop) => prop !== field));
      }, FIELD_UPDATE_ANIMATION_TIME);

      // Map field to corresponding server property
      const serverProp = fieldToServerPropMap[field];
      if (
        field === "ascap" ||
        field === "bmi" ||
        field === "sesac" ||
        field === "gmr"
      ) {
        patchOfferField(eventId, serverProp, newValue)
          .then(() => {
            clearPendingToggle(field);
            queryClient.invalidateQueries(["variables-offer-pdf", eventId]);
            queryClient.invalidateQueries(["expenses-offer-pdf", eventId]);
            queryClient.invalidateQueries(["event-rollups", eventId]);
          })
          .catch((error) => {
            toast.error(`Failed to update ${field}`);
            setPendingToggles((prev) => {
              const newToggles = { ...prev };
              delete newToggles[field];
              return newToggles;
            });
          });
      }
      if (serverProp) {
        patchEventField(eventId, serverProp, newValue)
          .then(() => {
            clearPendingToggle(field);
            queryClient.invalidateQueries(["variables-offer-pdf", eventId]);
            queryClient.invalidateQueries(["expenses-offer-pdf", eventId]);
            queryClient.invalidateQueries(["event-rollups", eventId]);
          })
          .catch((error) => {
            toast.error(`Failed to update ${field}`);
            setPendingToggles((prev) => {
              const newToggles = { ...prev };
              delete newToggles[field];
              return newToggles;
            });
          });
      }
    },
    [
      fieldToServerPropMap,
      patchEventField,
      patchAccountingField,
      eventId,
      clearPendingToggle,
      queryClient,
    ]
  );

  // Cell renderer for switches
  const AgGridRadioSwitch = useCallback(
    (params) => {
      const { isFieldPending } = useEventOfferStore();
      if (!params.data?.editableToggle) {
        return null;
      }

      // Use local state with synchronization to store/pendingToggles
      const [localValue, setLocalValue] = useState(params.data?.toggleValue);

      // Update when external state changes (from store or pending toggles)
      useEffect(() => {
        if (
          isFieldPending(params.data.field) ||
          isFieldPending(fieldToServerPropMap[params.data.field])
        ) {
          return;
        }
        setLocalValue(params.data?.toggleValue);
      }, [params.data?.toggleValue]);

      const handleLocalValueChange = (value) => {
        setLocalValue(value);
        handleToggleChange(params.data.field, value);
      };

      return (
        <>
          {/* {JSON.stringify(params.data.field)} */}
          <InputSwitch
            className="small-input-switch"
            disabled={
              isFieldPending(params.data.field) ||
              isFieldPending(fieldToServerPropMap[params.data.field])
            }
            checked={localValue}
            onChange={(e) => handleLocalValueChange(e.value)}
          />
        </>
      );
    },
    [handleToggleChange]
  );

  const handleCellClass = useCallback(
    (params) => {
      // console.log("ran");
      const initialClass = gatherEditableFields.includes(params.data.field)
        ? offerEditableBgClass
        : offerReadOnlyBgClass;
      // if (params.data.field === "credit_card_fees") {
      //   console.log(
      //     "isFieldModified(params.data.field",
      //     params.data.field,
      //     isFieldModified(params.data.field)
      //   );
      //   console.log(
      //     "fieldToServerPropMap",
      //     fieldToServerPropMap[params.data.field]
      //   );
      //   console.log(
      //     "isFieldModified(fieldToServerPropMap[params.data.field])",
      //     isFieldModified(fieldToServerPropMap[params.data.field])
      //   );
      // }
      return `${initialClass}`;
    },
    [
      // pendingFields,
      modifiedFields,
      gatherEditableFields,
      eventOfferForm.offer_has_comp_tax,
      eventOfferForm.offer_has_gmr,
      eventOfferForm.has_credit_card_fees,
      eventOfferForm.has_ticket_commission,
    ]
  );

  const handleIsEditable = useCallback(
    (params) => {
      return params.data.editable && params.data.editableToggle;
    },
    [pendingFields]
  );

  // Column definitions for AgGrid
  const columnDefs = useMemo(
    () => [
      {
        field: "header",
        width: 180,
        editable: false,
        cellStyle: {
          fontFamily: "Montserrat, sans-serif",
          fontWeight: "bold",
          backgroundColor: "#E5E7EB", // Light gray background like in PDF
        },
      },
      {
        field: "radio",
        editable: false,
        cellRenderer: AgGridRadioSwitch,
        width: "80px",
      },
      {
        field: "value",
        flex: 1,
        editable: handleIsEditable,
        cellEditor: "agTextCellEditor",
        cellClass: handleCellClass,
        cellStyle: {
          fontFamily: "Montserrat, sans-serif",
          fontWeight: "bold",
          textAlign: "left",
          paddingRight: "5px",
        },
        valueFormatter: (params) => {
          if (params.data.format === "money" && params.value) {
            return formatInputToMoney(params.value);
          }
          return params.value;
        },
        onCellValueChanged: (params) => {
          handleUpdateVariables(params.data.field, params.newValue);
        },
      },
      {
        field: "msg",
        flex: 1,
        cellStyle: {
          paddingLeft: "8px",
          fontFamily: "Montserrat, sans-serif",
          fontWeight: "normal",
        },
        editable: false,
        hide: (params) => !params.data.msg,
      },
    ],
    [
      handleUpdateVariables,
      AgGridRadioSwitch,
      eventOfferForm.has_ascap,
      eventOfferForm.has_bmi,
      eventOfferForm.has_sesac,
      eventOfferForm.offer_has_comp_tax,
      eventOfferForm.offer_has_gmr,
      eventOfferForm.has_insurance,
      eventOfferForm.offer_has_rent,
      eventOfferForm.has_credit_card_fees,
      eventOfferForm.has_ticket_commission,
    ]
  );

  // Default column definition
  const defaultColDef = useMemo(
    () => ({
      resizable: true,
      sortable: false,
      filter: false,
    }),
    []
  );

  // Handle grid ready
  const onGridReady = useCallback((params) => {
    if (params.columnApi) {
      params.columnApi.autoSizeAllColumns(false);
    } else if (params.api && params.api.columnModel) {
      params.api.sizeColumnsToFit();
    }

    const gridElement = document.querySelector(".ag-theme-alpine");
    if (gridElement) {
      gridElement.style.width = "100%";
    }

    // Add resize observer
    const resizeObserver = new ResizeObserver(() => {
      setTimeout(() => {
        // Don't auto-size on resize to maintain column spacing
      }, 100);
    });

    if (gridElement) {
      resizeObserver.observe(gridElement);
    }
    resizeObserver.observe(document.body);
  }, []);

  return (
    <div className="w-full border-2 border-gray-300 border-dashed">
      <div className="bg-gray-300 text-sm flex justify-between font-bold p-2 font-montserrat">
        <div>VARIABLE EXPENSES TOTAL</div>
        <div>{amountDisplay(calculateTotal)}</div>
      </div>
      <div
        className="ag-theme-alpine"
        style={{ height: "auto", width: "100%" }}
      >
        <AgGridReact
          ref={gridRef}
          rowData={rowData}
          columnDefs={columnDefs}
          defaultColDef={defaultColDef}
          onGridReady={onGridReady}
          singleClickEdit={true}
          domLayout="autoHeight"
          headerHeight={0}
          immutableData={true}
          getRowId={(params) => params.data.field}
          suppressPropertyNamesCheck={true}
          suppressColumnVirtualisation={true}
          stopEditingWhenCellsLoseFocus
          suppressCellFlash={true}
          animateRows={false}
          suppressChangeDetection={true}
          suppressMovableColumns={true}
          rowHeight={35}
          rowClass="variables-grid-row"
          className="variables-compact-grid"
        />
      </div>
      <style jsx>{`
        :global(.variables-grid-row) {
          padding-top: 2px;
          padding-bottom: 2px;
          border-bottom: 1px solid #e5e7eb;
        }
        :global(.variables-grid-row:last-child) {
          border-bottom: none;
        }
        :global(.variables-compact-grid) {
          --ag-grid-size: 4px;
          --ag-border-color: #dde2eb;
        }
        :global(.variables-compact-grid .ag-cell) {
          padding-left: 8px;
          padding-right: 5px;
        }
        :global(.variables-compact-grid .ag-header-cell) {
          padding-left: 5px;
          padding-right: 5px;
        }
        :global(.variables-compact-grid .ag-cell.ag-cell-inline-editing) {
          padding: 5px;
          height: 35px;
        }
      `}</style>
    </div>
  );
};

export default VariablesAgGrid;
