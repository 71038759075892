import dayjs from "dayjs";
import React from "react";
import LoadingPulse from "@/components/shared/LoadingPulse";
import { useQuery, useQueryClient } from "@tanstack/react-query";
import { getLinkCode } from "@/queries/links";
import { useParams, useNavigate } from "react-router-dom";
import { usePageTitle } from "@/utils/pagetitle";

export const PublicLinkDetail = () => {
  const { code } = useParams();
  const navigate = useNavigate();
  const link = useQuery(["link-public", code], () => getLinkCode(code));
  usePageTitle(
    link.isSuccess
      ? `/c${String.fromCharCode(92)}mammoth_ticketing`
      : `/c${String.fromCharCode(92)}ave_good...`
  );

  const queryClient = useQueryClient();

  if (link.isLoading) {
    return <LoadingPulse />;
  }

  return (
    <div className="flex flex-col px-6 py-8 mx-auto min-h-screen lg:py-0 dark:text-cave-white">
      <div className="mb-6">
        <h1 className="text-2xl font-semibold mb-4">Mammoth Ticket Details</h1>

        <div className="p-4 mb-4 bg-blue-50 dark:bg-blue-900 border-l-4 border-blue-500 text-blue-700 dark:text-blue-100">
          <p>Live ticket sales information. Updated automatically.</p>
        </div>
      </div>

      <div className="my-2 w-full overflow-x-auto shadow-md sm:rounded-lg">
        <table className="w-full text-sm text-left text-gray-500 dark:text-gray-400">
          <thead className="text-xs text-gray-700 uppercase bg-gray-100 dark:bg-gray-700 dark:text-gray-400">
            <tr>
              <th scope="col" className="px-6 py-3">
                Event
              </th>
              <th scope="col" className="px-6 py-3">
                Date
              </th>
              <th scope="col" className="px-6 py-3">
                Venue
              </th>
              <th scope="col" className="px-6 py-3">
                Name
              </th>
              <th scope="col" className="px-6 py-3">
                Today
              </th>
              <th scope="col" className="px-6 py-3">
                Previous
              </th>
              <th scope="col" className="px-6 py-3">
                TM
              </th>
              <th scope="col" className="px-6 py-3">
                etix
              </th>
              <th scope="col" className="px-6 py-3">
                Venue
              </th>
              <th scope="col" className="px-6 py-3">
                Fan Club
              </th>
              <th scope="col" className="px-6 py-3">
                Outlets
              </th>
              <th scope="col" className="px-6 py-3">
                VIP
              </th>
              <th scope="col" className="px-6 py-3">
                Comps
              </th>
              <th scope="col" className="px-6 py-3">
                Last Updated
              </th>
            </tr>
          </thead>
          <tbody>
            {link.data.manifests.map((link, index) => {
              return (
                <tr
                  key={index}
                  className="bg-white border-b dark:bg-gray-800 dark:border-gray-700 hover:bg-gray-50 dark:hover:bg-gray-600"
                >
                  <td className="px-6 py-4 font-medium text-gray-900 whitespace-nowrap dark:text-white">
                    {link.event_name}
                  </td>
                  <td className="px-6 py-4">
                    {dayjs(link.event_date).format("MMM D, YYYY")}
                  </td>
                  <td className="px-6 py-4">{link.venue_name}</td>
                  <td className="px-6 py-4">{link.name}</td>
                  <td className="px-6 py-4 font-semibold">{link.today}</td>
                  <td className="px-6 py-4">{link.previous}</td>
                  <td className="px-6 py-4">{link.tm}</td>
                  <td className="px-6 py-4">{link.etix}</td>
                  <td className="px-6 py-4">{link.venue}</td>
                  <td className="px-6 py-4">{link.fan_club}</td>
                  <td className="px-6 py-4">{link.outlets}</td>
                  <td className="px-6 py-4">{link.vip}</td>
                  <td className="px-6 py-4">{link.comps}</td>
                  <td className="px-6 py-4">
                    {link.last_updated
                      ? dayjs(link.last_updated).format("MMM D, YYYY")
                      : "N/A"}
                  </td>
                </tr>
              );
            })}
          </tbody>
        </table>
      </div>

      <div className="mt-8 text-center text-sm text-gray-500 dark:text-gray-400">
        <p>This link is provided by Mammoth Live for authorized use only.</p>
        <p>All data is confidential and should not be shared.</p>
      </div>
    </div>
  );
};
