import React, { useMemo } from "react";
import { Table, TableRow } from "../AppTable2";
import { amountDisplay } from "@/utils/money";
import { useOverrides } from "@/hooks/useOverrides";
import { useParams } from "react-router-dom";
import { useSettlementComponent } from "@/hooks/useSettlementComponent";

const normalizeFieldName = (name: string) =>
  name.trim().toLowerCase().replace(/\s+/g, "_");

const MAIN_SECTION = "final_figures";
const SUB_SECTIONS = {
  TXT_GROSS: "txt_gross_revenue",
  TOTAL_GROSS: "total_gross_revenue",
  ADJ_NET: "adj_net_gross",
} as const;

const BASE_TXT_GROSS_ROWS = [
  { title: "PLATINUM LIFT", valueOne: 0, rate: 0, total: 0, isCustom: false },
  { title: "Other", valueOne: 0, rate: 0, total: 0, isCustom: false },
];

const BASE_FACILITY_FEE_ROWS = [
  {
    title: "FACILITY FEE(S)",
    valueOne: 0,
    rate: "",
    total: 0,
    isCustom: false,
  },
  { title: "CHARITY", valueOne: 0, rate: 0, total: 0, isCustom: false },
  { title: "OTHER", valueOne: 0, rate: 0, total: 0, isCustom: false },
];

const BASE_TAX_AND_DEDUCTION_ROWS = [
  { title: "LESS TAX 1:", valueOne: 0, rate: "0%", total: 0, isCustom: false },
  { title: "LESS TAX2", valueOne: 0, rate: "0.00%", total: 0, isCustom: false },
  { title: "LESS OTHER", valueOne: 0, rate: "0", total: 0, isCustom: false },
  {
    title: "LESS PLATINUM FEE:",
    valueOne: 0,
    rate: "0",
    total: 0,
    isCustom: false,
  },
  { title: "LESS MISC:", valueOne: 0, rate: "0", total: 0, isCustom: false },
];

const FIELD_MAPPINGS = {
  txt_gross: {
    "PLATINUM LIFT": "platinum_lift",
    Other: "other",
  },
  facility_fee: {
    "FACILITY FEE(S)": "facility_fee",
    CHARITY: "charity",
    OTHER: "other",
  },
  tax_deduction: {
    "LESS TAX 1:": "tax1",
    "LESS TAX2": "tax2",
    "LESS OTHER": "other_hard",
    "LESS PLATINUM FEE:": "less_plat_fee",
    "LESS MISC:": "less_misc_fee",
  },
};

const BASE_FIELDS = {
  [SUB_SECTIONS.TXT_GROSS]: ["platinum_lift", "other"],
  [SUB_SECTIONS.TOTAL_GROSS]: ["facility_fee", "charity", "other"],
  [SUB_SECTIONS.ADJ_NET]: [
    "tax1",
    "tax2",
    "tax_percent",
    "tax_amount",
    "other_hard",
    "less_plat_fee",
    "less_misc_fee",
    "misc",
  ],
};

const SETTLEMENT_STRUCTURE = {
  [MAIN_SECTION]: {
    title: "FINAL FIGURES",
    subsections: {
      [SUB_SECTIONS.TXT_GROSS]: {
        title: "TXT GROSS REVENUE",
        fields: {
          tickets_gross: { isMoney: true, label: "TXT GROSS REVENUE" },
          platinum_lift: { isMoney: true, label: "PLATINUM LIFT" },
          other: { isMoney: true, label: "Other" },
        },
      },
      [SUB_SECTIONS.TOTAL_GROSS]: {
        title: "TOTAL GROSS REVENUE",
        fields: {
          facility_fee: { isMoney: true, label: "FACILITY FEE(S)" },
          charity: { isMoney: true, label: "CHARITY" },
          other: { isMoney: true, label: "OTHER" },
        },
      },
      [SUB_SECTIONS.ADJ_NET]: {
        title: "ADJ NET GROSS",
        fields: {
          tax1: { isMoney: true, label: "LESS TAX 1" },
          tax2: { isMoney: true, label: "LESS TAX2" },
          tax_percent: { isMoney: false, label: "Tax Percent", isRate: true },
          tax_amount: { isMoney: true, label: "Tax Amount" },
          other_hard: { isMoney: true, label: "LESS OTHER" },
          less_plat_fee: { isMoney: true, label: "LESS PLATINUM FEE" },
          less_misc_fee: { isMoney: true, label: "LESS MISC" },
          misc: { isMoney: true, label: "MISC" },
        },
      },
    },
  },
} as const;

const getSectionInfo = (fieldKey: string) => {
  for (const [mainSection, sectionData] of Object.entries(
    SETTLEMENT_STRUCTURE
  )) {
    for (const [subSection, subSectionData] of Object.entries(
      sectionData.subsections
    )) {
      if (subSectionData.fields[fieldKey]) {
        const fieldData = subSectionData.fields[fieldKey];
        return {
          mainSection,
          subSection,
          isMoney: fieldData.isMoney,
          isRate: fieldData.isRate || false,
          label: fieldData.label,
          found: true,
        };
      }
    }
  }
  return {
    mainSection: MAIN_SECTION,
    subSection: SUB_SECTIONS.TXT_GROSS,
    isMoney: true,
    isRate: false,
    label: fieldKey,
    found: false,
  };
};

export default function SettlementFinalFigures() {
  const { id } = useParams<{ id: string }>();
  const settlementComp = useSettlementComponent();
  const { settlementInputClassName } = settlementComp;
  const {
    loading,
    eventData: event,
    pageIndex,
    getOverrideFieldValue,
    handleOverride,
    deleteOverride,
    deleteOverrideById,
    overrides,
  } = useOverrides(id || "");

  const isReady = Boolean(id && !loading && event);

  const getFieldValues = (key: string, fallback: any = 0): any => {
    const { subSection, isRate } = getSectionInfo(key);
    const defaultFallback = isRate ? "0.00" : fallback;
    return getOverrideFieldValue({
      key,
      mainSection: MAIN_SECTION,
      subSection,
      fallback: defaultFallback,
    });
  };

  const updateRowValues = (
    rows: any[],
    fieldMapping: Record<string, string>
  ) => {
    return rows.map((row) => {
      let updated = { ...row };
      const normalizedTitle = normalizeFieldName(row.title);
      const mappingKey =
        fieldMapping[row.title] || fieldMapping[normalizedTitle];
      if (mappingKey) {
        const overrideData = getFieldValues(mappingKey);
        updated = { ...updated, ...overrideData };
        const info = getSectionInfo(mappingKey);
        updated.format = info.isMoney ? "money" : undefined;
      }
      if (fieldMapping[row.title] === "tax1") {
        const taxOverride = getFieldValues("tax_percent", "0.00");
        updated.rate = `${taxOverride.total}%`;
      }
      return updated;
    });
  };

  const getCustomRows = (subSection: string) => {
    return overrides
      .filter(
        (o) =>
          o.section_name === MAIN_SECTION &&
          o.sub_section_name === subSection &&
          !BASE_FIELDS[subSection].includes(
            normalizeFieldName(o.field_name.replace(/^__/, ""))
          )
      )
      .map((override) => {
        let jsonValue = {};
        try {
          jsonValue = JSON.parse(override.value);
        } catch (error) {
          console.error(error);
        }
        return {
          id: override.id,
          title: override.field_name.replace(/^__/, ""),
          valueOne: 0,
          rate: 0,
          ...jsonValue,
          isCustom: true,
          format: override.is_money ? "money" : undefined,
        };
      });
  };

  const txtGrossRowsWithCustom = useMemo(() => {
    if (!isReady) return [];
    const updatedBaseRows = updateRowValues(
      BASE_TXT_GROSS_ROWS,
      FIELD_MAPPINGS.txt_gross
    );
    const custom = getCustomRows(SUB_SECTIONS.TXT_GROSS);
    return [...updatedBaseRows, ...custom];
  }, [overrides, isReady]);

  const facilityFeeRowsWithCustom = useMemo(() => {
    if (!isReady) return [];
    const updatedBaseRows = updateRowValues(
      BASE_FACILITY_FEE_ROWS,
      FIELD_MAPPINGS.facility_fee
    );
    const custom = getCustomRows(SUB_SECTIONS.TOTAL_GROSS);
    return [...updatedBaseRows, ...custom];
  }, [overrides, isReady]);

  const taxAndDeductionRowsWithCustom = useMemo(() => {
    if (!isReady) return [];
    const updatedBaseRows = updateRowValues(
      BASE_TAX_AND_DEDUCTION_ROWS,
      FIELD_MAPPINGS.tax_deduction
    );
    const custom = getCustomRows(SUB_SECTIONS.ADJ_NET);
    return [...updatedBaseRows, ...custom];
  }, [overrides, isReady]);

  if (!isReady) return null;

  const handleBlur = async (
    rowIndex: number,
    accessor: string,
    value: any,
    row: any
  ) => {
    if (!row?.title) return;
    const { subSection, isMoney } = getSectionInfo(row.title);
    const resolvedValue = JSON.stringify({
      title: row.title,
      valueOne: row.valueOne,
      rate: row.rate,
      total: value,
    });
    await handleOverride({
      key: row.title,
      value: resolvedValue,
      isMoney,
      sectionName: MAIN_SECTION,
      subSectionName: subSection,
    });
  };

  const handleAddRow = async (
    subSection: keyof typeof SUB_SECTIONS,
    rowData: any
  ) => {
    if (!rowData?.title) return;
    return await handleOverride({
      key: rowData.title,
      value: JSON.stringify({
        title: rowData.title,
        valueOne: rowData.valueOne,
        rate: rowData.rate,
        total: rowData.total || 0,
      }),
      isMoney: true,
      sectionName: MAIN_SECTION,
      subSectionName: SUB_SECTIONS[subSection],
    });
  };

  const handleDeleteRow = async (row: any) => {
    if (!row?.isCustom) return false;
    return deleteOverrideById(row.id);
  };

  const columns = [
    {
      title: "Label",
      accessor: "title",
      addRowEditable: true,
      width: "31%",
      editable: (row: any) =>
        typeof row.title === "string" && row.isCustom === true,
    },
    {
      title: "Value One",
      accessor: "valueOne",
      addRowEditable: true,
      editable: true,
    },
    {
      title: "RATE",
      accessor: "rate",
      editable: (row: any) => {
        if (pageIndex !== "artist") return false;
        const allowedTitles = ["CHARITY", "OTHER", "LESS TAX 1:", "LESS TAX2"];
        return allowedTitles.includes(row.title.toUpperCase());
      },
      addRowEditable: true,
    },
    {
      title: "TOTAL",
      accessor: "total",
      textAlign: "right" as const,
      format: "money",
      editable: ["artist", "final"].includes(pageIndex),
    },
  ];

  const renderSubsectionHeader = (header: string, fallbackKey: string) => (
    <TableRow
      columns={[
        { key: "name", accessor: "name", textAlign: "left" },
        { key: "value", accessor: "value", textAlign: "right" },
      ]}
      rowData={{
        name: header + ":",
        value: amountDisplay(getFieldValues(fallbackKey).total),
      }}
      rowContainerClass="bg-blue-200"
    />
  );

  return (
    <div>
      <div className="border border-black mb-2">
        {renderSubsectionHeader("TXT GROSS REVENUE", "tickets_gross")}
        {txtGrossRowsWithCustom.map((row, index) => (
          <div key={index}>
            <TableRow
              columns={[
                {
                  key: "title",
                  accessor: "title",
                  textAlign: "left",
                  editable: row.isCustom,
                },
                { key: "valueOne", accessor: "valueOne", editable: true },
                {
                  key: "total",
                  accessor: "total",
                  textAlign: "right",
                  format: row.format,
                  editable: true,
                },
              ]}
              rowData={row}
              rowIndex={index}
              onBlur={(...args) => handleBlur(...args)}
              inputClassName={settlementInputClassName}
              onDelete={row.isCustom ? () => handleDeleteRow(row) : undefined}
              deleteConfirmation="modal"
              deleteIcons={row.isCustom}
            />
          </div>
        ))}
        <Table
          columns={columns}
          data={[]}
          enableAddRow
          onAddRow={(rowData) => handleAddRow("TXT_GROSS", rowData)}
          hideHeaders
          inputClassName={settlementInputClassName}
          showNoDataMessage={false}
        />
        {renderSubsectionHeader("TOTAL GROSS REVENUE", "total_revenue")}
        {facilityFeeRowsWithCustom.map((row, index) => (
          <div key={index}>
            <TableRow
              columns={[
                {
                  key: "title",
                  accessor: "title",
                  textAlign: "left",
                  editable: row.isCustom,
                },
                { key: "valueOne", accessor: "valueOne", editable: true },
                { key: "rate", accessor: "rate", editable: true },
                {
                  key: "total",
                  accessor: "total",
                  textAlign: "right",
                  format: row.format,
                  editable: true,
                },
              ]}
              rowData={row}
              rowIndex={index}
              onBlur={(...args) => handleBlur(...args)}
              inputClassName={settlementInputClassName}
              onDelete={row.isCustom ? () => handleDeleteRow(row) : undefined}
              deleteIcons={row.isCustom}
              deleteConfirmation="modal"
            />
          </div>
        ))}
        <Table
          columns={columns}
          data={[]}
          enableAddRow
          onAddRow={(rowData) => handleAddRow("TOTAL_GROSS", rowData)}
          hideHeaders
          inputClassName={settlementInputClassName}
          showNoDataMessage={false}
        />
        {renderSubsectionHeader("ADJ NET GROSS", "adjusted_gross")}
        {taxAndDeductionRowsWithCustom.map((row, index) => (
          <TableRow
            key={index}
            columns={[
              {
                key: "title",
                accessor: "title",
                textAlign: "left",
                editable: row.isCustom,
              },
              { key: "valueOne", accessor: "valueOne", editable: true },
              { key: "rate", accessor: "rate", editable: true },
              {
                key: "total",
                accessor: "total",
                textAlign: "right",
                format: row.format,
                editable: true,
              },
            ]}
            rowData={row}
            rowIndex={index}
            onBlur={(...args) => handleBlur(...args)}
            inputClassName={settlementInputClassName}
            onDelete={row.isCustom ? () => handleDeleteRow(row) : undefined}
            deleteIcons={row.isCustom}
          />
        ))}
        <Table
          columns={columns}
          data={[]}
          enableAddRow
          onAddRow={(rowData) => handleAddRow("ADJ_NET", rowData)}
          hideHeaders
          inputClassName={settlementInputClassName}
          showNoDataMessage={false}
        />
        <TableRow
          columns={[
            { key: "name", accessor: "name", textAlign: "left" },
            { key: "value", accessor: "value", textAlign: "right" },
          ]}
          rowData={{
            name: "NET GROSS RECEIPTS:",
            value: amountDisplay(getFieldValues("net_income").total),
          }}
          rowContainerClass="bg-blue-200"
        />
      </div>
    </div>
  );
}
