import React, { useState, useLayoutEffect } from "react";

interface RippleProps {
  duration?: number;
  color?: string;
  children?: React.ReactNode;
}

interface RippleItem {
  x: number;
  y: number;
  size: number;
}

const Ripple: React.FC<RippleProps> = ({
  duration = 850,
  color = "#fff",
  children,
}) => {
  const [rippleArray, setRippleArray] = useState<RippleItem[]>([]);

  const useDebouncedRippleCleanUp = (
    rippleCount: number,
    duration: number,
    cleanUpFunction: () => void
  ) => {
    useLayoutEffect(() => {
      let bounce: NodeJS.Timeout | null = null;
      if (rippleCount > 0) {
        if (bounce) clearTimeout(bounce);

        bounce = setTimeout(() => {
          cleanUpFunction();
          if (bounce) clearTimeout(bounce);
        }, duration * 4);
      }

      return () => {
        if (bounce) clearTimeout(bounce);
      };
    }, [rippleCount, duration, cleanUpFunction]);
  };

  useDebouncedRippleCleanUp(rippleArray.length, duration, () => {
    setRippleArray([]);
  });

  const addRipple = (event: React.MouseEvent<HTMLDivElement>) => {
    const rippleContainer = event.currentTarget.getBoundingClientRect();
    const size =
      rippleContainer.width > rippleContainer.height
        ? rippleContainer.width
        : rippleContainer.height;
    const x = event.pageX - rippleContainer.x - size / 2;
    const y = event.pageY - rippleContainer.y - size / 2;
    const newRipple = {
      x,
      y,
      size,
    };

    setRippleArray([...rippleArray, newRipple]);
  };

  return (
    <div
      className="absolute top-0 right-0 bottom-0 left-0"
      onMouseDown={addRipple}
      style={{
        ["--ripple-color" as any]: color,
        ["--ripple-duration" as any]: `${duration}ms`,
      }}
    >
      {children}

      {rippleArray.length > 0 &&
        rippleArray.map((ripple, index) => {
          return (
            <span
              key={`ripple-${index}`}
              className="absolute rounded-full opacity-75 animate-ripple"
              style={{
                top: ripple.y,
                left: ripple.x,
                width: ripple.size,
                height: ripple.size,
                backgroundColor: color,
                animationDuration: `${duration}ms`,
              }}
            />
          );
        })}

      <style>{`
        @keyframes ripple {
          to {
            opacity: 0;
            transform: scale(2);
          }
        }
        .animate-ripple {
          animation-name: ripple;
          transform: scale(0);
        }
      `}</style>
    </div>
  );
};

export default Ripple;
