import { AgGridReact } from "ag-grid-react";
import { useCallback, useEffect, useMemo, useState, useRef } from "react";

import { FIELD_UPDATE_ANIMATION_TIME } from "@/hooks/useGlowEffect";
import { useEventOfferStore } from "@/store/eventOfferStore";

const DealAgGrid = ({
  dealAmount,
  dealDescription,
  isLoading,
  isChanged,
  eventId,
  offerId,
  event,
}) => {
  const [flashField, setFlashField] = useState(false);
  const [notesValue, setNotesValue] = useState("");
  const gridRef = useRef(null);

  const {
    eventOfferForm,
    patchEventField,
    patchOfferField,
    initializeEventOfferForm,
  } = useEventOfferStore();

  useEffect(() => {
    if (event) {
      initializeEventOfferForm({
        deal_notes: event.deal_notes || "",
      });
      setNotesValue(event.deal_notes || "");
    }
  }, [event, eventId, initializeEventOfferForm]);

  useEffect(() => {
    setNotesValue(eventOfferForm.deal_notes || "");
  }, [eventOfferForm.deal_notes]);

  const rowData = useMemo(
    () => [
      {
        type: "THE DEAL",
        value: dealAmount ? dealAmount : "$0.00",
        isChanged,
      },
    ],
    [dealAmount, isChanged]
  );

  const handleNotesChange = useCallback((e) => {
    setNotesValue(e.target.value);
  }, []);

  const handleNotesBlur = useCallback(() => {
    if (eventId) {
      setFlashField(true);
      setTimeout(() => setFlashField(false), FIELD_UPDATE_ANIMATION_TIME);
      patchOfferField(eventId, "deal_notes", notesValue);
    }
  }, [eventId, notesValue, patchOfferField]);

  const columnDefs = [
    {
      headerName: "THE DEAL",
      field: "type",
      flex: 1,
      cellClass:
        "bg-gray-300 dark:bg-gray-100 font-bold text-xl pl-8 font-montserrat",
      editable: false,
    },
    {
      headerName: "Amount",
      flex: 1,
      field: "value",
      editable: false,
      cellClass: (params) =>
        `font-bold text-2xl text-end font-montserrat flex items-center h-full ${params.data.isChanged || flashField ? "glow-text" : ""
        }`,
    },
  ];

  const defaultColDef = {
    resizable: true,
    suppressMovable: true,
  };

  return (
    <div className="min-w-full border-4 border-gray-300">
      <div
        className="ag-theme-alpine"
        style={{ height: "auto", width: "100%" }}
      >
        <AgGridReact
          ref={gridRef}
          rowData={rowData}
          columnDefs={columnDefs}
          isLoading={isLoading}
          defaultColDef={defaultColDef}
          domLayout="autoHeight"
          headerHeight={0}
          rowHeight={35}
        />
      </div>

      {/* Custom Notes Section */}
      <div className="border-t-0 border-gray-300">
        <div className="bg-gray-300 py-0">
          <div className="text-base text-center py-0.5 px-2 font-montserrat">
            NOTES
          </div>
        </div>
        <div className="h-full">
          <textarea
            className={`text-sm w-full h-full font-montserrat bg-cave-white selection:bg-blue-900 
              selection:text-cave-white p-1 focus:outline-none focus:ring-2 focus:ring-blue-400
              ${flashField ? "glow-text" : ""}`}
            value={notesValue}
            onChange={handleNotesChange}
            onBlur={handleNotesBlur}
            style={{
              resize: "vertical",
              minHeight: "60px",
              whiteSpace: "pre-wrap",
            }}
          />
        </div>
      </div>

      <div className="border-t border-gray-300 text-center font-semibold p-2">
        <p className="my-4 font-montserrat">{dealDescription}</p>
      </div>
    </div>
  );
};

export default DealAgGrid;
