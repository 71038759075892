import React, { useState } from "react";
import { useNavigate } from "react-router-dom";

import { useQuery } from "@tanstack/react-query";
import { getVendorWireDetail } from "@/queries/vendors";
import Button from "@/components/Button";

const WireDetail = ({ vendorID, wireID }) => {
  const detail = useQuery(["vendor-wire-detail", wireID], () =>
    getVendorWireDetail(vendorID, wireID)
  );

  if (detail.isLoading) {
    return null;
  }

  return (
    <div className="p-5 border border-b-0 border-gray-200 dark:border-gray-700 dark:bg-[#181818]">
      <p className="mb-2 text-gray-500 dark:text-gray-400">
        <strong>{detail.data.description}</strong>
      </p>
      <p className="mb-2 text-gray-500 dark:text-gray-400">
        <strong>Bank Name</strong> {detail.data.bank_name}
      </p>
      <p className="mb-2 text-gray-500 dark:text-gray-400">
        <strong>Bank Address</strong> {detail.data.bank_address}
      </p>
      <p className="mb-2 text-gray-500 dark:text-gray-400">
        <strong>Account Number</strong> {detail.data.account_number}
      </p>
      <p className="mb-2 text-gray-500 dark:text-gray-400">
        <strong>Routing Number</strong> {detail.data.routing_number}
      </p>
      <p className="mb-2 text-gray-500 dark:text-gray-400">
        <strong>SWIFT</strong> {detail.data.swift}
      </p>
      <p className="mb-2 text-gray-500 dark:text-gray-400">
        <strong>IBAN</strong> {detail.data.iban}
      </p>
    </div>
  );
};

const WireInfoRow = (props) => {
  const { wireinfos, vendorID } = props;
  const [open, setOpen] = useState(false);

  const toggleOpen = () => {
    setOpen(!open);
  };

  return (
    <>
      <h2
        id="accordion-collapse-heading-{wireinfos.id}"
        onClick={() => toggleOpen()}
      >
        <button
          type="button"
          className="grid grid-cols-2 w-full p-5 font-medium text-left text-gray-500 Hover:text-blue-600 border border-gray-200 focus:ring-2 focus:ring-sky-200 dark:focus:ring-gray-800 dark:border-gray-700 dark:text-gray-400 hover:bg-gray-100 dark:hover:bg-gray-800"
          data-accordion-target="#accordion-collapse-body-1"
          aria-expanded="true"
          aria-controls="accordion-collapse-body-1"
        >
          <span>{wireinfos.description}</span>
          <span>{wireinfos.bank_name}</span>
        </button>
      </h2>
      {open && (
        <div
          id="accordion-collapse-body-1"
          aria-labelledby="accordion-collapse-heading-{wireinfos.id}"
        >
          <WireDetail vendorID={vendorID} wireID={wireinfos.id} />
        </div>
      )}
    </>
  );
};

const WireInfo = (props) => {
  const { wireinfos, itemType, addURL, editable, vendorID } = props;
  const navigate = useNavigate();

  if (wireinfos.isLoading) {
    return (
      <div className="text-center text-gray-700 dark:text-gray-300">
        Loading...
      </div>
    );
  }

  return (
    <>
      <div className="w-full flex justify-between items-center mb-4">
        <h3 className="text-xl font-semibold dark:text-gray-200">WireInfo</h3>
        <span>
          {editable && (
            <Button onClick={() => navigate(addURL)}>Add WireInfo</Button>
          )}
        </span>
      </div>
      <div id="accordion-collapse">
        {wireinfos.data.map((wireinfos) => (
          <WireInfoRow
            key={wireinfos.id}
            wireinfos={wireinfos}
            vendorID={vendorID}
          />
        ))}
        {wireinfos.data.length === 0 && (
          <div className="w-full p-5 font-medium text-left text-gray-500 border border-gray-200 text-center">
            <em>No wireinfos defined for this {itemType}</em>
          </div>
        )}
      </div>
    </>
  );
};

export default WireInfo;
