export const USER_ROLES = {
  ADMIN: "admin",
  ACCOUNTING_ADMIN: "accounting_admin",
  ACCOUNTANT: "accountant",
  ACCOUNTING: "accounting",
  ACCOUNTING_PLUS: "accounting_plus",
  LEGAL: "legal",
  BOOKING: "booking",
  TOURING: "touring",
  TOURING_ADMIN: "touring_admin",
  BUYER: "buyer",
  MARKETING_ADMIN: "marketing_admin",
  MARKETING: "marketing",
  MARKETER: "marketer",
  SPONSORSHIP: "sponsorship",
  TICKETING: "ticketing",
  PRODUCTION: "production",
  VENUE_OPERATION: "venue_operation",
  PROMOTER_REP: "promoter_rep",
  STAFF: "staff",
  HR: "hr",
  UPTOWN_MANAGEMENT: "uptown_management",
  STAFFING: "staffing",
  SECURITY_OPERATIONS: "security_operations",
  DEACTIVATED: "deactivated",
  UPTOWN_STAFF: "uptown_staff",
  TIER_ADMIN: "tier_admin",
  MAMMOTH_NW_ACCOUNTING: "mammoth_nw_accounting",
  UPTOWN_ACCOUNTING: "uptown_accounting",
  MANAGEMENT_ADMINISTRATION_NW: "management_administration_nw",
  BUYER_NW: "buyer_nw",
  ROSELAND_STAFF: "roseland_staff",
  ASSISTANT_BUYER: "assistant_buyer",
  MAMMOTH_NE_ADMIN: "mammoth_ne_admin",
  COTILLION_STAFF: "cotillion_staff",
  IT: "it",
  FINANCE_MANAGER: "finance_manager",
} as const;

export type UserRole = (typeof USER_ROLES)[keyof typeof USER_ROLES];

export const USER_ROLE_DISPLAY_NAMES: Record<UserRole, string> = {
  [USER_ROLES.ADMIN]: "Super Admin",
  [USER_ROLES.ACCOUNTING_ADMIN]: "Accounting Administration",
  [USER_ROLES.ACCOUNTING_PLUS]: "Accounting+",
  [USER_ROLES.ACCOUNTING]: "Accounting",
  [USER_ROLES.ACCOUNTANT]: "Accountant",
  [USER_ROLES.LEGAL]: "Legal",
  [USER_ROLES.BOOKING]: "Booking Administration",
  [USER_ROLES.TOURING]: "Touring",
  [USER_ROLES.TOURING_ADMIN]: "Touring Admin",
  [USER_ROLES.BUYER]: "Buyer",
  [USER_ROLES.MARKETING_ADMIN]: "Marketing Administration",
  [USER_ROLES.MARKETING]: "Marketing",
  [USER_ROLES.SPONSORSHIP]: "Sponsorship",
  [USER_ROLES.TICKETING]: "Ticketing",
  [USER_ROLES.PRODUCTION]: "Production",
  [USER_ROLES.VENUE_OPERATION]: "Venue Operations",
  [USER_ROLES.PROMOTER_REP]: "Promoter Rep",
  [USER_ROLES.STAFF]: "Staff",
  [USER_ROLES.HR]: "HR",
  [USER_ROLES.UPTOWN_MANAGEMENT]: "Uptown Management",
  [USER_ROLES.STAFFING]: "Staffing",
  [USER_ROLES.SECURITY_OPERATIONS]: "Security Operations",
  [USER_ROLES.DEACTIVATED]: "Deactivated",
  [USER_ROLES.UPTOWN_STAFF]: "Uptown Staff",
  [USER_ROLES.TIER_ADMIN]: "Tier Admin",
  [USER_ROLES.MAMMOTH_NW_ACCOUNTING]: "Mammoth NW Accounting",
  [USER_ROLES.UPTOWN_ACCOUNTING]: "Uptown Accounting",
  [USER_ROLES.MANAGEMENT_ADMINISTRATION_NW]: "Management Administration NW",
  [USER_ROLES.BUYER_NW]: "Buyer NW",
  [USER_ROLES.ROSELAND_STAFF]: "Roseland Staff",
  [USER_ROLES.ASSISTANT_BUYER]: "Assistant Buyer",
  [USER_ROLES.MAMMOTH_NE_ADMIN]: "Mammoth NE Admin",
  [USER_ROLES.COTILLION_STAFF]: "Cotillion Staff",
  [USER_ROLES.IT]: "IT",
  [USER_ROLES.FINANCE_MANAGER]: "Finance Manager",
};
