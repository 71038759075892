// Table.tsx

import React, {
  useState,
  useEffect,
  useRef,
  KeyboardEvent,
  useCallback,
  useMemo,
} from "react";
import { toast } from "react-toastify";
import {
  useDrag,
  useDrop,
  DragSourceMonitor,
  DropTargetMonitor,
} from "react-dnd";
import {
  CheckIcon,
  Bars3Icon as MenuIcon,
  XMarkIcon,
} from "@heroicons/react/24/outline";
import classNames from "classnames";
import MoneyInputComponent from "./Common/MoneyInput"; // Ensure this component accepts tabIndex
import { amountDisplay } from "@/utils/money";
import { debounce } from "@/helpers";
import { TrashIcon } from "@heroicons/react/24/solid";
import ConfirmModal from "./Common/ConfirmModal";

// Define the type for alignment options
export type AlignOption = "start" | "center" | "end" | "left" | "right";

// Define the Column interface
export interface Column {
  title?: string;
  accessor: string;
  key?: string;
  textAlign?: AlignOption;
  className?: string;
  editable?: boolean | ((row: any, col: Column) => boolean);
  addRowEditable?: boolean;
  render?: (data: any, row: Record<string, any>) => JSX.Element;
  onBlur?: (value: any, rowIndex: number, row: any, colKey?: string) => void;
  format?: "money" | "input" | "select" | "checkbox" | "actions";
  options?: { label: string; value: any }[]; // For select options
  width?: string; // e.g., "50px", "20%"
  placeholder?: string; // For configurable placeholders
  addRowPlaceholder?: string; // For configurable placeholders in add row
  addRender?: ({
    addRowData,
    setAddRowData,
    row,
    handleInputChange,
    col,
  }: {
    addRowData: Record<string, any>;
    setAddRowData: React.Dispatch<React.SetStateAction<Record<string, any>>>;
    row: Record<string, any>;
    handleInputChange: (field: string, value: any) => void;
    col: Column;
  }) => JSX.Element; // Custom render for add row
}

// Define the props for the TableRow component
interface TableRowProps {
  columns: Column[];
  rowData: Record<string, any>;
  rowIndex?: number;
  moveRow?: (dragIndex: number, hoverIndex: number) => void;
  onChange?: (rowIndex: number, accessor: string, value: any, row: any) => void;
  onBlur?: (rowIndex: number, accessor: string, value: any, row: any) => void;
  onDelete?: (rowIndex: number, rowData: Record<string, any>) => void;
  deleteIcons?: boolean;
  deleteRender?: (row: Record<string, any>) => JSX.Element;
  deleteConfirmation?: "modal" | "inline";
  deleteClassName?: string;
  deleteConfig?: Record<string, any>;
  orderable?: boolean;
  tableId?: string;
  className?: string;
  rowContainerClass?: string;
  inputClassName?: string;
  unstyled?: boolean;
  onDragEnd?: () => void;
  isAddRow?: boolean;
  onAddRowChange?: (accessor: string, value: any) => void;
  isPDF?: boolean;
}

// Define the props for the Table component
interface TableProps {
  className?: string;
  textCenter?: boolean;
  tableTitle?: string;
  columns: Column[];
  data: Record<string, any>[];
  totalsRow?: {
    title: string;
    textAlign?: AlignOption;
    values: (string | number)[];
  };
  hideHeaders?: boolean;
  onChange?: (rowIndex: number, accessor: string, value: any, row: any) => void;
  onCellBlur?: (
    rowIndex: number,
    accessor: string,
    value: any,
    row: any
  ) => void;
  showNoDataMessage?: boolean;
  orderable?: boolean;
  reorderablePosition?: "first" | "last";
  onReorder?: (newData: Record<string, any>[]) => void;
  onDelete?: (rowIndex: number, rowData: Record<string, any>) => void;
  deleteIcons?: boolean;
  deleteClassName?: string;
  deleteConfig?: Record<string, any>;
  deleteRender?: (row: Record<string, any>) => JSX.Element;
  deleteConfirmation?: "modal" | "inline";
  headerClassName?: string;
  rowClassName?: string | ((rowIndex: number) => string);
  rowContainerClass?: string | ((rowIndex: number) => string);
  inputClassName?: string | ((rowIndex: number) => string);
  unstyled?: boolean;
  enableAddRow?: boolean;
  onAddRow?: (newRow: Record<string, any>) => void;
  addRowTitle?: string;
  addRowButtonTitle?: string;
  isPDF?: boolean;
}

/**
 * Item Types for react-dnd
 */
const ItemTypes = {
  ROW: "row",
};

/**
 * Helper function to map textAlign to Tailwind CSS classes
 */
const textAlignClass = (align: AlignOption | undefined) => {
  switch (align) {
    case "left":
    case "start":
      return "text-left";
    case "center":
      return "text-center";
    case "right":
    case "end":
      return "text-right";
    default:
      return "text-left";
  }
};

/**
 * TableRow Component with Drag-and-Drop and Delete Functionality
 */
const TableRow: React.FC<TableRowProps> = React.memo(
  ({
    columns,
    rowData,
    rowIndex,
    moveRow,
    onChange,
    onBlur,
    onDelete,
    orderable = false,
    tableId,
    className,
    rowContainerClass,
    inputClassName,
    unstyled = false,
    onDragEnd,
    isAddRow = false,
    onAddRowChange,
    deleteRender,
    deleteIcons = false,
    deleteClassName,
    deleteConfig = {},
    deleteConfirmation,
  }) => {
    const ref = useRef<HTMLDivElement>(null);

    const [{ isDragging }, drag] = useDrag({
      type: ItemTypes.ROW,
      item: { index: rowIndex },
      canDrag: orderable,
      collect: (monitor: DragSourceMonitor) => ({
        isDragging: monitor.isDragging(),
      }),
      end: (item, monitor) => {
        if (monitor.didDrop()) {
          onDragEnd && onDragEnd();
        }
      },
    });

    const [{ isOver }, drop] = useDrop({
      accept: ItemTypes.ROW,
      hover: (item: { index: number }, monitor: DropTargetMonitor) => {
        if (!ref.current || item.index === rowIndex) return;
        moveRow && moveRow(item.index, rowIndex);
        item.index = rowIndex;
      },
      collect: (monitor: DropTargetMonitor) => ({
        isOver: monitor.isOver(),
      }),
    });

    drag(drop(ref));

    const [localRowData, setLocalRowData] = useState(() => {
      if (isAddRow) return { ...rowData };
      const initialData = { ...rowData };
      columns.forEach((col) => {
        if (initialData[col.accessor] === undefined) {
          if (col.format === "money" && col.editable) {
            initialData[col.accessor] = 0;
          } else if (col.format === "checkbox") {
            initialData[col.accessor] = false;
          } else {
            initialData[col.accessor] = "";
          }
        }
      });
      return initialData;
    });

    const [isDeleting, setIsDeleting] = useState(false);
    const [deleteModalOpen, setDeleteModalOpen] = useState(false);

    useEffect(() => {
      if (rowIndex !== -1 && !isAddRow) {
        setLocalRowData((prevData) => ({ ...prevData, ...rowData }));
      }
    }, [rowData, rowIndex, isAddRow]);

    const handleInputChange = useCallback(
      (field: string, value: any, isBlurrable = true) => {
        const updatedData = { ...localRowData, [field]: value };
        setLocalRowData(updatedData);
        if (isAddRow && onAddRowChange) {
          onAddRowChange(field, value);
        } else if (!isAddRow && (onChange || onBlur)) {
          if (onBlur && !isBlurrable) {
            onBlur(rowIndex, field, value, updatedData);
          } else {
            onChange && onChange(rowIndex, field, value, updatedData);
          }
        }
      },
      [localRowData, onChange, rowIndex, isAddRow, onAddRowChange, onBlur]
    );

    const handleInputBlur = useCallback(
      (col: Column, field: string, value: any) => {
        if (!isAddRow) {
          col.onBlur && col.onBlur(value, rowIndex, localRowData, field);
          onBlur && onBlur(rowIndex, field, value, localRowData);
        }
      },
      [onBlur, rowIndex, localRowData, isAddRow]
    );

    const handleKeyDown = useCallback(
      (
        e: KeyboardEvent<HTMLDivElement>,
        currentRowIndex: number,
        currentColIndex: number
      ) => {
        const activeElement = document.activeElement;
        if (
          activeElement &&
          (activeElement.tagName === "INPUT" ||
            activeElement.tagName === "TEXTAREA" ||
            activeElement.tagName === "SELECT" ||
            activeElement.getAttribute("contenteditable") === "true")
        ) {
          return;
        }
        const totalRows = document.querySelectorAll(
          `#${tableId} [data-row-index]`
        ).length;
        const totalCols = columns.length;
        let nextElement: HTMLElement | null = null;
        if (e.shiftKey && orderable) {
          if (e.key === "ArrowUp" && currentRowIndex > 0) {
            e.preventDefault();
            moveRow && moveRow(currentRowIndex, currentRowIndex - 1);
            return;
          } else if (e.key === "ArrowDown" && currentRowIndex < totalRows - 1) {
            e.preventDefault();
            moveRow && moveRow(currentRowIndex, currentRowIndex + 1);
            return;
          }
        } else if (e.key === "ArrowUp") {
          e.preventDefault();
          const prevRowIndex = currentRowIndex - 1;
          if (prevRowIndex >= 0) {
            nextElement = document.querySelector(
              `#${tableId} [data-row-index='${prevRowIndex}'] [data-col-index='${currentColIndex}']`
            ) as HTMLElement;
          }
        } else if (e.key === "ArrowDown") {
          e.preventDefault();
          const nextRowIndex = currentRowIndex + 1;
          if (nextRowIndex < totalRows) {
            nextElement = document.querySelector(
              `#${tableId} [data-row-index='${nextRowIndex}'] [data-col-index='${currentColIndex}']`
            ) as HTMLElement;
          }
        } else if (e.key === "ArrowLeft") {
          e.preventDefault();
          const prevColIndex = currentColIndex - 1;
          if (prevColIndex >= 0) {
            nextElement = document.querySelector(
              `#${tableId} [data-row-index='${currentRowIndex}'] [data-col-index='${prevColIndex}']`
            ) as HTMLElement;
          }
        } else if (e.key === "ArrowRight") {
          e.preventDefault();
          const nextColIndex = currentColIndex + 1;
          if (nextColIndex < totalCols) {
            nextElement = document.querySelector(
              `#${tableId} [data-row-index='${currentRowIndex}'] [data-col-index='${nextColIndex}']`
            ) as HTMLElement;
          }
        } else if (isPrintableKey(e) && columns[currentColIndex].editable) {
          e.preventDefault();
          const inputElement = document.querySelector(
            `#${tableId}-row-${currentRowIndex} [data-input-index='${currentColIndex}']`
          ) as HTMLInputElement | HTMLSelectElement;
          if (inputElement) {
            inputElement.focus();
            const char = e.key;
            if (
              inputElement instanceof HTMLInputElement ||
              inputElement instanceof HTMLSelectElement
            ) {
              const newValue = char;
              handleInputChange(columns[currentColIndex].accessor, newValue);
              setTimeout(() => {
                inputElement.setSelectionRange(1, 1);
              }, 0);
            }
          }
          return;
        }
        nextElement?.focus();
      },
      [columns, handleInputChange, tableId, orderable, moveRow]
    );

    const isPrintableKey = (e: KeyboardEvent) => {
      return (
        e.key.length === 1 &&
        !e.ctrlKey &&
        !e.metaKey &&
        !e.altKey &&
        !e.key.startsWith("Arrow") &&
        e.key !== "Enter"
      );
    };

    const handleDelete = useCallback(() => {
      console.log("onDelete", onDelete);
      if (onDelete) {
        onDelete(rowIndex, rowData);
      }
      setIsDeleting(false);
      setDeleteModalOpen(false);
    }, [onDelete, rowIndex, rowData]);

    const colPlaceholder = (col: Column) => {
      if (isAddRow && col.addRowPlaceholder) {
        return col.addRowPlaceholder ?? "";
      }
      return col.placeholder || "";
    };

    const renderedCells = columns.map((col, colIndex) => {
      const alignmentClass = textAlignClass(col.textAlign);
      if (col.accessor === "reorder" && !isAddRow) {
        return (
          <div
            key={`${col.accessor}-${colIndex}`}
            className={classNames(
              col.className,
              alignmentClass,
              "flex justify-center"
            )}
            data-col-index={colIndex}
            tabIndex={-1}
          >
            {orderable && (
              <MenuIcon
                className="h-4 w-4 cursor-move text-gray-500"
                aria-label="Drag to reorder"
              />
            )}
          </div>
        );
      }
      if (col.accessor === "delete" && !isAddRow) {
        return (
          <div
            key={`${col.accessor}-${colIndex}`}
            className={classNames(
              col.className,
              alignmentClass,
              "flex justify-center"
            )}
            data-col-index={colIndex}
          >
            {deleteModalOpen && (
              <ConfirmModal
                message="Are you sure you want to delete this row?"
                onConfirm={handleDelete}
                onCancel={() => setDeleteModalOpen(false)}
                show
              />
            )}
            {!isDeleting ? (
              <button
                onClick={() => {
                  if (deleteConfirmation === "modal") {
                    setDeleteModalOpen(true);
                  } else {
                    setIsDeleting(true);
                  }
                }}
                className="bg-red-400 hover:bg-red-600 text-cave-white py-1 px-2 text-xs rounded"
                aria-label="Delete Row"
              >
                {deleteIcons ? (
                  <TrashIcon
                    className={classNames(
                      "text-cave-white h-5 w-5",
                      deleteClassName
                    )}
                  />
                ) : (
                  "Delete"
                )}
              </button>
            ) : (
              <div>
                <div className="flex space-x-2">
                  <button
                    onClick={handleDelete}
                    className="bg-red-400 hover:bg-red-600 text-cave-white py-1 px-2 text-xs rounded"
                    aria-label="Confirm Delete"
                  >
                    {deleteIcons ? (
                      <CheckIcon className="h-5 w-5" />
                    ) : (
                      "Confirm"
                    )}
                  </button>
                  <button
                    onClick={() => setIsDeleting(false)}
                    className="bg-gray-400 hover:bg-gray-600 text-cave-white py-1 px-2 text-xs rounded"
                    aria-label="Cancel Delete"
                  >
                    {deleteIcons ? <XMarkIcon className="h-5 w-5" /> : "Cancel"}
                  </button>
                </div>
              </div>
            )}
          </div>
        );
      }
      let colContent: JSX.Element;
      if (col.addRender && isAddRow) {
        colContent = col.addRender({
          addRowData: localRowData,
          setAddRowData: setLocalRowData,
          row: rowData,
          handleInputChange,
          col,
        });
      } else if (col.render) {
        colContent = col.render(rowData[col.accessor], rowData);
      } else if (
        (col.editable !== false && col.editable !== undefined) ||
        (isAddRow && col.addRowEditable)
      ) {
        switch (col.format) {
          case "money":
            colContent = (
              <MoneyInputComponent
                value={localRowData[col.accessor] ?? ""}
                onChange={(e) =>
                  handleInputChange(col.accessor, e.target.value)
                }
                onBlur={(e) =>
                  handleInputBlur(col, col.accessor, e.target.value)
                }
                className={classNames(
                  !unstyled && "w-full bg-input-blue text-black",
                  alignmentClass,
                  inputClassName
                )}
                placeholder={colPlaceholder(col)}
                data-testid={
                  col.accessor === "name" ? "manifest-name-input" : undefined
                }
                data-input-index={colIndex}
                tabIndex={-1}
              />
            );
            break;
          case "select":
            if (col.options) {
              colContent = (
                <select
                  value={localRowData[col.accessor] ?? ""}
                  onChange={(e) =>
                    handleInputChange(col.accessor, e.target.value, false)
                  }
                  onBlur={(e) =>
                    handleInputBlur(col, col.accessor, e.target.value)
                  }
                  className={classNames(
                    !unstyled && "w-full bg-input-blue text-black",
                    alignmentClass,
                    inputClassName
                  )}
                  placeholder={colPlaceholder(col)}
                  data-input-index={colIndex}
                  tabIndex={-1}
                >
                  <option value="" disabled>
                    {colPlaceholder(col) || "Select..."}
                  </option>
                  {col.options.map((option) => (
                    <option key={option.value} value={option.value}>
                      {option.label}
                    </option>
                  ))}
                </select>
              );
            } else {
              colContent = (
                <span className={alignmentClass}>
                  {col.format === "money"
                    ? amountDisplay(localRowData[col.accessor])
                    : localRowData[col.accessor]}
                </span>
              );
            }
            break;
          case "checkbox":
            colContent = (
              <input
                type="checkbox"
                checked={!!localRowData[col.accessor]}
                onChange={(e) =>
                  handleInputChange(col.accessor, e.target.checked, false)
                }
                onBlur={(e) =>
                  handleInputBlur(col, col.accessor, e.target.checked)
                }
                data-input-index={colIndex}
                className={classNames(alignmentClass, inputClassName)}
                tabIndex={-1}
              />
            );
            break;
          default:
            colContent = (
              <input
                type="text"
                value={localRowData[col.accessor] ?? ""}
                onChange={(e) =>
                  handleInputChange(col.accessor, e.target.value)
                }
                onBlur={(e) =>
                  handleInputBlur(col, col.accessor, e.target.value)
                }
                className={classNames(
                  !unstyled && "w-full bg-input-blue text-black",
                  alignmentClass,
                  inputClassName
                )}
                placeholder={colPlaceholder(col)}
                data-input-index={colIndex}
                tabIndex={-1}
              />
            );
        }
      } else {
        colContent = (
          <span
            className={classNames(alignmentClass, {
              "opacity-50": isDeleting,
            })}
          >
            {col.format === "money"
              ? amountDisplay(localRowData[col.accessor])
              : localRowData[col.accessor]}
          </span>
        );
      }
      return (
        <div
          key={`${col.accessor}-${colIndex}`}
          className={classNames(col.className, alignmentClass)}
          data-col-index={colIndex}
          tabIndex={0}
          onKeyDown={(e) => handleKeyDown(e, rowIndex, colIndex)}
        >
          {colContent}
        </div>
      );
    });

    // Only show the extra delete cell if onDelete is defined and the row is custom.
    const extraDeleteCell =
      onDelete && !isAddRow ? (
        <div
          key="delete-cell"
          className={classNames("flex justify-center")}
          data-col-index={columns.length}
        >
          <button
            onClick={() => {
              if (deleteConfirmation === "modal") {
                setDeleteModalOpen(true);
              } else {
                setIsDeleting(true);
              }
            }}
            className="bg-red-400 hover:bg-red-600 text-cave-white py-1 px-2 text-xs rounded"
            aria-label="Delete Row"
          >
            {deleteIcons ? (
              <TrashIcon className="text-cave-white h-5 w-5" />
            ) : (
              "Delete"
            )}
          </button>
        </div>
      ) : null;

    // Compute grid template columns including an extra column if extraDeleteCell is rendered.
    const computedGridTemplateColumns = useMemo(() => {
      let template = columns.map((col) => col.width || "1fr").join(" ");
      // if (extraDeleteCell) {
      //   template += " " + (deleteIcons ? "35px" : "150px");
      // }
      return template;
    }, [columns, extraDeleteCell, deleteIcons]);

    return (
      <div
        ref={ref}
        className={classNames(
          "grid items-center",
          { "opacity-50": isDragging, "bg-gray-100": isOver },
          className,
          rowContainerClass
        )}
        style={{ gridTemplateColumns: computedGridTemplateColumns }}
        data-row-index={rowIndex}
        id={`${tableId}-row-${rowIndex}`}
      >
        {renderedCells}
        {/* {extraDeleteCell} */}
        {deleteModalOpen && (
          <ConfirmModal
            message="Are you sure you want to delete this row?"
            onConfirm={handleDelete}
            onCancel={() => setDeleteModalOpen(false)}
            show
          />
        )}
      </div>
    );
  }
);

TableRow.displayName = "TableRow";

/**
 * Table Component with Drag-and-Drop and Delete Functionality
 */
const Table: React.FC<TableProps> = ({
  className,
  textCenter,
  tableTitle,
  columns,
  data,
  totalsRow,
  hideHeaders = false,
  onChange,
  onCellBlur,
  showNoDataMessage = true,
  orderable = false,
  reorderablePosition = "first",
  onReorder,
  onDelete,
  headerClassName,
  rowClassName,
  rowContainerClass,
  inputClassName,
  unstyled,
  enableAddRow = false,
  onAddRow,
  addRowTitle = "",
  addRowButtonTitle,
  deleteIcons = false,
  deleteRender,
  deleteClassName = "",
  deleteConfig = {},
  deleteConfirmation = "inline",
  isPDF = false,
}) => {
  const [localData, setLocalData] = useState(data);
  const [isAdding, setIsAdding] = useState(false);
  const [addRowData, setAddRowData] = useState<Record<string, any>>({});
  const tableId = useRef(
    `table-${Math.random().toString(36).substr(2, 9)}`
  ).current;

  const preparedColumns = useMemo(() => {
    let updatedColumns = [...columns];
    if (orderable && !isPDF) {
      const reorderColumn: Column = {
        accessor: "reorder",
        title: "",
        width: "40px",
        editable: false,
      };
      if (reorderablePosition === "first") {
        updatedColumns = [reorderColumn, ...updatedColumns];
      } else {
        updatedColumns.push(reorderColumn);
      }
    }
    if (onDelete && !isPDF) {
      const deleteColumn: Column = {
        accessor: "delete",
        title: "",
        width: deleteIcons ? "35px" : "150px",
        editable: false,
        className: deleteClassName,
        ...deleteConfig,
      };
      updatedColumns.push(deleteColumn);
    }
    return updatedColumns;
  }, [columns, orderable, reorderablePosition, onDelete, isPDF, deleteIcons]);

  const gridTemplateColumns = useMemo(() => {
    return preparedColumns.map((col) => col.width || "1fr").join(" ");
  }, [preparedColumns]);

  useEffect(() => {
    setLocalData(data);
  }, [data]);

  useEffect(() => {
    if (isAdding) {
      setAddRowData((prevData) => {
        const initialAddRowData = preparedColumns.reduce(
          (acc, col) => {
            if (col.accessor !== "reorder" && col.accessor !== "delete") {
              if (prevData[col.accessor] !== undefined) {
                acc[col.accessor] = prevData[col.accessor];
              } else {
                if (col.format === "money") {
                  acc[col.accessor] = "";
                } else if (col.format === "checkbox") {
                  acc[col.accessor] = false;
                } else {
                  acc[col.accessor] = "";
                }
              }
            }
            return acc;
          },
          {} as Record<string, any>
        );
        return initialAddRowData;
      });
    }
  }, [isAdding, preparedColumns]);

  const moveRow = useCallback((dragIndex: number, hoverIndex: number) => {
    setLocalData((prevData) => {
      const newData = [...prevData];
      const [removed] = newData.splice(dragIndex, 1);
      newData.splice(hoverIndex, 0, removed);
      return newData;
    });
  }, []);

  const debouncedOnChange = useMemo(
    () =>
      debounce((rowIndex: number, accessor: string, value: any, row: any) => {
        if (onChange) {
          onChange(rowIndex, accessor, value, row);
        }
      }, 300),
    [onChange]
  );

  const convertToCents = (input: string) => {
    const sanitized = input.toString().replace(/[^\d.-]/g, "");
    const convert = Math.round(parseFloat(sanitized) * 100);
    return isNaN(convert) ? 0 : convert;
  };

  const handleSaveAddRow = useCallback(async () => {
    if (!onAddRow) return;
    const processedData: Record<string, any> = {};
    preparedColumns.forEach((col) => {
      let value = addRowData[col.accessor];
      if (col.format === "money") {
        processedData[col.accessor] = convertToCents(value);
      } else if (col.accessor === "qty" || col.accessor === "comps") {
        const numValue = parseInt(String(value), 10);
        processedData[col.accessor] = isNaN(numValue) ? 0 : numValue;
      } else {
        processedData[col.accessor] = value;
      }
    });
    try {
      const result = await onAddRow(processedData, setIsAdding);
      if (result.success) {
        setIsAdding(false);
        setAddRowData({});
      } else if (result.preserveRow) {
        setAddRowData(result.preserveRow);
      }
    } catch (error) {
      console.error("Error adding row:", error);
      setAddRowData(processedData);
    }
  }, [onAddRow, addRowData, preparedColumns, convertToCents]);

  const handleCancelAddRow = useCallback(() => {
    setIsAdding(false);
    setAddRowData({});
  }, []);

  const displayedData = useMemo(() => {
    if (isAdding) {
      return [...localData, { isNew: true, ...addRowData }];
    }
    return localData;
  }, [localData, isAdding, addRowData]);

  const handleCellChange = useCallback(
    (
      rowIndex: number,
      accessor: string,
      value: any,
      row: Record<string, any>
    ) => {
      setLocalData((prevData) => {
        const newData = [...prevData];
        newData[rowIndex][accessor] = value;
        return newData;
      });
      debouncedOnChange(rowIndex, accessor, value, row);
    },
    [debouncedOnChange]
  );

  const handleCellBlur = useCallback(
    (
      rowIndex: number,
      accessor: string,
      value: any,
      row: Record<string, any>
    ) => {
      if (onCellBlur) {
        onCellBlur(rowIndex, accessor, value, row);
      }
    },
    [onCellBlur]
  );

  const handleDragEnd = useCallback(() => {
    if (onReorder) {
      onReorder(localData);
    }
  }, [onReorder, localData]);

  const validateNumericField = useCallback((value: any) => {
    if (value === "") return "";
    const parsed = parseInt(value, 10);
    return isNaN(parsed) ? 0 : parsed;
  }, []);

  const validateMoneyField = useCallback((value: any) => {
    if (value === "") return "";
    const parsed = parseFloat(value);
    return isNaN(parsed) ? 0 : parsed;
  }, []);

  const validateTextField = useCallback((value: any, prevValue: any) => {
    return value || prevValue || "";
  }, []);

  const debouncedSetAddRowData = useMemo(
    () =>
      debounce((newData: Record<string, any>) => {
        setAddRowData(newData);
      }, 300),
    []
  );

  const handleAddRowChange = useCallback(
    (accessor: string, value: any) => {
      setAddRowData((prevData) => {
        try {
          let newValue = value;
          if (accessor === "qty" || accessor === "comps") {
            if (value !== "" && !/^\d*$/.test(value)) {
              throw new Error(`${accessor} must be a valid number`);
            }
            newValue = value === "" ? "" : validateNumericField(value);
          } else if (accessor === "price" || accessor === "ticket_fees") {
            if (value !== "" && !/^\d*\.?\d*$/.test(value)) {
              throw new Error(`${accessor} must be a valid monetary amount`);
            }
            newValue = value === "" ? "" : validateMoneyField(value);
          }
          return { ...prevData, [accessor]: newValue };
        } catch (error) {
          console.error(`Validation error for ${accessor}:`, error);
          toast.error(error instanceof Error ? error.message : "Invalid input");
          return prevData;
        }
      });
    },
    [validateNumericField, validateMoneyField, setAddRowData]
  );

  return (
    <div className="w-full overflow-x-auto">
      <div
        className={classNames(
          "font-montserrat overflow-x-auto",
          { "text-center": textCenter },
          className
        )}
        id={tableId}
      >
        {tableTitle && (
          <h2 className="text-center font-bold text-blue-600 border border-black mb-4">
            {tableTitle}
          </h2>
        )}
        {!hideHeaders && (
          <div
            className={classNames("grid font-bold p-2", headerClassName)}
            style={{
              gridTemplateColumns,
            }}
          >
            {preparedColumns.map((col, colIndex) => {
              const alignmentClass = textAlignClass(col.textAlign);
              if (col.accessor === "reorder" || col.accessor === "delete") {
                return (
                  <div
                    key={`${col.accessor}-${colIndex}`}
                    className={classNames(
                      col.className,
                      alignmentClass,
                      "flex justify-center"
                    )}
                    data-col-index={colIndex}
                    tabIndex={-1}
                  ></div>
                );
              }
              return (
                <div
                  key={`${col.accessor}-${colIndex}`}
                  className={classNames(
                    col.className,
                    alignmentClass,
                    col.format === "actions" && "flex justify-center"
                  )}
                  data-col-index={colIndex}
                >
                  {col.title}
                </div>
              );
            })}
          </div>
        )}
        <div>
          {displayedData.length === 0
            ? showNoDataMessage && (
                <span className="inline-block font-medium px-2">
                  No Data Found.
                </span>
              )
            : displayedData?.map(
                (row, rowIndex) =>
                  row && (
                    <TableRow
                      key={row.id || `row-${rowIndex}`}
                      columns={preparedColumns}
                      rowData={row}
                      rowIndex={rowIndex}
                      moveRow={orderable ? moveRow : undefined}
                      onChange={handleCellChange}
                      onBlur={row.isNew ? undefined : handleCellBlur}
                      onDelete={onDelete}
                      orderable={orderable}
                      tableId={tableId}
                      deleteIcons={deleteIcons}
                      deleteRender={deleteRender}
                      deleteClassName={deleteClassName}
                      deleteConfig={deleteConfig}
                      deleteConfirmation={deleteConfirmation}
                      className={
                        typeof rowClassName === "function"
                          ? rowClassName(rowIndex)
                          : rowClassName
                      }
                      rowContainerClass={
                        typeof rowContainerClass === "function"
                          ? rowContainerClass(rowIndex)
                          : rowContainerClass
                      }
                      inputClassName={
                        typeof inputClassName === "function"
                          ? inputClassName(rowIndex)
                          : inputClassName
                      }
                      unstyled={unstyled}
                      onDragEnd={handleDragEnd}
                      isAddRow={!!row?.isNew}
                      onAddRowChange={
                        row.isNew ? handleAddRowChange : undefined
                      }
                      isPDF={isPDF}
                    />
                  )
              )}
        </div>
        {totalsRow && (
          <div
            className={classNames(
              "grid font-bold p-2 bg-gray-200",
              textAlignClass(totalsRow.textAlign)
            )}
            style={{
              gridTemplateColumns,
            }}
          >
            <span className="font-bold">{totalsRow.title}</span>
            {totalsRow.values.map((value, index) => (
              <span key={index} className="font-bold">
                {value}
              </span>
            ))}
          </div>
        )}
        {enableAddRow && !isAdding && !isPDF && (
          <button
            onClick={() => setIsAdding(true)}
            className="bg-gray-400 text-white py-1 px-2 text-sm rounded mt-1.5 duration-200 ease-in-out hover:bg-gray-500"
            aria-label="Add Row"
          >
            {addRowButtonTitle || "Add Row"}
          </button>
        )}
        {enableAddRow && isAdding && !isPDF && (
          <div className="flex space-x-2 mt-2">
            <button
              onClick={handleSaveAddRow}
              className="bg-blue-500 hover:bg-blue-700 text-white px-3 py-1 rounded duration-200 ease-in-out"
              aria-label="Save New Row"
            >
              Save
            </button>
            <button
              onClick={handleCancelAddRow}
              className="bg-gray-300 hover:bg-gray-400 text-gray-800 px-1.5 py-1 rounded duration-200 ease-in-out"
              aria-label="Cancel Adding Row"
            >
              Cancel
            </button>
          </div>
        )}
      </div>
    </div>
  );
};

export { Table, TableRow };
