import React, { useEffect, useRef, useState } from "react";
import {
  FiMaximize2,
  FiMinimize2,
  FiChevronDown,
  FiChevronUp,
} from "react-icons/fi";

import OfferProjections from "./OfferProjections";
import { FIELD_UPDATE_ANIMATION_TIME } from "../views/events/EventEditOffer";
import { useEventOfferStore } from "@/store/eventOfferStore";
import { amountDisplay, amountStrToInt } from "@/utils/money";

export const WalkoutPot = ({
  dealHeader,
  offer_type,
  the_deal_amount,
  split_point,
  promoter_profit,
  walkout_potential,
  showHeader,
  toggleHeader,
  manifests,
  eventId,
  offerId,
}) => {
  const walkoutPotentialRef = useRef(walkout_potential);
  const [isChanged, setIsChanged] = useState(false);
  const [showProjections, setShowProjections] = useState(false);
  useEffect(() => {
    if (walkoutPotentialRef.current !== walkout_potential) {
      setIsChanged(true);
      walkoutPotentialRef.current = walkout_potential;
    }
  }, [walkout_potential]);

  useEffect(() => {
    if (isChanged) {
      setTimeout(() => {
        setIsChanged(false);
      }, FIELD_UPDATE_ANIMATION_TIME);
    }
  }, [isChanged]);

  return (
    <div
      className={`rounded overflow-hidden z-[10001] fixed right-4 ${showHeader ? "top-2" : ""
        } ${!isChanged ? "ring ring-ku-red/50" : ""
        } w-[430px] min-w-[430px] max-w-[430px] max-h-[90vh]`}
    >
      <div
        className={`bg-ku-blue p-2 shadow-md ${isChanged ? "glow-text-gradient-border" : ""
          }`}
      >
        <div className="flex items-center">
          <button
            className="p-2 rounded-full bg-gray-200 focus:outline-none"
            onClick={toggleHeader}
          >
            {showHeader ? (
              <FiMinimize2 className="text-gray-600" size={12} />
            ) : (
              <FiMaximize2 className="text-gray-600" size={12} />
            )}
          </button>
          <div className="pl-2 w-full items-end">
            {dealHeader !== 0 && (
              <p
                className={`pl-2 text-right font-mono text-lg font-semibold text-ku-yellow whitespace-nowrap ${isChanged ? "glow-text-mid" : ""
                  }`}
              >
                {dealHeader}
              </p>
            )}
            <p
              className={`pl-2 text-right font-mono text-lg font-semibold text-ku-yellow whitespace-nowrap ${isChanged ? "glow-text-mid" : ""
                }`}
            >
              Walkout Potential: {amountDisplay(walkout_potential)}
            </p>

            {promoter_profit !== 0 && (
              <>
                <p
                  className={`pl-2 text-right font-mono text-lg font-semibold text-ku-yellow whitespace-nowrap ${isChanged ? "glow-text-mid" : ""
                    }`}
                >
                  Promoter Profit: {amountDisplay(promoter_profit)}
                </p>
              </>
            )}

            {offer_type === "vs" ? (
              <p
                className={`pl-2 text-right font-mono text-lg font-semibold text-ku-yellow whitespace-nowrap ${isChanged ? "glow-text-mid" : ""
                  }`}
              >
                Net Expenses: {amountDisplay(split_point - the_deal_amount)}
              </p>
            ) : (
              <p
                className={`pl-2 text-right font-mono text-lg font-semibold text-ku-yellow whitespace-nowrap ${isChanged ? "glow-text-mid" : ""
                  }`}
              >
                {promoter_profit === 0
                  ? `${offer_type === "vs" ? "Net" : "Total"} Expenses: ${offer_type === "vs"
                    ? amountDisplay(split_point - the_deal_amount)
                    : amountDisplay(split_point)
                  }`
                  : ""}{" "}
                {promoter_profit / 100 > 0 && `BREAKEVEN`}
              </p>
            )}
          </div>
        </div>
      </div>
      {showHeader && (
        <div>
          <button
            onClick={() => setShowProjections(!showProjections)}
            className="flex items-center justify-end w-full p-2 bg-ku-blue text-white text-xs"
          >
            <span className="mr-1 -mt-2">Projections</span>
            {showProjections ? (
              <FiChevronUp size={14} className="-mt-2" />
            ) : (
              <FiChevronDown size={14} className="-mt-2" />
            )}
          </button>

          {showProjections && (
            <>
              {offerId ? (
                <OfferProjections
                  manifests={manifests}
                  eventId={eventId}
                  offerId={offerId}
                />
              ) : (
                <div className="bg-gray-700 text-gray-400 italic text-center py-4 px-2">
                  No offer ID available - save the event first to enable
                  projections
                </div>
              )}
            </>
          )}
        </div>
      )}
    </div>
  );
};
