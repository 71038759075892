import React, { useState } from "react";
import { TableRow } from "../AppTable2";
import { useSettlementComponent } from "@/hooks/useSettlementComponent";
import { Dialog } from "@headlessui/react";
import { useMutation, useQueryClient } from "@tanstack/react-query";
import { Link } from "react-router-dom";
import { ChevronDownIcon } from "@heroicons/react/24/outline";
import { toast } from "react-toastify";
import classNames from "classnames";
import {
  createVendor,
  createVendorArtist,
  createVendorWireInfo,
} from "@/queries/vendors";

interface WireInfo {
  id: number;
  vendor: number;
  description: string;
  bank_name: string;
  bank_address: string;
  account_number: string;
  routing_number: string;
  swift: string;
  active: boolean;
}

const getColumns = (artistInfo: any, index: number) => [
  { accessor: "label", title: "Field", editable: false, width: "200px" },
  {
    accessor: "value",
    title: artistInfo.artist?.name || `Artist ${index + 1}`,
    editable: false,
    width: "300px",
    className: "bg-input-blue",
  },
];

const getRowData = (artistInfo: any, wireInfo: WireInfo | null) => [
  { key: "name", label: "Artist Name", value: artistInfo.artist?.name || "" },
  {
    key: "vendor_name",
    label: "Vendor Name",
    value: artistInfo.vendor?.name || "No Vendor",
  },
  {
    key: "description",
    label: "Description",
    value: wireInfo?.description || "",
  },
  { key: "bank_name", label: "Bank Name", value: wireInfo?.bank_name || "" },
  {
    key: "bank_address",
    label: "Bank Address",
    value: wireInfo?.bank_address || "",
  },
  {
    key: "routing_number",
    label: "Routing Number",
    value: wireInfo?.routing_number || "",
  },
  {
    key: "account_number",
    label: "Account Number",
    value:
      wireInfo?.account_number && wireInfo.account_number.length >= 4
        ? "****" + wireInfo.account_number.slice(-4)
        : "",
  },
  { key: "swift", label: "SWIFT Code", value: wireInfo?.swift || "" },
];

const SettlementWireInfo: React.FC = () => {
  const {
    artistsWithVendors,
    settlementInputClassName,
    createEventOverrideMutation,
  } = useSettlementComponent();
  const queryClient = useQueryClient();
  const [showAddModal, setShowAddModal] = useState(false);
  const [showEditWireInfoModal, setShowEditWireInfoModal] = useState(false);
  const [selectedArtist, setSelectedArtist] = useState<any>(null);
  const [selectedVendor, setSelectedVendor] = useState<any>(null);
  const [selectedWireInfo, setSelectedWireInfo] = useState<WireInfo | null>(
    null
  );
  const [selectedVendorsByArtist, setSelectedVendorsByArtist] = useState<{
    [key: string]: number;
  }>({});
  const [selectedWireInfosByVendor, setSelectedWireInfosByVendor] = useState<{
    [key: string]: number;
  }>({});

  const createVendorMutation = useMutation({
    mutationFn: async (data: any) => {
      const vendorData = {
        name: data.name,
        description: data.description,
        email: data.email,
        phone: data.phone,
        is_agency: data.is_agency,
        address_line_1: data.address_line_1,
        address_line_2: data.address_line_2,
        address_line_3: data.address_line_3,
        city: data.city,
        state: data.state,
        zipcode: data.zipcode,
      };
      const vendor = await createVendor(vendorData);
      if (selectedArtist) {
        await createVendorArtist(vendor.id, {
          artists_id: [selectedArtist.id],
          vendor_id: vendor.id,
          description: "",
        });
        await createEventOverrideMutation.mutateAsync({
          section_name: "artist_vendor",
          sub_section_name: selectedArtist.name,
          field_name: "vendor_id",
          value: vendor.id.toString(),
          is_money: false,
          is_custom: false,
        });
      }
      const wireInfoData = {
        description: data.wire_description,
        bank_name: data.bank_name,
        bank_address: data.bank_address,
        account_number: data.account_number,
        routing_number: data.routing_number,
        swift: data.swift,
      };
      await createVendorWireInfo(vendor.id, wireInfoData);
      return vendor;
    },
    onSuccess: () => {
      queryClient.invalidateQueries(["vendors"]);
      setShowAddModal(false);
      toast.success("Vendor & Wire Info added successfully");
    },
    onError: (error: any) => {
      toast.error("Error adding vendor: " + error.message);
    },
  });

  const createWireInfoMutation = useMutation({
    mutationFn: async (data: { vendor_id: number; wireInfo: any }) => {
      return await createVendorWireInfo(data.vendor_id, data.wireInfo);
    },
    onSuccess: () => {
      queryClient.invalidateQueries(["vendors-wire-info"]);
      setShowEditWireInfoModal(false);
      toast.success("Wire Info updated successfully");
    },
    onError: (error: any) => {
      toast.error("Error updating wire info: " + error.message);
    },
  });

  return (
    <div className="bg-white rounded-lg shadow mt-4">
      <div className="flex justify-end items-center p-4">
        <Link
          to="/vendors"
          className="text-blue-600 hover:text-blue-800 font-medium"
        >
          Manage Vendors
        </Link>
      </div>
      <div className="gap grid-cols-3 gap-2">
        {artistsWithVendors.map((artistInfo: any, index: number) => {
          const hasVendor = !!artistInfo.vendor;
          const artistId = artistInfo.artist?.id || index;
          const vendors = artistInfo.artist?.vendors || [];
          const wireInfos = artistInfo.wireInfo || [];
          const selectedVendorId = selectedVendorsByArtist[artistId];
          const currentVendor =
            vendors.find((v: any) => v.id === selectedVendorId) || vendors[0];
          const selectedWireInfoId = currentVendor
            ? selectedWireInfosByVendor[currentVendor.id]
            : null;
          const currentWireInfo =
            currentVendor && wireInfos.length > 0
              ? wireInfos.find((w: WireInfo) => w.id === selectedWireInfoId) ||
                wireInfos[0]
              : null;
          return (
            <div key={artistId} className="py-4 border-b">
              <div className="flex justify-between items-center px-4 mb-4">
                <div>
                  <h3 className="text-lg font-semibold">
                    {artistInfo.artist?.name}
                  </h3>
                  {hasVendor && currentVendor && (
                    <p className="text-sm text-gray-600">
                      Using vendor: {currentVendor.name}
                    </p>
                  )}
                </div>
                <div className="flex items-center space-x-4">
                  {hasVendor ? (
                    <>
                      <div className="relative">
                        <select
                          value={selectedVendorId || currentVendor?.id || ""}
                          onChange={(e) => {
                            const value = e.target.value;
                            if (value === "new") {
                              setSelectedArtist(artistInfo.artist);
                              setShowAddModal(true);
                            } else {
                              setSelectedVendorsByArtist((prev) => ({
                                ...prev,
                                [artistId]: Number(value),
                              }));
                            }
                          }}
                          className="appearance-none bg-white border rounded-md px-3 py-2 pr-8 text-sm focus:outline-none focus:ring-2 focus:ring-blue-500"
                        >
                          {vendors.map((vendor: any) => (
                            <option key={vendor.id} value={vendor.id}>
                              {vendor.name}
                            </option>
                          ))}
                          <option value="new">Add new vendor</option>
                        </select>
                        <ChevronDownIcon className="absolute right-2 top-1/2 transform -translate-y-1/2 h-4 w-4 text-gray-500" />
                      </div>
                      {currentVendor && wireInfos.length > 0 && (
                        <div className="relative">
                          <select
                            value={
                              selectedWireInfoId ||
                              (currentWireInfo ? currentWireInfo.id : "")
                            }
                            onChange={(e) => {
                              const value = e.target.value;
                              if (value === "new") {
                                setSelectedVendor(currentVendor);
                                setShowEditWireInfoModal(true);
                              } else {
                                setSelectedWireInfosByVendor((prev) => ({
                                  ...prev,
                                  [currentVendor.id]: Number(value),
                                }));
                              }
                            }}
                            className="appearance-none bg-white border rounded-md px-3 py-2 pr-8 text-sm focus:outline-none focus:ring-2 focus:ring-blue-500"
                          >
                            {wireInfos.map((wire: WireInfo) => (
                              <option key={wire.id} value={wire.id}>
                                {wire.bank_name} - ****
                                {wire.account_number.slice(-4)}
                              </option>
                            ))}
                            <option value="new">Add new wire info</option>
                          </select>
                          <ChevronDownIcon className="absolute right-2 top-1/2 transform -translate-y-1/2 h-4 w-4 text-gray-500" />
                        </div>
                      )}
                    </>
                  ) : (
                    <button
                      onClick={() => {
                        setSelectedArtist(artistInfo.artist);
                        setShowAddModal(true);
                      }}
                      className="bg-blue-500 hover:bg-blue-600 text-white px-4 py-2 rounded-md text-sm font-medium transition-colors"
                    >
                      Add Vendor & Wire Info
                    </button>
                  )}
                </div>
              </div>
              <div className="px-4">
                {getRowData(artistInfo, currentWireInfo).map(
                  (row, rowIndex) => (
                    <TableRow
                      key={row.key}
                      columns={getColumns(artistInfo, index)}
                      rowData={row}
                      rowIndex={rowIndex}
                      className="grid items-center gap-2"
                      inputClassName={settlementInputClassName + " p-1"}
                    />
                  )
                )}
              </div>
            </div>
          );
        })}
      </div>
      <Dialog
        open={showAddModal}
        onClose={() => setShowAddModal(false)}
        className="relative z-50"
      >
        <div className="fixed inset-0 bg-black/30" aria-hidden="true" />
        <div className="fixed inset-0 flex items-center justify-center p-4">
          <Dialog.Panel className="mx-auto max-w-2xl w-full rounded bg-white p-6">
            <Dialog.Title className="text-lg font-medium leading-6 text-gray-900 mb-4">
              Add New Vendor & Wire Information
            </Dialog.Title>
            <form
              onSubmit={async (e) => {
                e.preventDefault();
                const formData = new FormData(e.currentTarget);
                const data = Object.fromEntries(formData.entries());
                await createVendorMutation.mutateAsync(data);
              }}
              className="grid grid-cols-2 gap-4"
            >
              <div className="space-y-4">
                <h4 className="font-medium">Vendor Information</h4>
                <input
                  name="name"
                  placeholder="Vendor Name"
                  className="border p-2 rounded w-full"
                  required
                  autoFocus
                />
                <label className="flex items-center">
                  <input type="checkbox" name="is_agency" className="mr-2" />
                  Is this an Agency?
                </label>
                <input
                  name="description"
                  placeholder="Description"
                  className="border p-2 rounded w-full"
                />
                <input
                  name="email"
                  placeholder="Email"
                  className="border p-2 rounded w-full"
                />
                <input
                  name="phone"
                  placeholder="Phone"
                  className="border p-2 rounded w-full"
                />
                <input
                  name="address_line_1"
                  placeholder="Address Line 1"
                  className="border p-2 rounded w-full"
                />
                <input
                  name="address_line_2"
                  placeholder="Address Line 2"
                  className="border p-2 rounded w-full"
                />
                <input
                  name="address_line_3"
                  placeholder="Address Line 3"
                  className="border p-2 rounded w-full"
                />
                <input
                  name="city"
                  placeholder="City"
                  className="border p-2 rounded w-full"
                />
                <input
                  name="state"
                  placeholder="State"
                  className="border p-2 rounded w-full"
                />
                <input
                  name="zipcode"
                  placeholder="Zipcode"
                  className="border p-2 rounded w-full"
                />
              </div>
              <div className="space-y-4">
                <h4 className="font-medium">Wire Information</h4>
                <input
                  name="bank_name"
                  placeholder="Bank Name"
                  className="border p-2 rounded w-full"
                  required
                />
                <input
                  name="bank_address"
                  placeholder="Bank Address"
                  className="border p-2 rounded w-full"
                  required
                />
                <input
                  name="account_number"
                  placeholder="Account Number"
                  className="border p-2 rounded w-full"
                  required
                />
                <input
                  name="routing_number"
                  placeholder="Routing Number"
                  className="border p-2 rounded w-full"
                  required
                />
                <input
                  name="swift"
                  placeholder="SWIFT Code"
                  className="border p-2 rounded w-full"
                />
                <input
                  name="wire_description"
                  placeholder="Wire Info Description"
                  className="border p-2 rounded w-full"
                />
              </div>
              <div className="col-span-2 flex justify-end space-x-2 mt-6">
                <button
                  type="button"
                  onClick={() => setShowAddModal(false)}
                  className="px-4 py-2 text-sm font-medium text-gray-700 bg-gray-100 hover:bg-gray-200 rounded-md"
                >
                  Cancel
                </button>
                <button
                  type="submit"
                  className="px-4 py-2 text-sm font-medium text-white bg-blue-500 hover:bg-blue-600 rounded-md"
                >
                  Add Vendor & Wire Info
                </button>
              </div>
            </form>
          </Dialog.Panel>
        </div>
      </Dialog>
      <Dialog
        open={showEditWireInfoModal}
        onClose={() => setShowEditWireInfoModal(false)}
        className="relative z-50"
      >
        <div className="fixed inset-0 bg-black/30" aria-hidden="true" />
        <div className="fixed inset-0 flex items-center justify-center p-4">
          <Dialog.Panel className="mx-auto max-w-lg w-full rounded bg-white p-6">
            <Dialog.Title className="text-lg font-medium leading-6 text-gray-900 mb-4">
              Edit Wire Information for {selectedVendor?.name}
            </Dialog.Title>
            <form
              onSubmit={async (e) => {
                e.preventDefault();
                const formData = new FormData(e.currentTarget);
                const data = Object.fromEntries(formData.entries());
                if (selectedVendor) {
                  await createWireInfoMutation.mutateAsync({
                    vendor_id: selectedVendor.id,
                    wireInfo: data,
                  });
                }
              }}
              className="space-y-4"
            >
              <input
                name="bank_name"
                placeholder="Bank Name"
                className="border p-2 rounded w-full"
                required
                autoFocus
              />
              <input
                name="bank_address"
                placeholder="Bank Address"
                className="border p-2 rounded w-full"
                required
              />
              <input
                name="account_number"
                placeholder="Account Number"
                className="border p-2 rounded w-full"
                required
              />
              <input
                name="routing_number"
                placeholder="Routing Number"
                className="border p-2 rounded w-full"
                required
              />
              <input
                name="swift"
                placeholder="SWIFT Code"
                className="border p-2 rounded w-full"
              />
              <input
                name="description"
                placeholder="Description"
                className="border p-2 rounded w-full"
              />
              <div className="flex justify-end space-x-2">
                <button
                  type="button"
                  onClick={() => setShowEditWireInfoModal(false)}
                  className="px-4 py-2 text-sm font-medium text-gray-700 bg-gray-100 hover:bg-gray-200 rounded-md"
                >
                  Cancel
                </button>
                <button
                  type="submit"
                  className="px-4 py-2 text-sm font-medium text-white bg-blue-500 hover:bg-blue-600 rounded-md"
                >
                  Update Wire Info
                </button>
              </div>
            </form>
          </Dialog.Panel>
        </div>
      </Dialog>
    </div>
  );
};

export default SettlementWireInfo;
