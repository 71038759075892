import { getApiRequest, makeRequest, jsonHeaders } from "./base";

export const getUsers = async () => {
  return await getApiRequest("/api/tasks/users/");
};

export const getTasks = async () => {
  return await getApiRequest("/api/tasks/");
};

export const getAllTasks = async () => {
  return await getApiRequest("/api/tasks/all/");
};

export const createTask = async (data) => {
  const response = await makeRequest(
    "/api/tasks/",
    "POST",
    await jsonHeaders(true),
    JSON.stringify(data)
  );
  if (!response.ok) {
    throw new Error(`Failed to create task: ${response.statusText}`);
  }
  return response.json();
};

export const updateTask = async (taskId, data) => {
  const response = await makeRequest(
    `/api/tasks/${taskId}/`,
    "PUT",
    await jsonHeaders(true),
    JSON.stringify(data)
  );
  if (!response.ok) {
    throw new Error(`Failed to update task: ${response.statusText}`);
  }
  return response.json();
};

export const deleteTask = async (taskId) => {
  return await makeRequest(
    `/api/tasks/${taskId}/`,
    "DELETE",
    await jsonHeaders(true)
  );
};

export const getTaskComments = async (taskId) => {
  return await getApiRequest(`/api/tasks/${taskId}/comments/`);
};

export const addTaskComment = async (taskId, comment) => {
  const response = await makeRequest(
    `/api/tasks/${taskId}/comments/`,
    "POST",
    await jsonHeaders(true),
    JSON.stringify({ text: comment })
  );
  if (!response.ok) {
    throw new Error(`Failed to add comment: ${response.statusText}`);
  }
  return response.json();
};

export const getTaskActivity = async (taskId) => {
  return await getApiRequest(`/api/tasks/${taskId}/activity/`);
};

export const startTaskTimer = async (taskId) => {
  return await getApiRequest(`/api/tasks/${taskId}/timer/start/`);
};

export const stopTaskTimer = async (taskId, duration) => {
  const response = await makeRequest(
    `/api/tasks/${taskId}/timer/stop/`,
    "POST",
    await jsonHeaders(true),
    JSON.stringify({ duration })
  );
  if (!response.ok) {
    throw new Error(`Failed to stop timer: ${response.statusText}`);
  }
  return response.json();
};

export const getTaskTimeEntries = async (taskId) => {
  return await getApiRequest(`/api/tasks/${taskId}/time-entries/`);
};

export const addTaskTimeEntry = async (taskId, timeData) => {
  const response = await makeRequest(
    `/api/tasks/${taskId}/time-entries/`,
    "POST",
    await jsonHeaders(true),
    JSON.stringify(timeData)
  );
  if (!response.ok) {
    throw new Error(`Failed to add time entry: ${response.statusText}`);
  }
  return response.json();
};

export const exportTasks = async () => {
  return await getApiRequest("/api/tasks/export/");
};

export const importTasks = async (tasksData) => {
  const response = await makeRequest(
    "/api/tasks/import/",
    "POST",
    await jsonHeaders(true),
    JSON.stringify({ tasks: tasksData })
  );
  if (!response.ok) {
    throw new Error(`Failed to import tasks: ${response.statusText}`);
  }
  return response.json();
};

export const getTaskTemplates = async () => {
  return await getApiRequest("/api/tasks/templates/");
};

export const saveTaskTemplate = async (templateData) => {
  const response = await makeRequest(
    "/api/tasks/templates/",
    "POST",
    await jsonHeaders(true),
    JSON.stringify(templateData)
  );
  if (!response.ok) {
    throw new Error(`Failed to save template: ${response.statusText}`);
  }
  return response.json();
};

export const getTaskSettings = {
  staleTime: 1000 * 60 * 5, // 5 minutes
  cacheTime: 1000 * 60 * 30, // 30 minutes
};
