import React, { useEffect, useState } from "react";
import { Header } from "@/components/Header";

const SectionHeader = (props) => {
  const { title, buttonText } = props;
  return (
    <div className="w-full my-4 border-b-2 border-gray-200">
      <h2 className="text-xl font-bold float-left">{title}</h2>
      <button
        type="button"
        className="mb-2 float-right rounded-md bg-blue-500 px-2.5 py-1.5 text-sm font-semibold text-cave-white shadow-sm hover:bg-blue-400 focus-visible:outline focus-visible:outline-2 focus-visible:outline-offset-2 focus-visible:outline-blue-500"
      >
        {buttonText}
      </button>
    </div>
  );
};

const ExpenseTable = (props) => {
  const { title, buttonText, items } = props;
  return (
    <>
      <SectionHeader title={title} buttonText={buttonText} />
      <table className="table-fixed border-x border-b w-full">
        <thead>
          <th className="text-left p-2 bg-gray-200 dark:text-gray-800">Name</th>
          <th className="text-left p-2 bg-gray-200 dark:text-gray-800">
            Payout
          </th>
          <th className="text-right p-2 bg-gray-200 dark:text-gray-800">
            Total
          </th>
        </thead>
        <tbody>
          {items.map((item, index) => (
            <tr className="hover:bg-gray-200 dark:hover:bg-gray-400 dark:hover:text-gray-800">
              <td className="py-4 px-2">{item.name}</td>
              <td>{item.payout}</td>
              <td className="text-right px-2">{item.amount}</td>
            </tr>
          ))}
        </tbody>
      </table>
    </>
  );
};

export const AccountingDemo = () => {
  const advertisingItems = [
    {
      name: "Radio",
      payout: "BUILDING",
      amount: "3,247.00",
    },
    {
      name: "Digital",
      payout: "BUILDING",
      amount: "3,844.22",
    },
    {
      name: "Misc",
      payout: "BUILDING",
      amount: "440.00",
    },
  ];

  const cateringItems = [
    {
      name: "Catering",
      payout: "BUILDING",
      amount: "3,000.00",
    },
  ];

  const expenseItems = [
    {
      name: "Rent",
      payout: "BUILDING",
      amount: "6,000.00",
    },
    {
      name: "Setup",
      payout: "BUILDING",
      amount: "1,500.00",
    },
    {
      name: "House Staff",
      payout: "BUILDING",
      amount: "679.25",
    },
    {
      name: "Security",
      payout: "BUILDING",
      amount: "2287.50",
    },
    {
      name: "Utilities",
      payout: "BUILDING",
      amount: "200.00",
    },
    {
      name: "Box Office",
      payout: "BUILDING",
      amount: "2000.00",
    },
    {
      name: "Stage Hands",
      payout: "BUILDING",
      amount: "8002.00",
    },
    {
      name: "Local Prod",
      payout: "BUILDING",
      amount: "7149.00",
    },
    {
      name: "Forklift",
      payout: "BUILDING",
      amount: "100.00",
    },
    {
      name: "Parking Permit",
      payout: "BUILDING",
      amount: "330.00",
    },
    {
      name: "Medical",
      payout: "BUILDING",
      amount: "302.50",
    },
    {
      name: "Police",
      payout: "BUILDING",
      amount: "195.00",
    },
    {
      name: "Runner",
      payout: "BUILDING",
      amount: "268.25",
    },
  ];

  return (
    <>
      <Header />
      <div className="flex flex-col my-6 px-6 py-8 mx-auto min-h-screen lg:py-0 dark:text-gray-200">
        <h1 className="text-2xl font-bold mb-4">
          Dustin Lynch @ Five Flags Center
        </h1>
        <p className="mb-2 font-sm text-gray-400">Thursday, April 7, 2022</p>

        <SectionHeader title="Offers" buttonText="Add Offer" />
        <table className="table-fixed border-x border-b w-full">
          <thead>
            <th className="text-left p-2 bg-gray-200 dark:text-gray-800">
              Artist
            </th>
            <th className="text-left p-2 bg-gray-200 dark:text-gray-800">
              Payout
            </th>
            <th className="text-right p-2 bg-gray-200 dark:text-gray-800">
              Total
            </th>
          </thead>
          <tbody>
            <tr className="hover:bg-gray-200 dark:hover:bg-gray-400 dark:hover:text-gray-800">
              <td className="py-4 px-2">Dustin Lynch</td>
              <td>CHECK 10254</td>
              <td className="text-right px-2">49,500.00</td>
            </tr>
            <tr className="hover:bg-gray-200 dark:hover:bg-gray-400 dark:hover:text-gray-800">
              <td className="py-4 px-2">Sean Steamly</td>
              <td>CHECK 10253</td>
              <td className="text-right px-2">500.00</td>
            </tr>
          </tbody>
        </table>

        <h2 className="bg-blue-200 border-2 border-blue-300 font-bold my-4 py-4 px-2 w-full">
          Expenses
        </h2>

        <ExpenseTable
          title="Advertising"
          buttonText="Add Advertising"
          items={advertisingItems}
        />

        <ExpenseTable
          title="Catering"
          buttonText="Add Catering"
          items={cateringItems}
        />

        <ExpenseTable
          title="Misc Expenses"
          buttonText="Add Expense"
          items={expenseItems}
        />

        <div className="pb-20"></div>
      </div>
    </>
  );
};
