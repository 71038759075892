import React, { useState } from "react";
import { useQuery, useMutation, useQueryClient } from "@tanstack/react-query";
import { useParams, useNavigate } from "react-router-dom";
import { PencilSquareIcon, TrashIcon } from "@heroicons/react/24/outline";
import { CheckCircleIcon } from "@heroicons/react/24/solid";
import DeleteConfirmation from "./DeleteConfirmation";
import GuestForm from "./GuestForm.jsx";

export const GuestList = ({ eventId, eventName, eventDate, marketer }) => {
  const navigate = useNavigate();
  const queryClient = useQueryClient();
  const [showModal, setShowModal] = useState(false);
  const [editingGuest, setEditingGuest] = useState(null);
  const [deleteGuest, setDeleteGuest] = useState(null);

  const {
    data: guests,
    isLoading,
    error,
  } = useQuery(["guestlist", eventId], () => {
    console.log("Fetching guest list...");
    return fetch(`/api/guestlist/event/${eventId}`, {
      credentials: "include",
      headers: {
        "Content-Type": "application/json",
        "X-CSRFToken": document.cookie.match(/csrftoken=([\w-]+)/)?.[1] || "",
      },
    }).then(async (res) => {
      if (!res.ok) {
        const error = await res.json();
        throw new Error(error.detail || "Failed to fetch guest list");
      }
      return res.json();
    });
  });

  const addGuestMutation = useMutation(
    (newGuest) =>
      fetch(`/api/guestlist/event/${eventId}`, {
        method: "POST",
        credentials: "include",
        headers: {
          "Content-Type": "application/json",
          "X-CSRFToken": document.cookie.match(/csrftoken=([\w-]+)/)?.[1] || "",
        },
        body: JSON.stringify(newGuest),
      }).then((res) => res.json()),
    {
      onSuccess: () => {
        queryClient.invalidateQueries(["guestlist", eventId]);
      },
    }
  );

  const updateGuestMutation = useMutation(
    (data) =>
      fetch(`/api/guestlist/guest/${data.id}`, {
        method: "PUT",
        credentials: "include",
        headers: {
          "Content-Type": "application/json",
          "X-CSRFToken": document.cookie.match(/csrftoken=([\w-]+)/)?.[1] || "",
        },
        body: JSON.stringify(data),
      }).then((res) => res.json()),
    {
      onSuccess: () => {
        queryClient.invalidateQueries(["guestlist", eventId]);
        setEditingGuest(null);
      },
    }
  );

  const deleteGuestMutation = useMutation(
    (guestId) =>
      fetch(`/api/guestlist/guest/${guestId}`, {
        method: "DELETE",
        credentials: "include",
        headers: {
          "X-CSRFToken": document.cookie.match(/csrftoken=([\w-]+)/)?.[1] || "",
        },
      }).then((res) => res.json()),
    {
      onSuccess: () => queryClient.invalidateQueries(["guestlist", eventId]),
    }
  );

  const { data: guestListState } = useQuery(["guestlist-state", eventId], () =>
    fetch(`/api/guestlist/event/${eventId}/state`, {
      credentials: "include",
      headers: {
        "Content-Type": "application/json",
        "X-CSRFToken": document.cookie.match(/csrftoken=([\w-]+)/)?.[1] || "",
      },
    }).then((res) => res.json())
  );

  const toggleGuestListMutation = useMutation(
    () =>
      fetch(`/api/guestlist/event/${eventId}/toggle-close`, {
        method: "PUT",
        credentials: "include",
        headers: {
          "Content-Type": "application/json",
          "X-CSRFToken": document.cookie.match(/csrftoken=([\w-]+)/)?.[1] || "",
        },
      }).then((res) => res.json()),
    {
      onSuccess: () => {
        queryClient.invalidateQueries(["guestlist-state", eventId]);
      },
    }
  );

  const approveGuestMutation = useMutation(
    (guestId) =>
      fetch(`/api/guestlist/guest/${guestId}/approve`, {
        method: "PUT",
        credentials: "include",
        headers: {
          "Content-Type": "application/json",
          "X-CSRFToken": document.cookie.match(/csrftoken=([\w-]+)/)?.[1] || "",
        },
      }).then((res) => res.json()),
    {
      onSuccess: () => {
        queryClient.invalidateQueries(["guestlist", eventId]);
      },
    }
  );

  const handleEdit = (guest) => {
    setEditingGuest(guest);
    setShowModal(true);
  };

  const handleDelete = (guest) => {
    setDeleteGuest(guest);
  };

  if (isLoading) {
    return (
      <div className="flex items-center justify-center p-4">
        <div className="animate-spin rounded-full h-8 w-8 border-b-2 border-green-600" />
        <span className="ml-2 text-green-800">Loading Guest List...</span>
      </div>
    );
  }

  if (error) {
    return (
      <div className="flex items-center justify-center p-4">
        <div className="text-red-500">Error: {error.message}</div>
      </div>
    );
  }

  return (
    <div className="w-full">
      <div className="flex flex-col items-center mb-4">
        <h3 className="text-lg text-gray-700">
          {eventName} - {eventDate}
          {marketer && (
            <span className="ml-2">
              - Marketing: {marketer.first_name} {marketer.last_name}
            </span>
          )}
        </h3>
      </div>
      <div className="flex flex-row justify-end items-center mb-4 space-x-4">
        <button
          onClick={() => toggleGuestListMutation.mutate()}
          className={`px-4 py-2 rounded ${
            guestListState?.is_closed
              ? "bg-yellow-600 text-white hover:bg-yellow-700"
              : "bg-green-600 text-white hover:bg-green-700"
          }`}
        >
          {guestListState?.is_closed ? "Open Guest List" : "Close Guest List"}
        </button>
        <button
          onClick={() =>
            window.open(`/events/${eventId}/guestlist/print`, "_blank")
          }
          className="px-4 py-2 bg-green-100 text-green-800 rounded hover:bg-green-200"
        >
          Print Version
        </button>
        <button
          onClick={() => {
            setEditingGuest(null);
            setShowModal(true);
          }}
          className="px-4 py-2 bg-green-600 text-white rounded hover:bg-green-700 disabled:bg-gray-400 disabled:cursor-not-allowed"
          disabled={guestListState?.is_closed || addGuestMutation.isLoading}
        >
          Add Guest
        </button>
      </div>

      <div className="bg-white shadow-md rounded-lg overflow-hidden">
        <div
          className={`${
            guestListState?.is_closed ? "bg-yellow-600" : "bg-green-600"
          } text-white text-xl font-bold p-4`}
        >
          {guestListState?.is_closed ? "Guest List CLOSED" : "Guest List"}
        </div>
        <div className="overflow-x-auto">
          <table className="min-w-full divide-y divide-green-200">
            <thead
              className={`${
                guestListState?.is_closed ? "bg-yellow-500" : "bg-green-500"
              }`}
            >
              <tr>
                <th className="px-6 py-3 text-left text-xs font-medium text-white uppercase tracking-wider">
                  NAME
                </th>
                <th className="px-6 py-3 text-left text-xs font-medium text-white uppercase tracking-wider">
                  # TICKETS
                </th>
                <th className="px-6 py-3 text-left text-xs font-medium text-white uppercase tracking-wider">
                  AFFILIATION
                </th>
                <th className="px-6 py-3 text-left text-xs font-medium text-white uppercase tracking-wider">
                  APPROVED BY
                </th>
                <th className="px-6 py-3 text-left text-xs font-medium text-white uppercase tracking-wider">
                  # PASSES
                </th>
                <th className="px-6 py-3 text-left text-xs font-medium text-white uppercase tracking-wider">
                  TYPE
                </th>
                <th className="px-6 py-3 text-left text-xs font-medium text-white uppercase tracking-wider">
                  REQUESTED BY
                </th>
                <th className="px-6 py-3 text-left text-xs font-medium text-white uppercase tracking-wider">
                  STATUS
                </th>
                <th className="px-6 py-3 text-left text-xs font-medium text-white uppercase tracking-wider">
                  ACTIONS
                </th>
              </tr>
            </thead>
            <tbody className="divide-y divide-green-200">
              {guests?.map((guest, idx) => (
                <tr
                  key={guest.id}
                  className={
                    idx % 2 === 0
                      ? "bg-white"
                      : guestListState?.is_closed
                      ? "bg-yellow-50"
                      : "bg-green-50"
                  }
                >
                  <td className="px-6 py-4 whitespace-nowrap text-sm text-gray-900">
                    {guest.name}
                  </td>
                  <td className="px-6 py-4 whitespace-nowrap text-sm text-gray-900">
                    {guest.num_tickets}
                  </td>
                  <td className="px-6 py-4 whitespace-nowrap text-sm text-gray-900">
                    {guest.affiliation}
                  </td>
                  <td className="px-6 py-4 whitespace-nowrap text-sm text-gray-900">
                    {guest.approved_by_name}
                  </td>
                  <td className="px-6 py-4 whitespace-nowrap text-sm text-gray-900">
                    {guest.num_passes}
                  </td>
                  <td className="px-6 py-4 whitespace-nowrap text-sm text-gray-900">
                    {guest.pass_type}
                  </td>
                  <td className="px-6 py-4 whitespace-nowrap text-sm text-gray-900">
                    {guest.requested_by_name}
                  </td>
                  <td className="px-6 py-4 whitespace-nowrap text-sm text-gray-900">
                    {console.log(
                      "Guest approval status:",
                      guest.name,
                      guest.is_approved
                    )}
                    {guest.is_approved ? (
                      <div className="flex items-center">
                        <CheckCircleIcon className="h-6 w-6 text-green-800" />
                      </div>
                    ) : (
                      <button
                        onClick={() => approveGuestMutation.mutate(guest.id)}
                        className="inline-flex items-center px-3 py-1 rounded-md text-sm font-medium bg-yellow-100 text-yellow-800 hover:bg-yellow-200 transition-colors duration-150"
                      >
                        Click to Approve
                      </button>
                    )}
                  </td>
                  <td className="px-6 py-4 whitespace-nowrap text-right text-sm font-medium">
                    <button
                      onClick={() => handleEdit(guest)}
                      className="text-green-600 hover:text-green-900 mr-4"
                    >
                      <PencilSquareIcon className="h-5 w-5" />
                    </button>
                    <button
                      onClick={() => handleDelete(guest)}
                      className="text-red-600 hover:text-red-900"
                    >
                      <TrashIcon className="h-5 w-5" />
                    </button>
                  </td>
                </tr>
              ))}
            </tbody>
          </table>
        </div>
      </div>

      {showModal && (
        <GuestForm
          isOpen={showModal}
          onClose={() => {
            setShowModal(false);
            setEditingGuest(null);
          }}
          onSubmit={(formData) => {
            if (editingGuest) {
              updateGuestMutation.mutate({
                ...formData,
                id: editingGuest.id,
              });
            } else {
              addGuestMutation.mutate(formData);
            }
            setShowModal(false);
          }}
          initialData={editingGuest}
        />
      )}

      <DeleteConfirmation
        isOpen={!!deleteGuest}
        onClose={() => setDeleteGuest(null)}
        onConfirm={() => {
          deleteGuestMutation.mutate(deleteGuest.id);
          setDeleteGuest(null);
        }}
        guestName={deleteGuest?.name}
      />
    </div>
  );
};

export default GuestList;
