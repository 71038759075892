import React, { useState, useMemo } from "react";
import { useIntersectionObserver } from "@/hooks/useIntersectionObserver";

export function PPeopleIcon({ person, debugInfo = {}, className = "" }) {
  const [isLoaded, setIsLoaded] = useState(false);
  const [hasError, setHasError] = useState(false);
  const { targetRef: ref, isIntersecting } = useIntersectionObserver({
    threshold: 0.1,
    rootMargin: "50px",
  });

  // Handle case where person is just an image URL string with separate person_id
  const personData = useMemo(() => {
    // If person is a string and person_id is provided, it's the old format
    if (typeof person === "string" && debugInfo.person_id) {
      return {
        id: debugInfo.person_id,
        person_id: debugInfo.person_id,
        photo: person,
      };
    }

    // For BulletinBoard format
    if (person && person.buyer_image_url) {
      return {
        id: person.person_id || person.buyer_id,
        person_id: person.person_id || person.buyer_id,
        photo: person.buyer_image_url || null,
        buyer_image_url: person.buyer_image_url || null,
        first_name: person.buyer ? person.buyer.split(" ")[0] : "",
        last_name: person.buyer
          ? person.buyer.split(" ").slice(1).join(" ")
          : "",
      };
    }

    // Default case - pass through the person object
    return person;
  }, [person, debugInfo]);

  // Debug the person object when component mounts
  React.useEffect(() => {
    console.log("PPeopleIcon mounted with person:", {
      person: personData,
      id: personData?.id,
      person_id:
        personData?.person_id ||
        personData?.id ||
        personData?.people_id ||
        personData?.user?.person_id,
      user_person_id: personData?.user?.person_id,
      people_id: personData?.people_id,
      debugInfo,
    });
  }, [personData, debugInfo]);

  const getImageUrl = () => {
    // Direct photo URL passed in
    if (typeof personData === "string") {
      return personData;
    }
    // Check for old format (personData.user.photo)
    if (personData?.user?.photo) {
      // Make sure the URL is valid and not null
      const photoUrl = personData.user.photo;
      if (photoUrl && typeof photoUrl === "string") {
        // If the URL starts with /media/, prepend the base URL
        if (photoUrl.startsWith("/media/")) {
          return `${window.location.origin}${photoUrl}`;
        }
        return photoUrl;
      }
    }
    // Check for buyer_image_url from API (first buyer's image)
    if (personData?.buyer_image_url) {
      const photoUrl = personData.buyer_image_url;
      if (photoUrl && typeof photoUrl === "string") {
        // If the URL starts with /media/, prepend the base URL
        if (photoUrl.startsWith("/media/")) {
          return `${window.location.origin}${photoUrl}`;
        }
        return photoUrl;
      }
    }
    // Check for new format (personData.photo)
    if (personData?.photo) {
      // Make sure the URL is valid and not null
      const photoUrl = personData.photo;
      if (photoUrl && typeof photoUrl === "string") {
        // If the URL starts with /media/, prepend the base URL
        if (photoUrl.startsWith("/media/")) {
          return `${window.location.origin}${photoUrl}`;
        }
        return photoUrl;
      }
    }

    // Debug what we're getting
    console.log("Image lookup for person:", {
      person: personData,
      id: personData?.id,
      person_id:
        personData?.person_id ||
        personData?.id ||
        personData?.people_id ||
        personData?.user?.person_id,
      people_id: personData?.people_id,
      userPhoto: personData?.user?.photo,
      photo: personData?.photo,
      images: personData?.images,
    });
    // Fall back to images array if neither photo exists
    if (personData?.images && personData.images.length > 0) {
      const imageUrl =
        personData.images[0].image_url || personData.images[0].image;
      if (imageUrl && typeof imageUrl === "string") {
        // If the URL starts with /media/, prepend the base URL
        if (imageUrl.startsWith("/media/")) {
          return `${window.location.origin}${imageUrl}`;
        }
        return imageUrl;
      }
    }

    // Check if we have a direct URL in the user object
    if (personData?.user) {
      // Try to find any property that looks like an image URL
      const userProps = Object.entries(personData.user);
      for (const [key, value] of userProps) {
        if (typeof value === "string" && value) {
          console.log(`Found image URL in user.${key}:`, value);
          // If the URL starts with /media/, prepend the base URL
          if (value.startsWith("/media/")) {
            return `${window.location.origin}${value}`;
          }
          return value;
        }
      }
    }

    // Last resort - check if we have a direct URL in the value
    if (personData && typeof personData === "object") {
      const possibleUrls = Object.values(personData).filter(
        (val) =>
          typeof val === "string" &&
          val &&
          (val.startsWith("/media/") || val.startsWith("http"))
      );
      if (possibleUrls.length > 0) {
        console.log("Found possible URL in person object:", possibleUrls[0]);
        // If the URL starts with /media/, prepend the base URL
        if (possibleUrls[0].startsWith("/media/")) {
          return `${window.location.origin}${possibleUrls[0]}`;
        }
        return possibleUrls[0];
      }
    }

    return null;
  };

  const imageUrl = getImageUrl();

  // Debug the image URL
  console.log("PPeopleIcon imageUrl result:", {
    imageUrl,
    person: personData,
    hasUserPhoto: !!personData?.user?.photo,
    hasPhoto: !!personData?.photo,
    // Add more detailed debugging
    fullUrl: imageUrl,
    origin: window.location.origin,
    pathOnly: imageUrl?.startsWith(window.location.origin)
      ? imageUrl.split(window.location.origin)[1]
      : imageUrl,
  });

  // Test the image URL directly
  if (imageUrl) {
    const img = new Image();
    img.onload = () => console.log("Image preload SUCCESS:", imageUrl);
    img.onerror = () => console.error("Image preload FAILED:", imageUrl);
    img.src = imageUrl;
  }

  const initials =
    personData?.first_name && personData?.last_name
      ? `${personData.first_name[0]}${personData.last_name[0]}`.toUpperCase()
      : personData?.first_name
      ? personData.first_name[0].toUpperCase()
      : personData?.user?.first_name && personData?.user?.last_name
      ? `${personData.user.first_name[0]}${personData.user.last_name[0]}`.toUpperCase()
      : personData?.user?.first_name
      ? personData.user.first_name[0].toUpperCase()
      : "?";

  const handleLoad = () => {
    setIsLoaded(true);
  };

  const handleError = (e) => {
    console.error("Image load error:", {
      imageUrl,
      error: e?.target?.error || "Unknown error",
      person: {
        id: personData?.id,
        person_id: personData?.person_id,
        people_id: personData?.people_id,
        user_person_id: personData?.user?.person_id,
      },
    });
    setHasError(true);
    // Force a re-render to show initials
    setIsLoaded(false);
  };

  // Extract the person ID once for consistent use
  const personId =
    personData?.person_id ||
    personData?.id ||
    personData?.people_id ||
    (personData?.user ? personData?.user?.person_id : undefined);

  // Create a direct navigation function
  const navigateToPerson = (e) => {
    if (e) {
      e.stopPropagation();
      e.preventDefault();
    }

    if (personId) {
      console.log(`Directly navigating to: /people/${personId}`);
      window.location.href = `/people/${personId}`;
    } else {
      console.warn("No valid person ID found:", person);
    }
  };

  return (
    <div
      onClick={navigateToPerson}
      className={`cursor-pointer ${
        className.includes("w-full") ? "w-full h-full" : ""
      }`}
      style={{ zIndex: 999, position: "relative" }}
      data-person-id={personId || "none"}
      title={`View profile for ${personData?.first_name || ""} ${
        personData?.last_name || ""
      }`}
    >
      <div
        ref={ref}
        className={`rounded-full bg-gray-200 flex items-center justify-center overflow-hidden relative ${
          className || "w-16 h-16"
        }`}
        onClick={navigateToPerson}
      >
        {imageUrl && typeof imageUrl === "string" ? (
          <>
            {imageUrl && (
              <img
                src={imageUrl}
                alt={initials}
                className={`absolute inset-0 w-full h-full object-cover transition-opacity duration-300 ${
                  isLoaded && !hasError ? "opacity-100" : "opacity-0"
                }`}
                onLoad={handleLoad}
                onError={handleError}
                style={{ objectFit: "cover" }}
              />
            )}
            {!isLoaded && !hasError && (
              <div className="flex items-center justify-center w-full h-full">
                <div className="animate-spin rounded-full h-6 w-6 border-b-2 border-gray-900"></div>
              </div>
            )}
            {hasError && (
              <span className="text-xl font-semibold text-gray-500">
                {initials}
              </span>
            )}
          </>
        ) : (
          <span className="text-xl font-semibold text-gray-500">
            {initials}
          </span>
        )}
      </div>
    </div>
  );
}

// Keep the old version for backwards compatibility but use the new component
export function PeopleIcon({ imageUrl, person_id }) {
  // If we have both imageUrl and person_id, use the new component with the old interface
  if (imageUrl && person_id) {
    return (
      <PPeopleIcon
        person={imageUrl}
        debugInfo={{ person_id, component: "LegacyPeopleIcon" }}
      />
    );
  }

  // Fall back to the original implementation if needed
  return (
    <a target="_blank" rel="noopener noreferrer" href={`/people/${person_id}`}>
      <div
        className="w-12 h-12 rounded-full bg-cover bg-center"
        style={{ backgroundImage: `url(${imageUrl})` }}
      ></div>
    </a>
  );
}
