import { calculateRebateValue } from "@/helpers";
import {
  createEventExpense,
  deleteExpense,
  getArtistEventRollups,
  getEventExpenses,
  updateEventExpense,
} from "@/queries/accounting";
import { getArtist } from "@/queries/artists";
import {
  getEvent,
  updateEvent,
  getEventSettlementInfo,
  getEventSettlement,
  getEventPeople,
  updateEventSettlement,
  getEventOfferVariables,
} from "@/queries/events";
import {
  createEventOverride,
  CreateFinalOverrideData,
  fetchEventOverrides,
  updateEventOverride,
  deleteEventOverride,
  FinalOverride,
} from "@/queries/settlement-overrides";
import { getVendor, getVendorWireInfo } from "@/queries/vendors";
import {
  EventSettlementOverride,
  CaveEvent,
  Rollup,
  Artist,
} from "@/types/event";
import { Expense } from "@/types/expense";
import { SettlementTab } from "@/types/settlement";
import { useMutation, useQuery, useQueryClient } from "@tanstack/react-query";
import { useLocation, useNavigate, useParams } from "react-router-dom";

interface UpdateEventSettlementParams {
  id?: string | number;
  book: "artist" | "copro" | "final";
  data: Record<string, unknown> | any[];
  key: keyof EventSettlementOverride["artist"];
}

interface UseSettlementOptions {
  enableExpenses?: boolean;
}

interface CaveError {
  message: string;
}

interface OverrideMutationVariables {
  data: CreateFinalOverrideData;
  overrideId?: number;
}

export function useSettlementComponent({
  enableExpenses = false,
}: UseSettlementOptions = {}) {
  const { id, type: settlementType } = useParams<{
    id: string;
    type: SettlementTab;
  }>();
  const queryClient = useQueryClient();
  const navigate = useNavigate();
  const location = useLocation();

  // Query Hooks
  const eventOverrides = useQuery<FinalOverride[], CaveError>(
    ["event-overrides", id],
    () => fetchEventOverrides(id),
    {
      onError: (error) => {
        console.error("Failed to fetch event overrides:", error);
      },
    }
  );

  const event = useQuery<CaveEvent, CaveError>(["event-detail", id], () =>
    getEvent(id)
  );

  const eventSettlement = useQuery<EventSettlementOverride, CaveError>(
    ["event-settlement", id],
    () => getEventSettlement(id)
  );

  const settlement = useQuery(["event-settlement-info", id], () =>
    getEventSettlementInfo(id)
  );

  const variables = useQuery(
    ["variables-offer-pdf", id],
    () => getEventOfferVariables(id),
    {
      initialData: () => ({}),
    }
  );

  const people = useQuery(["event", "people", id], () => getEventPeople(id));

  const rollups = useQuery<Rollup, CaveError>(
    ["event-rollups", "artist", id],
    () => getArtistEventRollups(id)
  );

  const expensesQuery = useQuery<Expense[], CaveError>(
    ["event-expenses", id?.toString()],
    () => getEventExpenses(id, settlementType),
    {
      initialData: [],
      enabled: enableExpenses,
    }
  );

  const artist = useQuery<Artist | null>(
    ["artist", event.data?.artists?.[0]?.id],
    () => getArtist(event.data.artists[0].id),
    {
      initialData() {
        return null;
      },
      enabled: !!event?.data?.artists[0],
    }
  );

  // write query to get all artists
  const artistsFromEvent = event.data?.artists;
  const allArtists = useQuery<Artist[]>(
    ["artists"],
    () => {
      return Promise.all(
        artistsFromEvent?.map((artist) => getArtist(artist.id))
      );
    },
    {
      initialData: [],
      enabled: !!artistsFromEvent?.length,
    }
  );
  // console.log("allArtists", allArtists.data);
  const allVendorIds = allArtists.data?.map(
    (artist) => artist.vendors?.[0]?.id
  );
  // console.log("allVendorsIds", allVendorIds);
  const allVendors = useQuery(
    ["vendors"],
    () => {
      return Promise.all(allVendorIds?.map((id) => getVendor(id)));
    },
    {
      initialData: [],
      enabled: !!allVendorIds?.length,
    }
  );

  const allVendorsWireInfo = useQuery(
    ["vendors-wire-info"],
    () => {
      return Promise.all(
        allVendorIds.map((vendor) => getVendorWireInfo(vendor))
      );
    },
    {
      initialData: [],
      enabled: !!allVendorIds?.length,
    }
  );

  const artistsWithVendors = allArtists.data?.map((artist, index) => {
    return {
      artist,
      vendor: allVendors.data[index],
      wireInfo: allVendorsWireInfo.data[index],
    };
  });
  // console.log("artistsWithVendors", artistsWithVendors);

  // console.log("allVendorsWireInfo.data", allVendorsWireInfo.data);

  // console.log("allVendors.data", allVendors.data);

  // Override Mutations
  const createEventOverrideMutation = useMutation<
    FinalOverride,
    CaveError,
    CreateFinalOverrideData
  >({
    mutationFn: (data) =>
      createEventOverride(id, {
        ...data,
        book_name: settlementType,
      }),
    onSuccess: () => {
      queryClient.invalidateQueries(["event-overrides", id]);
      queryClient.invalidateQueries(["event-settlement", id]);
    },
    onError: (error) => {
      console.error("Failed to create override:", error);
    },
  });

  const updateEventOverrideMutation = useMutation<
    FinalOverride,
    CaveError,
    OverrideMutationVariables
  >({
    mutationFn: ({ data, overrideId }) => {
      if (!overrideId) throw new Error("Override ID is required for updates");
      return updateEventOverride(id, overrideId, {
        ...data,
        book_name: settlementType,
      });
    },
    onSuccess: () => {
      queryClient.invalidateQueries(["event-overrides", id]);
      queryClient.invalidateQueries(["event-settlement", id]);
    },
    onError: (error) => {
      console.error("Failed to update override:", error);
    },
  });

  const deleteEventOverrideMutation = useMutation<void, CaveError, number>({
    mutationFn: (overrideId) => deleteEventOverride(id, overrideId),
    onSuccess: () => {
      queryClient.invalidateQueries(["event-overrides", id]);
      queryClient.invalidateQueries(["event-settlement", id]);
    },
    onError: (error) => {
      console.error("Failed to delete override:", error);
    },
  });

  // Expense Mutations
  const createExpenseMutation = useMutation({
    mutationFn: (data: any) => createEventExpense(id, data),
    onSuccess: () => {
      queryClient.invalidateQueries(["event-expenses", id?.toString()]);
      queryClient.invalidateQueries([
        "event-expenses",
        id?.toString(),
        settlementType,
      ]);
    },
  });

  const updateExpenseMutation = useMutation({
    mutationFn: (data: any) => updateEventExpense(data),
    onSuccess: () => {
      queryClient.invalidateQueries(["event-expenses", id?.toString()]);
      queryClient.invalidateQueries([
        "event-expenses",
        id?.toString(),
        settlementType,
      ]);
    },
  });

  const deleteExpenseMutation = useMutation({
    mutationFn: (expenseId: number) => deleteExpense(expenseId),
    onSuccess: () => {
      queryClient.invalidateQueries(["event-detail", id]);
      queryClient.invalidateQueries(["variables-offer-pdf", id]);
      queryClient.invalidateQueries(["expenses-offer-pdf", id]);
    },
  });

  // Helper Functions
  const setPageIndex = (settlementType: SettlementTab) => {
    navigate(`/events/settlement/${settlementType}/${id}`);
    window.scrollTo(0, 0);
  };

  const handleUpdateEventSettlement = async ({
    book = settlementType,
    data,
    id = event.data?.id,
    key,
  }: UpdateEventSettlementParams) => {
    const fullData = eventSettlement.data || { [book]: {} };
    if (Array.isArray(data)) {
      fullData[book][key] = data;
    } else {
      fullData[book][key] = {
        ...fullData[book][key],
        ...data,
      };
    }

    await updateEventSettlement(id, settlementType, {
      [book]: {
        ...fullData[book],
      },
    });

    queryClient.invalidateQueries(["event-detail", id]);
    queryClient.invalidateQueries(["event-settlement", id]);
  };

  const handleUpdateEvent = async (
    id = event.data?.id,
    data: Record<string, unknown>
  ) => {
    await updateEvent(id, {
      ...event.data,
      artists: event.data?.artists?.map((artist) => artist.id),
      buyer: event.data?.buyer.id,
      manifests: event.data?.manifests?.map((manifest) => manifest.id),
      venue: event.data?.venue.id,
      ...data,
    });

    queryClient.invalidateQueries(["event-detail", id]);
    queryClient.invalidateQueries(["event-rollups", "artist", id]);
    event.refetch();
    rollups.refetch();
  };

  // Computed Values
  const chargebacks = expensesQuery.data?.filter(
    (expense) => expense.artist > 0 && expense.mammoth > 0
  );

  const chargebacksTotal = chargebacks?.reduce(
    (acc, chargeback) => acc + (chargeback.artist + chargeback.mammoth),
    0
  );

  const isPDF = location.pathname.includes("pdf");

  const manifests = event.data?.manifests;

  const generateRebates = () => {
    const rebates = manifests?.map((manifest) => {
      const rebateValue = calculateRebateValue(manifest.price);
      return {
        rebate: rebateValue.toFixed(2),
        total: rebateValue ? Number(manifest.qty) * Number(rebateValue) : 0,
      };
    });
    return rebates;
  };

  const rebatesTotal = (generateRebates() ?? []).reduce(
    (acc, curr) => acc + curr.total,
    0
  );

  return {
    // Queries
    event,
    eventSettlement,
    settlement,
    variables,
    people,
    rollups,
    expensesQuery,
    eventOverrides,
    artistsWithVendors,

    // Mutations
    createEventOverrideMutation,
    updateEventOverrideMutation,
    deleteEventOverrideMutation,
    createExpenseMutation,
    updateExpenseMutation,
    deleteExpenseMutation,

    // Helper Functions
    handleUpdateEvent,
    handleUpdateEventSettlement,
    setPageIndex,
    generateRebates,

    // Computed Values
    chargebacks,
    chargebacksTotal,
    isPDF,
    rebatesTotal,

    // Other Values
    id,
    pageIndex: settlementType,
    queryClient,
    settlementInputClassName: "bg-input-blue hover:bg-orange-400 duration-200",
  };
}
