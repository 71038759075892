import React, { useEffect, useState, useCallback } from "react";
import PropTypes from "prop-types";
import "./scrollbar-hide.css";
import { amountDisplay } from "@/utils/money";
import {
  FiChevronDown,
  FiChevronUp,
  FiSave,
  FiPlus,
  FiRefreshCw,
  FiEdit,
} from "react-icons/fi";
import { useQuery } from "@tanstack/react-query";
import { DragDropContext, Droppable, Draggable } from "@hello-pangea/dnd";
import { getExpenseGroups } from "@/queries/accounting";
import Select from "react-select";
import { toast } from "react-toastify";
import useProjectionsStore from "@/stores/projectionsStore";

// Expense section categories
const SECTIONS = [
  "DIRECT COSTS",
  "PRODUCTION",
  "OPERATIONS",
  "ADVERTISING",
  "OTHER VARIABLE",
];

// Main component
const OfferProjections = ({ manifests, eventId, offerId }) => {
  // Use the Zustand store
  const {
    financials,
    ticketCounts,
    expandedSections,
    isLoading,
    isSaving,
    fetchProjections,
    saveProjections,
    loadProjectionsFromExpenses,
    setTicketCounts,
    setFinancialField,
    toggleSection,
    addExpense,
    removeExpense,
    updateExpense,
    moveExpense,
    getExpensesBySection,
    calculateSectionTotal,
    calculateProjectedRevenue,
    calculateGrossMargin,
    calculateContributionMargin,
    initializeTicketCounts,
    updateGrossRevenue,
    resetState,
  } = useProjectionsStore();

  // Modal state is managed separately for simplicity
  const [showExpenseModal, setShowExpenseModal] = useState(false);
  const [currentExpenseSection, setCurrentExpenseSection] = useState(null);
  const [selectedGroup, setSelectedGroup] = useState(null);
  const [selectedSubgroup, setSelectedSubgroup] = useState(null);
  const [expenseAmount, setExpenseAmount] = useState("");
  const [expenseNotes, setExpenseNotes] = useState("");
  const [showGroupSelector, setShowGroupSelector] = useState(false);
  const [isEditing, setIsEditing] = useState(false);
  const [currentExpenseId, setCurrentExpenseId] = useState(null);

  // Reset state on unmount
  useEffect(() => {
    return () => {
      resetState();
    };
  }, [resetState]);

  // Initialization effect - only dependent on offer/event ID to prevent fetch loops
  useEffect(() => {
    let isMounted = true;

    async function initializeComponent() {
      if (offerId && !isNaN(parseInt(offerId)) && isMounted) {
        console.log(
          "Initializing projections component with offer ID:",
          offerId
        );

        // Step 1: Fetch projections from API
        await fetchProjections(offerId, eventId);
      }
    }

    initializeComponent();

    return () => {
      isMounted = false;
    };
  }, [offerId, eventId]); // CRITICAL: Removed fetchProjections from dependencies

  // Separate effect for ticket count initialization after data is loaded
  useEffect(() => {
    if (
      !isLoading &&
      manifests &&
      manifests.length > 0 &&
      Object.keys(ticketCounts).length === 0
    ) {
      console.log("No ticket counts from API - initializing from manifests");
      initializeTicketCounts(manifests);
    }
  }, [isLoading, manifests, ticketCounts, initializeTicketCounts]);

  // Expense groups query - no caching
  const { data: expenseGroups, error: expenseGroupsError } = useQuery(
    ["expense-groups"],
    () => getExpenseGroups(),
    {
      staleTime: 0,
      cacheTime: 30000,
      refetchOnWindowFocus: false,
      retry: 3,
      onError: (error) => {
        console.error("Error fetching expense groups:", error);
        toast.error("Failed to load expense categories");
      },
    }
  );

  // Force refresh of data
  const forceFreshRefetch = useCallback(() => {
    console.log("Forcing fresh data fetch");
    fetchProjections(offerId, eventId);
  }, [offerId, eventId, fetchProjections]);

  // Update revenue only when ticket counts change and we're not loading
  // Reduced dependencies to prevent unnecessary rerenders
  useEffect(() => {
    if (!isLoading && Object.keys(ticketCounts).length > 0) {
      // Use setTimeout to break the immediate update cycle
      const timer = setTimeout(() => {
        console.log("Ticket counts changed - updating revenue");
        updateGrossRevenue(manifests);
      }, 0);

      // Clean up timer to prevent updates on unmounted components
      return () => clearTimeout(timer);
    }
  }, [ticketCounts, manifests, isLoading]); // CRITICAL: Removed updateGrossRevenue from dependencies

  // Handle ticket count change
  const handleTicketCountChange = useCallback(
    (type, value) => {
      setTicketCounts(type, value);
    },
    [setTicketCounts]
  );

  // Handle financial field change
  const handleFinancialChange = useCallback(
    (field, value) => {
      setFinancialField(field, value);
    },
    [setFinancialField]
  );

  // Handle drag end (for expense reordering)
  const handleDragEnd = useCallback(
    (result) => {
      const { destination, source, draggableId } = result;

      if (
        !destination ||
        (destination.droppableId === source.droppableId &&
          destination.index === source.index)
      ) {
        return;
      }

      // Move expense from one section to another
      moveExpense(draggableId, source.droppableId, destination.droppableId);
    },
    [moveExpense]
  );

  // Handle adding expense for a section
  const handleAddExpenseForSection = useCallback(
    (section) => {
      console.log("Adding expense for section:", section);

      // Reset editing state
      setIsEditing(false);
      setCurrentExpenseId(null);

      // Find the appropriate group for this section
      let groupName = section;

      if (section === "DIRECT COSTS") groupName = "Direct Costs";
      else if (section === "PRODUCTION") groupName = "Production";
      else if (section === "OPERATIONS") groupName = "Operations";
      else if (section === "ADVERTISING") groupName = "Advertising";
      else if (section === "OTHER VARIABLE") groupName = "Other Variable";

      // Find matching group
      const groupForSection = expenseGroups?.find(
        (group) =>
          group.name.toLowerCase() === groupName.toLowerCase() ||
          group.name.toLowerCase().includes(groupName.toLowerCase()) ||
          groupName.toLowerCase().includes(group.name.toLowerCase())
      );

      setCurrentExpenseSection(section);
      setShowGroupSelector(false);

      if (groupForSection) {
        console.log("Found group for section:", groupForSection);

        setSelectedGroup({
          label: groupForSection.name,
          value: groupForSection.id.toString(),
        });

        // Pre-select first subgroup if available
        if (groupForSection.subgroups && groupForSection.subgroups.length > 0) {
          setSelectedSubgroup({
            label: groupForSection.subgroups[0].name,
            value: groupForSection.subgroups[0].id.toString(),
          });
        }
      } else {
        console.log("No group found for section:", section);
        setSelectedGroup(null);
        setShowGroupSelector(true);
      }

      setShowExpenseModal(true);
    },
    [expenseGroups]
  );

  // Handle editing an expense
  const handleEditExpense = useCallback(
    (expense) => {
      console.log("Editing expense:", expense);

      // Set editing state
      setIsEditing(true);
      setCurrentExpenseId(expense.id.toString()); // Ensure ID is a string
      setCurrentExpenseSection(expense.section);

      // Set form values
      setExpenseAmount(expense.amount.toString());
      setExpenseNotes(expense.notes || "");

      // Find and set the group
      if (expense.group && expenseGroups) {
        const group = expenseGroups.find(
          (g) => g.name.toLowerCase() === expense.group.toLowerCase()
        );

        if (group) {
          setSelectedGroup({
            label: group.name,
            value: group.id.toString(),
          });

          // Find and set the subgroup
          if (expense.subgroup && group.subgroups) {
            const subgroup = group.subgroups.find(
              (sg) => sg.name.toLowerCase() === expense.subgroup.toLowerCase()
            );

            if (subgroup) {
              setSelectedSubgroup({
                label: subgroup.name,
                value: subgroup.id.toString(),
              });
            }
          }
        }
      }

      // Show the modal
      setShowExpenseModal(true);
    },
    [expenseGroups]
  );

  // Handle closing expense modal
  const handleCloseExpenseModal = useCallback(() => {
    setShowExpenseModal(false);
    setSelectedGroup(null);
    setSelectedSubgroup(null);
    setExpenseAmount("");
    setExpenseNotes("");
    setCurrentExpenseSection(null);
    setShowGroupSelector(false);
    setIsEditing(false);
    setCurrentExpenseId(null);
  }, []);

  // Handle saving expense
  const handleSaveExpense = useCallback(() => {
    if (!selectedSubgroup) {
      toast.error("Please select an expense type");
      return;
    }

    if (!expenseAmount || isNaN(parseFloat(expenseAmount))) {
      toast.error("Please enter a valid amount");
      return;
    }

    if (isEditing && currentExpenseId) {
      // Update existing expense
      const updatedExpense = {
        id: currentExpenseId,
        group: selectedGroup?.label || "",
        subgroup: selectedSubgroup.label,
        subgroup_id: parseInt(selectedSubgroup.value),
        amount: parseFloat(expenseAmount),
        notes: expenseNotes || "",
        section: currentExpenseSection,
      };

      // Update expense in store
      updateExpense(updatedExpense);
      console.log("Updated expense:", updatedExpense);
      //toast.success("Expense updated successfully");

      // Close modal after updating
      handleCloseExpenseModal();
      return;
    } else {
      // Create unique ID for the expense
      const tempId = `temp-${Date.now()}-${Math.random()
        .toString(36)
        .substr(2, 9)}`;

      // Create expense object
      const newExpense = {
        id: tempId,
        group: selectedGroup?.label || "",
        subgroup: selectedSubgroup.label,
        subgroup_id: parseInt(selectedSubgroup.value),
        amount: parseFloat(expenseAmount),
        notes: expenseNotes || "",
        section: currentExpenseSection,
      };

      // Add expense
      addExpense(newExpense, currentExpenseSection);
      //toast.success("Expense added successfully");

      // Close modal after adding
      handleCloseExpenseModal();
      return;
    }
  }, [
    selectedSubgroup,
    selectedGroup,
    expenseAmount,
    expenseNotes,
    currentExpenseSection,
    handleCloseExpenseModal,
    addExpense,
    isEditing,
    currentExpenseId,
    updateExpense,
  ]);

  // Handle removing expense
  const handleRemoveExpense = useCallback(
    (expenseId) => {
      if (window.confirm("Are you sure you want to delete this expense?")) {
        removeExpense(expenseId);
      }
    },
    [removeExpense]
  );

  // Handle loading projections from expenses
  const handleLoadProjections = useCallback(() => {
    if (
      window.confirm(
        "Are you sure you want to recalculate projections from expenses? This will update all expense categories based on the latest mappings."
      )
    ) {
      loadProjectionsFromExpenses(eventId, offerId);
    }
  }, [eventId, offerId, loadProjectionsFromExpenses]);

  // Handle saving projections
  const handleSaveProjections = useCallback(() => {
    const getProjectionsFromQuery = () => {
      const query = ["offer-projections", offerId];
      return null; // Pass null to let Zustand handle creating vs updating
    };

    saveProjections(offerId, getProjectionsFromQuery());
  }, [offerId, saveProjections]);

  // Component rendering
  return (
    <DragDropContext onDragEnd={handleDragEnd}>
      {isLoading ? (
        <div
          className="bg-cave-black p-3 text-white flex items-center justify-center"
          style={{ minHeight: "200px" }}
        >
          <div className="text-center">
            <div className="inline-block animate-spin rounded-full h-8 w-8 border-t-2 border-b-2 border-blue-500 mb-2"></div>
            <p>Loading financial projections...</p>
          </div>
        </div>
      ) : (
        <div className="bg-cave-black p-3 text-white transition-all duration-300 ease-in-out overflow-y-auto max-h-[calc(70vh-60px)] overflow-x-hidden">
          {/* Expected Ticket Counts Section */}
          <div className="mb-3">
            <div
              className="bg-gray-800 p-2 rounded flex justify-between items-center cursor-pointer"
              onClick={() => toggleSection("ticketCounts")}
            >
              <h4 className="text-ku-yellow font-semibold">
                Expected Ticket Counts
              </h4>
              <div className="flex items-center">
                <span className="font-mono text-ku-yellow mr-2">
                  {amountDisplay(calculateProjectedRevenue(manifests))}
                </span>
                {expandedSections.ticketCounts ? (
                  <FiChevronUp size={16} />
                ) : (
                  <FiChevronDown size={16} />
                )}
              </div>
            </div>

            {expandedSections.ticketCounts && (
              <div className="mt-2 bg-gray-700 p-2 rounded-b">
                <table className="w-full">
                  <thead className="text-left text-sm text-gray-300">
                    <tr>
                      <th className="p-1 w-5/12">Ticket Type</th>
                      <th className="p-1 w-2/12 text-right">Price</th>
                      <th className="p-1 w-3/12 text-center">Count</th>
                      <th className="p-1 w-2/12 text-right">Subtotal</th>
                    </tr>
                  </thead>
                  <tbody>
                    {manifests && manifests.length > 0 ? (
                      manifests.map(
                        (manifest, index) =>
                          manifest.name && (
                            <tr
                              key={`ticket-${index}`}
                              className="border-b border-gray-600"
                            >
                              <td className="p-2 font-medium">
                                {manifest.name}
                              </td>
                              <td className="p-2 text-right">
                                ${(manifest.price / 100).toFixed(2)}
                              </td>
                              <td className="p-2">
                                <input
                                  type="text"
                                  inputMode="numeric"
                                  pattern="[0-9]*"
                                  className="w-full bg-gray-600 text-white p-2 text-center rounded border border-gray-500"
                                  value={ticketCounts[manifest.name] || ""}
                                  style={{
                                    minHeight: "36px",
                                    appearance: "textfield",
                                    minWidth: "80px",
                                  }}
                                  onChange={(e) =>
                                    handleTicketCountChange(
                                      manifest.name,
                                      e.target.value.replace(/[^0-9]/g, "")
                                    )
                                  }
                                />
                              </td>
                              <td className="p-2 text-right font-mono">
                                {amountDisplay(
                                  (ticketCounts[manifest.name] || 0) *
                                    manifest.price
                                )}
                              </td>
                            </tr>
                          )
                      )
                    ) : (
                      <tr>
                        <td
                          colSpan="4"
                          className="text-gray-400 italic p-4 text-center"
                        >
                          No ticket types defined
                        </td>
                      </tr>
                    )}
                  </tbody>
                  <tfoot>
                    <tr className="bg-gray-600">
                      <td colSpan="3" className="p-2 font-medium">
                        Total Projected Revenue:
                      </td>
                      <td className="p-2 text-right font-mono font-bold text-ku-yellow">
                        {amountDisplay(calculateProjectedRevenue())}
                      </td>
                    </tr>
                  </tfoot>
                </table>
              </div>
            )}
          </div>

          {/* Financial Projections Section */}
          <div className="mb-3">
            <div
              className="bg-gray-800 p-2 rounded flex justify-between items-center cursor-pointer"
              onClick={() => toggleSection("financialProjections")}
            >
              <h4 className="text-ku-yellow font-semibold">
                Financial Projections
              </h4>
              <div className="flex items-center">
                <span className="font-mono text-ku-yellow mr-2">
                  {amountDisplay(calculateGrossMargin())}
                </span>
                {expandedSections.financialProjections ? (
                  <FiChevronUp size={16} />
                ) : (
                  <FiChevronDown size={16} />
                )}
              </div>
            </div>

            {expandedSections.financialProjections && (
              <div className="mt-2 bg-gray-700 p-2 rounded-b">
                <div className="grid grid-cols-2 gap-2 mb-2">
                  <div>
                    <label className="block text-sm">
                      Projected Gross Revenue
                    </label>
                    <input
                      type="number"
                      className="w-full bg-gray-300 text-black p-1 rounded"
                      value={financials.grossRevenue || ""}
                      onChange={(e) =>
                        handleFinancialChange("grossRevenue", e.target.value)
                      }
                    />
                  </div>
                  <div>
                    <label className="block text-sm">Direct Costs</label>
                    <input
                      type="number"
                      className="w-full bg-gray-300 text-black p-1 rounded"
                      value={financials.directCosts || ""}
                      onChange={(e) =>
                        handleFinancialChange("directCosts", e.target.value)
                      }
                    />
                  </div>
                </div>
              </div>
            )}
          </div>

          {/* Action Buttons */}
          <div className="flex justify-end mb-3">
            <button
              onClick={forceFreshRefetch}
              disabled={isSaving || isLoading}
              className="bg-gray-600 hover:bg-gray-500 text-white font-bold py-1 px-3 rounded flex items-center mr-2"
            >
              <FiRefreshCw className="mr-1" /> Refresh
            </button>
            <button
              onClick={handleLoadProjections}
              disabled={isSaving || isLoading || !eventId}
              className="bg-gray-600 hover:bg-gray-500 text-white font-bold py-1 px-3 rounded flex items-center mr-2"
            >
              {isLoading ? (
                <>Loading...</>
              ) : (
                <>
                  <FiRefreshCw className="mr-1" /> ReCalculate{" "}
                </>
              )}
            </button>
            <button
              onClick={handleSaveProjections}
              disabled={isSaving || isLoading}
              className="bg-ku-blue hover:bg-blue-700 text-white font-bold py-1 px-3 rounded flex items-center"
            >
              {isSaving ? (
                <>Saving...</>
              ) : (
                <>
                  <FiSave className="mr-1" /> Save Projections
                </>
              )}
            </button>
          </div>

          {/* Variable Expenses Section */}
          <div className="mb-3">
            <div
              className="bg-gray-800 p-2 rounded flex justify-between items-center cursor-pointer mt-3"
              onClick={() => toggleSection("variableExpenses")}
            >
              <h4 className="text-ku-yellow font-semibold">
                Variable Expenses
              </h4>
              <div className="flex items-center">
                {expandedSections.variableExpenses ? (
                  <FiChevronUp size={16} />
                ) : (
                  <FiChevronDown size={16} />
                )}
              </div>
            </div>

            {expandedSections.variableExpenses && (
              <div className="grid grid-cols-2 gap-2 mt-2 bg-gray-700 p-2 rounded-b">
                <div>
                  <label className="block text-sm">Production</label>
                  <input
                    type="number"
                    className="w-full bg-gray-300 text-black p-1 rounded"
                    value={financials.production || ""}
                    onChange={(e) =>
                      handleFinancialChange("production", e.target.value)
                    }
                  />
                </div>
                <div>
                  <label className="block text-sm">Operations</label>
                  <input
                    type="number"
                    className="w-full bg-gray-300 text-black p-1 rounded"
                    value={financials.operations || ""}
                    onChange={(e) =>
                      handleFinancialChange("operations", e.target.value)
                    }
                  />
                </div>
                <div>
                  <label className="block text-sm">Advertising</label>
                  <input
                    type="number"
                    className="w-full bg-gray-300 text-black p-1 rounded"
                    value={financials.advertising || ""}
                    onChange={(e) =>
                      handleFinancialChange("advertising", e.target.value)
                    }
                  />
                </div>
                <div>
                  <label className="block text-sm">Other Variable</label>
                  <input
                    type="number"
                    className="w-full bg-gray-300 text-black p-1 rounded"
                    value={financials.variableExpenses || ""}
                    onChange={(e) =>
                      handleFinancialChange("variableExpenses", e.target.value)
                    }
                  />
                </div>
              </div>
            )}
          </div>

          {/* Projected Expenses Section */}
          <div>
            <h4 className="text-ku-yellow font-semibold mb-2 mt-4">
              Projected Expenses
            </h4>

            {/* Expense Sections */}
            {SECTIONS.map((section) => (
              <div className="mb-4" key={section}>
                <div
                  className="bg-gray-800 p-2 rounded flex justify-between items-center cursor-pointer"
                  onClick={() =>
                    toggleSection(section.toLowerCase().replace(" ", ""))
                  }
                >
                  <h5 className="text-ku-yellow font-semibold">{section}</h5>
                  <div className="flex items-center">
                    <span className="font-mono text-ku-yellow">
                      {amountDisplay(calculateSectionTotal(section))}
                    </span>
                    {expandedSections[
                      section.toLowerCase().replace(" ", "")
                    ] ? (
                      <FiChevronUp size={16} className="ml-2" />
                    ) : (
                      <FiChevronDown size={16} className="ml-2" />
                    )}
                  </div>
                </div>

                {expandedSections[section.toLowerCase().replace(" ", "")] && (
                  <>
                    {isLoading ? (
                      <div className="bg-gray-700 text-gray-400 text-center py-4 rounded-b">
                        <div className="spinner inline-block mr-2" />
                        Loading expenses...
                      </div>
                    ) : getExpensesBySection(section).length > 0 ? (
                      <div className="bg-gray-700 rounded-b overflow-hidden">
                        <Droppable droppableId={section}>
                          {(provided) => (
                            <div
                              ref={provided.innerRef}
                              {...provided.droppableProps}
                              className="min-h-[20px]"
                            >
                              <table className="w-full text-sm">
                                <thead className="bg-gray-600">
                                  <tr>
                                    <th className="p-1 text-left">
                                      Description
                                    </th>
                                    <th className="p-1 text-right">Amount</th>
                                    <th className="p-1 w-8"></th>
                                  </tr>
                                </thead>
                                <tbody>
                                  {getExpensesBySection(section).map(
                                    (expense, index) => (
                                      <Draggable
                                        key={expense.id.toString()}
                                        draggableId={expense.id.toString()}
                                        index={index}
                                      >
                                        {(provided, snapshot) => (
                                          <tr
                                            ref={provided.innerRef}
                                            {...provided.draggableProps}
                                            className={`border-t border-gray-600 hover:bg-gray-600 ${
                                              snapshot.isDragging
                                                ? "opacity-75 bg-blue-900"
                                                : ""
                                            }`}
                                          >
                                            <td className="p-1">
                                              <div className="flex items-center">
                                                <span
                                                  {...provided.dragHandleProps}
                                                  className="mr-2 cursor-move text-gray-400 hover:text-white"
                                                >
                                                  ⋮⋮
                                                </span>
                                                <div>
                                                  <div className="font-medium">
                                                    {expense.subgroup}
                                                  </div>
                                                  {expense.notes && (
                                                    <div className="text-xs text-gray-400">
                                                      {expense.notes}
                                                    </div>
                                                  )}
                                                </div>
                                              </div>
                                            </td>
                                            <td className="p-1 text-right font-mono">
                                              {amountDisplay(
                                                expense.amount * 100
                                              )}
                                            </td>
                                            <td className="p-1 w-16">
                                              <div className="flex justify-end">
                                                <button
                                                  onClick={() =>
                                                    handleEditExpense(expense)
                                                  }
                                                  className="text-blue-400 hover:text-blue-300 hover:bg-gray-700 rounded-full w-5 h-5 flex items-center justify-center mr-1"
                                                  title="Edit expense"
                                                >
                                                  <FiEdit size={12} />
                                                </button>
                                                <button
                                                  onClick={() =>
                                                    handleRemoveExpense(
                                                      expense.id
                                                    )
                                                  }
                                                  className="text-red-400 hover:text-red-300 hover:bg-gray-700 rounded-full w-5 h-5 flex items-center justify-center"
                                                  title="Remove expense"
                                                >
                                                  ×
                                                </button>
                                              </div>
                                            </td>
                                          </tr>
                                        )}
                                      </Draggable>
                                    )
                                  )}
                                  {provided.placeholder}
                                </tbody>
                                <tfoot className="bg-gray-600">
                                  <tr>
                                    <td className="p-1 font-medium">Total</td>
                                    <td className="p-1 text-right font-mono font-bold">
                                      {amountDisplay(
                                        calculateSectionTotal(section)
                                      )}
                                    </td>
                                    <td></td>
                                  </tr>
                                </tfoot>
                              </table>
                            </div>
                          )}
                        </Droppable>
                      </div>
                    ) : (
                      <div className="bg-gray-700 text-gray-400 italic text-center py-2 rounded-b">
                        No {section.toLowerCase()} expenses found.
                        {eventId ? (
                          <span>
                            {" "}
                            Check if there are offer expenses for event #
                            {eventId}.
                          </span>
                        ) : (
                          <span> No event selected.</span>
                        )}
                      </div>
                    )}
                    <button
                      onClick={() => handleAddExpenseForSection(section)}
                      className="mt-1 bg-gray-700 hover:bg-gray-600 text-white py-1 px-2 rounded text-sm flex items-center"
                    >
                      <FiPlus className="mr-1" size={14} /> Add {section}{" "}
                      Expense
                    </button>
                  </>
                )}
              </div>
            ))}

            <div className="bg-gray-700 p-2 rounded mt-3">
              <div className="flex justify-between">
                <span>Contribution Margin:</span>
                <span className="font-mono font-semibold text-ku-yellow">
                  {amountDisplay(calculateContributionMargin())}
                </span>
              </div>
            </div>
          </div>

          {/* Expense Modal */}
          {showExpenseModal && (
            <div className="fixed inset-0 bg-black bg-opacity-50 flex items-center justify-center z-[10002]">
              <div className="bg-gray-800 rounded-lg p-4 w-full max-w-md">
                <h3 className="text-white text-lg font-semibold mb-4">
                  {isEditing ? "Edit" : "Add"} {currentExpenseSection} Expense
                </h3>

                {expenseGroupsError && (
                  <div className="mb-3 text-red-400">
                    Error loading expense categories. Please try again later.
                  </div>
                )}

                {/* Group selection - only show when needed */}
                <div className={showGroupSelector ? "" : "hidden"}>
                  <label className="block text-sm text-gray-300 mb-1">
                    Expense Category
                  </label>
                  {!expenseGroups ||
                  !Array.isArray(expenseGroups) ||
                  expenseGroups.length === 0 ? (
                    <div className="bg-red-800 text-white p-2 mb-2 rounded">
                      No expense groups loaded. Please check console for errors.
                    </div>
                  ) : null}

                  <Select
                    options={
                      Array.isArray(expenseGroups)
                        ? expenseGroups.map((group) => ({
                            label: group.name,
                            value: group.id.toString(),
                          }))
                        : []
                    }
                    value={selectedGroup}
                    onChange={(selected) => {
                      console.log("Selected group:", selected);
                      setSelectedGroup(selected);
                      setSelectedSubgroup(null);
                    }}
                    placeholder="Select Expense Category"
                    className="text-black mb-3"
                    styles={{
                      control: (baseStyles) => ({
                        ...baseStyles,
                        background: "white",
                        borderColor: "#ccc",
                      }),
                      option: (baseStyles, state) => ({
                        ...baseStyles,
                        color: "black",
                        backgroundColor: state.isSelected
                          ? "#007bff"
                          : state.isFocused
                          ? "#f8f9fa"
                          : "white",
                      }),
                      menu: (baseStyles) => ({
                        ...baseStyles,
                        zIndex: 9999,
                      }),
                    }}
                    menuPortalTarget={document.body}
                  />
                </div>

                {/* Subgroup selection */}
                <div className="mb-3">
                  <label className="block text-sm text-gray-300 mb-1">
                    {currentExpenseSection} Expense Type
                  </label>
                  {selectedGroup && expenseGroups ? (
                    <Select
                      options={expenseGroups
                        .filter(
                          (group) => group.id.toString() === selectedGroup.value
                        )
                        .flatMap((group) =>
                          (group.subgroups || []).map((subgroup) => ({
                            label: subgroup.name,
                            value: subgroup.id.toString(),
                          }))
                        )}
                      value={selectedSubgroup}
                      onChange={setSelectedSubgroup}
                      placeholder="Select Expense Type"
                      className="text-black"
                      noOptionsMessage={() =>
                        "No expense types found for this category"
                      }
                      styles={{
                        control: (baseStyles) => ({
                          ...baseStyles,
                          background: "white",
                          borderColor: "#ccc",
                        }),
                        option: (baseStyles, state) => ({
                          ...baseStyles,
                          color: "black",
                          backgroundColor: state.isSelected
                            ? "#007bff"
                            : state.isFocused
                            ? "#f8f9fa"
                            : "white",
                        }),
                        menu: (baseStyles) => ({
                          ...baseStyles,
                          zIndex: 9999,
                        }),
                      }}
                      menuPortalTarget={document.body}
                    />
                  ) : (
                    <div className="bg-gray-700 p-2 rounded text-gray-400">
                      {expenseGroups
                        ? "Select a category first"
                        : "Loading expense categories..."}
                    </div>
                  )}
                </div>

                <div className="mb-3">
                  <label className="block text-sm text-gray-300 mb-1">
                    Amount
                  </label>
                  <div className="relative">
                    <span className="absolute left-2 top-2 text-gray-400">
                      $
                    </span>
                    <input
                      type="number"
                      step="0.01"
                      min="0"
                      className="w-full bg-gray-700 text-white p-2 pl-6 rounded"
                      value={expenseAmount}
                      onChange={(e) => setExpenseAmount(e.target.value)}
                      placeholder="0.00"
                    />
                  </div>
                </div>

                <div className="mb-4">
                  <label className="block text-sm text-gray-300 mb-1">
                    Notes (optional)
                  </label>
                  <textarea
                    className="w-full bg-gray-700 text-white p-2 rounded resize-none"
                    value={expenseNotes}
                    onChange={(e) => setExpenseNotes(e.target.value)}
                    placeholder="Add any additional details here"
                    rows={3}
                  />
                </div>

                <div className="flex justify-end space-x-2">
                  <button
                    onClick={handleCloseExpenseModal}
                    className="bg-gray-600 hover:bg-gray-500 text-white py-2 px-4 rounded"
                  >
                    Cancel
                  </button>
                  <button
                    onClick={handleSaveExpense}
                    className="bg-ku-blue hover:bg-blue-700 text-white py-2 px-4 rounded"
                    disabled={
                      !selectedSubgroup || !expenseAmount || !expenseGroups
                    }
                  >
                    {isEditing ? "Update" : "Add"} Expense
                  </button>
                </div>
              </div>
            </div>
          )}
        </div>
      )}
    </DragDropContext>
  );
};

OfferProjections.propTypes = {
  manifests: PropTypes.arrayOf(
    PropTypes.shape({
      name: PropTypes.string,
      price: PropTypes.number,
      qty: PropTypes.oneOfType([PropTypes.number, PropTypes.string]),
    })
  ),
  eventId: PropTypes.oneOfType([PropTypes.number, PropTypes.string]),
  offerId: PropTypes.oneOfType([PropTypes.number, PropTypes.string]),
};

export default OfferProjections;
