import React, { useState, useMemo, useRef, forwardRef } from "react";
import { useQuery, useMutation, useQueryClient } from "@tanstack/react-query";
import Select from "react-select";
import { stateChoices } from "@/utils/states.js";
import { getEventState, postEventState } from "@/queries/events.js";

const stateColors = {
  confirmed: {
    bg: "bg-green-500",
    hover: "hover:bg-green-600",
    text: "text-white",
  },
  offer: {
    bg: "bg-orange-500",
    hover: "hover:bg-orange-600",
    text: "text-white",
  },
  draft: { bg: "bg-blue-500", hover: "hover:bg-blue-600", text: "text-white" },
  default: {
    bg: "bg-gray-500",
    hover: "hover:bg-gray-600",
    text: "text-white",
  },
};

type StateOption = {
  value: string;
  label: string;
};

type StateColors = {
  bg: string;
  hover: string;
  text: string;
};

interface StateSwitcherProps {
  eventId: string | number;
  onStateChange?: () => void;
  size?: "xs" | "sm" | "md" | "lg";
  className?: string;
  cellMode?: boolean;
  isDisabled?: boolean;
}

export const CompactStateSwitcher = forwardRef<
  HTMLDivElement,
  StateSwitcherProps
>(
  (
    {
      eventId,
      onStateChange,
      size = "md",
      className = "",
      cellMode = false,
      isDisabled = false,
    },
    ref
  ) => {
    const queryClient = useQueryClient();
    const [errorMessage, setErrorMessage] = useState<string | null>(null);
    const selectRef = useRef<any>(null);

    const {
      data: currentStateData,
      isLoading,
      isError,
    } = useQuery(["eventState", eventId], () => getEventState(eventId), {
      staleTime: 30000,
    });

    const mutation = useMutation(
      (newState: string) => postEventState(eventId, { state: newState }),
      {
        onSuccess: () => {
          queryClient.invalidateQueries(["eventState", eventId]);
          setErrorMessage(null);
          if (onStateChange) onStateChange();
        },
        onError: () => {
          setErrorMessage("Transition Not Allowed");
          setTimeout(() => setErrorMessage(null), 3000);
        },
      }
    );

    const currentState = currentStateData?.state;
    const currentStateOption = stateChoices.find(
      (choice) => choice.value === currentState
    );

    const dropdownOptions = useMemo(
      () => stateChoices.filter((choice) => choice.value !== currentState),
      [currentState]
    );

    const getStateStyle = (state?: string): StateColors => {
      const stateKey = state?.toLowerCase();
      return (
        stateColors[stateKey as keyof typeof stateColors] || stateColors.default
      );
    };

    // Size configurations
    const sizeClasses = {
      xs: { container: "w-28", text: "text-xs py-1 px-1", height: "h-6" },
      sm: { container: "w-32", text: "text-xs py-1 px-2", height: "h-7" },
      md: { container: "w-36", text: "text-sm py-1.5 px-2", height: "h-8" },
      lg: { container: "w-40", text: "text-sm py-2 px-3", height: "h-10" },
    };

    const currentSize = sizeClasses[size];

    // Control height based on size
    const controlHeight = { xs: 24, sm: 28, md: 32, lg: 38 }[size];

    const customStyles = {
      control: (provided: any) => ({
        ...provided,
        backgroundColor: "transparent",
        border: "none",
        boxShadow: "none",
        cursor: "pointer",
        minHeight: `${controlHeight}px`,
        width: "100%",
      }),
      valueContainer: (provided: any) => ({
        ...provided,
        padding: size === "xs" || size === "sm" ? "0 4px" : "2px 8px",
      }),
      option: (provided: any, state: any) => ({
        ...provided,
        backgroundColor: state.isSelected
          ? getStateStyle(state.data.value).bg
          : state.isFocused
            ? "rgba(0,0,0,0.05)"
            : "white",
        color: state.isSelected ? "white" : "black",
        padding: size === "xs" || size === "sm" ? "4px 8px" : "8px 12px",
        fontSize: size === "xs" || size === "sm" ? "12px" : "14px",
      }),
      singleValue: (provided: any) => ({
        ...provided,
        color: "white",
        fontSize: size === "xs" || size === "sm" ? "12px" : "14px",
        margin: "0 2px",
      }),
      dropdownIndicator: (provided: any) => ({
        ...provided,
        padding: size === "xs" ? "0 2px" : size === "sm" ? "0 4px" : "8px",
      }),
      menu: (provided: any) => ({
        ...provided,
        width: "max-content",
        minWidth: "100%",
      }),
      menuList: (provided: any) => ({
        ...provided,
        paddingTop: "4px",
        paddingBottom: "4px",
      }),
    };

    if (isLoading) {
      return (
        <div
          className={`flex items-center justify-center ${currentSize.height} bg-gray-200 rounded ${currentSize.container}`}
        >
          <span className="animate-pulse text-xs">Loading...</span>
        </div>
      );
    }

    if (isError) {
      return (
        <div
          className={`flex items-center justify-center ${currentSize.height} bg-red-100 text-red-600 rounded ${currentSize.container}`}
        >
          <span className="text-xs">Error</span>
        </div>
      );
    }

    const stateStyle = getStateStyle(currentState);

    return (
      <div
        ref={ref}
        className={`relative ${currentSize.container} ${className}`}
      >
        <div
          className={`rounded ${stateStyle.bg} ${stateStyle.hover} ${stateStyle.text} 
          ${currentSize.text} ${mutation.isLoading ? "opacity-70" : ""}`}
        >
          <Select
            ref={selectRef}
            value={currentStateOption || null}
            onChange={(option) => {
              if (option && option.value !== currentState) {
                mutation.mutate(option.value);
              }
            }}
            options={dropdownOptions}
            isDisabled={mutation.isLoading || isDisabled}
            styles={customStyles}
            menuPortalTarget={document.body}
            menuPosition="fixed"
            menuPlacement="auto"
            className="w-full"
            classNamePrefix="rs"
            isSearchable={false}
            components={{
              IndicatorSeparator: () => null,
            }}
          />
        </div>

        {errorMessage && (
          <div className="absolute -bottom-6 left-0 right-0 text-center text-red-500 text-xs bg-white shadow-sm p-1 rounded z-10">
            {errorMessage}
          </div>
        )}
      </div>
    );
  }
);

CompactStateSwitcher.displayName = "CompactStateSwitcher";

// For backward compatibility
export const StateSwitcher = ({ eventId }: { eventId: string | number }) => (
  <CompactStateSwitcher eventId={eventId} />
);
