import React from "react";
import { useNavigate } from "react-router-dom";
import classNames from "classnames";
import { Bars3Icon, ChevronRightIcon } from "@heroicons/react/24/outline";

export interface TabMenuItem {
  label: string;
  href: string;
}

interface Props {
  tabMenuItems: TabMenuItem[];
}

const DashboardTableBottomTabs: React.FC<Props> = ({ tabMenuItems }) => {
  const navigate = useNavigate();

  return (
    <div className="border-t border-green-900/30 bg-cave-green">
      <div className="flex flex-wrap items-center justify-between">
        <div className="flex flex-wrap items-center flex-1">
          <button className="p-4 text-green-50 hover:bg-green-800/20">
            <Bars3Icon className="w-6 h-6" />
          </button>
          <div className="flex flex-wrap">
            {tabMenuItems.map(({ label, href }) => (
              <button
                key={label}
                onClick={() => navigate(href)}
                className={classNames(
                  "px-4 py-2 m-2 text-green-50 text-sm",
                  "hover:bg-green-800/20"
                )}
              >
                {label}
              </button>
            ))}
          </div>
        </div>
        <button className="p-4 text-green-50 hover:bg-green-800/20">
          <ChevronRightIcon className="w-6 h-6" />
        </button>
      </div>
    </div>
  );
};

export default DashboardTableBottomTabs;
