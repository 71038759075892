import React, { useState, useMemo } from "react";
import { useQuery } from "@tanstack/react-query";
import { Alert, TextInput } from "flowbite-react";
import { getActives } from "@/queries/auth.js";
import { HiSearch } from "react-icons/hi";
import { HiArrowSmDown, HiArrowSmUp } from "react-icons/hi";

const getActiveUsersSettings = {
  refetchInterval: 60000, // Refetch every minute
};

export const ActiveUsersDashboard = () => {
  const activeUsers = useQuery(
    ["activeUsers"],
    getActives,
    getActiveUsersSettings
  );

  const [searchTerm, setSearchTerm] = useState("");
  const [sortConfig, setSortConfig] = useState({
    key: null,
    direction: "asc",
  });

  // Handle search input changes
  const handleSearchChange = (e) => {
    setSearchTerm(e.target.value);
  };

  // Handle sorting when column headers are clicked
  const requestSort = (key) => {
    let direction = "asc";
    if (sortConfig.key === key && sortConfig.direction === "asc") {
      direction = "desc";
    }
    setSortConfig({ key, direction });
  };

  // Get a sort indicator component based on current sort config
  const getSortIndicator = (columnName) => {
    if (sortConfig.key !== columnName) return null;
    return sortConfig.direction === "asc" ? (
      <HiArrowSmUp className="inline ml-1" />
    ) : (
      <HiArrowSmDown className="inline ml-1" />
    );
  };

  // Filter and sort the data
  const filteredAndSortedUsers = useMemo(() => {
    if (!activeUsers.data) return [];

    // Filter users based on search term
    let filteredUsers = activeUsers.data.filter((user) => {
      const searchLower = searchTerm.toLowerCase();
      return (
        (user.email && user.email.toLowerCase().includes(searchLower)) ||
        (user.current_page &&
          user.current_page.toLowerCase().includes(searchLower)) ||
        (user.last_page && user.last_page.toLowerCase().includes(searchLower))
      );
    });

    // Sort users if a sort config is set
    if (sortConfig.key) {
      filteredUsers.sort((a, b) => {
        // Handle null values
        if (!a[sortConfig.key]) return 1;
        if (!b[sortConfig.key]) return -1;

        // For dates, we need special handling
        if (sortConfig.key === "last_activity") {
          const dateA = new Date(a[sortConfig.key]);
          const dateB = new Date(b[sortConfig.key]);
          return sortConfig.direction === "asc" ? dateA - dateB : dateB - dateA;
        }

        // Standard string comparison
        if (a[sortConfig.key] < b[sortConfig.key]) {
          return sortConfig.direction === "asc" ? -1 : 1;
        }
        if (a[sortConfig.key] > b[sortConfig.key]) {
          return sortConfig.direction === "asc" ? 1 : -1;
        }
        return 0;
      });
    }

    return filteredUsers;
  }, [activeUsers.data, searchTerm, sortConfig]);

  if (activeUsers.isLoading)
    return (
      <div className="text-center text-gray-700 dark:text-gray-300">
        Loading...
      </div>
    );
  if (activeUsers.error)
    return (
      <Alert color="failure">
        An error occurred: {activeUsers.error.message}
      </Alert>
    );

  if (!activeUsers.data || activeUsers.data.length === 0) {
    return <Alert color="info">No active users at the moment.</Alert>;
  }

  return (
    <div className="w-full bg-cave-white dark:bg-gray-800 shadow-md">
      <div className="px-4 sm:px-6 lg:px-8 py-6">
        <h1 className="text-2xl font-bold mb-6 text-gray-800 dark:text-cave-white">
          Active Users Dashboard
        </h1>

        {/* Search input */}
        <div className="mb-4">
          <TextInput
            type="text"
            icon={HiSearch}
            placeholder="Search users..."
            value={searchTerm}
            onChange={handleSearchChange}
            className="max-w-md"
          />
        </div>

        <div className="overflow-x-auto">
          <table className="w-full">
            <thead className="bg-gray-50 dark:bg-gray-700">
              <tr>
                <th
                  className="px-3 sm:px-6 py-3 text-left text-xs font-medium text-gray-500 dark:text-gray-300 uppercase tracking-wider cursor-pointer"
                  onClick={() => requestSort("email")}
                >
                  Email {getSortIndicator("email")}
                </th>
                <th
                  className="px-3 sm:px-6 py-3 text-left text-xs font-medium text-gray-500 dark:text-gray-300 uppercase tracking-wider cursor-pointer"
                  onClick={() => requestSort("last_activity")}
                >
                  Last Activity {getSortIndicator("last_activity")}
                </th>
                <th
                  className="px-3 sm:px-6 py-3 text-left text-xs font-medium text-gray-500 dark:text-gray-300 uppercase tracking-wider cursor-pointer"
                  onClick={() => requestSort("current_page")}
                >
                  Current Page {getSortIndicator("current_page")}
                </th>
                <th
                  className="px-3 sm:px-6 py-3 text-left text-xs font-medium text-gray-500 dark:text-gray-300 uppercase tracking-wider cursor-pointer"
                  onClick={() => requestSort("last_page")}
                >
                  Last Page {getSortIndicator("last_page")}
                </th>
              </tr>
            </thead>
            <tbody className="bg-cave-white divide-y divide-gray-200 dark:bg-gray-800 dark:divide-gray-700">
              {filteredAndSortedUsers.map((user, index) => (
                <tr
                  key={index}
                  className="hover:bg-gray-50 dark:hover:bg-gray-700"
                >
                  <td className="px-3 sm:px-6 py-4 whitespace-nowrap text-sm font-medium text-cave-black dark:text-cave-white">
                    {user.email || "N/A"}
                  </td>
                  <td className="px-3 sm:px-6 py-4 whitespace-nowrap text-sm text-gray-500 dark:text-gray-300">
                    {user.last_activity
                      ? new Date(user.last_activity).toLocaleString()
                      : "N/A"}
                  </td>
                  <td className="px-3 sm:px-6 py-4 whitespace-nowrap text-sm text-gray-500 dark:text-gray-300">
                    {user.current_page || "N/A"}
                  </td>
                  <td className="px-3 sm:px-6 py-4 whitespace-nowrap text-sm text-gray-500 dark:text-gray-300">
                    {user.last_page || "N/A"}
                  </td>
                </tr>
              ))}
            </tbody>
          </table>

          {/* No results message */}
          {filteredAndSortedUsers.length === 0 && searchTerm && (
            <div className="text-center py-4 text-gray-500 dark:text-gray-400">
              No users matching your search criteria
            </div>
          )}
        </div>
      </div>
    </div>
  );
};

export default ActiveUsersDashboard;
