import { classNames } from "primereact/utils";

interface ConfirmModalProps {
  onConfirm: () => void;
  onCancel: () => void;
  message?: string;
  show?: boolean;
}

export default function ConfirmModal({
  onConfirm,
  onCancel,
  message = "Are you sure you?",
  show = false,
}: ConfirmModalProps) {
  return (
    <div
      className={classNames(
        "fixed inset-0 bg-black bg-opacity-50 flex items-center justify-center z-[100]",
        show === false && "hidden"
      )}
    >
      <div className="bg-white text-black p-4 rounded-lg shadow-lg">
        <p>{message}</p>
        <div className="flex space-x-2 mt-2">
          <button
            onClick={onConfirm}
            className="bg-red-400 hover:bg-red-600 text-cave-white py-1 px-2 text-xs rounded"
            aria-label="Confirm Delete"
          >
            Confirm
          </button>
          <button
            onClick={onCancel}
            className="bg-gray-400 hover:bg-gray-600 text-cave-white py-1 px-2 text-xs rounded"
            aria-label="Cancel Delete"
          >
            Cancel
          </button>
        </div>
      </div>
    </div>
  );
}
