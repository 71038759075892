import { create } from "zustand";

import { patchManifest } from "@/queries/events";
export const FIELD_UPDATE_ANIMATION_TIME = 2000;

export interface Manifest {
  id: string;
  eventId: number;
  manifest_id: string;
  ticketing: string;
  capacity: number;
  net_price: number | string;
  name: string;
  qty: number;
  price: number | string;
  on_sale: string | null;
  comps: number;
  ticket_fees: number;
  gross_price: number;
  potential: number;
  kills: number;
  is_offer: boolean;
  sort_order: number;
}

interface ManifestStore {
  manifests: Manifest[];
  pendingPatches: { [id: string]: Set<keyof Manifest> };
  modifiedFields: { [id: string]: Set<keyof Manifest> };
  savedFields: { [id: string]: Set<keyof Manifest> };
  isSaving: boolean;
  initializeManifests: (manifests: Manifest[]) => void;
  updateLocalManifestField: <K extends keyof Manifest>(
    manifestId: string,
    field: K,
    value: Manifest[K]
  ) => void;
  patchManifestField: <K extends keyof Manifest>(
    manifestId: string,
    field: K,
    value: Manifest[K]
  ) => Promise<any>;
  addManifest: (newManifest: Manifest) => void;
  removeManifest: (manifestId: string) => void;
  updateManifests: (manifests: Manifest[]) => void;
  clearManifests: () => void;
}

export const useManifestStore = create<ManifestStore>((set, get) => ({
  manifests: [],
  pendingPatches: {},
  modifiedFields: {},
  savedFields: {},
  isSaving: false,

  initializeManifests: (manifests) => {
    set({
      manifests,
      pendingPatches: {},
      modifiedFields: {},
      savedFields: {},
      isSaving: false,
    });
  },

  updateLocalManifestField: (manifestId, field, value) => {
    set((state) => ({
      manifests: state.manifests.map((m) =>
        m.id === manifestId ? { ...m, [field]: value } : m
      ),
      modifiedFields: {
        ...state.modifiedFields,
        [manifestId]: new Set([
          ...(state.modifiedFields[manifestId] || []),
          field,
        ]),
      },
    }));
  },

  patchManifestField: async (manifestId, field, value) => {
    const state = get();
    const manifest = state.manifests.find((m) => m.id === manifestId);
    if (!manifest) {
      throw new Error("Manifest not found");
    }
    const previousValue = manifest[field];

    // Set pending and modified flags for this field
    set((state) => ({
      pendingPatches: {
        ...state.pendingPatches,
        [manifestId]: new Set([
          ...(state.pendingPatches[manifestId] || []),
          field,
        ]),
      },
      modifiedFields: {
        ...state.modifiedFields,
        [manifestId]: new Set([
          ...(state.modifiedFields[manifestId] || []),
          field,
        ]),
      },
      isSaving: true,
      manifests: state.manifests.map((m) =>
        m.id === manifestId ? { ...m, [field]: value } : m
      ),
    }));

    // Clear modified flag after the animation time
    setTimeout(() => {
      set((state) => {
        const mod = new Set(state.modifiedFields[manifestId] || []);
        mod.delete(field);
        return {
          modifiedFields: {
            ...state.modifiedFields,
            [manifestId]: mod,
          },
        };
      });
    }, FIELD_UPDATE_ANIMATION_TIME);

    try {
      const result = await patchManifest(manifest.manifest_id || manifest.id, {
        [field]: value,
      });
      // On success, clear pending and add to saved flags
      set((state) => {
        const pending = new Set(state.pendingPatches[manifestId] || []);
        pending.delete(field);
        const saved = new Set(state.savedFields[manifestId] || []);
        saved.add(field);
        return {
          pendingPatches: {
            ...state.pendingPatches,
            [manifestId]: pending,
          },
          savedFields: {
            ...state.savedFields,
            [manifestId]: saved,
          },
          isSaving: Object.values(state.pendingPatches).some((s) => s.size > 0),
        };
      });
      // Clear saved flag after the animation time
      setTimeout(() => {
        set((state) => {
          const saved = new Set(state.savedFields[manifestId] || []);
          saved.delete(field);
          return {
            savedFields: {
              ...state.savedFields,
              [manifestId]: saved,
            },
          };
        });
      }, FIELD_UPDATE_ANIMATION_TIME);
      return result;
    } catch (error) {
      // On error, revert change and clear flags
      set((state) => {
        const pending = new Set(state.pendingPatches[manifestId] || []);
        pending.delete(field);
        const mod = new Set(state.modifiedFields[manifestId] || []);
        mod.delete(field);
        return {
          pendingPatches: {
            ...state.pendingPatches,
            [manifestId]: pending,
          },
          modifiedFields: {
            ...state.modifiedFields,
            [manifestId]: mod,
          },
          manifests: state.manifests.map((m) =>
            m.id === manifestId ? { ...m, [field]: previousValue } : m
          ),
          isSaving: Object.values(state.pendingPatches).some((s) => s.size > 0),
        };
      });
      throw error;
    }
  },

  addManifest: (newManifest) => {
    set((state) => ({
      manifests: [...state.manifests, newManifest],
    }));
  },

  removeManifest: (manifestId) => {
    set((state) => ({
      manifests: state.manifests.filter((m) => m.id !== manifestId),
    }));
  },

  updateManifests: (manifests) => {
    set(() => ({ manifests }));
  },
  clearManifests: () => set(() => ({ manifests: [] })),
}));
