import { BreadCrumbs } from "@/components/BreadCrumbs";
import { DarkToggle } from "@/components/Header/TopHeader/DarkToggle";
import { Logo } from "@/components/Header/TopHeader/Logo";
import { UserMenu } from "@/components/Header/TopHeader/UserMenu";
import React from "react";
import { ToastContainer } from "react-toastify";

export default function AccountingLayout({
  children,
}: {
  children: React.ReactNode;
}) {
  return (
    <div className="min-h-screen bg-gray-900 text-white">
      <main className="px-4">{children}</main>
    </div>
  );
}
