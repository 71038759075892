import { toast } from "react-toastify";

import {
  getApiRequest,
  postApiRequest,
  putApiRequest,
  deleteApiRequest,
  patchApiRequest,
  jsonHeaders,
  makeRequest,
} from "./base";

export const getEventsCalendarSettings = {
  cacheTime: 30 * 60 * 1000,
  staleTime: 15 * 60 * 1000,
};

export const getEventsCalendar = async (data) => {
  try {
    const url = `/api/events/cal/calendar/`;
    return await postApiRequest(url, data);
  } catch (error) {
    console.error("Error fetching calendar events:", error);
    throw error;
  }
};

export const fetchYearlyEvents = async (data) => {
  const url = `/api/events/cal/calendar/year/`;
  return await postApiRequest(url, data);
};

export const getEventsSettings = {
  cacheTime: 30 * 60 * 1000,
  staleTime: 15 * 60 * 1000,
};

export const getEvents = async () => {
  const url = `/api/events/`;
  return await getApiRequest(url);
};

export const getEventsList = async ({ company_filter } = {}) => {
  const url = `/api/events/list/${
    company_filter ? `?company_filter=${company_filter}` : ""
  }`;
  return await getApiRequest(url);
};

export const getEventsListStaffing = async () => {
  const url = `/api/events/list/staffing/`;
  return await getApiRequest(url);
};

export const getEventsDashboardList = async () => {
  const url = `/api/events/list/dashboard/`;
  return await getApiRequest(url);
};

export const getMyDrafts = async () => {
  const url = `/api/events/list/mydrafts/`;
  return await getApiRequest(url);
};

export const getOnSaleEvents = async () => {
  const url = `/api/events/list/onsale/`;
  return await getApiRequest(url);
};

export const recordTickets = async (eventID, manifestID, data) => {
  const url = `/api/events/${eventID}/ticket-manifests/${manifestID}/`;
  return await postApiRequest(url, data);
};

export const getEvent = async (id, options) => {
  const queryString = options?.cache === false ? "?cache=false" : "";
  const url = `/api/events/${id}/${queryString}`;
  return await getApiRequest(url);
};

export const updateEvent = async (id, data) => {
  if (!id && id !== 0) throw new Error("Event ID is required");
  if (!data) throw new Error("Update data is required");

  const url = `/api/events/${id}/`;

  try {
    console.log("updateEvent - Request payload:", data); // Debug log

    // Ensure company field has a default value
    const normalizedData = {
      ...data,
      company:
        data.company === null || data.company === undefined
          ? null
          : data.company || "mammoth",
    };

    // Normalize buyers data if present
    if (normalizedData.buyers && Array.isArray(normalizedData.buyers)) {
      // Always normalize to person_id values
      normalizedData.buyers = normalizedData.buyers
        .map((buyer) => {
          // Extract the person_id consistently
          let personId;

          if (buyer && typeof buyer === "object") {
            // Only use person_id or value, never fall back to id
            personId = buyer.person_id || buyer.value;
          } else if (
            typeof buyer === "number" ||
            (typeof buyer === "string" && !isNaN(parseInt(buyer)))
          ) {
            personId = parseInt(buyer);
          }

          if (!personId) {
            console.warn("Could not extract person ID from buyer:", buyer);
            return null;
          }

          // Always return the person_id value, not the ID
          return personId;
        })
        .filter(Boolean); // Remove any null entries

      // Log the normalized buyers for debugging
      console.log("Normalized buyers for API request:", normalizedData.buyers);
    }

    // Validate required fields
    if (!normalizedData.name) {
      throw new Error("Event name is required");
    }
    if (!normalizedData.state) {
      throw new Error("Event state is required");
    }

    const headers = await jsonHeaders(true);
    const response = await fetch(url, {
      method: "PUT",
      headers,
      body: JSON.stringify(normalizedData),
      credentials: "same-origin",
    });

    if (!response.ok) {
      let errorMessage;
      try {
        const errorData = await response.json();
        if (typeof errorData === "object") {
          errorMessage = JSON.stringify(errorData, null, 2);
        } else {
          errorMessage =
            errorData.detail || errorData.message || "Server error occurred";
        }
      } catch {
        errorMessage = `HTTP Error ${response.status}`;
      }
      console.error("Server error response:", errorMessage); // Debug log
      throw new Error(errorMessage);
    }

    const responseData = await response.json();
    console.log("Server response:", responseData); // Debug log
    return responseData;
  } catch (error) {
    console.error("Error updating event:", error);
    throw error;
  }
};

// PATCH endpoint for partial update of an Event
export const patchEvent = async (id, data) => {
  if (!id && id !== 0) throw new Error("Event ID is required");
  if (!data) throw new Error("Patch data is required");

  const url = `/api/events/${id}/`;

  try {
    console.log("patchEvent - Request payload:", data);
    const normalizedData = {
      ...data,
    };

    // Normalize buyers data if present
    if (normalizedData.buyers && Array.isArray(normalizedData.buyers)) {
      normalizedData.buyers = normalizedData.buyers
        .map((buyer) => {
          let personId;
          if (buyer && typeof buyer === "object") {
            personId = buyer.person_id || buyer.value;
          } else if (
            typeof buyer === "number" ||
            (typeof buyer === "string" && !isNaN(parseInt(buyer)))
          ) {
            personId = parseInt(buyer);
          }
          if (!personId) {
            console.warn("Could not extract person ID from buyer:", buyer);
            return null;
          }
          return personId;
        })
        .filter(Boolean);
      console.log("Normalized buyers for API request:", normalizedData.buyers);
    }

    // Note: For PATCH, we do not require all required fields
    const headers = await jsonHeaders(true);
    const response = await fetch(url, {
      method: "PATCH",
      headers,
      body: JSON.stringify(normalizedData),
      credentials: "same-origin",
    });

    if (!response.ok) {
      let errorMessage;
      try {
        const errorData = await response.json();
        if (typeof errorData === "object") {
          errorMessage = JSON.stringify(errorData, null, 2);
        } else {
          errorMessage =
            errorData.detail || errorData.message || "Server error occurred";
        }
      } catch {
        errorMessage = `HTTP Error ${response.status}`;
      }
      console.error("Server error response (patchEvent):", errorMessage);
      throw new Error(errorMessage);
    }

    const responseData = await response.json();
    console.log("Server response (patchEvent):", responseData);
    return responseData;
  } catch (error) {
    console.error("Error patching event:", error);
    throw error;
  }
};

export const updateEventAdvance = async (id, data) => {
  if (!id) throw new Error("Event ID is required");
  if (!data || typeof data !== "object")
    throw new Error("Invalid advance data format");

  const url = `/api/events/${id}/advance/`;

  try {
    const headers = await jsonHeaders(true);
    const response = await fetch(url, {
      method: "PUT",
      headers,
      body: JSON.stringify(data),
      credentials: "same-origin",
    });

    if (!response.ok) {
      const errorData = await response.json();
      throw new Error(
        errorData.detail || `Failed to update advance: ${response.status}`
      );
    }

    const responseData = await response.json();
    return responseData.advance;
  } catch (error) {
    console.error("Error updating advance:", error);
    throw error;
  }
};

export const patchEventAdvance = async (id, data) => {
  if (!id) throw new Error("Event ID is required");
  if (!data || typeof data !== "object")
    throw new Error("Invalid advance data format");

  const url = `/api/events/${id}/advance/`;

  try {
    const headers = await jsonHeaders(true);
    const response = await fetch(url, {
      method: "PATCH",
      headers,
      body: JSON.stringify(data),
      credentials: "same-origin",
    });

    if (!response.ok) {
      const errorData = await response.json();
      throw new Error(
        errorData.detail || `Failed to patch advance: ${response.status}`
      );
    }

    const responseData = await response.json();
    return responseData.advance;
  } catch (error) {
    console.error("Error patching advance:", error);
    throw error;
  }
};

export const getEventAdvance = async (id) => {
  if (!id) throw new Error("Event ID is required");
  const url = `/api/events/${id}/advance/`;
  const response = await getApiRequest(url);
  return response;
};

export const updateEventAccounting = async (id, data) => {
  const url = `/api/events/accounting/${id}/`;
  return await putApiRequest(url, data);
};

// PATCH endpoint for partial update of event accounting
export const patchEventAccounting = async (id, data) => {
  if (!id) throw new Error("Event ID is required");
  const url = `/api/events/accounting/${id}/`;
  return await patchApiRequest(url, data);
};

export const createEvent = async (data) => {
  const url = `/api/events/`;
  return await postApiRequest(url, data);
};

export const getEventLinkSettings = {
  cacheTime: 30 * 60 * 1000,
  staleTime: 15 * 60 * 1000,
};

export const getEventRoles = async () => {
  const url = `/api/events/people/roles/`;
  return await getApiRequest(url);
};

export const getEventPeople = async (id) => {
  const url = `/api/events/people/${Number(id)}/`;
  return await getApiRequest(url);
};

export const createEventPeople = async (id, data) => {
  const url = `/api/events/people/add/${id}/`;
  return await postApiRequest(url, data);
};

export const deleteEventPeople = async (id) => {
  const url = `/api/events/people/remove/${id}/`;
  return await deleteApiRequest(url);
};

export const toggleEventMainPOC = async (id) => {
  const url = `/api/events/people/toggle-main-contact/${id}/`;
  return await postApiRequest(url);
};

export const getEventLinks = async (id) => {
  const url = `/api/events/links/${id}/`;
  return await getApiRequest(url);
};

export const createEventLink = async (id, data) => {
  const url = `/api/events/links/${id}/`;
  return await postApiRequest(url, data);
};

export const deleteEventLink = async (id) => {
  const url = `/api/events/link/${id}/`;
  return await deleteApiRequest(url);
};

export const getEventCommentSettings = {
  cacheTime: 10 * 60 * 1000,
  staleTime: 2 * 60 * 1000,
};

export const getEventComments = async (id) => {
  const url = `/api/events/comments/${id}/`;
  return await getApiRequest(url);
};

export const createEventComment = async (id, data) => {
  const url = `/api/events/comments/${id}/`;
  return await postApiRequest(url, data);
};

export const updateEventComment = async (id, data) => {
  const url = `/api/events/comment/${id}/`;
  return await putApiRequest(url, data);
};

export const deleteEventComment = async (id) => {
  const url = `/api/events/comment/${id}/`;
  return await deleteApiRequest(url);
};

export const getEventFileSettings = {
  cacheTime: 10 * 60 * 1000,
  staleTime: 2 * 60 * 1000,
};

export const getEventFiles = async (id, marketingOnly = false) => {
  const url = `/api/events/files/${id}/${
    marketingOnly ? "?marketing_only=true" : ""
  }`;
  return await getApiRequest(url);
};

export const createEventFile = async (eventId, data) => {
  console.log("createEventFile called with eventId:", eventId);
  console.log("Data to be sent:", data);

  const url = `/api/events/files/${eventId}/`;
  console.log("Request URL:", url);

  const headers = await jsonHeaders(true);
  headers.delete("Content-Type");

  try {
    // Log the incoming FormData
    console.log("Incoming FormData entries:");
    for (let [key, value] of data.entries()) {
      console.log(`${key}:`, value);
    }

    // Use the FormData directly since it's already properly structured
    const resp = await makeRequest(url, "POST", headers, data);
    const jsonResponse = await resp.json();
    console.log("createEventFile response:", jsonResponse);
    return jsonResponse;
  } catch (error) {
    console.error("Error in createEventFile:", error);
    throw error;
  }
};

export const updateEventFile = async (fileId, data) => {
  const url = `/api/events/file/${fileId}/`;
  return await patchApiRequest(url, data);
};

export const deleteEventFile = async (id) => {
  const url = `/api/events/file/${id}/`;
  return await deleteApiRequest(url);
};

export const getManifest = async (id, data) => {
  const url = `/api/events/manifest/${id}/`;
  return await getApiRequest(url);
};

export const updateManifest = async (id, data) => {
  const url = `/api/events/manifest/${id}/`;

  if (!id) {
    throw new Error("Manifest ID is required");
  }

  try {
    const headers = await jsonHeaders(true);
    const response = await fetch(url, {
      method: "PUT",
      headers,
      body: JSON.stringify(data),
      credentials: "same-origin",
    });

    const responseData = await response.json();

    if (!response.ok) {
      const errorMessage =
        responseData.detail ||
        responseData.message ||
        `HTTP Error ${response.status}`;
      console.error("Server error response:", responseData);
      throw new Error(errorMessage);
    }

    return responseData;
  } catch (error) {
    console.error("Error updating manifest:", error);
    const message =
      error instanceof Error ? error.message : "Unknown error occurred";
    throw new Error(`Failed to update manifest: ${message}`);
  }
};

export const patchManifest = async (manifestId, data) => {
  if (!manifestId) throw new Error("Manifest ID is required");
  const url = `/api/events/manifest/${manifestId}/`;
  return await patchApiRequest(url, data);
};

export const reorderManifests = async (eventId, data) => {
  if (!eventId) throw new Error("Event ID is required");
  const url = `/api/events/${eventId}/reorder-manifests/`;
  return await postApiRequest(url, data);
};

export const createEventManifest = async (id, data) => {
  const url = `/api/events/${id}/ticket-manifests/`;
  return await postApiRequest(url, data);
};

export const deleteEventManifest = async (eventID, manifestID) => {
  const url = `/api/events/${eventID}/delete-ticket-manifest/${manifestID}/`;
  return await postApiRequest(url);
};

export const markContractReceived = async (id) => {
  const url = `/api/events/contract-received/${id}/`;
  return await postApiRequest(url);
};

export const markContractReturned = async (id) => {
  const url = `/api/events/contract-returned/${id}/`;
  return await postApiRequest(url);
};

export const markContractFullyExecuted = async (id) => {
  const url = `/api/events/contract-fully-executed/${id}/`;
  return await postApiRequest(url);
};

export const createEventHolds = async (data) => {
  const url = `/api/events/make/holds/`;
  //return await postApiRequest(url);

  console.log("Creating event holds with data:", data);
  try {
    const response = await postApiRequest(url, { ...data });
    console.log("Event holds created successfully:", response);
    return response;
  } catch (error) {
    console.error("Error creating event holds:", error);
    throw error;
  }
};

export const getEventSettlementInfo = async (id) => {
  const url = `/api/events/settlement-info/${id}/`;
  return await getApiRequest(url);
};

export const getEventSettlement = async (id, book = "final") => {
  const url = `/api/accounting/event-settlement/${id}/${book}/`;
  return await getApiRequest(url);
};

export const updateEventSettlement = async (id, book, data) => {
  const url = `/api/accounting/event-settlement/${id}/${book}/`;
  return await putApiRequest(url, data);
};

export const getEventPDFs = async (id) => {
  const url = `/api/events/pdfs/${id}/`;
  return await getApiRequest(url);
};

export const createEventPDF = async (id, data) => {
  const url = `/api/events/pdf/create/${id}/`;
  return await postApiRequest(url, data);
};

export const updateEventOffer = async (id, data) => {
  const url = `/api/events/offer/${id}/`;
  console.log("[ContactTable] Sending update to API:", data);
  try {
    const result = await putApiRequest(url, data);
    console.log("[ContactTable] API response:", result);
    return result;
  } catch (error) {
    console.error("[ContactTable] API error:", error);
    throw error;
  }
};

export const getOffers = async () => {
  const url = `/api/offers/`;
  return await getApiRequest(url);
};

export const getOffer = async (id) => {
  const url = `/api/offers/${id}/`;
  return await getApiRequest(url);
};

export const updateOffer = async (id, data) => {
  const url = `/api/offers/${id}/`;
  return await putApiRequest(url, data);
};

// PATCH endpoint for partial update of an Offer
export const patchOffer = async (id, data) => {
  if (!id) throw new Error("Offer ID is required");
  const url = `/api/events/offer/${id}/`;
  return await patchApiRequest(url, data);
};

export const createOffer = async (data) => {
  const url = `/api/offers/`;
  return await postApiRequest(url, data);
};

export const addEventToOffer = async (eventId, offerId) => {
  const url = `/api/offers/${offerId}/add-event/${eventId}/`;
  return await postApiRequest(url);
};

export const getEventOfferVariables = async (id) => {
  const url = `/api/events/offer/variables/${id}/`;
  try {
    const response = await fetch(url, {
      method: "GET",
      credentials: "same-origin",
      headers: await jsonHeaders(),
    });
    if (!response.ok) {
      const errorData = await response.json();
      const errorMessage =
        errorData.detail || errorData.message || "An unknown error occurred";
      toast.error(errorMessage, {
        position: "top-center",
        autoClose: 5000,
        hideProgressBar: false,
        closeOnClick: true,
        pauseOnHover: true,
        draggable: true,
      });
      throw new Error(errorMessage);
    }
    return response.json();
  } catch (error) {
    console.error("Error fetching offer variables:", error);
    throw error;
  }
};

export const getEventImageSettings = {
  cacheTime: 10 * 60 * 1000,
  staleTime: 2 * 60 * 1000,
};

export const getEventImages = async (id) => {
  const url = `/api/events/images/${id}/`;
  return await getApiRequest(url);
};

export const createEventImage = async (id, data) => {
  const url = `/api/events/images/add/${id}/`;
  const headers = await jsonHeaders(true);
  headers.delete("Content-Type");
  const resp = await makeRequest(url, "POST", headers, data);
  return resp.json();
};

export const deleteEventImage = async (id) => {
  const url = `/api/events/images/delete/${id}/`;
  return await deleteApiRequest(url);
};
export const getEventsBBList = async () => {
  const url = `/api/events/list/bb/`; // Remove the exclude_dismissed parameter
  const data = await getApiRequest(url);
  return data.filter((event) => event && event.id); // Filter out null/undefined events
};

export const getEventState = async (id) => {
  const url = `/api/events/${id}/get-state/`;
  const data = await getApiRequest(url);
  return data;
};

export const postEventState = async (id, data) => {
  const url = `/api/events/${id}/set-state/`;
  const headers = await jsonHeaders(true);
  const resp = await fetch(url, {
    method: "POST",
    headers,
    body: JSON.stringify(data),
  });
  if (!resp.ok) {
    const errorData = await resp.json();
    throw new Error(errorData.detail || "Failed to update event state");
  }
  return resp.json();
};

export const getEventDate = async (id) => {
  const url = `/api/events/get-date/${id}/`;
  const data = await getApiRequest(url);
  return data;
};

export const postEventDate = async (id, data) => {
  const url = `/api/events/set-date/${id}/`;
  const headers = await jsonHeaders(true);
  const resp = await fetch(url, {
    method: "POST",
    headers,
    body: JSON.stringify(data),
  });
  if (!resp.ok) {
    const errorData = await resp.json();
    throw new Error(errorData.detail || "Failed to update event date");
  }
  return resp.json();
};

export const postEventDuplicate = async (id) => {
  const url = `/api/events/${id}/duplicate/`;
  const data = await postApiRequest(url);
  return data;
};

export const getBuyerEvents = async (buyerId, isFuture = true) => {
  const url = `/api/events/list/buyer/${buyerId}/?is_future=${isFuture}`;
  return await getApiRequest(url);
};

export const createEventImages = async (eventId, data) => {
  console.log("createEventImage called with eventId:", eventId);
  console.log("Data to be sent:", data);
  const url = `/api/events/images/${eventId}/`;
  console.log("Request URL:", url);
  const headers = await jsonHeaders(true);
  headers.delete("Content-Type");
  try {
    const resp = await makeRequest(url, "POST", headers, data);
    const jsonResponse = await resp.json();
    console.log("createEventImage response:", jsonResponse);
    return jsonResponse;
  } catch (error) {
    console.error("Error in createEventImage:", error);
    throw error;
  }
};

export const getOfferProjections = async (
  offerId,
  forceFresh = false,
  eventId = null
) => {
  if (!offerId || isNaN(parseInt(offerId))) {
    console.error("Invalid offer ID:", offerId);
    return [];
  }

  // Always use parseInt for consistent offer ID handling
  const exactOfferId = parseInt(offerId, 10);

  // Add eventId to request if available
  const queryParams = [`offer=${exactOfferId}`];
  if (eventId) {
    queryParams.push(`event=${eventId}`);
  }

  // Simplified cache busting parameters - just use timestamp and a random value
  const timestamp = Date.now();
  const randomParam = Math.random().toString(36).substring(2, 9);
  const requestId = `fetch-${timestamp}-${randomParam}`;

  // Add minimal but effective cache busting
  queryParams.push(
    `_=${timestamp}`,
    `nocache=${randomParam}`,
    `requestId=${requestId}`
  );

  const url = `/api/projections/?${queryParams.join("&")}`;

  console.log(
    `Fetching projections from: ${url} (forceFresh: ${forceFresh}, eventId: ${eventId}, exact offerId: ${exactOfferId})`
  );
  console.log(
    "Browser cache control: Timestamp =",
    timestamp,
    "RandomParam =",
    randomParam
  );

  try {
    const headers = {
      "Cache-Control": "no-cache, no-store, must-revalidate",
      Pragma: "no-cache",
      Expires: "0",
      "X-Requested-Offer": exactOfferId.toString(),
      "X-Request-ID": requestId,
      "X-Force-Fresh": forceFresh ? "true" : "false",
      "X-Timestamp": timestamp.toString(),
      "X-Cache-Control-Override": "no-cache",
      // Add additional headers for cache busting
      "X-Random": Math.random().toString(),
      "X-Date": new Date().toISOString(),
      "X-Client-Time": new Date().getTime().toString(),
    };

    // Add event ID to headers if available
    if (eventId) {
      headers["X-Requested-Event"] = eventId.toString();
    }

    const result = await getApiRequest(url, { headers });
    console.log("Projections result:", result);

    // Ensure projected_expenses is always an array
    if (result && result.length > 0) {
      result.forEach((projection) => {
        if (!projection.projected_expenses) {
          projection.projected_expenses = [];
        }
      });
    }

    return result;
  } catch (error) {
    console.error("Error fetching offer projections:", error);
    return [];
  }
};

export const getOfferProjectionsSettings = {
  cacheTime: 0, // Don't cache at all
  staleTime: 0, // Always consider data stale
  refetchOnWindowFocus: true, // Refetch when window gets focus
  refetchOnMount: true, // Always fetch when component mounts
  refetchInterval: false, // No automatic refetching on interval
  retry: 3,
  retryDelay: 1000,
};

export const updateOfferProjections = async (projectionId, data) => {
  if (!projectionId) throw new Error("Projection ID is required");
  const url = `/api/projections/${projectionId}/`;

  console.log("Updating offer projection with data:", data);

  try {
    // Make sure offer is an integer
    const normalizedData = {
      ...data,
      offer: parseInt(data.offer),
      // Ensure all decimal fields are properly formatted
      gross_revenue: parseFloat(data.gross_revenue) || 0,
      direct_costs: parseFloat(data.direct_costs) || 0,
      production_expenses: parseFloat(data.production_expenses) || 0,
      operations_expenses: parseFloat(data.operations_expenses) || 0,
      advertising_expenses: parseFloat(data.advertising_expenses) || 0,
      other_variable_expenses: parseFloat(data.other_variable_expenses) || 0,
    };

    // Ensure projected_expenses is properly formatted for the API
    // Let backend determine the section field based on group
    if (normalizedData.projected_expenses) {
      normalizedData.projected_expenses = normalizedData.projected_expenses.map(
        (expense) => ({
          id: expense.id, // Keep original ID
          subgroup_id: parseInt(expense.subgroup_id) || 0,
          subgroup: expense.subgroup || "", // Keep subgroup name
          group: expense.group || "", // Keep group name
          amount: parseFloat(expense.amount) || 0,
          notes: expense.notes || "",
          // Remove section field - backend will categorize consistently
        })
      );
    }

    // Send the normalized data directly - let Django Ninja handle the payload structure
    console.log("Sending to API directly:", normalizedData);

    // Use aggressive cache-busting headers
    const headers = {
      "Cache-Control": "no-cache, no-store, must-revalidate",
      Pragma: "no-cache",
      Expires: "0",
      "X-Requested-Offer": normalizedData.offer.toString(),
      "X-Request-ID": `update-${Date.now()}-${Math.random()
        .toString(36)
        .substring(2, 9)}`,
    };

    const result = await putApiRequest(url, normalizedData, headers);
    console.log("Projection updated successfully:", result);
    return result;
  } catch (error) {
    console.error("Error updating projection:", error);
    throw error;
  }
};

export const createOfferProjections = async (data) => {
  if (!data.offer) throw new Error("Offer ID is required");
  const url = `/api/projections/`;
  console.log("Creating offer projection with data:", data);

  try {
    // Make sure offer is an integer
    const normalizedData = {
      ...data,
      offer: parseInt(data.offer),
      // Ensure all decimal fields are properly formatted
      gross_revenue: parseFloat(data.gross_revenue) || 0,
      direct_costs: parseFloat(data.direct_costs) || 0,
      production_expenses: parseFloat(data.production_expenses) || 0,
      operations_expenses: parseFloat(data.operations_expenses) || 0,
      advertising_expenses: parseFloat(data.advertising_expenses) || 0,
      other_variable_expenses: parseFloat(data.other_variable_expenses) || 0,
    };

    // Ensure projected_expenses is properly formatted
    // Let backend determine the section field based on group
    if (normalizedData.projected_expenses) {
      normalizedData.projected_expenses = normalizedData.projected_expenses.map(
        (expense) => ({
          id: expense.id, // Keep original ID
          subgroup_id: parseInt(expense.subgroup_id) || 0,
          subgroup: expense.subgroup || "", // Keep subgroup name
          group: expense.group || "", // Keep group name
          amount: parseFloat(expense.amount) || 0,
          notes: expense.notes || "",
          // Remove section field - backend will categorize consistently
        })
      );
    }

    // Send the normalized data directly - let Django Ninja handle the payload structure
    console.log("Sending to API directly:", normalizedData);

    // Use aggressive cache-busting headers
    const headers = {
      "Cache-Control": "no-cache, no-store, must-revalidate",
      Pragma: "no-cache",
      Expires: "0",
      "X-Requested-Offer": normalizedData.offer.toString(),
      "X-Request-ID": `create-${Date.now()}-${Math.random()
        .toString(36)
        .substring(2, 9)}`,
    };

    const result = await postApiRequest(url, normalizedData, headers);
    console.log("Projection created successfully:", result);
    return result;
  } catch (error) {
    console.error("Error creating projection:", error);
    throw error;
  }
};

export const deleteOfferProjections = async (projectionId) => {
  if (!projectionId) throw new Error("Projection ID is required");
  const url = `/api/projections/${projectionId}/`;
  return await deleteApiRequest(url);
};

export const recalculateOfferProjections = async (offerId, eventId = null) => {
  if (!offerId) throw new Error("Offer ID is required");

  const url = `/api/projections/recalculate/${offerId}/`;

  try {
    // Prepare the request data
    const data = { event_id: eventId };

    // Use aggressive cache-busting headers
    const headers = {
      "Cache-Control": "no-cache, no-store, must-revalidate",
      Pragma: "no-cache",
      Expires: "0",
      "X-Requested-Offer": offerId.toString(),
      "X-Request-ID": `recalc-${Date.now()}-${Math.random()
        .toString(36)
        .substring(2, 9)}`,
    };

    // Add event ID to headers if available
    if (eventId) {
      headers["X-Requested-Event"] = eventId.toString();
    }

    const result = await postApiRequest(url, data, headers);
    console.log("Projections recalculated successfully:", result);
    return result;
  } catch (error) {
    console.error("Error recalculating projections:", error);
    throw error;
  }
};

export const getProjectionExpenses = async (projectionId) => {
  if (!projectionId) throw new Error("Projection ID is required");
  const url = `/api/projections/${projectionId}/expenses/`;
  return await getApiRequest(url);
};

export const addProjectionExpense = async (projectionId, data) => {
  if (!projectionId) throw new Error("Projection ID is required");
  const url = `/api/projections/${projectionId}/expenses/`;
  return await postApiRequest(url, data);
};

export const deleteProjectionExpense = async (expenseId) => {
  if (!expenseId) throw new Error("Expense ID is required");
  const url = `/api/projections/expenses/${expenseId}/`;
  return await deleteApiRequest(url);
};
