import React, { useEffect, useState } from "react";
import { CompanyLogoAnimation } from "@/components/Animations/CompanyLogoAnimation";
import { Header } from "@/components/Header";
import { useQuery } from "@tanstack/react-query";
import { useNavigate } from "react-router-dom";
import { BreadCrumbs } from "@/components/BreadCrumbs";
import Button from "@/components/Button";
import { getVendors, getVendorsSettings } from "@/queries/vendors";
import { usePageTitle } from "@/utils/pagetitle";
import { SortedTable } from "../../components/SortedTable/SortedTable";

const FilteredVendorsTable = ({
  vendors,
  headers,
  total,
  canEdit,
  title,
  handleEditClick,
}) => {
  const navigate = useNavigate();

  const formatData = (vendors) => {
    return (
      vendors &&
      vendors.map((manifest) =>
        Object.assign(manifest, { total: manifest.price * manifest.qty })
      )
    );
  };

  const handleFormatCell = (accessor, rowData, rowIndex) => {
    if (accessor === "on_sale") {
      return rowData["on_sale"]
        ? dayjs(rowData["on_sale"]).format("MMM D, YYYY")
        : "No Date";
    }
  };

  const handleSort = (key, item) => {
    if (key === "total") {
      return item["price"] * item["qty"];
    } else {
      return item[key];
    }
  };

  return (
    <SortedTable
      total={total}
      data={formatData(vendors)}
      headers={headers}
      customSort={handleSort}
      formatCell={handleFormatCell}
      onEditClick={canEdit ? handleEditClick : null}
      handleRowClick={(row, index) => navigate(`/vendors/${row.id}`)}
      hasSorting
      hasFilter
      initialSortKey="name"
      initialSortOrder="asc"
    />
  );
};

export const AgenciesHome = () => {
  const {
    data: vendors,
    isLoading,
    isSuccess,
  } = useQuery(["vendors"], getVendors, getVendorsSettings);

  const navigate = useNavigate();
  const [isFadingOut, setIsFadingOut] = useState(false);
  usePageTitle(
    isSuccess
      ? `/c${String.fromCharCode(92)}agencies`
      : `/c${String.fromCharCode(92)}ave_good...`
  );

  useEffect(() => {
    if (isLoading) {
      setIsFadingOut(false);
    } else if (isSuccess) {
      setTimeout(() => {
        setIsFadingOut(true);
      }, 1000);
    }
  }, [isLoading, isSuccess]);

  const [useLogoFallback, setUseLogoFallback] = useState(false);

  useEffect(() => {
    fetch("/static/images/cave_loading25.mp4", { method: "HEAD" })
      .then((res) => {
        if (!res.ok) {
          setUseLogoFallback(true);
        }
      })
      .catch(() => setUseLogoFallback(true));
  }, []);

  return (
    <div className="relative min-h-screen bg-cave-white dark:bg-cave-black">
      {isLoading &&
        (useLogoFallback ? (
          <CompanyLogoAnimation
            isLoading={isLoading}
            isFadingOut={isFadingOut}
          />
        ) : (
          <video
            autoPlay
            muted
            loop
            className={`fixed top-0 left-0 w-full h-full object-cover transition-opacity duration-1000 ${
              !isLoading || isFadingOut ? "opacity-0" : "opacity-100"
            }`}
          >
            <source src="/static/images/cave_loading25.mp4" type="video/mp4" />
          </video>
        ))}
      <div
        className={`transition-opacity duration-1000 ${
          isLoading && !isFadingOut ? "opacity-0" : "opacity-100"
        }`}
      >
        <Header />
        <div className="flex flex-col px-6 py-8 mx-auto min-h-screen lg:py-0 dark:text-cave-white">
          <div className="w-full flex justify-between items-center">
            <BreadCrumbs links={[{ text: "Agencies", url: "/agencies" }]} />
            <span>
              <Button onClick={() => navigate("/agencies/add")}>
                Add Agency
              </Button>
            </span>
          </div>
          <div className="my-2 w-full overflow-x-auto shadow-md">
            <FilteredVendorsTable
              vendors={vendors?.filter((vendor) => vendor.is_agency) || []}
              headers={[
                { header: "AGENCY NAME", accessor: "name" },
                { header: "POC NAME", accessor: "poc_name" },
                { header: "POC EMAIL", accessor: "poc_email" },
                { header: "POC PHONE", accessor: "poc_phone" },
                { header: "FUTURE EVENTS", accessor: "future_events" },
              ]}
            />
          </div>
        </div>
      </div>
    </div>
  );
};
