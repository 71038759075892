import { useSettlementComponent } from "@/hooks/useSettlementComponent";
import React from "react";
import SettlementRevenuesPL from "./SettlementRevenuesPL";
import ExpenseTable from "../ExpensesTable";
import DealAndFinalFigures from "./SettlementArtistDealSection";
import { Table, TableRow } from "../AppTable2";
import SettlementTicketManifests from "./SettlementTicketManifests";
import { amountDisplay } from "@/utils/money";
import { calculateRebateValue } from "@/helpers";

export default function SettlementFinalPage() {
  const { event, isPDF, generateRebates, rebatesTotal } =
    useSettlementComponent();

  const txtRebates = {
    title: "MAMMOTH TICKETED EVENTS ONLY",
    headers: [
      {
        title: "MAMMOTH TXT REBATES",
        accessor: "rebate",
        key: "rebate",
      },
      {
        title: "REBATE TOTAL",
        accessor: "total",
        editable: false,
        format: "money",
        textAlign: "right",
      },
    ],
    data: generateRebates(),
  };
  return (
    <div>
      <div className="bg-green-300 text-center py-1 font-bold text-black">
        INTERNAL FINAL SETTLEMENT
      </div>
      <SettlementTicketManifests />
      <ExpenseTable
        className="mb-8"
        id={event.data?.id}
        book="final"
        isPDF={isPDF}
        shared
      />
      <ExpenseTable
        title="ADDITIONAL INTERNAL SHOW EXPENSES"
        className="mb-8"
        id={event.data?.id}
        book="final"
        isPDF={isPDF}
        unique
      />
      <DealAndFinalFigures className="mb-8" hideDeal />
      {/* 2 empty divs to match size of final figures */}
      <div className="mb-8">
        {/* <div className="hidden lg:block"></div>
        <div className="hiddem lg:block"></div> */}
        <div>
          <div className="border p-2">
            <Table
              tableTitle={txtRebates.title}
              columns={txtRebates.headers}
              data={txtRebates.data}
            />
          </div>
          <TableRow
            columns={[
              {
                key: "name",
                accessor: "name",
                textAlign: "left",
              },
              {
                key: "value",
                accessor: "value",
                textAlign: "right",
              },
            ]}
            rowData={{
              name: "",
              value: amountDisplay(rebatesTotal),
            }}
            rowContainerClass="bg-blue-200 border-2 border-t-0 border-black"
          />
        </div>
      </div>
      <SettlementRevenuesPL />
    </div>
  );
}
