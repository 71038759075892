import { AgGridReact } from "ag-grid-react";
import { useState, useEffect, useCallback, useMemo } from "react";

import { amountDisplay } from "@/utils/money";

export const FIELD_UPDATE_ANIMATION_TIME = 2000;

const CapacityAgGrid = ({ event: propEvent, variables, updatedProperties }) => {
  const [rowData, setRowData] = useState([]);
  const [isDataReady, setIsDataReady] = useState(false);
  const event = useMemo(() => propEvent, [propEvent]);
  // Helper function to safely get financial values
  const getFinancialValue = (key) => {
    // Try primary location first
    if (variables && variables[key] !== undefined && variables[key] !== null) {
      return variables[key];
    }
    // Try nested in financials
    if (
      variables?.financials &&
      variables.financials[key] !== undefined &&
      variables.financials[key] !== null
    ) {
      return variables.financials[key];
    }
    // Look for variations of the key name
    const variations = {
      gross_potential: ["grossPotential", "gross"],
      ticket_fees: ["ticketFees", "fees"],
      adjusted_gross: ["adjustedGross", "adjusted"],
      total_tax: ["totalTax", "tax"],
      net_potential: ["netPotential", "net"],
    };

    if (variations[key]) {
      for (const variant of variations[key]) {
        if (
          variables &&
          variables[variant] !== undefined &&
          variables[variant] !== null
        ) {
          return variables[variant];
        }
        if (
          variables?.financials &&
          variables.financials[variant] !== undefined &&
          variables.financials[variant] !== null
        ) {
          return variables.financials[variant];
        }
      }
    }

    return 0; // Safe default
  };

  // Effect to track when data is ready
  useEffect(() => {
    if (variables) {
      setIsDataReady(true);
    }
  }, [variables]);

  // Initialize rowData
  useEffect(() => {
    if (variables) {
      // Get financial values
      const grossPotential = getFinancialValue("gross_potential");
      const ticketFees = getFinancialValue("ticket_fees");
      const adjustedGross = getFinancialValue("adjusted_gross");
      const totalTax = variables?.total_tax;
      const netPotential = getFinancialValue("net_potential");

      // Create the row data with raw numeric values instead of pre-formatted strings
      const newRowData = [
        {
          header: "TOTAL CAPACITY",
          value: variables?.total_capacity || "0",
          editable: false,
          isChanged:
            updatedProperties.includes("capacity") ||
            updatedProperties.includes("total_capacity"),
          msg: variables.venue_capacity
            ? `[Venue Capacity: ${variables.venue_capacity}]`
            : "",
        },
        {
          header: "GROSS POTENTIAL",
          value: grossPotential || 0, // Pass raw value
          isChanged: updatedProperties.includes("gross_potential"),
          editable: false,
          isMoney: true,
        },
        {
          header: "TICKET FEES",
          value: ticketFees || 0, // Pass raw value
          isChanged: updatedProperties.includes("ticket_fees"),
          editable: false,
          isMoney: true,
        },
        {
          header: "ADJUSTED GROSS",
          value: adjustedGross || 0, // Pass raw value
          isChanged: updatedProperties.includes("adjusted_gross"),
          editable: false,
          isMoney: true,
        },
        {
          header: "TAX PERCENTAGE",
          value: `${event?.venue.sales_tax || "0"}%`,
          isChanged: updatedProperties.includes("sales_tax"),
          highlight: true,
          editable: false,
        },
        {
          header: "TOTAL TAX",
          value: totalTax || 0, // Pass raw value
          isChanged: updatedProperties.includes("total_tax"),
          editable: false,
          isMoney: true,
        },
        {
          header: "NET POTENTIAL",
          value: netPotential || 0, // Pass raw value
          isChanged: updatedProperties.includes("net_potential"),
          editable: false,
          isMoney: true,
        },
      ];
      setRowData(newRowData);
    }
  }, [variables, updatedProperties]);

  // Column definitions for AgGrid
  const columnDefs = [
    {
      field: "header",
      flex: 1,
      suppressSizeToFit: true,
      editable: false,
      cellStyle: {
        fontFamily: "Montserrat, sans-serif",
        fontWeight: "bold",
        backgroundColor: "#E5E7EB", // Light gray background like in PDF
      },
      cellClass: (params) => (params.data.highlight ? "font-extrabold" : ""),
    },
    {
      field: "value",
      flex: 1,
      editable: false,
      cellStyle: {
        fontFamily: "Montserrat, sans-serif",
        fontWeight: "bold",
        textAlign: "right",
        backgroundColor: "#93C5FD", // Light blue background like in PDF
      },
      valueFormatter: (params) => {
        // Handle money formatting directly in the valueFormatter
        if (params.data.isMoney) {
          try {
            // Simple formatting for cents to dollars
            const value = params.value || 0;
            return new Intl.NumberFormat("en-US", {
              style: "currency",
              currency: "USD",
            }).format(value / 100);
          } catch (error) {
            console.error("Error formatting money value:", error);
            return "$0.00";
          }
        }
        // Return other values as-is
        return params.value;
      },
      cellClass: (params) => {
        return `${params.data.isChanged ? "glow-text" : ""} ${params.data.highlight ? "font-extrabold" : ""
          }`;
      },
    },
    {
      field: "msg",
      cellRenderer: (params) => {
        if (!params.data.msg) {
          return <span className="text-gray-400">No Notes</span>;
        }
        return <span className="text-gray-400">{params.data.msg}</span>;
      },
      flex: 1,
      editable: false,
      cellStyle: { fontFamily: "Montserrat, sans-serif" },
      hide: (params) => !params.data.msg,
    },
  ];

  // Default column definition
  const defaultColDef = {
    resizable: true,
    sortable: false,
    filter: false,
  };

  const onGridReady = useCallback((params) => {
    params.api.sizeColumnsToFit();

    // Add event listener for window resize
    const resizeObserver = new ResizeObserver(() => {
      setTimeout(() => {
        params.api.sizeColumnsToFit();
      }, 100);
    });

    // Observe the grid container
    const gridElement = document.querySelector(".ag-theme-alpine");
    if (gridElement) {
      resizeObserver.observe(gridElement);
    }

    // Also observe the window
    resizeObserver.observe(document.body);
  }, []);

  return (
    <div className="w-full">
      {!isDataReady ? (
        <div className="p-4 text-center bg-gray-100">
          Loading financial data...
        </div>
      ) : (
        <div
          className="ag-theme-alpine"
          style={{ height: "auto", width: "100%" }}
        >
          <AgGridReact
            rowData={rowData}
            columnDefs={columnDefs}
            defaultColDef={defaultColDef}
            domLayout="autoHeight"
            onGridReady={onGridReady}
            headerHeight={0}
            // Force refresh with these props
            immutableData={true}
            getRowId={(params) => params.data.header}
            rowHeight={35}
            rowClass="capacity-grid-row"
            className="capacity-compact-grid"
          />
          <style jsx>{`
            :global(.capacity-compact-grid) {
              --ag-grid-size: 4px;
              --ag-border-color: #dde2eb;
              border: 2px dashed #9ca3af;
            }
            :global(.capacity-grid-row) {
              border-bottom: 1px solid #e5e7eb;
            }
            :global(.capacity-grid-row:last-child) {
              border-bottom: none;
            }
            :global(.capacity-compact-grid .ag-cell) {
              padding: 8px 5px;
            }
          `}</style>
        </div>
      )}
    </div>
  );
};

export default CapacityAgGrid;
