import { ArrowsUpDownIcon } from "@heroicons/react/24/outline";
import { useMutation, useQueryClient } from "@tanstack/react-query";
import { AgGridReact } from "ag-grid-react";
import React, {
  useState,
  useEffect,
  useMemo,
  useCallback,
  useRef,
} from "react";
import { toast } from "react-toastify";

import { updateManifest, createEventManifest } from "@/queries/events";
import { amountDisplay, formatInputToMoney } from "@/utils/money";

export const FIELD_UPDATE_ANIMATION_TIME = 2000;

const ManifestAgGrid = ({ rows: rowsProp, eventId }) => {
  const queryClient = useQueryClient();
  const [rowData, setRowData] = useState([]);
  const [changedCells, setChangedCells] = useState([]);
  const [isDragging, setIsDragging] = useState(false);
  const [deletingRowId, setDeletingRowId] = useState(null);
  const gridRef = useRef(null);
  const lastRowsRef = useRef("");

  // Process incoming rows only if they change
  useEffect(() => {
    if (!rowsProp || rowsProp.length === 0) return;

    const stringifiedRows = JSON.stringify(rowsProp);
    if (stringifiedRows !== lastRowsRef.current) {
      const processed = rowsProp.map((row, index) => ({
        ...row,
        sort_order: row.sort_order || index + 1,
        id: row.id || `row-${index + 1}`,
        eventId: row.eventId,
        manifest_id: row.manifest_id || row.id,
        ticketing: row.name,
        capacity: row.qty,
        net_price: row.price,
        name: row.name,
        qty: row.qty,
        price: row.price,
        on_sale: row.on_sale,
        comps: row.comps || 0,
        ticket_fees: row.ticket_fees || 0,
        gross_price: amountDisplay(
          Number(row.price) + Number(row.ticket_fees || 0)
        ),
        potential: amountDisplay(
          (Number(row.price) + Number(row.ticket_fees || 0)) *
            Number(row.qty || 0)
        ),
        kills: row.kills || 0,
        is_offer: row.is_offer === undefined ? true : row.is_offer,
      }));
      setRowData(processed.sort((a, b) => a.sort_order - b.sort_order));
      lastRowsRef.current = stringifiedRows;
    }
  }, [rowsProp]);

  // Create new manifest mutation
  const createManifestMutation = useMutation({
    mutationFn: async (data) => {
      return await createEventManifest(eventId, {
        ...data,
        eventId,
        is_offer: true,
      });
    },
    onSuccess: () => {
      invalidateQueries();
      toast.success("New manifest created");
    },
    onError: (error) => {
      toast.error("Failed to create manifest");
      console.error("Error creating manifest:", error);
    },
  });

  // Update manifest mutation
  const updateManifestMutation = useMutation({
    mutationFn: async (data) => {
      const updateData = {
        ...data,
        kills: 0,
        is_offer: true,
        event: parseInt(eventId),
      };
      return await updateManifest(
        updateData.manifest_id || updateData.id,
        updateData
      );
    },
    onSuccess: () => {
      invalidateQueries();
    },
    onError: (error) => {
      console.error("Failed to update manifest:", error);
      toast.error("Failed to update manifest");
    },
  });

  // Delete manifest mutation
  const deleteManifestMutation = useMutation({
    mutationFn: async (manifestId) => {
      // Implement your delete function here
      return await updateManifest(manifestId, { active: false });
    },
    onSuccess: () => {
      invalidateQueries();
      toast.success("Manifest deleted");
    },
    onError: (error) => {
      console.error("Failed to delete manifest:", error);
      toast.error("Failed to delete manifest");
    },
  });

  // Batch update manifests mutation
  const batchUpdateManifestsMutation = useMutation({
    mutationFn: async (rows) => {
      const updatePromises = rows.map((row) => {
        // Skip temp rows without a real ID
        if (row.isTemp) return Promise.resolve();

        const updateData = {
          manifest_id: row.manifest_id || row.id,
          id: row.id,
          event: parseInt(eventId),
          name: row.name,
          sort_order: row.sort_order,
          price: row.price?.toString() || "0",
          ticket_fees: row.ticket_fees?.toString() || "0",
          qty: parseInt(row.qty) || 0,
          comps: parseInt(row.comps) || 0,
          active: true,
          is_offer: true,
          kills: 0,
        };
        return updateManifest(updateData.manifest_id, updateData);
      });

      return Promise.all(updatePromises);
    },
    onSuccess: () => {
      invalidateQueries();
      toast.success("Manifests reordered successfully");
    },
    onError: (error) => {
      console.error("Failed to batch update manifests:", error);
      toast.error("Failed to reorder manifests");
    },
  });

  // Helper function to invalidate all related queries
  const invalidateQueries = useCallback(() => {
    queryClient.invalidateQueries(["event-detail", eventId]);
    queryClient.invalidateQueries(["variables-offer-pdf", eventId]);
    queryClient.invalidateQueries(["expenses-offer-pdf", eventId]);
    queryClient.invalidateQueries(["event-rollups", eventId]);
    queryClient.invalidateQueries(["manifests", eventId]);
  }, [queryClient, eventId]);

  // Handle cell value changes
  const onCellValueChanged = useCallback(
    (params) => {
      if (params.oldValue === params.newValue) return;

      const cellKey = `${params.node.rowIndex}-${params.column.colId}`;
      setChangedCells((prev) => [...prev, cellKey]);

      setTimeout(() => {
        setChangedCells((prev) => prev.filter((cell) => cell !== cellKey));
      }, FIELD_UPDATE_ANIMATION_TIME);

      // If it's a temporary row, just update the local state
      if (params.data.isTemp) return;

      // Update the manifest with the new value
      const fieldToUpdate = {};

      switch (params.column.colId) {
        case "name":
        case "ticketing":
          fieldToUpdate.name = params.newValue;
          break;
        case "qty":
        case "capacity":
          fieldToUpdate.qty = parseInt(params.newValue) || 0;
          break;
        case "price":
        case "net_price":
          fieldToUpdate.price = params.newValue;
          break;
        case "ticket_fees":
          fieldToUpdate.ticket_fees = params.newValue;
          break;
        case "comps":
          fieldToUpdate.comps = parseInt(params.newValue) || 0;
          break;
        default:
          return; // If it's not a field we want to update, return early
      }

      // Extract the ID for updating
      const id = params.data.manifest_id || params.data.id;
      if (!id) return;

      // Submit the update
      updateManifestMutation.mutate({
        ...fieldToUpdate,
        manifest_id: id,
        id: params.data.id,
        name: params.data.name,
        sort_order: params.data.sort_order,
        price: params.data.price,
        ticket_fees: params.data.ticket_fees,
        qty: params.data.qty,
        comps: params.data.comps,
      });
    },
    [updateManifestMutation]
  );

  // Row drag handlers
  const onRowDragStart = useCallback(() => {
    setIsDragging(true);
  }, []);

  const onRowDragEnd = useCallback(
    (dragEvent) => {
      setIsDragging(false);
      const fromIndex = dragEvent.node.rowIndex;
      const toIndex = dragEvent.overIndex;

      if (fromIndex === toIndex) return;

      const updated = [...rowData];
      const [moved] = updated.splice(fromIndex, 1);
      updated.splice(toIndex, 0, moved);

      // Update the sort_order of all rows
      const reordered = updated.map((row, index) => ({
        ...row,
        sort_order: index + 1,
        on_sale: row.on_sale
          ? new Intl.DateTimeFormat("en-CA").format(new Date(row.on_sale))
          : row.on_sale,
      }));

      // Update local state immediately for better UX
      setRowData(reordered);

      // Batch update all rows to the server
      batchUpdateManifestsMutation.mutate(reordered);
    },
    [rowData, batchUpdateManifestsMutation]
  );

  // Handle reordering for when AG Grid's drag-drop might not work
  const handleReorderManifests = useCallback(() => {
    const updatedData = [...rowData].map((row, index) => ({
      ...row,
      sort_order: index + 1,
    }));

    setRowData(updatedData);
    batchUpdateManifestsMutation.mutate(updatedData);
  }, [rowData, batchUpdateManifestsMutation]);

  // Delete handlers
  const handleDeleteInitiate = useCallback((rowId) => {
    setDeletingRowId(rowId);
  }, []);

  const handleDeleteConfirm = useCallback(
    (rowId) => {
      // If it's a temp row, just remove it from the local state
      const rowToDelete = rowData.find((row) => row.id === rowId);

      if (rowToDelete?.isTemp) {
        setRowData((prev) => prev.filter((row) => row.id !== rowId));
        setDeletingRowId(null);
        return;
      }

      // Otherwise delete from the backend
      deleteManifestMutation.mutate(rowToDelete.manifest_id || rowId);
      setDeletingRowId(null);
    },
    [rowData, deleteManifestMutation]
  );

  const handleDeleteCancel = useCallback(() => {
    setDeletingRowId(null);
  }, []);

  // Add temporary row
  const handleAddRow = useCallback(() => {
    const tempId = `temp-${Date.now()}`;
    const newRow = {
      id: tempId,
      isTemp: true,
      name: "New Manifest",
      qty: 0,
      price: "0",
      ticket_fees: "0",
      comps: 0,
      sort_order: rowData.length + 1,
      gross_price: "$0.00",
      potential: "$0.00",
    };

    setRowData((prev) => [...prev, newRow]);

    // Scroll to the new row
    setTimeout(() => {
      if (gridRef.current && gridRef.current.api) {
        gridRef.current.api.ensureIndexVisible(rowData.length);
      }
    }, 100);
  }, [rowData]);

  // Save temporary row
  const handleSaveRow = useCallback(
    (tempRowData) => {
      // Create the manifest data
      const newManifestData = {
        name: tempRowData.name || "New Manifest",
        event: parseInt(eventId),
        qty: parseInt(tempRowData.qty) || 0,
        price: tempRowData.price || "0",
        ticket_fees: tempRowData.ticket_fees || "0",
        sort_order: tempRowData.sort_order,
        active: true,
        is_offer: true,
        comps: parseInt(tempRowData.comps) || 0,
      };

      // Create the new manifest
      createManifestMutation.mutate(newManifestData, {
        onSuccess: () => {
          // Remove the temp row after successful creation
          setRowData((prev) => prev.filter((row) => row.id !== tempRowData.id));
        },
      });
    },
    [eventId, createManifestMutation]
  );

  // Column definitions
  const dragHandleCol = useMemo(
    () => ({
      rowDrag: true,
      width: 40,
      lockPosition: "left",
      suppressMenu: true,
      valueGetter: (params) => !params.data?.isTemp, // Disable drag for temp rows
      cellRenderer: (params) => {
        if (params.data?.isTemp) return "";
        return (
          <div className="flex h-full items-center justify-center cursor-grab active:cursor-grabbing">
            <ArrowsUpDownIcon className="w-4 h-4 text-gray-500" />
          </div>
        );
      },
    }),
    []
  );

  const columnDefs = useMemo(
    () => [
      dragHandleCol,
      {
        field: "name",
        headerName: "TICKETING",
        editable: true,
        width: 200,
        cellClass: (params) => {
          const classes = [];
          if (
            changedCells.includes(
              `${params.node.rowIndex}-${params.column.colId}`
            )
          ) {
            classes.push("bg-orange-100 transition-colors duration-2000");
          }
          if (isDragging) {
            classes.push("opacity-70");
          }
          return classes.join(" ");
        },
      },
      {
        field: "qty",
        headerName: "CAPACITY",
        editable: true,
        width: 120,
        cellClass: (params) => {
          const classes = [];
          if (
            changedCells.includes(
              `${params.node.rowIndex}-${params.column.colId}`
            )
          ) {
            classes.push("bg-orange-100 transition-colors duration-2000");
          }
          if (isDragging) {
            classes.push("opacity-70");
          }
          return classes.join(" ");
        },
      },
      {
        field: "comps",
        headerName: "COMPS",
        editable: true,
        width: 120,
        cellClass: (params) => {
          const classes = [];
          if (
            changedCells.includes(
              `${params.node.rowIndex}-${params.column.colId}`
            )
          ) {
            classes.push("bg-orange-100 transition-colors duration-2000");
          }
          if (isDragging) {
            classes.push("opacity-70");
          }
          return classes.join(" ");
        },
      },
      {
        field: "price",
        headerName: "NET PRICE",
        editable: true,
        width: 120,
        valueFormatter: (params) => formatInputToMoney(String(params.value)),
        cellClass: (params) => {
          const classes = [];
          if (
            changedCells.includes(
              `${params.node.rowIndex}-${params.column.colId}`
            )
          ) {
            classes.push("bg-orange-100 transition-colors duration-2000");
          }
          if (isDragging) {
            classes.push("opacity-70");
          }
          return classes.join(" ");
        },
      },
      {
        field: "ticket_fees",
        headerName: "TICKET FEES",
        editable: true,
        width: 120,
        valueFormatter: (params) => formatInputToMoney(String(params.value)),
        cellClass: (params) => {
          const classes = [];
          if (
            changedCells.includes(
              `${params.node.rowIndex}-${params.column.colId}`
            )
          ) {
            classes.push("bg-orange-100 transition-colors duration-2000");
          }
          if (isDragging) {
            classes.push("opacity-70");
          }
          return classes.join(" ");
        },
      },
      {
        field: "gross_price",
        headerName: "GROSS PRICE",
        editable: false,
        width: 120,
        valueFormatter: (params) => formatInputToMoney(String(params.value)),
        cellClass: (params) => {
          if (isDragging) {
            return "opacity-70";
          }
          return "";
        },
      },
      {
        field: "potential",
        headerName: "POTENTIAL",
        editable: false,
        width: 120,
        valueFormatter: (params) => formatInputToMoney(String(params.value)),
        cellClass: (params) => {
          if (isDragging) {
            return "opacity-70";
          }
          return "";
        },
      },
      {
        field: "actions",
        headerName: "",
        width: 125,
        suppressMovable: true,
        lockPosition: "right",
        cellRenderer: (params) => {
          // For temporary rows, show Save/Cancel buttons
          if (params.data.isTemp) {
            return (
              <div className="flex justify-end gap-1 p-1 text-sm font-montserrat">
                <button
                  className="bg-green-500 hover:bg-green-600 text-white py-1 px-2 text-xs rounded"
                  onClick={() => handleSaveRow(params.data)}
                >
                  Save
                </button>
                <button
                  className="bg-gray-400 hover:bg-gray-600 text-white py-1 px-2 text-xs rounded"
                  onClick={() => handleDeleteConfirm(params.data.id)}
                >
                  Cancel
                </button>
              </div>
            );
          }

          // For regular rows, show Delete button or confirmation
          return (
            <div className="flex justify-end gap-1 p-1 text-sm font-montserrat">
              {deletingRowId === params.data.id ? (
                <>
                  <button
                    className="bg-red-400 hover:bg-red-600 text-white py-1 px-2 text-xs rounded"
                    onClick={() => handleDeleteConfirm(params.data.id)}
                  >
                    Confirm
                  </button>
                  <button
                    className="bg-gray-400 hover:bg-gray-600 text-white py-1 px-2 text-xs rounded"
                    onClick={handleDeleteCancel}
                  >
                    Cancel
                  </button>
                </>
              ) : (
                <button
                  className="bg-red-400 hover:bg-red-600 text-white py-1 px-2 text-xs rounded"
                  onClick={() => handleDeleteInitiate(params.data.id)}
                >
                  Delete
                </button>
              )}
            </div>
          );
        },
      },
    ],
    [
      changedCells,
      isDragging,
      deletingRowId,
      handleSaveRow,
      handleDeleteConfirm,
      handleDeleteCancel,
      handleDeleteInitiate,
    ]
  );

  const defaultColDef = useMemo(
    () => ({
      resizable: true,
      sortable: false,
      filter: false,
    }),
    []
  );

  const memoizedRowData = useMemo(() => rowData, [rowData]);

  // Grid configuration for row dragging
  const gridOptions = useMemo(
    () => ({
      rowDragManaged: true,
      animateRows: false,
      suppressMoveWhenRowDragging: false,
      getRowId: (params) => params.data.id,
    }),
    []
  );

  if (!rowsProp) return <div>Loading...</div>;

  return (
    <>
      <div className="w-full grid border border-dashed border-gray-300 bg-gray-100">
        <div
          className={`ag-theme-alpine ${isDragging ? "dragging" : ""}`}
          style={{ width: "100%", minHeight: "200px" }}
        >
          <AgGridReact
            ref={gridRef}
            rowData={memoizedRowData}
            columnDefs={columnDefs}
            defaultColDef={defaultColDef}
            onCellValueChanged={onCellValueChanged}
            onRowDragStart={onRowDragStart}
            onRowDragEnd={onRowDragEnd}
            rowDragMultiRow={false}
            domLayout="autoHeight"
            suppressRowHoverHighlight={isDragging}
            getRowClass={(params) =>
              params.data.isTemp
                ? "bg-green-50"
                : isDragging
                  ? "opacity-80 transition-transform duration-200"
                  : ""
            }
            getRowId={(params) => params.data.id}
            immutableData={true}
            suppressChangeDetection={true}
            suppressCellFlash={true}
            // enableCellChangeFlash={false}
            animateRows={false}
            suppressRowTransform={true}
            suppressMovableColumns={true}
            rowDragManaged={true}
            {...gridOptions}
          />
        </div>
      </div>

      <div className="flex mt-2 gap-2">
        <button
          className="bg-gray-400 hover:bg-gray-500 rounded px-4 py-1 text-white font-sans text-lg"
          onClick={handleAddRow}
        >
          Add Manifest
        </button>

        <button
          className="bg-blue-400 hover:bg-blue-500 rounded px-4 py-1 text-white font-sans text-lg"
          onClick={handleReorderManifests}
        >
          Save Order
        </button>
      </div>
    </>
  );
};

export default ManifestAgGrid;
