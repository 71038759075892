import { Dialog, Switch, Transition } from "@headlessui/react";
import { useQuery, useQueryClient, useMutation } from "@tanstack/react-query";
import {
  ColDef,
  GridApi,
  GetQuickFilterTextParams,
  ICellRendererParams,
} from "ag-grid-community";
import { AgGridReact } from "ag-grid-react";
import React, { useState, useEffect, Fragment } from "react";
import Select from "react-select";
import AsyncSelect from "react-select/async";
import { toast } from "react-toastify";

import DashboardTableBottomTabs from "./DashboardBottomTabs";
import { DateCellRenderer, ImageNameCellRenderer } from "./StaffingDashboard";
import AccountingDashboardBulletinBoard from "../AccountingDashboard/AccountingDashboardBulletinBoard";
import AccountingDashboardTasks from "../AccountingDashboard/AccountingDashboardTasks";
import Button from "../Button";
import DashboardSearch from "../Common/DashboardSearch";
import Ripple from "../Common/Ripple";
import { accountingDashboardEarlyAccessUsers } from "@/constants";
import {
  extractRoleFromEventListStaffing,
  hasAdminAccountingRights,
  matchHighlightFormatter,
} from "@/helpers";
import { useUser } from "@/hooks/useUser";
import AccountingLayout from "@/layouts/AccountingLayout";
import { createAccountingMessage } from "@/queries/accounting";
import {
  getEventsList,
  getEventsListStaffing,
  getEventsSettings,
  getEventRoles,
  getEventPeople,
  createEventPeople,
  getEvent,
} from "@/queries/events";
import { deletePersonFromEvent, getPeople } from "@/queries/people";
import { createTask } from "@/queries/tasks";
import { CaveError } from "@/types";
import { CaveEvent, CaveEventStaffing } from "@/types/event";
import { USER_ROLES } from "@/types/user";
import { usePageTitle } from "@/utils/pagetitle";

interface Person {
  id: number;
  name: string;
}

interface EventData {
  id: number;
  name: string;
  venue_name: string;
  date: string;
  accountAssign?: Person[] | Person | string;
}

interface EventPersonRaw {
  person_id: number;
  first_name: string;
  last_name: string;
  role_name?: string;
}

interface QuickTaskCreate {
  due_date: string;
}

interface AssignmentDialogProps {
  isOpen: boolean;
  onClose: () => void;
  onAssign: (
    rowData: EventData,
    people: { lead?: Person | null; backup?: Person | null },
    createTask?: QuickTaskCreate
  ) => void;
  rowData: EventData | null;
}

function addBusinessDays(date: Date, days: number): Date {
  const result = new Date(date);
  let added = 0;
  while (added < days) {
    result.setDate(result.getDate() + 1);
    const day = result.getDay();
    if (day !== 0 && day !== 6) {
      added++;
    }
  }
  return result;
}

function formatDate(date: Date): string {
  const year = date.getFullYear();
  const month = ("0" + (date.getMonth() + 1)).slice(-2);
  const day = ("0" + date.getDate()).slice(-2);
  return `${year}-${month}-${day}`;
}

function extractAccountants(eventData: CaveEventStaffing) {
  const potentialAccountantRoles = [
    USER_ROLES.ACCOUNTANT,
    USER_ROLES.ACCOUNTING_ADMIN,
  ].map((r) => r.toLowerCase());
  return eventData?.staff.filter((p) => {
    return potentialAccountantRoles.includes(p.role?.toLowerCase());
  });
}

const AssignmentDialog = ({
  isOpen,
  onClose,
  onAssign,
  rowData,
}: AssignmentDialogProps) => {
  const queryClient = useQueryClient();
  const [leadPerson, setLeadPerson] = useState<Person | null>(null);
  const [backupPerson, setBackupPerson] = useState<Person | null>(null);
  const [useBackup, setUseBackup] = useState(false);
  const [createTask, setCreateTask] = useState(true);
  const [taskDueDate, setTaskDueDate] = useState("");
  const [assignmentError, setAssignmentError] = useState("");

  const { data: peopleOptions = [] } = useQuery(
    ["peopleOptions"],
    async () => {
      const url = new URL(`${window.location.origin}/api/people/staff/`);
      const res = await fetch(url);
      const data = await res.json();
      return data
        .filter(
          (d: any) =>
            d.first_name && accountingDashboardEarlyAccessUsers.includes(d.id)
        )
        .map((person: any) => ({
          id: person.id,
          name: person.first_name + " " + person.last_name,
          user_id: person?.user?.id,
          person: person,
        }));
    },
    { enabled: isOpen }
  );

  useEffect(() => {
    if (isOpen && rowData) {
      const eventPeople: EventPersonRaw[] =
        queryClient.getQueryData(["eventPeople", rowData.id]) || [];
      const settlementPeople = eventPeople.filter(
        (p) => p.role_name?.toLowerCase() === USER_ROLES.ACCOUNTANT
      );
      const mapped = settlementPeople
        .map((p) => ({
          id: p.person_id,
          name: `${p.first_name} ${p.last_name}`,
          user_id: p?.user_id,
        }))
        .sort((a, b) => a.name.localeCompare(b.name));

      setLeadPerson(mapped[0] || null);
      setBackupPerson(mapped[1] || null);
      setUseBackup(mapped.length > 1);
      const eventDate = new Date(rowData.date);
      const dueDate = addBusinessDays(eventDate, 5);
      setTaskDueDate(formatDate(dueDate));
      setAssignmentError("");
    }
  }, [isOpen, rowData, queryClient]);

  useEffect(() => {
    if (leadPerson && backupPerson && leadPerson.id === backupPerson.id) {
      setAssignmentError("Lead and Backup cannot be the same person");
    } else {
      setAssignmentError("");
    }
  }, [leadPerson, backupPerson]);

  const handleSubmit = () => {
    if (assignmentError) return;
    if (!leadPerson && !backupPerson) {
      toast.error("Please select at least one person");
      return;
    }
    onAssign(
      rowData!,
      { lead: leadPerson, backup: useBackup ? backupPerson : null },
      createTask ? { due_date: taskDueDate } : undefined
    );
  };

  return (
    <Transition appear show={isOpen} as={Fragment}>
      <Dialog
        as="div"
        className="relative z-50 overflow-visible"
        onClose={onClose}
      >
        <Transition.Child
          as={Fragment}
          enter="ease-out duration-300"
          enterFrom="opacity-0"
          enterTo="opacity-100"
          leave="ease-in duration-200"
          leaveFrom="opacity-100"
          leaveTo="opacity-0"
        >
          <div className="fixed inset-0 bg-black/25" />
        </Transition.Child>
        <div className="fixed inset-0 overflow-y-auto">
          <div className="flex min-h-full items-center justify-center p-4 text-center">
            <Transition.Child
              as={Fragment}
              enter="ease-out duration-300"
              enterFrom="opacity-0 scale-95"
              enterTo="opacity-100 scale-100"
              leave="ease-in duration-200"
              leaveFrom="opacity-100 scale-100"
              leaveTo="opacity-0 scale-95"
            >
              <Dialog.Panel className="w-full max-w-md transform overflow-hidden rounded-lg bg-gray-800 p-6 text-left shadow-xl transition-all">
                <Dialog.Title className="text-lg leading-6 text-white mb-4">
                  Assign Settlement for{" "}
                  <span className="font-bold">{rowData?.name}</span>
                </Dialog.Title>
                <div className="flex items-center mb-6">
                  <Switch
                    checked={useBackup}
                    onChange={setUseBackup}
                    className={`${useBackup ? "bg-blue-600" : "bg-gray-700"} relative inline-flex h-6 w-11 items-center rounded-full`}
                  >
                    <span className="sr-only">Assign Backup Settler?</span>
                    <span
                      className={`${useBackup ? "translate-x-6" : "translate-x-1"} inline-block h-4 w-4 transform rounded-full bg-white transition-transform`}
                    />
                  </Switch>
                  <label className="ml-3 text-sm text-white">
                    Assign Backup Settler?
                  </label>
                </div>
                <div className="mb-6">
                  <label className="block text-sm font-medium text-gray-300 mb-1">
                    Lead
                  </label>
                  <Select
                    options={peopleOptions}
                    onChange={setLeadPerson}
                    value={leadPerson}
                    getOptionLabel={(option: Person) => option.name}
                    getOptionValue={(option: Person) => `${option.id}`}
                    placeholder="Search for a lead..."
                    noOptionsMessage={() => "No results found"}
                    isClearable
                  />
                </div>
                {useBackup && (
                  <div className="mb-6">
                    <label className="block text-sm font-medium text-gray-300 mb-1">
                      Backup
                    </label>
                    <AsyncSelect
                      cacheOptions
                      defaultOptions={peopleOptions}
                      options={peopleOptions}
                      onChange={setBackupPerson}
                      value={backupPerson}
                      getOptionLabel={(option: Person) => option.name}
                      getOptionValue={(option: Person) => `${option.id}`}
                      placeholder="Search for a backup..."
                      noOptionsMessage={() => "No results found"}
                      isClearable
                    />
                  </div>
                )}
                {assignmentError && (
                  <p className="text-red-500 text-sm mb-4">{assignmentError}</p>
                )}
                <div className="flex items-center mb-6">
                  <Switch
                    checked={createTask}
                    onChange={setCreateTask}
                    className={`${createTask ? "bg-blue-600" : "bg-gray-700"} relative inline-flex h-6 w-11 items-center rounded-full`}
                  >
                    <span className="sr-only">Create Task?</span>
                    <span
                      className={`${createTask ? "translate-x-6" : "translate-x-1"} inline-block h-4 w-4 transform rounded-full bg-white transition-transform`}
                    />
                  </Switch>
                  <label className="ml-3 text-sm text-white">
                    Create Task?
                  </label>
                </div>
                {createTask && (
                  <div className="mb-6">
                    <label className="block text-sm font-medium text-gray-300">
                      Task Due Date
                    </label>
                    <input
                      type="date"
                      value={taskDueDate}
                      onChange={(e) => setTaskDueDate(e.target.value)}
                      className="mt-1 px-3 py-2 border border-gray-300 rounded-md shadow-sm focus:outline-none block w-full"
                    />
                  </div>
                )}
                <div className="mt-6 flex justify-end space-x-3">
                  <Button variant="secondary" onClick={onClose}>
                    Cancel
                  </Button>
                  <Button
                    variant="success"
                    onClick={handleSubmit}
                    disabled={!!assignmentError || (!leadPerson && !useBackup)}
                  >
                    Assign
                  </Button>
                </div>
              </Dialog.Panel>
            </Transition.Child>
          </div>
        </div>
      </Dialog>
    </Transition>
  );
};

interface AssignmentCellRendererProps extends ICellRendererParams {
  data: EventData;
  people: EventPersonRaw[];
  searchQuery: string;
  onAssignClick: (rowData: EventData) => void;
}
const AssignmentCellRenderer = (props: AssignmentCellRendererProps) => {
  const { data, searchQuery, onAssignClick } = props;
  const accountants = extractAccountants(data as any as CaveEventStaffing);
  const { user, roleUserIsUsing } = useUser();
  return (
    <div>
      <div className="font-medium">
        {accountants.length > 0 ? (
          accountants.map((p) => (
            <div
              key={p.id}
              style={{ whiteSpace: "normal", wordBreak: "break-word" }}
            >
              <span className="font-bold">
                {[
                  USER_ROLES.ACCOUNTING,
                  USER_ROLES.ACCOUNTING_ADMIN,
                  "settlement",
                ]
                  .map((item) => item?.toLowerCase())
                  .includes(p?.role?.toLowerCase())}
              </span>{" "}
              <span
                dangerouslySetInnerHTML={{
                  __html: matchHighlightFormatter(p?.name, searchQuery),
                }}
              ></span>
            </div>
          ))
        ) : (
          <div>Not Assigned</div>
        )}
      </div>
      {hasAdminAccountingRights({ ...user, role: roleUserIsUsing }) && (
        <button
          onClick={() => onAssignClick(data)}
          className="mt-1 text-sm text-cave-blue-500 hover:text-cave-blue-500/70"
        >
          Assign
          <Ripple color="#fff" duration={750} />
        </button>
      )}
    </div>
  );
};

interface PeopleCellRendererProps {
  eventId: number;
  field: string;
  searchQuery: string;
  data: CaveEvent;
  onAssignClick?: (data: EventData) => void;
  allPeople?: {
    is_mammoth: boolean;
    mammoth_role: string | null;
    mammoth_role_name: string | null;
    user: any | null;
    images: {
      id: number;
      image_url: string | null;
    }[];
    id: number;
    created: string;
    modified: string;
    active: boolean;
    first_name: string;
    last_name: string;
    preferred_name: string | null;
    company_name: string;
    job_title: string | null;
    office: string | null;
    description: string;
    email: string;
    secondary_email: string | null;
    phone: string;
    is_active: boolean;
    website_url: string;
    address_line_1: string;
    address_line_2: string;
    city: string;
    state: string;
    zipcode: string;
  }[];
}
const PeopleCellRenderer = ({
  eventId,
  field,
  searchQuery,
  data,
  onAssignClick,
  allPeople,
}: PeopleCellRendererProps) => {
  const queryClient = useQueryClient();
  const { data: eventPeople = [] } = useQuery(
    ["eventPeople", eventId],
    () => getEventPeople(eventId),
    { staleTime: Infinity }
  );
  const singleEvent = useQuery<CaveEvent, CaveError>(
    ["event", eventId],
    () => getEvent(eventId),
    {
      staleTime: Infinity,
    }
  );
  if (field === "accountant") {
    return (
      <AssignmentCellRenderer
        data={data}
        searchQuery={searchQuery}
        people={eventPeople}
        onAssignClick={onAssignClick!}
      />
    );
  }
  const roleMap: Record<string, string> = {
    marketer: "marketer",
    buyer_name: "buyer",
    productionmanager: "production manager",
  };
  const targetRole = roleMap[field] || "";
  let person = eventPeople.find(
    (p: EventPersonRaw) => p.role_name?.toLowerCase() === targetRole
  );
  if (roleMap[field] === "buyer") {
    person = (allPeople ?? []).find(
      (p) => p?.user?.person_id === data?.buyer_id
    );
  }
  let displayValue = person
    ? `${person.first_name} ${person.last_name}`
    : "N/A";
  if (roleMap[field] === "buyer") {
    displayValue =
      singleEvent.data?.buyers
        ?.map((buyer) => buyer?.first_name + " " + buyer?.last_name)
        .join(", ") || "N/A";
  }
  if (!searchQuery) return <span>{displayValue}</span>;
  const highlighted = displayValue.replace(
    new RegExp(searchQuery, "gi"),
    (match) => `<mark>${match}</mark>`
  );
  return <span dangerouslySetInnerHTML={{ __html: highlighted }} />;
};

const AccountingDashboard = () => {
  usePageTitle("Accounting Dashboard");
  const queryClient = useQueryClient();
  const [searchQuery, setSearchQuery] = useState("");
  const [isFirstRender, setIsFirstRender] = useState(true);
  const [assignmentFilter, setAssignmentFilter] = useState<
    "all" | "assigned" | "unassigned"
  >("all");
  const [isDialogOpen, setIsDialogOpen] = useState(false);
  const [selectedRow, setSelectedRow] = useState<EventData | null>(null);
  const [gridApi, setGridApi] = useState<GridApi | null>(null);
  const { data: eventsData = [], isLoading: eventsLoading } = useQuery(
    ["events", "list"],
    () => getEventsListStaffing(),
    getEventsSettings
  );
  const getAllPeople = useQuery(["peopleOptions"], () => getPeople(), {
    enabled: true,
    refetchOnMount: false,
  });
  const { roleUserIsUsing } = useUser();

  const createTaskMutation = useMutation(createTask, {
    onSuccess: () => queryClient.invalidateQueries(["tasks"]),
  });

  const roles = useQuery(["event-roles"], () => getEventRoles());
  const tabMenuItems = [
    { label: "Calendar", href: "/calendar" },
    { label: "Agencies", href: "/agencies" },
    { label: "Venues", href: "/venues" },
    { label: "Reports", href: "/reports" },
    { label: "Artists", href: "/artists" },
    { label: "Co-Pros", href: "/co-pros" },
  ];
  const assignMutation = useMutation({
    mutationFn: async (data: {
      event_id: number;
      person_id: number;
      rowData: EventData;
      taskData?: QuickTaskCreate;
      title: string;
      descriptionType: "lead" | "backup";
    }) => {
      const settlementRole = roles.data?.find(
        (r) => r.name?.toLowerCase() === USER_ROLES.ACCOUNTANT
      );
      return createEventPeople(data.event_id, {
        person_id: data.person_id,
        role_id: settlementRole?.id,
        description: data.descriptionType,
      });
    },
    onSuccess: (resp, vars) => {
      queryClient.invalidateQueries(["eventPeople", vars.rowData.id]);
      toast.success(vars.title);
      if (vars.taskData) {
        const payload = {
          description: vars.title,
          assigned_to_id: vars.person_id,
          due_date: new Date(vars.taskData.due_date).toISOString(),
          parent_task_id: null,
          status: "PENDING",
          priority: "MEDIUM",
        };
        createTaskMutation.mutate(payload);
        toast.success("Task created");
      }
    },
  });

  const handleAssignClick = (rowData: EventData) => {
    setSelectedRow(rowData);
    setIsDialogOpen(true);
  };

  const handleAssign = async (
    rowData: EventData,
    people: { lead?: Person | null; backup?: Person | null },
    taskData?: QuickTaskCreate
  ) => {
    if (!rowData) return;
    const currentSettlers: EventPersonRaw[] =
      queryClient.getQueryData(["eventPeople", rowData.id]) || [];
    const currentSettlement = currentSettlers.filter(
      (p) => p.role_name?.toLowerCase() === USER_ROLES.ACCOUNTANT
    );
    await Promise.all(
      currentSettlement.map((p) => deletePersonFromEvent(rowData.id, p.id))
    );
    const newAssignmentNames: string[] = [];
    const newAssignmentIds = new Set<number>();
    if (people.lead) {
      newAssignmentNames.push(people.lead.name);
      newAssignmentIds.add(people.lead.id);
      await assignMutation.mutateAsync({
        event_id: rowData.id,
        person_id: people.lead.id,
        rowData,
        taskData,
        title: `Settlement Lead for ${rowData.name} ${formatDate(new Date(rowData.date))}`,
        descriptionType: "lead",
      });
      await createAccountingMessage({
        content: `You have been assigned to settle ${rowData.name} on ${formatDate(new Date(rowData.date))}.`,
        recipient_id: people.lead?.user_id,
        event_id: rowData.id,
        is_sticky: false,
      });
    }
    if (people.backup) {
      newAssignmentNames.push(people.backup.name);
      newAssignmentIds.add(people.backup.id);
      await assignMutation.mutateAsync({
        event_id: rowData.id,
        person_id: people.backup.id,
        rowData,
        taskData,
        title: `Settlement Backup for ${rowData.name} ${formatDate(new Date(rowData.date))}`,
        descriptionType: "backup",
      });
      await createAccountingMessage({
        content: `You have been assigned to settle ${rowData.name} on ${formatDate(new Date(rowData.date))}.`,
        recipient_id: people.backup?.user_id,
        event_id: rowData.id,
        is_sticky: false,
      });
    }
    const replacementNames = newAssignmentNames.join(" and ");
    await Promise.all(
      currentSettlement
        .filter((p) => !newAssignmentIds.has(p.person_id))
        .map((p) =>
          createAccountingMessage({
            content: `You have been replaced by ${replacementNames} to settle ${rowData.name}.`,
            recipient_id: p?.user_id,
            event_id: rowData.id,
            is_sticky: false,
          })
        )
    );
    setIsDialogOpen(false);
  };

  const defaultColDef: ColDef = {
    flex: 1,
    minWidth: 120,
    resizable: true,
    filter: true,
    sortable: true,
    cellStyle: { display: "flex", alignItems: "center", whiteSpace: "normal" },
  };

  const columnDefs: ColDef[] = [
    {
      field: "name",
      headerName: "Event Name",
      cellRenderer: ImageNameCellRenderer,
      searchTerm: searchQuery,
      flex: 2,
      minWidth: 170,
      sortable: true,
      filter: true,
      headerClass: "ag-header-cell-sorted",
    },
    {
      field: "date",
      headerName: "Date",
      cellRenderer: DateCellRenderer,
      flex: 1,
      minWidth: 130,
      sortable: true,
      filter: true,
      headerClass: "ag-header-cell-sorted",
    },
    {
      field: "venue_name",
      headerName: "Venue",
      cellRenderer: (params) => {
        const ev = params.data as any as CaveEventStaffing;
        return (
          <span
            dangerouslySetInnerHTML={{
              __html: matchHighlightFormatter(`${ev.venue_name}`, searchQuery),
            }}
          ></span>
        );
      },
      valueGetter: (params) => {
        const ev = params.data as any as CaveEventStaffing;
        return `${ev.venue_name}`;
      },
    },
    {
      field: "marketer",
      headerName: "Marketer",
      valueGetter: (params) => {
        const r = extractRoleFromEventListStaffing({
          event: params.data as any as CaveEventStaffing,
          roles: [
            USER_ROLES.MARKETING,
            USER_ROLES.MARKETING_ADMIN,
            USER_ROLES.MARKETER,
          ],
        })
          .map((p) => `${p.name}`)
          .join(", ");
        return r;
      },
      cellRenderer: (params) => (
        <PeopleCellRenderer
          eventId={params.data.id}
          field="marketer"
          searchQuery={searchQuery}
          data={params.data}
        />
      ),
    },
    {
      field: "buyer_name",
      headerName: "Buyer",
      valueGetter: (params) => {
        return extractRoleFromEventListStaffing({
          event: params.data as any as CaveEventStaffing,
          roles: [USER_ROLES.BUYER],
        })
          .map((p) => `${p.name}`)
          .join(", ");
      },
      cellRenderer: (params) => (
        <PeopleCellRenderer
          eventId={params.data.id}
          field="buyer_name"
          searchQuery={searchQuery}
          data={params.data}
          allPeople={getAllPeople.data}
        />
      ),
    },
    {
      field: "productionmanager",
      headerName: "Production Manager",
      valueGetter: (params) => {
        return extractRoleFromEventListStaffing({
          event: params.data as any as CaveEventStaffing,
          roles: ["Production Manager"],
        })
          .map((p) => `${p.name}`)
          .join(", ");
      },
      cellRenderer: (params) => (
        <PeopleCellRenderer
          eventId={params.data.id}
          field="productionmanager"
          searchQuery={searchQuery}
          data={params.data}
        />
      ),
    },
    {
      field: "accountAssign",
      headerName: "Accountant",
      filter: true,
      valueGetter: (params) => {
        const accountants = extractAccountants(
          params.data as any as CaveEventStaffing
        );
        return accountants.map((p) => `${p.name}`).join(", ");
      },
      cellRenderer: (params) => (
        <PeopleCellRenderer
          eventId={params.data.id}
          field="accountant"
          searchQuery={searchQuery}
          data={params.data}
          onAssignClick={handleAssignClick}
        />
      ),
    },
  ];

  const isEventAssigned = (ev: EventData): boolean => {
    return ev?.accountant_staff_count > 0;
  };

  const filteredEvents = eventsData.filter((ev) => {
    const assigned = isEventAssigned(ev);
    if (assignmentFilter === "assigned") return assigned;
    if (assignmentFilter === "unassigned") return !assigned;
    return true;
  });

  const getQuickFilterText = (params: GetQuickFilterTextParams): string => {
    if (!params.data) return "";
    const ev = params.data as EventData;
    const cached: EventPersonRaw[] =
      queryClient.getQueryData(["eventPeople", ev.id]) || [];
    const settlementNames = cached
      .filter((p) => p.role_name?.toLowerCase() === USER_ROLES.ACCOUNTANT)
      .map((p) => `${p.first_name} ${p.last_name}`)
      .join(" ");
    return [ev.name, ev.venue_name, ev.date, settlementNames]
      .filter(Boolean)
      .join(" ");
  };

  const onGridReady = (params: { api: GridApi }) => {
    setGridApi(params.api);
  };

  return (
    <AccountingLayout>
      <DashboardSearch
        value={searchQuery}
        onChange={(e) => setSearchQuery(e.target.value)}
        className="mb-4"
      />
      <div className="flex space-x-4 mb-4">
        <label>
          <input
            type="radio"
            name="assignmentFilter"
            value="all"
            checked={assignmentFilter === "all"}
            onChange={() => setAssignmentFilter("all")}
          />{" "}
          All
        </label>
        <label>
          <input
            type="radio"
            name="assignmentFilter"
            value="assigned"
            checked={assignmentFilter === "assigned"}
            onChange={() => setAssignmentFilter("assigned")}
          />{" "}
          Assigned
        </label>
        <label>
          <input
            type="radio"
            name="assignmentFilter"
            value="unassigned"
            checked={assignmentFilter === "unassigned"}
            onChange={() => setAssignmentFilter("unassigned")}
          />{" "}
          Not Assigned
        </label>
      </div>
      <div className="flex flex-col mb-4">
        <div className="ag-theme-alpine-dark h-[60vh] mb-4">
          <AgGridReact
            className="ag-grid-green"
            loading={isFirstRender || eventsLoading}
            columnDefs={columnDefs}
            defaultColDef={defaultColDef}
            rowData={filteredEvents}
            onGridReady={onGridReady}
            getRowId={(params) => `${params.data.id}`}
            rowHeight={150}
            quickFilterText={searchQuery}
            getQuickFilterText={getQuickFilterText}
            onFirstDataRendered={() => setIsFirstRender(false)}
            onBodyScroll={(e) => e.api.refreshCells()}
            enableCellTextSelection
            pagination
            paginationPageSize={100}
          />
        </div>
        <DashboardTableBottomTabs tabMenuItems={tabMenuItems} />
      </div>
      <AccountingDashboardBulletinBoard />
      <AccountingDashboardTasks
        canAddTasks={["admin", "accounting_admin"].includes(roleUserIsUsing)}
      />
      <AssignmentDialog
        isOpen={isDialogOpen}
        onClose={() => setIsDialogOpen(false)}
        rowData={selectedRow}
        onAssign={handleAssign}
      />
    </AccountingLayout>
  );
};

export default AccountingDashboard;
