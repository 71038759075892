import React, { useState } from "react";
import { AgGridReact } from "ag-grid-react";
import { ColDef } from "ag-grid-community";

interface FakeRow {
  date: string;
  fromAccount: string;
  toAccount: string;
  amount: number;
  memo: string;
  startRequesting: string;
  artistName: string;
  vendorName: string;
  description: string;
  bankName: string;
  bankAddress: string;
  routingNumber: string;
  accountNumber: string;
  swiftCode: string;
}

const fakeData: FakeRow[] = [
  {
    date: "2025-03-01",
    fromAccount: "Account A",
    toAccount: "Account B",
    amount: 1000,
    memo: "Payment",
    startRequesting: "Yes",
    artistName: "Artist 1",
    vendorName: "Vendor 1",
    description: "Lorem ipsum dolor sit amet",
    bankName: "Bank A",
    bankAddress: "123 Bank St",
    routingNumber: "111000111",
    accountNumber: "123456789",
    swiftCode: "SWIFT1",
  },
  {
    date: "2025-03-02",
    fromAccount: "Account C",
    toAccount: "Account D",
    amount: 2000,
    memo: "Transfer",
    startRequesting: "No",
    artistName: "Artist 2",
    vendorName: "Vendor 2",
    description: "Consectetur adipiscing elit",
    bankName: "Bank B",
    bankAddress: "456 Bank Ave",
    routingNumber: "222000222",
    accountNumber: "987654321",
    swiftCode: "SWIFT2",
  },
];

// Common column definitions (14 total)
const commonColDefs: ColDef[] = [
  { field: "date", headerName: "DATE" },
  { field: "fromAccount", headerName: "FROM" },
  { field: "toAccount", headerName: "TO" },
  { field: "amount", headerName: "AMOUNT" },
  { field: "memo", headerName: "MEMO" },
  { field: "startRequesting", headerName: "REQUESTING" },
  { field: "artistName", headerName: "ARTIST" },
  { field: "vendorName", headerName: "VENDOR" },
  { field: "description", headerName: "DESCRIPTION" },
  { field: "bankName", headerName: "BANK NAME" },
  { field: "bankAddress", headerName: "BANK ADDRESS" },
  { field: "routingNumber", headerName: "ROUTING" },
  { field: "accountNumber", headerName: "ACCOUNT" },
  { field: "swiftCode", headerName: "SWIFT" },
];

interface SectionProps {
  title: string;
  themeClass: string;
  data: FakeRow[];
  hideHeaders?: boolean;
}

const SectionGrid: React.FC<SectionProps> = ({
  title,
  themeClass,
  data,
  hideHeaders,
}) => {
  const [gridApi, setGridApi] = useState<any>(null);

  return (
    <div>
      <h2
        className={`text-center font-bold uppercase py-2 ${themeClass}-header`}
      >
        {title}
      </h2>
      <div className={`ag-theme-alpine-dark ${themeClass} h-[300px]`}>
        <AgGridReact
          columnDefs={commonColDefs}
          rowData={data}
          headerHeight={hideHeaders ? 0 : 40}
          defaultColDef={{
            flex: 1,
            resizable: true,
            sortable: true,
            filter: true,
          }}
          onGridReady={(params) => setGridApi(params.api)}
        />
      </div>
    </div>
  );
};

const WireDashboard = () => {
  return (
    <div className="px-6">
      <SectionGrid
        title="Grizzly"
        themeClass="ag-grid-orange"
        data={fakeData}
      />
      <SectionGrid
        title="Sabertooth"
        themeClass="ag-grid-blue"
        data={fakeData}
      />
      <SectionGrid
        title="Transfers Only"
        themeClass="ag-grid-purple"
        data={fakeData}
      />
      <SectionGrid title="Mammoth" themeClass="ag-grid-green" data={fakeData} />
      <SectionGrid title="Astro" themeClass="ag-grid-teal" data={fakeData} />
      <SectionGrid title="Other" themeClass="ag-grid-gray" data={fakeData} />
    </div>
  );
};

export default WireDashboard;
