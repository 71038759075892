import { AgGridReact } from "ag-grid-react";
import { useState, useEffect, useCallback, useRef } from "react";

import { offerEditableBgClass } from "@/constants/offer";
import { FIELD_UPDATE_ANIMATION_TIME } from "@/hooks/useGlowEffect";
import { useEventOfferStore } from "@/store/eventOfferStore";

const ContactAgGrid = ({ eventID, eventData }) => {
  const [flashFields, setFlashFields] = useState({});
  const gridRef = useRef(null);
  const offerId = eventData?.offers?.[0]?.id;

  // Use the Zustand store
  const { eventOfferForm, initializeEventOfferForm, patchOfferField } =
    useEventOfferStore();

  // Initialize store with data from event
  useEffect(() => {
    if (eventData?.offers?.length > 0) {
      const latestOffer = eventData.offers.sort(
        (a, b) => new Date(b.modified) - new Date(a.modified)
      )[0];

      initializeEventOfferForm({
        production_adv:
          latestOffer?.production_adv || "production@mammothlive.com",
        local_marketing: latestOffer?.local_marketing || "",
        ticketing: latestOffer?.ticketing || "ticketing@mammothlive.com",
        tour_admat: latestOffer?.tour_admat || "",
        tour_marketing: latestOffer?.tour_marketing || "",
        onsale_info: latestOffer?.onsale_info || "",
        presale_info: latestOffer?.presale_info || "",
        contracts: latestOffer?.contracts || "contracts@mammothlive.com",
      });
    }
  }, [eventData, initializeEventOfferForm]);

  // Flash effect for updated fields
  const flashField = useCallback((fieldKey) => {
    setFlashFields((prev) => ({ ...prev, [fieldKey]: true }));
    setTimeout(() => {
      setFlashFields((prev) => {
        const copy = { ...prev };
        delete copy[fieldKey];
        return copy;
      });
    }, FIELD_UPDATE_ANIMATION_TIME);
  }, []);

  // Create row data from store values
  const rowData = [
    {
      header: "PRODUCTION ADV.",
      value: eventOfferForm.production_adv,
      field: "production_adv",
    },
    {
      header: "LOCAL MARKETING",
      value: eventOfferForm.local_marketing,
      field: "local_marketing",
    },
    {
      header: "TICKETING",
      value: eventOfferForm.ticketing,
      field: "ticketing",
    },
    {
      header: "TOUR ADMAT",
      value: eventOfferForm.tour_admat,
      field: "tour_admat",
    },
    {
      header: "TOUR MARKETING",
      value: eventOfferForm.tour_marketing,
      field: "tour_marketing",
    },
    {
      header: "ONSALE INFO",
      value: eventOfferForm.onsale_info,
      field: "onsale_info",
    },
    {
      header: "PRE-SALE INFO",
      value: eventOfferForm.presale_info,
      field: "presale_info",
    },
    {
      header: "CONTRACTS",
      value: eventOfferForm.contracts,
      field: "contracts",
    },
  ];

  // Handle field updates through the store
  const handleFieldUpdate = useCallback(
    (field, value) => {
      if (eventID) {
        patchOfferField(eventID, field, value);
        flashField(field);
      }
    },
    [offerId, patchOfferField, flashField]
  );

  // Column definitions
  const columnDefs = [
    {
      field: "header",
      width: 180,
      flex: 1,
      editable: false,
      cellStyle: {
        paddingRight: "5px",
        fontFamily: "Montserrat, sans-serif",
        fontWeight: "bold",
        backgroundColor: "#E5E7EB",
      },
    },
    {
      field: "value",
      editable: true,
      flex: 1,
      cellStyle: {
        paddingLeft: "0px",
        fontFamily: "Montserrat, sans-serif",
        backgroundColor: "#93C5FD",
      },
      cellEditor: "agTextCellEditor",
      cellClass: (params) => {
        return `${offerEditableBgClass} ${flashFields[params.data.field] ? "glow-text" : ""}`;
      },
      onCellValueChanged: (params) => {
        handleFieldUpdate(params.data.field, params.newValue);
      },
    },
  ];

  // Grid configuration
  const defaultColDef = {
    resizable: true,
    sortable: false,
    filter: false,
  };

  const onGridReady = useCallback((params) => {
    params.api.sizeColumnsToFit();

    const resizeObserver = new ResizeObserver(() => {
      setTimeout(() => params.api.sizeColumnsToFit(), 100);
    });

    const gridElement = document.querySelector(".ag-theme-alpine");
    if (gridElement) resizeObserver.observe(gridElement);
    resizeObserver.observe(document.body);

    return () => resizeObserver.disconnect();
  }, []);

  return (
    <div className="w-full border-4 border-gray-300">
      <div className="bg-gray-300 text-base text-center py-2 px-2 font-montserrat font-extrabold text-2xl">
        CONTACT INFO
      </div>
      <div
        className="ag-theme-alpine"
        style={{ height: "auto", width: "100%" }}
      >
        <AgGridReact
          ref={gridRef}
          rowData={rowData}
          columnDefs={columnDefs}
          defaultColDef={defaultColDef}
          singleClickEdit
          stopEditingWhenCellsLoseFocus
          onGridReady={onGridReady}
          domLayout="autoHeight"
          headerHeight={0}
          rowHeight={35}
          getRowId={(params) => params.data.field}
          className="contact-compact-grid"
        />
      </div>
      <style jsx>{`
        :global(.contact-compact-grid) {
          --ag-horizontal-padding: 5px;
          --ag-grid-size: 4px;
          --ag-border-color: #dde2eb;
        }
        :global(.contact-compact-grid .ag-cell) {
          padding-left: 5px;
          padding-right: 5px;
          border-bottom: 1px solid #e5e7eb;
        }
        :global(.contact-compact-grid .ag-row:last-child .ag-cell) {
          border-bottom: none;
        }
        :global(.contact-compact-grid .ag-cell.ag-cell-inline-editing) {
          padding: 5px;
          height: 35px;
        }
      `}</style>
    </div>
  );
};

export default ContactAgGrid;
