import React, { useState } from "react";
import { useQuery } from "@tanstack/react-query";
import { getTasks } from "@/queries/tasks";

export const TaskCalendar = ({ onClose, onTaskClick }) => {
  const [currentDate, setCurrentDate] = useState(new Date());
  const { data: tasks } = useQuery(["tasks"], getTasks);

  const getDaysInMonth = (year, month) => {
    return new Date(year, month + 1, 0).getDate();
  };

  const getFirstDayOfMonth = (year, month) => {
    return new Date(year, month, 1).getDay();
  };

  const getMonthData = () => {
    const year = currentDate.getFullYear();
    const month = currentDate.getMonth();
    const daysInMonth = getDaysInMonth(year, month);
    const firstDayOfMonth = getFirstDayOfMonth(year, month);
    const weeks = [];
    let days = [];

    // Add empty cells for days before the first day of the month
    for (let i = 0; i < firstDayOfMonth; i++) {
      days.push(null);
    }

    // Add days of the month
    for (let day = 1; day <= daysInMonth; day++) {
      days.push(new Date(year, month, day));
      if (days.length === 7) {
        weeks.push(days);
        days = [];
      }
    }

    // Add remaining days
    if (days.length > 0) {
      while (days.length < 7) {
        days.push(null);
      }
      weeks.push(days);
    }

    return weeks;
  };

  const getTasksForDate = (date) => {
    if (!date || !tasks) return [];
    return tasks.filter((task) => {
      const taskDate = new Date(task.due_date);
      return taskDate.toDateString() === date.toDateString();
    });
  };

  const monthNames = [
    "January",
    "February",
    "March",
    "April",
    "May",
    "June",
    "July",
    "August",
    "September",
    "October",
    "November",
    "December",
  ];

  const navigateMonth = (direction) => {
    setCurrentDate((prev) => {
      const newDate = new Date(prev);
      newDate.setMonth(prev.getMonth() + direction);
      return newDate;
    });
  };

  return (
    <div className="bg-purple-900 p-6 rounded-lg max-w-6xl w-full">
      <div className="flex justify-between items-center mb-6">
        <div className="flex items-center gap-4">
          <button
            onClick={() => navigateMonth(-1)}
            className="text-purple-200 hover:text-white"
          >
            ←
          </button>
          <h2 className="text-white text-xl font-bold">
            {monthNames[currentDate.getMonth()]} {currentDate.getFullYear()}
          </h2>
          <button
            onClick={() => navigateMonth(1)}
            className="text-purple-200 hover:text-white"
          >
            →
          </button>
        </div>
        <button onClick={onClose} className="text-purple-200 hover:text-white">
          ×
        </button>
      </div>

      <div className="grid grid-cols-7 gap-1">
        {["Sun", "Mon", "Tue", "Wed", "Thu", "Fri", "Sat"].map((day) => (
          <div key={day} className="text-purple-200 text-center py-2">
            {day}
          </div>
        ))}

        {getMonthData().map((week, weekIndex) =>
          week.map((date, dayIndex) => (
            <div
              key={`${weekIndex}-${dayIndex}`}
              className={`min-h-[120px] p-2 rounded ${
                date ? "bg-purple-800 hover:bg-purple-700" : "bg-purple-800/50"
              }`}
            >
              {date && (
                <>
                  <div className="text-purple-200 mb-2">{date.getDate()}</div>
                  <div className="space-y-1">
                    {getTasksForDate(date).map((task) => (
                      <div
                        key={task.id}
                        onClick={() => onTaskClick(task)}
                        className={`text-xs p-1 rounded cursor-pointer ${
                          task.priority === "URGENT"
                            ? "bg-red-500"
                            : task.priority === "HIGH"
                            ? "bg-orange-500"
                            : task.priority === "MEDIUM"
                            ? "bg-yellow-500"
                            : "bg-green-500"
                        }`}
                      >
                        {task.description.length > 20
                          ? task.description.substring(0, 20) + "..."
                          : task.description}
                      </div>
                    ))}
                  </div>
                </>
              )}
            </div>
          ))
        )}
      </div>
    </div>
  );
};

export default TaskCalendar;
