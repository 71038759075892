import { AgGridReact } from "ag-grid-react";
import { useCallback, useState, useRef, useEffect } from "react";
import "ag-grid-community/styles/ag-grid.css";
import "ag-grid-community/styles/ag-theme-alpine.css";
import { Bars3Icon } from "@heroicons/react/24/outline";
import { useDrag, useDrop } from "react-dnd";
import classNames from "classnames";
import { useParams, useNavigate, NavLink } from "react-router-dom";
import Select, { components } from "react-select";
import { toast } from "react-toastify";

import ManifestTable from "./EventManifestTable";
import { ToggleSwitch } from "../../components/Toggle/Toggle";
import {
  createEventExpenseOffer,
  getExpenseGroups,
  updateEventExpense,
} from "../../queries/accounting";
import { getArtists } from "../../queries/artists";
import {
  addEventToOffer,
  createEventManifest,
  createEventPDF,
  createOffer,
  deleteEventManifest,
  getEventPDFs,
  getOffer,
  getOffers,
  updateOffer,
} from "../../queries/events";
import { getVenues, updateVenue } from "../../queries/venues";
import { useUser, usePerms } from "@/components/Auth";
import { BreadCrumbs } from "@/components/BreadCrumbs";
import Button from "@/components/Button";
import { DataGrid } from "@/components/DataGrid";
import { DateChanger } from "@/components/EventState/DateChanger";
import { StateSwitcher } from "@/components/EventState/StateSwitcher.jsx";
import { selectValueToOption } from "@/components/Form";
import { Header } from "@/components/Header";
import { TemplateManager } from "@/components/TemplateManager";
import { WalkoutPot } from "@/components/WalkoutPot";
import {
  getEventRollups,
  getEventPDFExpenses,
  updateEventExpenseOffer,
  deleteExpense,
} from "@/queries/accounting";
import {
  getEvent,
  updateEventOffer,
  getEventOfferVariables,
  updateEvent,
  updateManifest,
  updateEventAccounting,
  getEventState,
  postEventState,
} from "@/queries/events";
import { getVenue } from "@/queries/venues";

import { useQuery, useMutation, useQueryClient } from "@tanstack/react-query";

import { localTime, formatTimeString, formatToHHMMSS } from "@/utils/datetime";
import {
  amountDisplay,
  amountStrToInt,
  formatInputToMoney,
} from "@/utils/money";

export const FIELD_UPDATE_ANIMATION_TIME = 2000;

import { usePageTitle } from "@/utils/pagetitle";
const DealTable = ({ dealAmount, dealDescription, isChanged }) => {
  return (
    <div className="min-w-full border border-gray-300">
      <div className="bg-cave-white flex justify-between items-center">
        <span className="bg-gray-300 dark:bg-gray-100 p-2 h-full w-1/3 text-left font-bold text-xl pl-8 font-montserrat">
          THE DEAL
        </span>
        <span
          className={`font-bold w-2/3 h-full text-2xl text-end p-2 font-montserrat ${isChanged ? "glow-text" : ""
            }`}
        >
          {dealAmount}
        </span>
      </div>
      <textarea
        className="text-black text-sm w-full p-2 text-center border-t border-gray-300 dark:bg-gray-100 font-montserrat"
        style={{ minHeight: "3rem" }}
        value={dealDescription}
        disabled
      />
    </div>
  );
};

const TextAreaTable = ({
  header,
  noBlue,
  value = "",
  disabled,
  onInputChange,
  accessor,
}) => {
  const [isAdding, setIsAdding] = useState(false);
  return (
    <div className="flex flex-col h-full w-full">
      <table className="w-full h-full">
        <thead className="bg-gray-300">
          <tr>
            <th className="text-base text-center py-1 px-2 font-montserrat">
              {header}
            </th>
          </tr>
        </thead>
        <tbody>
          <tr className="h-full">
            <td className="h-full">
              <textarea
                className={classNames(
                  !isAdding
                    ? `text-sm w-full h-full font-montserrat ${noBlue ? "bg-cave-white" : "bg-blue-300"
                    } selection:bg-blue-900 selection:text-cave-white p-1 focus:outline-none focus:ring-2 focus:ring-blue-400`
                    : "bg-cave-blue-1 border-cave-blue-3 border-4 p-4 m-2"
                )}
                defaultValue={value}
                disabled={disabled}
                onBlur={(e) =>
                  onInputChange && onInputChange(accessor, e.target.value)
                }
                onKeyDown={(e) => {
                  if (e.key === "Enter" && !e.shiftKey) {
                    e.stopPropagation();
                  }
                }}
                style={{
                  resize: "vertical",
                  minHeight: "100px",
                  whiteSpace: "pre-wrap",
                }}
              />
            </td>
          </tr>
        </tbody>
      </table>
    </div>
  );
};

// Define the ITEM_TYPE for react-dnd
const ITEM_TYPE = "ROW";

// Helper function to format input titles
const formatInput = (input) => input.toLowerCase().replace(/\s+/g, "_");

// MultiLineTableCell Component (as provided)
const MultiLineTableCell = ({
  header,
  isGreyedOut,
  handleKeyDown,
  handleFocus,
  handleBlur,
  children,
  placeholder,
  isDisabled = false,
  isChanged = false,
}) => {
  const cellRef = useRef(null);
  const [isMounted, setIsMounted] = useState(false);

  useEffect(() => {
    setIsMounted(true);
  }, []);

  const getEditable = () => {
    if (header.editable === false || isGreyedOut || isDisabled) return false;
    return true;
  };

  const handleClick = (e) => {
    if (isMounted && cellRef.current && getEditable()) {
      e.preventDefault();
      e.stopPropagation();

      // Only focus if not already focused
      if (document.activeElement !== cellRef.current) {
        cellRef.current.focus();
        if (handleFocus) {
          handleFocus(e);
        }
      }
    }
  };

  return (
    <td className="relative" disabled>
      <div
        ref={cellRef}
        className={`relative min-w-full max-w-[145px] h-[28px] cell-content p-1 text-sm font-montserrat whitespace-nowrap overflow-hidden text-ellipsis focus:overflow-visible focus:absolute focus:z-10 focus:bg-gray-100 focus:max-w-none ${isChanged ? "glow-text" : ""
          } ${isDisabled ? "opacity-50 cursor-not-allowed" : ""}`}
        contentEditable={getEditable()}
        suppressContentEditableWarning={true}
        tabIndex={0}
        onClick={handleClick}
        onMouseDown={(e) => e.preventDefault()} // Prevent text selection on double click
        onKeyDown={(e) => handleKeyDown && handleKeyDown(e)}
        onFocus={(e) => handleFocus && handleFocus(e)}
        onBlur={(e) => handleBlur && handleBlur(e)}
        style={{ opacity: isGreyedOut ? 0.2 : 1 }}
        data-placeholder={children === "" || !children ? placeholder : ""}
      >
        {children}
      </div>
    </td>
  );
};

const MultiLineManifestTable = ({
  rows: rowsProp,
  event,
  eventId,
  headers,
  onInputChange,
  onDeletePress,
  addRowButtonName,
  onAddNewRow,
  hasChangeDetection,
}) => {
  const queryClient = useQueryClient(); // Initialize React Query's queryClient
  const [rows, setRows] = useState([]);
  const [deletingRowId, setDeletingRowId] = useState(null);
  const [isAddingRow, setIsAddingRow] = useState(false);
  const TEMP_ROW_PLACEHOLDERS = {
    ticketing: "New Manifest",
    capacity: 0,
    net_price: 0,
    ticket_fees: 0,
    gross_price: "n/a",
    potential: "n/a",
  };
  const TEMP_ROW = {
    ticketing: "",
    capacity: "",
    net_price: "",
    ticket_fees: "",
    gross_price: "n/a",
    potential: "n/a",
  };
  const [tempRow, setTempRow] = useState(TEMP_ROW);
  const [changedCells, setChangedCells] = useState([]);

  const prevRowsRef = useRef(rowsProp);
  const originalContentRef = useRef("");
  const lastFocusedElementRef = useRef(null);

  // Initialize rows state when rowsProp changes
  useEffect(() => {
    if (rowsProp && rowsProp.length > 0) {
      const initializedRows = rowsProp.map((row, index) => ({
        ...row,
        sort_order: row.sort_order || index + 1,
        id: row.id || `row-${index + 1}`, // Ensure unique id
        eventId: row.eventId,
        manifest_id: row.manifest_id,
        name: row.name,
        qty: row.qty,
        price: row.price,
        on_sale: row.on_sale,
        comps: row.comps,
        ticket_fees: row.ticket_fees,
        kills: row.kills,
        is_offer: row.is_offer,
      }));
      setRows(initializedRows.sort((a, b) => a.sort_order - b.sort_order));
    }
  }, [rowsProp]);

  // Update prevRowsRef whenever rows change
  useEffect(() => {
    prevRowsRef.current = rows;
  }, [rows]);

  // Change detection effect
  useEffect(() => {
    if (!hasChangeDetection) return;
    const newChanges = [];
    rows.forEach((row, rowIndex) => {
      Object.keys(row).forEach((key) => {
        if (row[key] !== prevRowsRef.current[rowIndex][key]) {
          newChanges.push(`${rowIndex}-${key}`);
        }
      });
    });
    if (newChanges.length > 0) {
      setChangedCells(newChanges);
      prevRowsRef.current = rows;
      setTimeout(() => {
        setChangedCells([]);
      }, 1000); // Adjust FIELD_UPDATE_ANIMATION_TIME as needed
    }
  }, [rows, hasChangeDetection]);

  const updateEventManifest = useMutation({
    mutationFn: async (data) => {
      const { kills, is_offer, manifest_id, sort_order } = data;

      const updateData = {
        ...data,
        sort_order: sort_order,
        kills: 0,
        is_offer: true,
      };
      return await updateManifest(updateData.manifest_id, updateData);
    },
    onError: async (err, newManifestData, context) => {
      const id = newManifestData?.eventId;
      //toast.error("Error updating manifest");
      await event?.refetch();
    },
    onSuccess: async (newManifestData) => {
      const id = newManifestData?.eventId;
      // await event?.refetch();
      queryClient.invalidateQueries(["event-detail", id]);
      queryClient.invalidateQueries(["variables-offer-pdf", id]);
      queryClient.invalidateQueries(["expenses-offer-pdf", id]);
      queryClient.invalidateQueries(["event-rollups", id]);
      queryClient.invalidateQueries(["manifests", id]);
    },
  });

  // Function to handle sort order changes
  const handleSortOrderChange = async (reorderedRows) => {
    try {
      // Optimistically update UI
      setRows(reorderedRows);

      // Create update promises
      const updatePromises = reorderedRows.map((row) => {
        return updateEventManifest.mutateAsync({
          manifest_id: row.id,
          sort_order: row.sort_order,
          eventId: row.eventId,
          name: row.name,
          qty: row.qty,
          price: row.price,
          on_sale: row.on_sale,
          comps: row.comps,
          ticket_fees: row.ticket_fees,
        });
      });

      // Wait for all updates
      await Promise.all(updatePromises);

      // Invalidate and refetch
      queryClient.invalidateQueries(["event-detail", eventId]);
      queryClient.invalidateQueries(["variables-offer-pdf", eventId]);
      queryClient.invalidateQueries(["expenses-offer-pdf", eventId]);
      queryClient.invalidateQueries(["event-rollups", eventId]);
      queryClient.invalidateQueries(["manifests", eventId]);

      //toast.success("Sort order updated successfully");
    } catch (error) {
      // Revert optimistic update on error
      setRows(prevRowsRef.current);
      //toast.error("Failed to update sort order");
      console.error("Failed to update sort order:", error);
    }
  };
  // Function to handle moving a row in the table
  const moveRowHandler = (fromIndex, toIndex) => {
    const updatedRows = [...rows];
    const [movedRow] = updatedRows.splice(fromIndex, 1);
    updatedRows.splice(toIndex, 0, movedRow);

    const reorderedRows = updatedRows.map((row, index) => {
      return {
        ...row,
        id: row.id,
        eventId: eventId, // Include eventId for the mutation
        sort_order: index + 1,
        on_sale: new Intl.DateTimeFormat("en-CA").format(new Date(row.on_sale)),
      };
    });
    // Update the local state with the new order
    setRows(updatedRows);

    // Trigger the mutation to save the new order to the backend
    handleSortOrderChange(reorderedRows);
  };

  // DraggableRow Component
  const DraggableRow = ({ row, index, moveRow, children }) => {
    const ref = useRef(null);
    const [, drop] = useDrop({
      accept: ITEM_TYPE,
      hover(item, monitor) {
        if (!ref.current) return;

        const dragIndex = item.index;
        const hoverIndex = index;

        // Do nothing if the item is hovering over itself
        if (dragIndex === hoverIndex) return;

        const hoverBoundingRect = ref.current.getBoundingClientRect();
        const hoverMiddleY =
          (hoverBoundingRect.bottom - hoverBoundingRect.top) / 2;

        const clientOffset = monitor.getClientOffset();
        const hoverClientY = clientOffset.y - hoverBoundingRect.top;

        // Only perform the move when the mouse has crossed half of the item's height
        if (dragIndex < hoverIndex && hoverClientY < hoverMiddleY) return;
        if (dragIndex > hoverIndex && hoverClientY > hoverMiddleY) return;

        moveRow(dragIndex, hoverIndex);

        item.index = hoverIndex;
      },
      drop(item, monitor) {
        const dragIndex = item.index;
        const hoverIndex = index;

        if (dragIndex !== hoverIndex) {
          moveRow(dragIndex, hoverIndex);
        }
      },
    });

    const [{ isDragging }, drag] = useDrag({
      type: ITEM_TYPE,
      item: { type: ITEM_TYPE, id: row.id, index },
      collect: (monitor) => ({
        isDragging: monitor.isDragging(),
      }),
    });

    drag(drop(ref));

    return (
      <tr
        ref={ref}
        style={{ opacity: isDragging ? 0.5 : 1 }}
        className={`grid grid-cols-[200px_repeat(6,1fr)_125px]`}
      >
        {children}
      </tr>
    );
  };

  // Delete Handlers
  const handleDeleteInitiate = (rowId) => {
    setDeletingRowId(rowId);
  };

  const handleDeleteConfirm = (rowId) => {
    onDeletePress(rowId);
    setDeletingRowId(null);
  };

  const handleDeleteCancel = () => {
    setDeletingRowId(null);
  };

  // Key Down Handler
  const handleKeyDown = (e, rowIndex, colIndex) => {
    const currentCell = e.target;
    const currentRow = currentCell.parentElement.parentElement;
    const table = currentRow.parentElement;
    const rows = Array.from(table.children);

    const moveFocus = (targetCell) => {
      if (
        targetCell &&
        targetCell.getAttribute("contenteditable") !== "false"
      ) {
        currentCell.blur();
        targetCell.focus();
        return true;
      }
      return false;
    };

    const findEditableCell = (row, startCol, direction = 1) => {
      const cells = Array.from(row.children);
      const indices =
        direction > 0
          ? Array.from(
            { length: cells.length - startCol },
            (_, i) => startCol + i
          )
          : Array.from({ length: startCol + 1 }, (_, i) => startCol - i);

      for (let i of indices) {
        const cell = cells[i]?.firstElementChild;
        if (cell && cell.getAttribute("contenteditable") !== "false") {
          return cell;
        }
      }
      return null;
    };

    const moveRight = () => {
      // Try to find editable cell in current row
      const nextCell = findEditableCell(currentRow, colIndex + 1);
      if (nextCell) return moveFocus(nextCell);

      // Move to next row if at end of current row
      if (rowIndex < rows.length - 1) {
        const nextRow = rows[rowIndex + 1];
        if (nextRow) {
          const firstEditableCell = findEditableCell(nextRow, 0);
          if (firstEditableCell) return moveFocus(firstEditableCell);
        }
      }
      return false;
    };

    const moveLeft = () => {
      // Try to find editable cell in current row
      const prevCell = findEditableCell(currentRow, colIndex - 1, -1);
      if (prevCell) return moveFocus(prevCell);

      // Move to previous row if at start of current row
      if (rowIndex > 0) {
        const prevRow = rows[rowIndex - 1];
        if (prevRow) {
          const lastEditableCell = findEditableCell(
            prevRow,
            prevRow.children.length - 1,
            -1
          );
          if (lastEditableCell) return moveFocus(lastEditableCell);
        }
      }
      return false;
    };

    switch (e.key) {
      case "Enter":
        e.preventDefault();
        if (e.shiftKey) {
          // Move up to same column
          if (rowIndex > 0) {
            const prevRow = rows[rowIndex - 1];
            const targetCell = prevRow.children[colIndex]?.firstElementChild;
            if (targetCell) moveFocus(targetCell);
          }
        } else if (
          accessor === "support_1_label" ||
          accessor === "support_2_label"
        ) {
          updateEventMutation.mutate(
            Object.assign(EVENT_REQUIRED_PARAMS, { [accessor]: value }),
            {
              onSuccess: () => {
                callback?.();
              },
            }
          );
        } else {
          // Move down to same column
          if (rowIndex < rows.length - 1) {
            const nextRow = rows[rowIndex + 1];
            const targetCell = nextRow.children[colIndex]?.firstElementChild;
            if (targetCell) moveFocus(targetCell);
          }
        }
        break;
      case "Tab":
        e.preventDefault();
        if (e.shiftKey) {
          moveLeft();
        } else {
          moveRight();
        }
        break;
      case "ArrowLeft":
        if (!e.ctrlKey && !e.metaKey) {
          e.preventDefault();
          moveLeft();
        }
        break;
      case "ArrowRight":
        if (!e.ctrlKey && !e.metaKey) {
          e.preventDefault();
          moveRight();
        }
        break;
      case "ArrowUp":
        e.preventDefault();
        if (rowIndex > 0) {
          const prevRow = rows[rowIndex - 1];
          const targetCell = prevRow.children[colIndex]?.firstElementChild;
          if (targetCell) moveFocus(targetCell);
        }
        break;
      case "ArrowDown":
        e.preventDefault();
        if (rowIndex < rows.length - 1) {
          const nextRow = rows[rowIndex + 1];
          const targetCell = nextRow.children[colIndex]?.firstElementChild;
          if (targetCell) moveFocus(targetCell);
        }
        break;
      case "Escape":
        e.preventDefault();
        currentCell.textContent = originalContentRef.current;
        currentCell.blur();
        break;
      default:
        return;
    }
  };

  // Add Row Handlers
  const handleAddRow = () => {
    setIsAddingRow(true);
    setTempRow(TEMP_ROW);
  };

  const handleSaveNewManifest = () => {
    let rowData = {
      name:
        tempRow.ticketing === ""
          ? TEMP_ROW_PLACEHOLDERS.ticketing
          : tempRow.ticketing,
      qty:
        tempRow.capacity === ""
          ? TEMP_ROW_PLACEHOLDERS.capacity
          : tempRow.capacity,
      price:
        tempRow.net_price === ""
          ? TEMP_ROW_PLACEHOLDERS.net_price
          : tempRow.net_price,
      ticket_fees:
        tempRow.ticket_fees === ""
          ? TEMP_ROW_PLACEHOLDERS.ticket_fees
          : tempRow.ticket_fees,
      sort_order:
        tempRow.sort_order === ""
          ? TEMP_ROW_PLACEHOLDERS.sort_order
          : tempRow.sort_order,
      eventId:
        tempRow.eventId === ""
          ? TEMP_ROW_PLACEHOLDERS.eventId
          : tempRow.eventId,
      // Include other necessary fields if required
    };
    setIsAddingRow(false);
    setTempRow(TEMP_ROW);
    onAddNewRow && onAddNewRow(rowData);
  };

  const handleCancelNewManifest = () => {
    setIsAddingRow(false);
    setTempRow(TEMP_ROW);
  };

  // Blur and Focus Handlers
  const handleBlur = (e, colIndex, row, rowIndex) => {
    const currentContent = e.target.textContent;
    const currentCell = e.target;
    const currentRow = currentCell.parentElement.parentElement;
    const table = currentRow.parentElement;
    const allRows = Array.from(table.children);
    const currentRowIndex = allRows.indexOf(currentRow);

    // Store current position for focus restoration
    const focusPosition = {
      rowIndex: currentRowIndex,
      colIndex: colIndex,
    };

    // Format content based on type
    if (headers[colIndex].format === "numbers") {
      currentCell.textContent =
        currentCell.textContent === ""
          ? "0"
          : currentCell.textContent.replace(/\D/g, "");
      if (currentCell.textContent === "0") return;
    } else if (headers[colIndex].format === "money") {
      currentCell.textContent = formatInputToMoney(
        currentCell.textContent === "" ? "0" : currentCell.textContent
      );
      if (
        currentCell.textContent === "Invalid Input" ||
        currentCell.textContent === "$0.00"
      )
        return;
    } else if (headers[colIndex].format === "percentage") {
      const value = currentCell.textContent.split("/")[0].trim();
      const numValue = parseInt(value) || 0;
      const validValue = Math.min(100, Math.max(0, numValue));
      currentCell.textContent = `${validValue} / 100`;
      if (validValue === 0) return;
    }

    // Remove visual feedback
    currentCell.style.outline = "";
    currentCell.style.backgroundColor = "";

    // Only update if content changed
    if (
      !currentCell.textContent ||
      currentContent === originalContentRef.current
    )
      return;

    onInputChange &&
      onInputChange(
        headers[colIndex].accessor,
        currentCell.textContent,
        rowIndex,
        row,
        () => {
          // After mutation completes, restore focus to the correct cell
          requestAnimationFrame(() => {
            const updatedTable =
              currentCell.parentElement.parentElement.parentElement;
            const updatedRows = Array.from(updatedTable.children);
            const targetRow = updatedRows[focusPosition.rowIndex];
            if (targetRow) {
              const targetCell =
                targetRow.children[focusPosition.colIndex]?.firstElementChild;
              if (
                targetCell &&
                targetCell.getAttribute("contenteditable") !== "false"
              ) {
                targetCell.focus();
              }
            }
          });
        }
      );
  };

  const handleFocus = (e, colIndex) => {
    const cell = e.target;

    // Only proceed if cell is editable
    if (headers[colIndex].editable === false) return;

    // Store state
    originalContentRef.current = cell.textContent;
    lastFocusedElementRef.current = cell;

    // Clear placeholder values
    if (headers[colIndex].format === "money" && cell.textContent === "$0.00") {
      cell.textContent = "";
    } else if (
      headers[colIndex].format === "numbers" &&
      cell.textContent === "0"
    ) {
      cell.textContent = "";
    }

    // Select all content
    requestAnimationFrame(() => {
      try {
        const range = document.createRange();
        range.selectNodeContents(cell);
        const sel = window.getSelection();
        sel.removeAllRanges();
        sel.addRange(range);
      } catch (err) {
        console.warn("Focus selection failed:", err);
      }
    });

    // Visual feedback
    cell.style.outline = "2px solid #3182ce";
    cell.style.backgroundColor = "#EDF2F7";

    // Ensure cell is visible
    cell.scrollIntoView({ block: "nearest", behavior: "smooth" });
  };

  // Function to format input as money
  function formatInputToMoney(input) {
    const number = parseFloat(input.replace(/[^0-9.-]+/g, ""));
    if (isNaN(number)) return "Invalid Input";
    return `$${number.toFixed(2)}`;
  }

  // Function to update new cell data when adding a row
  const handleUpdateNewCell = (e, header) => {
    if (header.title === "GROSS PRICE" || header.title === "POTENTIAL") return;
    if (header.format === "money" && e.target.innerText !== "") {
      e.target.innerText = formatInputToMoney(e.target.innerText);
    } else if (header.format === "numbers") {
      e.target.innerText = e.target.innerText.replace(/\D/g, "");
    }
    setTempRow((prev) => ({
      ...prev,
      [formatInput(header.title)]: e.target.innerText,
    }));
    setTempRow((prev) => ({
      ...prev,
      [formatInput(header.title)]: e.target.innerText,
    }));
  };

  // Ensure that rows and headers are available
  if (!rows || !headers) return <></>;

  return (
    <>
      <table className="w-full grid border border-dashed border-gray-300 bg-gray-100">
        <thead>
          <tr
            className={`grid grid-cols-[200px_repeat(6,1fr)_125px] bg-gray-300`}
          >
            {headers.map((column, index) => (
              <th
                key={index}
                className="text-left px-1 text-lg font-montserrat"
              >
                {column.title}
              </th>
            ))}
            <th key={7} className="text-left px-1 text-sm font-montserrat"></th>
          </tr>
        </thead>
        <tbody>
          {rows.map((row, index) => (
            <DraggableRow
              key={row.id}
              row={row}
              index={index}
              moveRow={moveRowHandler}
            >
              {headers.map((header, colIndex) => (
                <MultiLineTableCell
                  key={colIndex}
                  header={header}
                  isGreyedOut={deletingRowId === row.id}
                  handleKeyDown={(e) => handleKeyDown(e, index, colIndex)}
                  handleFocus={(e) => handleFocus(e, colIndex)}
                  handleBlur={(e) => handleBlur(e, colIndex, row, index)}
                  isChanged={changedCells.includes(
                    `${index}-${formatInput(header.title)}`
                  )}
                >
                  {row[formatInput(header.title)]}
                </MultiLineTableCell>
              ))}

              <td className="flex justify-end gap-1 p-1 text-sm font-montserrat">
                <Bars3Icon className="cursor-pointer w-4 h-4" />
                &nbsp;&nbsp;&nbsp;
                {deletingRowId === row.id ? (
                  <>
                    <button
                      className="bg-red-400 hover:bg-red-600 text-cave-white py-1 px-2 text-xs rounded"
                      onClick={() => handleDeleteConfirm(row.id)}
                    >
                      Confirm
                    </button>
                    <button
                      className="bg-gray-400 hover:bg-gray-600 text-cave-white py-1 px-2 text-xs rounded"
                      onClick={handleDeleteCancel}
                    >
                      Cancel
                    </button>
                  </>
                ) : (
                  <button
                    className="bg-red-400 hover:bg-red-600 text-cave-white py-1 px-2 text-xs rounded"
                    onClick={() => handleDeleteInitiate(row.id)}
                  >
                    Delete
                  </button>
                )}
              </td>
            </DraggableRow>
          ))}

          {isAddingRow && (
            <tr className={`grid grid-cols-[200px_repeat(6,1fr)_125px]`}>
              {headers.map((header, colIndex) => (
                <MultiLineTableCell
                  key={colIndex}
                  header={header}
                  placeholder={TEMP_ROW_PLACEHOLDERS[formatInput(header.title)]}
                  handleBlur={(e) => handleUpdateNewCell(e, header)}
                  handleKeyDown={(e) => handleKeyDown(e, rows.length, colIndex)}
                >
                  {tempRow[formatInput(header.title)]}
                </MultiLineTableCell>
              ))}
              <td className="flex justify-end gap-1 p-1 text-sm font-montserrat">
                <button
                  className="bg-gray-400 hover:bg-gray-500 text-cave-white py-1 px-2 text-xs rounded"
                  onClick={handleSaveNewManifest}
                >
                  Save
                </button>
                <button
                  className="bg-gray-400 hover:bg-gray-500 text-cave-white py-1 px-2 text-xs rounded"
                  onClick={handleCancelNewManifest}
                >
                  Cancel
                </button>
              </td>
            </tr>
          )}
        </tbody>
      </table>
      {addRowButtonName && (
        <button
          disabled={isAddingRow}
          className="bg-gray-400 disabled:bg-gray-200 rounded px-4 py-1 text-cave-white font-sans text-lg mt-2"
          onClick={!isAddingRow ? handleAddRow : () => { }}
        >
          {addRowButtonName}
        </button>
      )}
    </>
  );
};

// DraggableRow Component (defined outside for clarity)
const DraggableRow = ({ row, index, moveRow, children }) => {
  const ref = useRef(null);

  const [, drop] = useDrop({
    accept: ITEM_TYPE,
    hover(item, monitor) {
      if (!ref.current) return;
      const dragIndex = item.index;
      const hoverIndex = index;

      if (dragIndex === hoverIndex) return;

      const hoverBoundingRect = ref.current.getBoundingClientRect();
      const hoverMiddleY =
        (hoverBoundingRect.bottom - hoverBoundingRect.top) / 2;
      const clientOffset = monitor.getClientOffset();
      const hoverClientY = clientOffset.y - hoverBoundingRect.top;

      // Only perform the move when the mouse has crossed half of the item's height
      if (dragIndex < hoverIndex && hoverClientY < hoverMiddleY) return;
      if (dragIndex > hoverIndex && hoverClientY > hoverMiddleY) return;

      moveRow(dragIndex, hoverIndex);
      item.index = hoverIndex;
    },
  });

  const [{ isDragging }, drag] = useDrag({
    type: ITEM_TYPE,
    item: { type: ITEM_TYPE, id: row.id, index },
    collect: (monitor) => ({
      isDragging: monitor.isDragging(),
    }),
  });

  drag(drop(ref));

  return (
    <tr
      ref={ref}
      style={{ opacity: isDragging ? 0.5 : 1 }}
      className={`grid grid-cols-[200px_repeat(6,1fr)_125px]`}
    >
      {children}
    </tr>
  );
};

const EditableTableSelect = ({
  options,
  onSelect,
  parentSelection,
  placeholder,
  isMulti = false,
  customStyles,
}) => {
  const [selectedOption, setSelectedOption] = useState(parentSelection);

  useEffect(() => {
    setSelectedOption(parentSelection);
  }, [parentSelection]);

  const handleChange = (option) => {
    setSelectedOption(option);
    onSelect && onSelect(option);
  };

  const CustomMultiValueRemove = (props) => {
    return selectedOption.length > 1 ? (
      <components.MultiValueRemove {...props} />
    ) : null;
  };

  return (
    <div className="relative bg-cave-orange z-20">
      <Select
        options={options}
        value={selectedOption}
        onChange={handleChange}
        placeholder={placeholder}
        isMulti={isMulti}
        isClearable={false}
        components={{
          MultiValueRemove: CustomMultiValueRemove,
        }}
        classNames={{
          container: (state) =>
            `bg-cave-blue-2 border-4 border-cave-blue-3 m-0 cursor-pointer ${customStyles?.container || ""
            }`,
          control: (state) =>
            `bg-cave-blue-2 p-0 flex justify-center items-center m-0 border-none shadow-none cursor-pointer hover:bg-cave-orange ${customStyles?.control || ""
            }`,
          valueContainer: (state) =>
            `p-0 bg-cave-blue-2 border-none px-2 text-sm cursor-pointer hover:bg-cave-orange ${customStyles?.valueContainer || ""
            }`,
          dropdownIndicator: (state) =>
            "text-black border-none text-sm cursor-pointer",
          indicatorSeparator: (state) => "bg-transparent border-none",
          option: (state) =>
            `text-sm cursor-pointer ${state.isFocused ? "bg-gray-100" : "bg-cave-white"
            } hover:bg-gray-200 ${customStyles?.option || ""}`,
          menu: (state) =>
            "bg-cave-white border border-gray-300 mt-1 rounded-md shadow-lg",
        }}
        unstyled
      />
    </div>
  );
};

const EditableTable = ({
  rows,
  header,
  solidBorder,
  noBlue,
  onInputChange,
  onHeaderChange,
  onMsgChange,
  event,
}) => {
  const [isLoading, setLoading] = useState(null);
  const originalContentRef = useRef(null);

  const [isAdding, setAdding] = useState(null);
  const queryClient = useQueryClient();
  const deleteMutation = useMutation({
    mutationFn: (id) => {
      console.log("---> deleting expense");
      return deleteExpense(id);
    },
    onSuccess: async (id) => {
      queryClient.invalidateQueries(["event-detail", id.event]);
      queryClient.invalidateQueries(["variables-offer-pdf", id.event]);
      queryClient.invalidateQueries(["expenses-offer-pdf", id.event]);
    },
  });

  const handleDeleteClick = async (row) => {
    const confirmDelete = window.confirm(
      `Are you sure you want to delete ${row.header} expense?`
    );
    if (confirmDelete) {
      // Call the backend mutation for deletion
      //deleteExpense(row.accessor);
      await deleteMutation.mutateAsync(row.otherParams.id);
      if (event)
        queryClient.refetchQueries(["event-offer-pdf"], event?.data?.id);
    }
  };

  const handleBlur = (index, e, row, header) => {
    let valueToSend;

    if (row.isCustom) {
      valueToSend = e.target.textContent;
      e.target.textContent = row.custom_format;
    } else if (row.format === "money") {
      e.target.textContent = formatInputToMoney(
        e.target.textContent === "" ? "0" : e.target.textContent
      );
    } else if (row.format === "time") {
      e.target.textContent = formatTimeString(e.target.textContent);
    } else if (row.format === "number" && e.target.textContent === "") {
      e.target.textContent = "0";
      return;
    }

    if (
      (row.isCustom && originalContentRef.current !== valueToSend) ||
      originalContentRef.current !== e.target.textContent
    ) {
      setLoading(loadingEqn(row, index, header));
      onInputChange &&
        onInputChange(
          row.accessor,
          valueToSend ? valueToSend : e.target.textContent,
          row.otherParams,
          () => {
            setTimeout(() => {
              setLoading(null);
            }, FIELD_UPDATE_ANIMATION_TIME);
          }
        );
    }
  };

  const handleHeaderBlur = (index, e, row) => {
    onHeaderChange &&
      onHeaderChange(row.accessor, e.target.textContent, row.otherParams);
  };

  const handleSelection = (accessor, value, otherParams, callback) => {
    setLoading(true);
    onInputChange && onInputChange(accessor, value, otherParams, callback);
  };

  const handleHeaderSelection = (accessor, value, otherParams) => {
    onHeaderChange && onHeaderChange(accessor, value, otherParams);
  };

  const handleKeyDown = (e, index) => {
    if (e.key === "Enter") {
      e.preventDefault();
      e.target.blur();
    } else if (e.key === "Escape") {
      e.preventDefault();
      e.target.textContent = originalContentRef.current;
      e.target.blur();
    } else if (e.key === "Tab") {
      e.preventDefault();
      const currentRow = e.target.closest("tr");
      const nextRow = currentRow.nextElementSibling;
      const prevRow = currentRow.previousElementSibling;

      if (e.shiftKey) {
        // Tab backwards
        if (prevRow) {
          const prevCell = prevRow.querySelector('[contenteditable="true"]');
          if (prevCell) {
            prevCell.focus();
          }
        }
      } else {
        // Tab forwards
        if (nextRow) {
          const nextCell = nextRow.querySelector('[contenteditable="true"]');
          if (nextCell) {
            nextCell.focus();
          }
        }
      }
    } else if (e.key === "ArrowUp" && index > 0) {
      e.preventDefault();
      const previousRow = e.target.closest("tr").previousElementSibling;
      if (previousRow) {
        const cell = previousRow.querySelector('[contenteditable="true"]');
        if (cell) cell.focus();
      }
    } else if (e.key === "ArrowDown" && index < rows.length - 1) {
      e.preventDefault();
      const nextRow = e.target.closest("tr").nextElementSibling;
      if (nextRow) {
        const cell = nextRow.querySelector('[contenteditable="true"]');
        if (cell) cell.focus();
      }
    }
  };

  const handleFocus = (e, row, index) => {
    // Store original content for ESC handling
    originalContentRef.current = e.target.textContent;

    e.target.style.outline = "outline 2px solid #3182c";
    e.target.style.zIndex = 1000;
    e.target.style.position = "relative";

    if (row.isCustom) {
      e.target.textContent = row.value;
    }

    // Select all text content
    requestAnimationFrame(() => {
      const range = document.createRange();
      const sel = window.getSelection();
      range.selectNodeContents(e.target);
      sel.removeAllRanges();
      sel.addRange(range);
    });

    // Handle special cases
    if (row.format === "money" && e.target.textContent === "$0.00") {
      e.target.textContent = "";
    } else if (row.format === "number" && e.target.textContent === "0") {
      e.target.textContent = "";
    } else if (row.format === "percentage") {
      const value = e.target.textContent.split("/")[0].trim();
      e.target.textContent = value;
    }
  };

  const loadingEqn = (row, index, header) => {
    return row.header + index + header?.title;
  };

  const handleFormatValue = (value, row) => {
    if (row.isCustom) {
      return row.custom_format;
    } else {
      return value;
    }
  };

  if (!rows) return <></>;

  return (
    <table
      className={`min-w-full border border-gray-300 ${!solidBorder && "border-dashed"
        }`}
    >
      <thead>
        {header && (
          <tr>
            <th
              className={`bg-gray-300 ${header.value ? "text-left" : "text-center"
                } font-bold text-sm font-montserrat`}
              colSpan={header.value ? 1 : 2} // Span the entire width if header.value is not provided
            >
              {header.title}
            </th>
            {header.value && (
              <th
                className={`bg-gray-300 text-sm text-right font-montserrat ${header.isChanged ? "glow-text" : ""
                  }`}
              >
                {header.value}
              </th>
            )}
          </tr>
        )}
      </thead>
      <tbody className="w-full">
        {rows.map((row, index) => {
          if (row.highlight) row.editable = false;
          return (
            <tr
              className={`relative ${row.highlight ? "bg-gray-300" : "bg-transparent"
                } ${row.isChanged ? "glow-text" : ""} ${row.isDeleting ? "opacity-50" : ""
                }`}
              key={index}
            >
              <th
                className={`dark:bg-cave-white w-1/4 font-montserrat ${row.highlight ? "font-extrabold" : "font-normal"
                  } text-left p-1 text-sm `}
                data-placeholder={
                  row.placeholder && (row.header === "" || !row.header)
                    ? row.placeholder
                    : ""
                }
                contentEditable={row.isNew}
                suppressContentEditableWarning={true}
                tabIndex={0}
                onBlur={(e) =>
                  row.headerFormat !== "select" &&
                  handleHeaderBlur(index, e, row)
                }
              >
                {(row?.otherParams?.group_name === "Expenses" ||
                  row?.otherParams?.group_name === "Advertising" ||
                  row?.otherParams?.group_name === "Artist" ||
                  row?.otherParams?.group_name === "Catering") && (
                    <div
                      className="inline-block w-4 h-4 bg-red-200 text-black text-xs font-bold flex items-center justify-center cursor-pointer"
                      style={{
                        position: "absolute",
                        bottom: "5px",
                        left: "-15px",
                      }}
                      onClick={() => handleDeleteClick(row)}
                    >
                      X
                    </div>
                  )}
                {row.onToggle ? (
                  <div className="flex items-center justify-between gap-2">
                    {row.header}
                    <ToggleSwitch
                      isOn={row.toggle}
                      size="sm"
                      handleToggle={(state) =>
                        row.onToggle({ state: state, accesor: row.accessor })
                      }
                    />
                  </div>
                ) : row.headerFormat === "select" && row.headerSelectOptions ? (
                  <div className="border-2">
                    <EditableTableSelect
                      options={row.headerSelectOptions}
                      customStyles={{
                        container: () => "!bg-orange-400",
                        control: () => "!bg-orange-400",
                        valueContainer: () => "!bg-orange-400",
                        option: row.customStyles?.option,
                      }}
                      onSelect={(option) =>
                        handleHeaderSelection(
                          row.accessor,
                          option.value,
                          row.otherParams
                        )
                      }
                      parentSelection={row.value}
                      placeholder="Type Name or Select"
                      isMulti={row.isMulti}
                    />
                  </div>
                ) : (
                  row.header
                )}
              </th>
              <td
                className={`w-3/4 p-1 ${noBlue || row.highlight
                    ? "bg-transparent dark:bg-cave-white"
                    : "bg-blue-300"
                  } selection:bg-blue-900 selection:text-cave-white text-sm font-montserrat ${row.isChanged ? "glow-text" : ""
                  }  ${row.isNew ? "bg-cave-blue-2 border-4 border-cave-blue-3" : ""
                  }`}
                contentEditable={row.editable !== false}
                suppressContentEditableWarning={true}
                onBlur={(e) => {
                  if (row.format !== "select" && row.editable !== false) {
                    handleBlur(index, e, row, header);
                  }
                }}
                onKeyDown={(e) =>
                  row.editable !== false && handleKeyDown(e, index)
                }
                onFocus={(e) =>
                  row.editable !== false && handleFocus(e, row, index)
                }
                tabIndex={row.editable !== false ? 0 : -1}
                data-placeholder={
                  row.placeholder && (row.value === "" || !row.value)
                    ? row.placeholder
                    : ""
                }
                disabled={isLoading === loadingEqn(row, index, header)}
              >
                {row.format === "select" && row.options ? (
                  <EditableTableSelect
                    options={row.options}
                    customStyles={row.customStyles}
                    onSelect={(option) =>
                      handleSelection(
                        row.accessor,
                        row.isMulti ? option : option.value,
                        row.otherParams,
                        () => {
                          setLoading(false);
                        }
                      )
                    }
                    parentSelection={row.value}
                    isMulti={row.isMulti}
                  />
                ) : (
                  handleFormatValue(row.value, row)
                )}
              </td>
              {row.msg && (
                <td className="w-1/3 bg-blue-300 z-10 top-0.5 right-0 p-1 text-sm text-right font-montserrat">
                  {row.msg}
                </td>
              )}
            </tr>
          );
        })}
      </tbody>
    </table>
  );
};

const ContactTable = ({ eventID, eventData, EVENT_OFFER_REQUIRED_PARAMS }) => {
  const queryClient = useQueryClient();
  const [updatedProperties, setUpdatedProperties] = useState([]);

  // Get the latest offer from eventData
  const latestOffer = eventData?.offers?.sort(
    (a, b) => new Date(b.modified) - new Date(a.modified)
  )?.[0];

  const updateEventOfferMutation = useMutation({
    mutationFn: async (data) => {
      return await updateEventOffer(eventID, data);
    },
    onMutate: async (newData) => {
      await queryClient.cancelQueries(["event-detail", eventID]);
      const previousEventData = queryClient.getQueryData([
        "event-detail",
        eventID,
      ]);

      if (previousEventData) {
        queryClient.setQueryData(["event-detail", eventID], {
          ...previousEventData,
          offer_percentage: newData.offer_percentage,
        });
      }
      return { previousEventData };
    },
    onError: (err, newData, context) => {
      if (context?.previousEventData) {
        queryClient.setQueryData(
          ["event-detail", eventID],
          context.previousEventData
        );
      }
      toast.error("Failed to update offer percentage");
    },
    onSuccess: () => {
      queryClient.invalidateQueries(["event-detail", eventID]);
      queryClient.invalidateQueries(["variables-offer-pdf", eventID]);
      queryClient.invalidateQueries(["expenses-offer-pdf", eventID]);
      queryClient.invalidateQueries(["event-rollups", eventID]);
    },
  });

  // Only call this function when input loses focus (onBlur)
  function handleUpdateOffer(accessor, value) {
    // Don't send update if value is empty
    if (!value) return;

    const updateData = {
      ...latestOffer,
      ...EVENT_OFFER_REQUIRED_PARAMS,
      [accessor]: value,
    };

    if (accessor === "offer_percentage") {
      const numValue = parseInt(value);
      updateData.offer_percentage = !isNaN(numValue)
        ? Math.min(100, Math.max(0, numValue))
        : 0;
    }

    updateEventOfferMutation.mutate(updateData);
  }

  return (
    <EditableTable
      rows={[
        {
          header: "PRODUCTION ADV.",
          value: latestOffer?.production_adv || "production@mammothlive.com",
          accessor: "production_adv",
          isChanged: updatedProperties.includes("production_adv"),
          editable: true,
          format: "text",
        },
        {
          header: "LOCAL MARKETING",
          value: latestOffer?.local_marketing || "",
          accessor: "local_marketing",
          isChanged: updatedProperties.includes("local_marketing"),
          editable: true,
          format: "text",
        },
        {
          header: "TICKETING",
          value: latestOffer?.ticketing || "ticketing@mammothlive.com",
          accessor: "ticketing",
          isChanged: updatedProperties.includes("ticketing"),
          editable: true,
          format: "text",
        },
        {
          header: "TOUR ADMAT",
          value: latestOffer?.tour_admat || "",
          accessor: "tour_admat",
          isChanged: updatedProperties.includes("tour_admat"),
          editable: true,
          format: "text",
        },
        {
          header: "TOUR MARKETING",
          value: latestOffer?.tour_marketing || "",
          accessor: "tour_marketing",
          isChanged: updatedProperties.includes("tour_marketing"),
          editable: true,
          format: "text",
        },
        {
          header: "ONSALE INFO",
          value: latestOffer?.onsale_info || "",
          accessor: "onsale_info",
          isChanged: updatedProperties.includes("onsale_info"),
          editable: true,
          format: "text",
        },
        {
          header: "PRE-SALE INFO",
          value: latestOffer?.presale_info || "",
          accessor: "presale_info",
          isChanged: updatedProperties.includes("presale_info"),
          editable: true,
          format: "text",
        },
        {
          header: "CONTRACTS",
          value: latestOffer?.contracts || "contracts@mammothlive.com",
          accessor: "contracts",
          isChanged: updatedProperties.includes("contracts"),
          editable: true,
          format: "text",
        },
      ]}
      header={{ title: "CONTACT INFO" }}
      noBlue={true}
      onInputChange={handleUpdateOffer}
    />
  );
};

const EditableField = ({ item, onBlur, className }) => {
  const [field, setField] = useState(item.value);
  const [isEditingField, setIsEditingField] = useState(false);
  const [updated, setUpdated] = useState(false);
  const fieldChanged = () => field !== item?.value;

  const handleOnBlur = () => {
    setIsEditingField(false);
    if (onBlur && fieldChanged()) {
      setUpdated(true);
      onBlur(field);
    }
  };

  useEffect(() => {
    if (item?.value) setField(item?.value);
  }, [item]);

  if (updated) {
    setTimeout(() => {
      setUpdated(false);
    }, FIELD_UPDATE_ANIMATION_TIME);
  }
  return (
    <input
      className={`ring ring-transparent hover:ring-blue-400 focus:ring-blue-600 focus:outline-none duration-200 ${className} ${updated ? "glow-text" : ""
        }`}
      type="text"
      value={field || ""}
      onChange={(e) => setField(e.target.value)}
      onBlur={handleOnBlur}
      onKeyDown={(e) => {
        if (e.key === "Enter" && fieldChanged()) {
          e.target.blur(); // Unfocus input after pressing Enter
          e.preventDefault();
        }
      }}
    />
  );
};

const ExpenseTable = ({
  handleUpdateExpense,
  displayExpenses,
  advertisingExpenses,
  totalAdvertisingExpenses,
  id,
  event,
}) => {
  const [selectedGroup, setSelectedGroup] = useState("");
  const [inputValue, setInputValue] = useState("");
  const [inputHeader, setInputHeader] = useState("");
  const [advExpenseUpdate, setAdvExpenseUpdate] = useState(false);
  const [updatedProperties, setUpdatedProperties] = useState([]);
  const [isAdding, setIsAdding] = useState(false);
  const [notes, setNotes] = useState("");
  const [deletingExpenseId, setDeletingExpenseId] = useState(null);

  const advTotalRef = useRef(totalAdvertisingExpenses);
  const groups = useQuery(["expense-groups"], () => getExpenseGroups());
  const queryClient = useQueryClient();

  const deleteExpenseMutation = useMutation({
    mutationFn: async (id) => {
      return await deleteExpense(id);
    },
    onSuccess: async (data) => {
      queryClient.invalidateQueries(["event-detail", id]);
      queryClient.invalidateQueries(["variables-offer-pdf", id]);
      queryClient.invalidateQueries(["expenses-offer-pdf", id]);
      queryClient.invalidateQueries(["event-rollups", id]);
      setDeletingExpenseId(null);
    },
    onError: (error) => {
      //toast.error("Error deleting expense");
      setDeletingExpenseId(null);
    },
  });

  const handleDeleteExpense = async (expenseId) => {
    if (window.confirm("Are you sure you want to delete this expense?")) {
      setDeletingExpenseId(expenseId);
      await deleteExpenseMutation.mutateAsync(expenseId);
    }
  };

  const prevDisplayExpensesRef = useRef();
  const prevAdvertisingExpensesRef = useRef();

  useEffect(() => {
    prevDisplayExpensesRef.current = displayExpenses;
    prevAdvertisingExpensesRef.current = advertisingExpenses;
  }, []);

  useEffect(() => {
    const compareArrays = (prevArray, currArray) => {
      const changes = [];
      currArray.forEach((item, index) => {
        const prevItem = prevArray[index];
        if (prevItem && item) {
          Object.keys(item).forEach((key) => {
            if (item[key] !== prevItem[key]) {
              changes.push(item.subgroup_name?.toUpperCase());
            }
          });
        }
      });
      return changes;
    };

    const displayChanges = compareArrays(
      prevDisplayExpensesRef.current,
      displayExpenses
    );
    const advertisingChanges = compareArrays(
      prevAdvertisingExpensesRef.current,
      advertisingExpenses
    );

    setUpdatedProperties([
      ...new Set([...displayChanges, ...advertisingChanges]),
    ]);

    prevDisplayExpensesRef.current = displayExpenses;
    prevAdvertisingExpensesRef.current = advertisingExpenses;
  }, [displayExpenses, advertisingExpenses]);

  useEffect(() => {
    if (updatedProperties?.length > 0) {
      setTimeout(() => {
        setUpdatedProperties([]);
      }, FIELD_UPDATE_ANIMATION_TIME);
    }
  }, [updatedProperties]);

  useEffect(() => {
    if (advTotalRef.current !== totalAdvertisingExpenses) {
      setAdvExpenseUpdate(true);
      setTimeout(() => {
        setAdvExpenseUpdate(false);
      }, FIELD_UPDATE_ANIMATION_TIME);
    }
  }, [totalAdvertisingExpenses]);

  const createExpenseMutation = useMutation({
    mutationFn: async (data) => {
      return await createEventExpenseOffer(id, data);
    },
    onSuccess: async (data) => {
      setTimeout(() => {
        queryClient.invalidateQueries(["event-detail", data.event.id]);
        queryClient.invalidateQueries(["variables-offer-pdf", data.event.id]);
        queryClient.invalidateQueries(["expenses-offer-pdf", data.event.id]);
      }, 500);
    },
  });

  const CUSTOM_STYLES = {
    container: (provided) => ({
      ...provided,
      border: "1px dashed #B3B3B3",
      margin: 0,
    }),
    control: (provided) => ({
      ...provided,
      backgroundColor: "rgb(231, 114, 29)",
      padding: 0,
      display: "flex",
      justifyContent: "center",
      alignItems: "center",
      margin: 0,
      border: "none",
      boxShadow: "none",
    }),
    valueContainer: (provided) => ({
      ...provided,
      padding: 0,
      border: "none",
      paddingLeft: "8px",
      paddingRight: "8px",
      fontSize: 14,
    }),
    dropdownIndicator: (provided) => ({
      ...provided,
      color: "black",
      border: "none",
      fontSize: 14,
    }),
    indicatorSeparator: (provided) => ({
      ...provided,
      backgroundColor: "none",
      border: "none",
    }),
    option: (provided) => ({
      ...provided,
      fontSize: 14,
    }),
  };

  const handleSaveNewExpense = async () => {
    setIsAdding(false);
    setSelectedGroup("");
    setInputHeader("");
    setInputValue("");
    setNotes("");
    await createExpenseMutation.mutate({
      amount: amountStrToInt(inputValue),
      subgroup: inputHeader && parseInt(inputHeader?.split(";id:")[1]),
      payment_method: "",
      check_number: "-1",
      description: notes ?? "",
      notes: notes ?? "",
      is_offer: true,
      exclude_artist: false,
      exclude_copro: false,
      exclude_final: false,
    });
    await queryClient.invalidateQueries(["event-detail", id]);
    await queryClient.invalidateQueries(["expenses-offer-pdf", id]);
    await queryClient.invalidateQueries(["variables-offer-pdf", id]);
  };

  const handleCancelAdding = () => {
    setIsAdding(false);
    setNotes("");
  };

  const handleIsAdding = () => {
    setIsAdding(true);
  };

  const handleSelection = (value) => {
    setSelectedGroup(value);
  };

  const updateExpenseMutation = useMutation({
    mutationFn: async (data) => {
      return await updateEventExpense(data);
    },
    onMutate: async (newExpenseData) => {
      // Cancel outgoing refetches
      await queryClient.cancelQueries(["event-detail", id]);
      await queryClient.cancelQueries(["expenses-offer-pdf", id]);
      await queryClient.cancelQueries(["variables-offer-pdf", id]);

      // Snapshot previous values
      const previousExpenses = queryClient.getQueryData([
        "expenses-offer-pdf",
        id,
      ]);

      // Optimistically update expenses
      queryClient.setQueryData(["expenses-offer-pdf", id], (old) => {
        return old?.map((expense) =>
          expense.id === newExpenseData.expense_id
            ? { ...expense, ...newExpenseData }
            : expense
        );
      });

      return { previousExpenses };
    },
    onSuccess: async (data, newExpenseData) => {
      const eventId = newExpenseData.event;

      // Invalidate and refetch all related queries
      await Promise.all([
        queryClient.invalidateQueries(["event-detail", id]),
        queryClient.invalidateQueries(["variables-offer-pdf", id]),
        queryClient.invalidateQueries(["expenses-offer-pdf", id]),
        queryClient.invalidateQueries(["event-rollups", id]),
      ]);

      // Force refetch to ensure we have fresh data
      await Promise.all([
        queryClient.refetchQueries(["event-detail", id]),
        queryClient.refetchQueries(["variables-offer-pdf", id]),
        queryClient.refetchQueries(["expenses-offer-pdf", id]),
        queryClient.refetchQueries(["event-rollups", id]),
      ]);
    },
    onError: (err, newData, context) => {
      // Rollback on error
      queryClient.setQueryData(
        ["expenses-offer-pdf", id],
        context.previousExpenses
      );
      //toast.error("Error updating expense");
    },
  });

  const data = [];

  if (displayExpenses && advertisingExpenses) {
    // Map display expenses
    data.push(
      ...displayExpenses.map((expense) => ({
        header: (
          <div className="flex items-center gap-2">
            <button
              onClick={() => handleDeleteExpense(expense.id)}
              className="w-5 h-5 flex items-center justify-center bg-red-500 text-white text-xs rounded hover:bg-red-600 disabled:opacity-50"
              disabled={deletingExpenseId === expense.id}
              aria-label="Delete Expense"
            >
              X
            </button>
            {expense.subgroup_name ? expense.subgroup_name.toUpperCase() : ""}
          </div>
        ),
        value: amountDisplay(expense.amount),
        otherParams: expense,
        format: "money",
        editable: true,
        isChanged: updatedProperties.includes(
          expense.subgroup_name.toUpperCase()
        ),
        isDeleting: deletingExpenseId === expense.id,
        msg: (
          <div className="border-2 border-dashed selection:bg-blue-900 selection:text-cave-white">
            <EditableField
              key={expense.id}
              item={{
                value: expense.notes,
              }}
              className="bg-transparent"
              onBlur={async (value) => {
                await updateExpenseMutation.mutateAsync({
                  amount: expense.amount,
                  subgroup: inputHeader
                    ? parseInt(inputHeader.split(";id:")[1])
                    : null,
                  payment_method: "",
                  check_number: "-1",
                  description: value,
                  notes: value,
                  is_offer: true,
                  exclude_artist: false,
                  exclude_copro: false,
                  exclude_final: false,
                  id: expense.id,
                  expense_id: expense.id,
                });
              }}
            />
          </div>
        ),
      }))
    );

    // Add advertising total
    data.push({
      header: "ADVERTISING TOTAL",
      value: amountDisplay(totalAdvertisingExpenses),
      highlight: true,
      isChanged: advExpenseUpdate,
    });

    // Map advertising expenses
    data.push(
      ...advertisingExpenses.map((expense) => ({
        header: (
          <div className="flex items-center gap-2">
            <button
              onClick={() => handleDeleteExpense(expense.id)}
              className="w-5 h-5 flex items-center justify-center bg-red-500 text-white text-xs rounded hover:bg-red-600 disabled:opacity-50"
              disabled={deletingExpenseId === expense.id}
              aria-label="Delete Expense"
            >
              X
            </button>
            {expense.subgroup_name ? expense.subgroup_name.toUpperCase() : ""}
          </div>
        ),
        value: amountDisplay(expense.amount),
        otherParams: expense,
        format: "money",
        editable: true,
        isChanged: updatedProperties.includes(
          expense.subgroup_name.toUpperCase()
        ),
        isDeleting: deletingExpenseId === expense.id,
      }))
    );
  }

  if (isAdding && selectedGroup) {
    data.push({
      headerFormat: "select",
      headerSelectOptions: groups.data
        ?.flatMap(
          (group) =>
            group.name === selectedGroup.label &&
            group?.subgroups?.map((subgroup) => {
              return {
                label: subgroup.name,
                value:
                  subgroup.name.toLowerCase().replace(/\s+/g, "-") +
                  ";id:" +
                  subgroup.id,
              };
            })
        )
        .filter((item) => !!item),
      value: "",
      isNew: true,
      format: "money",
      accessor: "is_new",
    });
  }

  const handleChangeInputValue = (accessor, value, otherParams, callback) => {
    setInputValue(value);
    if (accessor != "is_new") {
      setInputValue(value);
      updateExpenseMutation.mutate({
        amount: amountStrToInt(value),
        subgroup: otherParams.subgroup,
        payment_method: "",
        check_number: "-1",
        description: notes ?? "",
        notes: notes ?? "",
        is_offer: true,
        exclude_artist: false,
        exclude_copro: false,
        exclude_final: false,
        expense_id: otherParams.id,
      });
      //   handleUpdateExpense &&
      //     handleUpdateExpense(value, otherParams, accessor, callback);
    }
  };

  const handleChangeInputHeader = (accessor, value, otherParams) => {
    setInputHeader(value);
  };
  return (
    <div className="w-full">
      <EditableTable
        rows={data}
        onInputChange={handleChangeInputValue}
        onHeaderChange={handleChangeInputHeader}
        event={event}
      />
      <div
        //      className={classNames(
        //54-            enabled ? 'translate-x-5' : 'translate-x-0',
        //55-            'pointer-events-none inline-block h-5 w-5 transform rounded-full bg-cave-white shadow ring-0 transition duration-200 ease-in-out'
        //56-          )}

        className={classNames(
          !isAdding
            ? "border-0"
            : "bg-cave-blue-1 border-cave-blue-3 border-4 p-4 m-2",
          "w-full inline-flex justify-end gap-2"
        )}
        style={{ marginBottom: "1rem" }}
        style={{ marginTop: "1rem" }}
      >
        {!isAdding ? (
          <button
            onClick={handleIsAdding}
            className="bg-gray-400 disabled:bg-gray-200 rounded px-4 py-1 text-cave-white font-sans text-lg mt-2"
            aria-label="Add New Expense"
          >
            Add Expense
          </button>
        ) : (
          <div className="flex w-full gap-20">
            <div className="mt-2 w-full">
              <Select
                options={groups.data?.map((group) => {
                  return {
                    label: group.name,
                    value:
                      group.name.toLowerCase().replace(/\s+/g, "-") +
                      ";id:" +
                      group.id,
                  };
                })}
                value={selectedGroup}
                styles={CUSTOM_STYLES}
                onChange={handleSelection}
                placeholder="Choose Subgroup"
              />
              <label className="text-sm" htmlFor="notes">
                Notes
              </label>
              <input
                type="text"
                className="border-2 border-dotted  w-full p-1 mt-2 text-sm"
                placeholder="Notes (optional)"
                value={notes}
                onChange={(e) => setNotes(e.target.value)}
                aria-label="Manifest Notes"
              />
            </div>
            <div className="flex self-start gap-2">
              <button
                onClick={handleCancelAdding}
                className="bg-cave-red disabled:bg-gray-200 rounded px-4 py-1 text-cave-white font-sans text-sm mt-2"
                aria-label="Cancel Adding Expense"
              >
                Cancel
              </button>
              <button
                onClick={() => handleSaveNewExpense(id)}
                className="bg-blue-500 disabled:bg-gray-200 rounded px-4 py-1 text-cave-white font-sans text-sm mt-2"
                aria-label="Save New Expense"
              >
                Save
              </button>
            </div>
          </div>
        )}
      </div>
    </div>
  );
};

function logChanges(previousData, newData, updateState) {
  function findChanges(oldObj, newObj, path = "") {
    const changes = [];
    Object.keys(newObj).forEach((key) => {
      const newPath = path ? `${path}.${key}` : key;
      if (
        oldObj[key] &&
        typeof newObj[key] === "object" &&
        newObj[key] !== null &&
        !Array.isArray(newObj[key])
      ) {
        const nestedChanges = findChanges(oldObj[key], newObj[key], newPath);
        if (Object.keys(nestedChanges).length > 0) {
          Object.assign(changes, nestedChanges);
        }
      } else if (newObj[key] !== oldObj[key]) {
        changes.push(newPath);
      }
    });
    return changes;
  }

  const changes = findChanges(previousData, newData);
  if (changes.length > 0) {
    updateState &&
      updateState((existingState) => {
        return [...existingState, ...changes];
      });
  }
}

function useQueryWithChangeDetection(queryKey, queryFn, options, updateState) {
  const queryInfo = useQuery(queryKey, queryFn, options);
  const previousDataRef = useRef();

  useEffect(() => {
    if (
      queryInfo.data &&
      previousDataRef.current &&
      queryInfo.data !== previousDataRef.current
    ) {
      logChanges(previousDataRef.current, queryInfo.data, updateState);
    }
    previousDataRef.current = queryInfo.data;
  }, [queryInfo.data]);

  return queryInfo;
}

export const EventEditOffer = () => {
  // state variables
  const [videoLoaded, setVideoLoaded] = useState(false);
  const [loadError, setLoadError] = useState(false);
  const videoRef = useRef(null);
  const [displayExpenses, setDisplayExpenses] = useState([]);
  const [advertisingExpenses, setAdvertisingExpenses] = useState([]);
  const [totalAdvertisingExpenses, setTotalAdvertisingExpenses] = useState(0);
  const [grossPotential, setGrossPotential] = useState(0);
  const [ticketFees, setTicketFees] = useState(0);
  const [getEventDate, setGetEventDate] = useState("");
  const [dealHeader, setDealHeader] = useState("");
  const [venueID, setVenueID] = useState(null);
  const [isRefetchingPDF, setIsRefetching] = useState(false);
  const [isPDFGenerated, setIsPDFGenerated] = useState(false);
  const [showHeader, setShowHeader] = useState(true);
  const [updatedProperties, setUpdatedProperties] = useState([]);
  const toggleHeader = () => {
    setShowHeader(!showHeader);
  };

  const { user } = useUser();

  // hooks
  const { id } = useParams();
  const navigate = useNavigate();
  const queryClient = useQueryClient();

  // GET requests
  const event = useQueryWithChangeDetection(
    ["event-detail", id],
    () => getEvent(id, { cache: false }),
    {},
    setUpdatedProperties
  );

  usePageTitle(
    event.isSuccess
      ? `[of]${event.data.name}`
      : `/c${String.fromCharCode(92)}ave_good...`
  );
  const expenses = useQueryWithChangeDetection(
    ["expenses-offer-pdf", id],
    () => getEventPDFExpenses(id),
    {},
    setUpdatedProperties
  );
  const variables = useQueryWithChangeDetection(
    ["variables-offer-pdf", id],
    () => getEventOfferVariables(id),
    {},
    setUpdatedProperties
  );
  const rollups = useQueryWithChangeDetection(
    ["event-rollups", id],
    () => getEventRollups(id),
    {},
    setUpdatedProperties
  );
  const venue = useQueryWithChangeDetection(
    ["venue-detail", venueID],
    () => getVenue(venueID),
    {
      enabled: !!venueID,
    },
    setUpdatedProperties
  );
  const pdfs = useQuery(["event-pdfs", id], () => getEventPDFs(id), {
    refetchInterval: () => (isRefetchingPDF ? 500 : false),
  });
  const venues = useQuery(["venues"], () => getVenues());
  //const artists = useQuery(["artists"], () => getArtists(), {
  //  enabled: false, // Don't fetch automatically
  //});
  // PUT/POST requests

  const updateEventOfferMutation = useMutation({
    mutationFn: async (data) => {
      console.log("Calling updateEventOffer API with data:", data);
      console.log("Event ID being used:", id);
      try {
        const result = await updateEventOffer(id, data);
        console.log("updateEventOffer API returned:", result);
        return result;
      } catch (error) {
        console.error("updateEventOffer API error:", error);
        throw error;
      }
    },
    onMutate: async (newData) => {
      await queryClient.cancelQueries(["event-detail", id]);
      const previousEventData = queryClient.getQueryData(["event-detail", id]);
      if (previousEventData) {
        queryClient.setQueryData(["event-detail", id], {
          ...previousEventData,
          offer_type: newData.offer_type,
          offer_headliner: newData.offer_headliner,
          offer_support_1: newData.offer_support_1,
          offer_support_2: newData.offer_support_2,
          offer_opener: newData.offer_opener,
          offer_support_1_label: newData.offer_support_1_label,
          offer_support_2_label: newData.offer_support_2_label,
        });
      }
      return { previousEventData };
    },
    onSuccess: () => {
      // Add a delay before invalidating queries to allow the server to process the update
      setTimeout(() => {
        // Invalidate and then explicitly refetch the queries
        Promise.all([
          queryClient.invalidateQueries(["event-detail", id]),
          queryClient.invalidateQueries(["variables-offer-pdf", id]),
          queryClient.invalidateQueries(["expenses-offer-pdf", id]),
          queryClient.invalidateQueries(["event-rollups", id]),
        ]).then(() => {
          // Force refetch after invalidation
          Promise.all([
            queryClient.refetchQueries(["event-detail", id], { force: true }),
            queryClient.refetchQueries(["variables-offer-pdf", id], {
              force: true,
            }),
            queryClient.refetchQueries(["expenses-offer-pdf", id], {
              force: true,
            }),
            queryClient.refetchQueries(["event-rollups", id], { force: true }),
          ]);
        });
      }, 500); // Add a 500ms delay
    },
    onError: (err, newData, context) => {
      queryClient.setQueryData(["event-detail", id], context.previousEventData);
      console.error("Error updating event offer:", err);
      //toast.error("Error updating event");
    },
  });
  // Expense mutations
  const createExpenseMutation = useMutation({
    mutationFn: async (data) => {
      const body = {
        amount: amountStrToInt(inputValue),
        subgroup: inputHeader && parseInt(inputHeader?.split(";id:")[1]),
        payment_method: "",
        check_number: "-1",
        description: notes ?? "",
        notes: notes ?? "",
        is_offer: true,
        exclude_artist: false,
        exclude_copro: false,
        exclude_final: false,
      };
      return await createEventExpenseOffer(id, body);
    },
    onSuccess: async (data) => {
      setTimeout(() => {
        queryClient.invalidateQueries(["event-detail", data.event.id]);
        queryClient.invalidateQueries(["variables-offer-pdf", data.event.id]);
        queryClient.invalidateQueries(["expenses-offer-pdf", data.event.id]);
      }, 500);
    },
  });

  // Consolidated expense mutation
  const updateExpenseMutation = useMutation({
    mutationFn: async (data) => {
      console.log("Calling updateEventExpense API with data:", data);
      const expenseData = {
        ...data,
        expense: {
          artist: 0,
          partner_1: 0,
          partner_2: 0,
          venue: 0,
          mammoth: 1,
        },
      };
      try {
        const result = await updateEventExpense(expenseData);
        console.log("updateEventExpense API returned:", result);
        return result;
      } catch (error) {
        console.error("updateEventExpense API error:", error);
        throw error;
      }
    },
    onMutate: async (newExpenseData) => {
      // Cancel all related queries
      const queries = [
        ["event-detail", id],
        ["variables-offer-pdf", id],
        ["expenses-offer-pdf", id],
        ["event-rollups", id],
      ];

      await Promise.all(
        queries.map((query) => queryClient.cancelQueries(query))
      );

      // Snapshot previous values
      const previousData = {
        expenses: queryClient.getQueryData(["expenses-offer-pdf", id]),
        variables: queryClient.getQueryData(["variables-offer-pdf", id]),
        event: queryClient.getQueryData(["event-detail", id]),
        rollups: queryClient.getQueryData(["event-rollups", id]),
      };

      // Optimistically update expenses
      queryClient.setQueryData(["expenses-offer-pdf", id], (old) =>
        old?.map((expense) =>
          expense.id === newExpenseData.expense_id
            ? {
              ...expense,
              amount: newExpenseData.amount,
              notes: newExpenseData.notes,
            }
            : expense
        )
      );

      return { previousData };
    },
    onError: (err, newData, context) => {
      // Revert all optimistic updates
      const { previousData } = context || {};
      if (previousData) {
        queryClient.setQueryData(
          ["expenses-offer-pdf", id],
          previousData.expenses
        );
        queryClient.setQueryData(
          ["variables-offer-pdf", id],
          previousData.variables
        );
        queryClient.setQueryData(["event-detail", id], previousData.event);
        queryClient.setQueryData(["event-rollups", id], previousData.rollups);
      }
      console.error("Error updating expense:", err);
      //toast.error("Error updating expense");
    },
    onSettled: async (data, error) => {
      // Always invalidate and refetch on settle (success or error)
      const queries = [
        ["event-detail", id],
        ["variables-offer-pdf", id],
        ["expenses-offer-pdf", id],
        ["event-rollups", id],
      ];

      // Log success or error
      if (error) {
        console.error("Expense update failed:", error);
      } else {
        console.log("Expense update succeeded:", data);
      }

      // Invalidate all queries first
      await Promise.all(
        queries.map((query) => queryClient.invalidateQueries(query))
      );

      // Force immediate refetch of all queries
      await Promise.all(
        queries.map((query) =>
          queryClient.refetchQueries(query, { force: true })
        )
      );
    },
  });
  const createPDFMutation = useMutation({
    mutationFn: (data) => {
      console.dir(data);
      return createEventPDF(id, data);
    },
    onError: (error, variables, context) => {
      toast.error("Error generating PDF");
      console.dir(error);
      console.dir(variables);
      console.dir(context);
    },
    onSettled: (data, error, variables, context) => {
      queryClient.invalidateQueries(["event-pdfs", id]);
      // Restart our countdown
      setIsRefetching(true);
      setTimeout(() => {
        setIsRefetching(false);
        setIsPDFGenerated(true);
      }, 15000);
    },
  });
  //const findArtistsFromIDs = (ids) => {
  //  return artists.data.filter((artist) => ids.includes(artist.id));
  //};
  const updateEventMutation = useMutation({
    mutationFn: async (data) => {
      console.log("Calling updateEvent API with data:", data);
      try {
        const result = await updateEvent(id, data);
        console.log("updateEvent API returned:", result);
        return result;
      } catch (error) {
        console.error("updateEvent API error:", error);
        throw error;
      }
    },
    onMutate: async (newData) => {
      await queryClient.cancelQueries(["event-detail", id]);
      const previousEventData = queryClient.getQueryData(["event-detail", id]);
      if (previousEventData && newData.age_requirement !== undefined) {
        queryClient.setQueryData(["event-detail", id], {
          ...previousEventData,
          age_requirement:
            newData.age_requirement !== undefined
              ? newData.age_requirement
              : previousEventData.age_requirement,
          facility_fee:
            newData.facility_fee !== undefined
              ? newData.facility_fee
              : previousEventData.facility_fee,
          who_sells_merch:
            newData.who_sells_merch !== undefined
              ? newData.who_sells_merch
              : previousEventData.who_sells_merch,
          venue: {
            ...previousEventData.venue,
            name:
              newData.venue_id !== undefined
                ? venues.data.find((venue) => venue.id === newData.venue_id)
                  ?.name
                : previousEventData.venue.name,
          },
        });
      }
      variables.refetch();
      return { previousEventData };
    },
    onSuccess: (data) => {
      // Add a delay before invalidating queries to allow the server to process the update
      setTimeout(() => {
        // Invalidate and then explicitly refetch the queries
        Promise.all([
          queryClient.invalidateQueries(["event-detail", id]),
          queryClient.invalidateQueries(["variables-offer-pdf", id]),
          queryClient.invalidateQueries(["expenses-offer-pdf", id]),
          queryClient.invalidateQueries(["event-rollups", id]),
        ]).then(() => {
          // Force refetch after invalidation
          Promise.all([
            queryClient.refetchQueries(["event-detail", id], { force: true }),
            queryClient.refetchQueries(["variables-offer-pdf", id], {
              force: true,
            }),
            queryClient.refetchQueries(["expenses-offer-pdf", id], {
              force: true,
            }),
            queryClient.refetchQueries(["event-rollups", id], { force: true }),
          ]);
        });
      }, 500); // Add a 500ms delay

      if (data.venue && venue.data && data.venue.id !== venue.data.id) {
        queryClient.invalidateQueries(["venue-detail", data.venue.id]);
        queryClient.refetchQueries(["venue-detail", data.venue.id], {
          force: true,
        });
      }
    },
    onError: (err, newData, context) => {
      queryClient.setQueryData(["event-detail", id], context.previousEventData);
      console.error("Error updating event:", err);
      //toast.error("Error updating event");
    },
  });
  const updateEventManifest = useMutation({
    mutationFn: async (data) => {
      const { kills, is_offer, manifest_id, sort_order } = data;

      const updateData = {
        ...data,
        sort_order: sort_order,
        kills: 0,
        is_offer: true,
      };
      return await updateManifest(updateData.manifest_id, updateData);
    },
    onMutate: async (newData) => {
      // Cancel outgoing refetches
      await queryClient.cancelQueries(["event-detail", newData.eventId]);
      await queryClient.cancelQueries(["variables-offer-pdf", newData.eventId]);

      // Snapshot previous values
      const previousEventData = queryClient.getQueryData([
        "event-detail",
        newData.eventId,
      ]);

      // Optimistically update
      if (previousEventData) {
        queryClient.setQueryData(["event-detail", newData.eventId], (old) => ({
          ...old,
          manifests: old.manifests.map((m) =>
            m.id === newData.manifest_id ? { ...m, ...newData } : m
          ),
        }));
      }

      return { previousEventData };
    },
    onError: (err, newData, context) => {
      // Revert optimistic update on error
      queryClient.setQueryData(
        ["event-detail", newData.eventId],
        context.previousEventData
      );
      //toast.error("Error updating manifest");
    },
    onSuccess: async (newData) => {
      const id = newData.event.id;
      // Invalidate and refetch
      queryClient.invalidateQueries(["event-detail", id]);
      queryClient.invalidateQueries(["variables-offer-pdf", id]);
      queryClient.invalidateQueries(["expenses-offer-pdf", id]);
      queryClient.invalidateQueries(["event-rollups", id]);
    },
    onSettled: () => {
      // Always refetch after error or success
      queryClient.invalidateQueries(["event-detail"]);
      queryClient.invalidateQueries(["variables-offer-pdf"]);
      queryClient.invalidateQueries(["expenses-offer-pdf"]);
      queryClient.invalidateQueries(["event-rollups"]);
    },
  });
  const updateEventAccountingMutation = useMutation({
    mutationFn: async (data) => {
      console.log("Calling updateEventAccounting API with data:", data);
      try {
        const result = await updateEventAccounting(id, data);
        console.log("updateEventAccounting API returned:", result);
        return result;
      } catch (error) {
        console.error("updateEventAccounting API error:", error);
        throw error;
      }
    },
    onMutate: async (newData) => {
      await queryClient.cancelQueries(["event-detail", id]);
      const previousEventData = queryClient.getQueryData(["event-detail", id]);
      if (previousEventData) {
        queryClient.setQueryData(["event-detail", id], {
          ...previousEventData,
          who_sells_merch:
            newData.who_sells_merch !== undefined
              ? newData.who_sells_merch
              : previousEventData.who_sells_merch,
          artist_hard_merch_percentage:
            newData.artist_hard_merch_percentage !== undefined
              ? newData.artist_hard_merch_percentage
              : previousEventData.artist_hard_merch_percentage,
          artist_soft_merch_percentage:
            newData.artist_soft_merch_percentage !== undefined
              ? newData.artist_soft_merch_percentage
              : previousEventData.artist_soft_merch_percentage,
        });
      }
      return { previousEventData };
    },
    onSuccess: (data) => {
      // Invalidate and then explicitly refetch the queries
      Promise.all([
        queryClient.invalidateQueries(["event-detail", id]),
        queryClient.invalidateQueries(["variables-offer-pdf", id]),
        queryClient.invalidateQueries(["expenses-offer-pdf", id]),
        queryClient.invalidateQueries(["event-rollups", id]),
      ]).then(() => {
        // Force refetch after invalidation
        Promise.all([
          queryClient.refetchQueries(["event-detail", id], { force: true }),
          queryClient.refetchQueries(["variables-offer-pdf", id], {
            force: true,
          }),
          queryClient.refetchQueries(["expenses-offer-pdf", id], {
            force: true,
          }),
          queryClient.refetchQueries(["event-rollups", id], { force: true }),
        ]);
      });
    },
    onError: (error) => {
      //toast.error("Error updating ticket fees");
      console.error("Error updating offer ticket fees:", error);
    },
  });

  const addManifestMutation = useMutation({
    mutationFn: async (newManifest) => {
      newManifest.is_offer = true;
      newManifest.sort_order = 0;
      return await createEventManifest(id, newManifest);
    },
    onMutate: async (newManifest) => {
      // Cancel outgoing refetches
      await Promise.all([
        queryClient.cancelQueries(["event-detail", id]),
        queryClient.cancelQueries(["variables-offer-pdf", id]),
        queryClient.cancelQueries(["event-rollups", id]),
        queryClient.cancelQueries(["expenses-offer-pdf", id]),
      ]);

      // Snapshot previous values
      const previousData = {
        event: queryClient.getQueryData(["event-detail", id]),
        variables: queryClient.getQueryData(["variables-offer-pdf", id]),
        rollups: queryClient.getQueryData(["event-rollups", id]),
        expenses: queryClient.getQueryData(["expenses-offer-pdf", id]),
      };

      // Optimistically update event data
      if (previousData.event) {
        queryClient.setQueryData(["event-detail", id], (old) => ({
          ...old,
          manifests: [
            ...old.manifests,
            { ...newManifest, id: "temp-" + Date.now() },
          ],
        }));
      }

      return { previousData };
    },
    onError: (err, newManifest, context) => {
      // Revert optimistic updates on error
      if (context?.previousData) {
        queryClient.setQueryData(
          ["event-detail", id],
          context.previousData.event
        );
        queryClient.setQueryData(
          ["variables-offer-pdf", id],
          context.previousData.variables
        );
        queryClient.setQueryData(
          ["event-rollups", id],
          context.previousData.rollups
        );
        queryClient.setQueryData(
          ["expenses-offer-pdf", id],
          context.previousData.expenses
        );
      }
      //toast.error("Failed to add manifest");
    },
    onSettled: async () => {
      // Invalidate and refetch all related queries
      await Promise.all([
        queryClient.invalidateQueries(["event-detail", id]),
        queryClient.invalidateQueries(["variables-offer-pdf", id]),
        queryClient.invalidateQueries(["event-rollups", id]),
        queryClient.invalidateQueries(["expenses-offer-pdf", id]),
      ]);

      // Force immediate refetch
      await Promise.all([
        queryClient.refetchQueries(["event-detail", id], { force: true }),
        queryClient.refetchQueries(["variables-offer-pdf", id], {
          force: true,
        }),
        queryClient.refetchQueries(["event-rollups", id], { force: true }),
        queryClient.refetchQueries(["expenses-offer-pdf", id], { force: true }),
      ]);
    },
  });
  const updateVenueMutation = useMutation({
    mutationFn: async (data) => {
      return await updateVenue(venueID, data);
    },
    onSuccess: () => {
      queryClient.invalidateQueries(["venue-detail", id]);
    },
  });

  const removeManifestMutation = useMutation({
    mutationFn: async (manifestId) => {
      return await deleteEventManifest(id, manifestId);
    },
    onMutate: async (manifestId) => {
      // Cancel outgoing refetches
      await Promise.all([
        queryClient.cancelQueries(["event-detail", id]),
        queryClient.cancelQueries(["variables-offer-pdf", id]),
        queryClient.cancelQueries(["event-rollups", id]),
        queryClient.cancelQueries(["expenses-offer-pdf", id]),
      ]);

      // Snapshot previous values
      const previousData = {
        event: queryClient.getQueryData(["event-detail", id]),
        variables: queryClient.getQueryData(["variables-offer-pdf", id]),
        rollups: queryClient.getQueryData(["event-rollups", id]),
        expenses: queryClient.getQueryData(["expenses-offer-pdf", id]),
      };

      // Optimistically remove manifest
      if (previousData.event) {
        queryClient.setQueryData(["event-detail", id], (old) => ({
          ...old,
          manifests: old.manifests.filter((m) => m.id !== manifestId),
        }));
      }

      return { previousData };
    },
    onError: (err, manifestId, context) => {
      // Revert optimistic updates on error
      if (context?.previousData) {
        queryClient.setQueryData(
          ["event-detail", id],
          context.previousData.event
        );
        queryClient.setQueryData(
          ["variables-offer-pdf", id],
          context.previousData.variables
        );
        queryClient.setQueryData(
          ["event-rollups", id],
          context.previousData.rollups
        );
        queryClient.setQueryData(
          ["expenses-offer-pdf", id],
          context.previousData.expenses
        );
      }
      //toast.error("Failed to remove manifest");
    },
    onSettled: async () => {
      // Invalidate and refetch all related queries
      await Promise.all([
        queryClient.invalidateQueries(["event-detail", id]),
        queryClient.invalidateQueries(["variables-offer-pdf", id]),
        queryClient.invalidateQueries(["event-rollups", id]),
        queryClient.invalidateQueries(["expenses-offer-pdf", id]),
      ]);

      // Force immediate refetch
      await Promise.all([
        queryClient.refetchQueries(["event-detail", id], { force: true }),
        queryClient.refetchQueries(["variables-offer-pdf", id], {
          force: true,
        }),
        queryClient.refetchQueries(["event-rollups", id], { force: true }),
        queryClient.refetchQueries(["expenses-offer-pdf", id], { force: true }),
      ]);
    },
  });

  useEffect(() => {
    if (updatedProperties?.length > 0) {
      setTimeout(() => {
        setUpdatedProperties([]);
      }, FIELD_UPDATE_ANIMATION_TIME);
    }
  }, [updatedProperties]);

  useEffect(() => {
    if (expenses?.data) {
      const expensesArray = Array.isArray(expenses.data) ? expenses.data : [];
      setDisplayExpenses(
        expensesArray.filter((expense) => expense.group_name !== "Advertising")
      );
      let advExpenses = expensesArray.filter(
        (expense) => expense.group_name === "Advertising"
      );
      setAdvertisingExpenses(advExpenses);
      setTotalAdvertisingExpenses(
        advExpenses.reduce((total, expense) => total + expense.amount, 0)
      );
    }
  }, [expenses?.data]);

  useEffect(() => {
    if (event && event.data) {
      setGrossPotential(
        event.data.manifests.reduce((total, manifest) => {
          const ticketFees = amountStrToInt(manifest.ticket_fees) || 0;
          return total + (manifest.price + ticketFees) * manifest.qty;
        }, 0)
      );
      setTicketFees(
        event.data.manifests.reduce((total, manifest) => {
          const ticketFees = amountStrToInt(manifest.ticket_fees) || 0;
          return total + manifest.qty * ticketFees;
        }, 0)
      );
      setGetEventDate(`[${event.data.date}]`);
    }
    if (!venueID && event?.data?.venue) {
      setVenueID(event.data.venue.id);
    }
  }, [event?.data]);

  useEffect(() => {
    if (!event.data) return;
    let dealHeader = `VERSUS ${event.data.offer_percentage}% DEAL`;
    if (event.data.offer_type === "flat") {
      dealHeader = "FLAT";
    } else if (event.data.offer_type === "promoter-profit") {
      dealHeader = "SPLIT POINT";
    } else if (event.data.offer_type === "nbor") {
      dealHeader = `DOOR DEAL`;
    }
    setDealHeader(dealHeader);
  }, [event?.data]);

  useEffect(() => {
    const video = videoRef.current;
    if (!video) return;

    const handleCanPlay = () => {
      setTimeout(() => {
        setVideoLoaded(true);
      }, 500);
    };

    video.addEventListener("canplaythrough", handleCanPlay);
    video.addEventListener("error", () => {
      setLoadError(true);
    });

    return () => {
      video.removeEventListener("canplaythrough", handleCanPlay);
      video.removeEventListener("error", () => { });
    };
  }, []);

  if (
    rollups.isLoading ||
    expenses.isLoading ||
    event.isLoading ||
    variables.isLoading ||
    venue.isLoading ||
    venues.isLoading
  ) {
    return (
      <div className="fixed inset-0 z-50 flex items-center justify-center">
        <video
          data-testid="loading-video"
          ref={videoRef}
          className="w-full h-full object-cover"
          autoPlay
          muted
          playsInline
          loop
        >
          <source src="/static/images/cave_loading25.mp4" type="video/mp4" />
        </video>
      </div>
    );
  }
  // Let errors propagate to the error boundary
  if (rollups.isError) throw rollups.error;
  if (expenses.isError) throw expenses.error;
  if (event.error) throw event.error;
  if (variables.error) throw variables.error;
  if (venue.error) throw venue.error;
  if (venues.error) throw venues.error;

  // constants
  const AGE_OPTIONS = [
    { label: "All Ages", value: "all-ages" },
    { label: "13+", value: "13-up" },
    { label: "14+", value: "14-up" },
    { label: "15+", value: "15-up" },
    { label: "16+", value: "16-up" },
    { label: "17+", value: "17-up" },
    { label: "18+", value: "18-up" },
    { label: "19+", value: "19-up" },
    { label: "20+", value: "20-up" },
    { label: "21+", value: "21-up" },
  ];
  const EVENT_OFFER_TYPE_OPTIONS = [
    { label: "Flat", value: "flat" },
    { label: "Promoter Profit", value: "promoter-profit" },
    { label: "Versus", value: "vs" },
    { label: "Door Deal", value: "nbor" },
  ];
  const WHO_SELLS_MERCH_OPTIONS = [
    { label: "Mammoth", value: "mammoth" },
    { label: "Artist", value: "artist" },
    { label: "Venue", value: "venue" },
  ];

  const handleMapOptions = (options = [], type = "artist") => {
    if (!Array.isArray(options)) return [];
    return options.map((option) => {
      if (type === "buyer") {
        // option is a People object
        return {
          value: option?.id, // People ID
          label: `${option?.first_name} ${option?.last_name}`,
          user_id: option?.user?.id, // Nested user ID if needed
        };
      }
      return { value: option?.id, label: option?.name };
    });
  };
  const VENUE_OPTIONS = venues.data.map((option, index) => {
    return { value: option.name, label: option.name };
  });
  //const ARTIST_OPTIONS = handleMapOptions(artists.data);
  const BUYER_OPTIONS =
    event.data?.buyers?.map((buyer) => ({
      value: buyer.person_id,
      label: `${buyer.first_name} ${buyer.last_name}`,
    })) || [];

  const EVENT_REQUIRED_PARAMS = {
    name: event.data.name,
    date: event.data.date,
    artists: event.data.artists ? [event.data.artists[0].id] : [0],
    state: event.data.state,
    age_requirement: event.data.age_requirement,
    abbr: event.data.abbr,
    description: event.data.description,
    door_time: event.data.door_time,
    opener_time: event.data.opener_time,
    support_time: event.data.support_time,
    headliner_time: event.data.headliner_time,
    curfew_time: event.data.curfew_time,
    company: event.data.company || "mammoth",
    offer_notes: event.data.offer_notes,
    deal_notes: event.data.deal_notes,
    confirmation_notes: event.data.confirmation_notes,
    venue_id: event.data.venue.id ?? 0,
    has_insurance: event.data.has_insurance,
    has_credit_card_fees: event.data.has_credit_card_fees,
    has_ticket_commission: event.data.has_ticket_commission,
    has_ascap: event.data.has_ascap,
    has_bmi: event.data.has_bmi,
    has_sesac: event.data.has_sesac,
    offer_has_rent: event.data.offer_has_rent,
    offer_has_comp_tax: event.data.offer_has_comp_tax,
    offer_has_gmr: event.data.offer_has_gmr ?? false,
    offer_gmr: event.data.offer_gmr,
    facility_fee: event.data.facility_fee,
    offer_support_1: event.data.offer_support_1 || "",
    offer_support_2: event.data.offer_support_2 || "",
    offer_support_1_label: event.data.offer_support_1_label || "",
    offer_support_2_label: event.data.offer_support_2_label || "",
    offer_opener: event.data.offer_opener,
    offer_billing: event.data.offer_billing,
    charity: event.data.charity,
    platinum_lift: event.data.platinum_lift,
    buyers: event.data.buyers
      ? event.data.buyers.map((buyer) => buyer.person_id)
      : [],
  };
  // Get the latest offer
  const latestOffer = event.data?.offers?.sort(
    (a, b) => new Date(b.modified) - new Date(a.modified)
  )?.[0];

  const EVENT_OFFER_REQUIRED_PARAMS = {
    offer_type: event.data.offer_type,
    offer_agent: event.data.offer_agent,
    offer_headliner: event.data.offer_headliner,
    offer_support_1: event.data.offer_support_1,
    offer_support_2: event.data.offer_support_2,
    offer_support_1_label: event.data.offer_support_1_label,
    offer_support_2_label: event.data.offer_support_2_label,
    offer_opener: event.data.offer_opener,
    offer_billing: event.data.offer_billing,
    offer_stipulations: event.data.offer_stipulations,
    offer_avails_1: event.data.offer_avails_1,
    offer_avails_2: event.data.offer_avails_2,
    facility_fee: event.data.facility_fee,
    has_ascap: event.data.has_ascap,
    has_bmi: event.data.has_bmi,
    has_sesac: event.data.has_sesac,
    offer_comp_tax: event.data.offer_comp_tax,
    offer_gmr: event.data.offer_gmr,
    offer_has_gmr: event.data.offer_has_gmr,
    offer_minimum_rent: event.data.offer_minimum_rent,
    offer_rent_per_paid: event.data.offer_rent_per_paid,
    offer_onsale_info: event.data.offer_onsale_info,
    offer_presale_info: event.data.offer_presale_info,
    offer_notes: event.data.offer_notes,
    deal_notes: event.data.deal_notes,
    offer_percentage: event.data.offer_percentage || 0, // Explicitly include with fallback
    // Get contact info from latestOffer
    production_adv: latestOffer?.production_adv || "production@mammothlive.com",
    local_marketing: latestOffer?.local_marketing || "",
    ticketing: latestOffer?.ticketing || "ticketing@mammothlive.com",
    tour_admat: latestOffer?.tour_admat || "",
    tour_marketing: latestOffer?.tour_marketing || "",
    onsale_info: latestOffer?.onsale_info || "",
    presale_info: latestOffer?.presale_info || "",
    contracts: latestOffer?.contracts || "contracts@mammothlive.com",
    buyers: event.data.buyers,
  };

  const EVENT_ACCOUNTING_REQUIRED_PARAMS = {
    concessions: event.data.concessions,
    merch: event.data.merch,
    rebate: event.data.rebate,
    charity: event.data.charity,
    facility_fee: event.data.facility_fee,
    drop_count: event.data.drop_count,
    platinum_lift: event.data.platinum_lift,
    vip_lift: event.data.vip_lift,
    has_ascap: event.data.has_ascap,
    has_bmi: event.data.has_bmi,
    has_insurance: event.data.has_insurance,
    has_sesac: event.data.has_sesac,
    has_credit_card_fees: event.data.has_credit_card_fees,
    credit_card_fees: event.data.credit_card_fees,
    has_ticket_commission: event.data.has_ticket_commission,
    ticket_commission: event.data.ticket_commission,
    insurance_amount: event.data.insurance_amount,
    offer_comp_tax: event.data.offer_comp_tax,
    offer_has_comp_tax: event.data.offer_has_comp_tax,
    offer_gmr: event.data.offer_gmr,
    offer_has_rent: event.data.offer_has_rent,
    hold_tm: event.data.hold_tm,
    hold_etix: event.data.hold_etix,
    hold_venue: event.data.hold_venue,
    hold_fan_club: event.data.hold_fan_club,
    hold_outlets: event.data.hold_outlets,
    hold_vip: event.data.hold_vip,
    mammoth_percentage: event.data.mammoth_percentage,
    building_percentage: event.data.building_percentage,
    who_sells_merch: event.data.who_sells_merch,
    artist_soft_merch_percentage: event.data.artist_soft_merch_percentage,
    artist_hard_merch_percentage: event.data.artist_hard_merch_percentage,
  };

  const handleUpdateExpense = async (
    value,
    otherParams,
    accessor,
    callback
  ) => {
    console.log(
      `handleUpdateExpense called with accessor: ${accessor}, value:`,
      value
    );

    // Store current data for potential rollback
    const currentData = {
      expenses: queryClient.getQueryData(["expenses-offer-pdf", id]),
      event: queryClient.getQueryData(["event-detail", id]),
      variables: queryClient.getQueryData(["variables-offer-pdf", id]),
      rollups: queryClient.getQueryData(["event-rollups", id]),
    };

    try {
      if (accessor === "is_new") {
        console.log("Creating new expense with value:", value);
        await createExpenseMutation.mutateAsync({
          payment_method: "",
          check_number: "",
          description: "",
          amount: amountStrToInt(value),
          notes: accessor === "notes" ? value : otherParams?.notes || "",
          is_offer: true,
          exclude_artist: false,
          exclude_copro: false,
          exclude_final: false,
        });
      } else if (otherParams && otherParams.id) {
        console.log("Updating existing expense:", otherParams.id);
        await updateExpenseMutation.mutateAsync({
          ...otherParams,
          expense_id: otherParams.id,
          payment_method: otherParams.payment_method || "",
          check_number: otherParams.check_number || "",
          description: otherParams.description || "",
          notes: accessor === "notes" ? value : otherParams.notes || "",
          exclude_artist: false,
          exclude_copro: false,
          exclude_final: false,
          amount: amountStrToInt(value),
          event: id, // Add event ID to ensure proper invalidation
        });
      } else {
        console.warn("Invalid parameters for updating expense");
        return;
      }

      console.log(
        "Expense update successful, forcing refetch of all dependent queries"
      );

      // Force immediate refetch of all dependent queries
      await Promise.all([
        queryClient.refetchQueries(["event-detail", id], { force: true }),
        queryClient.refetchQueries(["variables-offer-pdf", id], {
          force: true,
        }),
        queryClient.refetchQueries(["expenses-offer-pdf", id], { force: true }),
        queryClient.refetchQueries(["event-rollups", id], { force: true }),
      ]);

      // Add delay to allow refetch to complete before callback
      setTimeout(() => {
        callback?.();
      }, 800);
    } catch (error) {
      console.error("Error updating expense:", error);
      //toast.error("Failed to update expense");

      // Revert optimistic updates on error
      if (currentData.expenses) {
        queryClient.setQueryData(
          ["expenses-offer-pdf", id],
          currentData.expenses
        );
      }
      if (currentData.event) {
        queryClient.setQueryData(["event-detail", id], currentData.event);
      }
      if (currentData.variables) {
        queryClient.setQueryData(
          ["variables-offer-pdf", id],
          currentData.variables
        );
      }
      if (currentData.rollups) {
        queryClient.setQueryData(["event-rollups", id], currentData.rollups);
      }
    }
  };

  const handleUpdateEvent = (accessor, value, otherParams, callback) => {
    console.log(
      `handleUpdateEvent called with accessor: ${accessor}, value:`,
      value
    );

    // Store current event data for potential rollback
    const currentEventData = queryClient.getQueryData(["event-detail", id]);

    updateEventMutation.mutate(
      Object.assign(EVENT_REQUIRED_PARAMS, { [accessor]: value }),
      {
        onSuccess: () => {
          console.log(`Successfully updated ${accessor} to:`, value);
          // Add delay to allow refetch to complete before callback
          setTimeout(() => {
            callback?.();
          }, 500);
        },
        onError: (error) => {
          console.error(`Error updating ${accessor}:`, error);
          // Revert optimistic update on error
          queryClient.setQueryData(["event-detail", id], currentEventData);
        },
      }
    );
  };

  const handleUpdateTime = (accessor, value, otherParams, callback) => {
    if (value === undefined || value === "") {
      return; // Exit if value is undefined or empty
    }
    const formattedValue = formatToHHMMSS(value);
    if (formattedValue && !formattedValue?.toLowerCase()?.includes("invalid")) {
      handleUpdateEvent(accessor, formattedValue, otherParams, callback);
    } else {
      callback?.();
    }
  };

  const handleChangeInput = (
    accessor,
    value,
    otherParams,
    eventId,
    callback
  ) => {
    try {
      // Validate inputs before proceeding
      if (!otherParams?.id) {
        console.error("Missing manifest ID");
        //toast.error("Invalid manifest data");
        return;
      }

      const body = {
        manifest_id: otherParams.id,
        name: otherParams.name,
        qty: String(otherParams.qty),
        price: String(otherParams.price), // Keep price as a string
        on_sale: new Intl.DateTimeFormat("en-CA").format(
          new Date(otherParams.on_sale)
        ),
        comps: String(otherParams.comps),
        eventId: eventId,
        ticket_fees: String(amountStrToInt(otherParams.ticket_fees)),
        sort_order: String(otherParams.sort_order),
        [accessor]: value,
      };

      // Ensure all numeric values are strings and properly formatted
      body.qty = String(parseInt(body.qty, 10) || 0);
      body.comps = String(parseInt(body.comps, 10) || 0);
      body.ticket_fees = String(amountStrToInt(body.ticket_fees) || 0);
      body.sort_order = String(parseInt(body.sort_order, 10) || 0);

      // Handle the case where the accessor is one of the numeric fields
      if (["qty", "comps", "ticket_fees", "sort_order"].includes(accessor)) {
        body[accessor] = String(
          accessor === "ticket_fees"
            ? amountStrToInt(value) || 0
            : parseInt(value, 10) || 0
        );
      } else if (accessor === "price") {
        // Keep price as a string without converting
        body[accessor] = String(value);
      }

      // Validate numbers
      if (
        isNaN(Number(body.qty)) ||
        isNaN(Number(body.ticket_fees)) ||
        isNaN(Number(body.comps)) ||
        isNaN(Number(body.sort_order))
      ) {
        toast.error("Invalid number format");
        return;
      }

      updateEventManifest.mutate(body, {
        onSuccess: () => {
          callback?.();
        },
        onError: (error) => {
          console.error("Manifest update error:", error);
          toast.error("Error updating manifest. Please try again.");
        },
      });
    } catch (error) {
      console.error("handleChangeInput error:", error);
      toast.error("Error processing manifest update");
    }

    if (accessor === "facility_fee") {
      if (typeof value === "string") value = amountStrToInt(value);
      updateEventMutation.mutate(
        Object.assign(EVENT_REQUIRED_PARAMS, { [accessor]: value })
      );
    }
  };

  const handleUpdateAgesTable = (accessor, value, otherParams, callback) => {
    console.log(
      `handleUpdateAgesTable called with accessor: ${accessor}, value:`,
      value
    );

    // Store current event data for potential rollback
    const currentEventData = queryClient.getQueryData(["event-detail", id]);

    if (
      accessor === "artist_soft_merch_percentage" ||
      accessor === "artist_hard_merch_percentage"
    ) {
      updateEventAccountingMutation.mutate(
        Object.assign(EVENT_ACCOUNTING_REQUIRED_PARAMS, {
          [accessor]: parseInt(value),
        }),
        {
          onSuccess: () => {
            console.log(
              `Successfully updated ${accessor} to ${parseInt(value)}`
            );
            // Add delay to allow refetch to complete before callback
            setTimeout(() => {
              callback?.();
            }, 500);
          },
          onError: (error) => {
            console.error(`Error updating ${accessor}:`, error);
            // Revert optimistic update on error
            queryClient.setQueryData(["event-detail", id], currentEventData);
          },
        }
      );
    } else if (accessor === "who_sells_merch") {
      updateEventAccountingMutation.mutate(
        Object.assign(EVENT_ACCOUNTING_REQUIRED_PARAMS, {
          [accessor]: value,
        }),
        {
          onSuccess: () => {
            console.log(`Successfully updated ${accessor} to ${value}`);
            // Force refetch to ensure UI is updated
            queryClient.refetchQueries(["event-detail", id], { force: true });
          },
          onError: (error) => {
            console.error(`Error updating ${accessor}:`, error);
            // Revert optimistic update on error
            queryClient.setQueryData(["event-detail", id], currentEventData);
          },
        }
      );
    } else if (accessor === "age_requirement") {
      updateEventMutation.mutate(
        Object.assign(EVENT_REQUIRED_PARAMS, { [accessor]: value }),
        {
          onSuccess: () => {
            console.log(`Successfully updated ${accessor} to ${value}`);
            // Add delay to allow refetch to complete before callback
            setTimeout(() => {
              callback?.();
            }, 500);
          },
          onError: (error) => {
            console.error(`Error updating ${accessor}:`, error);
            // Revert optimistic update on error
            queryClient.setQueryData(["event-detail", id], currentEventData);
          },
        }
      );
    } else {
      updateEventMutation.mutate(
        Object.assign(EVENT_REQUIRED_PARAMS, { [accessor]: value }),
        {
          onSuccess: () => {
            console.log(`Successfully updated ${accessor} to ${value}`);
            // Force refetch to ensure UI is updated
            queryClient.refetchQueries(["event-detail", id], { force: true });
          },
          onError: (error) => {
            console.error(`Error updating ${accessor}:`, error);
            // Revert optimistic update on error
            queryClient.setQueryData(["event-detail", id], currentEventData);
          },
        }
      );
    }
  };

  const handleUpdateEventOffer = (accessor, value, otherParams, callback) => {
    console.log(
      `handleUpdateEventOffer called with accessor: ${accessor}, value:`,
      value
    );

    // Store current event data for potential rollback
    const currentEventData = queryClient.getQueryData(["event-detail", id]);

    if (accessor === "offer_percentage") {
      const numValue = parseInt(value);
      if (!isNaN(numValue)) {
        const clampedValue = Math.min(100, Math.max(0, numValue));
        updateEventOfferMutation.mutate(
          Object.assign(EVENT_OFFER_REQUIRED_PARAMS, {
            [accessor]: clampedValue,
          }),
          {
            onSuccess: () => {
              console.log(
                `Successfully updated ${accessor} to ${clampedValue}`
              );
              // Add delay to allow refetch to complete before callback
              setTimeout(() => {
                callback?.();
              }, 500);
            },
            onError: (error) => {
              console.error(`Error updating ${accessor}:`, error);
              // Revert optimistic update on error
              queryClient.setQueryData(["event-detail", id], currentEventData);
            },
          }
        );
        return;
      }
    }

    const EVENT_REQUIRED_PARAMS = {
      name: event.data.name,
      date: event.data.date,
      artists: event.data.artists ? [event.data.artists[0].id] : [0],
      state: event.data.state,
      age_requirement: event.data.age_requirement,
      abbr: event.data.abbr,
      description: event.data.description,
      door_time: event.data.door_time,
      opener_time: event.data.opener_time,
      support_time: event.data.support_time,
      headliner_time: event.data.headliner_time,
      curfew_time: event.data.curfew_time,
      company: event.data.company || "mammoth",
      offer_notes: event.data.offer_notes,
      deal_notes: event.data.deal_notes,
      confirmation_notes: event.data.confirmation_notes,
      venue_id: event.data.venue.id ?? 0,
      has_insurance: event.data.has_insurance,
      has_credit_card_fees: event.data.has_credit_card_fees,
      has_ticket_commission: event.data.has_ticket_commission,
      has_ascap: event.data.has_ascap,
      has_bmi: event.data.has_bmi,
      has_sesac: event.data.has_sesac,
      offer_has_rent: event.data.offer_has_rent,
      offer_has_comp_tax: event.data.offer_has_comp_tax,
      offer_has_gmr: event.data.offer_has_gmr ?? false,
      offer_gmr: event.data.offer_gmr,
      facility_fee: event.data.facility_fee,
      offer_support_1_label: event.data.offer_support_1_label,
      offer_support_2_label: event.data.offer_support_2_label,
      buyers: event.data.buyers,
    };

    if (accessor === "artists" || accessor === "buyers") {
      if (value?.length > 0) {
        updateEventMutation.mutate(
          Object.assign(EVENT_REQUIRED_PARAMS, {
            [accessor]: value.map((item) => item.id),
          }),
          {
            onSuccess: () => {
              console.log(`Successfully updated ${accessor}`);
              // Add delay to allow refetch to complete before callback
              setTimeout(() => {
                callback?.();
              }, 500);
            },
            onError: (error) => {
              console.error(`Error updating ${accessor}:`, error);
              // Revert optimistic update on error
              queryClient.setQueryData(["event-detail", id], currentEventData);
            },
          }
        );
      } else if (value.length === 0) {
        setTimeout(() => {
          queryClient.invalidateQueries(["event-detail", event.data.id]);
          queryClient.refetchQueries(["event-detail", event.data.id], {
            force: true,
          });
        }, 500);
      }
    } else if (
      accessor === "offer_support_1_label" ||
      accessor === "offer_support_2_label"
    ) {
      // Use updateEventMutation for support label updates
      updateEventMutation.mutate(
        Object.assign(EVENT_REQUIRED_PARAMS, { [accessor]: value }),
        {
          onSuccess: () => {
            console.log(`Successfully updated ${accessor} to ${value}`);
            // Add delay to allow refetch to complete before callback
            setTimeout(() => {
              callback?.();
            }, 500);
          },
          onError: (error) => {
            console.error(`Error updating ${accessor}:`, error);
            // Revert optimistic update on error
            queryClient.setQueryData(["event-detail", id], currentEventData);
          },
        }
      );
    } else if (
      accessor === "offer_support_1" ||
      accessor === "offer_support_2"
    ) {
      // Use updateEventMutation for support fields
      updateEventMutation.mutate(
        Object.assign(EVENT_REQUIRED_PARAMS, { [accessor]: value }),
        {
          onSuccess: () => {
            console.log(`Successfully updated ${accessor} to ${value}`);
            // Add delay to allow refetch to complete before callback
            setTimeout(() => {
              callback?.();
            }, 500);
          },
          onError: (error) => {
            console.error(`Error updating ${accessor}:`, error);
            // Revert optimistic update on error
            queryClient.setQueryData(["event-detail", id], currentEventData);
          },
        }
      );
    } else {
      updateEventOfferMutation.mutate(
        Object.assign(EVENT_OFFER_REQUIRED_PARAMS, { [accessor]: value }),
        {
          onSuccess: () => {
            console.log(`Successfully updated ${accessor} to:`, value);
            // Add delay to allow refetch to complete before callback
            setTimeout(() => {
              callback?.();
            }, 500);
          },
          onError: (error) => {
            console.error(`Error updating ${accessor}:`, error);
            // Revert optimistic update on error
            queryClient.setQueryData(["event-detail", id], currentEventData);
          },
        }
      );
    }
  };

  const handleUpdateVariablesTable = (
    accessor,
    value,
    otherParams,
    callback
  ) => {
    console.log(
      `handleUpdateVariablesTable called with accessor: ${accessor}, value:`,
      value
    );

    // Store current event data for potential rollback
    const currentEventData = queryClient.getQueryData(["event-detail", id]);

    if (accessor === "offer_comp_tax") {
      updateEventMutation.mutate(
        Object.assign(EVENT_REQUIRED_PARAMS, {
          offer_comp_tax: amountStrToInt(value),
        }),
        {
          onSuccess: () => {
            console.log(
              `Successfully updated ${accessor} to ${amountStrToInt(value)}`
            );
            // Add delay to allow refetch to complete before callback
            setTimeout(() => {
              callback?.();
            }, 500);
          },
          onError: (error) => {
            console.error(`Error updating ${accessor}:`, error);
            // Revert optimistic update on error
            queryClient.setQueryData(["event-detail", id], currentEventData);
          },
        }
      );
    } else if (accessor === "offer_gmr") {
      updateEventMutation.mutate(
        Object.assign(EVENT_REQUIRED_PARAMS, {
          offer_gmr: amountStrToInt(value),
        }),
        {
          onSuccess: () => {
            console.log(
              `Successfully updated ${accessor} to ${amountStrToInt(value)}`
            );
            // Add delay to allow refetch to complete before callback
            setTimeout(() => {
              callback?.();
            }, 500);
          },
          onError: (error) => {
            console.error(`Error updating ${accessor}:`, error);
            // Revert optimistic update on error
            queryClient.setQueryData(["event-detail", id], currentEventData);
          },
        }
      );
    } else if (
      accessor === "credit_card_fees" ||
      accessor === "ticket_commission"
    ) {
      updateEventAccountingMutation.mutate(
        Object.assign(EVENT_ACCOUNTING_REQUIRED_PARAMS, {
          [accessor]: amountStrToInt(value),
        }),
        {
          onSuccess: () => {
            console.log(
              `Successfully updated ${accessor} to ${amountStrToInt(value)}`
            );
            // Force refetch to ensure UI is updated
            queryClient.refetchQueries(["event-detail", id], { force: true });
            queryClient.refetchQueries(["variables-offer-pdf", id], {
              force: true,
            });
          },
          onError: (error) => {
            console.error(`Error updating ${accessor}:`, error);
            // Revert optimistic update on error
            queryClient.setQueryData(["event-detail", id], currentEventData);
          },
        }
      );
    }
  };

  const handleVariablesToggle = (toggleData) => {
    if (toggleData.accesor === "offer_comp_tax") {
      updateEventMutation.mutate(
        Object.assign(EVENT_REQUIRED_PARAMS, {
          offer_has_comp_tax: toggleData.state,
        })
      );
    } else if (toggleData.accesor === "offer_gmr") {
      updateEventMutation.mutate(
        Object.assign(EVENT_REQUIRED_PARAMS, {
          offer_has_gmr: toggleData.state,
        })
      );
    } else if (toggleData.accesor === "rent") {
      updateEventMutation.mutate(
        Object.assign(EVENT_REQUIRED_PARAMS, {
          offer_has_rent: toggleData.state,
        })
      );
    } else if (toggleData.accesor === "ascap") {
      updateEventMutation.mutate(
        Object.assign(EVENT_REQUIRED_PARAMS, { has_ascap: toggleData.state })
      );
    } else if (toggleData.accesor === "bmi") {
      updateEventMutation.mutate(
        Object.assign(EVENT_REQUIRED_PARAMS, { has_bmi: toggleData.state })
      );
    } else if (toggleData.accesor === "sesac") {
      updateEventMutation.mutate(
        Object.assign(EVENT_REQUIRED_PARAMS, { has_sesac: toggleData.state })
      );
    } else if (toggleData.accesor === "insurance_amount") {
      updateEventMutation.mutate(
        Object.assign(EVENT_REQUIRED_PARAMS, {
          has_insurance: toggleData.state,
        })
      );
    } else if (toggleData.accesor === "credit_card_fees") {
      updateEventMutation.mutate(
        Object.assign(EVENT_REQUIRED_PARAMS, {
          has_credit_card_fees: toggleData.state,
        })
      );
    } else if (toggleData.accesor === "ticket_commission") {
      updateEventMutation.mutate(
        Object.assign(EVENT_REQUIRED_PARAMS, {
          has_ticket_commission: toggleData.state,
        })
      );
    }
  };

  const handleUpdateVenueDetails = (accessor, value) => {
    if (accessor === "venue_name") {
      const selectedVenue = venues.data.find((venue) => venue.name === value);
      if (selectedVenue) {
        updateEventMutation.mutate({
          ...EVENT_REQUIRED_PARAMS,
          venue_id: selectedVenue.id,
        });
      }
    }
  };

  const tempFormatFix = (value) => {
    if (typeof value === "string" && value === "0.00") return 0;
    else if (typeof value === "string") return parseInt(value);
    else return value;
  };

  const getDealText = () => {
    if (event.data.offer_type === "vs") {
      return `VERSUS ${event.data.offer_percentage}% of net receipts after approved expenses`;
    } else if (event.data.offer_type === "promoter-profit") {
      return `PLUS ${event.data.offer_percentage}% After split point.`;
    } else if (event.data.offer_type === "flat") {
      return `FLAT`;
    } else if (event.data.offer_type === "nbor") {
      return `${event.data.offer_percentage}% of NBOR`;
    }
  };

  const handleCreatePDF = () => {
    const data = {
      name: "Offer",
      file_type: "offer",
      note: "",
    };
    createPDFMutation.mutate(data);
  };

  const handleViewPDF = () => {
    const lastGeneratedPDF = pdfs.data?.sort(
      (a, b) => new Date(b.created) - new Date(a.created)
    )[0];
    if (lastGeneratedPDF?.file_url) {
      window.open(lastGeneratedPDF.file_url, "_blank", "noopener,noreferrer");
    } else {
      toast.error("PDF not found or still generating");
    }
  };

  const getPDFButtonText = () => {
    if (isRefetchingPDF) {
      return (
        <>
          Generating... &nbsp;&nbsp;
          <div className="spinner"></div>
        </>
      );
    } else if (pdfs.data?.length > 0) return "Regenerate PDF";
    else return "Create Offer PDF";
  };

  const calculateTotal = () => {
    let total = variables.data.variables_total;
    // if (event.data.offer_has_rent)
    //   total += tempFormatFix(event.data.tickets_total * 100);
    return total;
  };

  return (
    <div className="fixed inset-0 text-cave-black bg-cave-white min-h-screen w-full overflow-auto flex flex-col">
      <div>
        <BreadCrumbs
          links={[
            { text: "Events", url: `/events/` },
            {
              text: `${event.data.name} ${getEventDate}`,
              url: `/events/${event.data.id}`,
            },
            { text: "Edit: Offer" },
          ]}
        />
        <WalkoutPot
          walkout_potential={variables.data.walkout_potential}
          promoter_profit={variables.data.promoter_profit}
          the_deal_amount={variables.data.the_deal_amount}
          offer_type={variables.data.offer_type}
          dealHeader={dealHeader}
          split_point={variables.data.split_point}
          showHeader={showHeader}
          toggleHeader={toggleHeader}
          manifests={event.data.manifests}
          eventId={id}
          offerId={latestOffer?.id}
        />
      </div>
      <h3 className="text-2xl font-bold mb-4 text-black">
        Edit Offer - {event.data.name}
      </h3>
      <div className="py-6 min-w-[1500px] flex-grow">
        <div className="flex flex-row gap-4">
          <div className="w-1/3 flex flex-col gap-4">
            <div className="flex flex-col items-center justify-start gap-1">
              <img
                src={"/static/images/mammoth-logo.png"}
                width={250}
                height={200}
              />
              <p className="font-montserrat text-sm text-black">
                1520 HASKELL AVE LAWRENCE, KS 66044
              </p>
              <p className="font-montserrat text-sm text-black mb-4">
                785.749.7475 PH | 785.865.4110 FX
              </p>
              <div className="flex flex-col gap-2 items-center">
                <div className="flex gap-2">
                  <Button onClick={handleCreatePDF} aria-label="Generate PDF">
                    {getPDFButtonText()}
                  </Button>
                </div>
                {pdfs.data?.length > 0 && (
                  <Button
                    disabled={isRefetchingPDF}
                    variant="secondary"
                    onClick={handleViewPDF}
                    aria-label="View Generated PDF"
                  >
                    View PDF
                  </Button>
                )}
                <div>
                  {event.isSuccess && <StateSwitcher eventId={event.data.id} />}
                </div>
                <div>
                  {event.isSuccess && <DateChanger eventId={event.data.id} />}
                </div>
              </div>
            </div>
          </div>
          <div className="w-2/3 flex flex-col">
            <div className="flex flex-row">
              <div className="w-1/2">
                <DataGrid
                  className="border border-gray-300 border-dashed"
                  onBlur={(val, field) => {
                    handleUpdateEventOffer(field, val);
                  }}
                  cols={[
                    {
                      header: "AGENT",
                      value:
                        event.data?.offer_agent ||
                        event.data?.artists?.[0]?.agent ||
                        "",
                      field: "offer_agent",
                      isChanged: updatedProperties.includes("offer_agent"),
                    },
                    {
                      header: user.data?.id === 83 ? "BOOKER" : "BUYERS",
                      value: event.data.buyers.map((buyer) => ({
                        value: buyer.person_id,
                        label: `${buyer.first_name} ${buyer.last_name}`,
                      })),
                      isMulti: true,
                      type: "select",
                      options: BUYER_OPTIONS,
                      field: "buyers",
                      onValueChange: (val) => {
                        handleUpdateEventOffer("buyers", val);
                      },
                    },
                    {
                      header: "HEADLINER",
                      value: handleMapOptions(event.data.artists),
                      isMulti: true,
                      type: "select",
                      //options: ARTIST_OPTIONS,
                      field: "artists",
                      readOnly: true,
                      //onFocus: () => {
                      //  if (!artists.data) {
                      //    artists.refetch();
                      //  }
                      //},
                      onValueChange: (val) => {
                        handleUpdateEventOffer("artists", val);
                      },
                    },
                    {
                      header: event.data.offer_support_1_label || "SUPPORT",
                      value: event.data.offer_support_1,
                      field: "offer_support_1",
                      headerField: "offer_support_1_label",
                      editHeader: true,
                      onHeaderChange: (value, field) => {
                        updateEventMutation.mutate(
                          Object.assign(EVENT_REQUIRED_PARAMS, {
                            [field]: value,
                          }),
                          {
                            onSuccess: () => {
                              queryClient.invalidateQueries([
                                "event-detail",
                                id,
                              ]);
                            },
                          }
                        );
                      },
                      isChanged:
                        updatedProperties.includes("offer_support_1") &&
                        updatedProperties.includes("offer_support_1_label"),
                    },
                    {
                      header: event.data.offer_support_2_label || "SUPPORT",
                      value: event.data.offer_support_2,
                      field: "offer_support_2",
                      headerField: "offer_support_2_label",
                      editHeader: true,
                      onHeaderChange: (value, field) => {
                        updateEventMutation.mutate(
                          Object.assign(EVENT_REQUIRED_PARAMS, {
                            [field]: value,
                          }),
                          {
                            onSuccess: () => {
                              queryClient.invalidateQueries([
                                "event-detail",
                                id,
                              ]);
                            },
                          }
                        );
                      },
                      isChanged:
                        updatedProperties.includes("offer_support_2") &&
                        updatedProperties.includes("offer_support_2_label"),
                    },
                    {
                      header: "OPENER",
                      value: event.data.offer_opener,
                      field: "offer_opener",
                      isChanged: updatedProperties.includes("offer_opener"),
                    },
                    {
                      header: "BILLING",
                      value: event.data.offer_billing,
                      field: "offer_billing",
                      isChanged: updatedProperties.includes("offer_billing"),
                    },
                    {
                      header: "STIPULATIONS",
                      value: event.data.offer_stipulations,
                      field: "offer_stipulations",
                      isChanged:
                        updatedProperties.includes("offer_stipulations"),
                    },
                    {
                      header: "AVAILS",
                      value: event.data.offer_avails_1,
                      field: "offer_avails_1",
                      isChanged: updatedProperties.includes("offer_avails_1"),
                    },
                    {
                      header: "AVAILS",
                      value: event.data.offer_avails_2,
                      field: "offer_avails_2",
                      isChanged: updatedProperties.includes("offer_avails_2"),
                    },
                  ]}
                />
              </div>
              <div className="w-1/2 flex flex-col">
                <div
                  className={`bg-gray-300 text-center font-bold text-md font-montserrat w-full`}
                  colSpan={2} // Span the entire width if header.value is not provided
                >
                  VENUE INFORMATION
                </div>

                <DataGrid
                  // header={{ title: "VENUE INFORMATION" }}
                  cols={[
                    {
                      header: "NAME",
                      value: selectValueToOption(
                        event.data.venue.name,
                        VENUE_OPTIONS
                      ),
                      format: "select",
                      type: "select",
                      options: VENUE_OPTIONS,
                      accessor: "venue_name",
                      field: "venue_name",
                      customStyles: {
                        container: {
                          zIndex: 11050,
                        },
                      },
                    },
                    {
                      header: "ADDRESS",
                      value: event.data.venue.address,
                      editable: false,
                      isChanged: updatedProperties.includes("venue.address"),
                    },
                    {
                      header: "CITY",
                      value: event.data.venue.city,
                      editable: false,
                      isChanged: updatedProperties.includes("venue.city"),
                    },
                    {
                      header: "STATE",
                      value: event.data.venue.state,
                      editable: false,
                      isChanged: updatedProperties.includes("venue.state"),
                    },
                    {
                      header: "ZIPCODE",
                      value: event.data.venue.zipcode,
                      editable: false,
                      isChanged: updatedProperties.includes("venue.zipcode"),
                    },
                    {
                      header: "PHONE",
                      value: event.data.venue.phone,
                      editable: false,
                      isChanged: updatedProperties.includes("venue.phone"),
                    },
                    {
                      header: "URL",
                      value: event.data.venue.url,
                      editable: false,
                      isChanged: updatedProperties.includes("venue.url"),
                    },
                  ]}
                  onBlur={(val, field) => {
                    handleUpdateVenueDetails(field, val);
                  }}
                />
                {!venue.data.capacity && (
                  <span className="bg-red-500 text-cave-white px-2 py-1 rounded">
                    {" "}
                    no CAPACITY defined for venue{" "}
                    <a
                      target="_blank"
                      href={`/venues/edit/${venue.data.id}`}
                      className="underline"
                      rel="noreferrer"
                    >
                      edit here
                    </a>
                  </span>
                )}
                {!venue.data.sales_tax && (
                  <span className="bg-red-500 text-cave-white px-2 py-1 rounded">
                    {" "}
                    no SALES TAX defined for venue{" "}
                    <a
                      target="_blank"
                      href={`/venues/edit/${venue.data.id}`}
                      className="underline"
                      rel="noreferrer"
                    >
                      edit here
                    </a>
                  </span>
                )}
                <DataGrid
                  className="border border-gray-300 border-dashed"
                  cols={[
                    {
                      header: "AGE REQUIREMENT",
                      value: selectValueToOption(
                        event.data.age_requirement,
                        AGE_OPTIONS
                      ),
                      accessor: "age_requirement",
                      format: "select",
                      type: "select",
                      options: AGE_OPTIONS,
                      customStyles: {
                        container: {
                          zIndex: 11045,
                        },
                      },
                      isChanged: updatedProperties.includes("age_requirement"),
                    },
                    {
                      header: "SOFT MERCH",
                      value: event.data.artist_soft_merch_percentage,
                      accessor: "artist_soft_merch_percentage",
                      type: "number",
                      format: "percentage",
                      displayValue: (value) => {
                        const numValue = parseInt(value);
                        if (isNaN(numValue)) return "0 / 100";
                        const artistPercentage = Math.min(
                          100,
                          Math.max(0, numValue)
                        );
                        return `${artistPercentage} / 100`;
                      },
                      isChanged: updatedProperties.includes(
                        "artist_soft_merch_percentage"
                      ),
                    },
                    {
                      header: "HARD MERCH",
                      value: event.data.artist_hard_merch_percentage,
                      accessor: "artist_hard_merch_percentage",
                      type: "number",
                      format: "percentage",
                      displayValue: (value) => {
                        if (
                          value === null ||
                          value === undefined ||
                          value === ""
                        )
                          return "0 / 100";
                        const artistPercentage = Math.min(
                          100,
                          Math.max(0, parseInt(value) || 0)
                        );
                        return `${artistPercentage} / 100`;
                      },
                      isChanged: updatedProperties.includes(
                        "artist_hard_merch_percentage"
                      ),
                    },
                    {
                      header: "WHO SELLS",
                      value: selectValueToOption(
                        event.data.who_sells_merch,
                        WHO_SELLS_MERCH_OPTIONS
                      ),
                      accessor: "who_sells_merch",
                      field: "who_sells_merch",
                      format: "select",
                      type: "select",
                      options: WHO_SELLS_MERCH_OPTIONS,
                      customStyles: {
                        container: {
                          zIndex: 11040,
                        },
                      },
                    },
                  ]}
                  onBlur={(val, field) => {
                    handleUpdateAgesTable(field, val);
                  }}
                />
              </div>
            </div>
          </div>
        </div>
        <div className="flex flex-row mt-4 gap-4">
          <div className="w-1/3">
            <ExpenseTable
              id={id}
              event={event}
              handleUpdateExpense={handleUpdateExpense}
              displayExpenses={displayExpenses}
              advertisingExpenses={advertisingExpenses}
              totalAdvertisingExpenses={totalAdvertisingExpenses}
            />
          </div>
          <div className="w-2/3">
            <ManifestTable />
            {/* <EventManifestTable
                event={event}
                eventId={event.data.id}
                handleChangeInput={handleChangeInput}
              /> */}

            <TemplateManager
              venueId={event.data.venue.id}
              onTemplateLoad={async (manifestData) => {
                const toastId = toast.loading("Applying template...");
                try {
                  // Remove existing manifests
                  const existingManifests = event.data?.manifests?.filter(
                    (m) => m.active === true && m.is_offer === true
                  );

                  // Remove existing manifests sequentially
                  for (const manifest of existingManifests) {
                    await removeManifestMutation.mutateAsync(manifest.id);
                  }

                  // Create new manifests sequentially
                  for (const item of manifestData) {
                    await addManifestMutation.mutateAsync({
                      ...item,
                      eventId: id,
                      active: true,
                      is_offer: true,
                      kills: 0,
                    });
                  }

                  // Invalidate and refetch all related queries
                  await Promise.all([
                    queryClient.invalidateQueries(["event-detail", id]),
                    queryClient.invalidateQueries(["variables-offer-pdf", id]),
                    queryClient.invalidateQueries(["event-rollups", id]),
                    queryClient.invalidateQueries(["manifests", id]),
                  ]);

                  // Force immediate refetch
                  await Promise.all([
                    queryClient.refetchQueries(["event-detail", id], {
                      force: true,
                    }),
                    queryClient.refetchQueries(["variables-offer-pdf", id], {
                      force: true,
                    }),
                    queryClient.refetchQueries(["event-rollups", id], {
                      force: true,
                    }),
                    queryClient.refetchQueries(["manifests", id], {
                      force: true,
                    }),
                  ]);

                  toast.update(toastId, {
                    render: "Template applied successfully",
                    type: "success",
                    isLoading: false,
                    autoClose: 3000,
                  });
                } catch (error) {
                  console.error("Error applying template:", error);
                  toast.update(toastId, {
                    render: "Failed to apply template",
                    type: "error",
                    isLoading: false,
                    autoClose: 3000,
                  });

                  // Ensure queries are refetched even on error to maintain consistency
                  await Promise.all([
                    queryClient.refetchQueries(["event-detail", id], {
                      force: true,
                    }),
                    queryClient.refetchQueries(["variables-offer-pdf", id], {
                      force: true,
                    }),
                    queryClient.refetchQueries(["event-rollups", id], {
                      force: true,
                    }),
                    queryClient.refetchQueries(["manifests", id], {
                      force: true,
                    }),
                  ]);
                }
              }}
            />
            <div className="mt-4 flex flex-row gap-4">
              <div className="w-1/2">
                <EditableTable
                  rows={[
                    {
                      header: "TOTAL CAPACITY",
                      value: variables.data?.total_capacity || "0",
                      editable: false,
                      isChanged:
                        updatedProperties.includes("capacity") ||
                        updatedProperties.includes("total_capacity"),
                      msg: variables.data.venue_capacity
                        ? `[Venue Capacity: ${variables.data.venue_capacity}]`
                        : "",
                    },
                    {
                      header: "GROSS POTENTIAL",
                      value: amountDisplay(variables.data.gross_potential),
                      isChanged: updatedProperties.includes("gross_potential"),
                      editable: false,
                    },
                    {
                      header: "TICKET FEES",
                      value: amountDisplay(variables.data.ticket_fees),
                      isChanged: updatedProperties.includes("ticket_fees"),
                      editable: false,
                    },
                    {
                      header: "ADJUSTED GROSS",
                      value: amountDisplay(variables.data.adjusted_gross),
                      isChanged: updatedProperties.includes("adjusted_gross"),
                      editable: false,
                    },
                    {
                      header: "TAX PERCENTAGE",
                      value: `${event.data.venue.sales_tax}%`,
                      isChanged: updatedProperties.includes("sales_tax"),
                      highlight: true,
                    },
                    {
                      header: "TOTAL TAX",
                      value: amountDisplay(variables.data.total_tax),
                      isChanged: updatedProperties.includes("total_tax"),
                      editable: false,
                    },
                    {
                      header: "NET POTENTIAL",
                      value: amountDisplay(variables.data.net_potential),
                      isChanged: updatedProperties.includes("net_potential"),
                      editable: false,
                    },
                  ]}
                />
              </div>
              <div className="w-1/2">
                <DealTable
                  dealAmount={amountDisplay(variables.data.the_deal_amount)}
                  dealDescription={getDealText()}
                  isChanged={updatedProperties.includes("the_deal_amount")}
                />
                <div className="mt-4 dark:text-cave-white">
                  <TextAreaTable
                    header={"NOTES"}
                    value={event.data.deal_notes}
                    onInputChange={(accessor, value) =>
                      handleUpdateEvent("deal_notes", value)
                    }
                  />
                </div>
              </div>
            </div>
            <div className="mt-2 flex flex-row gap-4">
              <div className="w-1/2">
                <EditableTable
                  rows={[
                    {
                      editable: false,
                      header: "TOTAL EXPENSES",
                      value: amountDisplay(variables.data.total_expenses),
                      isChanged:
                        updatedProperties.includes("total_expenses") ||
                        updatedProperties.includes("variables_total"),
                    },
                    ...(event.data.offer_type === "promoter-profit"
                      ? [
                        {
                          editable: false,
                          header: "PROMOTER PROFIT",
                          value: amountDisplay(
                            variables.data.promoter_profit
                          ),
                          isChanged:
                            updatedProperties.includes("promoter_profit"),
                        },
                        {
                          editable: false,
                          header: "SPLIT POINT",
                          value: amountDisplay(variables.data.split_point),
                          isChanged:
                            updatedProperties.includes("split_point"),
                        },
                      ]
                      : []),
                    ...(event.data.offer_type === "vs"
                      ? [
                        {
                          editable: false,
                          header: "LESS GUARANTEE",
                          value: amountDisplay(
                            variables.data.the_deal_amount
                          ),
                          isChanged:
                            updatedProperties.includes("the_deal_amount"),
                        },
                        {
                          editable: false,
                          header: "NET EXPENSES",
                          value: amountDisplay(
                            variables.data.total_expenses -
                            variables.data.the_deal_amount
                          ),
                          isChanged:
                            updatedProperties.includes("net_expenses"),
                        },
                      ]
                      : []),
                  ]}
                  header={{ title: dealHeader }}
                  noBlue
                />
              </div>
              <div className="w-1/2 flex flex-col">
                <EditableTable
                  header={{ title: "WALK-OUT POTENTIAL" }}
                  rows={[
                    {
                      header: "EVENT OFFER TYPE",
                      value: selectValueToOption(
                        event.data.offer_type,
                        EVENT_OFFER_TYPE_OPTIONS
                      ),
                      accessor: "offer_type",
                      options: EVENT_OFFER_TYPE_OPTIONS,
                      editable: false,
                      format: "select",
                    },
                    {
                      header: "THE DEAL AMOUNT",
                      value: amountDisplay(variables.data.the_deal_amount),
                      editable: false,
                      isChanged: updatedProperties.includes("the_deal_amount"),
                    },
                    {
                      header: "WALKOUT PERCENTAGE",
                      value: variables.data.walkout_percentage,
                      editable: false,
                      isChanged:
                        updatedProperties.includes("walkout_percentage"),
                    },
                    {
                      header: "OFFER PERCENTAGE",
                      value: event.data.offer_percentage,
                      accessor: "offer_percentage",
                      format: "number",
                      isChanged: updatedProperties.includes("offer_percentage"),
                    },
                  ]}
                  noBlue
                  onInputChange={(accessor, value, otherParams, callback) => {
                    if (accessor === "offer_percentage") {
                      if (value === 0) value = 0;
                      if (value > 100) value = 100;
                      if (value === 100) value = 100;
                    }
                    return (
                      (accessor === "offer_type" ||
                        accessor === "offer_percentage") &&
                      handleUpdateEventOffer(
                        accessor,
                        value,
                        otherParams,
                        callback
                      )
                    );
                  }}
                />
                <TextAreaTable
                  header={"DEPOSITS"}
                  disabled
                  value={
                    "10%, 10 DAYS OUT. NO DEPOSITS UNDER $500 OR FOR SUPPORT."
                  }
                  noBlue
                />
              </div>
            </div>
            <div className="mt-4">
              <ContactTable
                eventID={id}
                eventData={event.data}
                EVENT_OFFER_REQUIRED_PARAMS={EVENT_OFFER_REQUIRED_PARAMS}
                EVENT_OFFER_REQUIRED_PARAMS={EVENT_OFFER_REQUIRED_PARAMS}
              />
            </div>
            <div className="mt-4 flex flex-row gap-4">
              <div className="w-1/2">
                {event.data && (
                  <DataGrid
                    key={variables.data.id}
                    className="border-2 border-gray-300 border-dashed"
                    cols={[
                      {
                        header: "ASCAP",
                        field: "ascap",
                        value: amountDisplay(variables.data.ascap),
                        editable: false,
                        editableToggle: true,
                        editableToggleValue: event.data.has_ascap,
                        onToggleChange: (value, field) =>
                          handleVariablesToggle({
                            field,
                            value,
                            accesor: field,
                            state: value,
                          }),
                      },
                      {
                        header: "BMI",
                        field: "bmi",
                        value: amountDisplay(variables.data.bmi),
                        editable: false,
                        editableToggle: true,
                        editableToggleValue: event.data.has_bmi,
                        onToggleChange: (value, field) =>
                          handleVariablesToggle({
                            field,
                            value,
                            accesor: field,
                            state: value,
                          }),
                      },
                      {
                        header: "SESAC",
                        field: "sesac",
                        value: amountDisplay(variables.data.sesac),
                        editable: false,
                        editableToggle: true,
                        editableToggleValue: event.data.has_sesac,
                        onToggleChange: (value, field) =>
                          handleVariablesToggle({
                            field,
                            value,
                            accesor: field,
                            state: value,
                          }),
                      },
                      {
                        header: "COMP TAX",
                        format: "money",
                        field: "offer_comp_tax",
                        value: amountDisplay(event.data.offer_comp_tax),
                        editable: !!event.data.offer_has_comp_tax,
                        editableToggle: true,
                        editableToggleValue: event.data.offer_has_comp_tax,
                        onToggleChange: (value, field) =>
                          handleVariablesToggle({
                            field,
                            value,
                            accesor: field,
                            state: value,
                          }),
                      },
                      {
                        header: "GMR",
                        format: "money",
                        field: "offer_gmr",
                        value: amountDisplay(variables.data.gmr),
                        editable: false,
                        editableToggle: true,
                        editableToggleValue: event.data.offer_has_gmr,
                        onToggleChange: (value, field) =>
                          handleVariablesToggle({
                            field,
                            value,
                            accesor: field,
                            state: value,
                          }),
                      },
                      {
                        header: "INSURANCE",
                        field: "insurance_amount",
                        value: amountDisplay(variables.data.insurance_amount),
                        editable: false,
                        editableToggle: true,
                        editableToggleValue: event.data.has_insurance,
                        onToggleChange: (value, field) =>
                          handleVariablesToggle({
                            field,
                            value,
                            accesor: field,
                            state: value,
                          }),
                        msg: "55¢/head x capacity",
                      },
                      {
                        header: "RENT",
                        field: "rent",
                        value: !event.data.offer_has_rent
                          ? "FLAT"
                          : amountDisplay(event.data.tickets_total * 100),
                        editable: false,
                        editableToggle: true,
                        editableToggleValue: event.data.offer_has_rent,
                        onToggleChange: (value, field) =>
                          handleVariablesToggle({
                            field,
                            value,
                            accesor: field,
                            state: value,
                          }),
                        toggle: event.data.offer_has_rent,
                        msg: "$1/per",
                      },
                      {
                        header: "CREDIT CARDS",
                        format: "money",
                        field: "credit_card_fees",
                        value: amountDisplay(variables.data.credit_card_fees),
                        editable: event.data.has_credit_card_fees !== false,
                        editableToggle: true,
                        editableToggleValue: event.data.has_credit_card_fees,
                        onToggleChange: (value, field) =>
                          handleVariablesToggle({
                            field,
                            value,
                            accesor: field,
                            state: value,
                          }),
                        msg: "3.50%",
                      },
                      {
                        header: "TICKET COMMISSION",
                        format: "money",
                        field: "ticket_commission",
                        value: amountDisplay(variables.data.ticket_commission),
                        editable: !!event.data.has_ticket_commission,
                        editableToggle: true,
                        editableToggleValue: event.data.ticket_commission,
                        onToggleChange: (value, field) => {
                          handleVariablesToggle({
                            field,
                            value,
                            accesor: field,
                            state: value,
                          });
                        },
                        msg: "3%/CAP",
                      },
                    ]}
                    header={
                      <div className="bg-gray-300 text-sm flex justify-between font-bold">
                        <div>VARIABLE.EXPENSES.TOTAL</div>

                        <div>{amountDisplay(calculateTotal())}</div>
                      </div>
                    }
                    blue={false}
                    onBlur={(value, field) => {
                      handleUpdateVariablesTable(field, value);
                    }}
                  />
                )}
                {/* <EditableTable
                    rows={[
                      {
                        header: "ASCAP",
                        accessor: "ascap",
                        value: amountDisplay(variables.data.ascap),
                        editable: false,
                        onToggle: handleVariablesToggle,
                        toggle: event.data.has_ascap,
                        isChanged: updatedProperties.includes("ascap"),
                      },
                      {
                        header: "BMI",
                        accessor: "bmi",
                        value: amountDisplay(variables.data.bmi),
                        editable: false,
                        onToggle: handleVariablesToggle,
                        toggle: event.data.has_bmi,
                        isChanged: updatedProperties.includes("bmi"),
                      },
                      {
                        header: "SESAC",
                        accessor: "sesac",
                        value: amountDisplay(variables.data.sesac),
                        editable: false,
                        onToggle: handleVariablesToggle,
                        toggle: event.data.has_sesac,
                        isChanged: updatedProperties.includes("sesac"),
                      },
                      {
                        header: "COMP TAX",
                        format: "money",
                        accessor: "offer_comp_tax",
                        value: amountDisplay(event.data.offer_comp_tax),
                        editable: !!event.data.offer_has_comp_tax,
                        onToggle: handleVariablesToggle,
                        toggle: event.data.offer_has_comp_tax,
                        isChanged: updatedProperties.includes("offer_comp_tax"),
                      },
                      {
                        header: "INSURANCE",
                        accessor: "insurance_amount",
                        value: amountDisplay(variables.data.insurance_amount),
                        editable: false,
                        onToggle: handleVariablesToggle,
                        toggle: event.data.has_insurance,
                        msg: "55¢/head x capacity",
                        isChanged:
                          updatedProperties.includes("insurance_amount"),
                      },
                      {
                        header: "RENT",
                        accessor: "rent",
                        value: !event.data.offer_has_rent
                          ? "FLAT"
                          : amountDisplay(event.data.tickets_total * 100),
                        editable: false,
                        onToggle: handleVariablesToggle,
                        toggle: event.data.offer_has_rent,
                        msg: "$1/per",
                        isChanged:
                          updatedProperties.includes("offer_has_rent") ||
                          updatedProperties.includes("rent_rate"),
                      },
                      {
                        header: "CREDIT CARDS",
                        format: "money",
                        accessor: "credit_card_fees",
                        value: amountDisplay(variables.data.credit_card_fees),
                        editable: !!event.data.has_credit_card_fees,
                        onToggle: handleVariablesToggle,
                        toggle: event.data.has_credit_card_fees,
                        msg: "3.50%",
                        isChanged:
                          updatedProperties.includes("credit_card_fees"),
                      },
                      {
                        header: "TICKET COMMISSION",
                        format: "money",
                        accessor: "ticket_commission",
                        value: amountDisplay(variables.data.ticket_commission),
                        editable: !!event.data.has_ticket_commission,
                        onToggle: handleVariablesToggle,
                        toggle: event.data.has_ticket_commission,
                        msg: "3%/CAP",
                        isChanged:
                          updatedProperties.includes("ticket_commission"),
                      },
                    ]}
                    header={{
                      title: "VARIABLE.EXPENSES.TOTAL",
                      value: amountDisplay(calculateTotal()),
                      isChanged: updatedProperties.includes("variables_total"),
                    }}
                    noBlue={false}
                    onInputChange={handleUpdateVariablesTable}
                  /> */}
              </div>
              <div className="w-1/2 flex flex-col">
                <TextAreaTable
                  header={"NOTES"}
                  value={event.data.offer_notes}
                  onInputChange={(accessor, value) =>
                    handleUpdateEvent("offer_notes", value)
                  }
                />
                <EditableTable
                  rows={[
                    {
                      header: "DOORS",
                      value: localTime(event.data.door_time),
                      accessor: "door_time",
                      format: "time",
                      isChanged: updatedProperties.includes("door_time"),
                    },
                    {
                      header: "OPENER",
                      value: localTime(event.data.opener_time),
                      accessor: "opener_time",
                      format: "time",
                      isChanged: updatedProperties.includes("opener_time"),
                    },
                    {
                      header: "HEADLINER",
                      value: localTime(event.data.headliner_time),
                      accessor: "headliner_time",
                      format: "time",
                      isChanged: updatedProperties.includes("headliner_time"),
                    },
                    {
                      header: "CURFEW",
                      value: localTime(event.data.curfew_time),
                      accessor: "curfew_time",
                      format: "time",
                      isChanged: updatedProperties.includes("curfew_time"),
                    },
                  ]}
                  header={{ title: "TIMES" }}
                  noBlue={true}
                  onInputChange={handleUpdateTime}
                />
              </div>
            </div>
          </div>
        </div>
      </div>
      {/* <ExpensesTable id={id} /> */}
    </div>
  );
};
