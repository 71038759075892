import {
  getApiRequest,
  postApiRequest,
  putApiRequest,
  deleteApiRequest,
  patchApiRequest,
  jsonHeaders,
  makeRequest,
} from "./base";

export const getExpenseGroupSettings = {
  cacheTime: 30 * 60 * 1000,
  staleTime: 15 * 60 * 1000,
};

export const getExpenseGroups = async () => {
  const url = `/api/projections/groups/`;
  console.log("Fetching expense groups from:", url);
  try {
    const result = await getApiRequest(url);
    console.log("Expense groups result:", result);
    return result;
  } catch (error) {
    console.error("Error in getExpenseGroups:", error);
    // Check if it's an authentication error
    if (error.response && error.response.status === 401) {
      throw new Error("Authentication required");
    }
    // Return empty array instead of throwing to prevent UI errors
    return [];
  }
};

export const getOfferExpenseGroups = async () => {
  const url = `/api/accounting/expense-groups/`;
  return await getApiRequest(url);
};

export const getExpenseSubGroupSettings = {
  cacheTime: 30 * 60 * 1000,
  staleTime: 15 * 60 * 1000,
};

export const getExpenseSubGroups = async () => {
  const url = `/api/accounting/expense-sub-groups/`;
  return await getApiRequest(url);
};

export const getExpenses = async (id) => {
  const url = `/api/accounting/event-expenses/${id}/`;
  return await getApiRequest(url);
};

export const getEventExpensesSettings = {
  cacheTime: 5 * 60 * 1000,
  staleTime: 2 * 60 * 1000,
};

export const getEventExpenses = async (id, books, getMethod = "") => {
  let url = `/api/accounting/event-expenses/${id}/`;
  let append = `${id}/`;
  if (getMethod === "shared") {
    append = append.concat("shared/");
  } else if (getMethod === "unique") {
    append = append.concat("unique/");
  }
  switch (books) {
    case "offer":
      url = `/api/accounting/event-expenses/offer/${id}/`;
      break;
    case "actual":
      url = `/api/accounting/event-expenses/actual/${id}/`;
      break;
    case "artist":
      url = `/api/accounting/event-expenses/artist/${append}`;
      break;
    case "copro":
      url = `/api/accounting/event-expenses/copro/${append}`;
      break;
    case "final":
      url = `/api/accounting/event-expenses/final/${append}`;
      break;
  }

  return await getApiRequest(url);
};

export const getArtistEventExpenses = async (id) => {
  const url = `/api/accounting/event-expenses/artist/${id}/`;
  return await getApiRequest(url);
};

export const getCoProEventExpenses = async (id) => {
  const url = `/api/accounting/event-expenses/copro/${id}/`;
  return await getApiRequest(url);
};

export const getFinalEventExpenses = async (id) => {
  const url = `/api/accounting/event-expenses/final/${id}/`;
  return await getApiRequest(url);
};

export const getEventExpenseFiles = async (id) => {
  const url = `/api/accounting/event-expenses/files/${id}/`;
  return await getApiRequest(url);
};

export const createExpenseFile = async (id, data) => {
  const url = `/api/accounting/event-expenses/files/${id}/`;
  const headers = await jsonHeaders(true);
  headers.delete("Content-Type");
  const resp = await makeRequest(url, "POST", headers, data);
  return resp.json();
};

export const createEventExpense = async (eventId, data) => {
  const url = `/api/accounting/event-expense-create/${eventId}/`;
  // Ensure expense data has the required fields
  return await postApiRequest(url, data);
};

export const createEventExpenseOffer = async (eventId, data) => {
  const url = `/api/accounting/event-expense-create-offer/${eventId}/`;
  return await postApiRequest(url, data);
};
export const updateEventExpenseOffer = async (data) => {
  const url = `/api/accounting/event-expense-update-offer/`;
  return await putApiRequest(url, data);
};

export const updateEventExpense = async (data) => {
  const url = `/api/accounting/event-expense-update/`;
  return await putApiRequest(url, data);
};

export const deleteExpense = async (expenseId) => {
  const url = `/api/accounting/event-expense/delete/${expenseId}/`;
  return await postApiRequest(url);
};

export const markExpensePaid = async (expenseId, checkNumber) => {
  const url = `/api/accounting/event-expense/mark-paid/`;
  return await postApiRequest(url, {
    expense_id: expenseId,
    check_number: checkNumber,
  });
};

export const markExpenseUnpaid = async (expenseId, checkNumber) => {
  const url = `/api/accounting/event-expense/mark-unpaid/`;
  return await postApiRequest(url, {
    expense_id: expenseId,
    check_number: checkNumber,
  });
};

export const getArtistCuts = async (id) => {
  const url = `/api/accounting/event-artist-cuts/${id}/`;
  return await getApiRequest(url);
};

export const updateArtistCuts = async (id, data) => {
  const url = `/api/accounting/event-artist-cuts/${id}/`;
  return await postApiRequest(url, data);
};

export const getEventRollups = async (id, books) => {
  let url = `/api/accounting/event-rollups/${id}/`;

  switch (books) {
    case "offer":
      url = `/api/accounting/event-rollups/offer/${id}/`;
      break;
    case "actual":
      url = `/api/accounting/event-rollups/actual/${id}/`;
      break;
    case "artist":
      url = `/api/accounting/event-rollups/artist/${id}/`;
      break;
    case "copro":
      url = `/api/accounting/event-rollups/copro/${id}/`;
      break;
    case "final":
      url = `/api/accounting/event-rollups/final/${id}/`;
      break;
  }

  return await getApiRequest(url);
};

export const getArtistEventRollups = async (id) => {
  const url = `/api/accounting/event-rollups/artist/${id}/`;
  return await getApiRequest(url);
};

export const getCoProEventRollups = async (id) => {
  const url = `/api/accounting/event-rollups/copro/${id}/`;
  return await getApiRequest(url);
};

export const getFinalEventRollups = async (id) => {
  const url = `/api/accounting/event-rollups/final/${id}/`;
  return await getApiRequest(url);
};

export const getEventCopros = async (id) => {
  const url = `/api/accounting/event-copros/${id}/`;
  return await getApiRequest(url);
};

export const createEventCopros = async (id, data) => {
  const url = `/api/accounting/event-copros/${id}/`;
  return await postApiRequest(url, data);
};

export const deactivateEventCopro = async (id) => {
  const url = `/api/accounting/event-copros/deactivate/${id}/`;
  return await postApiRequest(url);
};

export const getEventPDFExpenses = async (id) => {
  let url = `/api/accounting/event-expenses/pdf/offer/${id}/`;
  return await getApiRequest(url);
};

// Accounting Bulletin Board Messages
export const getAccountingMessages = async () => {
  const url = `/api/acc_bull/messages/`;
  return await getApiRequest(url);
};

export const createAccountingMessage = async (payload) => {
  const url = `/api/acc_bull/messages/`;
  return await postApiRequest(url, payload);
};

export const dismissAccountingMessage = async (messageId) => {
  const url = `/api/acc_bull/messages/${messageId}/dismiss/`;
  return await postApiRequest(url);
};

export const updateAccountingMessage = async (messageId, payload) => {
  const url = `/api/acc_bull/messages/${messageId}/`;
  return await putApiRequest(url, payload);
};

export const deleteAccountingMessage = async (messageId) => {
  const url = `/api/acc_bull/messages/${messageId}/`;
  return await deleteApiRequest(url);
};

export const getOfferProjections = async (offerId) => {
  if (!offerId || isNaN(parseInt(offerId))) {
    console.error("Invalid offer ID:", offerId);
    return [];
  }

  const url = `/api/projections/?offer_id=${offerId}`;
  console.log("Fetching offer projections from:", url);
  try {
    // Add cache busting parameter to force fresh data from the server
    const timestamp = new Date().getTime();
    const result = await getApiRequest(`${url}&_=${timestamp}`, {
      headers: {
        "Cache-Control": "no-cache, no-store, must-revalidate",
        Pragma: "no-cache",
      },
    });
    console.log("Offer projections result:", result);

    // Ensure projected_expenses is always an array
    if (result && result.length > 0) {
      result.forEach((projection) => {
        if (!projection.projected_expenses) {
          projection.projected_expenses = [];
        }
      });
    }

    return result;
  } catch (error) {
    console.error("Error fetching offer projections:", error);
    return [];
  }
};

export const getOfferProjectionsSettings = {
  cacheTime: 1 * 60 * 1000, // Cache for just 1 minute
  staleTime: 0, // Always consider stale
  refetchOnWindowFocus: true, // Refetch on window focus
  refetchOnMount: true, // Fetch when component mounts
  refetchInterval: false, // Disable automatic refetching
  retry: 3, // Retry failed requests 3 times
  retryDelay: 1000, // Wait 1 second between retries
};
