import React from "react";

export const LoadingIndicator = () => {
  return (
    <div className="flex items-center justify-center h-96 bg-cave-black">
      <div className="relative w-24 h-24">
        <div className="absolute inset-0 border-8 border-[#FFFBE6] rounded-full animate-[ping_4s_ease-in-out_infinite]"></div>
        <div className="absolute inset-0 border-8 border-[#3F3F3F] rounded-full animate-[pulse_4s_ease-in-out_infinite]"></div>
        <div className="absolute inset-0 flex items-center justify-center">
          <img
            src="/static/images/company-logo-light-bg.svg"
            className="h-12 w-auto dark:hidden animate-[pulse_4s_ease-in-out_infinite]"
            alt="Loading"
          />
          <img
            src="/static/images/company-logo-black-bg.svg"
            className="hidden h-12 w-auto dark:block animate-[pulse_4s_ease-in-out_infinite]"
            alt="Loading"
          />
        </div>
      </div>
    </div>
  );
};
