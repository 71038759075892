import React, { useEffect, useState } from "react";
import { Header } from "@/components/Header";
import * as yup from "yup";
import { useNavigate, useParams } from "react-router-dom";
import { useQuery, useMutation, useQueryClient } from "@tanstack/react-query";
import {
  FormBuilder,
  Input,
  TextInput,
  FormMultiSelect,
  selectOptionToValue,
} from "@/components/Form";

import { getLink, updateLink } from "@/queries/links";
import { getOnSaleEvents } from "@/queries/events";
import { getVenues, getVenuesSettings } from "@/queries/venues";
import { getArtists, getArtistsSettings } from "@/queries/artists";
import { BreadCrumbs } from "@/components/BreadCrumbs";
import Button from "@/components/Button";
import LoadingPulse from "@/components/shared/LoadingPulse";

export const LinkEdit = () => {
  const { id } = useParams();
  const navigate = useNavigate();
  const queryClient = useQueryClient();

  const { data: link, isLoading: linkLoading } = useQuery(
    ["link-detail", id],
    () => getLink(id)
  );

  const events = useQuery(
    ["events-onsale-for-link-edit", id],
    () => getOnSaleEvents(),
    {
      staleTime: 5 * 60 * 1000, // 5 minutes
      retry: 3,
      retryDelay: 1000,
    }
  );
  const venues = useQuery(["venues"], () => getVenues(), getVenuesSettings);
  const artists = useQuery(["artists"], getArtists, getArtistsSettings);

  const isLoading =
    linkLoading || events.isLoading || venues.isLoading || artists.isLoading;

  const mutation = useMutation({
    mutationFn: (data) => {
      console.log("---> updating link");
      console.dir(data);

      return updateLink(id, data);
    },
    onSuccess: async (data) => {
      queryClient.invalidateQueries(["links"]);
      queryClient.invalidateQueries(["link-detail", id]);
      navigate(`/links/${id}`);
    },
  });

  const updateSchema = yup.object().shape({
    name: yup.string().required(),
    description: yup.string(),
  });

  const handleSubmit = (data) => {
    // Extract just the IDs from the selected items
    const payload = {
      name: data.name,
      description: data.description || "",
      venues: data.venues
        ? data.venues.map((venue) => Number(venue.value))
        : [],
      events: data.events
        ? data.events.map((event) => Number(event.value))
        : [],
      artists: data.artists
        ? data.artists.map((artist) => Number(artist.value))
        : [],
    };

    console.log("Submitting data:", data);
    mutation.mutate(payload);
  };

  // Prepare the options for the form
  const eventOptions =
    events.data?.map((event) => {
      return { value: event.id, label: event.name };
    }) || [];

  const venueOptions =
    venues.data?.map((venue) => {
      return { value: venue.id, label: venue.name };
    }) || [];

  const artistOptions =
    artists.data?.map((artist) => {
      return { value: artist.id, label: artist.name };
    }) || [];

  // Format the current selections for the form
  const selectedEvents =
    link?.events
      ?.map((eventId) => {
        // First try to find in the loaded events
        const foundEvent = events.data?.find((e) => e.id === eventId);
        if (foundEvent) {
          return { value: foundEvent.id, label: foundEvent.name };
        }
        // If not found, create a more descriptive placeholder
        return {
          value: eventId,
          label: `Loading Event Details...`,
          isDisabled: false,
        };
      })
      .filter(Boolean) || [];

  const selectedVenues =
    link?.venues
      ?.map((venueId) => {
        // First try to find in the loaded venues
        const foundVenue = venues.data?.find((v) => v.id === venueId);
        if (foundVenue) {
          return { value: foundVenue.id, label: foundVenue.name };
        }
        // If not found, create a more descriptive placeholder
        return {
          value: venueId,
          label: `Loading Venue Details...`,
          isDisabled: false,
        };
      })
      .filter(Boolean) || [];

  const selectedArtists =
    link?.artists
      ?.map((artistId) => {
        // First try to find in the loaded artists
        const foundArtist = artists.data?.find((a) => a.id === artistId);
        if (foundArtist) {
          return { value: foundArtist.id, label: foundArtist.name };
        }
        // If not found, create a more descriptive placeholder
        return {
          value: artistId,
          label: `Loading Artist Details...`,
          isDisabled: false,
        };
      })
      .filter(Boolean) || [];

  // Default values for the form
  const defaultValues = link
    ? {
        name: link.name,
        description: link.description || "",
        events: selectedEvents,
        venues: selectedVenues,
        artists: selectedArtists,
      }
    : null;

  // Add debugging to help identify issues
  useEffect(() => {
    if (link && events.data && venues.data && artists.data) {
      console.log("Link loaded:", link);
      console.log("Selected events:", selectedEvents);
      console.log("Selected venues:", selectedVenues);
      console.log("Selected artists:", selectedArtists);
    }
  }, [
    link,
    events.data,
    venues.data,
    artists.data,
    selectedEvents,
    selectedVenues,
    selectedArtists,
  ]);

  return (
    <>
      <LoadingPulse isLoading={isLoading} />
      <Header />
      <div
        className="
        flex flex-col px-6 py-8 mx-auto min-h-screen lg:py-0
    "
      >
        <BreadCrumbs
          links={[
            { text: "Links", url: "/links" },
            { text: link?.name || "Loading...", url: `/links/${id}` },
            { text: "Edit" },
          ]}
        />

        <h3 className="text-xl font-bold mb-4 dark:text-cave-white">
          Edit TicketCount Link {link?.name ? `- ${link.name}` : ""}
        </h3>
        <div className="min-w-full">
          {!isLoading && defaultValues && (
            <FormBuilder
              onSubmit={(data) => handleSubmit(data)}
              schema={updateSchema}
              defaultValues={defaultValues}
            >
              <Input name="name" label="Name" autoFocus={true} />
              <TextInput name="description" label="Description" />
              <FormMultiSelect
                name="events"
                label="Event"
                options={eventOptions}
              />
              <FormMultiSelect
                name="venues"
                label="Venue"
                options={venueOptions}
              />
              <FormMultiSelect
                name="artists"
                label="Artist"
                options={artistOptions}
              />
              <div className="flex-inline float-right mb-12">
                <button
                  type="button"
                  onClick={() => navigate(`/links/${id}`)}
                  className="ml-4 mr-4 rounded-md bg-gray-400 px-3 py-2 text-sm font-semibold text-gray-600 shadow-sm hover:bg-gray-300 focus-visible:outline focus-visible:outline-2 focus-visible:outline-offset-2 focus-visible:outline-blue-600"
                >
                  Cancel
                </button>
                <Button type="submit">Update Link</Button>
              </div>
            </FormBuilder>
          )}
        </div>
      </div>
    </>
  );
};
