import React, { useState } from "react";
import { useQuery } from "@tanstack/react-query";
import { searchSpotifyArtist } from "@/queries/artists";

const SpotifyPlayer = ({ artistName, compact = false }) => {
  const [isPlaying, setIsPlaying] = useState(false);
  const [currentTrack, setCurrentTrack] = useState(null);

  const {
    data: artistData,
    isLoading,
    error,
  } = useQuery(
    ["spotify-artist", artistName],
    () => searchSpotifyArtist(artistName),
    {
      enabled: !!artistName,
      onSuccess: (data) => {
        // Set the first track as current if available
        if (data.found && data.top_tracks && data.top_tracks.length > 0) {
          setCurrentTrack(data.top_tracks[0]);
        }
      },
      staleTime: 1000 * 60 * 60, // 1 hour
      cacheTime: 1000 * 60 * 60 * 24, // 24 hours
    }
  );

  const togglePlay = () => {
    // Get the iframe element
    const iframe = document.getElementById(
      compact ? "spotify-iframe-compact" : "spotify-iframe-full"
    );

    // If we're currently playing and there's an iframe, try to pause it
    if (isPlaying && iframe) {
      try {
        // Try to send a pause message to the iframe
        iframe.contentWindow.postMessage(
          JSON.stringify({ action: "pause" }),
          "*"
        );
      } catch (e) {
        console.error("Error pausing Spotify player:", e);
      }
    }

    setIsPlaying(!isPlaying);

    // If we're switching to play mode, we might need to reload the iframe
    if (!isPlaying && iframe) {
      // Force reload the iframe with autoplay parameter
      const currentSrc = iframe.src;
      iframe.src = currentSrc.includes("autoplay=1")
        ? currentSrc
        : currentSrc.includes("?")
        ? `${currentSrc}&autoplay=1`
        : `${currentSrc}?autoplay=1`;
    }
  };

  const selectTrack = (track) => {
    setCurrentTrack(track);
    setIsPlaying(true);
  };

  if (isLoading) {
    return compact ? (
      <div className="spotify-player-container animate-pulse bg-gray-800 rounded-lg p-2 flex items-center justify-center h-10 w-48">
        <p className="text-white text-xs">Loading...</p>
      </div>
    ) : (
      <div className="spotify-player-container animate-pulse bg-gray-800 rounded-lg p-4 flex items-center justify-center h-16">
        <p className="text-white">Loading Spotify player...</p>
      </div>
    );
  }

  if (error) {
    return compact ? (
      <div className="spotify-player-container bg-gray-800 rounded-lg p-2 flex items-center justify-center h-10 w-48">
        <p className="text-white text-xs">Spotify unavailable</p>
      </div>
    ) : (
      <div className="spotify-player-container bg-gray-800 rounded-lg p-4 flex items-center justify-center h-16">
        <p className="text-white text-sm">
          {error.message || "Failed to load Spotify player"}
        </p>
      </div>
    );
  }

  if (!artistData || !artistData.found) {
    return null;
  }

  const { artist, top_tracks } = artistData;

  // Compact version of the player for the header
  if (compact) {
    return (
      <div className="spotify-player-container bg-black rounded-lg p-2 flex flex-col">
        <div className="flex items-center mb-2">
          <img
            src={
              artist.images[0]?.url || "https://open.spotify.com/favicon.ico"
            }
            alt={artist.name}
            className="h-6 w-6 mr-2 rounded-full"
            onError={(e) => {
              e.target.onerror = null;
              e.target.src = "https://open.spotify.com/favicon.ico";
            }}
          />
          <div className="flex-1 mr-2">
            <span className="text-white text-xs block truncate">
              {artist.name}
            </span>
            {currentTrack && isPlaying && (
              <span className="text-gray-400 text-xs block truncate">
                {currentTrack.name}
              </span>
            )}
          </div>
        </div>

        <div className="w-full">
          {currentTrack && (
            <iframe
              src={`https://open.spotify.com/embed/track/${
                currentTrack.id
              }?utm_source=generator${isPlaying ? "&autoplay=1" : ""}`}
              width="100%"
              height="80"
              frameBorder="0"
              allowtransparency="true"
              allow="encrypted-media; autoplay"
              title="Spotify Player"
              id="spotify-iframe-compact"
            ></iframe>
          )}
        </div>
      </div>
    );
  }

  // Original full-sized player
  return (
    <div className="spotify-player-container bg-black rounded-lg p-2 mb-4">
      <div className="flex items-center justify-between">
        <div className="flex items-center">
          <img
            src={
              artist.images[0]?.url || "https://open.spotify.com/favicon.ico"
            }
            alt={artist.name}
            className="h-8 w-8 mr-2"
            onError={(e) => {
              e.target.onerror = null;
              e.target.src = "https://open.spotify.com/favicon.ico";
            }}
          />
          <div>
            <span className="text-white font-medium block">
              {isPlaying ? "Now Playing: " : "Listen to: "}
              <span className="text-green-500">{artist.name}</span>
            </span>
            {currentTrack && (
              <span className="text-gray-400 text-sm block">
                {currentTrack.name}
              </span>
            )}
          </div>
        </div>
        <button
          onClick={togglePlay}
          className={`px-4 py-1 rounded-full ${
            isPlaying
              ? "bg-red-600 hover:bg-red-700"
              : "bg-green-600 hover:bg-green-700"
          } text-white font-medium transition-colors`}
        >
          {isPlaying ? "Pause" : "Play"}
        </button>
      </div>

      {currentTrack && (
        <div className="mt-2">
          <iframe
            src={`https://open.spotify.com/embed/track/${
              currentTrack.id
            }?utm_source=generator${isPlaying ? "&autoplay=1" : ""}`}
            width="100%"
            height="80"
            frameBorder="0"
            allowtransparency="true"
            allow="encrypted-media; autoplay"
            title="Spotify Player"
            id="spotify-iframe-full"
          ></iframe>
        </div>
      )}

      {top_tracks && top_tracks.length > 0 && (
        <div className="mt-2">
          <p className="text-gray-400 text-xs mb-1">Top Tracks:</p>
          <div className="flex flex-wrap gap-2">
            {top_tracks.map((track) => (
              <button
                key={track.id}
                onClick={() => selectTrack(track)}
                className={`text-xs px-2 py-1 rounded-full ${
                  currentTrack && currentTrack.id === track.id
                    ? "bg-green-800 text-white"
                    : "bg-gray-800 text-gray-300 hover:bg-gray-700"
                }`}
              >
                {track.name}
              </button>
            ))}
          </div>
        </div>
      )}

      <div className="mt-2 text-right">
        <a
          href={artist.external_url}
          target="_blank"
          rel="noopener noreferrer"
          className="text-green-500 hover:text-green-400 text-xs"
        >
          Open in Spotify
        </a>
      </div>
    </div>
  );
};

export default SpotifyPlayer;
