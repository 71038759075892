import React, { useMemo } from "react";
import SettlementTableTitle from "./SettlementTableTitle";
import { Table, TableRow } from "../AppTable2";
import { useSettlementComponent } from "@/hooks/useSettlementComponent";
import { toast } from "react-toastify";
import SettlementArtistPayment from "./SettlementArtistPayment";
import { OVERRIDES_SECTION_NAMES } from "@/constants/settlement";

const SECTION = {
  NAME: OVERRIDES_SECTION_NAMES.HEADERLINER_SETTLEMENT,
  SUBSECTIONS: {
    FEE_CALCULATION: "fee_calculation",
    DEDUCTIONS: "deductions",
    CHARGEBACKS: "chargebacks",
    REIMBURSEMENTS: "reimbursements",
    PAYMENT: "payment",
    PAYMENT_DETAILS: "payment_details", // For check_number, payment_method, paid_through
  },
} as const;

const SettlementArtistAdditionals: React.FC = () => {
  const {
    event,
    variables,
    expensesQuery,
    eventSettlement,
    rollups,
    settlementInputClassName,
    eventOverrides,
    createEventOverrideMutation,
    updateEventOverrideMutation,
    deleteEventOverrideMutation,
  } = useSettlementComponent({ enableExpenses: true });

  const isLoading =
    !variables.data ||
    !eventSettlement.data ||
    !rollups.data ||
    !expensesQuery.data;

  const isCustomField = (fieldName: string) => fieldName.startsWith("__");

  const getNumericValue = (key: string, subSection: string): number => {
    if (!eventOverrides.data) return 0;
    const override = eventOverrides.data.find(
      (o) =>
        o.field_name === key &&
        o.section_name === SECTION.NAME &&
        o.sub_section_name === subSection
    );
    if (override) return Number(override.value) || 0;
    const settlementData = eventSettlement.data?.artist?.[subSection];
    if (settlementData && settlementData[key] !== undefined) {
      return Number(settlementData[key]) || 0;
    }
    return Number(rollups.data[key] ?? variables.data?.[key] ?? 0) || 0;
  };

  const getStringValue = (key: string, subSection: string): string => {
    if (!eventOverrides.data) return "";
    const override = eventOverrides.data.find(
      (o) =>
        o.field_name === key &&
        o.section_name === SECTION.NAME &&
        o.sub_section_name === subSection
    );
    return override ? override.value : "";
  };

  const handleNumericOverride = async (
    fieldKey: string,
    val: any,
    subSection: string
  ): Promise<{ success: boolean }> => {
    if (!event?.data?.id || !fieldKey) return { success: false };
    try {
      const numericVal = Number(val) || 0;
      let finalName = fieldKey;
      if (!isCustomField(fieldKey)) {
        const parts = fieldKey.split("__");
        if (parts.length > 1) {
          finalName = parts[1];
        }
      }
      const overrideData = {
        event: event.data.id,
        section_name: SECTION.NAME,
        sub_section_name: subSection,
        field_name: finalName,
        value: numericVal.toString(),
        is_money: true,
      };
      const existing = eventOverrides.data.find(
        (o) =>
          o.field_name === finalName &&
          o.section_name === SECTION.NAME &&
          o.sub_section_name === subSection
      );
      if (existing) {
        await updateEventOverrideMutation.mutate({
          overrideId: existing.id,
          data: overrideData,
        });
      } else {
        await createEventOverrideMutation.mutate(overrideData);
      }
      return { success: true };
    } catch (error) {
      toast.error("Failed to save override");
      return { success: false };
    }
  };

  const handleStringOverride = async (
    fieldKey: string,
    val: string,
    subSection: string
  ): Promise<{ success: boolean }> => {
    if (!event?.data?.id || !fieldKey) return { success: false };
    try {
      let finalName = fieldKey;
      if (!isCustomField(fieldKey)) {
        const parts = fieldKey.split("__");
        if (parts.length > 1) {
          finalName = parts[1];
        }
      }
      const overrideData = {
        event: event.data.id,
        section_name: SECTION.NAME,
        sub_section_name: subSection,
        field_name: finalName,
        value: val,
        is_money: false,
      };
      const existing = eventOverrides.data.find(
        (o) =>
          o.field_name === finalName &&
          o.section_name === SECTION.NAME &&
          o.sub_section_name === subSection
      );
      if (existing) {
        await updateEventOverrideMutation.mutate({
          overrideId: existing.id,
          data: overrideData,
        });
      } else {
        await createEventOverrideMutation.mutate(overrideData);
      }
      return { success: true };
    } catch (error) {
      toast.error("Failed to save override");
      return { success: false };
    }
  };

  const handleDeleteOverride = async (override: any) => {
    try {
      await deleteEventOverrideMutation.mutate(override.id);
      toast.success("Override deleted successfully");
    } catch (error) {
      toast.error("Failed to delete override");
    }
  };

  const handleOverrideDeductions = async (
    overrideId: number | undefined,
    item: string,
    paid_by: string,
    amount: number
  ) => {
    if (!event?.data?.id || !item) return { success: false };
    try {
      let finalName = item;
      if (!isCustomField(item)) {
        const parts = item.split("__");
        if (parts.length > 1) {
          finalName = parts[1];
        }
      }
      const overrideData = {
        event: event.data.id,
        section_name: SECTION.NAME,
        sub_section_name: SECTION.SUBSECTIONS.DEDUCTIONS,
        field_name: finalName,
        value: JSON.stringify({ paid_by, amount }),
        is_money: false,
      };
      if (overrideId) {
        await updateEventOverrideMutation.mutate({
          overrideId,
          data: overrideData,
        });
      } else {
        await createEventOverrideMutation.mutate(overrideData);
      }
      return { success: true };
    } catch {
      toast.error("Failed to save deduction override");
      return { success: false };
    }
  };

  const handleAddRowDeductions = async (formData: any) => {
    if (!event?.data?.id) return { success: false };
    try {
      const item = `__${(formData.item || "").trim()}`;
      const paid_by = (formData.paid_by || "").trim();
      const amount = Number(formData.amount) || 0;
      const exists = eventOverrides.data?.some(
        (o) =>
          o.field_name === item &&
          o.section_name === SECTION.NAME &&
          o.sub_section_name === SECTION.SUBSECTIONS.DEDUCTIONS
      );
      if (exists) {
        toast.error("A deduction with this Item already exists");
        return { success: false };
      }
      return handleOverrideDeductions(undefined, item, paid_by, amount);
    } catch {
      toast.error("Failed to add deduction override");
      return { success: false };
    }
  };

  const onCellBlurDeductions = async (
    rowIndex: number,
    accessor: string,
    newVal: any,
    rowData: any
  ) => {
    const overrideId = rowData.id || undefined;
    const newItem = accessor === "item" ? newVal : rowData.item;
    const newPaidBy = accessor === "paid_by" ? newVal : rowData.paid_by;
    const newAmount =
      accessor === "amount" ? Number(newVal) : Number(rowData.amount);
    await handleOverrideDeductions(overrideId, newItem, newPaidBy, newAmount);
  };

  const getDeductionsData = useMemo(() => {
    if (!eventOverrides.data) return [];
    const dedOverrides = eventOverrides.data.filter(
      (o) =>
        o.section_name === SECTION.NAME &&
        o.sub_section_name === SECTION.SUBSECTIONS.DEDUCTIONS
    );
    return dedOverrides.map((ov) => {
      let paid_by = "";
      let amount = 0;
      try {
        const parsed = JSON.parse(ov.value || "{}");
        paid_by = parsed.paid_by || "";
        amount = Number(parsed.amount) || 0;
      } catch {}
      let itemDisplayed = ov.field_name;
      if (itemDisplayed.startsWith("__")) {
        itemDisplayed = itemDisplayed.slice(2);
      }
      return {
        id: ov.id,
        item: itemDisplayed,
        paid_by,
        amount,
      };
    });
  }, [eventOverrides.data]);

  const data = useMemo(() => {
    if (isLoading) return null;
    const feeCalculationRows = [
      {
        key: "net_receipts",
        label: "NET RECEIPTS LESS COSTS",
        value: rollups.data.net_receipts_less_costs,
        editable: false,
        format: "money",
      },
      {
        key: "guarantee",
        label: "GUARANTEE",
        value: variables.data?.the_deal_amount,
        editable: false,
        format: "money",
      },
      {
        key: "vs",
        label: "VS",
        value: variables.data.vs,
        editable: false,
        format: "money",
      },
      {
        key: "overage",
        label: "OVERAGE",
        value: variables.data.overage,
        editable: false,
        format: "money",
      },
      {
        key: "guarantee_2",
        label: "GUARANTEE",
        value: variables.data?.the_deal_amount,
        editable: false,
        format: "money",
      },
      {
        key: "promoter_profit",
        label: "PROMOTER PROFIT",
        value: variables.data.promoter_profit,
        editable: false,
        format: "money",
      },
      {
        key: "split_point",
        label: "SPLIT POINT",
        value: variables.data.split_point,
        editable: false,
        format: "money",
      },
    ];
    const feeCalcOverrides =
      eventOverrides.data?.filter(
        (o) =>
          o.section_name === SECTION.NAME &&
          o.sub_section_name === SECTION.SUBSECTIONS.FEE_CALCULATION
      ) || [];
    const feeCalcOverrideData = feeCalcOverrides.map((ov) => ({
      id: ov.id,
      field_name: ov.field_name.startsWith("__")
        ? ov.field_name.slice(2)
        : ov.field_name,
      value: Number(ov.value) || 0,
    }));

    const chargebacks =
      eventOverrides.data?.filter(
        (o) =>
          o.section_name === SECTION.NAME &&
          o.sub_section_name === SECTION.SUBSECTIONS.CHARGEBACKS
      ) || [];
    const artistChargebacksData = chargebacks.map((ov) => ({
      id: ov.id,
      field_name: ov.field_name.startsWith("__")
        ? ov.field_name.slice(2)
        : ov.field_name,
      value: Number(ov.value) || 0,
    }));

    const reimbursements =
      eventOverrides.data?.filter(
        (o) =>
          o.section_name === SECTION.NAME &&
          o.sub_section_name === SECTION.SUBSECTIONS.REIMBURSEMENTS
      ) || [];
    const artistReimbursementsData = reimbursements.map((ov) => ({
      id: ov.id,
      field_name: ov.field_name.startsWith("__")
        ? ov.field_name.slice(2)
        : ov.field_name,
      value: Number(ov.value) || 0,
    }));

    const artistPaymentsData = [
      {
        key: "guarantee",
        label: "GUARANTEE",
        value: getNumericValue("guarantee", SECTION.SUBSECTIONS.PAYMENT),
        isEditable: false,
        format: "money",
      },
      {
        key: "overage",
        label: "OVERAGE",
        value: getNumericValue("overage", SECTION.SUBSECTIONS.PAYMENT),
        isEditable: false,
        format: "money",
      },
      {
        key: "net_adjustments",
        label: "NET ADJUSTMENTS",
        value: getNumericValue("net_adjustments", SECTION.SUBSECTIONS.PAYMENT),
        isEditable: false,
        format: "money",
      },
      {
        key: "taxable_amount",
        label: "TAXABLE AMOUNT",
        value: getNumericValue("taxable_amount", SECTION.SUBSECTIONS.PAYMENT),
        isEditable: false,
        format: "money",
      },
      {
        key: "tax_rate",
        label: "TAX %: STATE / CITY",
        value: getNumericValue("tax_rate", SECTION.SUBSECTIONS.PAYMENT),
        isEditable: true,
        format: "percentage",
      },
      {
        key: "deductions",
        label: "LESS DEDUCTIONS",
        value: getNumericValue("deductions", SECTION.SUBSECTIONS.PAYMENT),
        isEditable: false,
        format: "money",
      },
    ];
    const paymentOverrides =
      eventOverrides.data?.filter(
        (o) =>
          o.section_name === SECTION.NAME &&
          o.sub_section_name === SECTION.SUBSECTIONS.PAYMENT
      ) || [];
    const paymentOverrideData = paymentOverrides.map((ov) => ({
      id: ov.id,
      field_name: ov.field_name.startsWith("__")
        ? ov.field_name.slice(2)
        : ov.field_name,
      value: Number(ov.value) || 0,
    }));

    return {
      feeCalculationRows,
      feeCalcOverrideData,
      artistChargebacksData,
      artistReimbursementsData,
      artistPaymentsData,
      paymentOverrideData,
    };
  }, [
    isLoading,
    variables.data,
    rollups.data,
    eventSettlement.data,
    eventOverrides.data,
  ]);

  if (isLoading || !data) {
    return <div>Loading...</div>;
  }

  return (
    <div className="grid grid-cols-1 lg:grid-cols-4">
      <div className="border border-black self-start">
        <SettlementTableTitle>1. ARTIST FEE CALCULATION</SettlementTableTitle>
        {data.feeCalculationRows.map((row) => (
          <TableRow
            key={row.key}
            nonEditableClass="bg-white flex-1 block"
            inputClassName={settlementInputClassName}
            columns={[
              { key: "label", accessor: "label", textAlign: "left" },
              {
                key: "value",
                accessor: "value",
                textAlign: "right",
                editable: row.editable,
                format: row.format,
              },
            ]}
            rowData={row}
            onBlur={
              row.editable
                ? (val) =>
                    handleNumericOverride(
                      row.key,
                      val,
                      SECTION.SUBSECTIONS.FEE_CALCULATION
                    )
                : undefined
            }
          />
        ))}
        <Table
          columns={[
            {
              key: "field_name",
              accessor: "field_name",
              title: "ITEM",
              textAlign: "left",
              width: "60%",
              editable: true,
              addRowEditable: true,
            },
            {
              key: "value",
              accessor: "value",
              title: "VALUE",
              textAlign: "right",
              format: "money",
              editable: true,
              addRowEditable: true,
            },
          ]}
          data={data.feeCalcOverrideData}
          enableAddRow
          hideHeaders
          addRowButtonTitle="Add Override"
          onAddRow={async (formData) => {
            const customField = `__${(formData.field_name || "").trim()}`;
            const val = Number(formData.value) || 0;
            const exists = eventOverrides.data?.some(
              (o) =>
                o.field_name === customField &&
                o.section_name === SECTION.NAME &&
                o.sub_section_name === SECTION.SUBSECTIONS.FEE_CALCULATION
            );
            if (exists) {
              toast.error("An override with this item already exists");
              return { success: false };
            }
            return handleNumericOverride(
              customField,
              val,
              SECTION.SUBSECTIONS.FEE_CALCULATION
            );
          }}
          onDelete={(index, row) => handleDeleteOverride(row)}
          onCellBlur={(rowIndex, accessor, value, row) => {
            handleNumericOverride(
              `__${row.field_name}`,
              value,
              SECTION.SUBSECTIONS.FEE_CALCULATION
            );
          }}
          deleteIcons
          deleteConfirmation="modal"
          showNoDataMessage={false}
          inputClassName={settlementInputClassName}
        />
      </div>

      <div className="border border-black self-start">
        <SettlementTableTitle>2a. ARTIST DEDUCTIONS</SettlementTableTitle>
        <Table
          columns={[
            {
              key: "item",
              accessor: "item",
              title: "ITEM",
              textAlign: "left",
              width: "40%",
              editable: true,
              addRowEditable: true,
            },
            {
              key: "paid_by",
              accessor: "paid_by",
              title: "PAID BY",
              textAlign: "left",
              width: "25%",
              editable: true,
              addRowEditable: true,
            },
            {
              key: "amount",
              accessor: "amount",
              title: "AMOUNT",
              textAlign: "right",
              width: "25%",
              editable: true,
              format: "money",
              addRowEditable: true,
            },
          ]}
          data={getDeductionsData}
          enableAddRow
          addRowButtonTitle="Add Deduction"
          onAddRow={handleAddRowDeductions}
          onCellBlur={onCellBlurDeductions}
          onDelete={(index, row) => handleDeleteOverride(row)}
          deleteIcons
          deleteConfirmation="modal"
          inputClassName={settlementInputClassName}
        />
        <div className="border-t border-black">
          <SettlementTableTitle>
            2b. ARTIST DIRECT CHARGEBACKS
          </SettlementTableTitle>
          <Table
            columns={[
              {
                key: "field_name",
                accessor: "field_name",
                title: "ITEM",
                editable: true,
                width: "55%",
                addRowEditable: true,
              },
              {
                key: "value",
                accessor: "value",
                title: "AMOUNT",
                editable: true,
                format: "money",
                textAlign: "right",
                addRowEditable: true,
              },
            ]}
            data={data.artistChargebacksData}
            enableAddRow
            addRowButtonTitle="Add Chargeback"
            onAddRow={async (formData) => {
              const customField = `__${(formData.field_name || "").trim()}`;
              const val = Number(formData.value) || 0;
              const exists = eventOverrides.data?.some(
                (o) =>
                  o.field_name === customField &&
                  o.section_name === SECTION.NAME &&
                  o.sub_section_name === SECTION.SUBSECTIONS.CHARGEBACKS
              );
              if (exists) {
                toast.error("A chargeback with this item already exists");
                return { success: false };
              }
              return handleNumericOverride(
                customField,
                val,
                SECTION.SUBSECTIONS.CHARGEBACKS
              );
            }}
            onDelete={(index, row) => handleDeleteOverride(row)}
            onCellBlur={(rowIndex, accessor, value, row) => {
              handleNumericOverride(
                `__${row.field_name}`,
                value,
                SECTION.SUBSECTIONS.CHARGEBACKS
              );
            }}
            deleteIcons
            deleteConfirmation="modal"
            inputClassName={settlementInputClassName}
          />
          <TableRow
            columns={[
              { key: "name", accessor: "name", textAlign: "left" },
              {
                key: "value",
                accessor: "value",
                textAlign: "right",
                format: "money",
              },
            ]}
            rowData={{
              name: "NET ADJUSTMENTS",
              value: data.artistChargebacksData.reduce(
                (acc, curr) => acc + Number(curr.value),
                0
              ),
            }}
            rowContainerClass="bg-blue-200"
          />
        </div>
      </div>

      <div className="border border-black self-start">
        <SettlementTableTitle>3. ARTIST REIMBURSEMENTS</SettlementTableTitle>
        <Table
          columns={[
            {
              key: "field_name",
              accessor: "field_name",
              title: "ITEM",
              editable: true,
              width: "55%",
              addRowEditable: true,
            },
            {
              key: "value",
              accessor: "value",
              title: "AMOUNT",
              editable: true,
              format: "money",
              textAlign: "right",
              addRowEditable: true,
            },
          ]}
          data={data.artistReimbursementsData}
          enableAddRow
          addRowButtonTitle="Add Reimbursement"
          onAddRow={async (formData) => {
            const customField = `__${(formData.field_name || "").trim()}`;
            const val = Number(formData.value) || 0;
            const exists = eventOverrides.data?.some(
              (o) =>
                o.field_name === customField &&
                o.section_name === SECTION.NAME &&
                o.sub_section_name === SECTION.SUBSECTIONS.REIMBURSEMENTS
            );
            if (exists) {
              toast.error("A reimbursement with this item already exists");
              return { success: false };
            }
            return handleNumericOverride(
              customField,
              val,
              SECTION.SUBSECTIONS.REIMBURSEMENTS
            );
          }}
          onDelete={(index, row) => handleDeleteOverride(row)}
          onCellBlur={(rowIndex, accessor, value, row) => {
            handleNumericOverride(
              `__${row.field_name}`,
              value,
              SECTION.SUBSECTIONS.REIMBURSEMENTS
            );
          }}
          deleteIcons
          deleteConfirmation="modal"
          inputClassName={settlementInputClassName}
        />
        <TableRow
          columns={[
            { key: "name", accessor: "name", textAlign: "left" },
            {
              key: "value",
              accessor: "value",
              textAlign: "right",
              format: "money",
            },
          ]}
          rowData={{
            name: "",
            value: data.artistReimbursementsData.reduce(
              (acc, curr) => acc + Number(curr.value),
              0
            ),
          }}
          rowContainerClass="bg-blue-200"
        />
      </div>

      <div className="border border-black self-start">
        <SettlementTableTitle>4. ARTIST PAYMENT</SettlementTableTitle>
        {data.artistPaymentsData.map((row) => (
          <TableRow
            key={row.key}
            nonEditableClass="bg-white flex-1 block"
            inputClassName={settlementInputClassName}
            columns={[
              { key: "label", accessor: "label", textAlign: "left" },
              {
                key: "value",
                accessor: "value",
                textAlign: "right",
                editable: row.isEditable,
                format: row.format,
              },
            ]}
            rowData={row}
            onBlur={
              row.isEditable
                ? (val) =>
                    handleNumericOverride(
                      row.key,
                      val,
                      SECTION.SUBSECTIONS.PAYMENT
                    )
                : undefined
            }
          />
        ))}
        <Table
          columns={[
            {
              key: "field_name",
              accessor: "field_name",
              title: "ITEM",
              editable: true,
              width: "60%",
              addRowEditable: true,
            },
            {
              key: "value",
              accessor: "value",
              title: "VALUE",
              editable: true,
              format: "money",
              textAlign: "right",
              addRowEditable: true,
            },
          ]}
          data={data.paymentOverrideData}
          enableAddRow
          hideHeaders
          addRowButtonTitle="Add Payment Override"
          onAddRow={async (formData) => {
            const customField = `__${(formData.field_name || "").trim()}`;
            const val = Number(formData.value) || 0;
            const exists = eventOverrides.data?.some(
              (o) =>
                o.field_name === customField &&
                o.section_name === SECTION.NAME &&
                o.sub_section_name === SECTION.SUBSECTIONS.PAYMENT
            );
            if (exists) {
              toast.error("A payment override with this item already exists");
              return { success: false };
            }
            return handleNumericOverride(
              customField,
              val,
              SECTION.SUBSECTIONS.PAYMENT
            );
          }}
          onDelete={(index, row) => handleDeleteOverride(row)}
          onCellBlur={(rowIndex, accessor, value, row) => {
            handleNumericOverride(
              `__${row.field_name}`,
              value,
              SECTION.SUBSECTIONS.PAYMENT
            );
          }}
          deleteIcons
          deleteConfirmation="modal"
          showNoDataMessage={false}
          inputClassName={settlementInputClassName}
        />
        <TableRow
          columns={[
            { key: "name", accessor: "name", textAlign: "left", width: "65%" },
            {
              key: "value",
              accessor: "value",
              textAlign: "right",
              format: "money",
            },
          ]}
          rowData={{
            name: "TOTAL DUE ARTIST",
            value: getNumericValue("total_due", SECTION.SUBSECTIONS.PAYMENT),
          }}
          rowContainerClass="bg-blue-200"
        />
        <SettlementArtistPayment
          checkNumber={{
            value: getStringValue(
              "__check_number",
              SECTION.SUBSECTIONS.PAYMENT_DETAILS
            ),
            onUpdate: (val: string) =>
              handleStringOverride(
                "__check_number",
                val,
                SECTION.SUBSECTIONS.PAYMENT_DETAILS
              ),
          }}
          paymentMethod={{
            value: getStringValue(
              "__payment_method",
              SECTION.SUBSECTIONS.PAYMENT_DETAILS
            ),
            onUpdate: (val: string) =>
              handleStringOverride(
                "__payment_method",
                val,
                SECTION.SUBSECTIONS.PAYMENT_DETAILS
              ),
          }}
          paidThrough={{
            value: getStringValue(
              "__paid_through",
              SECTION.SUBSECTIONS.PAYMENT_DETAILS
            ),
            onUpdate: (val: string) =>
              handleStringOverride(
                "__paid_through",
                val,
                SECTION.SUBSECTIONS.PAYMENT_DETAILS
              ),
          }}
        />
      </div>
    </div>
  );
};

export default SettlementArtistAdditionals;
