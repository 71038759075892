export const amountDisplay = (amount, reverse) => {
  // Handle null, undefined, or NaN explicitly
  if (
    amount === null ||
    amount === undefined ||
    (typeof amount === "number" && isNaN(amount))
  ) {
    return "$0.00";
  }

  // Ensure the amount is a valid number or fallback to 0
  let validAmount = 0;

  if (typeof amount === "number" && !isNaN(amount)) {
    validAmount = amount;
  } else if (typeof amount === "string") {
    // Remove any $ and , before parsing
    const cleanAmount = amount.replace(/[$,]/g, "");
    if (!isNaN(parseFloat(cleanAmount))) {
      validAmount = parseFloat(cleanAmount);
    }
  } else if (typeof amount === "object" && amount !== null) {
    // If it's an object, try to extract a numeric value
    if (amount.value !== undefined && !isNaN(parseFloat(amount.value))) {
      validAmount = parseFloat(amount.value);
    } else if (
      amount.amount !== undefined &&
      !isNaN(parseFloat(amount.amount))
    ) {
      validAmount = parseFloat(amount.amount);
    }
  }

  // If already formatted as a string with "$", return it directly
  if (typeof amount === "string" && amount.includes("$")) {
    return amount;
  }

  // Fallback to "$0.00" if the amount is zero
  if (validAmount === 0) {
    return "$0.00";
  }

  const formatter = new Intl.NumberFormat("en-US", {
    style: "currency",
    currency: "USD",
  });

  try {
    // Force explicit number conversion to catch edge cases
    validAmount = Number(validAmount);
    if (isNaN(validAmount)) {
      return "$0.00";
    }

    // Always divide by 100 since we store everything in cents
    const valueToFormat = validAmount / 100;

    // Format the amount
    const formattedAmount = formatter.format(valueToFormat);

    // Handle reverse display (for negative numbers)
    if (reverse) {
      return validAmount >= 0 ? `(${formattedAmount})` : formattedAmount;
    }

    return validAmount >= 0 ? formattedAmount : `(${formattedAmount})`;
  } catch (error) {
    console.error("Error formatting amount:", amount, error);
    return "$0.00";
  }
};

/* Take '4.01' and make it 401 */
export const amountStrToInt = (amount) => {
  amount = (amount + "").replace(/[,$]/g, "");
  let f = parseFloat(amount);
  f = f * 100;
  return parseInt(f);
};

export const formatDollarsToCents = (value) => {
  value = (value + "").replace(/[^\d.-]/g, "");
  if (value && value.includes(".")) {
    value = value.substring(0, value.indexOf(".") + 3);
  }

  return value ? Math.round(parseFloat(value) * 100) : 0;
};

export const formatCentsToDollars = (value) => {
  value = (value + "").replace(/[^\d.-]/g, "");
  value = parseFloat(value);
  return value ? value / 100 : 0;
};

export const formatDisplayToCents = (amount) => {
  if (!amount) return 0;

  // Remove any formatting characters
  const cleaned = amount.toString().replace(/[,$]/g, "");

  // Check if it contains a decimal point
  if (cleaned.includes(".")) {
    // Handle decimal input (e.g. "30.00" -> 3000, "1.50" -> 150)
    return Math.round(parseFloat(cleaned) * 100);
  } else {
    // Handle whole number input (e.g. "30" -> 3000, "1" -> 100)
    return parseInt(cleaned) * 100;
  }
};

/**
 * Formats a number or string to a currency string in US dollars.
 * The output is formatted with a dollar sign, commas as thousand separators, and exactly two decimal places.
 * For large values (likely in cents), divides by 100 to convert to dollars.
 *
 * @param {string} value - The number or string to format as currency.
 * @returns {string} The formatted currency string.
 *
 * @example
 * formatToMoney(401)           // "$4.01"
 * formatToMoney(4)             // "$0.04"
 * formatToMoney('4.00')        // "$4.00"
 * formatToMoney(20000)         // "$200.00"
 * formatToMoney("$20,000")     // "$200.00"
 * formatToMoney("$20,000.07")  // "$20,000.07"
 * formatToMoney(2419)          // "$24.19"
 */
export function formatInputToMoney(value) {
  // Handle null, undefined, non-strings
  if (!value || (typeof value === "string" && value.trim() === "")) {
    return "$0.00";
  }
  try {
    const formatter = new Intl.NumberFormat("en-US", {
      style: "currency",
      currency: "USD",
    });
    // Ensure value is converted to a string before using replace
    const valueStr = String(value);

    // Check if the value is already formatted with a dollar sign
    const isAlreadyFormatted = valueStr.includes("$");

    // Clean the string and parse it
    const parsedValue = parseFloat(valueStr.replace(/[$,]/g, ""));

    // Handle NaN
    if (isNaN(parsedValue)) {
      return "$0.00";
    }

    // If already formatted with a dollar sign, assume it's in dollars
    // Otherwise, treat large values as cents and divide by 100
    if (isAlreadyFormatted || parsedValue < 1000) {
      return formatter.format(parsedValue);
    } else {
      return formatter.format(parsedValue / 100);
    }
  } catch (error) {
    console.error("Error formatting money value:", error);
    return "$0.00"; // Return a safe default if formatting fails
  }
}
