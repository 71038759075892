import { useMutation, useQuery, useQueryClient } from "@tanstack/react-query";
import { useState, useEffect, useRef } from "react";
import { useParams, useNavigate, NavLink } from "react-router-dom";
import { toast } from "react-toastify";

import AgeMerchAgGrid from "./AgeMerchAgGrid";
import ArtistInfoAgGrid from "./ArtistInfoAgGrid";
import CapacityAgGrid from "./CapacityAgGrid";
import ContactAgGrid from "./ContactAgGrid";
import DealAgGrid from "./DealAgGrid";
import DealTypeAgGrid from "./DealTypeAgGrid";
import ExpenseAgGrid from "./ExpenseAgGrid";
import ManifestAgGrid from "./ManifestAgGrid";
import { useUser } from "@/components/Auth";
import { BreadCrumbs } from "@/components/BreadCrumbs";
import Button from "@/components/Button";
import { DateChanger } from "@/components/EventState/DateChanger";
import { StateSwitcher } from "@/components/EventState/StateSwitcher.jsx";
import { TemplateManager } from "@/components/TemplateManager";
import { WalkoutPot } from "@/components/WalkoutPot";
import { usePageTitle } from "@/utils/pagetitle";

// Custom hook to force periodic refetches to ensure data consistency, but less aggressively
const useDataConsistencyCheck = (id, queryClient) => {
  useEffect(() => {
    const interval = setInterval(() => {
      // Force refetch of critical queries every 30 seconds instead of 10
      queryClient.refetchQueries(["event-detail", id], { force: true });
      queryClient.refetchQueries(["variables-offer-pdf", id], { force: true });
    }, 30000); // 30 seconds

    return () => clearInterval(interval);
  }, [id, queryClient]);
};

// Function to detect changes - moved outside component to avoid hook ordering issues
function logChanges(previousData, newData, updateState) {
  function findChanges(oldObj, newObj, path = "") {
    const changes = [];
    Object.keys(newObj).forEach((key) => {
      const newPath = path ? `${path}.${key}` : key;
      if (
        oldObj[key] &&
        typeof newObj[key] === "object" &&
        newObj[key] !== null &&
        !Array.isArray(newObj[key])
      ) {
        const nestedChanges = findChanges(oldObj[key], newObj[key], newPath);
        if (Object.keys(nestedChanges).length > 0) {
          Object.assign(changes, nestedChanges);
        }
      } else if (newObj[key] !== oldObj[key]) {
        changes.push(newPath);
      }
    });
    return changes;
  }

  const changes = findChanges(previousData, newData);
  if (changes.length > 0) {
    updateState &&
      updateState((existingState) => {
        return [...existingState, ...changes];
      });
  }
}

// Custom hook defined outside component
function useQueryWithChangeDetection(queryKey, queryFn, options, updateState) {
  const queryInfo = useQuery(queryKey, queryFn, options);
  const previousDataRef = useRef();

  useEffect(() => {
    if (
      queryInfo.data &&
      previousDataRef.current &&
      queryInfo.data !== previousDataRef.current
    ) {
      logChanges(previousDataRef.current, queryInfo.data, updateState);
    }
    previousDataRef.current = queryInfo.data;
  }, [queryInfo.data]);

  return queryInfo;
}

// Import AgGrid components
import TextAreaAgGrid from "./TextAreaAgGrid";
import VariablesAgGrid from "./VariablesAgGrid";
import TimesAgGrid from "./TimesAgGrid";
import VenueInfoAgGrid from "./VenueInfoAgGrid";
import WalkoutPotentialAgGrid from "./WalkoutPotentialAgGrid";

// Import queries
import {
  getEventRollups,
  getEventPDFExpenses,
  updateEventExpenseOffer,
  deleteExpense,
  createEventExpenseOffer,
  getExpenseGroups,
  updateEventExpense,
} from "@/queries/accounting";
import {
  getEvent,
  updateEventOffer,
  getEventOfferVariables,
  updateEvent,
  updateManifest,
  updateEventAccounting,
  getEventState,
  postEventState,
  createEventManifest,
  createEventPDF,
  deleteEventManifest,
  getEventPDFs,
} from "@/queries/events";
import { getVenue, getVenues, updateVenue } from "@/queries/venues";
import { getArtists } from "@/queries/artists";

// Import utilities
import { localTime, formatTimeString, formatToHHMMSS } from "@/utils/datetime";
import {
  amountDisplay,
  amountStrToInt,
  formatInputToMoney,
} from "@/utils/money";
import { useEventOfferStore } from "@/store/eventOfferStore";
import { useManifestStore } from "@/store/manifestStore";

export const FIELD_UPDATE_ANIMATION_TIME = 2000;

// Add CSS to fix AG Grid height issues
const gridFixStyles = `
  .ag-theme-alpine {
    --ag-grid-size: 4px;
    --ag-border-color: #dde2eb;
  }
  .ag-theme-alpine .ag-root-wrapper {
    border: 1px solid var(--ag-border-color);
    height: auto !important;
    min-height: auto !important;
    overflow: hidden !important;
  }
  .ag-theme-alpine .ag-root {
    height: auto !important;
  }
  .ag-theme-alpine .ag-body-viewport {
    overflow: hidden !important;
  }
  .ag-theme-alpine .ag-center-cols-container {
    width: 100% !important;
    min-height: auto !important;
  }
  .ag-theme-alpine .ag-center-cols-viewport {
    overflow: hidden !important;
  }
  .ag-theme-alpine .ag-body-horizontal-scroll {
    display: none !important;
  }
  .ag-theme-alpine .ag-layout-normal {
    height: auto !important;
  }
`;

const NewEventEditOffer = () => {
  const { eventOfferForm, clearEventOfferForm } = useEventOfferStore();
  const { clearManifests } = useManifestStore();

  // state variables
  const [videoLoaded, setVideoLoaded] = useState(false);
  const [loadError, setLoadError] = useState(false);
  const videoRef = useRef(null);
  const [displayExpenses, setDisplayExpenses] = useState([]);
  const [advertisingExpenses, setAdvertisingExpenses] = useState([]);
  const [totalAdvertisingExpenses, setTotalAdvertisingExpenses] = useState(0);
  const [grossPotential, setGrossPotential] = useState(0);
  const [ticketFees, setTicketFees] = useState(0);
  const [getEventDate, setGetEventDate] = useState("");
  const [dealHeader, setDealHeader] = useState("");
  const [venueID, setVenueID] = useState(null);
  const [isRefetchingPDF, setIsRefetching] = useState(false);
  const [isPDFGenerated, setIsPDFGenerated] = useState(false);
  const [showHeader, setShowHeader] = useState(true);
  const [updatedProperties, setUpdatedProperties] = useState([]);

  const toggleHeader = () => {
    setShowHeader(!showHeader);
  };

  const { user } = useUser();

  // hooks
  const { id } = useParams();
  const navigate = useNavigate();
  const queryClient = useQueryClient();

  // Define ALL mutation hooks at top level before we use them
  const updateEventOfferMutation = useMutation({
    mutationFn: async (data) => {
      return await updateEventOffer(id, data);
    },
    onSuccess: () => {
      queryClient.invalidateQueries(["event-detail", id]);
      queryClient.invalidateQueries(["variables-offer-pdf", id]);
      queryClient.invalidateQueries(["expenses-offer-pdf", id]);
      queryClient.invalidateQueries(["event-rollups", id]);
    },
  });

  const updateEventMutation = useMutation({
    mutationFn: async (data) => {
      return await updateEvent(id, data);
    },
    onSuccess: () => {
      queryClient.invalidateQueries(["event-detail", id]);
      queryClient.invalidateQueries(["variables-offer-pdf", id]);
    },
  });

  const updateEventManifest = useMutation({
    mutationFn: async (data) => {
      const updateData = {
        ...data,
        sort_order: data.sort_order,
        kills: 0,
        is_offer: true,
      };
      return await updateManifest(updateData.manifest_id, updateData);
    },
    onSuccess: async (newManifestData) => {
      const id = newManifestData?.eventId;
      queryClient.invalidateQueries(["event-detail", id]);
      queryClient.invalidateQueries(["variables-offer-pdf", id]);
      queryClient.invalidateQueries(["expenses-offer-pdf", id]);
      queryClient.invalidateQueries(["event-rollups", id]);
      queryClient.invalidateQueries(["manifests", id]);
    },
  });

  const addManifestMutation = useMutation({
    mutationFn: async (newManifest) => {
      newManifest.is_offer = true;
      newManifest.sort_order = 0;
      return await createEventManifest(id, newManifest);
    },
    onSuccess: () => {
      queryClient.invalidateQueries(["event-detail", id]);
      queryClient.invalidateQueries(["variables-offer-pdf", id]);
      queryClient.invalidateQueries(["event-rollups", id]);
      queryClient.invalidateQueries(["manifests", id]);
    },
  });

  const removeManifestMutation = useMutation({
    mutationFn: async (manifestId) => {
      return await deleteEventManifest(id, manifestId);
    },
    onSuccess: () => {
      queryClient.invalidateQueries(["event-detail", id]);
      queryClient.invalidateQueries(["variables-offer-pdf", id]);
      queryClient.invalidateQueries(["event-rollups", id]);
      queryClient.invalidateQueries(["manifests", id]);
    },
  });

  const createPDFMutation = useMutation({
    mutationFn: (data) => {
      return createEventPDF(id, data);
    },
    onError: (error) => {
      toast.error("Error generating PDF");
    },
    onSettled: () => {
      queryClient.invalidateQueries(["event-pdfs", id]);
      setIsRefetching(true);
      setTimeout(() => {
        setIsRefetching(false);
        setIsPDFGenerated(true);
      }, 15000);
    },
  });

  useEffect(() => {
    return () => {
      clearEventOfferForm();
      clearManifests();
    };
  }, []);

  // Set up the query client for more aggressive refetching
  useEffect(() => {
    queryClient.setDefaultOptions({
      queries: {
        refetchOnWindowFocus: true,
        staleTime: 0,
      },
    });
  }, [queryClient]);

  // Use the consistency check hook
  useDataConsistencyCheck(id, queryClient);

  // GET requests
  const event = useQueryWithChangeDetection(
    ["event-detail", id],
    () => getEvent(id, { cache: false }),
    {
      refetchOnWindowFocus: false, // Prevent refetching on window focus
      refetchInterval: 0, // Disable automatic refetching
      staleTime: Infinity, // Data is considered fresh indefinitely
      cacheTime: 60000, // Keep the data in cache for 60 seconds
    },
    setUpdatedProperties
  );

  usePageTitle(
    event.isSuccess
      ? `[of]${event.data.name}`
      : `/c${String.fromCharCode(92)}ave_good...`
  );

  const expenses = useQueryWithChangeDetection(
    ["expenses-offer-pdf", id],
    () => getEventPDFExpenses(id),
    {
      refetchOnWindowFocus: false, // Prevent refetching on window focus
      refetchInterval: 0, // Disable automatic refetching
      staleTime: Infinity, // Data is considered fresh indefinitely
      cacheTime: 60000, // Keep the data in cache for 60 seconds
    },
    setUpdatedProperties
  );

  const variables = useQueryWithChangeDetection(
    ["variables-offer-pdf", id],
    () => getEventOfferVariables(id),
    {
      refetchOnWindowFocus: false, // Prevent refetching on window focus
      refetchInterval: 0, // Disable automatic refetching
      staleTime: Infinity, // Data is considered fresh indefinitely
      cacheTime: 60000, // Keep the data in cache for 60 seconds
    },
    setUpdatedProperties
  );

  const rollups = useQueryWithChangeDetection(
    ["event-rollups", id],
    () => getEventRollups(id),
    {
      refetchOnWindowFocus: false, // Prevent refetching on window focus
      refetchInterval: 0, // Disable automatic refetching
      staleTime: Infinity, // Data is considered fresh indefinitely
      cacheTime: 60000, // Keep the data in cache for 60 seconds
    },
    setUpdatedProperties
  );

  const venue = useQueryWithChangeDetection(
    ["venue-detail", venueID],
    () => getVenue(venueID),
    {
      enabled: !!venueID,
    },
    setUpdatedProperties
  );

  const pdfs = useQuery(["event-pdfs", id], () => getEventPDFs(id), {
    refetchInterval: () => (isRefetchingPDF ? 500 : false),
  });

  const venues = useQuery(["venues"], () => getVenues());

  // Reset updatedProperties after animation
  useEffect(() => {
    if (updatedProperties?.length > 0) {
      setTimeout(() => {
        setUpdatedProperties([]);
      }, FIELD_UPDATE_ANIMATION_TIME);
    }
  }, [updatedProperties]);

  // Process expenses data
  useEffect(() => {
    if (expenses?.data) {
      const expensesArray = Array.isArray(expenses.data) ? expenses.data : [];
      setDisplayExpenses(
        expensesArray.filter((expense) => expense.group_name !== "Advertising")
      );
      let advExpenses = expensesArray.filter(
        (expense) => expense.group_name === "Advertising"
      );
      setAdvertisingExpenses(advExpenses);
      setTotalAdvertisingExpenses(
        advExpenses.reduce((total, expense) => total + expense.amount, 0)
      );
    }
  }, [expenses?.data]);

  // Process event data
  useEffect(() => {
    if (event && event.data) {
      setGrossPotential(
        event.data.manifests.reduce((total, manifest) => {
          const ticketFees = amountStrToInt(manifest.ticket_fees) || 0;
          return total + (manifest.price + ticketFees) * manifest.qty;
        }, 0)
      );
      setTicketFees(
        event.data.manifests.reduce((total, manifest) => {
          const ticketFees = amountStrToInt(manifest.ticket_fees) || 0;
          return total + manifest.qty * ticketFees;
        }, 0)
      );
      setGetEventDate(`[${event.data.date}]`);
    }
    if (!venueID && event?.data?.venue) {
      setVenueID(event.data.venue.id);
    }
  }, [event?.data]);

  // Set deal header
  useEffect(() => {
    if (!event.data) return;
    let dealName = "VERSUS";
    if (eventOfferForm.offer_type?.toLowerCase() === "flat") {
      dealName = "FLAT";
    } else if (
      eventOfferForm.offer_type?.toLowerCase() === "promoter profit" ||
      eventOfferForm.offer_type?.toLowerCase() === "promoter-profit"
    ) {
      dealName = "SPLIT POINT";
    } else if (
      eventOfferForm.offer_type?.toLowerCase() === "nbor" ||
      eventOfferForm.offer_type?.toLowerCase() === "door deal"
    ) {
      dealName = `DOOR DEAL`;
    }
    const dealHeader = `${dealName} ${eventOfferForm.offer_percentage}% DEAL`;

    setDealHeader(dealHeader);
  }, [event?.data, eventOfferForm.offer_type, eventOfferForm.offer_percentage]);

  // Video loading effect
  useEffect(() => {
    const video = videoRef.current;
    if (!video) return;

    const handleCanPlay = () => {
      setTimeout(() => {
        setVideoLoaded(true);
      }, 500);
    };

    video.addEventListener("canplaythrough", handleCanPlay);
    video.addEventListener("error", () => {
      setLoadError(true);
    });

    return () => {
      video.removeEventListener("canplaythrough", handleCanPlay);
      video.removeEventListener("error", () => { });
    };
  }, []);

  // Loading state
  if (
    rollups.isLoading ||
    expenses.isLoading ||
    event.isLoading ||
    variables.isLoading ||
    venue.isLoading ||
    venues.isLoading
  ) {
    return (
      <div className="fixed inset-0 z-50 flex items-center justify-center">
        <video
          data-testid="loading-video"
          ref={videoRef}
          className="w-full h-full object-cover"
          autoPlay
          muted
          playsInline
          loop
        >
          <source src="/static/images/cave_loading25.mp4" type="video/mp4" />
        </video>
      </div>
    );
  }

  // Error handling
  if (rollups.isError) throw rollups.error;
  if (expenses.isError) throw expenses.error;
  if (event.error) throw event.error;
  if (variables.error) throw variables.error;
  if (venue.error) throw venue.error;
  if (venues.error) throw venues.error;

  // Constants
  const AGE_OPTIONS = [
    { label: "All Ages", value: "all-ages" },
    { label: "13+", value: "13-up" },
    { label: "14+", value: "14-up" },
    { label: "15+", value: "15-up" },
    { label: "16+", value: "16-up" },
    { label: "17+", value: "17-up" },
    { label: "18+", value: "18-up" },
    { label: "19+", value: "19-up" },
    { label: "20+", value: "20-up" },
    { label: "21+", value: "21-up" },
  ];

  const EVENT_OFFER_TYPE_OPTIONS = [
    { label: "Flat", value: "flat" },
    { label: "Promoter Profit", value: "promoter-profit" },
    { label: "Versus", value: "vs" },
    { label: "Door Deal", value: "nbor" },
  ];

  const WHO_SELLS_MERCH_OPTIONS = [
    { label: "Mammoth", value: "mammoth" },
    { label: "Artist", value: "artist" },
    { label: "Venue", value: "venue" },
  ];

  const VENUE_OPTIONS = venues.data.map((option) => {
    return { value: option.name, label: option.name };
  });

  const BUYER_OPTIONS =
    event.data?.buyers?.map((buyer) => ({
      value: buyer.person_id,
      label: `${buyer.first_name} ${buyer.last_name}`,
    })) || [];

  // Required parameters
  const EVENT_REQUIRED_PARAMS = {
    name: event.data.name,
    date: event.data.date,
    artists: event.data.artists ? [event.data.artists[0].id] : [0],
    state: event.data.state,
    age_requirement: event.data.age_requirement,
    abbr: event.data.abbr,
    description: event.data.description,
    door_time: event.data.door_time,
    opener_time: event.data.opener_time,
    support_time: event.data.support_time,
    headliner_time: event.data.headliner_time,
    curfew_time: event.data.curfew_time,
    company: event.data.company || "mammoth",
    offer_notes: event.data.offer_notes,
    deal_notes: event.data.deal_notes,
    confirmation_notes: event.data.confirmation_notes,
    venue_id: event.data.venue.id ?? 0,
    has_insurance: event.data.has_insurance,
    has_credit_card_fees: event.data.has_credit_card_fees,
    has_ticket_commission: event.data.has_ticket_commission,
    has_ascap: event.data.has_ascap,
    has_bmi: event.data.has_bmi,
    has_sesac: event.data.has_sesac,
    offer_has_rent: event.data.offer_has_rent,
    offer_has_comp_tax: event.data.offer_has_comp_tax,
    offer_has_gmr: event.data.offer_has_gmr ?? false,
    offer_gmr: event.data.offer_gmr,
    facility_fee: event.data.facility_fee,
    offer_support_1: event.data.offer_support_1 || "",
    offer_support_2: event.data.offer_support_2 || "",
    offer_support_1_label: event.data.offer_support_1_label || "",
    offer_support_2_label: event.data.offer_support_2_label || "",
    offer_opener: event.data.offer_opener,
    offer_billing: event.data.offer_billing,
    charity: event.data.charity,
    platinum_lift: event.data.platinum_lift,
    buyers: event.data.buyers
      ? event.data.buyers.map((buyer) => buyer.person_id)
      : [],
  };

  // Get the latest offer
  const latestOffer = event.data?.offers?.sort(
    (a, b) => new Date(b.modified) - new Date(a.modified)
  )?.[0];

  const EVENT_OFFER_REQUIRED_PARAMS = {
    offer_type: event.data.offer_type,
    offer_agent: event.data.offer_agent,
    offer_headliner: event.data.offer_headliner,
    offer_support_1: event.data.offer_support_1,
    offer_support_2: event.data.offer_support_2,
    offer_support_1_label: event.data.offer_support_1_label,
    offer_support_2_label: event.data.offer_support_2_label,
    offer_opener: event.data.offer_opener,
    offer_billing: event.data.offer_billing,
    offer_stipulations: event.data.offer_stipulations,
    offer_avails_1: event.data.offer_avails_1,
    offer_avails_2: event.data.offer_avails_2,
    facility_fee: event.data.facility_fee,
    has_ascap: event.data.has_ascap,
    has_bmi: event.data.has_bmi,
    has_sesac: event.data.has_sesac,
    offer_comp_tax: event.data.offer_comp_tax,
    offer_gmr: event.data.offer_gmr,
    offer_has_gmr: event.data.offer_has_gmr,
    offer_minimum_rent: event.data.offer_minimum_rent,
    offer_rent_per_paid: event.data.offer_rent_per_paid,
    offer_onsale_info: event.data.offer_onsale_info,
    offer_presale_info: event.data.offer_presale_info,
    offer_notes: event.data.offer_notes,
    deal_notes: event.data.deal_notes,
    offer_percentage: event.data.offer_percentage || 0,
    production_adv: latestOffer?.production_adv || "production@mammothlive.com",
    local_marketing: latestOffer?.local_marketing || "",
    ticketing: latestOffer?.ticketing || "ticketing@mammothlive.com",
    tour_admat: latestOffer?.tour_admat || "",
    tour_marketing: latestOffer?.tour_marketing || "",
    onsale_info: latestOffer?.onsale_info || "",
    presale_info: latestOffer?.presale_info || "",
    contracts: latestOffer?.contracts || "contracts@mammothlive.com",
    buyers: event.data.buyers,
  };

  const EVENT_ACCOUNTING_REQUIRED_PARAMS = {
    concessions: event.data.concessions,
    merch: event.data.merch,
    rebate: event.data.rebate,
    charity: event.data.charity,
    facility_fee: event.data.facility_fee,
    drop_count: event.data.drop_count,
    platinum_lift: event.data.platinum_lift,
    vip_lift: event.data.vip_lift,
    has_ascap: event.data.has_ascap,
    has_bmi: event.data.has_bmi,
    has_insurance: event.data.has_insurance,
    has_sesac: event.data.has_sesac,
    has_credit_card_fees: event.data.has_credit_card_fees,
    credit_card_fees: event.data.credit_card_fees,
    has_ticket_commission: event.data.has_ticket_commission,
    ticket_commission: event.data.ticket_commission,
    insurance_amount: event.data.insurance_amount,
    offer_comp_tax: event.data.offer_comp_tax,
    offer_has_comp_tax: event.data.offer_has_comp_tax,
    offer_gmr: event.data.offer_gmr,
    offer_has_rent: event.data.offer_has_rent,
    hold_tm: event.data.hold_tm,
    hold_etix: event.data.hold_etix,
    hold_venue: event.data.hold_venue,
    hold_fan_club: event.data.hold_fan_club,
    hold_outlets: event.data.hold_outlets,
    hold_vip: event.data.hold_vip,
    mammoth_percentage: event.data.mammoth_percentage,
    building_percentage: event.data.building_percentage,
    who_sells_merch: event.data.who_sells_merch,
    artist_soft_merch_percentage: event.data.artist_soft_merch_percentage,
    artist_hard_merch_percentage: event.data.artist_hard_merch_percentage,
  };

  // Mutations defined at the top level now - no code needed here

  // Handler functions
  const handleVariablesToggle = (toggleData) => {
    console.log("Toggle data received:", toggleData);

    // Add the field to updated properties for animation
    setUpdatedProperties((prev) => [...prev, toggleData.accesor]);

    // Reset animation after timeout
    setTimeout(() => {
      setUpdatedProperties((prev) =>
        prev.filter((prop) => prop !== toggleData.accesor)
      );
    }, FIELD_UPDATE_ANIMATION_TIME);

    const onSuccess = () => {
      // The mutation's onSuccess handler will automatically invalidate queries
      console.log(`Toggle update succeeded for ${toggleData.accesor}`);
    };

    if (toggleData.accesor === "offer_comp_tax") {
      updateEventMutation.mutate(
        Object.assign(EVENT_REQUIRED_PARAMS, {
          offer_has_comp_tax: toggleData.state,
        }),
        { onSuccess }
      );
    } else if (toggleData.accesor === "offer_gmr") {
      updateEventMutation.mutate(
        Object.assign(EVENT_REQUIRED_PARAMS, {
          offer_has_gmr: toggleData.state,
        }),
        { onSuccess }
      );
    } else if (toggleData.accesor === "rent") {
      updateEventMutation.mutate(
        Object.assign(EVENT_REQUIRED_PARAMS, {
          offer_has_rent: toggleData.state,
        }),
        { onSuccess }
      );
    } else if (toggleData.accesor === "ascap") {
      updateEventMutation.mutate(
        Object.assign(EVENT_REQUIRED_PARAMS, { has_ascap: toggleData.state }),
        { onSuccess }
      );
    } else if (toggleData.accesor === "bmi") {
      updateEventMutation.mutate(
        Object.assign(EVENT_REQUIRED_PARAMS, { has_bmi: toggleData.state }),
        { onSuccess }
      );
    } else if (toggleData.accesor === "sesac") {
      updateEventMutation.mutate(
        Object.assign(EVENT_REQUIRED_PARAMS, { has_sesac: toggleData.state }),
        { onSuccess }
      );
    } else if (toggleData.accesor === "insurance_amount") {
      updateEventMutation.mutate(
        Object.assign(EVENT_REQUIRED_PARAMS, {
          has_insurance: toggleData.state,
        }),
        { onSuccess }
      );
    } else if (toggleData.accesor === "credit_card_fees") {
      updateEventMutation.mutate(
        Object.assign(EVENT_REQUIRED_PARAMS, {
          has_credit_card_fees: toggleData.state,
        }),
        { onSuccess }
      );
    } else if (toggleData.accesor === "ticket_commission") {
      updateEventMutation.mutate(
        Object.assign(EVENT_REQUIRED_PARAMS, {
          has_ticket_commission: toggleData.state,
        }),
        { onSuccess }
      );
    } else {
      console.warn("Unknown toggle field:", toggleData.accesor);
    }
  };

  const handleUpdateVenueDetails = (accessor, value) => {
    if (accessor === "venue_name") {
      const selectedVenue = venues.data.find((venue) => venue.name === value);
      if (selectedVenue) {
        updateEventMutation.mutate({
          ...EVENT_REQUIRED_PARAMS,
          venue_id: selectedVenue.id,
        });
      }
    }
  };

  const handleUpdateEvent = (accessor, value) => {
    updateEventMutation.mutate(
      Object.assign(EVENT_REQUIRED_PARAMS, { [accessor]: value })
    );
  };

  const handleChangeInput = (field, value, rowIndex, rowData) => {
    try {
      // Validate inputs before proceeding
      if (!rowData?.id) {
        console.error("Missing manifest ID");
        return;
      }

      const body = {
        manifest_id: rowData.id,
        name: rowData.name,
        qty: String(rowData.qty),
        price: String(rowData.price),
        on_sale: new Intl.DateTimeFormat("en-CA").format(
          new Date(rowData.on_sale)
        ),
        comps: String(rowData.comps),
        eventId: id,
        ticket_fees: String(amountStrToInt(rowData.ticket_fees)),
        sort_order: String(rowData.sort_order),
        [field]: value,
      };

      // Ensure all numeric values are strings and properly formatted
      body.qty = String(parseInt(body.qty, 10) || 0);
      body.comps = String(parseInt(body.comps, 10) || 0);
      body.ticket_fees = String(amountStrToInt(body.ticket_fees) || 0);
      body.sort_order = String(parseInt(body.sort_order, 10) || 0);

      // Handle the case where the field is one of the numeric fields
      if (["qty", "comps", "ticket_fees", "sort_order"].includes(field)) {
        body[field] = String(
          field === "ticket_fees"
            ? amountStrToInt(value) || 0
            : parseInt(value, 10) || 0
        );
      } else if (field === "price") {
        // Keep price as a string without converting
        body[field] = String(value);
      }

      updateEventManifest.mutate(body);
    } catch (error) {
      console.error("handleChangeInput error:", error);
      toast.error("Error processing manifest update");
    }
  };

  const handleAddNewManifest = (newManifest) => {
    addManifestMutation.mutate({
      name: newManifest.name,
      qty: newManifest.qty,
      price: newManifest.price,
      ticket_fees: newManifest.ticket_fees,
      eventId: id,
      active: true,
      is_offer: true,
      kills: 0,
    });
  };

  const handleDeleteManifest = (manifestId) => {
    removeManifestMutation.mutate(manifestId);
  };

  const handleCreatePDF = () => {
    const data = {
      name: "Offer",
      file_type: "offer",
      note: "",
    };
    createPDFMutation.mutate(data);
  };

  const handleViewPDF = () => {
    const lastGeneratedPDF = pdfs.data?.sort(
      (a, b) => new Date(b.created) - new Date(a.created)
    )[0];
    if (lastGeneratedPDF?.file_url) {
      window.open(lastGeneratedPDF.file_url, "_blank", "noopener,noreferrer");
    } else {
      toast.error("PDF not found or still generating");
    }
  };

  const getPDFButtonText = () => {
    if (isRefetchingPDF) {
      return (
        <>
          Generating... &nbsp;&nbsp;
          <div className="spinner"></div>
        </>
      );
    } else if (pdfs.data?.length > 0) return "Regenerate PDF";
    else return "Create Offer PDF";
  };

  const getDealText = () => {
    if (eventOfferForm.offer_type === "vs") {
      return `VERSUS ${eventOfferForm.offer_percentage}% of net receipts after approved expenses`;
    } else if (eventOfferForm.offer_type === "promoter-profit") {
      return `PLUS ${eventOfferForm.offer_percentage}% After split point.`;
    } else if (eventOfferForm.offer_type === "flat") {
      return `FLAT`;
    } else if (eventOfferForm.offer_type === "nbor") {
      return `${eventOfferForm.offer_percentage}% of NBOR`;
    }
  };

  // Manifest headers
  const manifestHeaders = [
    { title: "TICKETING", format: "text" },
    { title: "CAPACITY", format: "numbers" },
    { title: "NET PRICE", format: "money" },
    { title: "TICKET FEES", format: "money" },
    { title: "GROSS PRICE", format: "money", editable: false },
    { title: "POTENTIAL", format: "money", editable: false },
    { title: "ON SALE", format: "date" },
  ];

  return (
    <div className="fixed inset-0 text-cave-black bg-cave-white min-h-screen w-full overflow-auto flex flex-col">
      <style>{gridFixStyles}</style>
      <div>
        <BreadCrumbs
          links={[
            { text: "Events", url: `/events/` },
            {
              text: `${event.data.name} ${getEventDate}`,
              url: `/events/${event.data.id}`,
            },
            { text: "Edit: Offer" },
          ]}
        />
        <WalkoutPot
          walkout_potential={variables.data.walkout_potential}
          promoter_profit={variables.data.promoter_profit}
          the_deal_amount={variables.data.the_deal_amount}
          loading={variables.isFetching || event.isFetching}
          offer_type={variables.data.offer_type}
          dealHeader={dealHeader}
          split_point={variables.data.split_point}
          showHeader={showHeader}
          toggleHeader={toggleHeader}
          manifests={event.data.manifests}
          eventId={id}
          offerId={
            event.data.offers && event.data.offers.length > 0
              ? event.data.offers[0].id
              : null
          }
        />
      </div>
      <h3 className="text-2xl font-bold mb-4 text-black">
        Edit Offer - {event.data.name}
      </h3>
      <div className="py-6 w-full lg:min-w-[1500px]">
        <div className="flex flex-wrap lg:flex-nowrap gap-4">
          <div className="w-full lg:w-1/3 flex flex-col gap-4">
            <div className="flex flex-col items-center justify-start gap-1">
              <img
                src={"/static/images/mammoth-logo.png"}
                width={250}
                height={200}
                alt="Mammoth Logo"
              />
              <p className="font-montserrat text-sm text-black">
                1520 HASKELL AVE LAWRENCE, KS 66044
              </p>
              <p className="font-montserrat text-sm text-black mb-4">
                785.749.7475 PH | 785.865.4110 FX
              </p>
              <div className="flex flex-col gap-2 items-center mb-6">
                <div className="flex gap-2">
                  <Button onClick={handleCreatePDF} aria-label="Generate PDF">
                    {getPDFButtonText()}
                  </Button>
                </div>
                {pdfs.data?.length > 0 && (
                  <Button
                    disabled={isRefetchingPDF}
                    variant="secondary"
                    onClick={handleViewPDF}
                    aria-label="View Generated PDF"
                  >
                    View PDF
                  </Button>
                )}
                <div>
                  {event.isSuccess && <StateSwitcher eventId={event.data.id} />}
                </div>
                <div>
                  {event.isSuccess && <DateChanger eventId={event.data.id} />}
                </div>
              </div>

              <ExpenseAgGrid
                id={id}
                event={event}
                handleUpdateExpense={() => { }}
                displayExpenses={displayExpenses}
                advertisingExpenses={advertisingExpenses}
                totalAdvertisingExpenses={totalAdvertisingExpenses}
              />
            </div>
          </div>

          <div className="w-full lg:w-2/3">
            <div className="flex flex-wrap lg:flex-nowrap gap-4 w-full">
              <div className="w-full lg:w-1/2">
                <ArtistInfoAgGrid
                  event={event.data}
                  eventId={id}
                  buyerOptions={BUYER_OPTIONS}
                  artistOptions={
                    event.data?.artists?.map((a) => ({
                      value: a.id,
                      label: a.name,
                    })) || []
                  }
                  EVENT_REQUIRED_PARAMS={EVENT_REQUIRED_PARAMS}
                  EVENT_OFFER_REQUIRED_PARAMS={EVENT_OFFER_REQUIRED_PARAMS}
                  updatedProperties={updatedProperties}
                />
              </div>
              <div className="w-full lg:w-1/2 flex flex-col">
                <VenueInfoAgGrid
                  event={event.data}
                  eventId={id}
                  venue={venue.data}
                  venueOptions={VENUE_OPTIONS}
                  EVENT_REQUIRED_PARAMS={EVENT_REQUIRED_PARAMS}
                  handleUpdateVenueDetails={handleUpdateVenueDetails}
                />
                <AgeMerchAgGrid
                  event={event.data}
                  eventId={id}
                  ageOptions={AGE_OPTIONS}
                  whoSellsMerchOptions={WHO_SELLS_MERCH_OPTIONS}
                  EVENT_REQUIRED_PARAMS={EVENT_REQUIRED_PARAMS}
                  EVENT_ACCOUNTING_REQUIRED_PARAMS={
                    EVENT_ACCOUNTING_REQUIRED_PARAMS
                  }
                  updatedProperties={updatedProperties}
                />
              </div>
            </div>
            <div className="flex flex-wrap lg:flex-nowrap mt-4 gap-4">
              <div className="w-full">
                <ManifestAgGrid
                  event={event}
                  eventId={id}
                  rows={event.data.manifests}
                  headers={manifestHeaders}
                  onInputChange={handleChangeInput}
                  onDeletePress={handleDeleteManifest}
                  addRowButtonName="Add Manifest"
                  onAddNewRow={handleAddNewManifest}
                  hasChangeDetection={true}
                />
                <TemplateManager
                  venueId={event.data.venue.id}
                  onTemplateLoad={async (manifestData) => {
                    const toastId = toast.loading("Applying template...");
                    try {
                      // Remove existing manifests
                      const existingManifests = event.data?.manifests?.filter(
                        (m) => m.active === true && m.is_offer === true
                      );
                      // Remove existing manifests sequentially
                      for (const manifest of existingManifests) {
                        await removeManifestMutation.mutateAsync(manifest.id);
                      }
                      // Create new manifests sequentially
                      for (const item of manifestData) {
                        await addManifestMutation.mutateAsync({
                          ...item,
                          eventId: id,
                          active: true,
                          is_offer: true,
                          kills: 0,
                        });
                      }
                      toast.update(toastId, {
                        render: "Template applied successfully",
                        type: "success",
                        isLoading: false,
                        autoClose: 3000,
                      });
                    } catch (error) {
                      console.error("Error applying template:", error);
                      toast.update(toastId, {
                        render: "Failed to apply template",
                        type: "error",
                        isLoading: false,
                        autoClose: 3000,
                      });
                    }
                  }}
                />
                <div className="mt-4 flex flex-row gap-4">
                  <div className="w-full lg:w-1/2">
                    <CapacityAgGrid
                      event={event.data}
                      variables={variables.data}
                      updatedProperties={updatedProperties}
                    />
                    <div className="mt-4">
                      <DealTypeAgGrid
                        isLoading={variables.isFetching || event.isFetching}
                        variables={variables.data}
                        event={event.data}
                        updatedProperties={updatedProperties}
                      />
                    </div>
                  </div>
                  <div className="w-full lg:w-1/2">
                    <DealAgGrid
                      dealAmount={amountDisplay(variables.data.the_deal_amount)}
                      dealDescription={getDealText()}
                      event={event.data}
                      loading={variables.isFetching || event.isFetching}
                      isChanged={updatedProperties.includes("the_deal_amount")}
                      dealNotes={event.data.deal_notes}
                      eventId={id}
                      onNotesChange={(value) =>
                        handleUpdateEvent("deal_notes", value)
                      }
                    />

                    <WalkoutPotentialAgGrid
                      variables={variables.data}
                      event={event.data}
                      eventId={id}
                      eventOfferTypeOptions={EVENT_OFFER_TYPE_OPTIONS}
                      EVENT_OFFER_REQUIRED_PARAMS={EVENT_OFFER_REQUIRED_PARAMS}
                      updatedProperties={updatedProperties}
                    />
                  </div>
                </div>
                <div className="mt-4">
                  <ContactAgGrid
                    eventID={id}
                    eventData={event.data}
                    EVENT_OFFER_REQUIRED_PARAMS={EVENT_OFFER_REQUIRED_PARAMS}
                  />
                </div>
                <div className="mt-2 flex flex-wrap lg:flex-nowrap gap-4">
                  <div className="w-full lg:w-1/2">
                    <VariablesAgGrid
                      variables={variables.data}
                      event={event.data}
                      eventId={id}
                      EVENT_REQUIRED_PARAMS={EVENT_REQUIRED_PARAMS}
                      EVENT_ACCOUNTING_REQUIRED_PARAMS={
                        EVENT_ACCOUNTING_REQUIRED_PARAMS
                      }
                      handleVariablesToggle={handleVariablesToggle}
                    />
                  </div>
                  <div className="w-full lg:w-1/2 flex flex-col">
                    <TextAreaAgGrid
                      header={"NOTES"}
                      value={event.data.offer_notes}
                      onInputChange={(accessor, value) =>
                        handleUpdateEvent("offer_notes", value)
                      }
                    />
                    <TimesAgGrid
                      event={event.data}
                      eventId={id}
                      EVENT_REQUIRED_PARAMS={EVENT_REQUIRED_PARAMS}
                    />
                  </div>
                </div>
                <div className="mt-4 flex flex-wrap lg:flex-nowrap gap-4">
                  <div className="w-full lg:w-1/2"></div>
                </div>
              </div>
            </div>
          </div>
        </div>
      </div>
    </div>
  );
};

export default NewEventEditOffer;
export { NewEventEditOffer };
