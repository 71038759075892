import { ArrowsUpDownIcon } from "@heroicons/react/24/outline";
import { useQueryClient, useMutation } from "@tanstack/react-query";
import { AgGridReact } from "ag-grid-react";
import React, {
  useState,
  useEffect,
  useMemo,
  useCallback,
  useRef,
} from "react";
import { toast } from "react-toastify";

import {
  createEventManifest,
  deleteEventManifest,
  reorderManifests,
  patchManifest,
} from "@/queries/events";
import {
  useManifestStore,
  FIELD_UPDATE_ANIMATION_TIME,
} from "@/store/manifestStore";
import { moneyColumnConfig, registerMoneyEditor } from "@/utils/ag-grid";
import { amountDisplay, formatDisplayToCents } from "@/utils/money";

function formatDateForSubmit(input) {
  if (!input) return null;

  const regex = /^\d{4}-\d{2}-\d{2}$/;
  if (typeof input === "string" && regex.test(input)) {
    return input;
  }

  const dateRegex = /^(\d{1,2})\/(\d{1,2})\/(\d{4})$/;
  if (typeof input === "string" && dateRegex.test(input)) {
    const matches = input.match(dateRegex);
    const month = matches[1].padStart(2, "0");
    const day = matches[2].padStart(2, "0");
    const year = matches[3];
    return `${year}-${month}-${day}`;
  }

  const date = new Date(input);
  if (isNaN(date.getTime())) return null;

  const year = date.getFullYear();
  let month = date.getMonth() + 1;
  let day = date.getDate();

  if (month < 10) month = "0" + month;
  if (day < 10) day = "0" + day;

  return `${year}-${month}-${day}`;
}

function formatDateForDisplay(dateString) {
  if (!dateString) return "";

  try {
    // Handle potential different formats
    if (typeof dateString === "object" && dateString instanceof Date) {
      return dateString.toLocaleDateString();
    }

    if (typeof dateString !== "string") {
      return String(dateString);
    }

    // For ISO format YYYY-MM-DD
    const [year, month, day] = dateString.split("-").map(Number);

    // Validate the date components
    if (!year || !month || !day || isNaN(year) || isNaN(month) || isNaN(day)) {
      return dateString; // If any component is invalid, return the original string
    }

    const date = new Date(year, month - 1, day);

    // Check if date is valid
    if (isNaN(date.getTime())) {
      return dateString;
    }

    return date.toLocaleDateString();
  } catch (e) {
    // If all parsing fails, at least return the input rather than nothing
    return dateString;
  }
}

const ManifestAgGrid = ({ rows: rowsProp, eventId }) => {
  const gridRef = useRef(null);
  const queryClient = useQueryClient();

  const {
    manifests,
    initializeManifests,
    patchManifestField,
    addManifest,
    removeManifest,
    updateManifests,
  } = useManifestStore();

  const [changedCells, setChangedCells] = useState([]);
  const [isDragging, setIsDragging] = useState(false);
  const [deletingRowId, setDeletingRowId] = useState(null);
  const [reorderLoading, setReorderLoading] = useState(false);
  const lastRowsRef = useRef("");

  useEffect(() => {
    if (!rowsProp || rowsProp.length === 0) return;
    if (manifests.length) return;
    const stringifiedRows = JSON.stringify(rowsProp);
    if (stringifiedRows !== lastRowsRef.current) {
      const processed = rowsProp
        .map((row, index) => ({
          ...row,
          sort_order: row.sort_order || index + 1,
          id: row.id || `row-${index + 1}`,
          eventId: row.eventId,
          manifest_id: row.manifest_id || row.id,
          ticketing: row.name,
          capacity: row.qty,
          net_price: row.price,
          name: row.name,
          qty: row.qty,
          price: row.price,
          on_sale: row.on_sale ? formatDateForSubmit(row.on_sale) : null,
          comps: row.comps || 0,
          ticket_fees: row.ticket_fees || 0,
          gross_price: Number(row.price) + Number(row.ticket_fees || 0),
          potential:
            (Number(row.price) + Number(row.ticket_fees || 0)) *
            (Number(row.qty || 0) - Number(row.comps || 0)),
          kills: row.kills || 0,
          is_offer: row.is_offer === undefined ? true : row.is_offer,
        }))
        .sort((a, b) => a.sort_order - b.sort_order);
      initializeManifests(processed);
      lastRowsRef.current = stringifiedRows;
    }
  }, [rowsProp, initializeManifests, manifests.length]);

  const invalidateQueries = useCallback(() => {
    queryClient.invalidateQueries(["event-detail", eventId]);
    queryClient.invalidateQueries(["variables-offer-pdf", eventId]);
    queryClient.invalidateQueries(["expenses-offer-pdf", eventId]);
    queryClient.invalidateQueries(["event-rollups", eventId]);
    queryClient.invalidateQueries(["manifests", eventId]);
  }, [queryClient, eventId]);

  const patchManifestMutation = useMutation({
    mutationFn: async (data) => {
      const { id, field, value } = data;
      const patchData = { [field]: value };
      return await patchManifest(id, patchData);
    },
    onSuccess: () => {
      invalidateQueries();
    },
    onError: (error) => {
      console.error("Failed to patch manifest:", error);
      toast.error("Failed to update manifest field");
    },
  });

  const createManifestMutation = useMutation({
    mutationFn: async (data) => {
      const manifestData = {
        ...data,
        eventId,
        is_offer: true,
        price: data.price.toString().includes(".")
          ? formatDisplayToCents(data.price).toString()
          : data.price.toString(),
        ticket_fees: data.ticket_fees.toString().includes(".")
          ? formatDisplayToCents(data.ticket_fees).toString()
          : data.ticket_fees.toString(),
      };
      return await createEventManifest(eventId, manifestData);
    },
    onSuccess: () => {
      invalidateQueries();
      toast.success("New manifest created");
    },
  });

  const deleteManifestMutation = useMutation({
    mutationFn: async (manifestId) => {
      return await deleteEventManifest(eventId, manifestId);
    },
    onSuccess: () => {
      invalidateQueries();
      toast.success("Manifest deleted");
    },
    onError: (error) => {
      console.error("Failed to delete manifest:", error);
      toast.error("Failed to delete manifest");
    },
  });

  const batchUpdateManifestsMutation = useMutation({
    mutationFn: async (rows) => {
      const manifestIds = rows.map((row) => row.manifest_id);
      return await reorderManifests(eventId, { manifest_ids: manifestIds });
    },
    onSuccess: () => {
      invalidateQueries();
      toast.success("Manifests reordered successfully");
    },
    onError: (error) => {
      console.error("Failed to reorder manifests:", error);
      toast.error("Failed to reorder manifests");
    },
  });

  const onCellValueChanged = useCallback(
    (params) => {
      if (params.oldValue === params.newValue) return;
      const cellKey = `${params.node.rowIndex}-${params.column.colId}`;
      setChangedCells((prev) => [...prev, cellKey]);
      setTimeout(() => {
        setChangedCells((prev) => prev.filter((cell) => cell !== cellKey));
      }, FIELD_UPDATE_ANIMATION_TIME);

      if (!params.data) return;
      let field;
      let valueToSend = params.newValue;
      switch (params.column.colId) {
        case "name":
        case "ticketing":
          field = "name";
          break;
        case "qty":
        case "capacity":
          field = "qty";
          break;
        case "price":
        case "net_price":
          field = "price";
          break;
        case "ticket_fees":
          field = "ticket_fees";
          break;
        case "comps":
          field = "comps";
          break;
        case "on_sale":
          field = "on_sale";
          const formatted = formatDateForSubmit(params.newValue);
          if (!formatted) return;
          valueToSend = formatted;

          // Update the cell value immediately in the local grid data
          // This ensures the date is displayed correctly without requiring a refresh
          if (gridRef.current && gridRef.current.api) {
            const rowNode = gridRef.current.api.getRowNode(params.data.id);
            if (rowNode) {
              rowNode.setDataValue("on_sale", formatted);
            }
          }
          break;
        default:
          return;
      }
      if (!field) return;

      // Update local state for temp rows
      if (String(params.data.id).startsWith("temp-")) {
        // For temporary rows, update the value in the manifestStore
        if (field === "on_sale") {
          patchManifestField(params.data.id, field, valueToSend);
        }
        return;
      }

      patchManifestMutation.mutate(
        {
          id: params.data.manifest_id,
          field,
          value: valueToSend,
        },
        {
          onSuccess: () => {
            // For successful updates, ensure the local state is also updated
            patchManifestField(params.data.id, field, valueToSend);
          },
          onError: (error) => {
            if (field !== "on_sale") {
              toast.error("Failed to update manifest field");
            }
          },
        }
      );
    },
    [patchManifestMutation, patchManifestField]
  );

  const onRowDragStart = useCallback(() => {
    setIsDragging(true);
  }, []);

  const onRowDragEnd = useCallback(() => {
    setIsDragging(false);
    if (gridRef.current && gridRef.current.api) {
      const allRows = gridRef.current.api.getModel().rowsToDisplay;
      const dataFromAllRows = allRows.map((row) => row.data);
      const reordered = dataFromAllRows.map((row, index) => ({
        ...row,
        sort_order: index + 1,
      }));
      updateManifests(reordered);
      setReorderLoading(true);
      gridRef.current.api.showLoadingOverlay();
      batchUpdateManifestsMutation.mutate(reordered, {
        onSuccess: () => {
          setReorderLoading(false);
          gridRef.current.api.hideOverlay();
          invalidateQueries();
        },
        onError: () => {
          setReorderLoading(false);
          gridRef.current.api.hideOverlay();
        },
      });
    }
  }, [updateManifests, batchUpdateManifestsMutation, invalidateQueries]);

  const handleDeleteInitiate = useCallback((rowId) => {
    setDeletingRowId(rowId);
  }, []);

  const handleDeleteConfirm = useCallback(
    (rowId) => {
      const rowToDelete = manifests.find((row) => row.id === rowId);
      if (!rowToDelete) return;
      if (String(rowToDelete.id).startsWith("temp-")) {
        removeManifest(rowId);
        setDeletingRowId(null);
        return;
      }
      deleteManifestMutation.mutate(rowToDelete.manifest_id || rowId);
      removeManifest(rowId);
      setDeletingRowId(null);
    },
    [manifests, removeManifest, deleteManifestMutation]
  );

  const handleDeleteCancel = useCallback(() => {
    setDeletingRowId(null);
  }, []);

  const handleAddRow = useCallback(() => {
    const tempId = `temp-${Date.now()}`;
    const newRow = {
      id: tempId,
      is_offer: true,
      name: "",
      qty: 0,
      price: "0",
      ticket_fees: "0",
      comps: 0,
      on_sale: null,
      sort_order: manifests.length + 1,
      gross_price: amountDisplay(0),
      potential: amountDisplay(0),
      eventId,
      manifest_id: tempId,
      ticketing: "",
      capacity: 0,
      net_price: "0",
      kills: 0,
    };
    addManifest(newRow);
    setTimeout(() => {
      if (gridRef.current && gridRef.current.api) {
        gridRef.current.api.ensureIndexVisible(manifests.length);
        gridRef.current.api.setFocusedCell(manifests.length, "name");
      }
    }, 100);
  }, [manifests.length, addManifest, eventId]);

  const handleSaveRow = useCallback(
    (tempRowData) => {
      try {
        const manifestData = {
          name: tempRowData.name || "New Manifest",
          event: eventId,
          qty: Number(tempRowData.qty) || 0,
          price: tempRowData.price || "0",
          ticket_fees: tempRowData.ticket_fees || "0",
          comps: Number(tempRowData.comps) || 0,
          on_sale: tempRowData.on_sale
            ? formatDateForSubmit(tempRowData.on_sale)
            : null,
          sort_order: tempRowData.sort_order,
          active: true,
          is_offer: true,
        };

        // Log the data being sent to help debug
        console.log("Saving manifest with data:", manifestData);

        createManifestMutation.mutate(manifestData, {
          onSuccess: (data) => {
            // Process the returned data to ensure date formats are consistent
            const processedData = {
              ...data,
              on_sale: data.on_sale ? formatDateForSubmit(data.on_sale) : null,
            };

            removeManifest(tempRowData.id);
            addManifest(processedData);

            // Force grid to refresh this row
            if (gridRef.current && gridRef.current.api) {
              setTimeout(() => {
                gridRef.current.api.refreshCells({ force: true });
              }, 100);
            }
          },
          onError: (error) => {
            console.error("Error saving manifest:", error);
            toast.error("Failed to save manifest");
          },
        });
      } catch (error) {
        toast.error("Failed to save manifest");
        console.error("Error saving manifest:", error);
      }
    },
    [createManifestMutation, eventId, removeManifest, addManifest]
  );

  const dragHandleCol = useMemo(
    () => ({
      rowDrag: true,
      width: 40,
      maxWidth: 40,
      lockPosition: "left",
      suppressMenu: true,
      suppressSizeToFit: true,
      cellRenderer: (params) => {
        if (String(params.data.id).startsWith("temp-")) return "";
        return (
          <div className="flex h-full items-center justify-center cursor-grab active:cursor-grabbing">
            <ArrowsUpDownIcon className="w-4 h-4 text-gray-500" />
          </div>
        );
      },
    }),
    []
  );

  const actionCol = useMemo(
    () => ({
      field: "actions",
      headerName: "",
      width: 125,
      maxWidth: 125,
      suppressSizeToFit: true,
      suppressMovable: true,
      lockPosition: "right",
      editable: false,
      cellRenderer: (params) => {
        if (String(params.data.id).startsWith("temp-")) {
          return (
            <div className="flex justify-end gap-1 p-1 text-sm font-montserrat">
              <button
                className="bg-green-500 hover:bg-green-600 text-white py-1 px-2 text-xs rounded focus:ring-2 focus:ring-green-600"
                onClick={() => handleSaveRow(params.data)}
              >
                Save
              </button>
              <button
                className="bg-gray-400 hover:bg-gray-600 text-white py-1 px-2 text-xs rounded focus:ring-2 focus:ring-gray-600"
                onClick={() => handleDeleteConfirm(params.data.id)}
              >
                Cancel
              </button>
            </div>
          );
        }
        return (
          <div className="flex justify-end gap-1 p-1 text-sm font-montserrat">
            {deletingRowId === params.data.id ? (
              <>
                <button
                  className="bg-red-400 hover:bg-red-600 text-white py-1 px-2 text-xs rounded"
                  onClick={() => handleDeleteConfirm(params.data.id)}
                >
                  Ok
                </button>
                <button
                  className="bg-gray-400 hover:bg-gray-600 text-white py-1 px-2 text-xs rounded"
                  onClick={handleDeleteCancel}
                >
                  Cancel
                </button>
              </>
            ) : (
              <button
                className="bg-red-400 hover:bg-red-600 text-white py-1 px-2 text-xs rounded"
                onClick={() => handleDeleteInitiate(params.data.id)}
              >
                Delete
              </button>
            )}
          </div>
        );
      },
    }),
    [
      deletingRowId,
      handleSaveRow,
      handleDeleteConfirm,
      handleDeleteCancel,
      handleDeleteInitiate,
    ]
  );

  const columnDefs = useMemo(
    () => [
      dragHandleCol,
      {
        field: "name",
        headerName: "TICKETING",
        editable: true,
        flex: 2,
        minWidth: 160,
        cellRenderer: (params) =>
          params.value || <span className="text-gray-300">Manifest Name</span>,
        cellClass: (params) => {
          const classes = [];
          if (
            changedCells.includes(
              `${params.node.rowIndex}-${params.column.colId}`
            )
          ) {
            classes.push("glow-text");
          }
          if (isDragging) {
            classes.push("opacity-70");
          }
          return classes.join(" ");
        },
      },
      {
        field: "qty",
        headerName: "CAPACITY",
        editable: true,
        flex: 1,
        minWidth: 80,
        cellClass: (params) => {
          const classes = [];
          if (
            changedCells.includes(
              `${params.node.rowIndex}-${params.column.colId}`
            )
          ) {
            classes.push("glow-text");
          }
          if (isDragging) {
            classes.push("opacity-70");
          }
          return classes.join(" ");
        },
      },
      {
        field: "comps",
        headerName: "COMPS",
        editable: true,
        flex: 1,
        minWidth: 70,
        cellClass: (params) => {
          const classes = [];
          if (
            changedCells.includes(
              `${params.node.rowIndex}-${params.column.colId}`
            )
          ) {
            classes.push("glow-text");
          }
          if (isDragging) {
            classes.push("opacity-70");
          }
          return classes.join(" ");
        },
      },
      {
        field: "price",
        headerName: "NET PRICE",
        editable: true,
        flex: 1,
        minWidth: 100,
        isMoney: true,
        ...moneyColumnConfig,
        cellClass: (params) => {
          const classes = [];
          if (
            changedCells.includes(
              `${params.node.rowIndex}-${params.column.colId}`
            )
          ) {
            classes.push("glow-text");
          }
          if (isDragging) {
            classes.push("opacity-70");
          }
          return classes.join(" ");
        },
      },
      {
        field: "ticket_fees",
        headerName: "TICKET FEES",
        editable: true,
        flex: 1,
        minWidth: 100,
        isMoney: true,
        ...moneyColumnConfig,
        cellClass: (params) => {
          const classes = [];
          if (
            changedCells.includes(
              `${params.node.rowIndex}-${params.column.colId}`
            )
          ) {
            classes.push("glow-text");
          }
          if (isDragging) {
            classes.push("opacity-70");
          }
          return classes.join(" ");
        },
      },
      {
        field: "gross_price",
        headerName: "GROSS PRICE",
        editable: false,
        flex: 1,
        minWidth: 100,
        valueFormatter: (params) => amountDisplay(params.value),
        cellClass: (params) => (isDragging ? "opacity-70" : ""),
      },
      {
        field: "potential",
        headerName: "POTENTIAL",
        editable: false,
        flex: 1,
        minWidth: 100,
        isMoney: true,
        valueFormatter: (params) => amountDisplay(params.value),
        cellClass: (params) => (isDragging ? "opacity-70" : ""),
      },
      {
        field: "on_sale",
        headerName: "ON SALE",
        editable: true,
        flex: 1,
        minWidth: 100,
        cellEditor: "agDateCellEditor",
        valueParser: (params) => {
          // Handle intermediate editing states more robustly
          if (!params.newValue) return null;
          const formatted = formatDateForSubmit(params.newValue);
          return formatted;
        },
        valueFormatter: (params) => {
          // Safely format the date or return empty string
          if (!params.value) return "";
          return formatDateForDisplay(params.value);
        },
        cellRenderer: (params) => {
          // If value is truly empty, show placeholder
          if (!params.value)
            return <span className="text-gray-300">On Sale</span>;

          // Otherwise format and show the date
          const formatted = formatDateForDisplay(params.value);

          // If we have a value but it can't be formatted, return the raw value
          // so at least something shows
          return formatted || params.value;
        },
        cellClass: (params) => {
          const classes = [];
          if (
            changedCells.includes(
              `${params.node.rowIndex}-${params.column.colId}`
            )
          ) {
            classes.push("glow-text");
          }
          if (isDragging) {
            classes.push("opacity-70");
          }
          return classes.join(" ");
        },
      },
      actionCol,
    ],
    [dragHandleCol, actionCol, changedCells, isDragging]
  );

  const defaultColDef = useMemo(
    () => ({
      resizable: true,
      sortable: false,
      filter: false,
    }),
    []
  );

  const gridOptions = useMemo(
    () => ({
      rowDragManaged: true,
      animateRows: false,
      suppressMoveWhenRowDragging: false,
      getRowId: (params) => params.data.id?.toString(),
    }),
    []
  );
  registerMoneyEditor(gridOptions);

  const onGridReady = useCallback((params) => {
    params.api.sizeColumnsToFit();
    const resizeObserver = new ResizeObserver(() => {
      setTimeout(() => {
        params.api.sizeColumnsToFit();
      }, 100);
    });
    const gridElement = document.querySelector(".ag-theme-alpine");
    if (gridElement) {
      resizeObserver.observe(gridElement);
    }
    resizeObserver.observe(document.body);
  }, []);

  if (!rowsProp) return <div>Loading...</div>;

  return (
    <>
      <div className="w-full grid border border-dashed border-gray-300 bg-gray-100">
        <div
          className={`ag-theme-alpine ${isDragging ? "dragging" : ""}`}
          style={{ width: "100%", minHeight: "200px" }}
        >
          <AgGridReact
            ref={gridRef}
            rowData={manifests}
            columnDefs={columnDefs}
            defaultColDef={defaultColDef}
            onCellValueChanged={onCellValueChanged}
            onRowDragStart={onRowDragStart}
            onRowDragEnd={onRowDragEnd}
            onGridReady={onGridReady}
            loading={reorderLoading}
            singleClickEdit
            rowDragMultiRow={false}
            domLayout="autoHeight"
            suppressRowHoverHighlight={isDragging}
            stopEditingWhenCellsLoseFocus
            getRowClass={(params) =>
              String(params.data.id).startsWith("temp-")
                ? "bg-green-50"
                : isDragging
                  ? "opacity-80 transition-transform duration-200"
                  : ""
            }
            getRowId={(params) => params.data.id}
            immutableData={true}
            suppressChangeDetection={true}
            suppressCellFlash={true}
            rowHeight={35}
            suppressRowTransform={true}
            suppressMovableColumns={true}
            rowDragManaged={true}
            {...gridOptions}
          />
        </div>
      </div>
      <button
        className="bg-gray-400 hover:bg-gray-500 rounded px-4 py-1 text-white font-sans text-lg mt-2"
        onClick={handleAddRow}
      >
        Add Manifest
      </button>
    </>
  );
};

export default ManifestAgGrid;
