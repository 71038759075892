import React, { useState } from "react";
import { useQuery, useQueryClient, useMutation } from "@tanstack/react-query";
import { getMe, userQuerySettings, logoutUser } from "@/queries/auth";
import { UserMenuItem } from "./UserMenuItem";
import { UserIcon } from "@heroicons/react/24/outline";
import { useUserStore } from "@/store/userStore";
import { USER_ROLES } from "@/types/user";
import { getRoleDisplayName } from "@/helpers";

export const UserMenu = () => {
  const queryClient = useQueryClient();
  const { data: user, isLoading } = useQuery(["me"], getMe, userQuerySettings);
  const { roleImpersonating, impersonateRole, resetImpersonation } =
    useUserStore();
  const [isOpen, setIsOpen] = useState(false);

  const logoutMutation = useMutation({
    mutationFn: () => {
      console.log("---> logging out user", user?.email);
      return logoutUser();
    },
    onSuccess: async () => {
      queryClient.invalidateQueries(["me"]);
      window.location.href = "/";
    },
  });

  if (isLoading) {
    return (
      <div className="text-center text-gray-700 dark:text-gray-300">
        Loading...
      </div>
    );
  }

  // Only allow role impersonation if the user is an admin.
  const isAdmin = user?.role === "admin";

  return (
    <>
      <button
        type="button"
        className="flex mx-3 text-sm bg-gray-800 rounded-full md:mr-0 focus:ring-4 focus:ring-gray-300 dark:focus:ring-gray-600"
        id="userMenuDropdownButton"
        aria-expanded="false"
        onClick={() => setIsOpen(!isOpen)}
      >
        <span className="sr-only">Open user menu</span>
        <UserIcon
          className="h-7 w-7 text-gray-500 bg-gray-300 rounded-xl p-1"
          aria-hidden="true"
        />
      </button>
      {isOpen && (
        <div
          className="absolute z-[10000] top-0 right-1 w-48 py-2 mt-2 origin-top-right bg-cave-white border border-gray-200 rounded-md shadow-lg dark:bg-gray-800 dark:border-gray-700"
          onMouseLeave={() => setIsOpen(false)}
        >
          <div className="py-3 px-4">
            <span className="block text-sm font-semibold text-cave-black dark:text-cave-white">
              {user.first_name} {user.last_name}
            </span>
            <span className="block text-sm font-light text-gray-500 dark:text-gray-400">
              {user.email}
            </span>
            {isAdmin && (
              <div className="mt-2">
                <span className="block text-xs font-medium text-gray-500 dark:text-gray-400">
                  Role Impersonation:
                </span>
                <select
                  value={roleImpersonating || ""}
                  onChange={(e) => {
                    const role = e.target.value;
                    if (role) {
                      impersonateRole(role);
                    } else {
                      resetImpersonation();
                    }
                  }}
                  className="mt-1 block w-full text-sm bg-white dark:bg-gray-700 border border-gray-300 rounded-md shadow-sm focus:border-blue-500 focus:ring-blue-500 text-white"
                >
                  {
                    Object.entries(USER_ROLES).sort((a, b) => {
                      if (a[1] === USER_ROLES.ADMIN) {
                        return -1;
                      }
                      if (b[1] === USER_ROLES.ADMIN) {
                        return 1;
                      }
                      return a[1].localeCompare(b[1]);
                    }).map(([key, value]) => (
                      <option key={key} value={value}>{getRoleDisplayName(value)}</option>
                    ))
                  }
                </select>
              </div>
            )}
          </div>
          <ul className="py-1 font-light text-gray-500 dark:text-gray-400">
            <UserMenuItem onClick={() => logoutMutation.mutate()}>
              Sign Out
            </UserMenuItem>
          </ul>
        </div>
      )}
    </>
  );
};
