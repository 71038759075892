import { useQueryClient } from "@tanstack/react-query";
import { AgGridReact } from "ag-grid-react";
import { useState, useEffect, useCallback, useMemo, useRef } from "react";

import { offerEditableBgClass, offerReadOnlyBgClass } from "@/constants/offer";
import { FIELD_UPDATE_ANIMATION_TIME } from "@/hooks/useGlowEffect";
import { useEventOfferStore } from "@/store/eventOfferStore";
import { amountDisplay } from "@/utils/money";

const WalkoutPotentialAgGrid = ({
  variables,
  event,
  eventId,
  eventOfferTypeOptions,
  updatedProperties,
}) => {
  const queryClient = useQueryClient();
  const [flashFields, setFlashFields] = useState({});
  const gridRef = useRef(null);

  // Get store functions
  const {
    eventOfferForm,
    initializeEventOfferForm,
    patchOfferField,
    patchEventField,
    modifiedFields,
    isFieldPending,
    isFieldModified,
    savedFields,
    pendingFields,
  } = useEventOfferStore();

  // Get offer ID from event
  const offerId = event?.offers?.[0]?.id;

  // Initialize the store with event data
  useEffect(() => {
    if (
      (event && !eventOfferForm.offer_type) ||
      !eventOfferForm.offer_percentage
    ) {
      initializeEventOfferForm({
        offer_type: event.offer_type || "",
        offer_percentage: event.offer_percentage || 0,
      });
    }
  }, [initializeEventOfferForm]);

  // Flash effect for updated fields
  const flashField = useCallback((fieldKey) => {
    setFlashFields((prev) => ({ ...prev, [fieldKey]: true }));
    setTimeout(() => {
      setFlashFields((prev) => {
        const copy = { ...prev };
        delete copy[fieldKey];
        return copy;
      });
    }, FIELD_UPDATE_ANIMATION_TIME);
  }, []);

  // Create row data from store and variables
  const rowData = useMemo(() => {
    if (!variables || !event) return [];

    return [
      {
        header: "EVENT OFFER TYPE",
        value: eventOfferForm.offer_type,
        field: "offer_type",
        options: eventOfferTypeOptions,
        editable: true,
        format: "select",
        isFlashing: isFieldModified("offer_type"),
      },
      {
        header: "THE DEAL AMOUNT",
        value: variables.the_deal_amount
          ? amountDisplay(Number(variables.the_deal_amount))
          : "$0.00",
        editable: false,
      },
      {
        header: "WALKOUT PERCENTAGE",
        value: variables.walkout_percentage,
        editable: false,
      },
      {
        header: "OFFER PERCENTAGE",
        value: eventOfferForm.offer_percentage,
        field: "offer_percentage",
        editable: true,
        format: "percentage",
        isFlashing: isFieldModified("offer_percentage"),
      },
    ];
  }, [variables, event, eventOfferForm, eventOfferTypeOptions, flashFields]);

  // Handle field updates through the store
  const handleFieldUpdate = useCallback(
    async (field, value) => {
      const dbValue =
        eventOfferTypeOptions.find((opt) => opt.label === value)?.value ||
        value;
      if (field === "offer_percentage") {
        const numValue = parseInt(value);
        if (!isNaN(numValue)) {
          const clampedValue = Math.min(100, Math.max(0, numValue));
          await patchOfferField(eventId, field, clampedValue);
          flashField(field);
        }
      } else if (field === "offer_type") {
        await patchOfferField(eventId, field, dbValue);
        flashField(field);
      }
      const queryClientEventId = eventId?.toString();
      queryClient.invalidateQueries(["event", queryClientEventId]);
      queryClient.invalidateQueries(["expenses-offer-pdf", queryClientEventId]);
      queryClient.invalidateQueries(["event-rollups", queryClientEventId]);
      queryClient.invalidateQueries([
        "variables-offer-pdf",
        queryClientEventId,
      ]);
      queryClient.invalidateQueries(["event-detail", queryClientEventId]);
      queryClient.invalidateQueries(["event-detail", eventId]);
    },
    [offerId, patchOfferField, flashField]
  );

  const getCellClass = useCallback(
    (params) => {
      const initialClass = params.data.editable
        ? offerEditableBgClass
        : offerReadOnlyBgClass;
      return `${initialClass} ${params.data.isFlashing ? "glow-text" : ""}`;
    },
    [modifiedFields]
  );

  const onCellValueChanged = useCallback(
    (params) => {
      if (params.data.field) {
        handleFieldUpdate(params.data.field, params.newValue);
      }
    },
    [handleFieldUpdate]
  );

  const cellEditorSelector = useCallback((params) => {
    if (params.data?.format === "select") {
      return {
        component: "agSelectCellEditor",
        params: {
          values: params.data.options?.map((option) => option.label),
        },
      };
    }
    return { component: "agTextCellEditor" };
  }, []);

  const columnDefs = useMemo(
    () => [
      {
        field: "header",
        flex: 1,
        width: 250,
        editable: false,
        cellStyle: {
          fontFamily: "Montserrat, sans-serif",
          fontWeight: "bold",
          backgroundColor: "#E5E7EB",
        },
      },
      {
        field: "value",
        flex: 1,
        editable: (params) => params.data.editable !== false,
        cellEditorSelector,
        cellRenderer: (params) => {
          if (params.data.format === "percentage") {
            return `${params.value}%`;
          }
          return params.value;
        },
        valueGetter: (params) => {
          if (params.data.format === "select" && params.data.options) {
            const found = params.data.options.find(
              (opt) => opt.value === params.data.value
            );
            return found ? found.label : params.data.value;
          }
          return params.data.value;
        },
        onCellValueChanged,
        cellClass: getCellClass,
      },
    ],
    [getCellClass, onCellValueChanged, cellEditorSelector]
  );

  const defaultColDef = useMemo(
    () => ({
      resizable: true,
      sortable: false,
      filter: false,
    }),
    []
  );

  const onGridReady = useCallback((params) => {
    gridRef.current = params;
    params.api.sizeColumnsToFit();

    const resizeObserver = new ResizeObserver(() => {
      setTimeout(() => {
        params.api.sizeColumnsToFit();
      }, 100);
    });

    const gridElement = document.querySelector(".ag-theme-alpine");
    if (gridElement) {
      resizeObserver.observe(gridElement);
    }
    resizeObserver.observe(document.body);

    return () => resizeObserver.disconnect();
  }, []);

  return (
    <div className="w-full border-4 border-gray-300">
      <div className="bg-gray-300 text-xl font-extrabold text-center py-2 px-2 font-montserrat">
        WALK-OUT POTENTIAL
      </div>
      <div
        className="ag-theme-alpine"
        style={{ height: "auto", width: "100%" }}
      >
        <AgGridReact
          ref={gridRef}
          rowData={rowData}
          columnDefs={columnDefs}
          defaultColDef={defaultColDef}
          singleClickEdit
          stopEditingWhenCellsLoseFocus
          onGridReady={onGridReady}
          domLayout="autoHeight"
          headerHeight={0}
          rowHeight={40}
          getRowId={(params) => params.data.header}
          className="walkout-grid"
        />
      </div>
      <style jsx>{`
        :global(.walkout-grid .ag-cell) {
          font-family: "Montserrat", sans-serif;
          padding: 8px 5px;
          border-bottom: 1px solid #e5e7eb;
        }
        :global(.walkout-grid .ag-row:last-child .ag-cell) {
          border-bottom: none;
        }
        :global(.walkout-grid .ag-cell:first-of-type) {
          background-color: #e5e7eb;
          font-weight: bold;
        }
        :global(.walkout-grid .ag-cell:last-of-type) {
          background-color: #93c5fd;
          text-align: right;
          font-weight: bold;
        }
      `}</style>

      <div className="w-full bg-gray-300 text-center text-xl font-extrabold font-montserrat mt-2">
        DEPOSITS
      </div>
      <div className="w-full flex p-1 text-md h-none">
        <p className="my-1 text-center w-full font-montserrat">
          10%, 10 DAYS OUT. NO DEPOSITS UNDER $500 OR FOR SUPPORT.
        </p>
      </div>
    </div>
  );
};

export default WalkoutPotentialAgGrid;
