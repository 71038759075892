import { MagnifyingGlassIcon } from "@heroicons/react/24/outline";
import { classNames } from "primereact/utils";
export default function DashboardSearch({
  value,
  onChange,
  className,
  placeholder,
}: {
  value: string;
  onChange: (e: React.ChangeEvent<HTMLInputElement>) => void;
  className?: string;
  placeholder?: string;
}) {
  return (
    <div className={classNames("border-b border-gray-700", className)}>
      <div className="relative">
        <MagnifyingGlassIcon className="absolute left-3 top-1/2 transform -translate-y-1/2 text-gray-400 w-5 h-5" />
        <input
          type="text"
          placeholder={placeholder || "Search"}
          value={value}
          onChange={onChange}
          className="w-full pl-10 pr-4 py-2 bg-gray-800 rounded-lg border border-gray-700 focus:outline-none focus:border-cave-blue-700 text-white"
        />
      </div>
    </div>
  );
}
