import React, { useCallback, useMemo, useState } from "react";
import { ChevronDownIcon, ChevronUpIcon } from "@heroicons/react/24/solid";
import Button from "@/components/Button";
import { useMutation, useQueryClient } from "@tanstack/react-query";
import { TrashIcon, PlusIcon, TagIcon } from "@heroicons/react/24/outline";
import {
  FormBuilder,
  TextInput,
  SaveCancel,
  Checkbox,
} from "@/components/Form";
import * as yup from "yup";
import { useDropzone } from "react-dropzone";
import { createFile } from "@/queries/files";
import { deleteFile } from "@/queries/files";
import { updateEventFile } from "@/queries/events";

// Define available tags
const AVAILABLE_TAGS = [
  "Draft",
  "In-Progress",
  "Art",
  "Final-Approved-Art",
  "Contract",
  "Legal",
];

const FileUploadForm = ({
  parentId,
  entityType,
  onSuccess,
  onCancel,
  onlyMarketing = false,
  onFileChange,
  showMarketingCheckbox = true,
  showFinalCheckbox = true,
  showLegalCheckbox = true,
  hideSubmitButton = false,
  simplified = false,
}) => {
  const [files, setFiles] = useState([]);
  const [isSubmitting, setIsSubmitting] = useState(false);
  const [uploadProgress, setUploadProgress] = useState(0);
  const [selectedTags, setSelectedTags] = useState([]);
  const queryClient = useQueryClient();

  const mutation = useMutation({
    mutationFn: async (formData) => {
      console.log("Submitting form data:", {
        entityType,
        parentId,
        formData: Array.from(formData.entries()),
      });
      if (!parentId) {
        console.error("No parentId provided");
        throw new Error("No parentId provided");
      }
      if (!entityType) {
        console.error("No entityType provided");
        throw new Error("No entityType provided");
      }
      return createFile(entityType, parentId, formData);
    },
    onSuccess: async (data) => {
      if (parentId) {
        queryClient.invalidateQueries([
          entityType.replace(/s$/, ""),
          "files",
          parentId,
        ]);
      }
      onSuccess(data);
      setFiles([]); // Clear files after successful upload
      setSelectedTags([]); // Clear selected tags
    },
    onError: (error) => {
      console.error("Error handling file:", error);
      //toast.error(error.message || "Failed to upload file");
    },
    onSettled: () => {
      setIsSubmitting(false);
      setUploadProgress(0);
    },
  });

  const onDrop = useCallback(
    (acceptedFiles) => {
      setFiles(acceptedFiles);
      if (onFileChange) {
        onFileChange(acceptedFiles);
      }
    },
    [onFileChange]
  );

  const { getRootProps, getInputProps, isDragActive } = useDropzone({
    onDrop,
    multiple: true,
  });

  const addSchema = yup.object().shape({
    description: yup.string(),
    marketing: yup.boolean(),
    name: yup.string(),
  });

  const handleTagToggle = (tag) => {
    setSelectedTags((prevTags) => {
      if (prevTags.includes(tag)) {
        return prevTags.filter((t) => t !== tag);
      } else {
        return [...prevTags, tag];
      }
    });
  };

  const handleSubmit = async (formData) => {
    if (!files.length) {
      console.error("No files selected");
      return;
    }

    setIsSubmitting(true);
    let progress = 0;
    const progressInterval = setInterval(() => {
      progress += 100 / (files.length * 10);
      setUploadProgress(Math.min(progress, 100));
      if (progress >= 100) clearInterval(progressInterval);
    }, 300);

    try {
      const submitFormData = new FormData();

      // Add files and form data
      if (files && files.length > 0) {
        files.forEach((file) => {
          submitFormData.append("files", file);
        });
      }

      // Add other form data
      submitFormData.append("description", formData.description || "");
      submitFormData.append(
        "marketing",
        formData.marketing || onlyMarketing ? "true" : "false"
      );
      submitFormData.append("final", formData.final ? "true" : "false");
      submitFormData.append("legal", formData.legal ? "true" : "false");
      submitFormData.append("tags", JSON.stringify(selectedTags));

      console.log("Submitting form data:", {
        description: formData.description,
        marketing: formData.marketing || onlyMarketing,
        final: formData.final,
        legal: formData.legal,
        tags: selectedTags,
        fileCount: files.length,
      });

      await mutation.mutateAsync(submitFormData);
    } catch (error) {
      console.error("Error uploading files:", error);
    } finally {
      setIsSubmitting(false);
      clearInterval(progressInterval);
      setUploadProgress(0);
    }
  };

  return (
    <div className="py-6 min-w-full">
      <FormBuilder
        onSubmit={handleSubmit}
        schema={addSchema}
        defaultValues={{
          marketing: onlyMarketing,
          name: "",
          description: "",
        }}
      >
        <div
          {...getRootProps({
            className: `border-2 border-dashed p-6 transition-all ${
              isDragActive
                ? "border-blue-400 bg-blue-100"
                : "border-gray-300 bg-gray-50"
            } hover:border-blue-400 hover:bg-blue-50 rounded-lg cursor-pointer text-center`,
          })}
        >
          <input {...getInputProps()} />
          <p className="text-gray-500">
            {isDragActive
              ? "Drop the files here..."
              : "Drag and drop files or folders here, or click to select"}
          </p>
        </div>

        {files.length > 0 && (
          <div className="mt-4">
            <h4 className="font-semibold text-lg">Files to upload:</h4>
            <ul className="space-y-2 mt-2">
              {files.map((file) => (
                <li
                  key={file.path}
                  className="flex justify-between items-center p-2 bg-white rounded-lg shadow-sm border border-gray-300"
                >
                  <div className="flex items-center space-x-2">
                    <span className="text-sm text-gray-600">{file.name}</span>
                  </div>
                  <span className="text-xs text-gray-500">
                    {(file.size / 1024).toFixed(2)} KB
                  </span>
                </li>
              ))}
            </ul>
          </div>
        )}

        {isSubmitting && (
          <div className="mt-4">
            <h4 className="font-semibold text-lg">Uploading files...</h4>
            <div className="w-full bg-gray-200 rounded-full h-4 mt-2">
              <div
                className="bg-blue-500 h-4 rounded-full"
                style={{ width: `${uploadProgress}%` }}
              ></div>
            </div>
            <p className="text-gray-600 text-sm mt-2">
              {uploadProgress.toFixed(0)}% complete
            </p>
          </div>
        )}

        {!simplified && (
          <TextInput
            name="description"
            label="Description"
            placeholder="Optional description"
          />
        )}

        <div className="mt-4">
          <label className="block text-sm font-medium text-gray-700 mb-2">
            Tags
          </label>
          <div className="flex flex-wrap gap-2">
            {AVAILABLE_TAGS.map((tag) => (
              <button
                key={tag}
                type="button"
                onClick={() => handleTagToggle(tag)}
                className={`px-3 py-1 rounded-full text-xs ${
                  selectedTags.includes(tag)
                    ? "bg-blue-500 text-white"
                    : "bg-gray-200 text-gray-700"
                }`}
              >
                {tag}
              </button>
            ))}
          </div>
        </div>

        {showMarketingCheckbox && !onlyMarketing && (
          <Checkbox name="marketing" label="Marketing File" />
        )}

        {showFinalCheckbox && (
          <Checkbox name="final" label="Final Settlement" />
        )}

        {showLegalCheckbox && <Checkbox name="legal" label="Legal Document" />}

        {!hideSubmitButton && (
          <SaveCancel
            label={`Add ${onlyMarketing ? "Marketing " : ""}Files`}
            cancelAction={onCancel}
            isSubmitting={isSubmitting}
            submittingMessage="Uploading files..."
          />
        )}
      </FormBuilder>
    </div>
  );
};

const File = ({
  file,
  entityType,
  editable,
  canEditMarketing,
  onDeleteClick,
  onDeleteComplete,
  parentId,
}) => {
  const [deleteConfirm, setDeleteConfirm] = useState(false);
  const [isEditingTags, setIsEditingTags] = useState(false);
  const [selectedTags, setSelectedTags] = useState(file.tags || []);
  const queryClient = useQueryClient();
  console.log("hux7", parentId);
  const deleteMutation = useMutation({
    mutationFn: (fileToDelete) =>
      deleteFile(entityType, parentId, fileToDelete),
    onSuccess: async (data) => {
      // Invalidate all relevant queries immediately
      await Promise.all([
        queryClient.invalidateQueries(["event", "files"]),
        queryClient.invalidateQueries(["event-detail"]),
        queryClient.invalidateQueries(["event-expense-files"]),
        queryClient.invalidateQueries(["subgroup-expense-files"]),
        queryClient.invalidateQueries(["files"]),
        queryClient.invalidateQueries(["expenses"]),
        queryClient.invalidateQueries(["people", "files", parentId]),
        queryClient.invalidateQueries(["event", "files", parentId]),
        queryClient.invalidateQueries(["venue", "files", parentId]),
        queryClient.invalidateQueries(["vendor", "files", parentId]),
        queryClient.invalidateQueries(["artist", "files", parentId]),
      ]);

      // Force immediate refetch of all file-related queries
      await Promise.all([
        queryClient.refetchQueries(["event", "files", parentId]),
        queryClient.refetchQueries(["venue", "files", parentId]),
        queryClient.refetchQueries(["artist", "files", parentId]),
        queryClient.refetchQueries(["people", "files", parentId]),
        queryClient.refetchQueries(["vendor", "files", parentId]),
        queryClient.refetchQueries(["files"]),
        queryClient.refetchQueries(["expenses"]),
      ]);

      onDeleteComplete();
      //toast.success("File deleted successfully");
    },
    onError: (error) => {
      console.error(`Error deleting file:`, error);
      //toast.error(error.message || "Error deleting file");
    },
  });

  const updateTagsMutation = useMutation({
    mutationFn: (data) => updateEventFile(file.id, data),
    onSuccess: async () => {
      // Invalidate and refetch queries
      await Promise.all([
        queryClient.invalidateQueries(["event", "files"]),
        queryClient.invalidateQueries(["event", "files", parentId]),
      ]);

      // Force immediate refetch
      await queryClient.refetchQueries(["event", "files", parentId]);

      setIsEditingTags(false);
      //toast.success("File tags updated successfully");
    },
    onError: (error) => {
      console.error(`Error updating file tags:`, error);
      //toast.error(error.message || "Error updating file tags");
    },
  });

  const handleDeleteClick = (e) => {
    e.stopPropagation();
    onDeleteClick();
    setDeleteConfirm(true);
  };

  const handleConfirmDelete = (e) => {
    e.stopPropagation();
    deleteMutation.mutate(file);
  };

  const handleCancelDelete = (e) => {
    e.stopPropagation();
    setDeleteConfirm(false);
    onDeleteComplete();
  };

  const handleTagToggle = (tag) => {
    setSelectedTags((prevTags) => {
      if (prevTags.includes(tag)) {
        return prevTags.filter((t) => t !== tag);
      } else {
        return [...prevTags, tag];
      }
    });
  };

  const handleSaveTags = (e) => {
    e.stopPropagation();
    updateTagsMutation.mutate({ tags: selectedTags });
  };

  const handleEditTags = (e) => {
    e.stopPropagation();
    setIsEditingTags(true);
    setSelectedTags(file.tags || []);
  };

  const handleCancelEditTags = (e) => {
    e.stopPropagation();
    setIsEditingTags(false);
    setSelectedTags(file.tags || []);
  };

  const canEditFile = editable && (!file.marketing || canEditMarketing);

  return (
    <tr className="flex w-full hover:bg-gray-100 dark:hover:bg-gray-800">
      <td className="p-4 border border-gray-200 w-1/3">
        <span
          className="text-sm text-blue-500 hover:text-blue-700 cursor-pointer"
          onClick={() => window.open(file.file_url, "_blank")}
        >
          {file.name}
        </span>
      </td>
      <td className="p-4 border border-gray-200 w-1/3 flex flex-col justify-center">
        <div className="flex flex-wrap gap-1 mb-1">
          <span
            className={`px-2 py-1 rounded-full text-xs ${
              file.marketing
                ? "bg-green-100 text-green-800"
                : file.final
                ? "bg-blue-100 text-blue-800"
                : file.legal
                ? "bg-red-100 text-red-800"
                : "bg-gray-100 text-gray-800"
            }`}
          >
            {file.marketing
              ? "Marketing"
              : file.final
              ? "Final"
              : file.legal
              ? "Legal"
              : "General"}
          </span>

          {/* Display tags */}
          {file.tags &&
            file.tags.map((tag) => (
              <span
                key={tag}
                className="px-2 py-1 rounded-full text-xs bg-purple-100 text-purple-800"
              >
                {tag}
              </span>
            ))}
        </div>

        {/* Tag editing UI */}
        {isEditingTags && canEditFile && (
          <div
            className="mt-2 flex flex-col space-y-2"
            onClick={(e) => e.stopPropagation()}
          >
            <div className="flex flex-wrap gap-1">
              {AVAILABLE_TAGS.map((tag) => (
                <button
                  key={tag}
                  type="button"
                  onClick={() => handleTagToggle(tag)}
                  className={`px-2 py-1 rounded-full text-xs ${
                    selectedTags.includes(tag)
                      ? "bg-blue-500 text-white"
                      : "bg-gray-200 text-gray-700"
                  }`}
                >
                  {tag}
                </button>
              ))}
            </div>
            <div className="flex justify-end space-x-2">
              <button
                className="text-xs px-2 py-1 bg-gray-200 rounded hover:bg-gray-300"
                onClick={handleCancelEditTags}
              >
                Cancel
              </button>
              <button
                className="text-xs px-2 py-1 bg-blue-500 text-white rounded hover:bg-blue-600"
                onClick={handleSaveTags}
              >
                Save
              </button>
            </div>
          </div>
        )}
      </td>
      <td className="p-4 border border-gray-200 w-1/3">
        <div className="flex justify-between items-center">
          <span>{file.description}</span>
          {canEditFile && !deleteConfirm && (
            <div className="flex space-x-2">
              <button
                className="text-gray-400 hover:text-blue-500"
                onClick={handleEditTags}
                title="Edit tags"
              >
                <TagIcon className="h-5 w-5" />
              </button>
              <TrashIcon
                className="h-6 w-6 text-gray-400 hover:text-red-400 cursor-pointer"
                onClick={handleDeleteClick}
              />
            </div>
          )}
        </div>
        {deleteConfirm && (
          <div className="flex justify-between items-center mt-2">
            <span className="text-red-600">Are you sure?</span>
            <div>
              <button
                className="mr-2 rounded bg-red-600 hover:bg-red-700 px-3 py-1 text-white text-sm"
                onClick={handleConfirmDelete}
              >
                Yes
              </button>
              <button
                className="rounded bg-gray-500 hover:bg-gray-600 px-3 py-1 text-white text-sm"
                onClick={handleCancelDelete}
              >
                No
              </button>
            </div>
          </div>
        )}
      </td>
    </tr>
  );
};

const FileDisplay = ({
  files,
  id,
  entityType,
  editable,
  perms,
  isReq = false,
  onlyMarketing = false,
  showFinalCheckbox = false,
}) => {
  const [isOnlyMarketing] = useState(onlyMarketing);
  const [isExpanded, setIsExpanded] = useState(false);
  const [showUploadForm, setShowUploadForm] = useState(false);
  const [isDeleting, setIsDeleting] = useState(false);

  const toggleExpand = () => {
    if (!isDeleting) {
      setIsExpanded(!isExpanded);
    }
  };

  const handleDeleteClick = () => {
    setIsDeleting(true);
  };

  const handleDeleteComplete = () => {
    setIsDeleting(false);
  };

  const canViewOnlyMarketing =
    perms && perms.data ? perms.data.edit_marketing : false;
  const canEditMarketing = Boolean(perms?.data?.edit_marketing && editable);

  const filteredFiles = useMemo(() => {
    if (files.isSuccess && Array.isArray(files.data)) {
      let filtered = files.data;

      // Filter marketing files if needed
      if (onlyMarketing) {
        filtered = filtered.filter((file) => file.marketing);
      }

      // Additional entity-specific filtering if needed
      switch (entityType) {
        case "artist":
          filtered = filtered.filter((file) => !file.isEventFile);
          break;
        case "venue":
          filtered = filtered.filter((file) => !file.isEventFile);
          break;
        default:
          break;
      }

      return filtered;
    }
    return [];
  }, [files.isSuccess, files.data, onlyMarketing, entityType]);

  const fileCount = filteredFiles.length;

  return (
    <div className="flex flex-col mb-6">
      <div className="w-full flex justify-between items-center mb-4">
        <h3 className="text-xl font-semibold dark:text-gray-200">
          {entityType === "artists"
            ? "Artist Files"
            : entityType === "venues"
            ? "Venue Files"
            : "Files"}
        </h3>
        <div className="flex items-center space-x-4">
          {editable && !showUploadForm && (
            <Button onClick={() => setShowUploadForm(true)}>
              <PlusIcon className="h-5 w-5 mr-2" />
              Add {onlyMarketing ? "Marketing " : ""}Files
            </Button>
          )}
        </div>
      </div>

      {showUploadForm && (
        <FileUploadForm
          parentId={id}
          entityType={entityType}
          onSuccess={() => {
            setShowUploadForm(false);
            setIsExpanded(true);
          }}
          onCancel={() => setShowUploadForm(false)}
          canEditMarketing={canEditMarketing}
          onlyMarketing={isOnlyMarketing}
          showMarketingCheckbox={!isReq && !onlyMarketing}
          showFinalCheckbox={showFinalCheckbox}
          showLegalCheckbox={true}
          hideSubmitButton={false}
          simplified={false}
        />
      )}

      {!showUploadForm && (
        <div className="overflow-x-auto mt-4">
          <table
            className="w-full text-sm text-left text-gray-500 dark:text-gray-400 cursor-pointer"
            onClick={toggleExpand}
          >
            <thead className="text-xs text-gray-700 uppercase bg-gray-100 dark:bg-gray-700 dark:text-gray-400">
              <tr className="flex w-full">
                <th scope="col" className="px-4 py-3 w-1/3">
                  {isExpanded ? "Files" : ""}
                </th>
                <th
                  scope="col"
                  className="px-4 py-3 w-1/3 flex justify-center items-center"
                >
                  <span className="dark:text-white text-black uppercase">
                    {isExpanded
                      ? "Type & Tags"
                      : `${fileCount} file${fileCount !== 1 ? "s" : ""}`}
                  </span>
                </th>
                <th
                  scope="col"
                  className="px-4 py-3 w-1/3 flex justify-end items-center"
                >
                  <span className="dark:text-white text-black mr-2">
                    {isExpanded ? "Description" : ""}
                  </span>
                  <button
                    className="ml-auto"
                    onClick={(e) => {
                      e.stopPropagation();
                      toggleExpand();
                    }}
                  >
                    {isExpanded ? (
                      <ChevronUpIcon className="h-5 w-5 dark:text-white" />
                    ) : (
                      <ChevronDownIcon className="h-5 w-5 dark:text-white" />
                    )}
                  </button>
                </th>
              </tr>
            </thead>
            <tbody className="dark:bg-white">
              {isExpanded && (
                <>
                  {files.isLoading && (
                    <tr>
                      <td
                        colSpan="3"
                        className="px-6 py-3 text-center text-sm text-gray-500 dark:text-gray-400"
                      >
                        Loading...
                      </td>
                    </tr>
                  )}
                  {files.isError && (
                    <tr>
                      <td
                        colSpan="3"
                        className="px-6 py-3 text-center text-sm text-red-500 dark:text-red-400"
                      >
                        Error loading files. Please try again later.
                      </td>
                    </tr>
                  )}
                  {files.isSuccess &&
                    filteredFiles.map((file) => (
                      <File
                        key={file.id}
                        file={file}
                        editable={editable}
                        canEditMarketing={canEditMarketing}
                        onDeleteClick={handleDeleteClick}
                        onDeleteComplete={handleDeleteComplete}
                        entityType={entityType}
                        parentId={id}
                      />
                    ))}
                  {files.isSuccess && !filteredFiles.length && (
                    <tr>
                      <td
                        colSpan="3"
                        className="px-6 py-3 text-center text-sm text-gray-500 dark:text-gray-400"
                      >
                        <em>No files found</em>
                      </td>
                    </tr>
                  )}
                </>
              )}
            </tbody>
          </table>
        </div>
      )}
    </div>
  );
};

export { FileUploadForm, FileDisplay, File };
export default FileDisplay;
