import { jsonHeaders, makeRequest, postApiRequest } from "./base";

export interface FinalOverride {
  id: number;
  event: number;
  field_name: string;
  section_name: string;
  sub_section_name: string;
  value: string;
  is_money: boolean;
  created: string;
  modified: string;
}

export interface CreateFinalOverrideData
  extends Omit<FinalOverride, "id" | "created" | "modified" | "event"> {
  event?: number;
}

/**
 * Fetches all overrides for a specific event
 */
export const fetchEventOverrides = async (
  eventId: number
): Promise<FinalOverride[]> => {
  const headers = await jsonHeaders(false);
  const response = await makeRequest(
    `/api/events/overrides/${eventId}/`,
    "GET",
    headers
  );
  return response.json();
};

/**
 * Creates a new override for an event
 */
export const createEventOverride = async (
  eventId: number,
  data: CreateFinalOverrideData
): Promise<FinalOverride> => {
  const headers = await jsonHeaders(true);
  const response = await makeRequest(
    `/api/events/overrides/${eventId}/`,
    "POST",
    headers,
    JSON.stringify({
      ...data,
      event: eventId,
      is_money: data.is_money ?? false, // Default to false if not specified
    })
  );
  return response.json();
};

/**
 * Fetches a specific override by ID
 */
export const getEventOverride = async (
  eventId: number,
  overrideId: number
): Promise<FinalOverride> => {
  const headers = await jsonHeaders(false);
  const response = await makeRequest(
    `/api/events/overrides/${eventId}/${overrideId}/`,
    "GET",
    headers
  );
  return response.json();
};

/**
 * Updates an existing override
 */
export const updateEventOverride = async (
  eventId: number,
  overrideId: number,
  data: Partial<CreateFinalOverrideData>
): Promise<FinalOverride> => {
  const headers = await jsonHeaders(true);
  const response = await makeRequest(
    `/api/events/overrides/${eventId}/${overrideId}/`,
    "PUT",
    headers,
    JSON.stringify(data)
  );
  return response.json();
};

/**
 * Deletes an override
 */
export const deleteEventOverride = async (
  eventId: number,
  overrideId: number
): Promise<void> => {
  const headers = await jsonHeaders(true);
  await makeRequest(
    `/api/events/overrides/${eventId}/${overrideId}/`,
    "DELETE",
    headers
  );
};

/**
 * Helper function to create a new override with default values
 */
export const addNewOverride = async (
  eventId: number,
  fieldName: string,
  sectionName: string = "",
  subSectionName: string = "",
  value: string = "",
  isMoney: boolean = false
): Promise<FinalOverride> => {
  return createEventOverride(eventId, {
    field_name: fieldName,
    section_name: sectionName,
    sub_section_name: subSectionName,
    value,
    is_money: isMoney,
  });
};

/**
 * Helper function to check if an override exists for a specific field
 */
export const hasOverride = (
  overrides: FinalOverride[],
  fieldName: string
): boolean => {
  return overrides.some((override) => override.field_name === fieldName);
};

/**
 * Helper function to get an override value by field name
 */
export const getOverrideValue = (
  overrides: FinalOverride[],
  fieldName: string
): string | null => {
  const override = overrides.find((o) => o.field_name === fieldName);
  return override ? override.value : null;
};
