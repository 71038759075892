import { AgGridReact } from "ag-grid-react";
import { useCallback, useState, useEffect, useRef } from "react";

import { offerEditableBgClass } from "@/constants/offer";
import { useEventOfferStore } from "@/store/eventOfferStore";
import { localTime, formatToHHMMSS } from "@/utils/datetime";

export const FIELD_UPDATE_ANIMATION_TIME = 2000;

const TimesAgGrid = ({ event, eventId }) => {
  const [flashFields, setFlashFields] = useState({});
  const [rowData, setRowData] = useState([]);
  const gridRef = useRef(null);

  // Get store functions
  const { eventOfferForm, patchEventField, initializeEventOfferForm } =
    useEventOfferStore();

  // Initialize time values in the store
  useEffect(() => {
    if (event) {
      initializeEventOfferForm({
        door_time: event.door_time || "",
        opener_time: event.opener_time || "",
        headliner_time: event.headliner_time || "",
        curfew_time: event.curfew_time || "",
      });
    }
  }, [event, initializeEventOfferForm]);

  // Flash effect for updated fields
  const flashField = useCallback((fieldKey) => {
    setFlashFields((prev) => ({ ...prev, [fieldKey]: true }));
    setTimeout(() => {
      setFlashFields((prev) => {
        const copy = { ...prev };
        delete copy[fieldKey];
        return copy;
      });
    }, FIELD_UPDATE_ANIMATION_TIME);
  }, []);

  // Update rowData from store values
  useEffect(() => {
    setRowData([
      {
        header: "DOORS",
        value: localTime(eventOfferForm.door_time),
        field: "door_time",
        format: "time",
        isFlashing: flashFields["door_time"],
      },
      {
        header: "OPENER",
        value: localTime(eventOfferForm.opener_time),
        field: "opener_time",
        format: "time",
        isFlashing: flashFields["opener_time"],
      },
      {
        header: "HEADLINER",
        value: localTime(eventOfferForm.headliner_time),
        field: "headliner_time",
        format: "time",
        isFlashing: flashFields["headliner_time"],
      },
      {
        header: "CURFEW",
        value: localTime(eventOfferForm.curfew_time),
        field: "curfew_time",
        format: "time",
        isFlashing: flashFields["curfew_time"],
      },
    ]);
  }, [eventOfferForm, flashFields]);

  // Handle time field update with proper conversion
  const handleTimeUpdate = useCallback(
    (field, value) => {
      if (!field || value === undefined || value === "") return;

      try {
        // Properly format time value using the utility function
        const formattedValue = formatToHHMMSS(value);

        // Extra validation to ensure we have a valid time
        if (
          !formattedValue ||
          formattedValue.toLowerCase().includes("invalid")
        ) {
          console.error("Invalid time format:", value);
          return;
        }

        console.log(
          `Updating ${field} with formatted value: ${formattedValue}`
        );

        // Send properly formatted time to the server
        patchEventField(eventId, field, formattedValue);
        flashField(field);
      } catch (error) {
        console.error("Error formatting time:", error);
      }
    },
    [eventId, patchEventField, flashField]
  );

  // Column definitions
  const columnDefs = [
    {
      field: "header",
      width: 150,
      editable: false,
    },
    {
      field: "value",
      flex: 1,
      editable: true,
      cellEditor: "agTextCellEditor",
      cellClass: (params) =>
        `${offerEditableBgClass} ${params.data.isFlashing ? "glow-text" : ""}`,
      onCellValueChanged: (params) => {
        handleTimeUpdate(params.data.field, params.newValue);
      },
    },
  ];

  // Default column definition
  const defaultColDef = {
    resizable: true,
    sortable: false,
    filter: false,
  };

  return (
    <div className="w-full">
      <div className="bg-gray-300 text-base text-center py-1 px-2 font-montserrat">
        TIMES
      </div>
      <div
        className="ag-theme-alpine"
        style={{ height: "auto", width: "100%" }}
      >
        <AgGridReact
          ref={gridRef}
          rowData={rowData}
          columnDefs={columnDefs}
          singleClickEdit={true}
          stopEditingWhenCellsLoseFocus
          defaultColDef={defaultColDef}
          domLayout="autoHeight"
          headerHeight={0}
          getRowId={(params) => params.data.field}
        />
      </div>
    </div>
  );
};

export default TimesAgGrid;
