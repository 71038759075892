import React, { useState } from "react";
import { XMarkIcon } from "@heroicons/react/24/outline";

export const GuestForm = ({
  isOpen,
  onClose,
  onSubmit,
  initialData = null,
}) => {
  const [formData, setFormData] = useState(
    initialData || {
      name: "",
      num_tickets: 0,
      affiliation: "",
      num_passes: 0,
      pass_type: "VIP",
    }
  );

  if (!isOpen) return null;

  const handleSubmit = (e) => {
    e.preventDefault();
    onSubmit(formData);
    onClose();
  };

  return (
    <div className="fixed inset-0 z-50 overflow-y-auto">
      <div className="flex items-center justify-center min-h-screen px-4">
        <div className="fixed inset-0 bg-black opacity-30"></div>
        <div className="relative bg-cave-black rounded-lg p-8 max-w-md w-full">
          <div className="absolute top-4 right-4">
            <button
              onClick={onClose}
              className="text-gray-500 hover:text-gray-700"
            >
              <XMarkIcon className="h-6 w-6" />
            </button>
          </div>
          <h3 className="text-lg font-medium text-green-800 mb-4">
            {initialData ? "Edit Guest" : "Add Guest"}
          </h3>
          <form onSubmit={handleSubmit} className="space-y-4">
            <div>
              <label className="block text-sm font-medium text-green-700">
                Name
              </label>
              <input
                type="text"
                value={formData.name}
                onChange={(e) =>
                  setFormData({ ...formData, name: e.target.value })
                }
                className="mt-1 block w-full rounded-md border-green-300 shadow-sm focus:border-green-500 focus:ring-green-500"
                required
              />
            </div>
            <div>
              <label className="block text-sm font-medium text-green-700">
                Number of Tickets
              </label>
              <input
                type="number"
                value={formData.num_tickets}
                onChange={(e) =>
                  setFormData({
                    ...formData,
                    num_tickets: parseInt(e.target.value),
                  })
                }
                className="mt-1 block w-full rounded-md border-green-300 shadow-sm focus:border-green-500 focus:ring-green-500"
                required
                min="0"
              />
            </div>
            <div>
              <label className="block text-sm font-medium text-green-700">
                Affiliation
              </label>
              <input
                type="text"
                value={formData.affiliation}
                onChange={(e) =>
                  setFormData({ ...formData, affiliation: e.target.value })
                }
                className="mt-1 block w-full rounded-md border-green-300 shadow-sm focus:border-green-500 focus:ring-green-500"
                required
              />
            </div>
            <div>
              <label className="block text-sm font-medium text-green-700">
                Number of Passes
              </label>
              <input
                type="number"
                value={formData.num_passes}
                onChange={(e) =>
                  setFormData({
                    ...formData,
                    num_passes: parseInt(e.target.value),
                  })
                }
                className="mt-1 block w-full rounded-md border-green-300 shadow-sm focus:border-green-500 focus:ring-green-500"
                required
                min="0"
              />
            </div>
            <div>
              <label className="block text-sm font-medium text-green-700">
                Pass Type
              </label>
              <select
                value={formData.pass_type}
                onChange={(e) =>
                  setFormData({ ...formData, pass_type: e.target.value })
                }
                className="mt-1 block w-full rounded-md border-green-300 shadow-sm focus:border-green-500 focus:ring-green-500"
                required
              >
                <option value="VIP">VIP</option>
                <option value="ALL_ACCESS">All Access</option>
                <option value="BACKSTAGE">Backstage</option>
                <option value="PHOTO">Photo</option>
                <option value="MEDIA">Media</option>
                <option value="COMP">Comp</option>
              </select>
            </div>
            <div className="flex justify-end space-x-3 mt-6">
              <button
                type="button"
                onClick={onClose}
                className="px-4 py-2 text-sm font-medium text-green-700 bg-white border border-green-300 rounded-md hover:bg-green-50"
              >
                Cancel
              </button>
              <button
                type="submit"
                className="px-4 py-2 text-sm font-medium text-white bg-green-600 border border-transparent rounded-md hover:bg-green-700"
              >
                {initialData ? "Update Guest" : "Add Guest"}
              </button>
            </div>
          </form>
        </div>
      </div>
    </div>
  );
};

export default GuestForm;
