import { AgGridReact } from "ag-grid-react";
import React, {
  useState,
  useEffect,
  useRef,
  useCallback,
  useImperativeHandle,
  useMemo,
} from "react";
import Select from "react-select";

import { offerEditableBgClass, offerReadOnlyBgClass } from "@/constants/offer";
import { FIELD_UPDATE_ANIMATION_TIME } from "@/hooks/useGlowEffect";
import { useEventOfferStore } from "@/store/eventOfferStore";

const SelectCellEditor = React.forwardRef((props, ref) => {
  const { value, data } = props;
  const [selected, setSelected] = useState(value);

  useImperativeHandle(ref, () => ({
    getValue: () => selected,
  }));

  const handleChange = useCallback((option) => {
    setSelected(option);
  }, []);

  return (
    <Select
      options={data.options}
      value={selected}
      onChange={handleChange}
      isMulti={data.isMulti}
      menuPortalTarget={document.body}
      menuPosition="fixed"
      styles={{ container: (base) => ({ ...base, zIndex: 9999 }) }}
    />
  );
});
SelectCellEditor.displayName = "SelectCellEditor";

const ArtistInfoAgGrid = ({
  event,
  eventId,
  offerId,
  buyerOptions,
  artistOptions,
  updatedProperties,
}) => {
  const gridRef = useRef(null);
  const {
    eventOfferForm,
    initializeEventOfferForm,
    patchEventField,
    patchOfferField,
  } = useEventOfferStore();
  const [flashFields, setFlashFields] = useState({});

  const flashField = useCallback((fieldKey) => {
    setFlashFields((prev) => ({ ...prev, [fieldKey]: true }));
    setTimeout(() => {
      setFlashFields((prev2) => {
        const copy = { ...prev2 };
        delete copy[fieldKey];
        return copy;
      });
    }, FIELD_UPDATE_ANIMATION_TIME);
  }, []);

  useEffect(() => {
    if (event) {
      initializeEventOfferForm({
        offer_agent: event.offer_agent || event.artists?.[0]?.agent || "",
        offer_support_1: event.offer_support_1 || "",
        offer_support_1_label: event.offer_support_1_label || "",
        offer_support_2: event.offer_support_2 || "",
        offer_support_2_label: event.offer_support_2_label || "",
        offer_opener: event.offer_opener || "",
        offer_billing: event.offer_billing || "",
        offer_stipulations: event.offer_stipulations || "",
        offer_avails_1: event.offer_avails_1 || "",
        offer_avails_2: event.offer_avails_2 || "",
      });
    }
  }, [event, initializeEventOfferForm]);

  const handlePatchField = useCallback(
    (field, newValue, headerField = null) => {
      const actualField = headerField || field;
      flashField(actualField);
      if (actualField.startsWith("offer_")) {
        patchOfferField(eventId, actualField, newValue);
      } else {
        patchEventField(eventId, actualField, newValue);
      }
    },
    [flashField, patchEventField, patchOfferField, eventId, offerId]
  );

  const rowData = useMemo(() => {
    if (!event) return [];
    return [
      {
        header: "AGENT",
        field: "offer_agent",
        value: eventOfferForm.offer_agent,
        editable: true,
        isChanged: updatedProperties.includes("offer_agent"),
      },
      {
        header: "BUYERS",
        field: "buyers",
        value: (event.buyers || []).map((b) => ({
          value: b.person_id,
          label: `${b.first_name} ${b.last_name}`,
        })),
        isMulti: true,
        type: "select",
        options: buyerOptions,
        readOnly: true,
      },
      {
        header: "HEADLINER",
        field: "artists",
        value: (event.artists || []).map((a) => ({
          value: a.id,
          label: a.name,
        })),
        isMulti: true,
        type: "select",
        options: artistOptions,
        readOnly: true,
      },
      {
        header: eventOfferForm.offer_support_1_label || "SUPPORT",
        field: "offer_support_1",
        headerField: "offer_support_1_label",
        value: eventOfferForm.offer_support_1,
        editHeader: true,
        editValue: true,
      },
      {
        header: eventOfferForm.offer_support_2_label || "SUPPORT",
        field: "offer_support_2",
        headerField: "offer_support_2_label",
        value: eventOfferForm.offer_support_2,
        editHeader: true,
        editValue: true,
      },
      {
        header: "OPENER",
        field: "offer_opener",
        value: eventOfferForm.offer_opener,
        editValue: true,
      },
      {
        header: "BILLING",
        field: "offer_billing",
        value: eventOfferForm.offer_billing,
        editValue: true,
      },
      {
        header: "STIPULATIONS",
        field: "offer_stipulations",
        value: eventOfferForm.offer_stipulations,
        editValue: true,
      },
      {
        header: "AVAILS",
        field: "offer_avails_1",
        value: eventOfferForm.offer_avails_1,
        editValue: true,
      },
      {
        header: "AVAILS",
        field: "offer_avails_2",
        value: eventOfferForm.offer_avails_2,
        editValue: true,
      },
    ];
  }, [event, eventOfferForm, buyerOptions, artistOptions, updatedProperties]);

  const getHeaderCellClass = useCallback((params) => {
    return params.data.editHeader ? offerEditableBgClass : offerReadOnlyBgClass;
  }, []);

  const getValueCellClass = useCallback((params) => {
    return params.data.editValue || params.data.editable
      ? offerEditableBgClass
      : offerReadOnlyBgClass;
  }, []);

  const onHeaderCellValueChanged = useCallback(
    (params) => {
      if (params.data.editHeader && params.data.headerField) {
        handlePatchField(
          params.data.field,
          params.newValue,
          params.data.headerField
        );
      }
    },
    [handlePatchField]
  );

  const onValueCellValueChanged = useCallback(
    (params) => {
      if (!params.data.type) {
        handlePatchField(params.data.field, params.newValue);
      }
    },
    [handlePatchField]
  );

  const valueFormatter = useCallback((params) => {
    if (Array.isArray(params.value)) {
      return params.value.map((item) => item.label).join(", ");
    }
    return params.value;
  }, []);

  const cellEditorFramework = useCallback((params) => {
    if (params.data.type === "select" && !params.data.readOnly) {
      return SelectCellEditor;
    }
    return undefined;
  }, []);

  const cellRenderer = useCallback(
    (params) => {
      if (params.data.type === "select") {
        if (params.data.readOnly) {
          const displayValue = Array.isArray(params.value)
            ? params.value.map((item) => item.label).join(", ")
            : "";
          return <div className="px-2 py-1">{displayValue}</div>;
        }
        const handleSelectChange = (selected) => {
          params.node.setDataValue(params.data.field, selected);
          handlePatchField(params.data.field, selected);
        };
        return (
          <Select
            options={params.data.options}
            value={params.value}
            onChange={handleSelectChange}
            isMulti={params.data.isMulti}
            menuPortalTarget={document.body}
            menuPosition="fixed"
            styles={{ container: (base) => ({ ...base, zIndex: 9999 }) }}
          />
        );
      }
      const handleDoubleClick = () => {
        gridRef.current?.api?.startEditingCell({
          rowIndex: params.rowIndex,
          colKey: params.column.colId,
        });
      };
      return (
        <div
          className={`px-2 py-1 ${params.data.editValue ? "editable-cell" : ""} ${flashFields[params.data.field] ? "glow-text" : ""
            }`}
          onDoubleClick={handleDoubleClick}
        >
          {params.value}
        </div>
      );
    },
    [flashFields, handlePatchField]
  );

  const columnDefs = useMemo(() => {
    return [
      {
        field: "header",
        // width: 180,
        // minWidth: 180,
        flex: 1,
        editable: (params) => params.data.editHeader,
        cellEditor: "agTextCellEditor",
        cellClass: getHeaderCellClass,
        onCellValueChanged: onHeaderCellValueChanged,
      },
      {
        field: "value",
        flex: 1,
        cellClass: getValueCellClass,
        editable: (params) => params.data.editValue || params.data.editable,
        cellEditorFramework,
        cellEditor: "agTextCellEditor",
        valueFormatter,
        cellRenderer,
        onCellValueChanged: onValueCellValueChanged,
      },
    ];
  }, [
    getHeaderCellClass,
    onHeaderCellValueChanged,
    getValueCellClass,
    cellEditorFramework,
    valueFormatter,
    cellRenderer,
    onValueCellValueChanged,
  ]);

  const defaultColDef = useMemo(
    () => ({
      resizable: true,
      sortable: false,
      filter: false,
    }),
    []
  );

  // if (!event) {
  //   return <div>Loading...</div>;
  // }

  return (
    <div style={{ width: "100%" }}>
      <div
        className="ag-theme-alpine"
        style={{
          width: "100%",
          border: "1px solid #dde2eb",
        }}
      >
        <AgGridReact
          ref={gridRef}
          rowData={rowData}
          columnDefs={columnDefs}
          defaultColDef={defaultColDef}
          singleClickEdit
          stopEditingWhenCellsLoseFocus
          animateRows={false}
          getRowId={(p) => p.data.field}
          domLayout="normal"
          headerHeight={0}
          loading={!event || !buyerOptions || !artistOptions}
          enableCellTextSelection
          ensureDomOrder
          suppressChangeDetection
          immutableData
          suppressRowTransform
          rowHeight={35}
          suppressScrollOnNewData
          suppressHorizontalScroll
          onGridReady={(p) => p.api.sizeColumnsToFit()}
          onFirstDataRendered={(p) => p.api.sizeColumnsToFit()}
        />
      </div>
    </div>
  );
};

export default ArtistInfoAgGrid;
