import { recalculateOfferProjections } from "@/queries/events";
import { toast } from "react-toastify";

// Helper function to normalize section names
const normalizeSection = (section) => {
  if (!section) return "OTHER VARIABLE";

  const sectionUpper = section.toUpperCase();

  if (sectionUpper.includes("DIRECT") || sectionUpper.includes("COST")) {
    return "DIRECT COSTS";
  } else if (sectionUpper.includes("PROD")) {
    return "PRODUCTION";
  } else if (sectionUpper.includes("OPER")) {
    return "OPERATIONS";
  } else if (sectionUpper.includes("AD") || sectionUpper.includes("MARKET")) {
    return "ADVERTISING";
  } else {
    return "OTHER VARIABLE";
  }
};

// Create the Zustand store
import { create } from "zustand";

const useProjectionsStore = create((set, get) => ({
  // State
  financials: {
    grossRevenue: 0,
    directCosts: 0,
    production: 0,
    operations: 0,
    advertising: 0,
    variableExpenses: 0,
  },
  projectedExpenses: [],
  ticketCounts: {},
  expandedSections: {
    ticketCounts: false,
    financialProjections: false,
    variableExpenses: false,
    directcosts: false,
    production: false,
    operations: false,
    advertising: false,
    othervariable: false,
  },
  isLoading: false,
  isSaving: false,

  // Actions
  resetState: () => {
    set({
      financials: {
        grossRevenue: 0,
        directCosts: 0,
        production: 0,
        operations: 0,
        advertising: 0,
        variableExpenses: 0,
      },
      projectedExpenses: [],
      ticketCounts: {},
      isLoading: false,
      isSaving: false,
    });
  },

  fetchProjections: async (offerId, eventId) => {
    if (!offerId) {
      console.error("Offer ID is required");
      return;
    }

    try {
      set({ isLoading: true });

      // Import the query function dynamically to avoid circular dependencies
      const { getOfferProjections } = await import("@/queries/events");

      // Fetch projections from API
      const projections = await getOfferProjections(offerId, true, eventId);

      if (!projections || projections.length === 0) {
        console.log("No projections found for offer:", offerId);
        set({ isLoading: false });
        return;
      }

      // Use the first projection (most recent)
      const projection = projections[0];

      // Extract projected expenses
      const projectedExpenses = projection.projected_expenses || [];

      // Update state with fetched data
      set({
        financials: {
          grossRevenue: parseFloat(projection.gross_revenue) || 0,
          directCosts: parseFloat(projection.direct_costs) || 0,
          production: parseFloat(projection.production_expenses) || 0,
          operations: parseFloat(projection.operations_expenses) || 0,
          advertising: parseFloat(projection.advertising_expenses) || 0,
          variableExpenses: parseFloat(projection.other_variable_expenses) || 0,
        },
        projectedExpenses: projectedExpenses.map((expense) => ({
          id: expense.id.toString(),
          group: expense.group,
          subgroup: expense.subgroup,
          subgroup_id: expense.subgroup_id,
          amount: parseFloat(expense.amount),
          notes: expense.notes || "",
          section: expense.section,
        })),
        isLoading: false,
      });

      console.log("Projections loaded successfully");
    } catch (error) {
      console.error("Error fetching projections:", error);
      set({ isLoading: false });
    }
  },

  saveProjections: async (offerId, existingProjection = null) => {
    if (!offerId) {
      console.error("Offer ID is required");
      return;
    }

    try {
      set({ isSaving: true });

      // Get current state
      const { financials, projectedExpenses, ticketCounts } = get();

      // Import the query functions dynamically
      const { createOfferProjections, updateOfferProjections } = await import(
        "@/queries/events"
      );

      // Prepare data for API
      const projectionData = {
        offer: parseInt(offerId),
        ticket_projections: ticketCounts,
        gross_revenue: financials.grossRevenue,
        direct_costs: financials.directCosts,
        production_expenses: financials.production,
        operations_expenses: financials.operations,
        advertising_expenses: financials.advertising,
        other_variable_expenses: financials.variableExpenses,
        projected_expenses: projectedExpenses.map((expense) => ({
          id: expense.id,
          subgroup_id: expense.subgroup_id,
          amount: expense.amount,
          notes: expense.notes || "",
        })),
      };

      let result;

      // Update or create based on whether we have an existing projection
      if (existingProjection && existingProjection.id) {
        result = await updateOfferProjections(
          existingProjection.id,
          projectionData
        );
      } else {
        result = await createOfferProjections(projectionData);
      }

      set({ isSaving: false });

      if (result) {
        //toast.success("Projections saved successfully");
        return result;
      } else {
        toast.error("Failed to save projections");
        return null;
      }
    } catch (error) {
      console.error("Error saving projections:", error);
      toast.error("Error saving projections");
      set({ isSaving: false });
      return null;
    }
  },

  loadProjectionsFromExpenses: async (eventId, offerId) => {
    if (!eventId || !offerId) {
      console.error("Event ID and Offer ID are required");
      return;
    }

    try {
      set({ isLoading: true });

      // Use the recalculate endpoint instead of fetching expenses directly
      const result = await recalculateOfferProjections(offerId, eventId);

      if (!result) {
        toast.error("Failed to recalculate projections");
        set({ isLoading: false });
        return;
      }

      // Extract the projected expenses
      const projectedExpenses = result.projected_expenses || [];

      // Create a new state object with the recalculated data
      const newState = {
        financials: {
          grossRevenue: parseFloat(result.gross_revenue) || 0,
          directCosts: parseFloat(result.direct_costs) || 0,
          production: parseFloat(result.production_expenses) || 0,
          operations: parseFloat(result.operations_expenses) || 0,
          advertising: parseFloat(result.advertising_expenses) || 0,
          variableExpenses: parseFloat(result.other_variable_expenses) || 0,
        },
        projectedExpenses: projectedExpenses.map((expense) => ({
          id: expense.id.toString(),
          group: expense.group,
          subgroup: expense.subgroup,
          subgroup_id: expense.subgroup_id,
          amount: parseFloat(expense.amount),
          notes: expense.notes || "",
          section: expense.section,
        })),
      };

      // Update the store
      set({
        financials: newState.financials,
        projectedExpenses: newState.projectedExpenses,
        isLoading: false,
      });

      //toast.success("Projections loaded from expenses");
    } catch (error) {
      console.error("Error loading projections from expenses:", error);
      toast.error("Failed to load projections from expenses");
      set({ isLoading: false });
    }
  },

  setTicketCounts: (type, value) => {
    set((state) => ({
      ticketCounts: {
        ...state.ticketCounts,
        [type]: value,
      },
    }));
  },

  setFinancialField: (field, value) => {
    set((state) => ({
      financials: {
        ...state.financials,
        [field]: value,
      },
    }));
  },

  toggleSection: (section) => {
    set((state) => ({
      expandedSections: {
        ...state.expandedSections,
        [section]: !state.expandedSections[section],
      },
    }));
  },

  addExpense: (expense, section) => {
    set((state) => ({
      projectedExpenses: [...state.projectedExpenses, expense],
    }));
  },

  removeExpense: (expenseId) => {
    set((state) => ({
      projectedExpenses: state.projectedExpenses.filter(
        (expense) => expense.id !== expenseId
      ),
    }));
  },

  updateExpense: (updatedExpense) => {
    set((state) => {
      console.log("Updating expense in store:", updatedExpense);
      console.log("Current expenses:", state.projectedExpenses);

      const updatedExpenses = state.projectedExpenses.map((expense) => {
        // Convert both IDs to strings for comparison
        const expenseId = String(expense.id);
        const updatedId = String(updatedExpense.id);

        if (expenseId === updatedId) {
          console.log("Found matching expense to update:", expense);
          return updatedExpense;
        }
        return expense;
      });

      console.log("Updated expenses:", updatedExpenses);
      return {
        projectedExpenses: updatedExpenses,
      };
    });
  },

  moveExpense: (expenseId, fromSection, toSection) => {
    set((state) => {
      const expense = state.projectedExpenses.find(
        (e) => e.id.toString() === expenseId
      );
      if (!expense) return state;

      const updatedExpense = { ...expense, section: toSection };
      return {
        projectedExpenses: state.projectedExpenses.map((e) =>
          e.id.toString() === expenseId ? updatedExpense : e
        ),
      };
    });
  },

  getExpensesBySection: (section) => {
    return get().projectedExpenses.filter(
      (expense) => expense.section === section
    );
  },

  calculateSectionTotal: (section) => {
    const expenses = get().getExpensesBySection(section);
    return expenses.reduce((total, expense) => total + expense.amount * 100, 0);
  },

  calculateProjectedRevenue: (manifests) => {
    const { ticketCounts } = get();

    // If manifests are provided, use them to calculate revenue
    if (manifests) {
      let total = 0;

      for (const manifest of manifests) {
        if (manifest && manifest.name && ticketCounts[manifest.name]) {
          const count = parseInt(ticketCounts[manifest.name]) || 0;
          total += count * manifest.price;
        }
      }

      return total;
    }

    // Otherwise, use the stored gross revenue
    return get().financials.grossRevenue * 100;
  },

  calculateGrossMargin: () => {
    const { financials } = get();
    const revenue = parseFloat(financials.grossRevenue) || 0;
    const directCosts = parseFloat(financials.directCosts) || 0;

    if (revenue === 0) return 0;

    const grossMargin = (revenue - directCosts) * 100;
    return grossMargin;
  },

  calculateContributionMargin: () => {
    const { financials } = get();
    const revenue = parseFloat(financials.grossRevenue) || 0;
    const directCosts = parseFloat(financials.directCosts) || 0;
    const production = parseFloat(financials.production) || 0;
    const operations = parseFloat(financials.operations) || 0;
    const advertising = parseFloat(financials.advertising) || 0;
    const variableExpenses = parseFloat(financials.variableExpenses) || 0;

    const totalVariableExpenses =
      production + operations + advertising + variableExpenses;

    if (revenue === 0) return 0;

    const contributionMargin =
      (revenue - directCosts - totalVariableExpenses) * 100;
    return contributionMargin;
  },

  initializeTicketCounts: (manifests) => {
    if (!manifests || manifests.length === 0) return;

    const newTicketCounts = {};

    manifests.forEach((manifest) => {
      if (manifest && manifest.name) {
        newTicketCounts[manifest.name] = manifest.qty || "0";
      }
    });

    set({ ticketCounts: newTicketCounts });
  },

  updateGrossRevenue: (manifests) => {
    if (!manifests) return;

    const projectedRevenue = get().calculateProjectedRevenue(manifests);

    set((state) => ({
      financials: {
        ...state.financials,
        grossRevenue: projectedRevenue / 100, // Convert cents to dollars
      },
    }));
  },
}));

export default useProjectionsStore;
