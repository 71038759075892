export const OVERRIDES_SECTION_NAMES = {
  ROLLUPS: "rollups",
  HEADERLINER_SETTLEMENT: "headerliner_settlement",
} as const;

export const OVERRIDES_SUB_SECTION_NAMES = {
  TXT_GROSS: "txt_gross_revenue",
  TOTAL_GROSS: "total_gross_revenue",
  ADJ_NET: "adj_net_gross",
} as const;

export type OverridesSectionName =
  (typeof OVERRIDES_SECTION_NAMES)[keyof typeof OVERRIDES_SECTION_NAMES];
export type OverridesSubSectionName =
  (typeof OVERRIDES_SUB_SECTION_NAMES)[keyof typeof OVERRIDES_SUB_SECTION_NAMES];
