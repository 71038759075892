import dayjs from "dayjs";
import React, { useEffect } from "react";
import { Header } from "@/components/Header";
import LoadingPulse from "@/components/shared/LoadingPulse";
import { useQuery, useQueryClient } from "@tanstack/react-query";
import { useRecordVisit } from "@/queries/visit";
import { getLink } from "@/queries/links";
import { useParams, useNavigate } from "react-router-dom";
import { BreadCrumbs } from "@/components/BreadCrumbs";
import { usePageTitle } from "@/utils/pagetitle";
import Button from "@/components/Button";

export const LinkDetail = () => {
  const { id } = useParams();
  const navigate = useNavigate();
  const link = useQuery(["link-detail", id], () => getLink(id));
  const queryClient = useQueryClient();
  const visitMutation = useRecordVisit();
  usePageTitle(
    link.isSuccess
      ? `/c${String.fromCharCode(92)}${link.data.name}`
      : `/c${String.fromCharCode(92)}ave_good...`
  );

  if (link.isLoading) {
    return <LoadingPulse />;
  }

  useEffect(() => {
    if (link.isSuccess) {
      // Record visit
      visitMutation.mutate({
        itemID: link.data.id,
        itemType: "link",
        itemURL: `/links/${link.data.id}`,
      });
    }
  }, [link.isSuccess]);

  return (
    <>
      <Header />
      <div className="flex flex-col px-6 py-8 mx-auto min-h-screen lg:py-0">
        <BreadCrumbs
          links={[{ text: "Links", url: "/links" }, { text: link.data.name }]}
        />

        <div className="py-6 md:flex md:items-center md:justify-between">
          <div className="min-w-0 flex-1">
            <h2 className="text-2xl font-bold leading-7 dark:text-cave-white sm:truncate sm:text-3xl sm:tracking-tight">
              {link.data.name}
            </h2>
          </div>
          <div className="mt-4 flex md:ml-4 md:mt-0 justify-start align-top">
            <Button
              onClick={() => {
                navigate(`/links/edit/${link.data.id}`);
              }}
            >
              Edit Link
            </Button>
          </div>
        </div>

        <div className="bg-white dark:bg-gray-800 shadow overflow-hidden sm:rounded-lg mb-6">
          <div className="px-4 py-5 sm:px-6">
            <h3 className="text-lg leading-6 font-medium text-gray-900 dark:text-white">
              Link Information
            </h3>
            <p className="mt-1 max-w-2xl text-sm text-gray-500 dark:text-gray-400">
              Details about this TicketCount link.
            </p>
          </div>
          <div className="border-t border-gray-200 dark:border-gray-700">
            <dl>
              <div className="bg-gray-50 dark:bg-gray-900 px-4 py-5 sm:grid sm:grid-cols-3 sm:gap-4 sm:px-6">
                <dt className="text-sm font-medium text-gray-500 dark:text-gray-400">
                  Type
                </dt>
                <dd className="mt-1 text-sm text-gray-900 dark:text-white sm:mt-0 sm:col-span-2">
                  {link.data.type}
                </dd>
              </div>
              <div className="bg-white dark:bg-gray-800 px-4 py-5 sm:grid sm:grid-cols-3 sm:gap-4 sm:px-6">
                <dt className="text-sm font-medium text-gray-500 dark:text-gray-400">
                  Description
                </dt>
                <dd className="mt-1 text-sm text-gray-900 dark:text-white sm:mt-0 sm:col-span-2">
                  {link.data.description || "No description provided"}
                </dd>
              </div>
              <div className="bg-gray-50 dark:bg-gray-900 px-4 py-5 sm:grid sm:grid-cols-3 sm:gap-4 sm:px-6">
                <dt className="text-sm font-medium text-gray-500 dark:text-gray-400">
                  Shareable Link
                </dt>
                <dd className="mt-1 text-sm text-gray-900 dark:text-white sm:mt-0 sm:col-span-2">
                  <a
                    className="text-blue-500 underline hover:text-blue-600"
                    href={link.data.url}
                    target="_blank"
                    rel="noopener noreferrer"
                  >
                    {link.data.url}
                  </a>
                  <button
                    onClick={() => {
                      navigator.clipboard.writeText(link.data.url);
                      alert("Link copied to clipboard!");
                    }}
                    className="ml-3 inline-flex items-center rounded-md bg-gray-200 dark:bg-gray-700 px-2 py-1 text-xs font-medium text-gray-700 dark:text-gray-200 hover:bg-gray-300 dark:hover:bg-gray-600"
                  >
                    Copy
                  </button>
                </dd>
              </div>
              <div className="bg-white dark:bg-gray-800 px-4 py-5 sm:grid sm:grid-cols-3 sm:gap-4 sm:px-6">
                <dt className="text-sm font-medium text-gray-500 dark:text-gray-400">
                  Code
                </dt>
                <dd className="mt-1 text-sm text-gray-900 dark:text-white sm:mt-0 sm:col-span-2">
                  {link.data.code}
                </dd>
              </div>
            </dl>
          </div>
        </div>

        <div className="p-4 mb-6 bg-blue-50 dark:bg-blue-900 border-l-4 border-blue-500 text-blue-700 dark:text-blue-100">
          <p>
            Share this link with anyone who needs to view ticket information. No
            login required.
          </p>
        </div>
      </div>
    </>
  );
};
