import { Header } from "@/components/Header";
import { useQuery } from "@tanstack/react-query";
import { useNavigate } from "react-router-dom";
import {
  getEventsList,
  getEventsSettings,
  getEventRoles,
  getEventPeople,
} from "@/queries/events";
import { BreadCrumbs } from "@/components/BreadCrumbs";
import { LoadingPulse } from "@/components/shared/LoadingPulse";
import EventState from "@/components/EventState";
import Button from "@/components/Button";
import { usePerms } from "@/components/Auth";
import { usePageTitle } from "@/utils/pagetitle";
import { EventRow } from "./EventsList";
import { useState } from "react";

export const BlockBookingList = () => {
  const events = useQuery(
    ["events", "list", "blockbooking"],
    () => getEventsList({ company_filter: "mammoth_bb" }),
    getEventsSettings
  );
  const roles = useQuery(["event-roles"], () => getEventRoles());
  const navigate = useNavigate();
  const { perms } = usePerms();
  usePageTitle(events.isSuccess ? "/c/block-booking" : "/c/ave_good...");

  const [searchTerm, setSearchTerm] = useState("");

  const authorizedRoles = ["admin", "event_manager"];
  const isAuthorized = perms.data && authorizedRoles.includes(perms.data.role);

  if (events.isLoading || roles.isLoading) {
    return <LoadingPulse />;
  }

  const filteredEvents = events.data.filter((event) =>
    event.name.toLowerCase().includes(searchTerm.toLowerCase())
  );

  const eventRoleChoices = roles.data
    .filter((role) => role.active)
    .map((role) => ({ id: role.id, name: role.name }));

  return (
    <>
      <Header />
      <div className="flex flex-col px-6 py-8 mx-auto min-h-screen lg:py-0 dark:text-cave-white">
        <div className="w-full flex justify-between items-center mt-[.5rem]">
          <BreadCrumbs
            links={[{ text: "Block Booking Events", url: "/block-booking" }]}
          />
          <input
            type="text"
            value={searchTerm}
            onChange={(e) => setSearchTerm(e.target.value)}
            placeholder="Filter Events by Name"
            className="w-72 p-2 border border-gray-300 rounded-md"
          />
          <Button onClick={() => navigate("/block-booking")}>
            Event Cards
          </Button>
          <span>
            {perms.data.create_events && (
              <Button
                onClick={() => navigate("/events/add?company=block-booking")}
              >
                Create Block Booking Draft
              </Button>
            )}
          </span>
        </div>
        <div className="my-2 w-full shadow-md sm:rounded-lg">
          {events.isSuccess && (
            <table className="w-full text-sm text-left text-gray-500 dark:text-gray-400">
              <thead className="text-xs text-gray-700 uppercase bg-gray-100 dark:bg-gray-700 dark:text-gray-400">
                <tr>
                  <th scope="col" className="px-6 py-3 w-1/6">
                    Event Name
                  </th>
                  <th scope="col" className="px-6 py-3 w-1/6">
                    Date
                  </th>
                  <th scope="col" className="px-6 py-3 w-1/6">
                    Venue
                  </th>
                  <th scope="col" className="px-6 py-3 w-1/6">
                    Staff
                  </th>
                  <th scope="col" className="px-6 py-3 w-1/6">
                    {isAuthorized ? "Select Option" : "Status"}
                  </th>
                  <th scope="col" className="px-6 py-3 w-1/12">
                    Sold / Available
                  </th>
                  <th scope="col" className="px-6 py-3 w-1/12"></th>
                </tr>
              </thead>
              <tbody>
                {filteredEvents.map((event) => (
                  <EventRow
                    key={event.id}
                    event={event}
                    showSelectMenu={!isAuthorized}
                    roles={eventRoleChoices}
                  />
                ))}
              </tbody>
            </table>
          )}
        </div>
      </div>
    </>
  );
};
