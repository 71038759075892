import dayjs from "dayjs";
import { useEffect, useState } from "react";
import { Header } from "@/components/Header";
import * as yup from "yup";
import { getEvent } from "@/queries/events";
import { useParams, useNavigate } from "react-router-dom";
import { useQuery, useMutation, useQueryClient } from "@tanstack/react-query";
import {
  Datepicker,
  FormBuilder,
  Input,
  TextInput,
  FormSelect,
  FormMultiSelect,
  selectOptionToValue,
} from "@/components/Form";
import { stateChoices } from "@/utils/states";
import {
  updateEvent,
  markContractReceived,
  markContractReturned,
  markContractFullyExecuted,
} from "@/queries/events";
import {
  getEventCopros,
  createEventCopros,
  deactivateEventCopro,
} from "@/queries/accounting";
import { DocumentCheckIcon, TrashIcon } from "@heroicons/react/24/outline";
import { getVenues, getVenuesSettings } from "@/queries/venues";
import { getArtists, getArtistsSettings } from "@/queries/artists";
import { BreadCrumbs } from "@/components/BreadCrumbs";
import { FileUploader } from "@/components/Files/FileUploader";
import Button from "@/components/Button";
import { usePerms } from "@/components/Auth";
import { usePageTitle } from "@/utils/pagetitle";
import { getOffer, getOffers, updateOffer } from "../../queries/events";
import { useUser } from "@/components/Auth";

const ContractBox = (props) => {
  const { label, eventID, verb, mutationQuery, done } = props;
  const { perms } = usePerms();
  const queryClient = useQueryClient();

  const mutation = useMutation({
    mutationFn: (id) => {
      console.log("---> marking event");

      return mutationQuery(id);
    },
    onSuccess: async (data) => {
      console.dir(data);
      queryClient.invalidateQueries(["events"]);
      queryClient.setQueryData(["event-detail", data.id.toString()], data);
    },
  });

  const doAction = () => {
    mutation.mutate(eventID);
  };

  return (
    <div className="border border-blue-300 p-4 flex flex-inline justify-between w-full bg-blue-100 mb-4">
      <span className="mt-2 align-middle">
        {label} {verb}
      </span>
      {done && <DocumentCheckIcon className="h-8 w-8 text-green-500" />}
      {!done && perms.edit_contracts && (
        <button
          className="ml-4 rounded-md bg-blue-400 px-3 py-2 text-sm font-semibold text-cave-white shadow-sm hover:bg-blue-600 focus-visible:outline focus-visible:outline-2 focus-visible:outline-offset-2 focus-visible:outline-blue-600"
          onClick={() => doAction()}
        >
          Mark {label} {verb}
        </button>
      )}
    </div>
  );
};

const AddCoPro = (props) => {
  const { id } = useParams();
  const queryClient = useQueryClient();
  const [showForm, setShowForm] = useState(false);

  const mutation = useMutation({
    mutationFn: (data) => {
      console.log("---> updating expense");
      return createEventCopros(id, data);
    },
    onSuccess: async () => {
      setShowForm(false);
      queryClient.invalidateQueries(["event-detail"]);
      queryClient.invalidateQueries(["event-copros"]);
    },
  });

  const addSchema = yup.object().shape({
    name: yup.string().required(),
    description: yup.string(),
    percentage: yup.string(),
  });

  const handleSubmit = (data) => {
    console.log("--> adding copro");
    console.dir(data);
    mutation.mutate(data);
  };

  if (showForm) {
    return (
      <div className="border border-blue-300 bg-blue-100 dark:bg-gray-800 p-4">
        <h3 className="font-semibold dark:text-cave-white">Add New Copro</h3>
        <FormBuilder onSubmit={(data) => handleSubmit(data)} schema={addSchema}>
          <Input name="name" label="Name" autoFocus={true} />
          <TextInput name="description" label="Description" />
          <Input name="percentage" label="Percentage" />
          <div className="flex-inline float-right mb-4 mt-8">
            <button
              type="button"
              onClick={() => setShowForm(false)}
              className="ml-4 mr-4 rounded-md bg-gray-400 px-3 py-2 text-sm font-semibold text-gray-600 shadow-sm hover:bg-gray-300 focus-visible:outline focus-visible:outline-2 focus-visible:outline-offset-2 focus-visible:outline-blue-600"
            >
              Cancel
            </button>
            <Button type="submit">Add Copro</Button>
          </div>
        </FormBuilder>
      </div>
    );
  }

  return (
    <div className="w-full text-right">
      <button
        className="ml-4 rounded-md bg-blue-400 px-3 py-2 text-sm font-semibold text-cave-white shadow-sm hover:bg-blue-600 focus-visible:outline focus-visible:outline-2 focus-visible:outline-offset-2 focus-visible:outline-blue-600"
        onClick={() => setShowForm(true)}
      >
        Add Copro
      </button>
    </div>
  );
};

export const EventEditAdvance = () => {
  const [offerID, setOfferID] = useState(null);
  const { id } = useParams();
  const navigate = useNavigate();
  const event = useQuery(["event-detail", id], () => getEvent(id));
  const venues = useQuery(["venues"], () => getVenues(), getVenuesSettings);
  const artists = useQuery(["artists"], getArtists, getArtistsSettings);
  const copros = useQuery(["event-copros", id], () => getEventCopros(id));
  //const offers = useQuery(["offers"], () => getOffers());
  const offer = useQuery(["offer-detail", offerID], () => getOffer(offerID), {
    enabled: !!offerID,
  });
  const queryClient = useQueryClient();
  const getEventDate = `[${event?.data?.date ?? "TBD"}]` || "";
  usePageTitle(
    event.isSuccess
      ? `/c${String.fromCharCode(92)}${event.data.name}`
      : `/c${String.fromCharCode(92)}ave_good...`
  );

  const { user } = useUser();

  useEffect(() => {
    if (!event.data) {
      return;
    }
    const latestOffer = event.data.offers.sort(
      (a, b) => new Date(b.modified) - new Date(a.modified)
    )[0];

    if (latestOffer) {
      setOfferID(latestOffer.id);
      queryClient.invalidateQueries(["offer-detail", latestOffer.id]);
    }
  }, [event.data]);

  const fixTimes = (name, data) => {
    if (data[name] === "") {
      data[name] = null;
    }
    return data;
  };

  const updateEventMutation = useMutation({
    mutationFn: (data) => {
      console.log("---> updating event");

      data = fixTimes("door_time", data);
      data = fixTimes("opener_time", data);
      data = fixTimes("support_time", data);
      data = fixTimes("headliner_time", data);
      data = fixTimes("curfew_time", data);

      console.dir(data);

      return updateEvent(id, data);
    },
    onSuccess: async (data) => {
      queryClient.invalidateQueries(["events"]);
      queryClient.setQueryData(["event-detail", data.id], data);
    },
  });

  const updateOfferMutation = useMutation({
    mutationFn: (data) => {
      console.log("updating offer data", data);
      return updateOffer(offerID, data);
    },
    onSuccess: async (data) => {
      console.log("data", data);
      queryClient.invalidateQueries(["offer-detail", offerID]);
    },
  });

  const deactivateMutation = useMutation({
    mutationFn: (id) => {
      console.log("---> deactivating copro");
      return deactivateEventCopro(id);
    },
    onSuccess: async (data) => {
      queryClient.invalidateQueries(["event-detail"]);
      queryClient.invalidateQueries(["event-copros"]);
    },
  });

  if (
    event.isLoading ||
    venues.isLoading ||
    artists.isLoading ||
    copros.isLoading ||
    offer.isLoading
  ) {
    return <LoadingPulse />;
  }

  const updateSchema = yup.object().shape({
    event_offer_headliner: yup.string(),
    event_offer_support_1: yup.string(),
    event_offer_support_2: yup.string(),
    venue_name: yup.string(),
    venue_address: yup.string(),
    venue_city: yup.string(),
    venue_state: yup.string(),
    venue_zip: yup.string(),
    venue_phone: yup.string(),
    venue_website: yup.string(),
    venue_capacity: yup.number().positive().integer(),
    schedule_venue_access: yup.string(),
    schedule_breakfast: yup.string(),
    schedule_local_load_in: yup.string(),
    schedule_sound_check: yup.string(),
    schedule_security_meeting: yup.string(),
    schedule_dinner: yup.string(),
    schedule_doors: yup.string(),
    schedule_set_1: yup.string(),
    schedule_intermission: yup.string(),
    schedule_set_2: yup.string(),
    schedule_call_back: yup.string(),
    schedule_curfew: yup.string(),
    offer_production_adv: yup.string(),
    offer_ticketing: yup.string(),
    offer_tour_marketing: yup.string(),
    offer_local_marketing: yup.string(),
  });

  let values = {
    event_offer_headliner: event.data.offer_headliner || "",
    event_offer_support_1: event.data.offer_support_1 || "",
    event_offer_support_2: event.data.offer_support_2 || "",
    venue_name: event.data.venue.name || "",
    venue_address: event.data.venue.address || "",
    venue_city: event.data.venue.city || "",
    venue_state: event.data.venue.state || "",
    venue_zip: event.data.venue.zip || "",
    venue_phone: event.data.venue.phone || "",
    venue_website: event.data.venue.website || "",
    venue_capacity: event.data.venue.capacity || "",
    schedule_venue_access: event.data?.schedule?.venue_access || "",
    schedule_breakfast: event.data?.schedule?.breakfast || "",
    schedule_local_load_in: event.data?.schedule?.local_load_in || "",
    schedule_tour_load_in: event.data?.schedule?.tour_load_in || "",
    schedule_lunch: event.data?.schedule?.lunch || "",
    schedule_sound_check: event.data?.schedule?.sound_check || "",
    schedule_security_meeting: event.data?.schedule?.security_meeting || "",
    schedule_dinner: event.data?.schedule?.dinner || "",
    schedule_doors: event.data?.schedule?.doors || "",
    schedule_set_1: event.data?.schedule?.set_1 || "",
    schedule_intermission: event.data?.schedule?.intermission || "",
    schedule_set_2: event.data?.schedule?.set_2 || "",
    schedule_call_back: event.data?.schedule?.call_back || "",
    schedule_curfew: event.data?.schedule?.curfew || "",
    offer_production_adv: offer.data.production_adv || "",
    offer_ticketing: offer.data.ticketing || "",
    offer_tour_marketing: offer.data.tour_marketing || "",
    offer_local_marketing: offer.data.local_marketing || "",
  };

  const handleSubmit = (data) => {
    console.log("In submit");
    console.log("data", data);

    let eventObj = {};
    let eventScheduleObj = {};
    let offerObj = {};

    Object.keys(data).map((key) => {
      if (key.substring(0, 5) == "event") {
        eventObj[key.replace("event_", "")] = data[key];
      } else if (key.substring(0, 5) == "offer") {
        offerObj[key.replace("offer_", "")] = data[key];
      } else if (key.substring(0, 8) == "schedule") {
        eventScheduleObj[key.replace("schedule_", "")] = data[key];
      }
    });

    let eventData = {
      ...event.data,
      ...eventObj,
      schedule: Object.assign(event.data.schedule || {}, eventScheduleObj),
    };

    eventData.artists = eventData.artists ? [eventData.artists[0].id] : [0];

    updateEventMutation.mutate(eventData);

    let offerData = {
      ...{
        production_adv: offer.data.production_adv,
        local_marketing: offer.data.local_marketing,
        ticketing: offer.data.ticketing,
        tour_admat: offer.data.tour_admat,
        tour_marketing: offer.data.tour_marketing,
        onsale_info: offer.data.onsale_info,
        presale_info: offer.data.presale_info,
        contracts: offer.data.contracts,
        draft: offer.data.draft,
        name: offer.data.name,
        created: offer.data.created,
        modified: new Date().toISOString(),
        buyer_id: offer.data.buyer_id || user?.data?.id,
        created_by_id: offer.data.created_by_id || user?.data?.id,
        events: offer.data.events || [event.data.id],
        active: offer.data.active,
        closed: offer.data.closed,
      },
      ...offerObj,
    };

    updateOfferMutation.mutate(offerData);

    navigate(`/events/${id}`);
  };

  return (
    <>
      <Header />
      <div
        className="
        flex flex-col px-6 py-8 mx-auto min-h-screen lg:py-0"
      >
        <BreadCrumbs
          links={[
            { text: "Events", url: `/events/` },
            {
              text: `${event.data.name} ${getEventDate}`,
              url: `/events/${event.data.id}`,
            },
            { text: "Edit Advance" },
          ]}
        />

        <h3 className="text-xl font-bold mb-4 dark:text-cave-white">
          Edit Advance - {event.data.name}
        </h3>
        <div className="py-6 min-w-full">
          <FormBuilder
            defaultValues={values}
            onSubmit={(data) => handleSubmit(data)}
            schema={updateSchema}
          >
            <Input name="event_offer_headliner" label="Headliner" />
            <Input name="event_offer_support_1" label="Support 1" />
            <Input name="event_offer_support_2" label="Support 2" />
            <Input name="event_offer_support_2" label="Support 2" />
            <Input name="event_offer_support_2" label="Support 2" />
            <Input
              name="schedule_venue_access"
              type="time"
              label="Venue Access"
            />
            <Input name="schedule_breakfast" type="time" label="Breakfast" />
            <Input
              name="schedule_local_load_in"
              type="time"
              label="Local Load In"
            />
            <Input
              name="schedule_tour_load_in"
              type="time"
              label="Tour Load In"
            />
            <Input name="schedule_lunch" type="time" label="Lunch" />
            <Input
              name="schedule_sound_check"
              type="time"
              label="Sound Check"
            />
            <Input
              name="schedule_security_meeting"
              type="time"
              label="Security Meeting"
            />
            <Input name="schedule_dinner" type="time" label="Dinner" />
            <Input name="schedule_doors" type="time" label="Doors" />
            <Input name="schedule_set_1" type="time" label="Set 1" />
            <Input
              name="schedule_intermission"
              type="time"
              label="Intermission"
            />
            <Input name="schedule_set_2" type="time" label="Set 2" />
            <Input name="schedule_call_back" type="time" label="Call Back" />
            <Input name="schedule_curfew" type="time" label="Curfew" />
            <Input name="offer_production_adv" label="Production Advance" />
            <Input name="offer_ticketing" label="Ticketing" />
            <Input name="offer_tour_marketing" label="Tour Marketing" />
            <Input name="offer_local_marketing" label="Local Marketing" />
            <div className="flex-inline float-right mb-12 mt-8">
              <button
                type="button"
                onClick={() => navigate(`/events/${event.data.id}`)}
                className="ml-4 mr-4 rounded-md bg-gray-400 px-3 py-2 text-sm font-semibold text-gray-600 shadow-sm hover:bg-gray-300 focus-visible:outline focus-visible:outline-2 focus-visible:outline-offset-2 focus-visible:outline-blue-600"
              >
                Cancel
              </button>
              <Button type="submit">Save</Button>
            </div>
          </FormBuilder>
        </div>
        <div className="mb-8">&nbsp;</div>
      </div>
    </>
  );
};
