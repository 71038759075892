import { AgGridReact } from "ag-grid-react";
import { useState, useEffect, useMemo } from "react";

import { useEventOfferStore } from "@/store/eventOfferStore";
import { amountDisplay } from "@/utils/money";

const DealTypeAgGrid = ({ variables, isLoading, event, updatedProperties }) => {
  const [rowData, setRowData] = useState([]);
  const { eventOfferForm } = useEventOfferStore();
  // Initialize rowData based on event type
  useEffect(() => {
    if (!variables || !event) return;

    let newRowData = [];

    if (event.offer_type === "vs") {
      // For VS deal type
      newRowData = [
        {
          header: "TOTAL EXPENSES",
          value: variables.total_expenses || 0,
          isChanged: updatedProperties.includes("total_expenses"),
          isMoney: true,
        },
        {
          header: "LESS GUARANTEE",
          value: variables.the_deal_amount || 0,
          isChanged: updatedProperties.includes("the_deal_amount"),
          isMoney: true,
        },
        {
          header: "NET EXPENSES",
          value:
            variables.net_expenses ||
            variables.total_expenses - variables.the_deal_amount ||
            0,
          isChanged: updatedProperties.includes("net_expenses"),
          isMoney: true,
          highlight: true,
        },
      ];
    } else if (event.offer_type === "promoter-profit") {
      // For Promoter Profit deal type
      newRowData = [
        {
          header: "TOTAL EXPENSES",
          value: variables.total_expenses || 0,
          isChanged: updatedProperties.includes("total_expenses"),
          isMoney: true,
        },
        {
          header: "PROMOTER PROFIT",
          value: variables.promoter_profit || 0,
          isChanged: updatedProperties.includes("promoter_profit"),
          isMoney: true,
        },
        {
          header: "SPLIT POINT",
          value: variables.split_point || 0,
          isChanged: updatedProperties.includes("split_point"),
          isMoney: true,
          highlight: true,
        },
      ];
    }

    setRowData(newRowData);
  }, [variables, event, updatedProperties]);

  // Column definitions for AgGrid
  const columnDefs = useMemo(
    () => [
      {
        field: "header",
        width: 200,
        editable: false,
        cellStyle: {
          fontFamily: "Montserrat, sans-serif",
          fontWeight: "bold",
          backgroundColor: "#E5E7EB", // Light gray background like in PDF
        },
        cellClass: (params) => (params.data.highlight ? "font-extrabold" : ""),
        flex: 1,
      },
      {
        field: "value",
        flex: 1,
        editable: false,
        cellStyle: {
          fontFamily: "Montserrat, sans-serif",
          fontWeight: "bold",
          textAlign: "right",
          backgroundColor: "#93C5FD", // Light blue background like in PDF
        },
        valueFormatter: (params) => {
          // Handle money formatting
          if (params.data.isMoney) {
            try {
              const value = params.value || 0;
              return amountDisplay(value);
            } catch (error) {
              console.error("Error formatting money value:", error);
              return "$0.00";
            }
          }
          return params.value;
        },
        cellClass: (params) => {
          return `${params.data.isChanged ? "glow-text" : ""} ${params.data.highlight ? "font-extrabold" : ""
            }`;
        },
      },
    ],
    []
  );

  // Default column definition
  const defaultColDef = useMemo(
    () => ({
      resizable: true,
      sortable: false,
      filter: false,
    }),
    []
  );

  // Get the title based on event type
  const getTitle = () => {
    if (event.offer_type === "vs") {
      return `VERSUS ${event.offer_percentage}% DEAL`;
    } else if (event.offer_type === "promoter-profit") {
      return "SPLIT POINT";
    } else if (event.offer_type === "flat") {
      return "FLAT";
    } else if (event.offer_type === "nbor") {
      return `DOOR DEAL`;
    }
    return "DEAL";
  };

  // Only render if we have data and it's a relevant deal type
  if (!variables || !event) {
    return null;
  }

  return (
    <div className="w-full border-4 border-gray-300">
      <div className="bg-gray-300 text-xl font-extrabold text-center py-2 px-2 font-montserrat">
        {getTitle()}
      </div>
      <div
        className="ag-theme-alpine"
        style={{ height: "auto", width: "100%" }}
      >
        <AgGridReact
          rowData={rowData}
          columnDefs={columnDefs}
          defaultColDef={defaultColDef}
          singleClickEdit
          stopEditingWhenCellsLoseFocus
          loading={isLoading}
          domLayout="autoHeight"
          headerHeight={0}
          rowHeight={35}
          className="deal-type-grid"
        />
      </div>
      <style jsx>{`
        :global(.deal-type-grid .ag-cell) {
          font-family: "Montserrat", sans-serif;
          padding: 8px 5px;
          border-bottom: 1px solid #e5e7eb;
        }
        :global(.deal-type-grid .ag-row:last-child .ag-cell) {
          border-bottom: none;
        }
      `}</style>
    </div>
  );
};

export default DealTypeAgGrid;
