import { useMutation, useQueryClient, useQuery } from "@tanstack/react-query";
import { AgGridReact } from "ag-grid-react";
import { useState, useEffect, useRef, useMemo, useCallback } from "react";
import Select from "react-select";
import { toast } from "react-toastify";

import {
  deleteExpense,
  createEventExpenseOffer,
  getOfferExpenseGroups,
  updateEventExpense,
} from "@/queries/accounting";
import { moneyColumnConfig, registerMoneyEditor } from "@/utils/ag-grid";
import {
  amountDisplay,
  amountStrToInt,
  formatDisplayToCents,
} from "@/utils/money";

export const FIELD_UPDATE_ANIMATION_TIME = 2000;

const ExpenseAgGrid = ({
  displayExpenses,
  advertisingExpenses,
  totalAdvertisingExpenses,
  id,
}) => {
  const [selectedGroup, setSelectedGroup] = useState("");
  const [inputValue, setInputValue] = useState("");
  const [inputHeader, setInputHeader] = useState("");
  const [advExpenseUpdate, setAdvExpenseUpdate] = useState(false);
  const [updatedProperties, setUpdatedProperties] = useState([]);
  const [isAdding, setIsAdding] = useState(false);
  const [notes, setNotes] = useState("");
  const [deletingExpenseId, setDeletingExpenseId] = useState(null);
  const [rowData, setRowData] = useState([]);
  const gridRef = useRef(null);

  const queryClient = useQueryClient();
  const groups = useQuery(["expense-groups"], () => getOfferExpenseGroups());

  const advTotalRef = useRef(totalAdvertisingExpenses);

  // Initialize rowData when expenses change
  useEffect(() => {
    const data = [];

    if (displayExpenses) {
      // Map display expenses
      data.push(
        ...displayExpenses.map((expense) => ({
          id: expense.id,
          header: expense.subgroup_name
            ? expense.subgroup_name.toUpperCase()
            : "",
          value: expense.amount,
          otherParams: expense,
          format: "money",
          editable: true,
          isChanged: updatedProperties.includes(
            expense.subgroup_name.toUpperCase()
          ),
          isDeleting: deletingExpenseId === expense.id,
          notes: expense.notes || "",
          type: "expense",
        }))
      );
    }

    // Add advertising total
    if (advertisingExpenses) {
      data.push({
        header: "ADVERTISING TOTAL",
        value: totalAdvertisingExpenses,
        highlight: true,
        isChanged: advExpenseUpdate,
        type: "header",
      });

      // Map advertising expenses
      data.push(
        ...advertisingExpenses.map((expense) => ({
          id: expense.id,
          header: expense.subgroup_name
            ? expense.subgroup_name.toUpperCase()
            : "",
          value: expense.amount,
          otherParams: expense,
          format: "money",
          editable: true,
          isChanged: updatedProperties.includes(
            expense.subgroup_name.toUpperCase()
          ),
          isDeleting: deletingExpenseId === expense.id,
          type: "advertising",
        }))
      );
    }

    setRowData(data);
  }, [
    displayExpenses,
    advertisingExpenses,
    totalAdvertisingExpenses,
    updatedProperties,
    deletingExpenseId,
  ]);

  // Update advExpenseUpdate when totalAdvertisingExpenses changes
  useEffect(() => {
    if (advTotalRef.current !== totalAdvertisingExpenses) {
      setAdvExpenseUpdate(true);
      setTimeout(() => {
        setAdvExpenseUpdate(false);
      }, FIELD_UPDATE_ANIMATION_TIME);
    }
    advTotalRef.current = totalAdvertisingExpenses;
  }, [totalAdvertisingExpenses]);

  // Reset updatedProperties after animation
  useEffect(() => {
    if (updatedProperties?.length > 0) {
      setTimeout(() => {
        setUpdatedProperties([]);
      }, FIELD_UPDATE_ANIMATION_TIME);
    }
  }, [updatedProperties]);

  // Delete expense mutation
  const deleteExpenseMutation = useMutation({
    mutationFn: async (id) => {
      return await deleteExpense(id);
    },
    onSuccess: async () => {
      await invalidateQueries();
      setDeletingExpenseId(null);
    },
    onError: () => {
      setDeletingExpenseId(null);
    },
  });

  // Create expense mutation
  const createExpenseMutation = useMutation({
    mutationFn: async (data) => {
      return await createEventExpenseOffer(id, data);
    },
    onSuccess: async (data) => {
      setTimeout(() => {
        invalidateQueries();
      }, 500);
    },
    onError: () => {
      toast.error("Failed to create expense");
    },
  });

  // Update expense mutation
  const updateExpenseMutation = useMutation({
    mutationFn: async (data) => {
      return await updateEventExpense(data);
    },
    onMutate: async (newData) => {
      await queryClient.cancelQueries(["event-detail", id]);
      await queryClient.cancelQueries(["variables-offer-pdf", id]);
      await queryClient.cancelQueries(["expenses-offer-pdf", id]);
      await queryClient.cancelQueries(["event-rollups", id]);

      const previousExpenses = queryClient.getQueryData([
        "expenses-offer-pdf",
        id,
      ]);
      const previousEvent = queryClient.getQueryData(["event-detail", id]);

      if (previousExpenses) {
        queryClient.setQueryData(["expenses-offer-pdf", id], (oldData) => {
          if (!oldData) return oldData;

          const updatedExpenses = JSON.parse(JSON.stringify(oldData));
          const expenseIndex = updatedExpenses.findIndex(
            (exp) => exp.id === newData.id
          );

          if (expenseIndex >= 0) {
            updatedExpenses[expenseIndex] = {
              ...updatedExpenses[expenseIndex],
              ...newData,
            };
          }

          return updatedExpenses;
        });
      }

      if (previousEvent) {
        queryClient.setQueryData(["event-detail", id], (oldData) => {
          if (!oldData || !oldData.expenses) return oldData;

          const updatedEvent = JSON.parse(JSON.stringify(oldData));
          const expenseIndex = updatedEvent.expenses.findIndex(
            (exp) => exp.id === newData.id
          );

          if (expenseIndex >= 0) {
            updatedEvent.expenses[expenseIndex] = {
              ...updatedEvent.expenses[expenseIndex],
              ...newData,
            };
          }

          return updatedEvent;
        });
      }

      return { previousExpenses, previousEvent };
    },
    onSuccess: async () => {
      await invalidateQueries();
    },
    onError: (err, newData, context) => {
      // Rollback on error
      if (context?.previousExpenses) {
        queryClient.setQueryData(
          ["expenses-offer-pdf", id],
          context.previousExpenses
        );
      }
      if (context?.previousEvent) {
        queryClient.setQueryData(["event-detail", id], context.previousEvent);
      }
      toast.error("Failed to update expense");
    },
  });

  // Helper function to invalidate queries
  const invalidateQueries = useCallback(async () => {
    await Promise.all([
      queryClient.invalidateQueries(["event-detail", id]),
      queryClient.invalidateQueries(["variables-offer-pdf", id]),
      queryClient.invalidateQueries(["expenses-offer-pdf", id]),
      queryClient.invalidateQueries(["event-rollups", id]),
    ]);
    await Promise.all([
      queryClient.refetchQueries(["event-detail", id]),
      queryClient.refetchQueries(["variables-offer-pdf", id]),
      queryClient.refetchQueries(["expenses-offer-pdf", id]),
      queryClient.refetchQueries(["event-rollups", id]),
    ]);
  }, [queryClient, id]);

  // Handle delete expense
  const handleDeleteExpense = useCallback(
    async (expenseId) => {
      if (window.confirm("Are you sure you want to delete this expense?")) {
        setDeletingExpenseId(expenseId);
        await deleteExpenseMutation.mutateAsync(expenseId);
      }
    },
    [deleteExpenseMutation]
  );

  // Handle save new expense
  const handleSaveNewExpense = useCallback(async () => {
    const amount = inputValue.includes(".")
      ? formatDisplayToCents(inputValue)
      : inputValue * 100;

    await createExpenseMutation.mutate({
      amount: amount,
      subgroup: inputHeader && parseInt(inputHeader?.value?.split(";id:")[1]),
      payment_method: "",
      check_number: "-1",
      description: notes ?? "",
      notes: notes ?? "",
      is_offer: true,
      exclude_artist: false,
      exclude_copro: false,
      exclude_final: false,
    });

    setIsAdding(false);
    setSelectedGroup("");
    setInputHeader("");
    setInputValue("");
    setNotes("");
  }, [createExpenseMutation, inputValue, inputHeader, notes]);

  // Column definitions for AgGrid
  const columnDefs = useMemo(
    () => [
      {
        headerName: "Expense",
        field: "header",
        width: 200,
        cellRenderer: (params) => {
          if (params.data.highlight) {
            return <div className="font-extrabold">{params.value}</div>;
          }

          return (
            <div className="flex items-center gap-2">
              <button
                onClick={() => handleDeleteExpense(params.data.id)}
                className="min-w-[20px] min-h-[20px] flex items-center justify-center bg-red-500 text-white text-xs rounded hover:bg-red-600 disabled:opacity-50"
                disabled={params.data.isDeleting}
                aria-label="Delete Expense"
              >
                X
              </button>
              {params.value}
            </div>
          );
        },
      },
      {
        headerName: "Amount",
        field: "value",
        flex: 1,
        editable: (params) => params.data.editable,
        isMoney: true,
        ...moneyColumnConfig,
        cellClass: (params) =>
          `${params.data.isChanged ? "glow-text" : ""} ${params.data.highlight ? "font-extrabold" : ""}`,
        onCellValueChanged: (params) => {
          if (params.data.otherParams) {
            updateExpenseMutation.mutate({
              amount: params.newValue,
              subgroup: params.data.otherParams.subgroup,
              payment_method: "",
              check_number: "-1",
              description: params.data.notes ?? "",
              notes: params.data.notes ?? "",
              is_offer: true,
              exclude_artist: false,
              exclude_copro: false,
              exclude_final: false,
              expense_id: params.data.id,
              id: params.data.id,
              event: id,
            });
          }
        },
      },
      {
        headerName: "Notes",
        field: "notes",
        width: "200px",
        cellRenderer: (params) =>
          params.value ? (
            params.value
          ) : (
            <div className="text-gray-400 group duration-200 ease-in-out w-full h-full">
              {" "}
              <span className="text-gray-100 block  opacity-0 group-hover:opacity-100 duration-200">
                {/* <span className="block px-1">Enter Notes</span> */}
              </span>
            </div>
          ),
        editable: (params) => params.data.type === "expense",
        hide: (params) => params.data.type !== "expense",
        onCellValueChanged: (params) => {
          if (params.data.otherParams) {
            updateExpenseMutation.mutate({
              amount: params.data.otherParams.amount,
              subgroup: params.data.otherParams.subgroup,
              payment_method: "",
              check_number: "-1",
              description: params.newValue,
              notes: params.newValue,
              is_offer: true,
              exclude_artist: false,
              exclude_copro: false,
              exclude_final: false,
              id: params.data.id,
              expense_id: params.data.id,
              event: id,
            });
          }
        },
      },
    ],
    [handleDeleteExpense, updateExpenseMutation, id]
  );

  // Default column definition
  const defaultColDef = useMemo(
    () => ({
      resizable: true,
      sortable: true,
      filter: true,
    }),
    []
  );

  // Grid options with money editor
  const gridOptions = useMemo(() => ({}), []);
  registerMoneyEditor(gridOptions);

  return (
    <div className="w-full">
      <div
        className="ag-theme-alpine"
        style={{ height: "auto", minHeight: "200px", width: "100%" }}
      >
        <AgGridReact
          ref={gridRef}
          rowData={rowData}
          singleClickEdit
          stopEditingWhenCellsLoseFocus
          columnDefs={columnDefs}
          defaultColDef={defaultColDef}
          domLayout="autoHeight"
          {...gridOptions}
        />
      </div>

      <div
        className={`w-full inline-flex justify-end gap-2 ${!isAdding
            ? "border-0"
            : "bg-cave-blue-1 border-cave-blue-3 border-4 p-4 m-2"
          }`}
        style={{ marginBottom: "1rem", marginTop: "1rem" }}
      >
        {!isAdding ? (
          <button
            onClick={() => setIsAdding(true)}
            className="bg-gray-400 disabled:bg-gray-200 rounded px-4 py-1 text-cave-white font-sans text-lg mt-2"
            aria-label="Add New Expense"
          >
            Add Expense
          </button>
        ) : (
          <div className="flex w-full gap-20">
            <div className="mt-2 w-full">
              <Select
                options={groups.data?.map((group) => ({
                  label: group.name,
                  value: `${group.name.toLowerCase().replace(/\s+/g, "-")};id:${group.id}`,
                }))}
                value={selectedGroup}
                onChange={setSelectedGroup}
                placeholder="Choose Subgroup"
              />
              {selectedGroup && (
                <Select
                  options={groups.data
                    ?.flatMap(
                      (group) =>
                        group.name === selectedGroup.label &&
                        group?.subgroups?.map((subgroup) => ({
                          label: subgroup.name,
                          value: `${subgroup.name.toLowerCase().replace(/\s+/g, "-")};id:${subgroup.id}`,
                        }))
                    )
                    .filter(Boolean)}
                  value={inputHeader}
                  onChange={setInputHeader}
                  placeholder="Choose Expense Type"
                  className="mt-2"
                />
              )}
              <input
                type="text"
                className="border-2 border-dotted w-full p-1 mt-2 text-sm"
                placeholder="Amount"
                value={inputValue}
                onChange={(e) => setInputValue(e.target.value)}
                aria-label="Expense Amount"
              />
              <label className="text-sm" htmlFor="notes">
                Notes
              </label>
              <input
                type="text"
                className="border-2 border-dotted w-full p-1 mt-2 text-sm"
                placeholder="Notes (optional)"
                value={notes}
                onChange={(e) => setNotes(e.target.value)}
                aria-label="Expense Notes"
              />
            </div>
            <div className="flex self-start gap-2">
              <button
                onClick={() => setIsAdding(false)}
                className="bg-cave-red disabled:bg-gray-200 rounded px-4 py-1 text-cave-white font-sans text-sm mt-2"
                aria-label="Cancel Adding Expense"
              >
                Cancel
              </button>
              <button
                onClick={handleSaveNewExpense}
                className="bg-blue-500 disabled:bg-gray-200 rounded px-4 py-1 text-cave-white font-sans text-sm mt-2"
                aria-label="Save New Expense"
                disabled={!selectedGroup || !inputHeader || !inputValue}
              >
                Save
              </button>
            </div>
          </div>
        )}
      </div>
    </div>
  );
};

export default ExpenseAgGrid;
