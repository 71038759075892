import React, { useState } from "react";
import { Disclosure, Dialog, Transition } from "@headlessui/react";
import {
  TrashIcon,
  PlusIcon,
  ChevronDownIcon,
  XMarkIcon,
} from "@heroicons/react/24/outline";
import Select from "react-select";
import { useQuery, useMutation, useQueryClient } from "@tanstack/react-query";
import { toast } from "react-toastify";
import { parseISO } from "date-fns";

import { getTasks, createTask, updateTask, deleteTask } from "@/queries/tasks";
import { getStaff } from "@/queries/people";
import { CaveError } from "@/types";
import { People } from "@/types/people";
import { Task, NewTask, UpdateTask } from "@/types/accounting-dashboard";
import ConfirmModal from "../Common/ConfirmModal";
import classNames from "classnames";
import Button from "../Button";
import { useUser } from "@/hooks/useUser";

// const customSelectStyles = {
//   control: (provided: any, state: any) => ({
//     ...provided,
//     backgroundColor: "#374151",
//     borderColor: "#4B5563",
//     borderRadius: "0.375rem",
//     minHeight: "2.5rem",
//     height: "2.5rem",
//     color: "white",
//     boxShadow: state.isFocused ? "0 0 0 1px #4B5563" : "none",
//     "&:hover": { borderColor: "#4B5563" },
//   }),
//   input: (provided: any) => ({ ...provided, color: "white" }),
//   placeholder: (provided: any) => ({ ...provided, color: "white" }),
//   singleValue: (provided: any) => ({ ...provided, color: "white" }),
//   menuPortal: (base: any) => ({ ...base, zIndex: 9999 }),
//   menu: (provided: any) => ({ ...provided, backgroundColor: "#374151" }),
//   option: (provided: any, state: any) => ({
//     ...provided,
//     backgroundColor:
//       state.isSelected || state.isFocused ? "#4B5563" : "#374151",
//     color: "white",
//     "&:active": { backgroundColor: "#4B5563" },
//   }),
//   dropdownIndicator: (provided: any) => ({ ...provided, color: "white" }),
//   indicatorSeparator: (provided: any) => ({
//     ...provided,
//     backgroundColor: "transparent",
//   }),
// };

const priorityOptions = [
  { value: "LOW", label: "Low" },
  { value: "MEDIUM", label: "Medium" },
  { value: "HIGH", label: "High" },
  { value: "URGENT", label: "Urgent" },
];

function formatUTCDate(dateString: string): string {
  const date = parseISO(dateString); // parse as UTC
  const year = date.getUTCFullYear();
  const month = (date.getUTCMonth() + 1).toString().padStart(2, "0");
  const day = date.getUTCDate().toString().padStart(2, "0");
  return `${year}-${month}-${day}`;
}

function getTodayUTCString(): string {
  const now = new Date();
  const year = now.getUTCFullYear();
  const month = (now.getUTCMonth() + 1).toString().padStart(2, "0");
  const day = now.getUTCDate().toString().padStart(2, "0");
  return `${year}-${month}-${day}`;
}

interface TaskFormProps {
  values: NewTask;
  onChange: (field: keyof NewTask, value: string) => void;
  peopleOptions: any[];
  priorityOptions: any[];
  parentTaskOptions: any[];
  onSubmit: () => void;
  onCancel: () => void;
}

const TaskForm: React.FC<TaskFormProps> = ({
  values,
  onChange,
  peopleOptions,
  priorityOptions,
  parentTaskOptions,
  onSubmit,
  onCancel,
}) => {
  return (
    <div className="mt-4 space-y-4">
      <div>
        <label className="block text-sm font-medium text-gray-400">Title</label>
        <input
          type="text"
          value={values.title}
          onChange={(e) => onChange("title", e.target.value)}
          className="mt-1 px-3 py-2 border border-gray-300 rounded-md shadow-sm focus:outline-none focus:ring-indigo-500 focus:border-indigo-500 block w-full"
        />
      </div>
      <div>
        <label className="block text-sm font-medium text-gray-400">
          Assigned To
        </label>
        <Select
          isSearchable
          options={peopleOptions}
          value={
            peopleOptions.find(
              (option: any) => option.value.toString() === values.assignedTo
            ) || null
          }
          onChange={(selectedOption: any) =>
            onChange("assignedTo", selectedOption?.value.toString() || "")
          }
          placeholder="Select person"
          //   styles={customSelectStyles}
          className="mt-1"
          classNamePrefix="react-select"
        />
      </div>
      {/* <div>
        <label className="block text-sm font-medium text-gray-400">
          Department
        </label>
        <input
          type="text"
          value={values.department}
          onChange={(e) => onChange("department", e.target.value)}
          className="mt-1 px-3 py-2 border border-gray-300 rounded-md shadow-sm focus:outline-none focus:ring-indigo-500 focus:border-indigo-500 block w-full"
        />
      </div> */}
      <div>
        <label className="block text-sm font-medium text-gray-400">
          Due Date
        </label>
        <input
          type="date"
          value={values.dueDate}
          onChange={(e) => onChange("dueDate", e.target.value)}
          className="mt-1 px-3 py-2 border border-gray-300 rounded-md shadow-sm focus:outline-none focus:ring-indigo-500 focus:border-indigo-500 block w-full"
        />
      </div>
      <div>
        <label className="block text-sm font-medium text-gray-400">
          Priority
        </label>
        <Select
          isSearchable
          menuPlacement="top"
          options={priorityOptions}
          value={
            priorityOptions.find((opt) => opt.value === values.priority) || null
          }
          onChange={(selectedOption: any) =>
            onChange("priority", selectedOption?.value || "MEDIUM")
          }
          placeholder="Select priority"
          //   styles={customSelectStyles}
          className="mt-1"
          classNamePrefix="react-select"
        />
      </div>
      <div>
        <label className="block text-sm font-medium text-gray-400">
          Parent Task
        </label>
        <Select
          isSearchable
          menuPlacement="top"
          options={parentTaskOptions}
          value={
            parentTaskOptions.find(
              (opt: any) => opt.value.toString() === values.parentTask
            ) || null
          }
          onChange={(selectedOption: any) =>
            onChange("parentTask", selectedOption?.value.toString() || "")
          }
          placeholder="Select parent task (optional)"
          //   styles={customSelectStyles}
          className="mt-1"
          classNamePrefix="react-select"
        />
      </div>
      <div className="mt-6 flex justify-end space-x-3">
        <button
          onClick={onCancel}
          className="px-4 py-2 text-sm font-medium text-gray-400 hover:text-white"
        >
          CANCEL
        </button>
        <Button
          onClick={onSubmit}
          variant="success"
          className="px-4 py-2 text-sm font-medium"
        >
          Submit
        </Button>
      </div>
    </div>
  );
};

const AccountingDashboardTasks: React.FC = ({
  canAddTasks = false,
}: {
  canAddTasks?: boolean;
}) => {
  const queryClient = useQueryClient();
  const { data: allTasksData } = useQuery<Task[], CaveError>(
    ["tasks"],
    getTasks,
    {
      staleTime: 300000,
    }
  );
  const { data: staffData } = useQuery<People[], CaveError>(
    ["people"],
    getStaff
  );
  const { personId } = useUser();
  const tasksData = allTasksData?.filter((task) => {
    if (personId) {
      return (
        task.assigned_to_id === personId ||
        task.assigned_by_id === personId ||
        task?.collaborators?.includes(personId)
      );
    }
    return false;
  });

  const peopleOptions = staffData
    ? staffData
        .filter((p) => p.user?.first_name)
        .map((person: People) => ({
          value: person.user.person_id,
          label: person.user.first_name + " " + person.user.last_name,
        }))
        .sort((a, b) => a.label.localeCompare(b.label))
    : [];

  const parentTaskOptions = tasksData
    ? tasksData.map((task) => ({
        value: task.id,
        label: task.description,
      }))
    : [];

  /** Include tasks with status "todo" or tasks whose due date is today's UTC date. */
  function isTodayTask(t: Task) {
    return (
      t.status.toLowerCase() === "todo" ||
      (t.due_date && formatUTCDate(t.due_date) === getTodayUTCString())
    );
  }

  function isOverdueTask(t: Task) {
    return (
      t.due_date &&
      new Date(t.due_date).getTime() < new Date().getTime() &&
      t.status.toLowerCase() !== "completed"
    );
  }

  /** Filter tasks by category. */
  function getDisplayedTasks(): Task[] {
    if (!tasksData) return [];
    switch (selectedTaskCategory) {
      case "all":
        return tasksData;
      case "todo":
        return tasksData.filter(isTodayTask);
      case "scheduled":
        return tasksData.filter((t) => t.status.toLowerCase() === "scheduled");
      case "completed":
        return tasksData.filter((t) => t.status.toLowerCase() === "completed");
      case "overdue":
        return tasksData.filter(
          (t) => t.status.toLowerCase() === "overdue" || isOverdueTask(t)
        );
      default:
        return tasksData;
    }
  }

  const [selectedTasks, setSelectedTasks] = useState<string[]>([]);
  const [selectedTaskCategory, setSelectedTaskCategory] = useState<
    "all" | "todo" | "scheduled" | "completed" | "overdue"
  >("all");
  const [isAddTaskOpen, setIsAddTaskOpen] = useState(false);
  const [isEditTaskOpen, setIsEditTaskOpen] = useState(false);
  const [formValues, setFormValues] = useState<NewTask>({
    title: "",
    assignedTo: "",
    department: "",
    dueDate: "",
    status: "todo",
    priority: "MEDIUM",
    parentTask: "",
  });
  const [editingTask, setEditingTask] = useState<Task | null>(null);
  const [showDeleteModal, setShowDeleteModal] = useState(false);
  const [taskToDelete, setTaskToDelete] = useState<string[]>([]);

  const createTaskMutation = useMutation(createTask, {
    onSuccess: () => queryClient.invalidateQueries(["tasks"]),
  });
  const updateTaskMutation = useMutation(
    ({ id, data }: { id: number; data: any }) => updateTask(id, data),
    { onSuccess: () => queryClient.invalidateQueries(["tasks"]) }
  );
  const deleteTaskMutation = useMutation(deleteTask, {
    onSuccess: () => queryClient.invalidateQueries(["tasks"]),
  });

  function handleFormChange(field: keyof NewTask, value: string) {
    setFormValues((prev) => ({ ...prev, [field]: value }));
  }

  function handleAddTask() {
    if (formValues.title) {
      const payload = {
        description: formValues.title,
        assigned_to_id: formValues.assignedTo
          ? parseInt(formValues.assignedTo)
          : null,
        due_date: formValues.dueDate || null,
        parent_task_id: formValues.parentTask
          ? parseInt(formValues.parentTask)
          : null,
        status:
          formValues.status.toUpperCase() === "TODO"
            ? "PENDING"
            : formValues.status.toUpperCase(),
        priority: formValues.priority.toUpperCase(),
      };
      createTaskMutation.mutate(payload);
      setIsAddTaskOpen(false);
      setFormValues({
        title: "",
        assignedTo: "",
        department: "",
        dueDate: "",
        status: "todo",
        priority: "MEDIUM",
        parentTask: "",
      });
      toast.success("Task added successfully");
    }
  }

  function handleEditTask(task: Task) {
    setEditingTask(task);
    setFormValues({
      title: task.description,
      assignedTo: task.assigned_to_id ? task.assigned_to_id.toString() : "",
      department: task.department || "",
      dueDate: task.due_date ? formatUTCDate(task.due_date) : "",
      status: task.status.toLowerCase() as
        | "todo"
        | "scheduled"
        | "completed"
        | "overdue",
      priority: task.priority ? task.priority.toUpperCase() : "MEDIUM",
      parentTask: task.parent_task_id ? task.parent_task_id.toString() : "",
    });
    setIsEditTaskOpen(true);
  }

  function handleUpdateTask() {
    if (editingTask) {
      const payload = {
        description: formValues.title,
        due_date: formValues.dueDate || null,
        status:
          formValues.status.toUpperCase() === "TODO"
            ? "PENDING"
            : formValues.status.toUpperCase(),
        assigned_to_id: formValues.assignedTo
          ? parseInt(formValues.assignedTo)
          : null,
        parent_task_id: formValues.parentTask
          ? parseInt(formValues.parentTask)
          : null,
        priority: formValues.priority.toUpperCase(),
      };
      updateTaskMutation.mutate({ id: Number(editingTask.id), data: payload });
      setIsEditTaskOpen(false);
      setEditingTask(null);
      toast.success("Task updated successfully");
    }
  }

  function handleDeleteTasks(ids: string[]) {
    ids.forEach((id) => deleteTaskMutation.mutate(Number(id)));
    setSelectedTasks([]);
    toast.success("Task(s) deleted successfully");
  }

  function handleStatusChange(ids: string[], newStatus: Task["status"]) {
    ids.forEach((id) => {
      const task = tasksData?.find((t) => t.id === id);
      if (task) {
        const updatedTask: UpdateTask = { ...task, status: newStatus };
        updateTaskMutation.mutate({
          id: Number(task.id),
          data: { ...updatedTask, description: task.description },
        });
        toast.success("Task status updated successfully");
      }
    });
    setSelectedTasks([]);
  }

  const displayedTasks = getDisplayedTasks();

  function TaskCategoryButtons() {
    const counts = {
      all: tasksData?.length || 0,
      todo: tasksData ? tasksData.filter(isTodayTask).length : 0,
      scheduled: tasksData
        ? tasksData.filter((t) => t.status.toLowerCase() === "scheduled").length
        : 0,
      completed: tasksData
        ? tasksData.filter((t) => t.status.toLowerCase() === "completed").length
        : 0,
      overdue: tasksData
        ? tasksData.filter((t) => t.status.toLowerCase() === "overdue")
            .length || tasksData.filter(isOverdueTask).length
        : 0,
    };

    const labels: Record<
      "all" | "todo" | "scheduled" | "completed" | "overdue",
      string
    > = {
      all: "All",
      todo: "Today",
      scheduled: "Scheduled",
      completed: "Completed",
      overdue: "Overdue",
    };

    const colors: Record<
      "all" | "todo" | "scheduled" | "completed" | "overdue",
      string
    > = {
      all: "bg-gray-500",
      todo: "bg-blue-400",
      scheduled: "bg-orange-400",
      completed: "bg-green-400",
      overdue: "bg-red-400",
    };

    return (
      <div className="flex flex-wrap gap-4">
        {(["all", "todo", "scheduled", "completed", "overdue"] as const).map(
          (cat) => (
            <button
              key={cat}
              onClick={() => setSelectedTaskCategory(cat)}
              className={`flex items-center justify-center space-x-2 w-full lg:w-40 py-2 rounded-md ${cat === "overdue" && counts.overdue > 0 ? "ring-4 ring-cave-red" : ""} ${
                selectedTaskCategory === cat
                  ? "bg-gray-700"
                  : "bg-gray-800 hover:bg-gray-700"
              }`}
            >
              <div
                className={`w-2 h-2 ${colors[cat]}  ${cat === "overdue" && counts.overdue > 0 ? " ring-2 ring-red-600" : ""} rounded-full`}
              />
              <span
                className={`${cat === "overdue" && counts.overdue > 0 ? "text-cave-red" : ""}`}
              >
                <span className="">{labels[cat]} </span>
                <span className="font-bold">({counts[cat]})</span>
              </span>
            </button>
          )
        )}
      </div>
    );
  }

  return (
    <div className="p-4 text-white">
      <ConfirmModal
        message="Are you sure you want to delete the selected task(s)?"
        show={showDeleteModal}
        onConfirm={() => {
          handleDeleteTasks(taskToDelete);
          setShowDeleteModal(false);
        }}
        onCancel={() => setShowDeleteModal(false)}
      />
      <div className="flex gap-4 justify-between items-center mb-4">
        <h2 className="text-2xl font-semibold">Tasks</h2>
        <div className="flex flex-wrap gap-4 lg:gap-2">
          {selectedTasks.length > 0 && (
            <div className="flex space-x-2">
              <button
                onClick={() => {
                  setShowDeleteModal(true);
                  setTaskToDelete(selectedTasks);
                }}
                className="flex items-center space-x-2 px-4 py-2 bg-red-600/20 text-red-400 rounded-lg text-sm"
              >
                <TrashIcon className="w-4 h-4" />
                <span>DELETE</span>
              </button>
              <button
                onClick={() => {}}
                className="px-4 py-2 bg-gray-700 rounded-lg text-sm"
              >
                NOT TODAY
              </button>
              <button
                onClick={() => handleStatusChange(selectedTasks, "completed")}
                className="px-4 py-1 text-sm bg-green-600 rounded hover:bg-green-500"
              >
                MARK COMPLETE
              </button>
            </div>
          )}
          {canAddTasks && (
            <Button
              onClick={() => {
                setIsAddTaskOpen(true);
                setFormValues({
                  title: "",
                  assignedTo: "",
                  department: "",
                  dueDate: "",
                  status: "todo",
                  priority: "MEDIUM",
                  parentTask: "",
                });
              }}
              withRipple
              variant="success"
            >
              <PlusIcon className="w-5 h-5" />
              <span>ADD TASK</span>
            </Button>
          )}
        </div>
      </div>

      <div className="mb-4">
        <TaskCategoryButtons />
      </div>

      <div className="space-y-2 min-h-[320px]">
        {displayedTasks.length === 0 && (
          <div className="text-left pt-4 text-gray-400">No tasks found</div>
        )}
        {displayedTasks.map((task) => (
          <div key={task.id} className="bg-gray-800 rounded-lg">
            <Disclosure>
              {({ open }) => (
                <>
                  <div className="flex items-center p-4">
                    <input
                      type="checkbox"
                      checked={selectedTasks.includes(task.id)}
                      onChange={() =>
                        setSelectedTasks((prev) =>
                          prev.includes(task.id)
                            ? prev.filter((tid) => tid !== task.id)
                            : [...prev, task.id]
                        )
                      }
                      className="mr-4 h-4 w-4 rounded border-gray-600 bg-gray-700 text-green-600 focus:ring-green-500"
                    />
                    <Disclosure.Button className="flex flex-1 items-center">
                      <ChevronDownIcon
                        className={`w-4 h-4 mr-4 transform transition-transform ${
                          open ? "rotate-180" : ""
                        }`}
                      />
                      <div>
                        <h4 className="text-sm font-medium text-left">
                          {task.description}
                        </h4>
                      </div>
                    </Disclosure.Button>
                  </div>
                  <Transition
                    show={open}
                    as={React.Fragment}
                    enter="transition transform duration-300 ease-out"
                    enterFrom="scale-y-0 opacity-0"
                    enterTo="scale-y-100 opacity-100"
                    leave="transition transform duration-500 ease-in"
                    leaveFrom="scale-y-100 opacity-100"
                    leaveTo="scale-y-0 opacity-0"
                  >
                    <Disclosure.Panel className="origin-top overflow-hidden px-12 pb-4 border-t border-gray-700 pt-4">
                      <div className="space-y-4">
                        <div className="flex space-x-4">
                          <div className="flex-1">
                            <label className="block text-sm text-gray-400">
                              Assigned to
                            </label>
                            <span className="text-sm">
                              {task.assigned_to_name}
                            </span>
                            <label className="block text-sm text-gray-400">
                              Assigned by
                            </label>
                            <span className="text-sm">
                              {task.assigned_by_name || "N/A"}
                            </span>
                            <label className="block text-sm text-gray-400">
                              Dependencies
                            </label>
                            <span className="text-sm">N/A</span>
                            <label className="block text-sm text-gray-400">
                              Collaborators
                            </label>
                            <span className="text-sm">
                              N/A
                              {/* {task.assigned_by_name ?? "N/A"} */}
                            </span>
                          </div>
                          {/* <div className="flex-1">
                            <label className="block text-sm text-gray-400">
                              Department
                            </label>
                            <span className="text-sm">{task.department}</span>
                          </div> */}
                          <div className="flex-1">
                            <label className="block text-sm text-gray-400">
                              Due Date
                            </label>
                            <span className="text-sm">
                              {task.due_date
                                ? formatUTCDate(task.due_date)
                                : ""}
                            </span>
                          </div>
                        </div>
                        <div className="flex gap-4 flex-wrap justify-between">
                          <div className="flex space-x-2">
                            <button
                              onClick={() => {
                                setShowDeleteModal(true);
                                setTaskToDelete([task.id]);
                              }}
                              className="bg-red-400 text-white px-1.5 py-2 rounded-md text-sm hover:bg-red-300 duration-150"
                            >
                              Delete
                            </button>
                            <button
                              onClick={() => handleEditTask(task)}
                              className="bg-blue-400 text-white px-2.5 py-2 rounded-md text-sm hover:bg-blue-300 duration-150"
                            >
                              Edit
                            </button>
                          </div>
                          <div className="flex space-x-2">
                            <Button
                              className={classNames(
                                "px-4 py-1 text-sm",
                                task.dueDate !== getTodayUTCString() &&
                                  "pointer-events-none opacity-70"
                              )}
                              variant="secondary"
                              disabled={task.dueDate !== getTodayUTCString()}
                              onClick={() => {
                                updateTaskMutation.mutate({
                                  id: Number(task.id),
                                  data: {
                                    ...task,
                                    due_date: formatUTCDate(
                                      new Date(
                                        new Date().getTime() +
                                          24 * 60 * 60 * 1000
                                      ).toISOString()
                                    ),
                                  },
                                });
                              }}
                            >
                              NOT TODAY
                            </Button>
                            <Button
                              onClick={() =>
                                handleStatusChange([task.id], "completed")
                              }
                              className={`px-4 py-1 text-sm ${task.status.toLowerCase() === "completed" ? "pointer-events-none opacity-70" : ""}`}
                              variant="success"
                            >
                              MARK COMPLETE
                            </Button>
                          </div>
                        </div>
                      </div>
                    </Disclosure.Panel>
                  </Transition>
                </>
              )}
            </Disclosure>
          </div>
        ))}
      </div>

      {/* Add Task Dialog */}
      <Transition show={isAddTaskOpen} as={React.Fragment}>
        <Dialog
          as="div"
          className="fixed inset-0 z-[999999] overflow-y-auto"
          onClose={() => setIsAddTaskOpen(false)}
        >
          <div className="min-h-screen px-4 text-center">
            <Transition.Child
              as={React.Fragment}
              enter="ease-out duration-300"
              enterFrom="opacity-0"
              enterTo="opacity-100"
              leave="ease-in duration-200"
              leaveFrom="opacity-100"
              leaveTo="opacity-0"
            >
              <Dialog.Overlay className="fixed inset-0 bg-black opacity-30" />
            </Transition.Child>
            <span
              className="inline-block h-screen align-middle"
              aria-hidden="true"
            >
              &#8203;
            </span>
            <Transition.Child
              as={React.Fragment}
              enter="ease-out duration-300"
              enterFrom="opacity-0 scale-95"
              enterTo="opacity-100 scale-100"
              leave="ease-in duration-200"
              leaveFrom="opacity-100 scale-100"
              leaveTo="opacity-0 scale-95"
            >
              <div className="inline-block w-full max-w-md p-6 my-8 overflow-hidden text-left align-middle transition-all transform bg-gray-800 shadow-xl rounded-2xl">
                <Dialog.Title
                  as="h3"
                  className="text-lg font-medium leading-6 text-white"
                >
                  Add New Task
                </Dialog.Title>
                <button
                  onClick={() => setIsAddTaskOpen(false)}
                  className="absolute top-4 right-4 text-gray-400 hover:text-white"
                >
                  <XMarkIcon className="w-6 h-6" />
                </button>
                <TaskForm
                  values={formValues}
                  onChange={handleFormChange}
                  peopleOptions={peopleOptions}
                  priorityOptions={priorityOptions}
                  parentTaskOptions={parentTaskOptions}
                  onSubmit={handleAddTask}
                  onCancel={() => setIsAddTaskOpen(false)}
                />
              </div>
            </Transition.Child>
          </div>
        </Dialog>
      </Transition>

      {/* Edit Task Dialog */}
      <Transition show={isEditTaskOpen} as={React.Fragment}>
        <Dialog
          as="div"
          className="fixed inset-0 z-[999999] overflow-y-auto"
          onClose={() => setIsEditTaskOpen(false)}
        >
          <div className="min-h-screen px-4 text-center">
            <Transition.Child
              as={React.Fragment}
              enter="ease-out duration-300"
              enterFrom="opacity-0"
              enterTo="opacity-100"
              leave="ease-in duration-200"
              leaveFrom="opacity-100"
              leaveTo="opacity-0"
            >
              <Dialog.Overlay className="fixed inset-0 bg-black opacity-30" />
            </Transition.Child>
            <span
              className="inline-block h-screen align-middle"
              aria-hidden="true"
            >
              &#8203;
            </span>
            <Transition.Child
              as={React.Fragment}
              enter="ease-out duration-300"
              enterFrom="opacity-0 scale-95"
              enterTo="opacity-100 scale-100"
              leave="ease-in duration-200"
              leaveFrom="opacity-100 scale-100"
              leaveTo="opacity-0 scale-95"
            >
              <div className="inline-block w-full max-w-md p-6 my-8 overflow-hidden text-left align-middle transition-all transform bg-gray-800 shadow-xl rounded-2xl">
                <Dialog.Title
                  as="h3"
                  className="text-lg font-medium leading-6 text-white"
                >
                  Edit Task
                </Dialog.Title>
                <button
                  onClick={() => setIsEditTaskOpen(false)}
                  className="absolute top-4 right-4 text-gray-400 hover:text-white"
                >
                  <XMarkIcon className="w-6 h-6" />
                </button>
                {editingTask && (
                  <TaskForm
                    values={formValues}
                    onChange={handleFormChange}
                    peopleOptions={peopleOptions}
                    priorityOptions={priorityOptions}
                    parentTaskOptions={parentTaskOptions}
                    onSubmit={handleUpdateTask}
                    onCancel={() => setIsEditTaskOpen(false)}
                  />
                )}
              </div>
            </Transition.Child>
          </div>
        </Dialog>
      </Transition>
    </div>
  );
};

export default AccountingDashboardTasks;
