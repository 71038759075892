/* import classnames from "classnames"; */
/* import dayjs from "dayjs"; */
import { useNavigate, useParams /* useNavigate */ } from "react-router-dom";
import { DarkToggle } from "@/components/Header/TopHeader/DarkToggle";
import { UserMenu } from "@/components/Header/TopHeader/UserMenu";
import { Logo } from "@/components/Header/TopHeader/Logo";
import {
  useQuery /* useMutation, useQueryClient */,
  useQueryClient,
} from "@tanstack/react-query";
import {
  getEvent,
  getEventPeople,
  getEventSettlementInfo,
  updateEvent,
} from "@/queries/events";
import { getArtistEventRollups } from "@/queries/accounting";
import { usePageTitle } from "@/utils/pagetitle";
import { ToastContainer } from "react-toastify";
import SettlementTabs from "@/components/Settlement/SettlementTabs";
import { SettlementTab } from "@/types/settlement";
import SettlementHeader from "@/components/Settlement/SettlementHeader";
import SettlementArtistPage from "@/components/Settlement/SettlementArtistPage";
import SettlementCoproPage from "@/components/Settlement/SettlementCoproPage";
import SettlementFinalPage from "@/components/Settlement/SettlementFinalPage";

import React, { useEffect, useRef, useState } from "react";
import { BreadCrumbs } from "@/components/BreadCrumbs";

export const EventSettlement = ({ isPDF = false }: { isPDF?: boolean }) => {
  const { id, type: settlementType } = useParams<{
    id: string;
    type: SettlementTab;
  }>();
  const pageIndex = settlementType;
  const navigate = useNavigate();
  if (!settlementType) {
    navigate(`/events/settlement/artist/${id}`);
    return null;
  }
  const event = useQuery(["event-detail", id], () => getEvent(id));
  const queryClient = useQueryClient();
  const handleUpdateEvent = async (
    id = event.data?.id,
    data: Record<string, unknown>
  ) => {
    await updateEvent(id, {
      ...event.data,
      artists: event.data.artists?.map((artist) => artist.id),
      buyer: event.data.buyer.id,
      manifests: event.data.manifests?.map((manifest) => manifest.id),
      venue: event.data.venue.id,
      ...data,
    });
    event.refetch();
    rollups.refetch();
  };
  const settlement = useQuery(["event-settlement-info", id], () =>
    getEventSettlementInfo(id)
  );
  const people = useQuery(["event", "people", id], () => getEventPeople(id));
  const rollups = useQuery(["event-rollups", "artist", id], () =>
    getArtistEventRollups(id)
  );
  const tabToTitle = () => {
    if (pageIndex === "artist") {
      return "ARTIST";
    }
    if (pageIndex === "copro") {
      return "COPRO";
    }
    return "FINAL";
  };
  usePageTitle(
    event.isSuccess
      ? `/c${String.fromCharCode(92)}${tabToTitle()} Settle`
      : `/c${String.fromCharCode(92)}ave_good...`
  );
  const [getEventDate, setGetEventDate] = useState("");

  if (
    event.isLoading ||
    people.isLoading ||
    rollups.isLoading ||
    settlement.isLoading
  ) {
    return (
      <div className="text-center text-gray-700 dark:text-gray-300">
        Loading...
      </div>
    );
  }

  return (
    <>
      <div className="!bg-white pb-32 px-8">
        {!isPDF && (
          <nav className="bg-white border-gray-200 px-4 lg:px-6 py-2.5 dark:bg-white">
            <div className="flex justify-between items-center mb-6">
              <div className="flex justify-start items-center">
                <Logo />
              </div>
              <div className="flex justify-between items-center lg:order-2">
                <DarkToggle />
                <span className="hidden mx-2 w-px h-5 bg-gray-200 dark:bg-gray-600 md:inline"></span>
                <UserMenu />
                <ToastContainer />
              </div>
            </div>
            <div className="text-xs pl-4">
              <BreadCrumbs
                links={[
                  { text: "Events", url: `/events/` },
                  {
                    text: `${event.data.name} ${getEventDate}`,
                    url: `/events/${event.data.id}`,
                  },
                  { text: "Settlement" },
                ]}
              />
            </div>
          </nav>
        )}

        {!isPDF && <SettlementTabs />}
        <div
          classNames={`${
            isPDF ? "!bg-white !print:bg-white !important" : "bg-white"
          } px-4 lg:px-12 pb-56 lg:pb-24`}
        >
          <SettlementHeader isPDF={isPDF} />
          {pageIndex === "artist" && <SettlementArtistPage />}
          {pageIndex === "copro" && <SettlementCoproPage />}
          {pageIndex === "final" && <SettlementFinalPage />}
        </div>
      </div>
    </>
  );
};

export default EventSettlement;
