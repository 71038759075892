import React, { useEffect, useState } from "react";
import { CompanyLogoAnimation } from "@/components/Animations/CompanyLogoAnimation";
import { Header } from "@/components/Header";
import { useQuery } from "@tanstack/react-query";
import { useNavigate } from "react-router-dom";
import { BreadCrumbs } from "@/components/BreadCrumbs";
import Button from "@/components/Button";
import { getVendors, getVendorsSettings } from "@/queries/vendors";
import { usePageTitle } from "@/utils/pagetitle";
import { ToggleSwitch } from "@/components/Button/ToggleSwitch";
import { SortedTable } from "../../components/SortedTable/SortedTable";

const VendorRow = (props) => {
  const { vendor } = props;
  const navigate = useNavigate();

  return (
    <tr
      className="bg-cave-white border-b dark:bg-gray-800 dark:border-gray-700 hover:bg-gray-50 dark:hover:bg-gray-600"
      onClick={() => navigate(`/vendors/${vendor.id}`)}
    >
      <th
        scope="row"
        className="px-6 py-4 font-medium text-cave-black whitespace-nowrap dark:text-cave-white"
      >
        {vendor.name}
      </th>
      <td className="px-6 py-4">{vendor.poc_name}</td>
      <td className="px-6 py-4">{vendor.poc_email}</td>
      <td className="px-6 py-4">{vendor.poc_phone}</td>
      <td className="px-6 py-4">{vendor.future_events}</td>
    </tr>
  );
};

const FilteredVendorsTable = ({
  vendors,
  headers,
  total,
  canEdit,
  title,
  handleEditClick,
}) => {
  const navigate = useNavigate();

  const formatData = (vendors) => {
    // calculate total for each manifest
    return (
      vendors &&
      vendors.map((manifest) =>
        Object.assign(manifest, { total: manifest.price * manifest.qty })
      )
    );
  };

  const handleFormatCell = (accessor, rowData, rowIndex) => {
    if (accessor === "on_sale") {
      return rowData["on_sale"]
        ? dayjs(rowData["on_sale"]).format("MMM D, YYYY")
        : "No Date";
    }
    // return nothing for all other values
  };

  const handleSort = (key, item) => {
    if (key === "total") {
      return item["price"] * item["qty"];
    } else {
      return item[key];
    }
  };

  return (
    <SortedTable
      total={total}
      data={formatData(vendors)}
      headers={headers}
      customSort={handleSort}
      formatCell={handleFormatCell}
      onEditClick={canEdit ? handleEditClick : null}
      handleRowClick={(row, index) => navigate(`/vendors/${row.id}`)}
      hasSorting
      hasFilter
      initialSortKey="name"
      initialSortOrder="asc"
    />
  );
};

export const VendorsHome = () => {
  //const vendors = useQuery(["vendors"], () => getVendors(), getVendorsSettings)
  const {
    data: vendors,
    isLoading,
    isSuccess,
  } = useQuery(["vendors"], getVendors, getVendorsSettings);

  const navigate = useNavigate();
  const [isFadingOut, setIsFadingOut] = useState(false);
  usePageTitle(
    isSuccess
      ? `/c${String.fromCharCode(92)}vendors`
      : `/c${String.fromCharCode(92)}ave_good...`
  );
  const filteredVendors = (vendors || []).filter((vendor) => !vendor.is_agency);

  useEffect(() => {
    if (isLoading) {
      setIsFadingOut(false);
    } else if (isSuccess) {
      setTimeout(() => {
        setIsFadingOut(true);
      }, 1000); // Adjust the delay as needed
    }
  }, [isLoading, isSuccess]);

  const [useLogoFallback, setUseLogoFallback] = useState(false);

  useEffect(() => {
    // Check if cave_loading25.mp4 exists
    fetch("/static/images/cave_loading25.mp4", { method: "HEAD" })
      .then((res) => {
        if (!res.ok) {
          setUseLogoFallback(true);
        }
      })
      .catch(() => setUseLogoFallback(true));
  }, []);

  return (
    <div className="relative min-h-screen bg-cave-white dark:bg-cave-black">
      {isLoading &&
        (useLogoFallback ? (
          <CompanyLogoAnimation
            isLoading={isLoading}
            isFadingOut={isFadingOut}
          />
        ) : (
          <video
            autoPlay
            muted
            loop
            className={`fixed top-0 left-0 w-full h-full object-cover transition-opacity duration-1000 ${
              !isLoading || isFadingOut ? "opacity-0" : "opacity-100"
            }`}
          >
            <source src="/static/images/cave_loading25.mp4" type="video/mp4" />
          </video>
        ))}
      <div
        className={`transition-opacity duration-1000 ${
          isLoading && !isFadingOut ? "opacity-0" : "opacity-100"
        }`}
      >
        <Header />
        <div className="flex flex-col px-6 py-8 mx-auto min-h-screen lg:py-0">
          <div className="w-full flex justify-between items-center">
            <BreadCrumbs links={[{ text: "Vendors", url: "/vendors" }]} />
            <span>
              <Button onClick={() => navigate("/vendors/add")}>
                Add Vendor
              </Button>
            </span>
          </div>
          <div className="my-2 w-full overflow-x-auto shadow-md">
            <FilteredVendorsTable
              vendors={filteredVendors}
              headers={[
                { header: "VENDOR NAME", accessor: "name" },
                { header: "POC NAME", accessor: "poc_name" },
                { header: "POC EMAIL", accessor: "poc_email" },
                { header: "POC PHONE", accessor: "poc_phone" },
                { header: "FUTURE EVENTS", accessor: "future_events" },
              ]}
            />
          </div>
        </div>
      </div>
    </div>
  );
};
