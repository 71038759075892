import dayjs from "dayjs";
import React, { useEffect, useState } from "react";
import { Header } from "@/components/Header";
import * as yup from "yup";
import { getEvent } from "@/queries/events";
import { useParams, useNavigate } from "react-router-dom";
import { useQuery, useMutation, useQueryClient } from "@tanstack/react-query";
import { LoadingPulse } from "@/components/shared/LoadingPulse";
import { toast } from "react-toastify";
import {
  Datepicker,
  FormBuilder,
  Input,
  TextInput,
  FormSelect,
  FormMultiSelect,
  selectOptionToValue,
} from "@/components/Form";
import { stateChoices } from "@/utils/states";
import { companyChoices } from "@/utils/states";
import {
  updateEvent,
  markContractReceived,
  markContractReturned,
  markContractFullyExecuted,
} from "@/queries/events";
import {
  getEventCopros,
  createEventCopros,
  deactivateEventCopro,
} from "@/queries/accounting";
import { DocumentCheckIcon, TrashIcon } from "@heroicons/react/24/outline";
import { getVenues, getVenuesSettings } from "@/queries/venues";
import { getArtists, getArtistsSettings } from "@/queries/artists";
import { getStaff } from "@/queries/people";
import { BreadCrumbs } from "@/components/BreadCrumbs";
import { FileUploader } from "@/components/Files/FileUploader";
import Button from "@/components/Button";
import { usePerms } from "@/components/Auth";
import { usePageTitle } from "@/utils/pagetitle";

const ContractBox = (props) => {
  const { label, eventID, verb, mutationQuery, done } = props;
  const { perms } = usePerms();
  const queryClient = useQueryClient();

  const mutation = useMutation({
    mutationFn: () => mutationQuery(eventID),
    onSuccess: (data) => {
      queryClient.invalidateQueries(["events"]);
      queryClient.setQueryData(["event-detail", eventID.toString()], data);
    },
    onError: (error) => {
      toast.error(`Error updating contract status: ${error.message}`);
    },
  });

  return (
    <div className="border border-blue-300 p-4 flex flex-inline justify-between w-full bg-blue-100 mb-4">
      <span className="mt-2 align-middle">
        {label} {verb}
      </span>
      {done && <DocumentCheckIcon className="h-8 w-8 text-green-500" />}
      {!done && perms.edit_contracts && (
        <button
          className="ml-4 rounded-md bg-blue-400 px-3 py-2 text-sm font-semibold text-cave-white shadow-sm hover:bg-blue-600 focus-visible:outline focus-visible:outline-2 focus-visible:outline-offset-2 focus-visible:outline-blue-600"
          onClick={() => mutation.mutate()}
        >
          Mark {label} {verb}
        </button>
      )}
    </div>
  );
};

const AddCoPro = (props) => {
  const { id } = useParams();
  const queryClient = useQueryClient();
  const [showForm, setShowForm] = useState(false);

  const mutation = useMutation({
    mutationFn: (data) => createEventCopros(id, data),
    onSuccess: () => {
      setShowForm(false);
      queryClient.invalidateQueries(["event-detail"]);
      queryClient.invalidateQueries(["event-copros"]);
    },
    onError: (error) => {
      toast.error(`Error adding co-pro: ${error.message}`);
    },
  });

  const addSchema = yup.object().shape({
    name: yup.string().required("Name is required"),
    description: yup.string(),
    percentage: yup.string().required("Percentage is required"),
  });

  const handleSubmit = (data) => {
    // Normalize buyer data if present
    if (data.buyers && Array.isArray(data.buyers)) {
      data.buyers = data.buyers
        .map((buyer) => {
          // Extract the person_id consistently
          let personId;

          if (buyer && typeof buyer === "object") {
            personId = buyer.person_id || buyer.value || buyer.id;
          } else if (
            typeof buyer === "number" ||
            (typeof buyer === "string" && !isNaN(parseInt(buyer)))
          ) {
            personId = parseInt(buyer);
          }

          if (!personId) {
            console.warn("Could not extract person ID from buyer:", buyer);
            return null;
          }

          // Return a consistent format
          return personId;
        })
        .filter(Boolean); // Remove any null entries
    }

    mutation.mutate(data);
  };

  if (showForm) {
    return (
      <div className="border border-blue-300 bg-blue-100 dark:bg-gray-800 p-4">
        <h3 className="font-semibold dark:text-cave-white">Add New Copro</h3>
        <FormBuilder onSubmit={handleSubmit} schema={addSchema}>
          <Input name="name" label="Name" autoFocus={true} />
          <TextInput name="description" label="Description" />
          <Input name="percentage" label="Percentage" />
          <div className="flex-inline float-right mb-4 mt-8">
            <button
              type="button"
              onClick={() => setShowForm(false)}
              className="ml-4 mr-4 rounded-md bg-gray-400 px-3 py-2 text-sm font-semibold text-gray-600 shadow-sm hover:bg-gray-300 focus-visible:outline focus-visible:outline-2 focus-visible:outline-offset-2 focus-visible:outline-blue-600"
            >
              Cancel
            </button>
            <Button type="submit">Add Copro</Button>
          </div>
        </FormBuilder>
      </div>
    );
  }

  return (
    <div className="w-full text-right">
      <button
        className="ml-4 rounded-md bg-blue-400 px-3 py-2 text-sm font-semibold text-cave-white shadow-sm hover:bg-blue-600 focus-visible:outline focus-visible:outline-2 focus-visible:outline-offset-2 focus-visible:outline-blue-600"
        onClick={() => setShowForm(true)}
      >
        Add Copro
      </button>
    </div>
  );
};

export const EventEdit = () => {
  const { id } = useParams();
  const navigate = useNavigate();
  const queryClient = useQueryClient();
  const { perms } = usePerms();

  // Queries
  const event = useQuery(["event-detail", id], () => getEvent(id));
  const venues = useQuery(["venues"], () => getVenues(), getVenuesSettings);
  const artists = useQuery(["artists"], getArtists, getArtistsSettings);
  const staff = useQuery(["staff"], getStaff);
  const copros = useQuery(["event-copros", id], () => getEventCopros(id));

  const getEventDate = event?.data?.date ? `[${event.data.date}]` : "[TBD]";

  usePageTitle(
    event.isSuccess
      ? `/c${String.fromCharCode(92)}${event.data.name}`
      : `/c${String.fromCharCode(92)}ave_good...`
  );

  // Process form data before submission
  const processFormData = (data) => {
    // Create a copy to avoid mutating the original
    const processedData = { ...data };

    // Handle venue selection
    if (processedData.venue_id && typeof processedData.venue_id === "object") {
      processedData.venue_id = parseInt(processedData.venue_id.value);
    }

    // Handle state selection
    if (processedData.state && typeof processedData.state === "object") {
      processedData.state = processedData.state.value;
    } else if (!processedData.state) {
      processedData.state = "draft";
    }

    // Handle age requirement
    if (
      processedData.age_requirement &&
      typeof processedData.age_requirement === "object"
    ) {
      processedData.age_requirement = processedData.age_requirement.value;
    }

    // Handle company selection
    if (processedData.company && typeof processedData.company === "object") {
      processedData.company = processedData.company.value;
    }

    // Handle artists array
    if (Array.isArray(processedData.artists)) {
      processedData.artists = processedData.artists
        .map((artist) =>
          typeof artist === "object" ? parseInt(artist.value) : parseInt(artist)
        )
        .filter((id) => !isNaN(id));
    } else {
      processedData.artists = [];
    }

    // Handle buyers array
    if (Array.isArray(processedData.buyers)) {
      // Ensure we're sending an array of values, not objects
      processedData.buyers = processedData.buyers
        .map((buyer) => {
          if (typeof buyer === "object" && buyer !== null) {
            // This should be the person_id
            return parseInt(buyer.value, 10);
          }
          return parseInt(buyer, 10);
        })
        .filter((id) => !isNaN(id));

      // Log the processed buyers for debugging
      console.log("Processed buyers:", processedData.buyers);
    } else {
      processedData.buyers = [];
    }

    // Handle time fields
    [
      "door_time",
      "opener_time",
      "support_time",
      "headliner_time",
      "curfew_time",
    ].forEach((field) => {
      if (processedData[field] === "") {
        processedData[field] = null;
      }
    });

    // Preserve original support data
    if (event.data) {
      processedData.offer_support_1 = event.data.offer_support_1;
      processedData.offer_support_2 = event.data.offer_support_2;
      processedData.offer_support_1_label = event.data.offer_support_1_label;
      processedData.offer_support_2_label = event.data.offer_support_2_label;
    }

    return processedData;
  };

  // Mutations
  const mutation = useMutation({
    mutationFn: (data) => {
      const processedData = processFormData(data);
      return updateEvent(id, processedData);
    },
    onSuccess: async (data) => {
      queryClient.invalidateQueries(["events"]);
      queryClient.setQueryData(["event-detail", id], data);
      navigate(`/events/${id}`);
    },
    onError: (error) => {
      toast.error(`Error updating event: ${error.message}`);
    },
  });

  const deactivateMutation = useMutation({
    mutationFn: (coProId) => deactivateEventCopro(coProId),
    onSuccess: () => {
      queryClient.invalidateQueries(["event-detail"]);
      queryClient.invalidateQueries(["event-copros"]);
    },
    onError: (error) => {
      toast.error(`Error removing co-pro: ${error.message}`);
    },
  });

  const handleSubmit = (data) => {
    // Normalize buyers data if present
    if (data.buyers && Array.isArray(data.buyers)) {
      // Always normalize to person_id values
      data.buyers = data.buyers
        .map((buyer) => {
          // Extract the person_id consistently
          let personId;

          if (buyer && typeof buyer === "object") {
            // Only use person_id or value, never fall back to id
            personId = buyer.person_id || buyer.value;
          } else if (
            typeof buyer === "number" ||
            (typeof buyer === "string" && !isNaN(parseInt(buyer)))
          ) {
            personId = parseInt(buyer);
          }

          if (!personId) {
            console.warn("Could not extract person ID from buyer:", buyer);
            return null;
          }

          // Always return the person_id value
          return personId;
        })
        .filter(Boolean); // Remove any null entries

      // Log the normalized buyers for debugging
      console.log("Normalized buyers for form submission:", data.buyers);
    }

    mutation.mutate(data);
  };

  const handleDeactivate = (coProId) => {
    deactivateMutation.mutate(coProId);
  };

  // Loading state
  if (
    event.isLoading ||
    venues.isLoading ||
    artists.isLoading ||
    staff.isLoading ||
    copros.isLoading
  ) {
    return <LoadingPulse isLoading={true} />;
  }

  // Check if we have event data
  if (!event.data) {
    return (
      <>
        <Header />
        <div className="flex flex-col px-6 py-8 mx-auto min-h-screen lg:py-0 dark:bg-cave-black dark:text-cave-white">
          <div className="py-6">
            <p className="text-red-500">Error: Could not load event data</p>
            <button
              onClick={() => navigate("/events")}
              className="mt-4 rounded-md bg-blue-400 px-3 py-2 text-sm font-semibold text-cave-white shadow-sm hover:bg-blue-600"
            >
              Back to Events
            </button>
          </div>
        </div>
      </>
    );
  }

  // Prepare options for select fields
  const venueOptions = venues.data.map((venue) => ({
    value: venue.id,
    label: venue.name,
  }));

  const artistOptions = artists.data.map((artist) => ({
    value: artist.id,
    label: artist.name,
  }));

  const userOptions = staff.data
    .filter(
      (staffMember) =>
        staffMember.first_name ||
        (staffMember.user && staffMember.user.first_name)
    )
    .map((staffMember) => {
      const firstName = staffMember.user?.first_name || staffMember.first_name;
      const lastName = staffMember.user?.last_name || staffMember.last_name;
      const personId = staffMember.user?.person_id;

      return {
        value: personId,
        label: `${firstName} ${lastName}`,
      };
    });

  const ageOptions = [
    { value: "all-ages", label: "All Ages" },
    { value: "16-up", label: "16+" },
    { value: "18-up", label: "18+" },
    { value: "21-up", label: "21+" },
  ];

  // Prepare initial form values
  const venueObject = event.data?.venue
    ? { value: event.data.venue.id, label: event.data.venue.name }
    : null;

  const stateObject = event.data?.state
    ? stateChoices.find((option) => option.value === event.data.state) || {
        value: "draft",
        label: "Draft",
      }
    : { value: "draft", label: "Draft" };

  const ageObject = event.data?.age_requirement
    ? ageOptions.find(
        (option) => option.value === event.data.age_requirement
      ) || null
    : null;

  const companyObject = event.data?.company
    ? companyChoices.find((option) => option.value === event.data.company) ||
      null
    : null;

  const buyerObjects = event.data?.buyers
    ? event.data.buyers
        .map((buyer) => {
          // Extract the person_id correctly
          let personId = null;
          let firstName = "";
          let lastName = "";

          // If buyer has a user property with person_id
          if (buyer && buyer.user && buyer.user.person_id) {
            personId = buyer.user.person_id;
            firstName = buyer.user.first_name || "";
            lastName = buyer.user.last_name || "";
          }
          // If buyer has a direct person_id property
          else if (buyer && buyer.person_id) {
            personId = buyer.person_id;
            firstName = buyer.first_name || "";
            lastName = buyer.last_name || "";
          }
          // If buyer is just an ID number (assuming it's a person_id)
          else if (typeof buyer === "number") {
            personId = buyer;
          }

          if (!personId) {
            console.log("Could not extract person ID from buyer:", buyer);
            return null;
          }

          // Find the staff member by person_id
          const staffMember = staff.data.find((s) => s.id === personId);

          if (staffMember) {
            // Use staff data if found
            return {
              value: personId,
              person_id: personId, // Explicitly include person_id
              label:
                `${staffMember.first_name || ""} ${
                  staffMember.last_name || ""
                }`.trim() || `Person ID: ${personId}`,
            };
          } else if (firstName || lastName) {
            // Use data from buyer object if staff not found
            return {
              value: personId,
              person_id: personId, // Explicitly include person_id
              label:
                `${firstName} ${lastName}`.trim() || `Person ID: ${personId}`,
            };
          } else {
            // Last resort - just use the ID
            console.log("Could not find staff member for person ID:", personId);
            return {
              value: personId,
              person_id: personId, // Explicitly include person_id
              label: `Person ID: ${personId}`,
            };
          }
        })
        .filter(Boolean)
    : [];

  // Add console log to debug the buyer objects
  console.log("Buyer objects:", buyerObjects);

  // Ensure all buyer objects have person_id consistently set
  const normalizedBuyerObjects = buyerObjects.map((buyer) => ({
    ...buyer,
    person_id: buyer.person_id || buyer.value,
  }));
  console.log("Normalized buyer objects:", normalizedBuyerObjects);

  const artistObjects = event.data?.artists
    ? event.data.artists.map((artist) => ({
        value: artist.id,
        label: artist.name,
      }))
    : [];

  const formValues = {
    name: event.data?.name || "",
    description: event.data?.description || "",
    state: stateObject,
    active: event.data?.active,
    date: event.data?.date ? dayjs(event.data.date).format("YYYY-MM-DD") : "",
    abbr: event.data?.abbr || "",
    venue_id: venueObject,
    seating_chart_id: event.data?.seating_chart,
    artists: artistObjects,
    buyers: normalizedBuyerObjects,
    age_requirement: ageObject,
    company: companyObject,
    door_time: event.data?.door_time || "",
    opener_time: event.data?.opener_time || "",
    support_time: event.data?.support_time || "",
    headliner_time: event.data?.headliner_time || "",
    curfew_time: event.data?.curfew_time || "",
    offer_notes: event.data?.offer_notes || "",
    confirmation_notes: event.data?.confirmation_notes || "",
    has_insurance: event.data?.has_insurance,
    has_credit_card_fees: event.data?.has_credit_card_fees,
    has_ticket_commission: event.data?.has_ticket_commission,
    has_ascap: event.data?.has_ascap,
    has_bmi: event.data?.has_bmi,
    has_sesac: event.data?.has_sesac,
    offer_has_rent: event.data?.offer_has_rent,
    offer_has_comp_tax: event.data?.offer_has_comp_tax,
    facility_fee: event.data?.facility_fee,
  };

  // Form validation schema
  const updateSchema = yup.object().shape({
    name: yup.string().required("Event name is required"),
    date: yup.string().required("Event date is required"),
    description: yup.string(),
    abbr: yup.string(),
    venue_id: yup.mixed(),
    state: yup.mixed().default("draft"),
    age_requirement: yup.mixed(),
    company: yup.mixed(),
  });

  // Determine whether to show contract boxes
  const showContractBoxes =
    event.data?.state && ["offer", "confirmed"].includes(event.data.state);

  return (
    <>
      <Header />
      <div className="flex flex-col px-6 py-8 mx-auto min-h-screen lg:py-0 dark:bg-cave-black dark:text-cave-white">
        <BreadCrumbs
          links={[
            { text: "Events", url: `/events/` },
            {
              text: `${event.data?.name || "Event"} ${getEventDate}`,
              url: `/events/${id}`,
            },
            { text: "Edit Details" },
          ]}
        />

        <h3 className="text-xl font-bold mb-4 dark:text-cave-white">
          Edit - {event.data?.name || "Event"}
        </h3>
        <div className="py-6 min-w-full">
          <FormBuilder
            defaultValues={formValues}
            onSubmit={handleSubmit}
            schema={updateSchema}
          >
            <FormSelect
              name="company"
              label="Company"
              options={companyChoices}
              className="w-48"
              defaultValue={companyObject}
            />

            <Input name="name" label="Name" />
            <Datepicker name="date" label="Date" />
            <FormSelect
              name="venue_id"
              label="Venue"
              options={venueOptions}
              required={true}
            />
            <FormMultiSelect
              name="artists"
              label="Artists"
              options={artistOptions}
              required={true}
              className="text-cave-black"
              styles={{
                option: (provided) => ({
                  ...provided,
                  color: "#000000", // Black text for options
                }),
                singleValue: (provided) => ({
                  ...provided,
                  color: "#000000", // Black text for selected values
                }),
                multiValue: (provided) => ({
                  ...provided,
                  color: "#000000", // Black text for multi-values
                }),
                multiValueLabel: (provided) => ({
                  ...provided,
                  color: "#000000", // Black text for multi-value labels
                }),
              }}
            />
            <FormMultiSelect
              name="buyers"
              label="Buyers"
              options={userOptions}
              required={true}
              className="text-cave-black"
              styles={{
                option: (provided) => ({
                  ...provided,
                  color: "#000000", // Black text for options
                }),
                singleValue: (provided) => ({
                  ...provided,
                  color: "#000000", // Black text for selected values
                }),
                multiValue: (provided) => ({
                  ...provided,
                  color: "#000000", // Black text for multi-values
                }),
                multiValueLabel: (provided) => ({
                  ...provided,
                  color: "#000000", // Black text for multi-value labels
                }),
              }}
            />
            <FormSelect
              name="state"
              label="Status"
              options={stateChoices}
              defaultValue={stateObject}
            />
            <FormSelect
              name="age_requirement"
              label="Ages"
              options={ageOptions}
              defaultValue={
                ageObject || { value: "all-ages", label: "All Ages" }
              }
            />
            <Input name="abbr" label="Abbreviation" />
            <TextInput name="description" label="Description" />
            <Input name="door_time" type="time" label="Door Time" />
            <Input name="opener_time" type="time" label="Opener Time" />
            <Input name="support_time" type="time" label="Support Time" />
            <Input name="headliner_time" type="time" label="Headliner Time" />
            <Input name="curfew_time" type="time" label="Curfew Time" />
            <TextInput name="offer_notes" label="Offer Notes" />
            <TextInput name="confirmation_notes" label="Confirm Notes" />
            <div className="flex-inline float-right mb-12 mt-8">
              <button
                type="button"
                onClick={() => navigate(`/events/${event.data.id}`)}
                className="ml-4 mr-4 rounded-md bg-gray-400 px-3 py-2 text-sm font-semibold text-gray-600 shadow-sm hover:bg-gray-300 focus-visible:outline focus-visible:outline-2 focus-visible:outline-offset-2 focus-visible:outline-blue-600"
              >
                Cancel
              </button>
              <Button type="submit">Save</Button>
            </div>
          </FormBuilder>
        </div>

        <h3 className="my-4 font-semibold border-b border-gray-800 dark:border-gray-100 dark:text-cave-white">
          Manage Copros
        </h3>
        <AddCoPro />

        <table className="border mt-4">
          <thead>
            <tr className="bg-gray-300 text-left">
              <th className="p-2">Name</th>
              <th className="p-2">Description</th>
              <th className="p-2">Percentage</th>
              <th className="p-2"></th>
            </tr>
          </thead>
          <tbody>
            {copros.data.map((copro) => (
              <tr
                key={copro.id}
                className="hover:bg-gray-100 dark:bg-cave-white dark:text-cave-black"
              >
                <td className="p-2">{copro.name}</td>
                <td className="p-2">{copro.description}</td>
                <td className="p-2">{copro.percentage} %</td>
                <td className="p-2">
                  <TrashIcon
                    className="w-6 h-6 float-right hover:text-red-500 cursor-pointer"
                    onClick={() => handleDeactivate(copro.id)}
                  />
                </td>
              </tr>
            ))}
          </tbody>
        </table>

        {showContractBoxes && (
          <>
            <h3 className="my-4 font-semibold border-b border-gray-800 dark:border-gray-100 dark:text-cave-white">
              Contract Status
            </h3>
            <div className="my-4">
              <ContractBox
                label="Contract"
                eventID={event.data.id}
                verb="Received"
                mutationQuery={markContractReceived}
                done={event.data.contract_received}
              />
            </div>
            {event.data.contract_received && (
              <div className="my-4">
                <ContractBox
                  label="Contract"
                  eventID={event.data.id}
                  verb="Returned"
                  mutationQuery={markContractReturned}
                  done={event.data.contract_returned}
                />
              </div>
            )}
            {event.data.contract_returned && (
              <div className="my-4">
                <ContractBox
                  label="Contract"
                  eventID={event.data.id}
                  state={event.data.state}
                  verb="Fully Executed"
                  mutationQuery={markContractFullyExecuted}
                  done={event.data.contract_fully_executed}
                />
              </div>
            )}
          </>
        )}
        <div className="mb-8">&nbsp;</div>
      </div>
    </>
  );
};
