import React, { useState } from "react";
import { useMutation, useQueryClient } from "@tanstack/react-query";
import { useNavigate } from "react-router-dom";
import { postEventDuplicate } from "@/queries/events";
import { BeakerIcon } from "@heroicons/react/24/outline";

const DuplicateEventButton = ({ eventId, className }) => {
  const queryClient = useQueryClient();
  const navigate = useNavigate();
  const [statusMessage, setStatusMessage] = useState("");
  const [newEventId, setNewEventId] = useState(null);
  const [newEventName, setNewEventName] = useState("");

  const mutation = useMutation(() => postEventDuplicate(eventId), {
    onMutate: () => {
      setStatusMessage("Duplicating...");
    },
    onSuccess: (data) => {
      setNewEventId(data.new_event_id);
      setNewEventName(data.new_event_name);
      setStatusMessage("Success: Event duplicated successfully.");
      queryClient.invalidateQueries(["events"]); // Invalidate event-related queries if needed
    },
    onError: (error) => {
      setStatusMessage("Failure: " + error.message);
    },
  });

  return (
    <>
      <div
        onClick={() => mutation.mutate()}
        disabled={mutation.isLoading}
        className={`
        ${
          className ||
          "inline-flex items-center justify-center w-36 h-10 p-1.5 font-bold text-cave-white uppercase rounded-lg shadow-md transition-colors duration-300 ease-in-out cursor-pointer"
        }
                ${
                  mutation.isLoading
                    ? "bg-gray-500 cursor-not-allowed"
                    : "bg-yellow-600 hover:bg-yellow-700"
                }`}
      >
        {mutation.isLoading ? (
          <span></span>
        ) : (
          <>
            <span>Copy Event</span>
          </>
        )}
      </div>
      {statusMessage && (
        <div className="fixed top-4 right-4 z-50 p-4 rounded-md shadow-lg bg-gray-800 text-white">
          <p
            className={`text-sm ${
              statusMessage.includes("Success")
                ? "text-green-400"
                : "text-red-400"
            }`}
          >
            {statusMessage}
          </p>
          {newEventId && (
            <div
              onClick={() => navigate(`/events/${newEventId}`)}
              className="mt-2 px-4 py-2 bg-green-600 hover:bg-green-700 text-cave-white font-semibold rounded-md shadow-sm transition-colors cursor-pointer"
            >
              Go to new event: {newEventName}
            </div>
          )}
        </div>
      )}
    </>
  );
};

export default DuplicateEventButton;
