import { useEffect, useState } from "react";
import { Header } from "@/components/Header";
import { useUser } from "@/components/Auth";
import { Calendar } from "@/components/Calendar/index.jsx";
import { usePageTitle } from "@/utils/pagetitle";

export const EventsCalendar = () => {
  usePageTitle(`/c${String.fromCharCode(92)}cal`);
  const { user } = useUser();

  useEffect(() => {
    console.log("EventsCalendar mounted - User details:", {
      userId: user?.data?.id,
      userIdType: typeof user?.data?.id,
      userIdAsNumber: Number(user?.data?.id),
      authenticated: !!user?.data,
      fullUserData: user?.data,
    });
  }, [user]);

  return (
    <>
      <Header />
      <div
        className="
        flex flex-col px-6 py-8 mx-auto min-h-screen lg:py-0 dark:text-cave-white
    "
      >
        <div className="my-6 font-sans font-bold">
          <Calendar />
        </div>
      </div>
    </>
  );
};
