import { formatCentsToDollars, formatDollarsToCents } from "./money";

export const MoneyEditor = function () {};

MoneyEditor.prototype.init = function (params) {
  this.eInput = document.createElement("input");
  this.eInput.style.width = "100%";
  this.eInput.style.height = "100%";

  if (params.value !== null && params.value !== undefined) {
    const dollars = params.value / 100;
    this.eInput.value = dollars.toFixed(2);
  } else {
    this.eInput.value = "";
  }

  this.eInput.addEventListener("focus", function () {
    this.select();
  });
};

MoneyEditor.prototype.getGui = function () {
  return this.eInput;
};

MoneyEditor.prototype.getValue = function () {
  if (this.eInput.value === "") return null;
  const dollars = parseFloat(this.eInput.value) || 0;
  return Math.round(dollars * 100);
};

MoneyEditor.prototype.afterGuiAttached = function () {
  this.eInput.focus();
  this.eInput.select();
};

MoneyEditor.prototype.isPopup = function () {
  return false;
};

export const moneyColumnConfig = {
  isMoney: true,
  valueFormatter: (params: ValueFormatterParams): string => {
    if (params.value === null || params.value === undefined) return "";

    const dollars = formatCentsToDollars(params.value);
    return new Intl.NumberFormat("en-US", {
      style: "currency",
      currency: "USD",
    }).format(dollars);
  },
  valueParser: (params: ValueParserParams): number | null => {
    if (!params.newValue) return null;

    return formatDollarsToCents(params.newValue);
  },
  cellEditor: "moneyEditor",
};

export function registerMoneyEditor(gridOptions) {
  if (!gridOptions.components) {
    gridOptions.components = {};
  }
  gridOptions.components.moneyEditor = MoneyEditor;
}
