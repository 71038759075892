import React from "react";
import { useQuery } from "@tanstack/react-query";
import { getTasks } from "@/queries/tasks";

export const TaskDependencyView = ({ task, onClose }) => {
  const { data: allTasks } = useQuery(["tasks"], getTasks);

  const findRelatedTasks = (taskId) => {
    if (!allTasks) return { parentTasks: [], subTasks: [] };

    const parentTasks = allTasks.filter((t) => t.id === task.parent_task_id);
    const subTasks = allTasks.filter((t) => t.parent_task_id === taskId);

    return { parentTasks, subTasks };
  };

  const { parentTasks, subTasks } = findRelatedTasks(task.id);

  const TaskCard = ({ task, type }) => (
    <div className="bg-purple-800 p-3 rounded-lg">
      <div className="flex items-center justify-between">
        <span
          className={`px-2 py-1 rounded text-xs ${
            type === "parent"
              ? "bg-blue-600"
              : type === "current"
              ? "bg-purple-600"
              : "bg-green-600"
          }`}
        >
          {type === "parent"
            ? "Parent"
            : type === "current"
            ? "Current"
            : "Sub-task"}
        </span>
        <span
          className={`px-2 py-1 rounded text-xs ${
            task.status === "COMPLETED"
              ? "bg-green-600"
              : task.status === "IN_PROGRESS"
              ? "bg-blue-600"
              : "bg-gray-600"
          }`}
        >
          {task.status}
        </span>
      </div>
      <p className="text-white mt-2">{task.description}</p>
      <div className="text-purple-300 text-sm mt-2">
        {task.due_date && (
          <span>Due: {new Date(task.due_date).toLocaleDateString()}</span>
        )}
      </div>
    </div>
  );

  return (
    <div className="bg-purple-900 p-6 rounded-lg max-w-4xl w-full">
      <div className="flex justify-between items-center mb-6">
        <h2 className="text-white text-xl font-bold">Task Dependencies</h2>
        <button onClick={onClose} className="text-purple-200 hover:text-white">
          ×
        </button>
      </div>

      <div className="space-y-6">
        {parentTasks.length > 0 && (
          <div>
            <h3 className="text-purple-200 mb-2">Parent Tasks</h3>
            <div className="space-y-2">
              {parentTasks.map((parentTask) => (
                <TaskCard key={parentTask.id} task={parentTask} type="parent" />
              ))}
            </div>
          </div>
        )}

        <div>
          <h3 className="text-purple-200 mb-2">Current Task</h3>
          <TaskCard task={task} type="current" />
        </div>

        {subTasks.length > 0 && (
          <div>
            <h3 className="text-purple-200 mb-2">Sub Tasks</h3>
            <div className="space-y-2">
              {subTasks.map((subTask) => (
                <TaskCard key={subTask.id} task={subTask} type="sub" />
              ))}
            </div>
          </div>
        )}
      </div>
    </div>
  );
};

export default TaskDependencyView;
