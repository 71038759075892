import { useSettlementComponent } from "@/hooks/useSettlementComponent";
import { bundleAllExpenses } from "@/helpers";
import { Table, TableRow } from "../AppTable2";
import SettlementTableTitle from "./SettlementTableTitle";
import { useQuery, useMutation, useQueryClient } from "@tanstack/react-query";
import { PlusIcon } from "@heroicons/react/24/outline";
import {
  createEventDueMisc,
  deleteEventDueMisc,
  EventDue,
  EventDueMisc,
  fetchEventDuesMisc,
  updateEventDue,
  createEventDues,
  fetchEventDues,
  deleteEventDue,
} from "@/queries/dues";
import Button from "../Button";
import classNames from "classnames";
import ConfirmModal from "../Common/ConfirmModal";
import { useState } from "react";
import { toast } from "react-toastify";

const DUE_FIELDS = [
  { key: "mammoth_percentage", label: "Mammoth %", isEditable: true },
  { key: "profit_percent", label: "Profit Percent", isEditable: false },
  {
    key: "plus_expenses",
    label: "Plus Expenses",
    format: "money",
    isEditable: true,
  },
  {
    key: "plus_guarantee",
    label: "Plus Guarantee, Ovg, Bonus",
    format: "money",
    isEditable: true,
  },
  {
    key: "plus_artist_costs",
    label: "Plus Artist Costs",
    format: "money",
    isEditable: true,
  },
  { key: "plus_tax", label: "Plus Tax", format: "money", isEditable: true },
  {
    key: "plus_facility_fee",
    label: "Plus FAC FEE",
    format: "money",
    isEditable: true,
  },
  {
    key: "plus_platinum_percentage",
    label: "PLUS PLATINUM %",
    format: "money",
    isEditable: true,
  },
  {
    key: "plus_charity",
    label: "PLUS CHARITY",
    format: "money",
    isEditable: true,
  },
  {
    key: "less_rebate",
    label: "LESS REBATE",
    format: "money",
    isEditable: true,
  },
  {
    key: "less_guarantee_deductions",
    label: "LESS GUARANTEE DEDUCTIONS",
    format: "money",
    isEditable: true,
  },
  { key: "less_cash", label: "LESS CASH", format: "money", isEditable: true },
  {
    key: "less_ancillary_revenue",
    label: "LESS ANCILLARY REVENUE",
    format: "money",
    isEditable: true,
  },
  { key: "less_tax", label: "Less Tax", format: "money", isEditable: false },
  {
    key: "less_platinum",
    label: "LESS PLATINUM",
    format: "money",
    isEditable: false,
  },
  {
    key: "less_ticketing_revenue",
    label: "Less TICKETING GROSS REVENUE",
    format: "money",
    isEditable: false,
  },
  {
    key: "less_cash_received",
    label: "LESS CASH (received)",
    format: "money",
    isEditable: false,
  },
];

export default function SettlementDueGrid() {
  const [deleteModalOpen, setDeleteModalOpen] = useState(false);
  const [dueToDelete, setDueToDelete] = useState<EventDue | null>(null);
  const queryClient = useQueryClient();
  const { expensesQuery, settlementInputClassName, id } =
    useSettlementComponent({ enableExpenses: true });

  const eventDues = useQuery<EventDue[], any>(
    ["event-dues", id?.toString()],
    () => fetchEventDues(id),
    { initialData: [] }
  );

  const eventDueMisc = useQuery<EventDueMisc[], any>(
    ["event-due-misc", id?.toString()],
    () => fetchEventDuesMisc(id?.toString()),
    { initialData: [] }
  );

  const createDueMutation = useMutation({
    mutationFn: async () => {
      if (!id) throw new Error("Missing event id");
      const baseData = {
        event: id,
        due_building: 0,
        mammoth_percentage: 0,
        profit_percent: 0,
        plus_expenses: bundleAllExpenses(expensesQuery.data).mammoth || 0,
        plus_guarantee: 0,
        plus_artist_costs: bundleAllExpenses(expensesQuery.data).artist || 0,
        plus_tax: 0,
        plus_facility_fee: 0,
        plus_platinum_percentage: 0,
        plus_charity: 0,
        less_rebate: 0,
        less_guarantee_deductions: 0,
        less_cash: 0,
        less_ancillary_revenue: 0,
        less_tax: 0,
        less_platinum: 0,
        less_ticketing_revenue: 0,
        less_cash_received: 0,
      };
      return createEventDues(id, baseData);
    },
    onSuccess: () => {
      queryClient.invalidateQueries(["event-dues", id?.toString()]);
    },
  });

  const deleteDueMutation = useMutation({
    mutationFn: (dueId: number) => {
      if (!id) throw new Error("Missing event id");
      return deleteEventDue(id, dueId);
    },
    onSuccess: () => {
      queryClient.invalidateQueries(["event-dues", id?.toString()]);
      setDeleteModalOpen(false);
      setDueToDelete(null);
      toast.success("Due deleted successfully");
    },
    onError: (error) => {
      console.error("Failed to delete due:", error);
      toast.error("Failed to delete due");
    },
  });

  const updateDueMutation = useMutation({
    mutationFn: ({
      dueId,
      data,
    }: {
      dueId: number;
      data: Partial<EventDue>;
    }) => {
      if (!id) throw new Error("Missing event id");
      return updateEventDue(id, dueId, data);
    },
    onSuccess: () => {
      queryClient.invalidateQueries(["event-dues", id?.toString()]);
    },
  });

  const handleAddDue = () => {
    createDueMutation.mutate();
  };

  const handleDeleteDue = (item: EventDue) => {
    setDueToDelete(item);
    setDeleteModalOpen(true);
  };

  const confirmDelete = () => {
    if (dueToDelete) {
      deleteDueMutation.mutate(dueToDelete.id);
    }
  };

  return (
    <div>
      <button
        onClick={handleAddDue}
        className="px-4 py-2 bg-blue-400 text-cave-white dark:text-cave-black hover:bg-blue-500 flex justify-center items-center font-bold duration-200"
      >
        <PlusIcon className="w-4 h-4 mr-2" /> Click to Add New Due
      </button>

      <div className="grid grid-cols-1 lg:grid-cols-3 gap-4 pb-36">
        {eventDues.data.map((due, index) => (
          <div key={due.id}>
            <button
              onClick={() => handleDeleteDue(due)}
              className={classNames(
                "bg-red-400 hover:bg-red-600 text-cave-white py-1 px-2 text-xs rounded flex mb-1",
                index < 2 ? "opacity-0 pointer-events-none" : ""
              )}
            >
              Delete Due
            </button>
            <SettlementTableTitle>
              {index === 0
                ? "Due Mammoth"
                : index === 1
                  ? "Due Building"
                  : `Due Support ${index - 1}`}
            </SettlementTableTitle>
            {DUE_FIELDS.map((field) => (
              <TableRow
                key={field.key}
                className="bg-white w-full"
                inputClassName={settlementInputClassName}
                columns={[
                  { accessor: "label", width: "50%" },
                  {
                    key: "value",
                    accessor: "value",
                    textAlign: "right",
                    editable: field.isEditable,
                    format: (field.format as any) || "input",
                    onBlur: (newValue) => {
                      if (!id) return;
                      updateDueMutation.mutate({
                        dueId: due.id,
                        data: { ...due, event: id, [field.key]: newValue },
                      });
                    },
                  },
                ]}
                rowData={{
                  label:
                    field.key === "mammoth_percentage"
                      ? index === 0
                        ? "Mammoth %"
                        : index === 1
                          ? "Building %"
                          : "Additional %"
                      : field.label,
                  value: (due as any)[field.key],
                }}
              />
            ))}
            <Table
              hideHeaders
              enableAddRow
              addRowButtonTitle="Add Misc Item"
              onAddRow={async (newRow) => {
                if (!id) return;
                await createEventDueMisc(id, {
                  amount: parseFloat(newRow.value) || 0,
                  is_addition: true,
                  description: newRow.label || "New Misc Item",
                  event: id,
                });
                queryClient.invalidateQueries([
                  "event-due-misc",
                  id.toString(),
                ]);
              }}
              onDelete={async (rowIndex, row) => {
                if (!id || !row.id) return;
                await deleteEventDueMisc(id, row.id);
                queryClient.invalidateQueries([
                  "event-due-misc",
                  id.toString(),
                ]);
              }}
              data={eventDueMisc.data.map((item) => ({
                id: item.id,
                label: item.description,
                value: item.amount,
              }))}
              inputClassName={settlementInputClassName}
              showNoDataMessage={false}
              deleteConfirmation="modal"
              deleteConfig={{
                className: "flex !justify-end",
                width: "13%",
              }}
              columns={[
                {
                  key: "label",
                  accessor: "label",
                  textAlign: "left",
                  editable: true,
                  addRowPlaceholder: "Label",
                  width: "50%",
                },
                {
                  key: "value",
                  accessor: "value",
                  textAlign: "right",
                  editable: true,
                  addRowPlaceholder: "Value",
                  format: "money",
                },
              ]}
            />
          </div>
        ))}
      </div>

      <ConfirmModal
        message="Are you sure you want to delete this due?"
        onConfirm={confirmDelete}
        onCancel={() => {
          setDeleteModalOpen(false);
          setDueToDelete(null);
        }}
        show={deleteModalOpen}
      />
    </div>
  );
}
