import { create } from "zustand";
import { UserRole } from "@/types/user";

interface UserStore {
  roleImpersonating: UserRole | null;
  impersonateRole: (role: UserRole) => void;
  resetImpersonation: () => void;
}

export const useUserStore = create<UserStore>((set) => ({
  roleImpersonating: null,
  impersonateRole: (role: UserRole) => set(() => ({ roleImpersonating: role })),
  resetImpersonation: () => set(() => ({ roleImpersonating: null })),
}));
