import { getMe, userQuerySettings } from "@/queries/auth";
import { CaveError, CaveUser } from "@/types";
import { useQuery } from "@tanstack/react-query";
import { useUserStore } from "@/store/userStore";

export function useUser() {
  const userQuery = useQuery<CaveUser, CaveError>(
    ["me"],
    getMe,
    userQuerySettings
  );
  const user = userQuery.data;
  const personId = user?.person_id;
  const fullName = user ? `${user.first_name} ${user.last_name}` : "";

  const { roleImpersonating, impersonateRole, resetImpersonation } =
    useUserStore();
  const roleUserIsUsing = roleImpersonating || user?.role;
  return {
    user,
    personId,
    fullName,
    roleImpersonating,
    impersonateRole,
    resetImpersonation,
    roleUserIsUsing,
  };
}
