import * as yup from "yup";
import { parseTimeValue } from "../../utils/time";
import { validateTimeSequence } from "../../utils/errorHandling";

// Time field validation schema
// Time field validation schema
const timeFieldSchema = yup
  .string()
  .nullable()
  .test("is-valid-time", "Invalid time format", (value) => {
    if (!value) return true;
    try {
      return parseTimeValue(value) !== null;
    } catch (e) {
      return false;
    }
  })
  .transform((value) => (value?.trim() === "" ? null : value));

// Time sequence validation schema
const timeSequenceSchema = yup
  .object()
  .test("time-sequence", "Invalid time sequence", function (value) {
    const times = [
      "schedule_venue_access",
      "schedule_breakfast_time",
      "schedule_local_load_in",
      "schedule_lunch_time",
      "schedule_sound_check",
      "schedule_dinner_time",
      "schedule_doors",
      "schedule_set_1_time",
      "schedule_set_2_time",
      "schedule_curfew",
    ];

    const errors = validateTimeSequence(value, times);
    if (Object.keys(errors).length > 0) {
      return this.createError({ message: JSON.stringify(errors) });
    }
    return true;
  });

// Field mappings for frontend to backend
export const fieldMappings = {
  // Equipment Fields
  equip_projector: "equipment.projector",
  equip_screens: "equipment.screens",
  equip_led_wall: "equipment.ledWall",
  equip_generators: "equipment.generators",
  equip_power_distro: "equipment.powerDistro",
  equip_truss: "equipment.truss",
  equip_chain_motors: "equipment.chainMotors",
  equip_stage_screws: "equipment.stageScrews",
  equip_lifts: "equipment.lifts",
  equip_dollies: "equipment.dollies",
  equip_cases: "equipment.cases",
  equip_tools: "equipment.tools",
  equip_backline: "equipment.backline",
  equip_monitors: "equipment.monitors",
  equip_microphones: "equipment.microphones",
  equip_power_requirements: "equipment.powerRequirements",
  equip_rigging_requirements: "equipment.riggingRequirements",
  equip_stage_plot: "equipment.stagePlot",
  equip_input_list: "equipment.inputList",
  equip_sound_system: "equipment.soundSystem",
  equip_monitor_system: "equipment.monitorSystem",
  equip_lighting_system: "equipment.lightingSystem",
  equip_video_system: "equipment.videoSystem",
};

// Custom error messages
const errorMessages = {
  required: (field) => `${field} is required`,
  email: "Invalid email address",
  phone: "Invalid phone number format",
  url: "Must be a valid URL",
  positive: "Must be a positive number",
  boolean: "Must be true or false",
  min: "Cannot be less than 0",
};

export const advanceSheetSchema = yup.object().shape({
  // Time fields use the single timeFieldSchema declaration
  schedule_venue_access_display: timeFieldSchema,
  schedule_venue_access_value: timeFieldSchema,
  schedule_breakfast_time_display: timeFieldSchema,
  schedule_breakfast_time_value: timeFieldSchema,
  schedule_local_load_in_display: timeFieldSchema,
  schedule_local_load_in_value: timeFieldSchema,
  schedule_tour_load_in_display: timeFieldSchema,
  schedule_tour_load_in_value: timeFieldSchema,
  schedule_lunch_time_display: timeFieldSchema,
  schedule_lunch_time_value: timeFieldSchema,
  schedule_sound_check_display: timeFieldSchema,
  schedule_sound_check_value: timeFieldSchema,
  schedule_security_meeting_display: timeFieldSchema,
  schedule_security_meeting_value: timeFieldSchema,
  schedule_dinner_time_display: timeFieldSchema,
  schedule_dinner_time_value: timeFieldSchema,
  schedule_doors_display: timeFieldSchema,
  schedule_doors_value: timeFieldSchema,
  schedule_set_1_time_display: timeFieldSchema,
  schedule_set_1_time_value: timeFieldSchema,
  schedule_intermission_display: timeFieldSchema,
  schedule_intermission_value: timeFieldSchema,
  schedule_set_2_time_display: timeFieldSchema,
  schedule_set_2_time_value: timeFieldSchema,
  schedule_call_back_display: timeFieldSchema,
  schedule_call_back_value: timeFieldSchema,
  schedule_curfew_display: timeFieldSchema,
  schedule_curfew_value: timeFieldSchema,

  // Event Info
  offer_support_1: yup.string().nullable(),
  offer_support_2: yup.string().nullable(),
  headliner_time: yup
    .string()
    .nullable()
    .transform((value) => (value?.trim() === "" ? null : value)),
  headliner_time_value: yup
    .string()
    .nullable()
    .transform((value) => (value?.trim() === "" ? null : value)),
  venue_name: yup.string().nullable(),
  venue_address: yup.string().nullable(),
  venue_phone: yup.string().nullable(),
  venue_capacity: yup
    .number()
    .typeError("Capacity must be a number")
    .positive(errorMessages.positive)
    .nullable(),
  venue_website: yup
    .string()
    .url(errorMessages.url)
    .nullable()
    .transform((value) => (value?.trim() === "" ? null : value)),
  venue_type: yup
    .string()
    .nullable()
    .transform((value) => (value?.trim() === "" ? null : value)),

  // Schedule time fields
  schedule_venue_access: yup
    .string()
    .nullable()
    .transform((value) => (value?.trim() === "" ? null : value)),
  schedule_venue_access_value: yup
    .string()
    .nullable()
    .transform((value) => (value?.trim() === "" ? null : value)),

  schedule_breakfast_time: yup
    .string()
    .nullable()
    .transform((value) => (value?.trim() === "" ? null : value)),
  schedule_breakfast_time_value: yup
    .string()
    .nullable()
    .transform((value) => (value?.trim() === "" ? null : value)),

  // Equipment Fields
  equip_power_requirements: yup.string().nullable(),
  equip_rigging_requirements: yup.string().nullable(),
  equip_stage_plot: yup.string().nullable(),
  equip_input_list: yup.string().nullable(),
  equip_sound_system: yup.string().nullable(),
  equip_monitor_system: yup.string().nullable(),
  equip_lighting_system: yup.string().nullable(),
  equip_video_system: yup.string().nullable(),
  equip_projector: yup.string().nullable(),
  equip_screens: yup.string().nullable(),
  equip_led_wall: yup.string().nullable(),
  equip_generators: yup.string().nullable(),
  equip_power_distro: yup.string().nullable(),
  equip_truss: yup.string().nullable(),
  equip_chain_motors: yup.string().nullable(),
  equip_stage_screws: yup.string().nullable(),
  equip_lifts: yup.string().nullable(),
  equip_dollies: yup.string().nullable(),
  equip_cases: yup.string().nullable(),
  equip_tools: yup.string().nullable(),
  equip_backline: yup.string().nullable(),
  equip_monitors: yup.string().nullable(),
  equip_microphones: yup.string().nullable(),

  // Technical Fields
  tech_power_requirements: yup.string().nullable(),
  tech_stage_equipment: yup.string().nullable(),
  tech_audio_system: yup.string().nullable(),
  tech_monitor_system: yup.string().nullable(),
  tech_lighting_system: yup.string().nullable(),
  tech_video_system: yup.string().nullable(),
  tech_effects_system: yup.string().nullable(),
  tech_communication_system: yup.string().nullable(),

  // Settlement Fields
  settlement_contact: yup.string().nullable(),
  settlement_phone: yup.string().nullable(),
  settlement_email: yup.string().nullable(),
  settlement_time: yup.string().nullable(),
  settlement_location: yup.string().nullable(),
  settlement_instructions: yup.string().nullable(),

  // Catering Fields
  catering_vendor_name: yup.string().nullable(),
  catering_vendor_contact: yup.string().nullable(),
  catering_payment_method: yup.string().nullable(),
  catering_per_person_cost: yup.number().min(0, "Must be positive").nullable(),
  catering_minimum_guarantee: yup
    .number()
    .min(0, "Must be positive")
    .nullable(),
  catering_estimate: yup.number().min(0, "Must be positive").nullable(),

  // Venue Contacts
  // Venue Contacts - basic string validation only
  gm_name: yup.string(),
  gm_phone: yup.string(),
  gm_cell: yup.string(),
  gm_email: yup.string(),

  marketing_name: yup.string(),
  marketing_phone: yup.string(),
  marketing_cell: yup.string(),
  marketing_email: yup.string(),

  box_office_name: yup.string(),
  box_office_phone: yup.string(),
  box_office_cell: yup.string(),
  box_office_email: yup.string(),

  production_name: yup.string(),
  production_phone: yup.string(),
  production_cell: yup.string(),
  production_email: yup.string(),

  catering_name: yup.string(),
  catering_phone: yup.string(),
  catering_cell: yup.string(),
  catering_email: yup.string(),

  // Tour Contacts
  tour_contacts: yup.array().of(
    yup.object().shape({
      name: yup.string(),
      phone: yup.string(),
      cell: yup.string(),
      email: yup.string().email("Invalid email format"),
    })
  ),

  // Stage Manager Fields
  stage_mgr_name: yup
    .string()
    .nullable()
    .transform((value) => (value?.trim() === "" ? null : value)),
  stage_mgr_phone: yup
    .string()
    .nullable()
    .transform((value) => (value?.trim() === "" ? null : value)),
  stage_mgr_cell: yup
    .string()
    .nullable()
    .transform((value) => (value?.trim() === "" ? null : value)),
  stage_mgr_email: yup
    .string()
    .nullable()
    .email("Please enter a valid email address")
    .transform((value) => (value?.trim() === "" ? null : value)),
  stage_mgr_company: yup.string().nullable(),
  stage_mgr_address: yup.string().nullable(),
  stage_mgr_website: yup.string().nullable(),
  stage_mgr_emergency: yup.string().nullable(),
  stage_mgr_role: yup.string().nullable(),
  stage_mgr_department: yup.string().nullable(),
  stage_mgr_notes: yup.string().nullable(),
  stage_mgr_requirements: yup.string().nullable(),
  stage_mgr_schedule: yup.string().nullable(),
  stage_mgr_equipment: yup.string().nullable(),
  stage_mgr_staff: yup.string().nullable(),
  stage_mgr_credentials: yup.string().nullable(),
  stage_mgr_certifications: yup.string().nullable(),
  stage_mgr_experience: yup.string().nullable(),
  stage_mgr_availability: yup.string().nullable(),
  stage_mgr_preferences: yup.string().nullable(),
  stage_mgr_training: yup.string().nullable(),
  stage_mgr_licenses: yup.string().nullable(),
  stage_mgr_insurance: yup.string().nullable(),
  stage_mgr_union: yup.string().nullable(),
  stage_mgr_rates: yup.string().nullable(),

  // Production Requirements
  production_requirements: yup
    .mixed()
    .transform((value) => {
      if (value === "" || value === undefined) return null;
      return value;
    })
    .nullable(),

  // Labor Calls
  labor_calls: yup.object().shape({
    steward: yup.string(),
    hands: yup.string(),
    loaders: yup.string(),
    riggers_up: yup.string(),
    riggers_down: yup.string(),
    electrician: yup.string(),
    wardrobe: yup.string(),
    spot_ops: yup.string(),
    house_lights: yup.string(),
    fly_man: yup.string(),
  }),

  // Phones/Internet
  phones_internet: yup.object().shape({
    phone_needs: yup.string(),
    internet: yup.string(),
  }),

  // Dressing Rooms
  dressing_rooms: yup.object().shape({
    room_one: yup.string(),
    room_two: yup.string(),
    room_three: yup.string(),
    furniture: yup.string(),
  }),

  // Parking
  parking: yup.object().shape({
    trucks: yup.string(),
    buses: yup.string(),
    vans: yup.string(),
    cars: yup.string(),
  }),

  // Towels/Showers/Laundry
  towels_showers_laundry: yup.object().shape({
    laundry_onsite: yup.boolean(),
    showers_onsite: yup.boolean(),
    bath_towels: yup.number().min(0, "Cannot be negative"),
    hand_towels: yup.number().min(0, "Cannot be negative"),
  }),

  // Estimates
  estimates: yup.object().shape({
    catering: yup.number().min(0, "Cannot be negative"),
    hands: yup.number().min(0, "Cannot be negative"),
    production: yup.number().min(0, "Cannot be negative"),
  }),

  // Notes
  notes: yup.object().shape({
    load_in_notes: yup.string(),
    production_notes: yup.string(),
    production_requirements: yup.string(),
    catering_notes: yup.string(),
    hotel_notes: yup.string(),
    parking_notes: yup.string(),
    merch_notes: yup.string(),
    guest_list_notes: yup.string(),
    dressing_room_notes: yup.string(),
    general_notes: yup.string(),
  }),

  // Cash Needs
  cash_needs: yup
    .mixed()
    .transform((value) => {
      if (value === "" || value === undefined) return null;
      return value;
    })
    .nullable(),
});
