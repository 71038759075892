import React, { useState } from "react";
import * as yup from "yup";
import Button from "@/components/Button";
import { useNavigate } from "react-router-dom";
import { FormBuilder, TextInput } from "@/components/Form";
import displayDateTime from "@/utils/datetime";
import { Modal } from "@/components/Modal/Modal";

const Comment = (props) => {
  const { comment, onEdit, onDelete } = props;
  const [isEditing, setIsEditing] = useState(false);
  const [editedBody, setEditedBody] = useState(comment.body);
  const [showDeleteModal, setShowDeleteModal] = useState(false);

  const handleEditToggle = () => {
    if (isEditing) {
      // Cancel editing
      setEditedBody(comment.body);
    }
    setIsEditing(!isEditing);
  };

  const handleSave = () => {
    onEdit(comment.id, { body: editedBody });
    setIsEditing(false);
  };

  const handleDeleteClick = () => {
    setShowDeleteModal(true);
  };

  const handleConfirmDelete = () => {
    onDelete(comment.id);
    setShowDeleteModal(false);
  };

  return (
    <div className="w-full border border-1 text-xl mt-4 rounded rounded-lg dark:bg-cave-white text-gray-800">
      {isEditing ? (
        <div className="p-4">
          <textarea
            className="w-full p-2 border rounded"
            value={editedBody}
            onChange={(e) => setEditedBody(e.target.value)}
          />
          <div className="flex justify-end mt-2 space-x-2">
            <Button variant="secondary" onClick={handleEditToggle}>
              Cancel
            </Button>
            <Button onClick={handleSave}>Save</Button>
          </div>
        </div>
      ) : (
        <div className="p-4">{comment.body}</div>
      )}
      <div className="p-2 text-sm bg-gray-200 flex justify-between rounded-br-lg rounded-bl-lg">
        <div className="flex space-x-2">
          {!isEditing && (
            <>
              <button
                onClick={handleEditToggle}
                className="text-blue-600 hover:text-blue-800"
              >
                Edit
              </button>
              <button
                onClick={handleDeleteClick}
                className="text-red-600 hover:text-red-800"
              >
                Delete
              </button>
            </>
          )}
        </div>
      </div>

      <Modal
        isOpen={showDeleteModal}
        onClose={() => setShowDeleteModal(false)}
        onConfirm={handleConfirmDelete}
        title="Delete Comment"
      >
        <p>
          Are you sure you want to delete this comment? This action cannot be
          undone.
        </p>
      </Modal>
    </div>
  );
};

const CommentDisplay = (props) => {
  const { comments, handleAddComment, handleEditComment, handleDeleteComment } =
    props;
  const navigate = useNavigate();

  // Comments
  const commentSchema = yup.object().shape({
    body: yup.string().required(),
  });

  return (
    <>
      <div className="mb-6 border-t-[1px] border-[#595959] pt-[1rem]">
        <div className="w-full flex justify-between items-center mb-4">
          <h3 className="text-xl font-semibold dark:text-gray-200">Notes</h3>
        </div>
        <div className="my-2">
          {comments.isLoading && (
            <div className="dark:text-cave-white">Loading...</div>
          )}
          {comments.isSuccess &&
            comments.data.map((comment, index) => (
              <Comment
                key={index}
                comment={comment}
                onEdit={handleEditComment}
                onDelete={handleDeleteComment}
              />
            ))}
          {comments.isSuccess && !comments.data.length && (
            <div className="dark:text-cave-white">No notes found</div>
          )}
        </div>
      </div>
      <div className="text-xl">
        <FormBuilder
          onSubmit={handleAddComment}
          schema={commentSchema}
          reset={true}
        >
          <TextInput
            name="body"
            label=""
            placeholder="Enter your note here..."
          />
          <div className="float-center">
            <Button type="submit">Add Note</Button>
          </div>
        </FormBuilder>
      </div>
    </>
  );
};

export default CommentDisplay;
