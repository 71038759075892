import React, { useState, useEffect } from "react";
import { useNavigate, Link } from "react-router-dom";
import dayjs from "dayjs";
import { LoadingPulse } from "@/components/shared/LoadingPulse";
import { useUser, usePerms } from "@/components/Auth";
import { releaseHold } from "@/services/holdService";
import { toast } from "react-toastify";
import { HoldSwitcher } from "@/components/EventState/HoldSwitcher";
import { ChallengeHold } from "@/components/ChallengeHold";
import { PPeopleIcon } from "@/components/People/PeopleIcon";
import DuplicateEventButton from "@/components/DupeEvent";
import SpotifyPlayer from "./SpotifyPlayer";
import {
  MapIcon,
  ChevronDownIcon,
  ChevronUpIcon,
} from "@heroicons/react/24/outline";

const EventHeader = ({
  event,
  people,
  copros,
  isLoading,
  error,
  scrollToSection,
  filesRef,
  peopleRef,
  guestlistRef,
  advanceRef,
  expensesRef,
  wiresRef,
  confirmationRef,
  setShowWires,
  setShowGuestList,
  setShowAdvance,
  setShowConfirmation,
  setShowFiles,
}) => {
  const navigate = useNavigate();
  const { user } = useUser();
  const { perms } = usePerms();

  // Define variables needed for button visibility
  let canSeeNumbers = false;
  let canSeeFiles = false;
  let onlyMarketing = false;
  let buyerLike = false;

  // Set permissions based on user role
  if (user?.data?.role === "legal") {
    canSeeFiles = true;
  }

  if (user?.data?.role === "marketing") {
    onlyMarketing = true;
  }

  if (event?.buyers?.some((buyer) => buyer.id === user?.data?.id)) {
    canSeeNumbers = true;
    buyerLike = true;
  }

  if (perms?.data?.edit_marketing) {
    canSeeNumbers = true;
    onlyMarketing = true;
  }

  if (
    [
      "tier_admin",
      "admin",
      "accounting_admin",
      "accounting",
      "touring_admin",
      "marketing_admin",
    ].includes(user?.data?.role)
  ) {
    canSeeNumbers = true;
    buyerLike = true;
  }

  if (isLoading) {
    return <LoadingPulse />;
  }

  if (error) {
    return <div>Error loading event data: {error.message}</div>;
  }

  // Check if this is a hold that user can't see
  if (
    event?.state === "hold" &&
    (event?.buyer?.id !== user?.data?.id || !perms?.data?.view_holds)
  ) {
    return (
      <div>
        <div className="pt-6 pb-2 md:flex md:items-center md:justify-between">
          <div className="min-w-0 flex-1">
            <h2 className="text-2xl font-bold leading-7 dark:text-cave-white sm:truncate sm:text-3xl sm:tracking-tight">
              {`${event.company} Hold`}
            </h2>
            <h3 className="my-2 text-gray-500">
              {dayjs(event.date).format("dddd MMM D, YYYY")} @{" "}
              {event.venue &&
                `${event.venue.name} - ${event.venue.city}, ${event.venue.state}`}
            </h3>
          </div>
        </div>
        <div>
          {event.buyers.some((buyerId) => buyerId != user.data.id) && (
            <div className="w-full p-6 dark:bg-cave-black dark:text-cave-white text-2xl border-2 border-color-cave-orange">
              There is a hold on this venue for this date from another buyer.
              <br />
              <ChallengeHold event={event} user={user} />
            </div>
          )}
        </div>
      </div>
    );
  }

  // Format age requirement
  let ageText = "All Ages";
  if (event?.age_requirement) {
    switch (event.age_requirement) {
      case "all-ages":
        ageText = "All Ages";
        break;
      case "13-up":
        ageText = "13+";
        break;
      case "14-up":
        ageText = "14+";
        break;
      case "15-up":
        ageText = "15+";
        break;
      case "16-up":
        ageText = "16+";
        break;
      case "17-up":
        ageText = "17+";
        break;
      case "18-up":
        ageText = "18+";
        break;
      case "19-up":
        ageText = "19+";
        break;
      case "20-up":
        ageText = "20+";
        break;
      case "21-up":
        ageText = "21+";
        break;
    }
  }

  // Format door time
  let timeText = null;
  if (event?.door_time) {
    const timeParts = event.door_time.split(":");
    const hour = parseInt(timeParts[0]);
    const minute = timeParts[1];
    let amPm = "AM";
    if (hour > 12) {
      amPm = "PM";
      timeText = `${hour - 12}:${minute} ${amPm}`;
    } else {
      timeText = `${hour}:${minute} ${amPm}`;
    }
  }

  // Format co-promoters list
  let coProsList = [];
  if (event?.is_building_copro) {
    coProsList.push(event.venue.name);
  }
  if (copros?.data && Array.isArray(copros.data)) {
    copros.data.forEach((copro) => {
      if (copro?.name) {
        coProsList.push(copro.name);
      }
    });
  }

  return (
    <div className="event-detail-page">
      <div className="event-detail-container">
        {/* Event Title and Info */}
        <div className="event-title-section relative">
          {/* Spotify Player in upper right */}
          {event.artists && event.artists.length > 0 && (
            <div className="absolute top-0 right-0 z-10 w-72">
              <SpotifyPlayer
                artistName={event.artists[0].name}
                compact={true}
              />
            </div>
          )}

          <h1 className="event-title text-white">{event.name}</h1>
          <h2 className="event-venue text-white">
            {event.venue.name} ({event.venue.city}, {event.venue.state})
            <a
              href={`https://www.google.com/maps/search/?api=1&query=${encodeURIComponent(
                `${event.venue.name} ${event.venue.address} ${event.venue.city} ${event.venue.state} ${event.venue.zipcode}`
              )}`}
              target="_blank"
              rel="noopener noreferrer"
              className="inline-block ml-2 text-blue-300 hover:text-blue-100"
              title="View on Google Maps"
            >
              <MapIcon className="h-10 w-10 inline-block" />
              Map
            </a>
          </h2>
          <h3 className="event-date text-white">
            {dayjs(event.date).format("dddd, MMMM D, YYYY")}
          </h3>
          <h4 className="event-age text-white">
            {ageText}
            {timeText && ` | Doors at ${timeText}`}
          </h4>
        </div>

        {/* Company and Buyers */}
        <div className="event-meta-section">
          <div className="event-meta-item">
            <span className="meta-label text-white">Company:</span>
            <span className="meta-value text-white uppercase">
              {event.company}
            </span>
          </div>

          {event.buyers && event.buyers.length > 0 && (
            <div className="event-meta-item">
              <span className="meta-label text-white">Buyers:</span>
              <span className="meta-value text-white">
                <div className="flex flex-row items-center gap-2">
                  {event.buyers.map((buyer) => (
                    <span
                      key={buyer.id}
                      className="buyer-pill inline-flex items-center"
                    >
                      <PPeopleIcon
                        person={buyer}
                        debugInfo={{
                          component: "EventHeader",
                          location: "buyer section",
                        }}
                      />
                    </span>
                  ))}
                </div>
              </span>
            </div>
          )}
        </div>

        {/* Artists and Co-Pros */}
        <div className="event-meta-section">
          <div className="event-meta-item">
            <span className="meta-label text-white">Artists:</span>
            {event.artists && event.artists.length > 0 ? (
              event.artists.map((artist, index) => (
                <span key={artist.id} className="meta-value text-white">
                  <Link
                    to={`/artists/${artist.id}`}
                    className="artist-link text-blue-300 hover:text-blue-100"
                  >
                    {artist.name}
                  </Link>
                  {index < event.artists.length - 1 ? ", " : ""}
                </span>
              ))
            ) : (
              <span className="meta-value text-white">{event.name}</span>
            )}
          </div>

          {coProsList.length > 0 && (
            <div className="event-meta-item">
              <span className="meta-label text-white">Co-Pros:</span>
              <span className="meta-value text-white">
                {coProsList.join(", ")}
              </span>
            </div>
          )}
        </div>

        {/* Navigation Buttons */}
        <div className="container p-1 mb-4 w-full">
          <div className="event-nav-buttons flex flex-wrap justify-center gap-2 w-full">
            {perms?.data &&
              event &&
              ((canSeeNumbers && onlyMarketing == false) ||
                perms.data.edit_event ||
                perms.data.is_admin) && (
                <button
                  className="event-nav-button w-32"
                  onClick={() => navigate(`/events/edit/offer/${event.id}`)}
                >
                  Edit Offer
                </button>
              )}
            {perms?.data &&
              event &&
              ((canSeeNumbers && onlyMarketing == false) ||
                perms.data.edit_event ||
                perms.data.view_settlement ||
                perms.data.is_admin) && (
                <button
                  className="event-nav-button w-32"
                  onClick={() =>
                    navigate(`/events/settlement/artist/${event.id}/`)
                  }
                >
                  Edit Settlement
                </button>
              )}
            {perms?.data &&
              event &&
              (canSeeNumbers ||
                perms.data.edit_event ||
                perms.data.viewPeople === true ||
                perms.data.is_admin) && (
                <button
                  className="event-nav-button w-32"
                  onClick={() => scrollToSection(advanceRef)}
                >
                  Advance
                </button>
              )}
            {perms?.data &&
              event &&
              ((canSeeNumbers && onlyMarketing == false) ||
                perms.data.is_admin) && (
                <button
                  className="event-nav-button w-32"
                  onClick={() => scrollToSection(wiresRef)}
                >
                  Wire Sheet
                </button>
              )}
            {perms?.data &&
              event &&
              (canSeeNumbers ||
                perms.data.edit_event ||
                perms.data.viewPeople === true ||
                perms.data.is_admin) && (
                <button
                  className="event-nav-button w-32"
                  onClick={() => scrollToSection(confirmationRef)}
                >
                  Confirmation
                </button>
              )}
            <button
              className="event-nav-button w-32"
              onClick={() => scrollToSection(guestlistRef)}
            >
              Guest List
            </button>
            <button
              className="event-nav-button w-32"
              onClick={() => scrollToSection(peopleRef)}
            >
              People
            </button>
            {perms?.data &&
              event &&
              (canSeeNumbers ||
                canSeeFiles ||
                perms.data.edit_event ||
                perms.data.viewAllFiles ||
                perms.data.view_files ||
                perms.data.is_admin) && (
                <button
                  className="event-nav-button w-32"
                  onClick={() => scrollToSection(filesRef)}
                >
                  Files
                </button>
              )}
            {perms?.data &&
              event &&
              (canSeeNumbers ||
                perms.data.edit_event ||
                perms.data.viewPeople === true ||
                perms.data.is_admin) && (
                <button
                  className="event-nav-button w-32"
                  onClick={() => scrollToSection(expensesRef)}
                >
                  Expenses
                </button>
              )}
            {perms?.data && event && canSeeNumbers && buyerLike && (
              <DuplicateEventButton
                eventId={event.id}
                className="event-nav-button w-32"
              />
            )}
            {perms?.data &&
              event &&
              ((canSeeNumbers && onlyMarketing == false) ||
                perms.data.is_admin) && (
                <button
                  className="event-nav-button w-32"
                  onClick={() => navigate(`/events/edit/${event.id}`)}
                >
                  Edit Event
                </button>
              )}
          </div>
        </div>

        {/* Hold Buttons */}
        {event.state === "hold" &&
          event.buyers.some((buyerId) => buyerId === user.data.id) && (
            <div className="flex gap-4 mb-4">
              <HoldSwitcher eventId={event.id} />
              <button
                onClick={async () => {
                  if (
                    window.confirm(
                      "Are you sure you want to release your hold?"
                    )
                  ) {
                    try {
                      await releaseHold(event.id);
                      toast.success("Hold released successfully");
                      window.location.reload();
                    } catch (error) {
                      toast.error("Failed to release hold: " + error.message);
                    }
                  }
                }}
                className="bg-red-600 hover:bg-red-700 text-white font-bold py-2 px-4 rounded"
              >
                Release My Hold
              </button>
            </div>
          )}

        {/* Artist Image */}
        <div className="artist-image-wrapper full-width">
          <div className="wave-pattern-background"></div>
          <img
            src={
              event.event_image_url ||
              event.highlight ||
              "/default-event-image.jpg"
            }
            alt={event.name}
            className="artist-image full-width-image"
          />
        </div>
      </div>
    </div>
  );
};

export default EventHeader;
