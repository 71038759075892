import { AgGridReact } from "ag-grid-react";
import classNames from "classnames";
import { useState } from "react";

const TextAreaAgGrid = ({
  header,
  noBlue,
  value = "",
  disabled,
  onInputChange,
  accessor,
  style,
}) => {
  const [isAdding, setIsAdding] = useState(false);
  const handleBlur = (e) => {
    if (onInputChange) {
      onInputChange(accessor, e.target.value);
    }
  };

  return (
    <div className="flex flex-col h-full w-full">
      <div className={`bg-gray-300 ${noBlue ? "py-0" : ""}`}>
        <div
          className={`text-base text-center ${noBlue ? "py-0.5" : "py-1"
            } px-2 font-montserrat`}
        >
          {header}
        </div>
      </div>
      <div className="h-full">
        <textarea
          className={classNames(
            !isAdding
              ? `text-sm w-full h-full font-montserrat ${noBlue ? "bg-cave-white" : "bg-blue-300"
              } selection:bg-blue-900 selection:text-cave-white p-1 focus:outline-none focus:ring-2 focus:ring-blue-400`
              : "bg-cave-blue-1 border-cave-blue-3 border-4 p-4 m-2"
          )}
          defaultValue={value}
          disabled={disabled}
          onBlur={handleBlur}
          onKeyDown={(e) => {
            if (e.key === "Enter" && !e.shiftKey) {
              e.stopPropagation();
            }
          }}
          style={{
            resize: "vertical",
            minHeight: noBlue ? "60px" : "100px",
            whiteSpace: "pre-wrap",
            ...(style || {}),
          }}
        />
      </div>
    </div>
  );
};

export default TextAreaAgGrid;
