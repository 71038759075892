import React, { useEffect, useState } from "react";
import { Header } from "@/components/Header";
import * as yup from "yup";
import { useParams, useNavigate } from "react-router-dom";
import { useQuery, useMutation, useQueryClient } from "@tanstack/react-query";
import {
  FormBuilder,
  Input,
  TextInput,
  Datepicker,
  FormSelect,
} from "@/components/Form";
import { createArtistLink } from "@/queries/artists";
import { getArtist } from "@/queries/artists";
import { BreadCrumbs } from "@/components/BreadCrumbs";
import Button from "@/components/Button";

export const ArtistLinkAdd = () => {
  const { id } = useParams(); // Artist ID
  const artist = useQuery(["artist-detail", id], () => getArtist(id));
  const navigate = useNavigate();
  const queryClient = useQueryClient();

  const mutation = useMutation({
    mutationFn: (data) => {
      console.log("---> creating link");
      console.dir(data);
      return createArtistLink(id, data);
    },
    onSuccess: async (data) => {
      queryClient.invalidateQueries(["artist", "links", id]);
      navigate(`/artists/${id}`);
    },
  });

  const addSchema = yup.object().shape({
    name: yup.string().required(),
    url: yup.string().required(),
    description: yup.string(),
  });

  const handleSubmit = (data) => {
    console.log("In submit");
    console.dir(data);
    mutation.mutate(data);
  };

  // Wait for Event to load
  if (artist.isLoading) {
    return (
      <div className="text-center text-gray-700 dark:text-gray-300">
        Loading...
      </div>
    );
  }

  return (
    <>
      <Header />
      <div
        className="
        flex flex-col px-6 py-8 mx-auto min-h-screen lg:py-0
    "
      >
        <BreadCrumbs
          links={[
            { text: "Artists", url: "/artists" },
            { text: "Add Artist Link" },
          ]}
        />

        <h3 className="text-xl font-bold dark:text-cave-white">
          Add New Link for {artist.data.name}
        </h3>
        <div className="py-6 min-w-full">
          <FormBuilder
            onSubmit={(data) => handleSubmit(data)}
            schema={addSchema}
          >
            <Input
              name="name"
              label="Name"
              autoFocus={true}
              autoComplete="one-time-code"
            />
            <Input name="url" label="URL" placeholder="https://example.com" />
            <TextInput
              name="description"
              label="Description"
              placeholder="Optional description"
            />
            <div className="flex-inline float-right mb-12">
              <button
                type="button"
                onClick={() => navigate(`/artists/${id}`)}
                className="ml-4 mr-4 rounded-md bg-gray-400 px-3 py-2 text-sm font-semibold text-gray-600 shadow-sm hover:bg-gray-300 focus-visible:outline focus-visible:outline-2 focus-visible:outline-offset-2 focus-visible:outline-blue-600"
              >
                Cancel
              </button>
              <Button type="submit">Add Link</Button>
            </div>
          </FormBuilder>
        </div>
      </div>
    </>
  );
};
