import { useQuery, useMutation, useQueryClient } from "@tanstack/react-query";
import { Fragment, useEffect, useState } from "react";
import { Dialog, Transition, Switch } from "@headlessui/react";
import {
  PencilIcon,
  TrashIcon,
  XMarkIcon,
  PlusIcon,
  ArrowUturnLeftIcon as ReplyIcon,
} from "@heroicons/react/24/outline";
import PinIcon from "../Icons/PinIcon";
import AsyncSelect from "react-select";
import {
  getBuyerEvents,
  getEventsList,
  getEventsSettings,
} from "@/queries/events";
import { useUser } from "@/hooks/useUser";
import {
  getAccountingMessages,
  dismissAccountingMessage,
  createAccountingMessage,
  updateAccountingMessage,
  deleteAccountingMessage,
} from "@/queries/accounting";
import { LoadingIndicator } from "../shared/LoadingIndicator";
import Button from "../Button";
import ConfirmModal from "../Common/ConfirmModal";
import { toast } from "react-toastify";
import { USER_ROLES } from "@/types/user";
import { Tooltip } from "primereact/tooltip";
import Select from "react-select";
import { CaveEvent } from "@/types/event";
import { CaveError } from "@/types";

interface Event {
  event_name: string;
  event_id: number;
  buyer_id: number;
  buyer_name: string;
  date: string;
  venue_name: string;
}

interface EventsResponse {
  person_id: number;
  events: Event[];
  is_buyer: boolean;
}

interface AccMessage {
  id: number;
  sender_id: number | null;
  sender_name: string;
  sender_image_url: string | null;
  recipient_id: number | null;
  event_id: number | null;
  content: string;
  is_sticky: boolean;
  created_at: string;
}

interface EventOption {
  value: number;
  label: string;
}

interface RecipientOption {
  value: number;
  label: string;
}

interface Person {
  id: number;
  first_name: string;
  last_name: string;
  mammoth_role: string;
}

interface MessageComposerProps {
  isOpen: boolean;
  onClose: () => void;
  initialMessage: AccMessage | null;
  events: Event[];
  replyTo?: AccMessage | null;
}

interface MessageCardProps {
  message: AccMessage;
  onEdit: (message: AccMessage) => void;
  onDelete: (messageId: number) => void;
  onDismiss: (messageId: number) => void;
  onReply: (message: AccMessage) => void;
}

interface DeleteConfirmationProps {
  isOpen: boolean;
  onClose: () => void;
  onConfirm: () => void;
  isDeleting: boolean;
}

function MessageComposer({
  isOpen,
  onClose,
  initialMessage = null,
  events,
  replyTo = null,
}: MessageComposerProps) {
  const queryClient = useQueryClient();
  const [message, setMessage] = useState(initialMessage?.content || "");
  const [isSticky, setIsSticky] = useState(initialMessage?.is_sticky || false);
  const { user } = useUser();
  const { data: eventsData = [], isLoading: eventsLoading } = useQuery<
    CaveEvent[],
    CaveError
  >(["events", "list"], () => getEventsList(), getEventsSettings);
  const recipients = useQuery(["people", "accounting"], () => {
    return fetch(`${window.location.origin}/api/people/staff/`)
      .then((res) => res.json())
      .then((data) =>
        data.filter(
          (person: Person) =>
            person.first_name &&
            ["accounting", "accounting_admin"].includes(person.mammoth_role)
        )
      );
  });
  const [selectedEvent, setSelectedEvent] = useState<EventOption | null>(
    initialMessage?.event_id
      ? {
          value: initialMessage.event_id,
          label:
            events.find((e) => e.event_id === initialMessage.event_id)
              ?.event_name || `Event #${initialMessage.event_id}`,
        }
      : null
  );
  const [selectedRecipient, setSelectedRecipient] =
    useState<RecipientOption | null>(
      initialMessage?.recipient_id
        ? {
            value: initialMessage.recipient_id,
            label: `Recipient #${initialMessage.recipient_id}`,
          }
        : null
    );
  const eventOptions: EventOption[] = [
    { value: -1, label: "No specific event" },
    ...eventsData
      .sort((a, b) => a.name.localeCompare(b.name))
      .map((event) => ({
        value: event.id,
        label: `${event.name} @ ${event?.venue_name} (${new Date(
          event?.date
        ).toLocaleDateString()})`,
      })),
  ];
  const createMutation = useMutation({
    mutationFn: createAccountingMessage,
    onSuccess: () => {
      toast.success("Message created successfully");
      queryClient.invalidateQueries(["acc_bb"]);
      setMessage("");
      setSelectedEvent(null);
      setSelectedRecipient(null);
      setIsSticky(false);
      onClose();
    },
    onError: (error) => {
      toast.error("Error creating message");
      console.error("Error creating message:", error);
    },
  });
  const updateMutation = useMutation({
    mutationFn: (data: any) =>
      updateAccountingMessage(initialMessage!.id, data),
    onSuccess: () => {
      toast.success("Message updated successfully");
      queryClient.invalidateQueries(["acc_bb"]);
      onClose();
      setMessage("");
      setSelectedEvent(null);
      setSelectedRecipient(null);
      setIsSticky(false);
    },
    onError: (error) => {
      toast.error("Error updating message");
      console.error("Error updating message:", error);
    },
  });
  const handleSubmit = (e: React.FormEvent) => {
    e.preventDefault();
    const payload = {
      content: message,
      is_sticky: isSticky,
      event_id: selectedEvent ? selectedEvent.value : -1,
      recipient_id: selectedRecipient ? selectedRecipient.value : -1,
    };
    if (initialMessage) {
      updateMutation.mutate(payload);
    } else {
      createMutation.mutate(payload);
    }
  };
  useEffect(() => {
    if (initialMessage) {
      setMessage(initialMessage.content);
      setIsSticky(initialMessage.is_sticky);
      setSelectedEvent(
        initialMessage.event_id
          ? {
              value: initialMessage.event_id,
              label:
                events.find((e) => e.event_id === initialMessage.event_id)
                  ?.event_name || `Event #${initialMessage.event_id}`,
            }
          : null
      );
      setSelectedRecipient(
        initialMessage.recipient_id
          ? {
              value: initialMessage.recipient_id,
              label: `Recipient #${initialMessage.recipient_id}`,
            }
          : null
      );
    } else {
      setMessage("");
      setIsSticky(false);
      setSelectedEvent(null);
      setSelectedRecipient(null);
    }
  }, [initialMessage]);
  useEffect(() => {
    if (!initialMessage && replyTo) {
      setMessage(`> ${replyTo.content}\n\n`);
    }
  }, [replyTo, initialMessage]);
  return (
    <Transition appear show={isOpen} as={Fragment}>
      <Dialog as="div" className="relative z-50" onClose={onClose}>
        <Transition.Child
          as={Fragment}
          enter="ease-out duration-300"
          enterFrom="opacity-0"
          enterTo="opacity-100"
          leave="ease-in duration-200"
          leaveFrom="opacity-100"
          leaveTo="opacity-0"
        >
          <div className="fixed inset-0 bg-black/70" />
        </Transition.Child>
        <div className="fixed inset-0 overflow-y-auto">
          <div className="flex min-h-full items-center justify-center p-4">
            <Transition.Child
              as={Fragment}
              enter="ease-out duration-300"
              enterFrom="opacity-0 scale-95"
              enterTo="opacity-100 scale-100"
              leave="ease-in duration-200"
              leaveFrom="opacity-100 scale-100"
              leaveTo="opacity-0 scale-95"
            >
              <Dialog.Panel className="w-full max-w-lg transform overflow-hidden rounded-lg bg-gray-800  p-6 shadow-xl transition-all">
                <Dialog.Title className="text-xl font-semibold mb-4 text-white">
                  {initialMessage ? "Edit Message" : "Create Message"}
                </Dialog.Title>
                <form onSubmit={handleSubmit}>
                  <div className="mb-4">
                    <label className="block text-sm font-medium mb-2 text-white">
                      Recipient (optional)
                    </label>
                    <AsyncSelect
                      className="text-black"
                      value={selectedRecipient}
                      onChange={setSelectedRecipient}
                      options={(recipients.data ?? []).map(
                        (person: Person) => ({
                          value: person?.user?.id,
                          label: `${person.first_name} ${person.last_name}`,
                        })
                      )}
                      getOptionLabel={(option) => option?.label}
                      getOptionValue={(option) => option?.value}
                      placeholder="Search for a person..."
                      noOptionsMessage={({ inputValue }) =>
                        inputValue ? "No results found" : "Type to search..."
                      }
                      loadingMessage={() => "Loading..."}
                      isClearable
                      isSearchable
                    />
                    <p className="text-xs text-gray-400 mt-1">
                      Start typing to search for accounting staff members
                    </p>
                  </div>
                  <div className="mb-4">
                    <label className="block text-sm font-medium mb-2 text-white">
                      Related Event (optional)
                    </label>
                    <Select
                      value={selectedEvent}
                      onChange={setSelectedEvent}
                      options={eventOptions}
                      placeholder="Select an event or leave empty"
                      isSearchable
                      isClearable
                      classNamePrefix="react-select"
                    />
                  </div>
                  <div className="mb-4">
                    <label className="block text-sm font-medium mb-2 text-white">
                      Message
                    </label>
                    <textarea
                      className="w-full text-black rounded-lg p-3 mb-1 min-h-[10rem] focus:ring-2 focus:ring-blue-500 focus:outline-none"
                      placeholder="Enter your message..."
                      value={message}
                      onChange={(e) => setMessage(e.target.value)}
                      required
                    />
                  </div>
                  {["accounting_admin", "admin"].includes(user?.role_name) && (
                    <div className="flex items-center mb-6">
                      <Switch
                        checked={isSticky}
                        onChange={setIsSticky}
                        className={`${
                          isSticky ? "bg-blue-600" : "bg-gray-700"
                        } relative inline-flex h-6 w-11 items-center rounded-full transition-colors focus:outline-none focus:ring-2 focus:ring-blue-500 focus:ring-offset-2`}
                      >
                        <span className="sr-only">Pin message</span>
                        <span
                          className={`${
                            isSticky ? "translate-x-6" : "translate-x-1"
                          } inline-block h-4 w-4 transform rounded-full bg-white transition-transform`}
                        />
                      </Switch>
                      <label className="ml-3 text-sm flex items-center">
                        <PinIcon className="w-4 h-4 mr-1" />
                        <span className="text-white">Pin this message</span>
                      </label>
                    </div>
                  )}
                  <div className="flex justify-end space-x-3 mt-6">
                    <Button type="button" variant="secondary" onClick={onClose}>
                      Cancel
                    </Button>
                    <Button
                      type="submit"
                      variant="success"
                      loading={
                        createMutation.isLoading || updateMutation.isLoading
                      }
                    >
                      Submit
                    </Button>
                  </div>
                </form>
              </Dialog.Panel>
            </Transition.Child>
          </div>
        </div>
      </Dialog>
    </Transition>
  );
}

function MessageCard({
  message,
  onEdit,
  onDelete,
  onDismiss,
  onReply,
  events,
}: MessageCardProps & { events: Event[] }) {
  const { user, roleUserIsUsing } = useUser();
  const formatDate = (dateString: string) => {
    const date = new Date(dateString);
    return (
      date.toLocaleDateString(undefined, { month: "short", day: "numeric" }) +
      " at " +
      date.toLocaleTimeString([], { hour: "2-digit", minute: "2-digit" })
    );
  };
  const foundEvent = events.find((e) => e.event_id === message.event_id);
  const ActionButton = ({
    children,
    onClick,
    variant,
    tooltipText,
  }: {
    children: React.ReactNode;
    onClick: () => void;
    tooltipText?: string;
    variant:
      | "primary"
      | "secondary"
      | "success"
      | "danger"
      | "warning"
      | "info";
  }) => {
    return (
      <Button
        rounded
        size="icon"
        className={`w-10 h-10 ${tooltipText}`}
        onClick={onClick}
        variant={variant}
        data-pr-tooltip={tooltipText}
        data-pr-position="top"
      >
        {children}
        <Tooltip target={`.${tooltipText}`} position="top" />
      </Button>
    );
  };
  return (
    <div className="relative group w-full bg-gray-800 rounded-lg px-4 pb-6 pt-2 mb-3 transition-all hover:bg-gray-750 border border-gray-700">
      {message.is_sticky && (
        <div className="absolute top-2 right-3 text-red-500 font-bold text-xs">
          <div className="flex items-center gap-1.5">
            <PinIcon className="w-4 h-4" />
            <span>Pinned</span>
          </div>
        </div>
      )}
      <div className="mb-2">
        {message.sender_image_url ? (
          <img
            src={message.sender_image_url}
            alt="Sender"
            className="w-8 h-8 rounded-full mr-3"
          />
        ) : (
          <div className="w-8 h-8 rounded-full bg-blue-600 flex items-center justify-center mr-3">
            <span className="text-sm font-bold">A</span>
          </div>
        )}
        <div className="flex-1">
          <div className="flex justify-between items-center">
            <span className="font-medium">
              {message.sender_name
                ? `From: ${message.sender_name}`
                : "Accounting Team"}
            </span>
            <span className="text-xs text-gray-400 -mt-7">
              {formatDate(message.created_at)}
            </span>
          </div>
          <div className="flex flex-wrap gap-2 mt-1 mb-1">
            {message.recipient_id && (
              <span className="text-xs bg-purple-900 text-purple-100 px-2 py-0.5 rounded">
                To:{" "}
                {message.recipient_id === user?.id ? (
                  <span className="font-bold">You</span>
                ) : (
                  `#${message.recipient_id}`
                )}
              </span>
            )}
            {message.event_id && message.event_id !== -1 && (
              <span className="text-xs bg-blue-900 text-blue-100 px-2 py-0.5 rounded">
                Event{" "}
                <span className="font-bold">{foundEvent?.event_name}</span>
              </span>
            )}
          </div>
          <p className="text-sm mt-4 mb-10 whitespace-pre-wrap w-full">
            {message.content}
          </p>
        </div>
      </div>
      <div className="flex absolute bottom-4 right-4 space-x-2">
        <>
          {[USER_ROLES.ADMIN, USER_ROLES.ACCOUNTING_ADMIN].includes(
            roleUserIsUsing
          ) && (
            <>
              <ActionButton
                onClick={() => onEdit(message)}
                variant="info"
                tooltipText="Edit"
              >
                <PencilIcon className="h-5 w-5" />
              </ActionButton>
              <ActionButton
                onClick={() => onDelete(message.id)}
                variant="danger"
                tooltipText="Delete"
              >
                <TrashIcon className="h-5 w-5" />
              </ActionButton>
            </>
          )}
          <ActionButton
            onClick={() => onReply(message)}
            variant="primary"
            tooltipText="Reply"
          >
            <ReplyIcon className="h-5 w-5" />
          </ActionButton>
          {!message.is_sticky && (
            <ActionButton
              onClick={() => onDismiss(message.id)}
              variant="warning"
              tooltipText="Dismiss"
            >
              <XMarkIcon className="h-6 w-6" />
            </ActionButton>
          )}
        </>
      </div>
    </div>
  );
}

export default function AccountingDashboardBulletinBoard() {
  const queryClient = useQueryClient();
  const [isComposerOpen, setIsComposerOpen] = useState(false);
  const [isDeleteConfirmOpen, setIsDeleteConfirmOpen] = useState(false);
  const [messageToEdit, setMessageToEdit] = useState<AccMessage | null>(null);
  const [messageToDelete, setMessageToDelete] = useState<number | null>(null);
  const [messageToDismiss, setMessageToDismiss] = useState<number | null>(null);
  const [messageToReply, setMessageToReply] = useState<AccMessage | null>(null);
  const { user, personId, roleUserIsUsing } = useUser();
  const {
    data: eventsData,
    error: eventsError,
    isLoading: eventsLoading,
  } = useQuery(
    ["accountingEvents", personId],
    () => getBuyerEvents(personId, true),
    {
      enabled: !!user && !!personId,
      staleTime: 5 * 60 * 1000,
    }
  );
  const {
    data: accBulletins,
    error: accBulletinsError,
    isLoading: accBulletinsLoading,
  } = useQuery(["acc_bb"], getAccountingMessages, {
    retry: true,
    refetchOnWindowFocus: true,
  });
  const dismissMutation = useMutation({
    mutationFn: dismissAccountingMessage,
    onSuccess: () => {
      toast.success("Message dismissed successfully");
      queryClient.invalidateQueries(["acc_bb"]);
      setMessageToDismiss(null);
    },
    onError: (error) => {
      toast.error("Error dismissing message");
      console.error("Error dismissing message:", error);
    },
  });
  const deleteMutation = useMutation({
    mutationFn: deleteAccountingMessage,
    onSuccess: () => {
      toast.success("Message deleted successfully");
      queryClient.invalidateQueries(["acc_bb"]);
      setIsDeleteConfirmOpen(false);
      setMessageToDelete(null);
    },
    onError: (error) => {
      toast.error("Error deleting message");
      console.error("Error deleting message:", error);
    },
  });
  const openComposer = () => {
    setMessageToEdit(null);
    setMessageToReply(null);
    setIsComposerOpen(true);
  };
  const editMessage = (message: AccMessage) => {
    setMessageToEdit(message);
    setMessageToReply(null);
    setIsComposerOpen(true);
  };
  const replyMessage = (message: AccMessage) => {
    setMessageToReply(message);
    setMessageToEdit(null);
    setIsComposerOpen(true);
  };
  const confirmDelete = (messageId: number) => {
    setMessageToDelete(messageId);
    setIsDeleteConfirmOpen(true);
  };
  const confirmDismiss = (messageId: number) => {
    dismissMutation.mutate(messageId);
  };
  const executeDelete = () => {
    if (messageToDelete) {
      deleteMutation.mutate(messageToDelete);
    }
  };
  const events = eventsData?.events || [];
  if (accBulletinsLoading || eventsLoading) {
    return <LoadingIndicator />;
  }
  if (accBulletinsError || eventsError) {
    console.error("Error fetching data:", accBulletinsError || eventsError);
    return <div className="text-red-400 p-4">Error loading bulletin board</div>;
  }
  return (
    <div>
      <div className="flex items-center justify-between mb-6">
        <div className="flex items-center">
          <h2 className="text-xl font-semibold">Bulletin Board</h2>
          <div className="ml-2 bg-red-500 text-white text-xs rounded-full w-8 h-8 flex items-center justify-center">
            {accBulletins?.length}
          </div>
        </div>
        {[USER_ROLES.ADMIN, USER_ROLES.ACCOUNTING_ADMIN].includes(
          roleUserIsUsing
        ) && (
          <Button onClick={openComposer} variant="primary">
            <PlusIcon className="h-4 w-4 mr-1" />
            New Message
          </Button>
        )}
      </div>
      <div className="mb-8 overflow-x-auto min-w-full">
        {accBulletins && accBulletins.length > 0 ? (
          <div className="flex gap-4">
            {accBulletins.map((message: AccMessage) => (
              <div key={message.id} className="flex-shrink-0 w-[425px]">
                <MessageCard
                  events={events}
                  message={message}
                  onEdit={editMessage}
                  onDelete={confirmDelete}
                  onDismiss={() => setMessageToDismiss(message.id)}
                  onReply={replyMessage}
                />
              </div>
            ))}
          </div>
        ) : (
          <div className="bg-gray-800 rounded-lg p-6 text-center text-gray-400">
            <p>No department messages available</p>
          </div>
        )}
      </div>
      <MessageComposer
        isOpen={isComposerOpen}
        onClose={() => {
          setIsComposerOpen(false);
          setMessageToEdit(null);
          setMessageToReply(null);
        }}
        initialMessage={messageToEdit}
        replyTo={messageToReply}
        events={events}
      />
      <ConfirmModal
        show={isDeleteConfirmOpen}
        message="Are you sure you want to delete this message?"
        onCancel={() => setIsDeleteConfirmOpen(false)}
        onClose={() => setIsDeleteConfirmOpen(false)}
        onConfirm={executeDelete}
        isDeleting={deleteMutation.isLoading}
      />
      <ConfirmModal
        show={messageToDismiss !== null}
        message="Are you sure you want to dismiss this message?"
        onCancel={() => setMessageToDismiss(null)}
        onClose={() => setMessageToDismiss(null)}
        onConfirm={() => confirmDismiss(messageToDismiss!)}
        isDeleting={dismissMutation.isLoading}
      />
    </div>
  );
}
