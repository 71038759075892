// useOverrides.ts
import { useQueryClient } from "@tanstack/react-query";
import { toast } from "react-toastify";
import {
  createEventOverride,
  updateEventOverride,
  deleteEventOverride,
  FinalOverride,
  CreateFinalOverrideData,
} from "@/queries/settlement-overrides";
import { useSettlementComponent } from "@/hooks/useSettlementComponent";

// Helper: Normalize field names to lowercase with underscores.
const normalizeFieldName = (name: string) =>
  name.trim().toLowerCase().replace(/\s+/g, "_");

export interface GetOverrideOptions {
  key: string;
  mainSection: string;
  fallback?: string | number;
  subSection?: string;
}

export interface HandleOverrideOptions {
  key: string;
  value: any;
  isMoney?: boolean;
  sectionName: string;
  subSectionName?: string;
}

/**
 * This hook centralizes override logic. It always stores and returns override
 * values as JSON strings (parsed into objects on retrieval).
 */
export function useOverrides(eventId: string) {
  const {
    event,
    pageIndex, // "artist", "copro", or "final"
    eventOverrides,
    createEventOverrideMutation,
    updateEventOverrideMutation,
    deleteEventOverrideMutation,
    rollups,
    eventSettlement,
    variables,
  } = useSettlementComponent({ enableExpenses: true });

  const queryClient = useQueryClient();
  const overrides = eventOverrides.data ?? [];

  const loading =
    !event?.data ||
    !rollups?.data ||
    !eventSettlement?.data ||
    (overrides.length === 0 && eventOverrides.isLoading);

  function getOverrideFieldValue(opts: GetOverrideOptions): any {
    const { key, mainSection, fallback = 0, subSection } = opts;
    // Build the base key and normalize it.
    const baseKey = `__${key.replace(/^__/, "")}`;
    const normalizedBaseKey = normalizeFieldName(baseKey);

    // Determine the override order based on the current page:
    let booksOrder: string[] = [];
    if (pageIndex === "artist") {
      booksOrder = ["artist"];
    } else if (pageIndex === "copro") {
      booksOrder = ["copro", "artist"];
    } else if (pageIndex === "final") {
      booksOrder = ["final", "copro", "artist"];
    } else {
      booksOrder = ["artist"];
    }

    // Loop over the books in order and return the first matching override.
    for (const book of booksOrder) {
      const override = overrides.find(
        (o) =>
          normalizeFieldName(o.field_name) === normalizedBaseKey &&
          o.section_name === mainSection &&
          o.sub_section_name === (subSection || mainSection) &&
          o.book_name === book
      );
      if (override) {
        try {
          const parsed = JSON.parse(override.value);
          // If parsed value is an object with a total property, return it;
          // otherwise wrap it in an object.
          if (
            parsed &&
            typeof parsed === "object" &&
            parsed.total !== undefined
          ) {
            return parsed;
          } else {
            return { total: parsed };
          }
        } catch (error) {
          console.error("Error parsing override JSON:", error);
        }
      }
    }
    return { total: fallback };
  }

  async function handleOverrideFn(
    opts: HandleOverrideOptions
  ): Promise<{ success: boolean }> {
    const { key, value, isMoney = true, sectionName, subSectionName } = opts;
    if (!event?.data?.id || !key) return { success: false };
    const finalKey = key.startsWith("__") ? key : `__${key}`;
    const isJson =
      typeof value === "string" &&
      value.trim().startsWith("{") &&
      value.trim().endsWith("}");
    const resolvedValue =
      isJson || !isMoney ? value : (Number(value) || 0).toString();
    const overrideData: CreateFinalOverrideData = {
      field_name: finalKey,
      value: resolvedValue,
      is_money: isMoney,
      section_name: sectionName,
      sub_section_name: subSectionName || sectionName,
      event: event.data.id,
    };
    const existingOverride = overrides.find(
      (o) =>
        normalizeFieldName(o.field_name) === normalizeFieldName(finalKey) &&
        o.section_name === sectionName
    );
    try {
      if (existingOverride) {
        await updateEventOverrideMutation.mutateAsync({
          data: overrideData,
          overrideId: existingOverride.id,
        });
      } else {
        await createEventOverrideMutation.mutateAsync(overrideData);
      }
      return { success: true };
    } catch (error) {
      console.error("Error handling override:", error);
      toast.error("Failed to save override");
      return { success: false };
    }
  }

  async function deleteOverrideFn(key: string): Promise<{ success: boolean }> {
    const searchKey = key.startsWith("__") ? key : `__${key}`;
    const normalizedSearchKey = normalizeFieldName(searchKey);
    const existing = overrides.find(
      (o) =>
        normalizeFieldName(o.field_name) === normalizedSearchKey &&
        o.section_name === "promoter_revenues" && // adjust if needed
        o.sub_section_name === "promoter_revenues"
    );
    if (!existing) return { success: false };
    try {
      await deleteEventOverrideMutation.mutateAsync(existing.id);
      return { success: true };
    } catch (error) {
      console.error("Error deleting override:", error);
      toast.error("Failed to delete override");
      return { success: false };
    }
  }

  async function deleteOverrideById(
    overrideId: string
  ): Promise<{ success: boolean }> {
    try {
      await deleteEventOverrideMutation.mutateAsync(overrideId);
      return { success: true };
    } catch (error) {
      console.error("Error deleting override by id:", error);
      toast.error("Failed to delete override");
      return { success: false };
    }
  }

  return {
    loading,
    eventData: event.data,
    eventSettlement,
    rollups,
    overrides,
    getOverrideFieldValue,
    handleOverride: handleOverrideFn,
    deleteOverride: deleteOverrideFn,
    deleteOverrideById,
    pageIndex,
    variables,
  };
}
