import React from "react";
import { Header } from "@/components/Header";
import * as yup from "yup";
import { useParams, useNavigate } from "react-router-dom";
import { useQuery, useMutation, useQueryClient } from "@tanstack/react-query";
import { getEvent, updateEvent } from "@/queries/events";
import { LoadingPulse } from "@/components/shared/LoadingPulse";
import { BreadCrumbs } from "@/components/BreadCrumbs";
import { usePageTitle } from "@/utils/pagetitle";
import { toast } from "react-toastify";
import {
  Datepicker,
  FormBuilder,
  Input,
  TextInput,
  FormSelect,
  FormMultiSelect,
} from "@/components/Form";
import { stateChoices } from "@/utils/states";
import { companyChoices } from "@/utils/states";
import { getVenues, getVenuesSettings } from "@/queries/venues";
import { getArtists, getArtistsSettings } from "@/queries/artists";
import { getStaff } from "@/queries/people";
import Button from "@/components/Button";

export const EventEditNew = () => {
  const { id } = useParams();
  const navigate = useNavigate();
  const queryClient = useQueryClient();

  // Queries
  const event = useQuery(["event-detail", id], () => getEvent(id));
  const venues = useQuery(["venues"], () => getVenues(), getVenuesSettings);
  const artists = useQuery(["artists"], getArtists, getArtistsSettings);
  const staff = useQuery(["staff"], getStaff);

  const getEventDate = event?.data?.date ? `[${event.data.date}]` : "[TBD]";

  usePageTitle(
    event.isSuccess
      ? `/c${String.fromCharCode(92)}${event.data.name}`
      : `/c${String.fromCharCode(92)}ave_good...`
  );

  // Process form data before submission
  const processFormData = (data) => {
    // Create a copy to avoid mutating the original
    const processedData = { ...data };

    // Handle venue selection
    if (processedData.venue_id && typeof processedData.venue_id === "object") {
      processedData.venue_id = parseInt(processedData.venue_id.value);
    }

    // Handle state selection
    if (processedData.state && typeof processedData.state === "object") {
      processedData.state = processedData.state.value;
    } else if (!processedData.state) {
      processedData.state = "draft";
    }

    // Handle age requirement
    if (
      processedData.age_requirement &&
      typeof processedData.age_requirement === "object"
    ) {
      processedData.age_requirement = processedData.age_requirement.value;
    }

    // Handle company selection
    if (processedData.company && typeof processedData.company === "object") {
      processedData.company = processedData.company.value;
    }

    // Handle artists array
    if (Array.isArray(processedData.artists)) {
      processedData.artists = processedData.artists
        .map((artist) =>
          typeof artist === "object" ? parseInt(artist.value) : parseInt(artist)
        )
        .filter((id) => !isNaN(id));
    } else {
      processedData.artists = [];
    }

    // Handle buyers array - this is where the issue is
    if (Array.isArray(processedData.buyers)) {
      // Log the original buyers array for debugging
      console.log("Original buyers:", processedData.buyers);

      // Ensure we're sending an array of values, not objects
      processedData.buyers = processedData.buyers
        .map((buyer) => {
          // Handle different formats of buyer data
          if (buyer === null || buyer === undefined) {
            return null;
          }

          if (typeof buyer === "object") {
            // Make sure we're extracting the value correctly
            return buyer.value;
          }

          // If it's already a primitive value, return it
          return buyer;
        })
        .filter(Boolean); // Remove any null/undefined values

      // Log the processed buyers for debugging
      console.log("Processed buyers:", processedData.buyers);
    } else {
      processedData.buyers = [];
    }

    // Handle time fields
    [
      "door_time",
      "opener_time",
      "support_time",
      "headliner_time",
      "curfew_time",
    ].forEach((field) => {
      if (processedData[field] === "") {
        processedData[field] = null;
      }
    });

    return processedData;
  };

  // Mutations
  const mutation = useMutation({
    mutationFn: (data) => {
      const processedData = processFormData(data);
      return updateEvent(id, processedData);
    },
    onSuccess: async (data) => {
      queryClient.invalidateQueries(["events"]);
      queryClient.setQueryData(["event-detail", id], data);
      toast.success("Event updated successfully");
      navigate(`/events/${id}`);
    },
    onError: (error) => {
      toast.error(`Error updating event: ${error.message}`);
    },
  });

  const handleSubmit = (data) => {
    mutation.mutate(data);
  };

  // Loading state
  if (
    event.isLoading ||
    venues.isLoading ||
    artists.isLoading ||
    staff.isLoading
  ) {
    return <LoadingPulse isLoading={true} />;
  }

  // Check if we have event data
  if (!event.data) {
    return (
      <>
        <Header />
        <div className="flex flex-col px-6 py-8 mx-auto min-h-screen lg:py-0 dark:bg-cave-black dark:text-cave-white">
          <div className="py-6">
            <p className="text-red-500">Error: Could not load event data</p>
            <button
              onClick={() => navigate("/events")}
              className="mt-4 rounded-md bg-blue-400 px-3 py-2 text-sm font-semibold text-cave-white shadow-sm hover:bg-blue-600"
            >
              Back to Events
            </button>
          </div>
        </div>
      </>
    );
  }

  // Prepare options for select fields
  const venueOptions = venues.data.map((venue) => ({
    value: venue.id,
    label: venue.name,
  }));

  const artistOptions = artists.data.map((artist) => ({
    value: artist.id,
    label: artist.name,
  }));

  const userOptions = staff.data
    .filter(
      (staffMember) =>
        staffMember.first_name ||
        (staffMember.user && staffMember.user.first_name)
    )
    .map((staffMember) => {
      const firstName = staffMember.user?.first_name || staffMember.first_name;
      const lastName = staffMember.user?.last_name || staffMember.last_name;
      const personId = staffMember.user?.person_id;

      return {
        value: personId,
        label: `${firstName} ${lastName}`,
      };
    });

  const ageOptions = [
    { value: "all-ages", label: "All Ages" },
    { value: "16-up", label: "16+" },
    { value: "18-up", label: "18+" },
    { value: "21-up", label: "21+" },
  ];

  // Prepare initial form values
  const venueObject = event.data?.venue
    ? { value: event.data.venue.id, label: event.data.venue.name }
    : null;

  const stateObject = event.data?.state
    ? stateChoices.find((option) => option.value === event.data.state) || {
        value: "draft",
        label: "Draft",
      }
    : { value: "draft", label: "Draft" };

  const ageObject = event.data?.age_requirement
    ? ageOptions.find(
        (option) => option.value === event.data.age_requirement
      ) || null
    : null;

  const companyObject = event.data?.company
    ? companyChoices.find((option) => option.value === event.data.company) ||
      null
    : null;

  const buyerObjects = event.data?.buyers
    ? event.data.buyers
        .map((buyer) => {
          // First try to get the person_id directly
          const personId = buyer.person_id;
          if (!personId) {
            // If no direct person_id, try to get it from the user relationship
            if (buyer.user && buyer.user.person_id) {
              return {
                value: buyer.user.person_id,
                label: `${buyer.user.first_name} ${buyer.user.last_name}`,
              };
            } else {
              console.log("No person_id found for buyer:", buyer);
              return null;
            }
          }

          // Find the corresponding staff member
          const staffMember = staff.data.find(
            (s) => s.user && s.user.person_id === personId
          );

          if (!staffMember) {
            console.log("No staff member found for person_id:", personId);
            return null;
          }

          return {
            value: personId,
            label: `${staffMember.user.first_name} ${staffMember.user.last_name}`,
          };
        })
        .filter(Boolean)
    : [];

  const artistObjects = event.data?.artists
    ? event.data.artists.map((artist) => ({
        value: artist.id,
        label: artist.name,
      }))
    : [];

  const formValues = {
    name: event.data?.name || "",
    description: event.data?.description || "",
    state: stateObject,
    active: event.data?.active,
    date: event.data?.date ? event.data.date : "",
    abbr: event.data?.abbr || "",
    venue_id: venueObject,
    artists: artistObjects,
    buyers: buyerObjects,
    age_requirement: ageObject,
    company: companyObject,
    door_time: event.data?.door_time || "",
    opener_time: event.data?.opener_time || "",
    support_time: event.data?.support_time || "",
    headliner_time: event.data?.headliner_time || "",
    curfew_time: event.data?.curfew_time || "",
    offer_notes: event.data?.offer_notes || "",
    confirmation_notes: event.data?.confirmation_notes || "",
  };

  // Form validation schema
  const updateSchema = yup.object().shape({
    name: yup.string().required("Event name is required"),
    date: yup.string().required("Event date is required"),
    description: yup.string(),
    abbr: yup.string(),
    venue_id: yup.mixed(),
    state: yup.mixed().default("draft"),
    age_requirement: yup.mixed(),
    company: yup.mixed(),
  });

  return (
    <>
      <Header />
      <div className="flex flex-col px-6 py-8 mx-auto min-h-screen lg:py-0 dark:bg-cave-black dark:text-cave-white">
        <BreadCrumbs
          links={[
            { text: "Events", url: `/events/` },
            {
              text: `${event.data?.name || "Event"} ${getEventDate}`,
              url: `/events/${id}`,
            },
            { text: "Edit New" },
          ]}
        />

        <h3 className="text-xl font-bold mb-4 dark:text-cave-white">
          Edit New - {event.data?.name || "Event"}
        </h3>

        <div className="py-6 min-w-full">
          <FormBuilder
            defaultValues={formValues}
            onSubmit={handleSubmit}
            schema={updateSchema}
          >
            <FormSelect
              name="company"
              label="Company"
              options={companyChoices}
              className="w-48"
              defaultValue={companyObject}
            />

            <Input name="name" label="Name" />
            <Datepicker name="date" label="Date" />
            <FormSelect
              name="venue_id"
              label="Venue"
              options={venueOptions}
              required={true}
            />
            <FormMultiSelect
              name="artists"
              label="Artists"
              options={artistOptions}
              required={true}
            />
            <FormMultiSelect
              name="buyers"
              label="Buyers"
              options={userOptions}
              required={true}
            />
            <FormSelect
              name="state"
              label="Status"
              options={stateChoices}
              defaultValue={stateObject}
            />
            <FormSelect
              name="age_requirement"
              label="Ages"
              options={ageOptions}
              defaultValue={
                ageObject || { value: "all-ages", label: "All Ages" }
              }
            />
            <Input name="abbr" label="Abbreviation" />
            <TextInput name="description" label="Description" />
            <Input name="door_time" type="time" label="Door Time" />
            <Input name="opener_time" type="time" label="Opener Time" />
            <Input name="support_time" type="time" label="Support Time" />
            <Input name="headliner_time" type="time" label="Headliner Time" />
            <Input name="curfew_time" type="time" label="Curfew Time" />
            <TextInput name="offer_notes" label="Offer Notes" />
            <TextInput name="confirmation_notes" label="Confirm Notes" />
            <div className="flex-inline float-right mb-12 mt-8">
              <button
                type="button"
                onClick={() => navigate(`/events/${event.data.id}`)}
                className="ml-4 mr-4 rounded-md bg-gray-400 px-3 py-2 text-sm font-semibold text-gray-600 shadow-sm hover:bg-gray-300 focus-visible:outline focus-visible:outline-2 focus-visible:outline-offset-2 focus-visible:outline-blue-600"
              >
                Cancel
              </button>
              <Button type="submit">Save</Button>
            </div>
          </FormBuilder>
        </div>
      </div>
    </>
  );
};
