import { useUser } from "@/hooks/useUser";
import React, { useEffect, useState, Suspense } from "react";
import AdminDashboard from "./AdminDashboard";
import LoadingVideo from "../LoadingVideo";
import { USER_ROLES } from "@/types/user";
import AccountingDashboard from "./AccountingDashboard";
import StaffingDashboard from "./StaffingDashboard";
import WireDashboard from "./WireDashboard";
import { Header } from "../Header";
import {
  canAccessAccountingDashboard,
  getRoleDisplayName,
  shouldLandOnAccountingDashboard,
} from "@/helpers";
import Select from "react-select";
import { AcademicCapIcon, ChevronDownIcon } from "@heroicons/react/24/outline";

export function Dashboard() {
  const { user, roleUserIsUsing } = useUser();
  const [isLoading, setIsLoading] = useState(true);

  const hasAdminAccess =
    [USER_ROLES.ADMIN].includes(user.role) ||
    [USER_ROLES.ACCOUNTING_PLUS].includes(user.role) ||
    [USER_ROLES.ACCOUNTING].includes(user.role) ||
    [USER_ROLES.ACCOUNTING_ADMIN].includes(user.role) ||
    [USER_ROLES.LEGAL].includes(user.role) ||
    [USER_ROLES.LEGAL_ADMIN].includes(user.role);

  const dashboardOptions = (() => {
    // For ACCOUNTING role - only show accounting and wire dashboards
    if (
      [USER_ROLES.ACCOUNTING].includes(user.role) ||
      [USER_ROLES.ACCOUNTING_ADMIN].includes(user.role)
    ) {
      return [
        { value: "accounting", label: "Accounting Dashboard" },
        { value: "wire", label: "Wire Dashboard" },
      ];
    }

    // For ACCOUNTING_PLUS role - only show default and wire dashboards
    if (
      [USER_ROLES.ACCOUNTING_PLUS].includes(user.role) ||
      [USER_ROLES.LEGAL].includes(user.role) ||
      [USER_ROLES.LEGAL_ADMIN].includes(user.role)
    ) {
      return [
        { value: "default", label: "Default Dashboard" },
        { value: "wire", label: "Wire Dashboard" },
      ];
    }

    // For all other roles (including ADMIN) - show all dashboards
    return [
      { value: "default", label: "Default Dashboard" },
      { value: "accounting", label: "Accounting Dashboard" },
      { value: "staffing", label: "Staffing Dashboard" },
      { value: "wire", label: "Wire Dashboard" },
    ];
  })();

  const [selectedDashboard, setSelectedDashboard] = useState("admin");
  useEffect(() => {
    // if ([USER_ROLES.ACCOUNTING, USER_ROLES.ACCOUNTING_ADMIN].includes(roleUserIsUsing)) {

    // }
    if (shouldLandOnAccountingDashboard(user)) {
      setSelectedDashboard("accounting");
    }
    const RussMartin = "Russ Martin";
    if (
      [USER_ROLES.STAFFING, USER_ROLES.STAFF].includes(roleUserIsUsing) ||
      user.first_name + " " + user.last_name === RussMartin
    ) {
      setSelectedDashboard("staffing");
    }

    if ([USER_ROLES.ACCOUNTING_PLUS].includes(user.role)) {
      setSelectedDashboard("default");
    }
    // Only set isLoading to false when user data AND other required data is loaded
    // Note: The component loading logic will handle showing the loading state until
    // all required data is available
    setTimeout(() => {
      if (user && Object.keys(user).length > 0) {
        setIsLoading(false);
      }
    }, 1000); // Add a slight delay to ensure smoother loading experience
  }, [roleUserIsUsing, user]);
  const Render = () => {
    if (isLoading) {
      return <LoadingVideo />;
    }

    if (hasAdminAccess) {
      switch (selectedDashboard) {
        case "accounting":
          return (
            <Suspense fallback={<LoadingVideo />}>
              <AccountingDashboard />
            </Suspense>
          );
        case "staffing":
          return (
            <Suspense fallback={<LoadingVideo />}>
              <StaffingDashboard />
            </Suspense>
          );
        case "wire":
          return (
            <Suspense fallback={<LoadingVideo />}>
              <WireDashboard />
            </Suspense>
          );
        default:
          return (
            <Suspense fallback={<LoadingVideo />}>
              <AdminDashboard />
            </Suspense>
          );
      }
    }

    if (canAccessAccountingDashboard(user)) {
      return (
        <Suspense fallback={<LoadingVideo />}>
          <AccountingDashboard />
        </Suspense>
      );
    }
    if ([USER_ROLES.STAFFING].includes(user.role)) {
      return (
        <Suspense fallback={<LoadingVideo />}>
          <StaffingDashboard />
        </Suspense>
      );
    }
    return (
      <Suspense fallback={<LoadingVideo />}>
        <AdminDashboard />
      </Suspense>
    );
  };

  const customSelectStyles = {
    control: (provided) => ({
      ...provided,
      backgroundColor: "rgb(30, 41, 59)",
      border: "1px solid rgb(51, 65, 85)",
      borderRadius: "0.5rem",
      padding: "2px 4px",
      boxShadow: "none",
      "&:hover": {
        borderColor: "rgb(100, 116, 139)",
      },
    }),
    menu: (provided) => ({
      ...provided,
      backgroundColor: "rgb(30, 41, 59)",
      borderRadius: "0.5rem",
      boxShadow:
        "0 10px 15px -3px rgba(0, 0, 0, 0.1), 0 4px 6px -2px rgba(0, 0, 0, 0.05)",
      border: "1px solid rgb(51, 65, 85)",
    }),
    option: (provided, state) => ({
      ...provided,
      backgroundColor: state.isSelected
        ? "rgb(51, 65, 85)"
        : state.isFocused
        ? "rgb(51, 65, 85, 0.5)"
        : undefined,
      "&:hover": {
        backgroundColor: "rgb(51, 65, 85, 0.5)",
      },
      color: "white",
      cursor: "pointer",
    }),
    singleValue: (provided) => ({
      ...provided,
      color: "white",
    }),
    dropdownIndicator: (provided) => ({
      ...provided,
      color: "rgb(148, 163, 184)",
      "&:hover": {
        color: "white",
      },
    }),
    indicatorSeparator: () => ({
      display: "none",
    }),
    placeholder: (provided) => ({
      ...provided,
      color: "rgb(148, 163, 184)",
    }),
  };

  const selectedOption = dashboardOptions.find(
    (option) => option.value === selectedDashboard
  );

  return (
    <div>
      <Header />
      <div className="bg-slate-800 border-b border-slate-700 mb-6 mt-2">
        <div className=" px-4 sm:px-6 py-3">
          <div className="flex flex-wrap justify-between items-center">
            <h1 className="text-2xl font-bold text-white">
              {selectedOption ? selectedOption.label : "Dashboard"}
            </h1>

            <div className="flex items-center gap-4">
              {hasAdminAccess && (
                <div className="relative w-64">
                  <div className="absolute left-3 top-1/2 transform -translate-y-1/2 text-slate-400 pointer-events-none">
                    <AcademicCapIcon className="h-5 w-5" />
                  </div>
                  <Select
                    options={dashboardOptions}
                    value={selectedOption}
                    onChange={(option) =>
                      setSelectedDashboard(option ? option.value : "admin")
                    }
                    styles={customSelectStyles}
                    placeholder="Switch Dashboard"
                    menuPortalTarget={document.body}
                    menuPosition="fixed"
                    components={{
                      DropdownIndicator: () => (
                        <div className="text-slate-400 mr-2">
                          <ChevronDownIcon className="h-5 w-5" />
                        </div>
                      ),
                    }}
                    isSearchable={false}
                  />
                </div>
              )}

              <div className="flex items-center px-4 py-2 bg-slate-700 rounded-lg">
                <span className="text-slate-300 text-sm font-medium mr-2">
                  Role:
                </span>
                <span className="text-white font-semibold">
                  {user.data?.id === 83
                    ? "Booker"
                    : getRoleDisplayName(user?.role_name) || "No Role Assigned"}
                </span>
              </div>
            </div>
          </div>
        </div>
      </div>

      <div>
        <Render />
      </div>
    </div>
  );
}
