import SettlementVerticalTable from "./SettlementVerticalTable";
import SupportArtistSupports from "./SettlementArtistSupports";
import SettlementArtistAdditionals from "./SettlementArtistAdditionals";
import DealAndFinalFigures from "./SettlementArtistDealSection";
import { TableRow } from "../AppTable2";
import { useSettlementComponent } from "@/hooks/useSettlementComponent";
import { amountDisplay } from "@/utils/money";
import SettlementTableTitle from "./SettlementTableTitle";
import { UserIcon } from "@heroicons/react/24/outline";

const SettlementArtistTheDeal: React.FC = () => {
  const { chargebacks, chargebacksTotal, rollups } = useSettlementComponent({
    enableExpenses: true,
  });
  return (
    <div>
      <div className="flex flex-col space-y-8">
        {/* Show Expenses Table */}
        <SettlementArtistAdditionals />
        <div className="w-full lg:w-[24%] bg-white">
          <SettlementTableTitle>PROMOTER PROFIT (LOSS)</SettlementTableTitle>
          <TableRow
            columns={[
              {
                key: "name",
                accessor: "name",
                textAlign: "left",
                editable: false,
              },
              {
                key: "value",
                accessor: "value",
                textAlign: "right",
                editable: false,
              },
            ]}
            rowData={{
              name: "PROFIT (LOSS)",
              value: amountDisplay(rollups.data?.profit_loss),
            }}
          />
          <TableRow
            columns={[
              {
                key: "name",
                accessor: "name",
                textAlign: "left",
                editable: false,
              },
              {
                key: "value",
                accessor: "value",
                textAlign: "right",
                editable: false,
              },
            ]}
            rowData={{
              name: "CHARGEBACKS",
              value: amountDisplay(chargebacksTotal),
            }}
          />
          <TableRow
            rowContainerClass="w-full bg-blue-200"
            columns={[
              {
                key: "name",
                accessor: "name",
                textAlign: "left",
              },
              {
                key: "value",
                accessor: "value",
                textAlign: "right",
              },
            ]}
            rowData={{
              name: "TOTAL P&L:",
              value: amountDisplay(
                chargebacksTotal + rollups.data?.profit_loss
              ),
            }}
          />
        </div>
        <SupportArtistSupports />
        <UserIcon className="h-6 w-6 text-gray-500 dark:text-gray-400 mt-4" />

      </div>
    </div>
  );
};

export default SettlementArtistTheDeal;
