import React, { useState, useEffect } from "react";

export const Carousel = ({
  children,
  three,
  className,
  style,
  controlsClassName,
}) => {
  const [currentIndex, setCurrentIndex] = useState(0);
  const [itemsPerPage, setItemsPerPage] = useState(three ? 3 : 7);

  useEffect(() => {
    const updateItemsPerPage = () => {
      const containerWidth = window.innerWidth - 48; // Account for padding
      const cardWidth = three ? 100 : 120; // Smaller width for all layouts
      const maxCards = Math.floor(containerWidth / cardWidth);

      if (window.innerWidth < 640) {
        setItemsPerPage(1);
      } else if (window.innerWidth < 1024) {
        setItemsPerPage(three ? 2 : Math.min(maxCards, 5));
      } else {
        setItemsPerPage(three ? 3 : Math.min(maxCards, 6));
      }
    };

    updateItemsPerPage();
    window.addEventListener("resize", updateItemsPerPage);
    return () => window.removeEventListener("resize", updateItemsPerPage);
  }, [three]);

  const goNext = () => {
    setCurrentIndex(
      (prevIndex) => (prevIndex + 1) % Math.ceil(children.length / itemsPerPage)
    );
  };

  const goPrev = () => {
    setCurrentIndex(
      (prevIndex) =>
        (prevIndex - 1 + Math.ceil(children.length / itemsPerPage)) %
        Math.ceil(children.length / itemsPerPage)
    );
  };

  const startIndex = currentIndex * itemsPerPage;
  const endIndex = startIndex + itemsPerPage;

  return (
    <div className={`w-full ${className || ""}`} style={style || {}}>
      {/* Always use the top-positioned controls for better layout */}
      {
        <div className="flex justify-between items-center mb-2">
          <div></div> {/* Empty div for spacing */}
          <div className="flex gap-2">
            <button
              onClick={goPrev}
              className="dark:text-gray-200 text-4xl dark:hover:text-gray-500 focus:outline-none"
            >
              &#10094;
            </button>
            <button
              onClick={goNext}
              className="dark:text-gray-200 text-4xl dark:hover:text-gray-500 focus:outline-none"
            >
              &#10095;
            </button>
          </div>
        </div>
      }
      <div
        className={`overflow-hidden flex flex-wrap ${
          three
            ? "justify-between"
            : itemsPerPage === 1
            ? "justify-center"
            : "justify-start"
        } -mx-1`}
      >
        {children &&
          typeof children === "object" &&
          children.slice(startIndex, endIndex).map((child, index) => (
            <div
              key={index}
              className={`${
                itemsPerPage === 1 ? "mx-auto" : three ? "px-0" : "px-0.5"
              } flex-grow-0 flex-shrink-0 ${
                three ? "basis-[32%]" : "basis-[120px]"
              }`}
            >
              {child}
            </div>
          ))}
      </div>
    </div>
  );
};
