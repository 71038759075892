import React, { useState, useEffect } from "react";
import { FormBuilder, Input } from "./Form";
import * as yup from "yup";
import { useMutation, useQueryClient } from "@tanstack/react-query";
import { useNavigate, useParams } from "react-router-dom";
import { Logo } from "@/components/Header/TopHeader/Logo";
import { passwordResetConfirm } from "@/queries/auth";

const PasswordReset = (props) => {
  const { uidb64, token } = useParams();
  const navigate = useNavigate();
  const queryClient = useQueryClient();
  const [error, setError] = useState(false);
  const [success, setSuccess] = useState(false);
  const [running, setRunning] = useState(false);

  const resetMutation = useMutation({
    mutationFn: (data) => {
      return passwordResetConfirm(data);
    },
    onMutate: (variables) => {
      setRunning(true);
    },
    onSuccess: (data, variables, context) => {
      setError(false);
      setRunning(false);
      setSuccess(true);
    },
    onError: (error, variables, context) => {
      setError(error.data.message);
      setRunning(false);
    },
  });

  const onSubmit = (data) => {
    setError(false);
    data["uidb64"] = uidb64;
    data["token"] = token;
    resetMutation.mutate(data);
  };

  const loginSchema = yup.object().shape({
    password1: yup.string().required(),
    password2: yup.string().required(),
  });

  let headerText = "Change Your Password";
  if (success) {
    headerText = "Your Password Has Been Reset";
  }

  return (
    <section className="bg-gray-300 dark:bg-[#181818]">
      <div className="flex flex-col items-center justify-center px-6 py-8 mx-auto min-h-screen lg:py-0">
        <div className="mb-6">
          <Logo />
        </div>
        <div className="w-full bg-cave-white rounded-lg shadow dark:border md:mt-0 sm:max-w-md xl:p-0 dark:bg-gray-800 dark:border-gray-700">
          <div className="p-6 space-y-4 md:space-y-6 sm:p-8">
            <h1 className="text-xl font-bold leading-tight tracking-tight text-cave-black md:text-2xl dark:text-cave-white">
              {headerText}
            </h1>
            {error && <p className="text-sm text-red-600">{error}</p>}
            {!success && (
              <FormBuilder onSubmit={onSubmit} schema={loginSchema}>
                <Input
                  name="password1"
                  type="password"
                  placeholder="Set New Password"
                  label="New Password"
                  autoFocus
                />
                <Input
                  name="password2"
                  type="password"
                  label="Confirm New Password"
                />
                <button
                  type="submit"
                  disabled={running}
                  className="mt-2 w-full text-cave-white bg-blue-600 hover:bg-blue-700 focus:ring-4 focus:outline-none focus:ring-blue-300 font-medium rounded-lg text-sm px-5 py-2.5 text-center dark:bg-blue-600 dark:hover:bg-blue-700 dark:focus:ring-blue-800"
                >
                  {running ? "Changing Password" : "Save New Password"}
                </button>
              </FormBuilder>
            )}
            {success && (
              <div className="my-2">
                <p className="my-2 mb-6">
                  Your password has been reset. Please login again.
                </p>
                <div className="w-full text-center">
                  <a
                    href="/login"
                    className="mt-2 w-full text-cave-white bg-blue-600 hover:bg-blue-700 focus:ring-4 focus:outline-none focus:ring-blue-300 font-medium rounded-lg text-sm px-5 py-2.5 text-center dark:bg-blue-600 dark:hover:bg-blue-700 dark:focus:ring-blue-800"
                  >
                    Return to Login
                  </a>
                </div>
              </div>
            )}
          </div>
        </div>
      </div>
    </section>
  );
};

export default PasswordReset;
