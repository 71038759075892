import React, { useState, useMemo, useEffect } from "react";
import { TableRow, Table } from "../AppTable2";
import SettlementVerticalTable from "./SettlementVerticalTable";
import SettlementFinalFigures from "./SettlementFinalFigures";
import SettlementTableTitle from "./SettlementTableTitle";
import { useSettlementComponent } from "@/hooks/useSettlementComponent";
import { amountDisplay } from "@/utils/money";
import { toast } from "react-toastify";

const baseFieldKeys = new Set([
  "__net_ticket_sales",
  "__txt_rebates",
  "__fac_fee",
  "__concession_revenue",
  "__merch_revenue",
  "__txtmaster_revenue",
  "__vip_revenue",
]);

const SECTION = { NAME: "the_deal" };

export interface DealRow {
  key: string;
  label: string;
  value: number | string;
  per_cap?: number | string;
  isEditable?: boolean;
  format?: "money";
}

export default function SettlementArtistDealSection({
  className,
  hideDeal,
}: {
  className?: string;
  hideDeal?: boolean;
}) {
  const {
    event,
    variables,
    settlementInputClassName,
    eventOverrides,
    createEventOverrideMutation,
    updateEventOverrideMutation,
    deleteEventOverrideMutation,
  } = useSettlementComponent();

  if (!variables.data || !event.data) return null;

  const isCustomField = (fieldName: string) => fieldName.startsWith("__");

  const handleOverride = async (
    key: string,
    value: any
  ): Promise<{ success: boolean }> => {
    if (!event?.data?.id || !key) return { success: false };

    let finalName = key;
    if (!isCustomField(key)) {
      const parts = key.split("__");
      if (parts.length > 1) {
        finalName = parts[1];
      }
    }
    const fieldName = `__${finalName}`;
    const resolvedValue = Number(value) || 0;
    try {
      const overrideData = {
        field_name: fieldName,
        value: resolvedValue.toString(),
        is_money: true,
        section_name: SECTION.NAME,
        sub_section_name: null,
        event: event.data.id,
      };
      const existingOverride = eventOverrides.data?.find(
        (o) => o.field_name === fieldName && o.section_name === SECTION.NAME
      );
      if (existingOverride) {
        await updateEventOverrideMutation.mutate({
          data: overrideData,
          overrideId: existingOverride.id,
        });
      } else {
        await createEventOverrideMutation.mutate(overrideData);
      }
      return { success: true };
    } catch (error) {
      console.error("Error handling override:", error);
      toast.error("Failed to save override");
      return { success: false };
    }
  };

  const manifestsTotalTicketPrice = event.data.manifests.reduce(
    (acc: number, manifest: any) =>
      acc + (manifest.price + manifest.ticket_fees),
    0
  );
  const amountOfManifests = event.data.manifests.length || 1;
  const averageTicketPrice = manifestsTotalTicketPrice / amountOfManifests;

  const getInitialRows = (): DealRow[] => {
    const baseRows: DealRow[] = [
      {
        key: "Guarantee",
        label: "Guarantee",
        value: variables.data?.the_deal_amount ?? 0,
        isEditable: true,
        format: "money",
      },
      {
        key: "DealType",
        label: "Deal Type",
        value: event.data?.offer_type ?? "N/A",
        isEditable: false,
      },
      {
        key: "PromProfit",
        label: "PROM PROFIT %",
        value: variables.data.promoter_profit ?? 0,
        isEditable: true,
      },
      {
        key: "ArtistShare",
        label: "ARTIST SHARE",
        value: variables.data.walkout_percentage ?? 0,
        isEditable: true,
      },
      {
        key: "Versus",
        label: "VERSUS %",
        value: 100 - (variables.data.walkout_percentage ?? 0),
        isEditable: true,
      },
      {
        key: "GuaranteePaidBy",
        label: "GUARANTEE PAID BY:",
        value: "MAMMOTH",
        isEditable: false,
      },
      {
        key: "CoHeadlinerSplit",
        label: "CO-HEADLINER SPLIT (if applicable)",
        value: "100%",
        isEditable: false,
      },
    ];

    const mergedBaseRows = baseRows.map((row) => {
      const override = eventOverrides.data?.find(
        (o) =>
          o.field_name === `__${row.key}` && o.section_name === SECTION.NAME
      );
      if (override) {
        return {
          ...row,
          value: isNaN(Number(override.value))
            ? override.value
            : Number(override.value),
          format: row.format,
        };
      }
      return row;
    });

    const baseKeys = new Set(baseRows.map((row) => `__${row.key}`));
    const customRows: DealRow[] =
      eventOverrides.data
        ?.filter(
          (o) => o.section_name === SECTION.NAME && !baseKeys.has(o.field_name)
        )
        .map((override) => ({
          key: override.field_name,
          label: override.field_name.startsWith("__")
            ? override.field_name.slice(2)
            : override.field_name,
          value: isNaN(Number(override.value))
            ? override.value
            : Number(override.value),
          isEditable: true,
          format: !isNaN(Number(override.value)) ? "money" : undefined,
        })) || [];
    return [...mergedBaseRows, ...customRows];
  };

  const [dealRows, setDealRows] = useState<DealRow[]>(getInitialRows());

  useEffect(() => {
    setDealRows(getInitialRows());
  }, [eventOverrides.data, event.data, variables.data]);

  const ticketInfoData = useMemo(() => {
    const percentSold = event.data.tickets_total
      ? ((event.data.tickets_sold / event.data.tickets_total) * 100).toFixed(
          2
        ) + "%"
      : "0%";
    return [
      {
        key: "PercentSold",
        label: "% SOLD",
        value: percentSold,
        isEditable: false,
      },
      {
        key: "AvgTxtPrice",
        label: "AVERAGE TXT PRICE",
        value: amountDisplay(averageTicketPrice),
        isEditable: false,
      },
      {
        key: "DropCount",
        label: "DROP COUNT",
        value: event.data.drop_count ?? 0,
        isEditable: false,
      },
    ];
  }, [event.data, averageTicketPrice]);

  /**
   * When a row's "value" field blurs, update the override.
   */
  const handleBlur = async (
    rowIndex: number,
    accessor: string,
    newValue: any,
    row: DealRow
  ) => {
    setDealRows((prev) =>
      prev.map((r, i) => (i === rowIndex ? { ...r, value: newValue } : r))
    );
    if (row.isEditable) {
      await handleOverride(row.key, newValue);
    }
  };

  /**
   * Handles adding a new custom row.
   * Expects formData with keys "label" and "value".
   */
  const handleAddRow = async (formData: any): Promise<{ success: boolean }> => {
    try {
      if (!formData.label) {
        toast.error("Field label is required");
        return { success: false };
      }
      const fieldName = formData.label;
      const exists = dealRows.some((row) => row.key === `__${fieldName}`);
      if (exists) {
        toast.error("A field with this name already exists");
        return { success: false };
      }
      const result = await handleOverride(fieldName, formData.value);
      if (!result.success) return { success: false };
      const newRow: DealRow = {
        key: `__${fieldName}`,
        label: fieldName,
        value: isNaN(Number(formData.value))
          ? formData.value
          : Number(formData.value),
        isEditable: true,
        format: !isNaN(Number(formData.value)) ? "money" : undefined,
      };
      setDealRows((prev) => [...prev, newRow]);
      toast.success("Field added successfully");
      return { success: true };
    } catch (error) {
      console.error("Error adding field:", error);
      toast.error("Failed to add field");
      return { success: false };
    }
  };

  const handleDeleteCustomRow = async (rowKey: string) => {
    const overrideRecord = eventOverrides.data?.find(
      (o) => o.field_name === rowKey && o.section_name === SECTION.NAME && true
    );
    if (!overrideRecord) {
      console.error("No override record found for", rowKey);
      return;
    }
    try {
      await deleteEventOverrideMutation.mutate(overrideRecord.id);
      setDealRows((prev) => prev.filter((row) => row.key !== rowKey));
      toast.success("Field deleted successfully");
    } catch (error) {
      console.error("Error deleting field:", error);
      toast.error("Failed to delete field");
    }
  };

  return (
    <div className={className}>
      <div className="grid grid-cols-1 lg:grid-cols-3 my-8 gap-4">
        {!hideDeal && (
          <div className="inline-flex flex-col">
            <SettlementTableTitle>THE DEAL</SettlementTableTitle>
            {dealRows.map((row, i) => (
              <TableRow
                key={row.key}
                columns={[
                  {
                    key: "label",
                    accessor: "label",
                    textAlign: "left",
                    editable: false,
                  },
                  {
                    key: "value",
                    accessor: "value",
                    textAlign: "right",
                    editable: row.isEditable,
                    format: row.format,
                  },
                ]}
                rowData={row}
                rowIndex={i}
                inputClassName={settlementInputClassName}
                onBlur={
                  row.isEditable
                    ? (rowIndex, accessor, value, rowData) =>
                        handleBlur(rowIndex, accessor, value, rowData)
                    : undefined
                }
                onDelete={
                  row.key.startsWith("__")
                    ? () => handleDeleteCustomRow(row.key)
                    : undefined
                }
                deleteIcons={row.key.startsWith("__")}
                deleteConfirmation="modal"
              />
            ))}
            <Table
              columns={[
                {
                  key: "label",
                  accessor: "label",
                  title: "LABEL",
                  textAlign: "left",
                  width: "60%",
                  addRowEditable: true,
                },
                {
                  key: "value",
                  accessor: "value",
                  title: "VALUE",
                  textAlign: "right",
                  format: "money",
                  addRowEditable: true,
                },
              ]}
              data={[]}
              enableAddRow
              addRowButtonTitle="Add Custom Field"
              showNoDataMessage={false}
              inputClassName={settlementInputClassName}
              onAddRow={handleAddRow}
              hideHeaders
            />
          </div>
        )}
        <SettlementVerticalTable
          title="Ticket Info"
          data={ticketInfoData}
          enableAddRow={false}
          onBlur={() => {}}
        />
        <SettlementFinalFigures />
      </div>
    </div>
  );
}
