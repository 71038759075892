import React from "react";

const LoadingVideo = () => {
  return (
    <div className="flex justify-center items-center h-64 w-full">
      <div className="w-64">
        <video autoPlay loop muted className="w-full">
          <source src="/loading-animation.mp4" type="video/mp4" />
          Your browser does not support the video tag.
        </video>
        <div className="text-center mt-4 text-slate-300">Loading data...</div>
      </div>
    </div>
  );
};

export default LoadingVideo;
