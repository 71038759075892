import React from "react";
import { useQuery } from "@tanstack/react-query";
import { useParams } from "react-router-dom";
import dayjs from "dayjs";
import { getEventPeople } from "@/queries/events";
import { LoadingPulse } from "@/components/shared/LoadingPulse";

export const GuestListPrint = () => {
  const { id: eventId } = useParams();

  const { data: guests, isLoading: guestsLoading } = useQuery(
    ["guestlist", eventId],
    () =>
      fetch(`/api/guestlist/event/${eventId}`, {
        credentials: "include",
        headers: {
          "Content-Type": "application/json",
          "X-CSRFToken": document.cookie.match(/csrftoken=([\w-]+)/)?.[1] || "",
        },
      }).then((res) => res.json())
  );

  const { data: event, isLoading: eventLoading } = useQuery(
    ["event-detail", eventId],
    () =>
      fetch(`/api/events/${eventId}/`, {
        credentials: "include",
        headers: {
          "Content-Type": "application/json",
          "X-CSRFToken": document.cookie.match(/csrftoken=([\w-]+)/)?.[1] || "",
        },
      }).then((res) => res.json())
  );

  const { data: people, isLoading: peopleLoading } = useQuery(
    ["event", "people", eventId],
    () => getEventPeople(eventId)
  );

  React.useEffect(() => {
    if (
      !guestsLoading &&
      !eventLoading &&
      !peopleLoading &&
      guests &&
      event &&
      people
    ) {
      window.print();
    }
  }, [guestsLoading, eventLoading, peopleLoading, guests, event, people]);

  if (guestsLoading || eventLoading || peopleLoading) {
    return <LoadingPulse />;
  }

  console.log("People data:", people); // Debug all people data

  const marketer = people?.find((person) => {
    // Check for both role_name and role properties
    const role = (person.role_name || person.role || "").toLowerCase();
    return role.includes("market"); // This will match any role containing 'market'
  });

  console.log("Found marketer:", marketer); // Debug found marketer

  return (
    <div className="p-8 print:p-0 print:border-12 border-x-2 print:border-black">
      <div className="flex flex-col items-center">
        <div className="w-full border-y-2 border-black">
          <h1 className="text-4xl font-extrabold mb-2 text-center py-2">
            MAMMOTH GUEST LIST
          </h1>
        </div>
        <div className="text-2xl font-bold text-gray-700 flex flex-col justify-center">
          <div className="mb-4 float-left">
            <span className="font-bold mr-2">SHOW:</span>
            {event?.name}
          </div>
          <div className="flex items-center">
            <span className="font-bold mr-2">DATE:</span>
            {dayjs(event?.date).format("MMMM D, YYYY")}
            {marketer && (
              <>
                <span className="mx-4">&nbsp;</span>
                <span className="font-bold mr-2">MARKETER:</span>
                {marketer.first_name} {marketer.last_name}
              </>
            )}
          </div>
        </div>
      </div>
      <table className="w-full border-collapse border-y-2 border-black border-t-0">
        <thead>
          <tr>
            <th className="text-left py-2 px-4 border-2 border-x-0 border-black">
              NAME
            </th>
            <th className="text-left py-2 px-4 border-2 border-black">
              # TICKETS
            </th>
            <th className="text-left py-2 px-4 border-2 border-black">
              AFFILIATION
            </th>
            <th className="text-left py-2 px-4 border-2 border-black">
              APPROVED BY
            </th>
            <th className="text-left py-2 px-4 border-2 border-black">
              # PASSES
            </th>
            <th className="text-left py-2 px-4 border-2 border-x-0 border-black">
              TYPE
            </th>
          </tr>
        </thead>
        <tbody>
          {guests?.map((guest) => (
            <tr key={guest.id}>
              <td className="py-2 px-4 border border-x-0 border-black">
                {guest.name}
              </td>
              <td className="py-2 px-4 border border-black">
                {guest.num_tickets}
              </td>
              <td className="py-2 px-4 border border-black">
                {guest.affiliation}
              </td>
              <td className="py-2 px-4 border border-black">
                {guest.approved_by_name}
              </td>
              <td className="py-2 px-4 border border-black">
                {guest.num_passes}
              </td>
              <td className="py-2 px-4 border border-x-0 border-black">
                {guest.pass_type}
              </td>
            </tr>
          ))}
        </tbody>
      </table>
    </div>
  );
};

export default GuestListPrint;
