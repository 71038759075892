import React, { useState, useEffect, useCallback } from "react";
import LoadingPulse from "@/components/shared/LoadingPulse";
import { Header } from "@/components/Header";
import { BreadCrumbs } from "@/components/BreadCrumbs";
import { useQuery, useMutation, useQueryClient } from "@tanstack/react-query";
import {
  Card,
  Button,
  Label,
  TextInput,
  Alert,
  Modal,
  Pagination,
  Table,
} from "flowbite-react";
import {
  fetchSeatingCharts,
  createSeatingChart,
  updateSeatingChart,
  deleteSeatingChart,
  getVenues,
  fetchSeatingSections,
  createSeatingSection,
  updateSeatingSection,
  deleteSeatingSection,
  getVenuesSettings,
} from "@/queries/venues.js";
import { useUser, usePerms } from "@/components/Auth";

export const SeatingChartManager = ({ perms, user }) => {
  const [openSections, setOpenSections] = useState({});
  const [formData, setFormData] = useState({
    name: "",
    description: "",
    venue: "",
  });
  const [sectionFormData, setSectionFormData] = useState({
    name: "",
    qty: "",
    section_type: "",
    description: "",
  });
  const [error, setError] = useState("");
  const [isModalOpen, setIsModalOpen] = useState(false);
  const [isSectionModalOpen, setIsSectionModalOpen] = useState(false);
  const [currentPage, setCurrentPage] = useState(1);
  const [editingChart, setEditingChart] = useState(null);
  const [editingSection, setEditingSection] = useState(null);
  const [venueSearch, setVenueSearch] = useState("");
  const [filteredVenues, setFilteredVenues] = useState([]);
  const [selectedChart, setSelectedChart] = useState(null);
  const itemsPerPage = 100;

  const queryClient = useQueryClient();

  const toggleSection = (chartId) => {
    setOpenSections((prev) => ({
      ...prev,
      [chartId]: !prev[chartId],
    }));
  };

  //  const { data: allSeatingCharts, isLoading: charts.isLoading, isError: charts.isError } = useQuery({
  //    queryKey: ['seatingCharts'],
  //    queryFn: fetchSeatingCharts,
  //  });

  const charts = useQuery(
    ["seatingCharts"],
    () => fetchSeatingCharts(),
    getVenuesSettings
  );

  const venues = useQuery(["venues"], () => getVenues(), getVenuesSettings);

  const getVenueName = (venueId) => {
    const venue = venues.data.find((v) => v.id === venueId);
    if (venue) {
      return `${venue.name}, ${venue.city}, ${venue.state}`;
    } else {
      console.log(`Venue not found for id: ${venueId}`);
      return "Unknown";
    }
  };
  const {
    data: allSections,
    isLoading: sectionsLoading,
    isError: sectionsError,
  } = useQuery({
    queryKey: ["sections"],
    queryFn: fetchSeatingSections,
  });

  const createMutation = useMutation({
    mutationFn: createSeatingChart,
    onSuccess: handleMutationSuccess,
    onError: handleMutationError,
  });

  const updateMutation = useMutation({
    mutationFn: ({ id, data }) => updateSeatingChart(id, data),
    onSuccess: () => {
      queryClient.invalidateQueries(["seatingCharts"]);
      handleMutationSuccess();
    },
    onError: handleMutationError,
  });

  const deleteMutation = useMutation({
    mutationFn: deleteSeatingChart,
    onSuccess: handleMutationSuccess,
    onError: handleMutationError,
  });

  const createSectionMutation = useMutation({
    mutationFn: createSeatingSection,
    onSuccess: () => {
      queryClient.invalidateQueries(["sections"]);
      handleSectionMutationSuccess();
    },
    onError: handleMutationError,
  });

  const updateSectionMutation = useMutation({
    mutationFn: ({ id, data }) => updateSeatingSection(id, data),
    onSuccess: () => {
      queryClient.invalidateQueries(["sections"]);
      handleSectionMutationSuccess();
    },
    onError: handleMutationError,
  });

  const deleteSectionMutation = useMutation({
    mutationFn: deleteSeatingSection,
    onSuccess: () => {
      queryClient.invalidateQueries(["sections"]);
    },
    onError: handleMutationError,
  });

  function handleMutationSuccess() {
    queryClient.invalidateQueries(["seatingCharts"]);
    setFormData({ name: "", description: "", venue: "" });
    setError("");
    setIsModalOpen(false);
    setEditingChart(null);
    setVenueSearch("");
  }

  function handleSectionMutationSuccess() {
    setSectionFormData({
      name: "",
      qty: "",
      section_type: "",
      description: "",
    });
    setError("");
    setIsSectionModalOpen(false);
    setEditingSection(null);
  }

  function handleMutationError(error) {
    setError(
      error.message || "An error occurred while performing the operation."
    );
  }

  const handleInputChange = (e) => {
    const { name, value } = e.target;
    setFormData((prev) => ({ ...prev, [name]: value }));
  };

  const handleSectionInputChange = (e) => {
    const { name, value } = e.target;
    setSectionFormData((prev) => ({ ...prev, [name]: value }));
  };

  const handleVenueSearch = useCallback(
    (e) => {
      const searchTerm = e.target.value.toLowerCase();
      setVenueSearch(searchTerm);

      if (searchTerm.length > 0 && venues.data) {
        const filtered = venues.data.filter(
          (venue) =>
            venue.name.toLowerCase().includes(searchTerm) ||
            venue.city.toLowerCase().includes(searchTerm) ||
            venue.state.toLowerCase().includes(searchTerm)
        );
        setFilteredVenues(filtered);
      } else {
        setFilteredVenues([]);
      }
    },
    [venues.data]
  );

  const handleVenueSelect = useCallback((venue) => {
    setFormData((prev) => ({ ...prev, venue: venue.id }));
    setVenueSearch(`${venue.name}, ${venue.city}, ${venue.state}`);
    setFilteredVenues([]);
  }, []);

  const handleSubmit = (e) => {
    e.preventDefault();
    if (!formData.name || !formData.venue) {
      setError("Name and Venue ID are required.");
      return;
    }
    const payload = { ...formData, venue: parseInt(formData.venue) };
    if (editingChart) {
      updateMutation.mutate({ id: editingChart.id, data: payload });
    } else {
      createMutation.mutate(payload);
    }
  };

  const handleSectionSubmit = useCallback(
    (e) => {
      e.preventDefault();
      if (!sectionFormData.name || !sectionFormData.qty || !selectedChart) {
        setError("Name, Quantity, and Chart are required.");
        return;
      }
      const payload = {
        name: sectionFormData.name,
        qty: parseInt(sectionFormData.qty),
        section_type: sectionFormData.section_type || null,
        description: sectionFormData.description || null,
        chart: selectedChart, // Make sure this line is present and selectedChart is set correctly
      };

      if (editingSection) {
        updateSectionMutation.mutate({ id: editingSection.id, data: payload });
      } else {
        createSectionMutation.mutate(payload);
      }
    },
    [
      sectionFormData,
      selectedChart,
      editingSection,
      createSectionMutation,
      updateSectionMutation,
    ]
  );

  const handleEdit = (chart) => {
    setEditingChart(chart);
    setFormData({
      name: chart.name,
      description: chart.description || "",
      venue: chart.venue,
    });
    setIsModalOpen(true);
  };

  const handleDelete = (id) => {
    if (window.confirm("Are you sure you want to delete this seating chart?")) {
      deleteMutation.mutate(id);
    }
  };

  const handleEditSection = (section) => {
    setEditingSection(section);
    setSectionFormData({
      name: section.name,
      qty: section.qty.toString(),
      section_type: section.section_type || "",
      description: section.description || "",
    });
    setIsSectionModalOpen(true);
  };

  const handleDeleteSection = (id) => {
    if (window.confirm("Are you sure you want to delete this section?")) {
      deleteSectionMutation.mutate(id);
    }
  };

  if (charts.isLoading || venues.isLoading) return <LoadingPulse />;
  if (charts.isError || venues.isError) return <div>Error fetching data</div>;

  const totalPages = Math.ceil(charts.data.length / itemsPerPage);
  const seatingCharts = charts.data.slice(
    (currentPage - 1) * itemsPerPage,
    currentPage * itemsPerPage
  );
  return (
    <>
      <Header />
      <div
        className="
        flex flex-col px-6 py-8 mx-auto min-h-screen lg:py-0"
      >
        <div className="w-full flex justify-between items-center flex-col sm:flex-row mb-[.5rem] sm:mb-0">
          <BreadCrumbs links={[{ text: "Venues", url: "/venues" }]} />
        </div>

        <div className="p-4 bg-cave-white dark:bg-cave-black text-cave-black dark:text-cave-white min-h-screen">
          <h1 className="text-3xl font-bold mb-6">Seating Chart Manager</h1>

          <Button
            onClick={() => {
              setEditingChart(null);
              setIsModalOpen(true);
              setVenueSearch("");
            }}
            className="mb-6 bg-blue-600 hover:bg-blue-700 text-cave-white"
          >
            Add New Seating Chart
          </Button>

          <Modal
            show={isModalOpen}
            onClose={() => {
              setIsModalOpen(false);
              setEditingChart(null);
              setVenueSearch("");
            }}
          >
            <Modal.Header>
              {editingChart ? "Edit Seating Chart" : "Create New Seating Chart"}
            </Modal.Header>
            <Modal.Body>
              <form onSubmit={handleSubmit} className="flex flex-col gap-4">
                <div>
                  <Label htmlFor="name" value="Seating Chart Name" />
                  <TextInput
                    id="name"
                    name="name"
                    type="text"
                    value={formData.name}
                    onChange={handleInputChange}
                    placeholder="Enter seating chart name"
                    required
                  />
                </div>
                <div>
                  <Label htmlFor="description" value="Description (optional)" />
                  <TextInput
                    id="description"
                    name="description"
                    type="text"
                    value={formData.description}
                    onChange={handleInputChange}
                    placeholder="Enter description"
                  />
                </div>
                <div>
                  <Label htmlFor="venue_search" value="Venue" />
                  <TextInput
                    id="venue_search"
                    name="venue_search"
                    type="text"
                    value={venueSearch}
                    onChange={handleVenueSearch}
                    placeholder="Search for a venue"
                    required
                  />
                  {filteredVenues.length > 0 && (
                    <ul className="mt-2 border border-gray-300 rounded-md max-h-40 overflow-y-auto">
                      {filteredVenues.map((venue) => (
                        <li
                          key={venue.id}
                          className="p-2 hover:bg-gray-100 cursor-pointer"
                          onClick={() => handleVenueSelect(venue)}
                        >
                          {`${venue.name}, ${venue.city}, ${venue.state}`}
                        </li>
                      ))}
                    </ul>
                  )}
                </div>

                <Button
                  type="submit"
                  className="bg-blue-500 hover:bg-blue-600 text-cave-white"
                >
                  {editingChart
                    ? "Update Seating Chart"
                    : "Create Seating Chart"}
                </Button>
              </form>
            </Modal.Body>
          </Modal>
          <Modal
            show={isSectionModalOpen}
            onClose={() => {
              setIsSectionModalOpen(false);
              setEditingSection(null);
            }}
          >
            <Modal.Header>
              {editingSection ? "Edit Section" : "Create New Section"}
            </Modal.Header>
            <Modal.Body>
              <form
                onSubmit={handleSectionSubmit}
                className="flex flex-col gap-4"
              >
                <div>
                  <Label htmlFor="section_name" value="Section Name" />
                  <TextInput
                    id="section_name"
                    name="name"
                    type="text"
                    value={sectionFormData.name}
                    onChange={handleSectionInputChange}
                    placeholder="Enter section name"
                    required
                  />
                </div>
                <div>
                  <Label htmlFor="section_qty" value="Quantity" />
                  <TextInput
                    id="section_qty"
                    name="qty"
                    type="number"
                    value={sectionFormData.qty}
                    onChange={handleSectionInputChange}
                    placeholder="Enter quantity"
                    required
                  />
                </div>
                <div>
                  <Label htmlFor="section_type" value="Section Type" />
                  <TextInput
                    id="section_type"
                    name="section_type"
                    type="text"
                    value={sectionFormData.section_type}
                    onChange={handleSectionInputChange}
                    placeholder="Enter section type"
                  />
                </div>
                <div>
                  <Label
                    htmlFor="section_description"
                    value="Description (optional)"
                  />
                  <TextInput
                    id="section_description"
                    name="description"
                    type="text"
                    value={sectionFormData.description}
                    onChange={handleSectionInputChange}
                    placeholder="Enter description"
                  />
                </div>
                <Button
                  type="submit"
                  className="bg-blue-500 hover:bg-blue-600 text-cave-white"
                  disabled={
                    createSectionMutation.isLoading ||
                    updateSectionMutation.isLoading
                  }
                >
                  {editingSection ? "Update Section" : "Create Section"}
                </Button>
              </form>
            </Modal.Body>
          </Modal>

          {error && (
            <Alert color="failure" className="mb-4">
              {error}
            </Alert>
          )}

          <div className="grid grid-cols-1 md:grid-cols-2 gap-6">
            {seatingCharts.map((chart) => (
              <div
                key={chart.id}
                className="relative bg-cave-white dark:bg-gray-800 border border-gray-200 dark:border-gray-700 rounded-lg shadow-lg overflow-hidden"
              >
                <div className="p-4">
                  <h5 className="text-2xl font-bold tracking-tight text-cave-black dark:text-cave-white mb-2">
                    {chart.name}
                  </h5>
                  <p className="text-gray-700 dark:text-gray-400 mb-2">
                    {chart.description}
                  </p>
                  <p className="text-gray-700 dark:text-gray-400 mb-4">
                    Venue: {getVenueName(chart.venue)}
                  </p>
                  <div className="flex gap-2 mb-4">
                    <Button
                      color="info"
                      onClick={() => handleEdit(chart)}
                      className="bg-blue-600 hover:bg-blue-700 text-cave-white"
                    >
                      Edit
                    </Button>
                    <Button
                      color="failure"
                      onClick={() => handleDelete(chart.id)}
                      className="bg-red-600 hover:bg-red-700"
                    >
                      Delete
                    </Button>
                    <Button
                      color="success"
                      onClick={() => {
                        setSelectedChart(chart.id);
                        setIsSectionModalOpen(true);
                      }}
                      className="bg-green-600 hover:bg-green-700"
                    >
                      Add Section
                    </Button>
                  </div>
                  <Button
                    onClick={() => toggleSection(chart.id)}
                    className="w-full bg-gray-200 dark:bg-gray-700 text-gray-800 dark:text-cave-white hover:bg-gray-300 dark:hover:bg-gray-600"
                  >
                    {openSections[chart.id] ? "Hide Sections" : "Show Sections"}
                  </Button>
                </div>
                {openSections[chart.id] && (
                  <div className="border-t border-gray-200 dark:border-gray-700">
                    <div className="p-4 bg-gray-50 dark:bg-cave-black">
                      <h6 className="text-lg font-semibold mb-4 text-cave-black dark:text-cave-white">
                        Sections
                      </h6>
                      {sectionsLoading ? (
                        <LoadingPulse />
                      ) : sectionsError ? (
                        <p className="text-red-600 dark:text-red-400">
                          Error loading sections
                        </p>
                      ) : allSections &&
                        allSections.filter(
                          (section) => section.chart === chart.id
                        ).length > 0 ? (
                        <div className="overflow-x-auto">
                          <table className="w-full text-sm text-left text-gray-500 dark:text-gray-400">
                            <thead className="text-xs text-gray-700 uppercase bg-gray-100 dark:bg-gray-700 dark:text-gray-400">
                              <tr>
                                <th scope="col" className="px-6 py-3">
                                  Name
                                </th>
                                <th scope="col" className="px-6 py-3">
                                  Quantity
                                </th>
                                <th scope="col" className="px-6 py-3">
                                  Type
                                </th>
                                <th scope="col" className="px-6 py-3">
                                  Actions
                                </th>
                              </tr>
                            </thead>
                            <tbody>
                              {allSections
                                .filter((section) => section.chart === chart.id)
                                .map((section) => (
                                  <tr
                                    key={section.id}
                                    className="bg-cave-white dark:bg-gray-800 border-b dark:border-gray-700"
                                  >
                                    <td className="px-6 py-4 font-medium text-cave-black dark:text-cave-white whitespace-nowrap">
                                      {section.name}
                                    </td>
                                    <td className="px-6 py-4">{section.qty}</td>
                                    <td className="px-6 py-4">
                                      {section.section_type || "N/A"}
                                    </td>
                                    <td className="px-6 py-4">
                                      <button
                                        onClick={() =>
                                          handleEditSection(section)
                                        }
                                        className="font-medium text-blue-600 dark:text-blue-500 hover:underline mr-2"
                                      >
                                        Edit
                                      </button>
                                      <button
                                        onClick={() =>
                                          handleDeleteSection(section.id)
                                        }
                                        className="font-medium text-red-600 dark:text-red-500 hover:underline"
                                      >
                                        Delete
                                      </button>
                                    </td>
                                  </tr>
                                ))}
                            </tbody>
                          </table>
                        </div>
                      ) : (
                        <p className="text-gray-700 dark:text-gray-400">
                          No sections found for this seating chart.
                        </p>
                      )}
                    </div>
                  </div>
                )}
              </div>
            ))}
          </div>
        </div>
      </div>
    </>
  );
};

export default SeatingChartManager;
