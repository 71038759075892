import { useQuery, useQueryClient } from "@tanstack/react-query";
import { AgGridReact } from "ag-grid-react";
import { useState, useEffect, useMemo, useCallback } from "react";
import { useParams } from "react-router-dom";

import { selectValueToOption } from "@/components/Form";
import { offerEditableBgClass, offerReadOnlyBgClass } from "@/constants/offer";
import { getEvent } from "@/queries/events";
import { getVenues } from "@/queries/venues";
import { useEventOfferStore } from "@/store/eventOfferStore";

export const FIELD_UPDATE_ANIMATION_TIME = 2000;

const VenueInfoAgGrid = ({
  eventId,
  venue: initialVenue,
  venueOptions,
  EVENT_REQUIRED_PARAMS,
}) => {
  const { id } = useParams();
  const queryClient = useQueryClient();

  // Get event data from React Query for initial load
  const eventQuery = useQuery(
    ["event-detail", id],
    () => getEvent(id, { cache: false }),
    {
      refetchOnWindowFocus: false,
      notifyOnChangeProps: "tracked",
      staleTime: 30000,
    }
  );

  // Get venues from React Query
  const venuesQuery = useQuery(["venues"], getVenues, {
    refetchOnMount: false,
    staleTime: Infinity,
  });

  const event = eventQuery.data;
  const [rowData, setRowData] = useState([]);
  const [currentVenue, setCurrentVenue] = useState(initialVenue);

  // Flash animation state with timestamps instead of just field names
  const [flashingFields, setFlashingFields] = useState({
    // field: endTimestamp
  });

  const {
    eventOfferForm,
    setLocalField,
    patchEventField,
    isSaving,
    isFieldPending,
    initializeEventOfferForm,
    modifiedFields,
    isFieldModified,
  } = useEventOfferStore();

  useEffect(() => {
    if (event && !eventOfferForm.venue_id) {
      initializeEventOfferForm({
        venue_id: event.venue.id,
      });
      setLocalField("venue", event.venue);
      // setCurrentVenue(event.venue);
    }
  }, [event]);

  const VENUE_OPTIONS = useMemo(() => {
    if (!venuesQuery.data) return [];
    return venuesQuery.data.map((v) => ({ value: v.name, label: v.name }));
  }, [venuesQuery.data]);

  const getHeaderCellClass = useCallback((params) => {
    return params.data.editHeader ? offerEditableBgClass : offerReadOnlyBgClass;
  }, []);

  // New getCellClass function that handles the glow effect
  const getCellClass = useCallback(
    (params) => {
      const base = params.data.editValue
        ? offerEditableBgClass
        : offerReadOnlyBgClass;

      // Apply glow-text class if the field is currently flashing
      return isFieldModified("venue_id") ? `glow-text ${base}` : base;
    },
    [modifiedFields]
  );

  const cellEditorSelector = useCallback((params) => {
    if (params.data.format === "select") {
      return {
        component: "agSelectCellEditor",
        params: {
          values: params.data.options?.map((o) => o.value),
        },
      };
    }
    return null;
  }, []);

  const valueFormatter = useCallback((params) => params.value, []);
  const cellRenderer = useCallback((params) => <div>{params.value}</div>, []);

  const onCellValueChanged = useCallback(async (params) => {
    if (params.data.editable === false) return;
    if (params.data.accessor === "venue_name") {
      // Update local state first
      // setLocalField("venue_name", params.newValue);
      // Then send to API with a slight delay to prevent rapid updates
      // setTimeout(() => {
      //   handleUpdateVenueDetails("venue_name", params.newValue);
      // }, 300);
      const selectedVenue = venuesQuery.data?.find(
        (v) => v.name === params.newValue
      );
      if (selectedVenue) {
        const newItem = await patchEventField(
          eventId,
          "venue_id",
          selectedVenue.id
        );
        if (newItem.venue) {
          setLocalField("venue", newItem.venue);
        }
      }
    }
  }, []);

  useEffect(() => {
    if (isFieldPending("venue_id")) return;
    // Get the name to display, prioritize the current venue's name
    const venueName = eventOfferForm.venue.name || "";
    const nameOption = selectValueToOption(venueName, venueOptions);

    // Check if venue_name is currently flashing
    const now = Date.now();
    const isVenueNameFlashing =
      flashingFields["venue_name"] && flashingFields["venue_name"] > now;

    setRowData([
      {
        header: "NAME",
        value: nameOption?.value ?? venueName,
        format: "select",
        options: VENUE_OPTIONS,
        accessor: "venue_name",
        field: "venue_name",
        editable: true,
        editValue: true,
        // Check if field is currently flashing based on timestamps
        isFlashing: isVenueNameFlashing,
      },
      {
        header: "ADDRESS",
        value: eventOfferForm.venue.address || "",
        editable: false,
        accessor: "address",
        isFlashing: false,
      },
      {
        header: "CITY",
        value: eventOfferForm.venue.city || "",
        editable: false,
        accessor: "city",
        isFlashing: false,
      },
      {
        header: "STATE",
        value: eventOfferForm.venue.state || "",
        editable: false,
        accessor: "state",
        isFlashing: false,
      },
      {
        header: "ZIPCODE",
        value: eventOfferForm.venue.zipcode || "",
        editable: false,
        accessor: "zipcode",
        isFlashing: false,
      },
      {
        header: "PHONE",
        value: eventOfferForm.venue.phone || "",
        editable: false,
        accessor: "phone",
        isFlashing: false,
      },
      {
        header: "URL",
        value: eventOfferForm.venue.url || "",
        editable: false,
        accessor: "url",
        isFlashing: false,
      },
    ]);
  }, [eventOfferForm.venue]);

  const columnDefs = useMemo(
    () => [
      {
        field: "header",
        flex: 1,
        width: 150,
        editable: false,
        cellClass: getHeaderCellClass,
      },
      {
        field: "value",
        flex: 3,
        editable: (params) => params.data.editable !== false,
        cellClass: getCellClass, // Use the new cell class function
        onCellValueChanged,
        cellEditorSelector,
        valueFormatter,
        cellRenderer,
      },
    ],
    [
      getHeaderCellClass,
      getCellClass,
      onCellValueChanged,
      cellEditorSelector,
      valueFormatter,
      cellRenderer,
    ]
  );

  const defaultColDef = useMemo(
    () => ({
      resizable: true,
      sortable: false,
      filter: false,
    }),
    []
  );

  // if (!event) return <div>Loading...</div>;

  return (
    <div className="w-full h-full inline-flex flex-col self-start">
      <div className="bg-gray-300 text-base text-center py-1 px-2 font-montserrat">
        VENUE INFORMATION
      </div>
      <div className="ag-theme-alpine" style={{ width: "100%" }}>
        <AgGridReact
          rowData={rowData}
          columnDefs={columnDefs}
          defaultColDef={defaultColDef}
          suppressPropertyNamesCheck
          refreshCells={false}
          suppressCellFlash
          animateRows={false}
          suppressChangeDetection
          suppressClickEdit={false}
          singleClickEdit
          stopEditingWhenCellsLoseFocus
          loading={!event || !venuesQuery.data}
          suppressRowTransform
          domLayout="normal"
          headerHeight={0}
          getRowId={(params) => params.data.header}
          immutableData
          // enableCellChangeFlash={false}
          rowHeight={35}
          suppressScrollOnNewData
          suppressHorizontalScroll
        />
      </div>
      <div className="mt-2 w-full text-center">
        {!eventOfferForm.venue?.capacity && (
          <span className="bg-red-500 text-white px-2 py-1 rounded mr-2">
            no CAPACITY defined for venue{" "}
            <a
              target="_blank"
              href={`/venues/edit/${eventOfferForm.venue.id}`}
              className="underline"
              rel="noreferrer"
            >
              edit here
            </a>
          </span>
        )}
        {!eventOfferForm.venue?.sales_tax && (
          <span className="bg-red-500 text-white px-2 py-1 rounded">
            no SALES TAX defined for venue{" "}
            <a
              target="_blank"
              href={`/venues/edit/${eventOfferForm.venue.id}`}
              className="underline"
              rel="noreferrer"
            >
              edit here
            </a>
          </span>
        )}
      </div>
    </div>
  );
};

export default VenueInfoAgGrid;
