import React, { useMemo, useState } from "react";
import { Table, TableRow } from "../AppTable2";
import SettlementVerticalTable from "./SettlementVerticalTable";
import SettlementTableTitle from "./SettlementTableTitle";
import { useSettlementComponent } from "@/hooks/useSettlementComponent";
import { useOverrides } from "@/hooks/useOverrides";
import { amountDisplay } from "@/utils/money";
import { toast } from "react-toastify";

// Utility: Normalize field names to lowercase with underscores.
const normalizeFieldName = (name: string) =>
  name.trim().toLowerCase().replace(/\s+/g, "_");

function getExpensesTotal(expenses: any[]): number {
  return expenses.reduce((acc, e) => acc + Number(e.amount || 0), 0);
}

const MAIN_SECTIONS = {
  PROMOTER_REVENUES: "promoter_revenues",
  SHOW_PL: "show_pl",
} as const;

const SECTION = { NAME: "the_deal" };

const baseFieldKeys = new Set([
  "__net_ticket_sales",
  "__txt_rebates",
  "__fac_fee",
  "__concession_revenue",
  "__merch_revenue",
  "__txtmaster_revenue",
  "__vip_revenue",
]);

export interface DealRow {
  key: string;
  revenue: string;
  total: number | string;
  per_cap?: number | string;
  editable?: boolean;
  format?: "money";
}

export default function SettlementRevenuesPL() {
  const {
    event,
    pageIndex,
    settlementInputClassName,
    expensesQuery,
    rollups,
    eventSettlement,
    variables,
    rebatesTotal,
  } = useSettlementComponent({ enableExpenses: true });

  const {
    loading,
    eventData,
    overrides,
    getOverrideFieldValue,
    handleOverride,
    deleteOverride,
  } = useOverrides(event?.data?.id?.toString() || "");

  if (!eventData || !rollups || !eventSettlement) return <div>Loading...</div>;

  const headers = [
    {
      title: "REVENUES",
      accessor: "revenue",
      key: "revenues",
      addRowEditable: true,
      addRowPlaceholder: "Revenue Title",
      textAlign: "left",
    },
    {
      title: "TOTAL",
      accessor: "value",
      editable: (row: any) => row.key !== "__net_ticket_sales",
      format: "money",
      addRowPlaceholder: "Revenue Total",
      textAlign: "right",
    },
    {
      title: "PER CAP",
      accessor: "per_cap",
      addRowEditable: true,
      addRowPlaceholder: "Revenue Per Cap",
      textAlign: "right",
    },
  ];

  const netTicketSales = useMemo(() => {
    return (
      eventData.manifests.reduce(
        (acc: number, curr: any) =>
          acc + (curr.price + curr.ticket_fees) * curr.paid,
        0
      ) || 0
    );
  }, [eventData.manifests]);

  // Build base rows for promoter revenues.
  // IMPORTANT: When using getOverrideFieldValue we extract the primitive from the returned object (e.g. .total)
  const basePromoterRows: DealRow[] = [
    {
      revenue: "NET TICKET SALES",
      key: "__net_ticket_sales",
      total: netTicketSales,
      per_cap: getOverrideFieldValue({
        key: "net_ticket_sales_per_cap",
        mainSection: MAIN_SECTIONS.PROMOTER_REVENUES,
        fallback: 0,
      }).total,
      editable: false,
      format: "money",
    },
    {
      revenue: `${pageIndex === "final" ? "MAMMOTH " : ""}TXT REBATES`,
      key: "__txt_rebates",
      total:
        getOverrideFieldValue({
          key: "txt_rebates",
          mainSection: MAIN_SECTIONS.PROMOTER_REVENUES,
          fallback: variables.data?.txt_rebates || "",
        }).total || rebatesTotal,
      per_cap: getOverrideFieldValue({
        key: "txt_rebates_per_cap",
        mainSection: MAIN_SECTIONS.PROMOTER_REVENUES,
        fallback: "",
      }).total,
      editable: true,
      format: "money",
    },
    {
      revenue: "FAC FEE",
      key: "__fac_fee",
      total:
        getOverrideFieldValue({
          key: "facility_fee",
          mainSection: MAIN_SECTIONS.PROMOTER_REVENUES,
          fallback: eventData.facility_fee || 0,
        }).total ||
        eventData.facility_fee ||
        0,
      per_cap: getOverrideFieldValue({
        key: "facility_fee_per_cap",
        mainSection: MAIN_SECTIONS.PROMOTER_REVENUES,
        fallback: "",
      }).total,
      editable: true,
      format: "money",
    },
    {
      revenue: "CONCESSION REVENUE",
      key: "__concession_revenue",
      total: getOverrideFieldValue({
        key: "concession_revenue",
        mainSection: MAIN_SECTIONS.PROMOTER_REVENUES,
        fallback: "",
      }).total,
      per_cap: getOverrideFieldValue({
        key: "concession_revenue_per_cap",
        mainSection: MAIN_SECTIONS.PROMOTER_REVENUES,
        fallback: "",
      }).total,
      editable: true,
      format: "money",
    },
    {
      revenue: "MERCH REVENUE",
      key: "__merch_revenue",
      total: getOverrideFieldValue({
        key: "merch_revenue",
        mainSection: MAIN_SECTIONS.PROMOTER_REVENUES,
        fallback: "",
      }).total,
      per_cap: getOverrideFieldValue({
        key: "merch_revenue_per_cap",
        mainSection: MAIN_SECTIONS.PROMOTER_REVENUES,
        fallback: "",
      }).total,
      editable: true,
      format: "money",
    },
    {
      revenue: "TXTMASTER REVENUE",
      key: "__txtmaster_revenue",
      total: getOverrideFieldValue({
        key: "txtmaster_revenue",
        mainSection: MAIN_SECTIONS.PROMOTER_REVENUES,
        fallback: "",
      }).total,
      per_cap: getOverrideFieldValue({
        key: "txtmaster_revenue_per_cap",
        mainSection: MAIN_SECTIONS.PROMOTER_REVENUES,
        fallback: "",
      }).total,
      editable: true,
      format: "money",
    },
    {
      revenue: "VIP",
      key: "__vip_revenue",
      total: getOverrideFieldValue({
        key: "vip_revenue",
        mainSection: MAIN_SECTIONS.PROMOTER_REVENUES,
        fallback: "",
      }).total,
      per_cap: getOverrideFieldValue({
        key: "vip_revenue_per_cap",
        mainSection: MAIN_SECTIONS.PROMOTER_REVENUES,
        fallback: "",
      }).total,
      editable: true,
      format: "money",
    },
  ];

  // Merge in any override for base rows.
  function mergeRowWithOverride(row: DealRow): DealRow {
    const override = overrides.find(
      (o) =>
        normalizeFieldName(o.field_name) === normalizeFieldName(row.key) &&
        o.section_name === MAIN_SECTIONS.PROMOTER_REVENUES &&
        o.sub_section_name === MAIN_SECTIONS.PROMOTER_REVENUES
    );
    if (!override) return row;
    try {
      const parsed = JSON.parse(override.value);
      return {
        ...row,
        revenue: parsed.revenue ?? row.revenue,
        total: parsed.total ?? row.total,
        per_cap: parsed.per_cap ?? row.per_cap,
      };
    } catch (error) {
      console.error("Error parsing override JSON:", error);
      return row;
    }
  }

  const baseRowsMerged = useMemo(
    () => basePromoterRows.map(mergeRowWithOverride),
    [overrides]
  );

  // Custom rows: overrides that do not belong to base fields.
  const customRows: DealRow[] =
    (overrides || [])
      .filter(
        (o) =>
          o.section_name === MAIN_SECTIONS.PROMOTER_REVENUES &&
          o.sub_section_name === MAIN_SECTIONS.PROMOTER_REVENUES &&
          !baseFieldKeys.has(o.field_name)
      )
      .map((override) => {
        try {
          const parsed = JSON.parse(override.value);
          return {
            revenue: parsed.revenue || override.field_name.slice(2),
            key: override.field_name,
            total: parsed.total ?? 0,
            per_cap: parsed.per_cap,
            editable: true,
            format: !isNaN(Number(parsed.total)) ? "money" : undefined,
          };
        } catch (error) {
          console.error("Error parsing custom override JSON:", error);
          return {
            revenue: override.field_name.slice(2),
            key: override.field_name,
            total: override.value,
            per_cap: undefined,
            editable: true,
            format: "money",
          };
        }
      }) || [];

  const promoterRows = useMemo(
    () => [...baseRowsMerged, ...customRows],
    [baseRowsMerged, customRows]
  );

  const promoterRevenuesTotal = useMemo(() => {
    return promoterRows.reduce((acc, curr) => acc + Number(curr.total || 0), 0);
  }, [promoterRows]);

  const showPnLFinalData = [
    {
      key: "guarantee",
      label: "GUARANTEE",
      value: amountDisplay(variables.data?.the_deal_amount ?? 0),
      isEditable: false,
      format: "money",
    },
    {
      key: "venue_copro_share",
      label: "VENUE COPRO SHARE",
      value: amountDisplay(
        getOverrideFieldValue({
          key: "venue_copro_share",
          mainSection: MAIN_SECTIONS.SHOW_PL,
          fallback: eventData.venue?.sales_tax || "",
        }).total
      ),
      isEditable: false,
    },
    {
      key: "partner_1_copro_share",
      label: "PARTNER 1 COPRO SHARE",
      value: amountDisplay(
        getOverrideFieldValue({
          key: "partner_1_copro_share",
          mainSection: MAIN_SECTIONS.SHOW_PL,
          fallback: "",
        }).total
      ),
      isEditable: false,
    },
    {
      key: "partner_2_copro_share",
      label: "PARTNER 2 COPRO SHARE",
      value: amountDisplay(
        getOverrideFieldValue({
          key: "partner_2_copro_share",
          mainSection: MAIN_SECTIONS.SHOW_PL,
          fallback: "",
        }).total
      ),
      isEditable: false,
    },
    {
      key: "adjustments",
      label: "Adjustments",
      value: amountDisplay(
        getOverrideFieldValue({
          key: "adjustments",
          mainSection: MAIN_SECTIONS.SHOW_PL,
          fallback: "",
        }).total
      ),
      isEditable: false,
    },
    {
      key: "other",
      label: "Other",
      value: amountDisplay(
        getOverrideFieldValue({
          key: "other",
          mainSection: MAIN_SECTIONS.SHOW_PL,
          fallback: "",
        }).total
      ),
      isEditable: false,
    },
  ];

  const showPnLData = useMemo(
    () => [
      {
        key: "ticketRev",
        label: "Ticketing Revenue",
        value: amountDisplay(netTicketSales),
        format: "money",
        isEditable: false,
      },
      {
        key: "ancillaryRev",
        label: "Ancillary Revenue",
        value: amountDisplay(promoterRevenuesTotal - netTicketSales),
        format: "money",
        isEditable: false,
      },
      {
        key: "expenses",
        label: "Expenses",
        value: amountDisplay(getExpensesTotal(expensesQuery.data ?? [])),
        format: "money",
        isEditable: false,
      },
      {
        key: "overages",
        label: "Overages",
        value: amountDisplay(
          getOverrideFieldValue({
            key: "overages",
            mainSection: MAIN_SECTIONS.SHOW_PL,
            fallback: "",
          }).total
        ),
        isEditable: false,
      },
      {
        key: "bonus",
        label: "Bonus",
        value: amountDisplay(
          getOverrideFieldValue({
            key: "bonus",
            mainSection: MAIN_SECTIONS.SHOW_PL,
            fallback: "",
          }).total
        ),
        isEditable: false,
      },
      {
        key: "deductions",
        label: "Deductions from Guarantee",
        value: amountDisplay(
          getOverrideFieldValue({
            key: "deductions",
            mainSection: MAIN_SECTIONS.SHOW_PL,
            fallback: "",
          }).total
        ),
        isEditable: false,
      },
      ...(pageIndex === "final" ? showPnLFinalData : []),
    ],
    [
      netTicketSales,
      promoterRevenuesTotal,
      expensesQuery.data,
      pageIndex,
      showPnLFinalData,
      getOverrideFieldValue,
      variables.data,
      eventData,
    ]
  );

  // Instead of using handleOverrideBlur, define our own onBlur handler for promoter rows.
  const onPromoterRowBlur = (
    rowIndex: number,
    accessor: string,
    value: any,
    row: DealRow
  ) => {
    const resolvedValue = JSON.stringify({
      title: row.revenue,
      valueOne: row.valueOne,
      rate: row.rate,
      total: value,
    });
    handleOverride({
      key: row.key,
      value: resolvedValue,
      isMoney: true,
      sectionName: MAIN_SECTIONS.PROMOTER_REVENUES,
      subSectionName: MAIN_SECTIONS.PROMOTER_REVENUES,
    });
  };

  return (
    <div className="mb-8">
      {loading ? (
        <div>Loading...</div>
      ) : (
        <div className="flex flex-wrap lg:flex-nowrap gap-6 mb-8">
          <div className="w-full lg:w-2/3">
            <SettlementTableTitle>Promoter Revenues</SettlementTableTitle>
            {/* Render header Table for add-row */}
            <Table
              columns={headers}
              data={[]}
              showNoDataMessage={false}
              inputClassName={settlementInputClassName}
            />
            {promoterRows.map((row, i) => (
              <TableRow
                key={row.key}
                columns={[
                  {
                    key: "revenue",
                    accessor: "revenue",
                    textAlign: "left",
                    editable: false,
                  },
                  {
                    key: "total",
                    accessor: "total",
                    textAlign: "right",
                    format: "money",
                    editable: row.key === "__net_ticket_sales" ? false : true,
                  },
                  {
                    key: "per_cap",
                    accessor: "per_cap",
                    textAlign: "right",
                    editable: false,
                  },
                ]}
                rowData={row}
                rowIndex={i}
                inputClassName={settlementInputClassName}
                onBlur={(rowIndex, accessor, value, rowData) =>
                  onPromoterRowBlur(rowIndex, accessor, value, rowData)
                }
                onDelete={
                  !baseFieldKeys.has(row.key)
                    ? () => {
                        deleteOverride(row.key);
                      }
                    : undefined
                }
                deleteIcons={!baseFieldKeys.has(row.key)}
                deleteConfirmation="modal"
              />
            ))}
            <Table
              columns={[
                {
                  key: "field_name",
                  accessor: "field_name",
                  title: "FIELD",
                  textAlign: "left",
                  width: "60%",
                  addRowEditable: true,
                },
                {
                  key: "value",
                  accessor: "value",
                  title: "TOTAL",
                  textAlign: "right",
                  format: "money",
                  addRowEditable: true,
                },
                {
                  key: "per_cap",
                  accessor: "per_cap",
                  title: "PER CAP",
                  textAlign: "right",
                  addRowEditable: false,
                },
              ]}
              data={[]}
              enableAddRow
              addRowButtonTitle="Add Revenue"
              showNoDataMessage={false}
              inputClassName={settlementInputClassName}
              onAddRow={async (formData) => {
                if (!formData.field_name) {
                  toast.error("Revenue title is required");
                  return { success: false };
                }
                const fieldName = formData.field_name;
                const exists = promoterRows.some(
                  (r) => r.key === `__${fieldName}`
                );
                if (exists) {
                  toast.error("A revenue with this title already exists");
                  return { success: false };
                }
                const total = Number(formData.value) || 0;
                const per_cap = Number(formData.per_cap) || 0;
                await handleOverride({
                  key: fieldName,
                  value: JSON.stringify({ revenue: fieldName, total, per_cap }),
                  isMoney: true,
                  sectionName: MAIN_SECTIONS.PROMOTER_REVENUES,
                  subSectionName: MAIN_SECTIONS.PROMOTER_REVENUES,
                });
                toast.success("Revenue added");
                return { success: true };
              }}
              hideHeaders
            />
            <TableRow
              columns={[
                {
                  key: "name",
                  accessor: "name",
                  textAlign: "left",
                  width: "65%",
                },
                {
                  key: "value",
                  accessor: "value",
                  textAlign: "right",
                  format: "money",
                },
              ]}
              rowData={{
                name: "TOTAL REVENUES",
                value: promoterRevenuesTotal,
              }}
              rowContainerClass="bg-blue-200"
            />
          </div>
          <div className="w-full lg:w-1/3">
            <SettlementVerticalTable
              title="Show P&L"
              data={showPnLData}
              enableAddRow={false}
              valueClass="text-right pr-4"
              labelClass="pl-1 whitespace-nowrap"
            />
            <TableRow
              columns={[
                {
                  key: "name",
                  accessor: "name",
                  textAlign: "left",
                  width: "65%",
                },
                {
                  key: "value",
                  accessor: "value",
                  textAlign: "right",
                  format: "money",
                },
              ]}
              rowData={{
                name: "PROMOTER PROFIT (LOSS)",
                value: showPnLData.reduce((acc, curr) => {
                  const numVal = Number(curr.value.replace(/[^0-9.-]+/g, ""));
                  return acc + (isNaN(numVal) ? 0 : numVal);
                }, 0),
              }}
              rowContainerClass="bg-blue-200"
            />
          </div>
        </div>
      )}
    </div>
  );
}
