import { useQueryClient } from "@tanstack/react-query";
import { AgGridReact } from "ag-grid-react";
import { useState, useEffect, useMemo, useCallback, useRef } from "react";

import { selectValueToOption } from "@/components/Form";
import { offerEditableBgClass, offerReadOnlyBgClass } from "@/constants/offer";
import { useEventOfferStore } from "@/store/eventOfferStore";

export const FIELD_UPDATE_ANIMATION_TIME = 2000;

const AgeMerchAgGrid = ({
  event,
  eventId,
  ageOptions,
  whoSellsMerchOptions,
  EVENT_REQUIRED_PARAMS,
  EVENT_ACCOUNTING_REQUIRED_PARAMS,
  updatedProperties,
}) => {
  const queryClient = useQueryClient();
  const gridRef = useRef(null);
  const [rowData, setRowData] = useState([]);

  // Use Zustand store instead of mutations and local state
  const {
    eventOfferForm,
    setLocalField,
    patchEventField,
    patchAccountingField,
    modifiedFields,
    isFieldModified,
  } = useEventOfferStore();

  // Initialize store with event data when it loads
  useEffect(() => {
    if (event) {
      // Only initialize fields we care about if they're not already set
      const fieldsToInit = {
        age_requirement: event.age_requirement || "",
        artist_soft_merch_percentage:
          event.artist_soft_merch_percentage !== undefined
            ? event.artist_soft_merch_percentage
            : null,
        artist_hard_merch_percentage:
          event.artist_hard_merch_percentage !== undefined
            ? event.artist_hard_merch_percentage
            : null,
        who_sells_merch: event.who_sells_merch || "",
      };

      // Only initialize if the values don't already exist in the store
      const needsInit = Object.keys(fieldsToInit).some(
        (key) =>
          eventOfferForm[key] === undefined ||
          (eventOfferForm[key] === null && fieldsToInit[key] !== null) ||
          (eventOfferForm[key] === "" && fieldsToInit[key] !== "")
      );

      if (needsInit) {
        useEventOfferStore.getState().initializeEventOfferForm(fieldsToInit);
      }
    }
  }, [event]);

  // ---- Build row data from Zustand store state ----
  const memoEvent = useMemo(() => event, [event]);
  const memoAgeOptions = useMemo(() => ageOptions, [ageOptions]);
  const memoWhoSellsMerchOptions = useMemo(
    () => whoSellsMerchOptions,
    [whoSellsMerchOptions]
  );
  const memoUpdatedProps = useMemo(
    () => updatedProperties,
    [updatedProperties]
  );

  const displayPercentage = useCallback((val) => {
    if (val === null || val === undefined || val === "") return "0 / 100";
    const num = parseInt(val, 10);
    const pct = isNaN(num) ? 0 : Math.min(100, Math.max(0, num));
    return `${pct} / 100`;
  }, []);

  // Helper to get the final value from the Zustand store
  const finalValue = useCallback(
    (accessor) => {
      // If field is in the store, use that value
      if (eventOfferForm[accessor] !== undefined) {
        return eventOfferForm[accessor];
      }
      // Fall back to event data if not in store
      if (!memoEvent) return "";
      return memoEvent[accessor];
    },
    [eventOfferForm, memoEvent]
  );

  useEffect(() => {
    if (!memoEvent) {
      setRowData([]);
      return;
    }

    setRowData([
      {
        header: "AGE REQUIREMENT",
        value:
          selectValueToOption(finalValue("age_requirement"), memoAgeOptions)
            ?.value || finalValue("age_requirement"),
        optionLabel: selectValueToOption(
          finalValue("age_requirement"),
          memoAgeOptions
        )?.label,
        accessor: "age_requirement",
        format: "select",
        options: memoAgeOptions,
        isChanged: isFieldModified("age_requirement"),
        editable: true,
      },
      {
        header: "SOFT MERCH",
        value: finalValue("artist_soft_merch_percentage"),
        accessor: "artist_soft_merch_percentage",
        format: "percentage",
        displayValue: displayPercentage,
        isChanged: isFieldModified("artist_soft_merch_percentage"),
        editable: true,
      },
      {
        header: "HARD MERCH",
        value: finalValue("artist_hard_merch_percentage"),
        accessor: "artist_hard_merch_percentage",
        format: "percentage",
        displayValue: displayPercentage,
        isChanged: isFieldModified("artist_hard_merch_percentage"),
        editable: true,
      },
      {
        header: "WHO SELLS",
        value:
          selectValueToOption(
            finalValue("who_sells_merch"),
            memoWhoSellsMerchOptions
          )?.value || finalValue("who_sells_merch"),
        accessor: "who_sells_merch",
        format: "select",
        options: memoWhoSellsMerchOptions,
        isChanged: isFieldModified("who_sells_merch"),
        editable: true,
      },
    ]);
  }, [
    memoEvent,
    memoAgeOptions,
    memoWhoSellsMerchOptions,
    memoUpdatedProps,
    displayPercentage,
    finalValue,
    modifiedFields,
  ]);

  // Handle field updates based on which type of field is being changed
  const handleUpdateField = useCallback(
    (accessor, value) => {
      // First, update the local state in the store
      setLocalField(accessor, value);

      // Apply validation logic for percentage fields
      if (
        accessor === "artist_soft_merch_percentage" ||
        accessor === "artist_hard_merch_percentage"
      ) {
        const num = parseInt(value, 10);
        if (!isNaN(num)) {
          const clamped = Math.min(100, Math.max(0, num));
          // Use patch accounting for accounting fields
          patchAccountingField(eventId, accessor, clamped).then(() => {
            queryClient.invalidateQueries(["event-detail", eventId]);
          });
        }
      } else if (accessor === "who_sells_merch") {
        // Use patch accounting for who sells merch
        patchAccountingField(eventId, accessor, value).then(() => {
          queryClient.invalidateQueries(["event-detail", eventId]);
        });
      } else if (accessor === "age_requirement") {
        // Use patch event for age requirement
        patchEventField(eventId, accessor, value).then(() => {
          queryClient.invalidateQueries(["event-detail", eventId]);
        });
      }
    },
    [setLocalField, patchAccountingField, patchEventField, eventId, queryClient]
  );

  // ---- AG Grid definitions ----

  const cellEditorSelector = useCallback((params) => {
    if (params.data?.format === "select") {
      return {
        component: "agSelectCellEditor",
        params: {
          values: params.data.options?.map((option) => option.label),
        },
      };
    }
    return { component: "agTextCellEditor" };
  }, []);

  const valueFormatter = useCallback((params) => {
    if (params.data?.format === "select" && params.data.options) {
      const found = params.data.options.find((o) => o.value === params.value);
      return found ? found.label : params.value;
    }
    if (params.data?.format === "percentage" && params.data.displayValue) {
      return params.data.displayValue(params.value);
    }
    return params.value;
  }, []);

  const onCellValueChanged = useCallback(
    (params) => {
      if (params.data?.format === "select") {
        const found = params.data.options?.find(
          (o) => o.label === params.newValue
        );
        if (found) {
          handleUpdateField(params.data.accessor, found.value);
        }
      } else {
        handleUpdateField(params.data.accessor, params.newValue);
      }
    },
    [handleUpdateField]
  );

  const getCellClass = useCallback(
    (params) => {
      const base = params.data.editable
        ? offerEditableBgClass
        : offerReadOnlyBgClass;
      return params.data.isChanged ? `glow-text ${base}` : base;
    },
    [modifiedFields]
  );

  const columnDefs = useMemo(
    () => [
      {
        field: "header",
        width: 180,
        minWidth: 180,
        editable: false,
        cellClass: offerReadOnlyBgClass,
      },
      {
        field: "value",
        flex: 3,
        editable: (params) => !!params.data?.editable,
        cellEditorSelector,
        valueGetter: (params) => {
          if (params.data?.format === "select" && params.data.options) {
            const found = params.data.options.find(
              (opt) => opt.value === params.data.value
            );
            return found ? found.label : params.data.value;
          }
          return params.data.value;
        },
        valueFormatter,
        onCellValueChanged,
        cellClass: getCellClass,
      },
    ],
    [cellEditorSelector, valueFormatter, onCellValueChanged, getCellClass]
  );

  const defaultColDef = useMemo(
    () => ({
      resizable: true,
      sortable: false,
      filter: false,
    }),
    []
  );

  const memoRowData = useMemo(() => rowData, [rowData]);

  const onGridReady = useCallback((params) => {
    gridRef.current.api = params.api;
  }, []);

  if (!memoEvent) {
    return (
      <div className="w-full border border-gray-300 border-dashed p-4 text-center">
        <p>Waiting for event data...</p>
      </div>
    );
  }

  return (
    <div className="w-full border border-gray-300 border-dashed">
      <div className="ag-theme-alpine" style={{ width: "100%" }}>
        <AgGridReact
          ref={gridRef}
          rowData={memoRowData}
          columnDefs={columnDefs}
          defaultColDef={defaultColDef}
          domLayout="normal"
          headerHeight={0}
          onGridReady={onGridReady}
          getRowId={(params) => params.data.accessor}
          immutableData
          suppressChangeDetection
          suppressCellFlash
          animateRows={false}
          suppressRowTransform
          singleClickEdit
          stopEditingWhenCellsLoseFocus
          refreshCells={false}
          rowHeight={35}
          suppressScrollOnNewData
          suppressHorizontalScroll
        />
      </div>
    </div>
  );
};

export default AgeMerchAgGrid;
