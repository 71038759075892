const MAMMOTH_STATE = "KS";
const MAMMOTH_CITY = "LAWRENCE";
const MAMMOTH_ZIPCODE = "66044";
export const MAMMOTH_DETAILS = {
  STATE: MAMMOTH_STATE,
  CITY: MAMMOTH_CITY,
  ZIPCODE: MAMMOTH_ZIPCODE,
  SRC: "/static/images/mammoth-logo.png",
  ADDRESS: "1520 HASKELL AVE",
  PHONE: "785.749.7475 PH | 785.865.4110 FX",
};

export const accountingDashboardEarlyAccessUsers = [
  38, 117, 306, 312, 27, 305, 26, 296, 46, 118, 115,
];
