import { jsonHeaders, makeRequest, postApiRequest } from "./base";

export interface EventDueMisc {
  id: number;
  event: number;
  description: string;
  amount: number;
  is_addition: boolean;
  created: string;
  modified: string;
}

export interface EventDue {
  id: number;
  event: number;
  due_building: number;
  mammoth_percentage: number;
  profit_percent: number;
  plus_expenses: number;
  plus_guarantee: number;
  plus_artist_costs: number;
  plus_tax: number;
  plus_facility_fee: number;
  plus_platinum_percentage: number;
  plus_charity: number;
  less_rebate: number;
  less_guarantee_deductions: number;
  less_cash: number;
  less_ancillary_revenue: number;
  less_tax: number;
  less_platinum: number;
  less_ticketing_revenue: number;
  less_cash_received: number;
  due_misc_items: EventDueMisc[];
  created: string;
  modified: string;
}

export const fetchEventDues = async (eventId: number): Promise<EventDue[]> => {
  const headers = await jsonHeaders(false);
  const response = await makeRequest(
    `/api/events/dues/${eventId}/`,
    "GET",
    headers
  );
  return response.json();
};

export const updateEventDue = async (
  eventId: number,
  dueId: number,
  data: Partial<EventDue>
): Promise<EventDue> => {
  const headers = await jsonHeaders(true);
  const response = await makeRequest(
    `/api/events/dues/${eventId}/${dueId}/`,
    "PUT",
    headers,
    JSON.stringify(data)
  );
  return response.json();
};

export const createEventDues = async (id, data) => {
  const url = `/api/events/dues/${id}/`;
  return await postApiRequest(url, data);
};

export const createEventDueMisc = async (
  eventId: number,
  data: Omit<EventDueMisc, "id" | "created" | "modified">
): Promise<EventDueMisc> => {
  const headers = await jsonHeaders(true);
  const response = await makeRequest(
    `/api/events/dues-misc/${eventId}/`,
    "POST",
    headers,
    JSON.stringify({
      ...data,
      event: eventId,
      is_addition: data.is_addition ?? true, // Default to addition if not specified
    })
  );
  return response.json();
};

export const fetchEventDuesMisc = async (
  eventId: number
): Promise<EventDueMisc[]> => {
  const headers = await jsonHeaders(false);
  const response = await makeRequest(
    `/api/events/dues-misc/${eventId}/`,
    "GET",
    headers
  );
  return response.json();
};

export const addNewMiscEntry = async (
  eventId: number,
  description: string = "Misc Item",
  amount: number = 0,
  isAddition: boolean = true
): Promise<EventDueMisc> => {
  return createEventDueMisc(eventId, {
    description,
    amount,
    is_addition: isAddition,
    event: eventId,
  });
};

export const updateEventDueMisc = async (
  eventId: number,
  itemId: number,
  data: Partial<EventDueMisc>
): Promise<EventDueMisc> => {
  const headers = await jsonHeaders(true);
  const response = await makeRequest(
    `/api/events/dues-misc/${eventId}/${itemId}/`,
    "PUT",
    headers,
    JSON.stringify(data)
  );
  return response.json();
};

export const deleteEventDueMisc = async (
  eventId: number,
  itemId: number
): Promise<void> => {
  const headers = await jsonHeaders(true);
  await makeRequest(
    `/api/events/dues-misc/${eventId}/${itemId}/`,
    "DELETE",
    headers
  );
};

export const deleteEventDue = async (
  eventId: number,
  dueId: number
): Promise<void> => {
  const headers = await jsonHeaders(true);
  await makeRequest(`/api/events/dues/${eventId}/${dueId}/`, "DELETE", headers);
};
