import { EventsLoading } from "@/components/Events/EventsLoading";
import { Header } from "@/components/Header";
import { useQuery } from "@tanstack/react-query";
import { useNavigate } from "react-router-dom";
import { getEventsList, getEventsSettings } from "@/queries/events";
import { BreadCrumbs } from "@/components/BreadCrumbs";
import EventState from "@/components/EventState";
import Button from "@/components/Button";
import { usePerms } from "@/components/Auth";
import { usePageTitle } from "@/utils/pagetitle";
import { EventCard } from "@/components/Dashboard/EventCard/EventCard";
import { Carousel } from "@/components/Dashboard/Carousel/Carousel";
import { useState } from "react";

export const TouringHome = () => {
  const [activeFilters, setActiveFilters] = useState([
    "mammoth_touring",
    "sabertooth",
    "grizzly",
  ]);

  const events = useQuery(
    ["events", "list"],
    () => getEventsList(),
    getEventsSettings
  );

  // Add comprehensive debugging logs
  console.log("Query state:", {
    isLoading: events.isLoading,
    isError: events.isError,
    error: events.error,
    isFetched: events.isFetched,
  });
  console.log("All events:", events.data);

  const filteredEvents =
    events.data?.filter((event) => {
      // Normalize company values for comparison
      const eventCompany = (event?.company || "").toLowerCase().trim();
      const normalizedFilters = activeFilters.map((f) =>
        f.toLowerCase().trim()
      );

      // Log detailed filtering info
      console.log("Filtering event:", {
        name: event?.name,
        company: event?.company,
        normalizedCompany: eventCompany,
        hasCompany: !!event?.company,
        matchesFilter: normalizedFilters.includes(eventCompany),
        activeFilters: normalizedFilters,
      });

      return event && eventCompany && normalizedFilters.includes(eventCompany);
    }) || [];

  // Log final results with detailed event info
  console.log("Raw events data:", events.data);
  console.log(
    "Filtered events:",
    filteredEvents?.map((event) => ({
      id: event?.id,
      name: event?.name,
      company: event?.company,
      matchesFilter: event?.company && activeFilters.includes(event?.company),
    }))
  );
  console.log("Active filters:", activeFilters);
  const navigate = useNavigate();
  const { perms } = usePerms();
  usePageTitle(events.isSuccess ? "/c/touring" : "/c/ave_good...");

  if (events.isLoading) {
    return <EventsLoading />;
  }

  return (
    <>
      <div>
        <Header />
        <div className="flex flex-col px-6 py-8 mx-auto min-h-screen lg:py-0 dark:text-cave-white">
          <div className="w-full flex justify-between xs:flex-col sm:flex-row md:flex-row items-center xs:gap-[1rem]">
            <div className="flex items-center gap-4">
              <BreadCrumbs
                links={[{ text: "Touring Events", url: "/touring" }]}
              />
              <div className="flex space-x-2">
                <button
                  className={`px-2 py-1 rounded text-xs transition-colors duration-200 ${
                    activeFilters.includes("mammoth_touring")
                      ? "bg-blue-600 text-white"
                      : "bg-gray-500 text-white"
                  }`}
                  onClick={() =>
                    setActiveFilters((prev) =>
                      prev.includes("mammoth_touring")
                        ? prev.filter((f) => f !== "mammoth_touring")
                        : [...prev, "mammoth_touring"]
                    )
                  }
                >
                  Mammoth Touring
                </button>
                <button
                  className={`px-2 py-1 rounded text-xs transition-colors duration-200 ${
                    activeFilters.includes("sabertooth")
                      ? "bg-blue-600 text-white"
                      : "bg-gray-500 text-white"
                  }`}
                  onClick={() =>
                    setActiveFilters((prev) =>
                      prev.includes("sabertooth")
                        ? prev.filter((f) => f !== "sabertooth")
                        : [...prev, "sabertooth"]
                    )
                  }
                >
                  Sabertooth
                </button>
                <button
                  className={`px-2 py-1 rounded text-xs transition-colors duration-200 ${
                    activeFilters.includes("grizzly")
                      ? "bg-blue-600 text-white"
                      : "bg-gray-500 text-white"
                  }`}
                  onClick={() =>
                    setActiveFilters((prev) =>
                      prev.includes("grizzly")
                        ? prev.filter((f) => f !== "grizzly")
                        : [...prev, "grizzly"]
                    )
                  }
                >
                  Grizzly
                </button>
              </div>
              <Button onClick={() => navigate("/touring/list")}>
                Event List
              </Button>
              {perms.data.create_events && (
                <Button onClick={() => navigate("/events/add?company=touring")}>
                  Create Touring Draft
                </Button>
              )}
            </div>
          </div>

          <div className="my-2 h-auto w-auto shadow-md sm:rounded-lg">
            {events.isSuccess && (
              <div className="flex flex-wrap gap-6">
                {filteredEvents.map((event, index) => (
                  <div
                    key={`non-draft-${event.id}`}
                    className="bg-cave-white dark:bg-[#181818] xs:mx-auto shadow-[0_8px_30px_rgb(255,255,255,0.12)] dark:shadow-[0_8px_30px_rgba(0,0,0,0.12)]"
                  >
                    <EventCard
                      eventName={event.name}
                      venue={event.venue_name}
                      date={event.date}
                      status={event.state}
                      onClick={() => navigate(`/events/${event.id}`)}
                      backgroundImage={
                        event.event_image_url
                          ? event.event_image_url
                          : "/static/images/cave_no_image_event.png"
                      }
                      url={`/events/${event.id}`}
                    />
                  </div>
                ))}
              </div>
            )}
          </div>
        </div>
      </div>
    </>
  );
};
