import React from "react";
import {
  useEffect,
  useRef,
  useState,
  useCallback,
  useMemo,
  useReducer,
} from "react";
import { useQuery, useMutation, useQueryClient } from "@tanstack/react-query";
import { format, parse, isValid } from "date-fns";
import { advanceSheetSchema } from "./validationSchema";
import {
  handleValidationError,
  handleSubmissionError,
  clearValidationErrors,
} from "../../utils/errorHandling";
import { useUser, usePerms } from "@/components/Auth";
import { useParams, useNavigate } from "react-router-dom";
import Button from "@/components/Button";
import styles from "@/views/events/EventDetail.module.css";
import classNames from "classnames";

import {
  addEventToOffer,
  createOffer,
  getEventOfferVariables,
  getOffer,
  getOffers,
  updateEvent,
  deleteEventPeople,
  getEventPeople,
  getEvent,
} from "../../queries/events";
import {
  getEventAdvance,
  createEventAdvance,
  updateEventAdvance,
  deleteEventAdvance,
} from "../../queries/advances";
import {
  PencilSquareIcon,
  CheckIcon,
  ChevronDownIcon,
  ChevronUpIcon,
} from "@heroicons/react/24/outline";
import { getEventRollups, getEventCopros } from "@/queries/accounting";
import {
  contactFields,
  tourContactFields,
  scheduleFields,
  equipmentFields,
  technicalFields,
  laborFields,
  cateringFields,
  dressingRoomFields,
  parkingFields,
  notesFields,
} from "./fieldMappings";

export const EventAdvance = ({ id, eventData, variablesData, advData }) => {
  // Query client hook must be first
  const queryClient = useQueryClient();

  // All other queries should follow
  const event = useQuery(["event-detail", id], () => getEvent(id), {
    onError: (error) => {
      console.error("Failed to load event:", error);
    },
  });

  const advance = useQuery(["event-advance", id], () => getEventAdvance(id), {
    onError: (error) => {
      console.error("Failed to load advance data:", error);
    },
  });

  // Table Components
  const TechnicalStageInfoTable = ({ advance, onUpdate }) => {
    const techRows = [
      {
        header: "POWER #1",
        value: advance.data?.tech_power_one || "",
        editable: true,
        accessor: "tech_power_one",
      },
      {
        header: "POWER #2",
        value: advance.data?.tech_power_two || "",
        editable: true,
        accessor: "tech_power_two",
      },
      {
        header: "SHORE POWER",
        value: advance.data?.tech_shore_power || "",
        editable: true,
        accessor: "tech_shore_power",
      },
      {
        header: "STAGE SIZE",
        value: advance.data?.tech_stage_dimensions || "",
        editable: true,
        accessor: "tech_stage_dimensions",
      },
      {
        header: "STAGE LEFT WING",
        value: advance.data?.tech_stage_left_wing || "",
        editable: true,
        accessor: "tech_stage_left_wing",
      },
      {
        header: "STAGE RIGHT WING",
        value: advance.data?.tech_stage_right_wing || "",
        editable: true,
        accessor: "tech_stage_right_wing",
      },
      {
        header: "MIX AREA",
        value: advance.data?.tech_mix_area || "",
        editable: true,
        accessor: "tech_mix_area",
      },
      {
        header: "MIX DISTANCE",
        value: advance.data?.tech_mix_distance || "",
        editable: true,
        accessor: "tech_mix_distance",
      },
      {
        header: "PROSCENIUM",
        value: advance.data?.tech_proscenium || "",
        editable: true,
        accessor: "tech_proscenium",
      },
      {
        header: "GRID HEIGHT",
        value: advance.data?.tech_grid_height || "",
        editable: true,
        accessor: "tech_grid_height",
      },
    ];

    return (
      <ItemTable
        header={{ value: "VENUE TECH/STAGE INFO" }}
        rows={techRows}
        columns={[
          { header: "", accessor: "header" },
          { header: "", accessor: "value" },
        ]}
        color="red"
        showTable={true}
        showChevrons={false}
        onBlur={handleTableInputBlur}
        onKeyDown={handleTableInputKeyDown}
      />
    );
  };

  const LaborCallsTable = ({ advance, onUpdate }) => {
    const laborRows = [
      {
        header: "STEWARD",
        value: advance.data?.labor_call_steward || "",
        editable: true,
        accessor: "labor_call_steward",
      },
      {
        header: "HANDS",
        value: advance.data?.labor_call_hands || "",
        editable: true,
        accessor: "labor_call_hands",
      },
      {
        header: "LOADERS",
        value: advance.data?.labor_call_loaders || "",
        editable: true,
        accessor: "labor_call_loaders",
      },
      {
        header: "RIGGERS (UP)",
        value: advance.data?.labor_call_riggers_up || "",
        editable: true,
        accessor: "labor_call_riggers_up",
      },
      {
        header: "RIGGERS (DOWN)",
        value: advance.data?.labor_call_riggers_down || "",
        editable: true,
        accessor: "labor_call_riggers_down",
      },
      {
        header: "ELECTRICIAN",
        value: advance.data?.labor_call_electrician || "",
        editable: true,
        accessor: "labor_call_electrician",
      },
      {
        header: "WARDROBE",
        value: advance.data?.labor_call_wardrobe || "",
        editable: true,
        accessor: "labor_call_wardrobe",
      },
      {
        header: "SPOT OPS",
        value: advance.data?.labor_call_spot_ops || "",
        editable: true,
        accessor: "labor_call_spot_ops",
      },
      {
        header: "HOUSE LIGHTS",
        value: advance.data?.labor_call_house_lights || "",
        editable: true,
        accessor: "labor_call_house_lights",
      },
      {
        header: "FLY MAN",
        value: advance.data?.labor_call_fly_man || "",
        editable: true,
        accessor: "labor_call_fly_man",
      },
    ];

    return (
      <ItemTable
        header={{ value: "LABOR CALLS" }}
        rows={laborRows}
        columns={[
          { header: "", accessor: "header" },
          { header: "LOAD IN:", accessor: "loadIn" },
          { header: "SHOW CALL:", accessor: "showCall" },
          { header: "LOAD OUT:", accessor: "loadOut" },
        ]}
        color="red"
        showTable={true}
        showChevrons={false}
        onBlur={handleTableInputBlur}
        onKeyDown={handleTableInputKeyDown}
      />
    );
  };

  const LaborRulesTable = ({ advance, onUpdate }) => {
    const laborRulesRows = [
      {
        header: "CREW TYPE",
        value: advance.data?.labor_rule_crew_type || "",
        editable: true,
        accessor: "labor_rule_crew_type",
      },
      {
        header: "STEWARD NAME",
        value: advance.data?.labor_rule_steward_name || "",
        editable: true,
        accessor: "labor_rule_steward_name",
      },
      {
        header: "MIN IN",
        value: advance.data?.labor_rule_min_in || "",
        editable: true,
        accessor: "labor_rule_min_in",
      },
      {
        header: "MIN SHOW",
        value: advance.data?.labor_rule_min_show || "",
        editable: true,
        accessor: "labor_rule_min_show",
      },
      {
        header: "MIN OUT",
        value: advance.data?.labor_rule_min_out || "",
        editable: true,
        accessor: "labor_rule_min_out",
      },
      {
        header: "CONVERT",
        value: advance.data?.labor_rule_convert || "",
        editable: true,
        accessor: "labor_rule_convert",
      },
      {
        header: "FEED BY",
        value: advance.data?.labor_rule_feed_by || "",
        editable: true,
        accessor: "labor_rule_feed_by",
      },
      {
        header: "MIN CREW",
        value: advance.data?.labor_rule_min_crew || "",
        editable: true,
        accessor: "labor_rule_min_crew",
      },
      {
        header: "OVERTIME",
        value: advance.data?.labor_rule_overtime || "",
        editable: true,
        accessor: "labor_rule_overtime",
      },
      {
        header: "DARK",
        value: advance.data?.labor_rule_dark || "",
        editable: true,
        accessor: "labor_rule_dark",
      },
    ];

    return (
      <ItemTable
        header={{ value: "LABOR TYPE/RULES" }}
        rows={laborRulesRows}
        columns={[
          { header: "", accessor: "header" },
          { header: "", accessor: "value" },
        ]}
        color="red"
        showTable={true}
        showChevrons={false}
        onBlur={handleTableInputBlur}
        onKeyDown={handleTableInputKeyDown}
      />
    );
  };

  const SettlementTable = ({ advance, onUpdate }) => {
    const settlementRows = [
      {
        header: "CONTACT",
        value: advance.data?.settlement_contact || "",
        editable: true,
        accessor: "settlement_contact",
      },
      {
        header: "PHONE",
        value: advance.data?.settlement_phone || "",
        editable: true,
        accessor: "settlement_phone",
      },
      {
        header: "EMAIL",
        value: advance.data?.settlement_email || "",
        editable: true,
        accessor: "settlement_email",
      },
      {
        header: "TIME",
        value: advance.data?.settlement_time || "",
        editable: true,
        accessor: "settlement_time",
        format: "time",
      },
      {
        header: "LOCATION",
        value: advance.data?.settlement_location || "",
        editable: true,
        accessor: "settlement_location",
      },
      {
        header: "INSTRUCTIONS",
        value: advance.data?.settlement_instructions || "",
        editable: true,
        accessor: "settlement_instructions",
      },
      {
        header: "AMOUNT",
        value: advance.data?.settlement_amount || "",
        editable: true,
        accessor: "settlement_amount",
        format: "currency",
      },
      {
        header: "METHOD",
        value: advance.data?.settlement_method || "",
        editable: true,
        accessor: "settlement_method",
      },
    ];

    return (
      <ItemTable
        header={{ value: "SETTLEMENT" }}
        rows={settlementRows}
        columns={[
          { header: "", accessor: "header" },
          { header: "", accessor: "value" },
        ]}
        color="red"
        showTable={true}
        showChevrons={false}
        onBlur={handleTableInputBlur}
        onKeyDown={handleTableInputKeyDown}
      />
    );
  };

  // Table Components
  const ProductionRequirementsTable = ({ advance, onUpdate }) => {
    const equipmentRows = [
      {
        header: "RACK/STACKS",
        value: advance.data?.equip_rack_stacks || "",
        editable: true,
        accessor: "equip_rack_stacks",
      },
      {
        header: "CONSOLES",
        value: advance.data?.equip_consoles || "",
        editable: true,
        accessor: "equip_consoles",
      },
      {
        header: "LIGHTS",
        value: advance.data?.equip_lights || "",
        editable: true,
        accessor: "equip_lights",
      },
      {
        header: "VIDEO",
        value: advance.data?.equip_video || "",
        editable: true,
        accessor: "equip_video",
      },
      {
        header: "SPOTLIGHTS",
        value: advance.data?.equip_spotlights || "",
        editable: true,
        accessor: "equip_spotlights",
      },
      {
        header: "CLEAR COMM",
        value: advance.data?.equip_clear_comm || "",
        editable: true,
        accessor: "equip_clear_comm",
      },
      {
        header: "CURTAINS",
        value: advance.data?.equip_curtains || "",
        editable: true,
        accessor: "equip_curtains",
      },
      {
        header: "BIKE RACK",
        value: advance.data?.equip_bike_rack || "",
        editable: true,
        accessor: "equip_bike_rack",
      },
      {
        header: "BARRICADE",
        value: advance.data?.equip_barricade || "",
        editable: true,
        accessor: "equip_barricade",
      },
    ];

    return (
      <ItemTable
        header={{ value: "PRODUCTION REQUIREMENTS" }}
        rows={equipmentRows}
        columns={[
          { header: "", accessor: "header" },
          { header: "", accessor: "value" },
        ]}
        color="red"
        showTable={true}
        showChevrons={false}
        onBlur={handleTableInputBlur}
        onKeyDown={handleTableInputKeyDown}
      />
    );
  };

  const CateringInfoTable = ({ advance, onUpdate }) => {
    const cateringRows = [
      {
        header: "BREAKFAST",
        value: advance.data?.catering_breakfast_info || "",
        editable: true,
        accessor: "catering_breakfast_info",
      },
      {
        header: "LUNCH",
        value: advance.data?.catering_lunch_info || "",
        editable: true,
        accessor: "catering_lunch_info",
      },
      {
        header: "DINNER",
        value: advance.data?.catering_dinner_info || "",
        editable: true,
        accessor: "catering_dinner_info",
      },
      {
        header: "DRESSING ROOMS",
        value: advance.data?.catering_dressing_room || "",
        editable: true,
        accessor: "catering_dressing_room",
      },
      {
        header: "SPECIAL REQUESTS",
        value: advance.data?.catering_special_requests || "",
        editable: true,
        accessor: "catering_special_requests",
      },
      {
        header: "DIETARY RESTRICTIONS",
        value: advance.data?.catering_dietary_restrictions || "",
        editable: true,
        accessor: "catering_dietary_restrictions",
      },
    ];

    return (
      <ItemTable
        header={{ value: "CATERING INFO" }}
        rows={cateringRows}
        columns={[
          { header: "", accessor: "header" },
          { header: "", accessor: "value" },
        ]}
        color="red"
        showTable={true}
        showChevrons={false}
        onBlur={handleTableInputBlur}
        onKeyDown={handleTableInputKeyDown}
      />
    );
  };

  const DressingRoomTable = ({ advance, onUpdate }) => {
    const dressingRoomRows = [
      {
        header: "ROOM #1",
        value: advance.data?.room_one || "",
        editable: true,
        accessor: "room_one",
      },
      {
        header: "ROOM #2",
        value: advance.data?.room_two || "",
        editable: true,
        accessor: "room_two",
      },
      {
        header: "ROOM #3",
        value: advance.data?.room_three || "",
        editable: true,
        accessor: "room_three",
      },
      {
        header: "FURNITURE",
        value: advance.data?.furniture || "",
        editable: true,
        accessor: "furniture",
      },
    ];

    return (
      <ItemTable
        header={{ value: "DRESSING ROOM NEEDS" }}
        rows={dressingRoomRows}
        columns={[
          { header: "", accessor: "header" },
          { header: "", accessor: "value" },
        ]}
        color="red"
        showTable={true}
        showChevrons={false}
        onBlur={handleTableInputBlur}
        onKeyDown={handleTableInputKeyDown}
      />
    );
  };

  const ParkingTable = ({ advance, onUpdate }) => {
    const parkingRows = [
      {
        header: "TRUCKS",
        value: advance.data?.parking_truck || "",
        editable: true,
        accessor: "parking_truck",
      },
      {
        header: "BUSES",
        value: advance.data?.parking_bus || "",
        editable: true,
        accessor: "parking_bus",
      },
      {
        header: "VANS",
        value: advance.data?.parking_van || "",
        editable: true,
        accessor: "parking_van",
      },
      {
        header: "CARS",
        value: advance.data?.parking_car || "",
        editable: true,
        accessor: "parking_car",
      },
    ];

    return (
      <ItemTable
        header={{ value: "PARKING" }}
        rows={parkingRows}
        columns={[
          { header: "", accessor: "header" },
          { header: "", accessor: "value" },
        ]}
        color="red"
        showTable={true}
        showChevrons={false}
        onBlur={handleTableInputBlur}
        onKeyDown={handleTableInputKeyDown}
      />
    );
  };

  const NotesTable = ({ advance, onUpdate }) => {
    const notesRows = [
      {
        header: "LOAD IN NOTES",
        value: advance.data?.notes_load_in || "",
        editable: true,
        accessor: "notes_load_in",
      },
      {
        header: "CATERING NOTES",
        value: advance.data?.catering_notes || "",
        editable: true,
        accessor: "catering_notes",
      },
      {
        header: "HOTEL NOTES",
        value: advance.data?.hotel_notes || "",
        editable: true,
        accessor: "hotel_notes",
      },
      {
        header: "PARKING NOTES",
        value: advance.data?.parking_notes || "",
        editable: true,
        accessor: "parking_notes",
      },
      {
        header: "MERCH NOTES",
        value: advance.data?.merch_notes || "",
        editable: true,
        accessor: "merch_notes",
      },
      {
        header: "GENERAL NOTES",
        value: advance.data?.general_notes || "",
        editable: true,
        accessor: "general_notes",
      },
      {
        header: "SECURITY NOTES",
        value: advance.data?.security_notes || "",
        editable: true,
        accessor: "security_notes",
      },
      {
        header: "VENUE NOTES",
        value: advance.data?.venue_notes || "",
        editable: true,
        accessor: "venue_notes",
      },
      {
        header: "TECHNICAL NOTES",
        value: advance.data?.technical_notes || "",
        editable: true,
        accessor: "technical_notes",
      },
      {
        header: "SCHEDULE NOTES",
        value: advance.data?.schedule_notes || "",
        editable: true,
        accessor: "schedule_notes",
      },
      {
        header: "GUEST LIST NOTES",
        value: advance.data?.guest_list_notes || "",
        editable: true,
        accessor: "guest_list_notes",
      },
    ];

    return (
      <ItemTable
        header={{ value: "NOTES" }}
        rows={notesRows}
        columns={[
          { header: "", accessor: "header" },
          { header: "", accessor: "value" },
        ]}
        color="red"
        showTable={true}
        showChevrons={false}
        onBlur={handleTableInputBlur}
        onKeyDown={handleTableInputKeyDown}
      />
    );
  };

  // Constants
  const showTable2 = true;

  // Define the error boundary wrapper first
  const EventAdvanceWithErrorBoundary = (props) => (
    <EventAdvanceErrorBoundary>
      <EventAdvance {...props} />
    </EventAdvanceErrorBoundary>
  );

  class EventAdvanceErrorBoundary extends React.Component {
    constructor(props) {
      super(props);
      this.state = { hasError: false };
    }

    static getDerivedStateFromError(error) {
      return { hasError: true };
    }

    componentDidCatch(error, errorInfo) {
      console.error("EventAdvance Error:", error, errorInfo);
    }

    render() {
      if (this.state.hasError) {
        return <div>Something went wrong. Please try again.</div>;
      }
      return this.props.children;
    }
  }

  function parseHumanTime(value) {
    const parsed = parse(value, "h:mm a", new Date());
    if (isValid(parsed)) {
      return parsed;
    } else {
      // Try parsing without minutes
      const parsedHour = parse(value, "h a", new Date());
      if (isValid(parsedHour)) {
        return parsedHour;
      }
    }
    return null;
  }

  // Add this helper function
  // Pure utility functions
  const validateField = (value, type) => {
    switch (type) {
      case "email":
        return /^[^\s@]+@[^\s@]+\.[^\s@]+$/.test(value)
          ? null
          : "Invalid email format";
      case "phone":
        return /^[0-9-+()]*$/.test(value) ? null : "Invalid phone format";
      case "time":
        return parseTimeValue(value) ? null : "Invalid time format";
      case "currency":
        return !isNaN(parseFloat(value)) && parseFloat(value) >= 0
          ? null
          : "Invalid currency value";
      case "boolean":
        return typeof value === "boolean" || value === "Yes" || value === "No"
          ? null
          : "Must be Yes or No";
      case "number":
        return !isNaN(parseInt(value)) && parseInt(value) >= 0
          ? null
          : "Must be a positive number";
      case "textarea":
        return typeof value === "string" && value.length <= 2000
          ? null
          : "Text too long (max 2000 characters)";
      default:
        return null;
    }
  };

  // Pure utility functions
  const parseTimeValue = (value) => {
    if (!value) return null;

    // Handle "5pm" format
    const pmRegex = /^(\d{1,2})(?::(\d{2}))?\s*(pm|am)$/i;
    const match = value.toLowerCase().match(pmRegex);

    if (match) {
      let [_, hours, minutes = "00", period] = match;
      hours = parseInt(hours);

      // Convert to 24-hour format
      if (period === "pm" && hours !== 12) {
        hours += 12;
      } else if (period === "am" && hours === 12) {
        hours = 0;
      }

      // Return in HH:mm:ss format
      return `${hours.toString().padStart(2, "0")}:${minutes}:00`;
    }

    // If it's already in HH:mm:ss format, return as is
    const timeRegex = /^([0-1]?[0-9]|2[0-3]):[0-5][0-9]:[0-5][0-9]$/;
    if (timeRegex.test(value)) {
      return value;
    }

    // Try parsing with date-fns as fallback
    const parsed = parseHumanTime(value);
    if (parsed) {
      return format(parsed, "HH:mm:ss");
    }

    return null;
  };

  const ItemTable = ({
    rows = [],
    header,
    info,
    columns = [],
    color = "gray",
    highlightedColumn,
    onUpdate,
    tableId,
    showTable,
    columnWidths = ["200px", "auto"],
    showChevrons = true,
    validationErrors = {},
    setValidationErrors,
    getSchemaPath,
    onBlur,
    onKeyDown,
  }) => {
    const [editableRows, setEditableRows] = useState(rows);
    const [tableValidationErrors, setTableValidationErrors] = useState({});

    const thClasses = classNames(
      "cursor-pointer text-center font-bold text-white text-md font-inter",
      { "bg-[#4E6B85] hover:bg-[#3A4F61]": color === "gray" },
      { "bg-red-500 hover:bg-red-700": color === "red" }
    );

    const handleTableSectionHeaderChange = (event, rowIndex) => {
      const newRows = [...editableRows];
      newRows[rowIndex].header = event.target.value;
      setEditableRows(newRows);
    };

    const handleHeaderChange = (event, rowIndex) => {
      const newRows = [...editableRows];
      newRows[rowIndex].header = event.target.value;
      setEditableRows(newRows);
      if (onUpdate) {
        onUpdate(`header_${rowIndex}`, event.target.value);
      }
    };

    return (
      <table
        className="min-w-full rounded-lg overflow-hidden table-fixed"
        style={{ tableLayout: "fixed", width: "100%" }}
      >
        <colgroup>
          {columnWidths.map((width, idx) => (
            <col key={idx} style={{ width }} />
          ))}
        </colgroup>

        <thead>
          {header && (
            <tr className="h-10">
              <th className={thClasses} colSpan={columns.length || 1}>
                <div className="flex justify-center items-center">
                  <span>{header.value}</span>
                  {showChevrons &&
                    (showTable ? (
                      <ChevronDownIcon
                        className={styles.chevronAccordionDown}
                      />
                    ) : (
                      <ChevronUpIcon className={styles.chevronAccordionUp} />
                    ))}
                </div>
              </th>
            </tr>
          )}
          {showTable && columns.length > 0 && (
            <tr className="bg-[#f3836f]">
              {columns.map((col, idx) => (
                <th
                  key={idx}
                  className={`border-r border-black text-center dark:text-white uppercase ${styles.heavyFont}`}
                >
                  {col.header}
                </th>
              ))}
            </tr>
          )}
        </thead>
        {showTable && (
          <tbody className="w-full">
            {info && (
              <tr className="w-full h-[240px]">
                <td
                  colSpan={columns.length || 1}
                  className="bg-white border-b border-[black] text-center text-sm font-inter whitespace-pre-line fton-[900] text-[1.2rem] leading-[1.5]"
                >
                  <div>
                    {info}
                    {row?.header && col?.accessor && tableValidationErrors && (
                      <div className="text-xs text-red-500 mt-1">
                        {tableValidationErrors[
                          `${row.header.toLowerCase().replace(/\s+/g, "_")}_${
                            col.accessor
                          }`
                        ] || ""}
                      </div>
                    )}
                  </div>
                </td>
              </tr>
            )}
            {editableRows.length > 0 ? (
              editableRows.map((row, index) => (
                <tr
                  className={`${
                    index % 2 !== 0 ? "bg-gray-100" : "bg-white"
                  } h-12`}
                  key={index}
                >
                  {columns.map((col, colIdx) => (
                    <td
                      key={colIdx}
                      className={`border-r border-black p-1 px-3 text-sm font-inter relative ${
                        colIdx === 0
                          ? "text-black font-[900]"
                          : "text-black font-[400]"
                      } ${
                        validationErrors[
                          `${row.header?.toLowerCase().replace(/\s+/g, "_")}_${
                            col.accessor
                          }`
                        ]
                          ? "border-red-500 bg-red-50"
                          : ""
                      }`}
                      title={
                        col.accessor === "website"
                          ? "Must be a complete URL starting with http:// or https:// (e.g. https://example.com) - or leave blank. Invalid URLs will not be saved."
                          : ""
                      }
                      contentEditable={colIdx !== 0 && row.editable}
                      suppressContentEditableWarning={true}
                      data-field={
                        row.type === "contact"
                          ? `contact_${row.contactType}_${col.accessor}`
                          : row.type === "vendor"
                          ? `vendor_${row.vendorType}_${col.accessor}`
                          : row.accessor ||
                            `${row.header
                              ?.toLowerCase()
                              .replace(/\s+/g, "_")}_${col.accessor}`
                      }
                      onFocus={(e) => {
                        e.preventDefault();
                        e.target.dataset.beforeInput = e.target.textContent;
                      }}
                      onInput={(e) => {
                        const value = e.target.textContent;
                        console.log("Input value:", value);
                      }}
                      onBlur={(e) => {
                        const newValue = e.target.textContent.trim();
                        const oldValue = e.target.dataset.beforeInput;

                        if (newValue !== oldValue) {
                          const fieldAccessor = e.target.dataset.field;
                          console.log("Table cell blur event:", {
                            fieldAccessor,
                            value: newValue,
                            oldValue,
                          });

                          // Create update object with the new value
                          const updateData = {
                            ...advance.data,
                            [fieldAccessor]: newValue,
                            event_id: id,
                          };

                          // Trigger the mutation with the updated data
                          updateAdvanceMutation.mutate(updateData);
                        }
                      }}
                      onClick={(e) => {
                        e.preventDefault();
                        e.stopPropagation();
                      }}
                      onKeyDown={(e) => onKeyDown?.(e)}
                      style={{
                        whiteSpace: "wrap",
                        overflow: "visible",
                        textOverflow: "ellipsis",
                      }}
                    >
                      <div>
                        {col.accessor === "header" && row.isEditableHeader ? (
                          <input
                            type="text"
                            value={row.header}
                            onChange={(e) => handleHeaderChange(e, index)}
                            className="bg-transparent border-none p-0 m-0 text-center text-black font-[900]"
                            style={{
                              width: "100%",
                              outline: "none",
                              textAlign: "left",
                              textTransform: "uppercase",
                            }}
                          />
                        ) : (
                          row[col.accessor]
                        )}
                        {validationErrors[
                          `${row.header?.toLowerCase().replace(/\s+/g, "_")}_${
                            col.accessor
                          }`
                        ] && (
                          <div className="absolute bottom-[-20px] left-0 text-xs text-red-500 bg-white p-1 rounded shadow-sm z-10">
                            {
                              validationErrors[
                                `${row.header
                                  ?.toLowerCase()
                                  .replace(/\s+/g, "_")}_${col.accessor}`
                              ]
                            }
                          </div>
                        )}
                      </div>
                    </td>
                  ))}
                </tr>
              ))
            ) : (
              <tr>
                <td
                  colSpan={columns.length || 1}
                  className="bg-white border-b border-black text-center text-sm font-normal whitespace-pre-line h-12"
                >
                  NO DATA
                </td>
              </tr>
            )}
          </tbody>
        )}
      </table>
    );
  };

  // Form field component for controlled inputs
  const FormField = ({
    accessor,
    value,
    onChange,
    onBlur,
    type = "text",
    editable = true,
  }) => {
    return (
      <div className="form-field">
        <input
          type={type}
          name={accessor}
          value={value || ""}
          onChange={onChange}
          onBlur={onBlur}
          disabled={!editable}
          className="bg-transparent border-none w-full focus:outline-none"
        />
      </div>
    );
  };

  const variables = useQuery(["variables-offer-pdf", id], () =>
    getEventOfferVariables(id)
  );
  const rollups = useQuery(["event-rollups", id], () => getEventRollups(id));
  const copros = useQuery(["event-copros", id], () => getEventCopros(id));
  const people = useQuery(["event", "people", id], () => getEventPeople(id));

  // Get the latest offer - single source of truth
  const latestOffer = useMemo(
    () =>
      event.data?.offers?.sort(
        (a, b) => new Date(b.modified) - new Date(a.modified)
      )[0],
    [event.data?.offers]
  );

  // Artist state reducer
  function artistReducer(state, action) {
    switch (action.type) {
      case "SET_ARTISTS":
        return {
          ...state,
          headliner: action.payload.headliner,
          support1: action.payload.support1,
          support2: action.payload.support2,
          selectedHeadliner: action.payload.headliner,
          selectedSupport1: action.payload.support1,
          selectedSupport2: action.payload.support2,
        };
      case "SET_OFFER":
        return {
          ...state,
          offerID: action.payload,
        };
      case "UPDATE_SELECTED":
        return {
          ...state,
          [`selected${action.payload.type}`]: action.payload.value,
        };
      default:
        return state;
    }
  }

  // Get user data
  const user = useUser();

  // Group all useState hooks together at the top
  const [validationErrors, setValidationErrors] = useState({});
  const [activeTableSection, setActiveTableSection] = useState(null);
  const [openTable, setOpenTable] = useState(null);
  const [selectedHeadliner, setSelectedHeadliner] = useState(null);
  const [selectedSupport1, setSelectedSupport1] = useState(null);
  const [selectedSupport2, setSelectedSupport2] = useState(null);
  const [offerID, setOfferID] = useState(null);
  const [isClubViewOpen, setClubViewOpen] = useState(false);
  const [isArenaViewOpen, setArenaViewOpen] = useState(false);
  const [clubViewTitle, setClubViewTitle] = useState("Club/Small Theater View");
  const [arenaViewTitle, setArenaViewTitle] = useState(
    "Arena/Amphitheater View"
  );
  const [shouldRenderArenaView, setShouldRenderArenaView] = useState(true);
  const [shouldRenderClubView, setShouldRenderClubView] = useState(true);
  const [grossPotential, setGrossPotential] = useState(0);
  const [ticketFees, setTicketFees] = useState(0);
  const [viewMode, setViewMode] = useState("all");

  // Consolidated artist state
  const [artistState, dispatch] = useReducer(artistReducer, {
    headliner: null,
    support1: null,
    support2: null,
    offerID: null,
  });
  const EVENT_REQUIRED_PARAMS = useMemo(
    () => ({
      name: event?.data?.name,
      date: event?.data?.date,
      artists: event?.data?.artists ? [event.data.artists[0].id] : [0],
      state: event?.data?.state,
      age_requirement: event?.data?.age_requirement,
      abbr: event?.data?.abbr,
      description: event?.data?.description,
      door_time: event?.data?.door_time,
      opener_time: event?.data?.opener_time,
      support_time: event?.data?.support_time,
      headliner_time: event?.data?.headliner_time,
      curfew_time: event?.data?.curfew_time,
      offer_notes: event?.data?.offer_notes,
      confirmation_notes: event?.data?.confirmation_notes,
      venue_id: event?.data?.venue?.id,
      has_insurance: event?.data?.has_insurance,
      has_credit_card_fees: event?.data?.has_credit_card_fees,
      has_ticket_commission: event?.data?.has_ticket_commission,
      has_ascap: event?.data?.has_ascap,
      has_bmi: event?.data?.has_bmi,
      has_sesac: event?.data?.has_sesac,
      offer_has_rent: event?.data?.offer_has_rent,
      offer_has_comp_tax: event?.data?.offer_has_comp_tax,
      schedule: event?.data?.schedule,
    }),
    [event?.data]
  );

  const getPersonRolesForListSection = useCallback(
    (roles) => {
      return people?.data
        ?.map((person) => {
          if (roles.includes(person.role_name)) {
            return {
              header: person.role_name,
              value: person.first_name + " " + person.last_name,
            };
          }
          return null;
        })
        .filter((person) => person !== null);
    },
    [people?.data]
  );

  const handleError = useCallback((error, errorInfo) => {
    console.error("EventAdvance Error:", error, errorInfo);
    setValidationErrors((prev) => ({
      ...prev,
      boundary: error.message || "An unexpected error occurred",
    }));
  }, []);

  // Add logging to monitor state changes
  // Monitor advance data changes
  useEffect(() => {
    console.log("Current advance data:", advance.data);
  }, [advance.data]);

  const handleTableInputKeyDown = useCallback((e, index) => {
    if (e.key === "Enter") {
      e.preventDefault();
      e.target.blur();
    }
  }, []);
  const updateEventMutation = useMutation({
    mutationFn: async (data) => {
      return await updateEvent(id, data);
    },
    onSuccess: (data) => {
      queryClient.invalidateQueries(["event-detail", id]);
      queryClient.invalidateQueries(["variables-offer-pdf", id]);
      queryClient.invalidateQueries(["expenses-offer-pdf", id]);
      queryClient.invalidateQueries(["event-rollups", id]);
    },
    onMutate: async (newData) => {
      await queryClient.cancelQueries(["event-detail", id]);
      const previousEventData = queryClient.getQueryData(["event-detail", id]);
      if (previousEventData && newData.schedule !== undefined) {
        queryClient.setQueryData(["event-detail", id], {
          ...previousEventData,
          schedule: newData.schedule,
        });
      }
      return { previousEventData };
    },
    onError: (err, newData, context) => {
      queryClient.setQueryData(["event-detail", id], context.previousEventData);
    },
  });

  const updateAdvanceMutation = useMutation({
    mutationFn: async (data) => {
      console.log("Mutation executing with data:", data);
      return await updateEventAdvance(id, data);
    },
    onSuccess: (data) => {
      console.log("Mutation successful:", data);
      queryClient.invalidateQueries(["event-advance", id]);
    },
    onError: (error) => {
      console.error("Mutation failed:", error);
      setValidationErrors((prev) => ({
        ...prev,
        submit: error.message || "Failed to submit advance",
      }));
    },
  });

  const handleVendorUpdate = React.useCallback(
    (vendorType, field, value) => {
      if (!advance.data) return;

      const updatedData = {
        ...advance.data,
        [`vendor_${vendorType}_${field}`]: value,
      };
      updateAdvanceMutation.mutate(updatedData);
    },
    [advance.data, updateAdvanceMutation]
  );

  const handleUpdateSchedule = useCallback(
    async (accessor, value) => {
      try {
        // First update the event schedule
        const updatedSchedule = {
          ...event.data.schedule,
          [accessor]: value,
        };

        // Update the event with new schedule
        await updateEventMutation.mutateAsync({
          ...EVENT_REQUIRED_PARAMS,
          schedule: updatedSchedule,
        });

        // If we have advance data, update that too
        if (advance.data) {
          const updatedAdvanceData = {
            ...advance.data,
            event_id: id,
            schedule: updatedSchedule,
          };
          await updateAdvanceMutation.mutateAsync(updatedAdvanceData);
        }

        // Clear any validation errors for this field
        setValidationErrors((prev) => ({
          ...prev,
          [accessor]: null,
        }));
      } catch (error) {
        console.error("Failed to update schedule:", error);
        setValidationErrors((prev) => ({
          ...prev,
          [accessor]: error.message || "Failed to update schedule",
        }));
      }
    },
    [
      event.data.schedule,
      EVENT_REQUIRED_PARAMS,
      advance.data,
      id,
      updateEventMutation,
      updateAdvanceMutation,
    ]
  );

  const handleUpdateAdvanceData = useCallback(
    (field, value) => {
      if (!field) {
        console.warn("No field provided to handleUpdateAdvanceData");
        return;
      }

      const newData = {
        ...advance.data,
        [field]: value,
        event_id: id,
        headliner_value: event.data?.artists[0]?.id,
        venue_name: event.data?.venue?.name,
        venue_address: event.data?.venue?.address,
      };

      // Automatically update related fields if needed
      if (field === "headliner_time") {
        newData.headliner_time_value = parseTimeValue(value);
      }

      console.log(`Updating ${field}:`, {
        oldValue: advance.data?.[field],
        newValue: value,
      });

      updateAdvanceMutation.mutate(newData);
    },
    [advance.data, id, event.data, updateAdvanceMutation]
  );

  // Utility functions
  const transformContactData = (data, prefix) => ({
    [`${prefix}_name`]: data?.name || "",
    [`${prefix}_phone`]: data?.phone || "",
    [`${prefix}_cell`]: data?.cell || "",
    [`${prefix}_email`]: data?.email || "",
  });

  const transformScheduleData = (schedule) => {
    if (!schedule) return {};

    return Object.entries(schedule).reduce((acc, [key, value]) => {
      if (value && typeof value === "string") {
        try {
          const parsedTime = parseTimeValue(value);
          acc[key] = parsedTime ? format(parsedTime, "HH:mm:ss") : null;
        } catch (error) {
          console.error(`Error parsing time for ${key}:`, error);
          acc[key] = null;
        }
      }
      return acc;
    }, {});
  };

  const handleTableInputBlur = useCallback(
    (accessor, e, row) => {
      if (!accessor || !row?.editable) return;

      const value = e.target.textContent.trim();
      console.log("Processing table input blur:", { accessor, value, row });

      let newData = { ...advance.data };

      if (row.type === "contact") {
        const [_, contactType, field] = accessor.split("_");
        console.log("Handling contact update:", { contactType, field, value });
        if (
          contactType.startsWith("tour_") ||
          contactType.startsWith("prod_")
        ) {
          // Handle tour contacts directly
          newData[`${contactType}_${field}`] = value;
        } else {
          // Handle venue contacts with contact_ prefix
          newData[`contact_${contactType}_${field}`] = value;
        }
      } else if (row.type === "vendor") {
        const [_, vendorType, field] = accessor.split("_");
        const fieldName = `vendor_${vendorType}_${field}`;
        console.log("Handling vendor update:", {
          vendorType,
          field,
          value,
          fieldName,
        });
        newData[fieldName] = value;
      } else if (row.format === "time") {
        newData[accessor] = value;
        newData[`${accessor}_value`] = parseTimeValue(value);
      } else {
        newData[accessor] = value;
      }

      // Always include these required fields
      newData = {
        ...newData,
        event_id: id,
        headliner_value: event.data?.artists[0]?.id,
        venue_name: event.data?.venue?.name,
        venue_address: event.data?.venue?.address,
      };

      console.log("Table input update:", { accessor, value });

      // Trigger mutation to save changes
      updateAdvanceMutation.mutate(newData);
    },
    [advance.data, id, event.data, updateAdvanceMutation]
  );

  // Helper function to map table columns to schema paths
  const getSchemaPath = (header, field) => {
    const prefix = header.toLowerCase().replace(/\s+/g, "_");
    return `${prefix}_${field}`;
  };

  const createAdvanceMutation = useMutation({
    mutationFn: async (data) => {
      // Pre-populate required fields from event data
      const advanceData = {
        ...data,
        headliner_value: event.data.artists[0]?.id,
        headliner_time_value: event.data.headliner_time,
        venue_name: event.data.venue?.name,
        venue_address: event.data.venue?.address,
      };
      return await createEventAdvance(id, advanceData);
    },
    onSuccess: () => {
      queryClient.invalidateQueries(["event-advance", id]);
    },
    onError: (error) => {
      console.error("Failed to create advance:", error);
    },
  });
  const toggleTableSection = (sectionId) => {
    setActiveTableSection(activeTableSection === sectionId ? null : sectionId);
  };

  const toggleClubViewSection = () => {
    setClubViewOpen(!isClubViewOpen);
    if (!isClubViewOpen) {
      setArenaViewOpen(false);
    }
  };

  const toggleArenaViewSection = () => {
    setArenaViewOpen(!isArenaViewOpen);
    if (!isArenaViewOpen) {
      setClubViewOpen(false);
    }
  };

  const handleToggle = useCallback(() => {
    setViewMode(viewMode === "all" ? "selected" : "all");
  }, [viewMode]);

  const navigate = useNavigate();

  const createOfferMutation = useMutation({
    mutationFn: createOffer,
    onSuccess: (data) => {
      if (data?.id) {
        dispatch({ type: "SET_OFFER", payload: data.id });
        addEventToOfferMutation.mutate({
          event_id: event.data.id,
          offer_id: data.id,
        });
      }
    },
  });

  const addEventToOfferMutation = useMutation({
    mutationFn: (data) =>
      addEventToOffer(parseInt(data.event_id), data.offer_id),
    onSuccess: () => {
      if (offerID) {
        queryClient.invalidateQueries(["offer-detail", offerID]);
      }
    },
  });

  useEffect(() => {
    if (event && event.data) {
      setGrossPotential(
        event.data.manifests.reduce(
          (total, manifest) =>
            total + (manifest.price + manifest?.ticket_fees) * manifest.qty,
          0
        )
      );
      setTicketFees(
        event.data.manifests.reduce(
          (total, manifest) => total + manifest.qty * manifest?.ticket_fees,
          0
        )
      );
    }
  }, [event?.data]);

  // Debug logging for time values
  useEffect(() => {
    console.log("Advance Data Updated:", {
      displayTime: advance?.data?.headliner_time,
      databaseTime: advance?.data?.headliner_time_value,
    });
  }, [advance?.data?.headliner_time, advance?.data?.headliner_time_value]);

  // Monitor all advance.data changes
  useEffect(() => {
    console.log("advance.data updated:", advance.data);
  }, [advance.data]);

  //  // Cleanup effect
  //  useEffect(() => {
  //    return () => {
  //      updateAdvanceMutation.reset();
  //      updateEventMutation.reset();
  //      queryClient.removeQueries(["event-advance", id]);
  //      setValidationErrors({});
  //    };
  //  }, [id, updateAdvanceMutation, updateEventMutation, queryClient]);

  // Memoize artist lookups
  const headliner = useMemo(
    () =>
      event?.data?.artists?.find(
        (a) => a.name === event?.data?.offer_headliner
      ),
    [event?.data?.artists, event?.data?.offer_headliner]
  );

  const support1 = useMemo(
    () =>
      event?.data?.artists?.find(
        (a) => a.name === event?.data?.offer_support_1
      ),
    [event?.data?.artists, event?.data?.offer_support_1]
  );

  const support2 = useMemo(
    () =>
      event?.data?.artists?.find(
        (a) => a.name === event?.data?.offer_support_2
      ),
    [event?.data?.artists, event?.data?.offer_support_2]
  );

  useEffect(() => {
    if (!event?.data || !user?.data) return;

    // Set artists only once when data loads
    const headliner = event.data.artists.find(
      (a) => a.name === event.data.offer_headliner
    );
    const support1 = event.data.artists.find(
      (a) => a.name === event.data.offer_support_1
    );
    const support2 = event.data.artists.find(
      (a) => a.name === event.data.offer_support_2
    );

    dispatch({
      type: "SET_ARTISTS",
      payload: {
        headliner: headliner?.id || null,
        support1: support1?.id || null,
        support2: support2?.id || null,
      },
    });

    // Handle offer creation/update
    if (latestOffer && !artistState.offerID) {
      dispatch({ type: "SET_OFFER", payload: latestOffer.id });
    } else if (!artistState.offerID && event.data.offers.length === 0) {
      createOfferMutation.mutate({
        production_adv: "production@mammothlive.com",
        local_marketing: "",
        ticketing: "ticketing@mammothlive.com",
        tour_admat: "",
        tour_marketing: "",
        onsale_info: "",
        presale_info: "",
        contracts: "contracts@mammothlive.com",
        draft: false,
        name: "New Offer",
        created: new Date().toISOString(),
        modified: new Date().toISOString(),
        buyer_id: user.data.id,
        created_by_id: user.data.id,
        events: [event.data.id],
        active: true,
        closed: false,
      });
    }
  }, [event?.data, user?.data]);

  // Separate effect for artist selection
  useEffect(() => {
    if (!event?.data) return;

    const headliner = event.data.artists.find(
      (a) => a.name === event.data.offer_headliner
    );
    const support1 = event.data.artists.find(
      (a) => a.name === event.data.offer_support_1
    );
    const support2 = event.data.artists.find(
      (a) => a.name === event.data.offer_support_2
    );

    if (headliner?.id) setSelectedHeadliner(headliner.id);
    if (support1?.id) setSelectedSupport1(support1.id);
    if (support2?.id) setSelectedSupport2(support2.id);
  }, [
    event.data,
    user.data,
    selectedHeadliner,
    selectedSupport1,
    selectedSupport2,
    offerID,
    queryClient,
    createOfferMutation,
  ]);

  const submitAdvanceMutation = useMutation({
    mutationFn: async (data) => {
      console.log("Submitting advance data:", data);
      try {
        // Ensure we have the event ID
        if (!id) {
          throw new Error("Event ID is required");
        }

        // Ensure the data has the required fields
        const submissionData = {
          event_id: id,
          view: data.view || "club",
          schedule: event.data.schedule || {},
          ...data, // This contains all our form fields and existing data
        };

        // Pre-submission validation
        if (advanceSheetSchema) {
          await advanceSheetSchema.validate(submissionData, {
            abortEarly: false,
            strict: false,
            stripUnknown: true,
          });
        }

        // Clear any existing errors before submission
        clearValidationErrors(setValidationErrors);

        // Submit data
        const result = await updateEventAdvance(id, submissionData);

        if (!result || typeof result !== "object") {
          throw new Error("Invalid response from server");
        }

        return result;
      } catch (error) {
        console.error("Validation/submission error:", error);

        if (error.name === "ValidationError") {
          handleValidationError(error, setValidationErrors);
        } else {
          handleSubmissionError(error, setValidationErrors);
        }

        throw error;
      }
    },
    onSuccess: (data) => {
      console.log("Advance submitted successfully:", data);
      queryClient.invalidateQueries(["event-advance", id]);

      // Update view titles after successful submission
      if (data?.view === "club") {
        setClubViewTitle(`${event.data.name} / ${event.data.date} = Advance`);
        setShouldRenderArenaView(false);
      } else if (data?.view === "arena") {
        setArenaViewTitle(`${event.data.name} - Advance`);
        setShouldRenderClubView(false);
      }
    },
    onError: (error) => {
      console.error("Failed to submit advance:", error);
      // Show error in UI
      setValidationErrors((prev) => ({
        ...prev,
        submit: error.message || "Failed to submit advance",
      }));
    },
  });

  const validateAdvanceData = (data) => {
    try {
      console.log("Validating advance data:", data);
      const validationData = {
        ...advance.data,
        ...data,
      };
      advanceSheetSchema.validateSync(validationData, { abortEarly: false });
      console.log("Validation passed");
      return true;
    } catch (err) {
      console.log("Validation failed:", err);
      const errors = {};
      err.inner.forEach((error) => {
        console.log("Validation error:", error.path, error.message);
        errors[error.path] = error.message;
      });
      setValidationErrors(errors);
      return false;
    }
  };

  const handleSubmitAdvance = async (view) => {
    try {
      console.log(
        "Starting submission with current advance data:",
        advance.data
      );

      // Collect all form data
      const formData = {
        ...advance.data,
        event_id: id,
        view,
        headliner_value: event.data.artists[0]?.id,
        venue_name: event.data.venue?.name,
        venue_address: event.data.venue?.address,
        schedule: transformScheduleData(advance?.data?.schedule || {}),
        // Add any missing required fields
        production_requirements: advance?.data?.production_requirements || "",
        cash_needs: advance?.data?.cash_needs || "",
      };

      // Add all contact fields if they exist
      const contactTypes = [
        "gm",
        "marketing",
        "boxoffice",
        "production",
        "catering",
      ];
      const contactFields = ["name", "phone", "cell", "email"];

      contactTypes.forEach((type) => {
        contactFields.forEach((field) => {
          const key = `contact_${type}_${field}`;
          if (advance.data && advance.data[key]) {
            formData[key] = advance.data[key];
          }
        });
      });

      console.log("Built submission data:", formData);

      // Validate
      if (!validateAdvanceData(formData)) {
        console.warn("Validation failed:", validationErrors);
        return;
      }

      // Submit using mutation
      const result = await updateAdvanceMutation.mutateAsync(formData);
      console.log("Submission successful:", result);

      // Update view titles and visibility
      if (view === "club") {
        setClubViewTitle(`${event.data.name} / ${event.data.date} = Advance`);
        setShouldRenderArenaView(false);
      } else if (view === "arena") {
        setArenaViewTitle(`${event.data.name} - Advance`);
        setShouldRenderClubView(false);
      }

      // Show success message
      console.log("Advance submitted successfully");
    } catch (error) {
      console.error("Failed to submit advance:", error);
      setValidationErrors((prev) => ({
        ...prev,
        submit: error.message || "Failed to submit advance",
      }));
    }
  };

  // Handlers
  const handleEditButtonPress = useCallback(() => {
    navigate(`/events/advance/edit/${event.data.id}`);
  }, [navigate, event.data?.id]);

  const handleScheduleUpdate = useCallback(
    async (accessor, value) => {
      try {
        if (!accessor) return;

        const scheduleUpdate = {
          ...(advance.data?.schedule || {}),
          [accessor]: value,
        };

        const updatedData = {
          ...advance.data,
          schedule: scheduleUpdate,
        };

        await updateAdvanceMutation.mutateAsync({
          ...updatedData,
          event_id: id,
        });
      } catch (error) {
        console.error("Schedule update failed:", error);
        setValidationErrors((prev) => ({
          ...prev,
          [accessor]: error.message,
        }));
      }
    },
    [advance.data, id, updateAdvanceMutation]
  );

  const toggleTableSectionView = useCallback(
    (tableId) => {
      setOpenTable(openTable === tableId ? null : tableId);
    },
    [openTable]
  );

  if (
    event.isLoading ||
    variables.isLoading ||
    rollups.isLoading ||
    copros.isLoading ||
    people.isLoading ||
    !event.data
  ) {
    return (
      <div className="flex items-center justify-center p-4">
        <div className="animate-spin rounded-full h-8 w-8 border-b-2 border-gray-900 dark:border-white"></div>
        <span className="ml-2 dark:text-white">Loading Event Advance...</span>
      </div>
    );
  }

  return (
    <form
      onSubmit={(e) => {
        e.preventDefault();
        handleSubmitAdvance(e.target.dataset.view);
      }}
    >
      <div className="eventAdvanceWrapper border-t-[1px] border-[#595959] pt-[1rem]">
        {Object.keys(validationErrors).length > 0 && (
          <div className="flex flex-col gap-4 mb-12 mt-12">
            <div className="flex justify-between items-start">
              <div>
                <strong className="font-bold">
                  Please fix the following errors:
                </strong>
                <ul className="mt-2 list-disc list-inside">
                  {Object.entries(validationErrors).map(([field, error]) => (
                    <li key={field} className="text-sm">
                      <span className="font-semibold">
                        {field.replace(/([A-Z])/g, " $1").toLowerCase()}
                      </span>
                      : {error}
                    </li>
                  ))}
                </ul>
              </div>
              <button
                onClick={() => clearValidationErrors(setValidationErrors)}
                className="text-red-700 hover:text-red-900"
              >
                <span className="text-xl">&times;</span>
              </button>
            </div>
          </div>
        )}
        <div className="flex flex-row justify-between items-center mb-4">
          <h2 id="section2" className="text-xl font-bold dark:text-white">
            Event Advance
          </h2>
          {/* <Button onClick={handleEditButtonPress}>Edit Advance</Button> */}
        </div>

        {/* Club/Small Theater View Accordion */}
        {shouldRenderClubView && (
          <div>
            <button
              className={`flex justify-between items-center w-full px-4 py-2 bg-[green] text-left text-lg font-semibold cursor-pointer rounded-[10px] ${styles.chevronAccordionDiv}`}
              onClick={toggleClubViewSection}
            >
              <span className="m-auto dark:text-white">{clubViewTitle}</span>
              {isClubViewOpen ? (
                <ChevronDownIcon className={styles.chevronAccordionDown} />
              ) : (
                <ChevronUpIcon className={styles.chevronAccordionUp} />
              )}
            </button>

            {isClubViewOpen && (
              <div className="mt-4">
                <div className="grid grid-cols-12 grid-rows-48 gap-4">
                  {/* Event Info Table */}
                  <div
                    className={`col-start-1 col-span-3 row-start-1 row-span-2 ${styles.chevronAccordionDiv}`}
                    onClick={() => toggleTableSection("eventInfo")}
                  >
                    <ItemTable
                      header={{ value: "DEVENT INFO" }}
                      validationErrors={validationErrors}
                      setValidationErrors={setValidationErrors}
                      getSchemaPath={getSchemaPath}
                      rows={[
                        {
                          header: "HEADLINER",
                          value: event.data.artists[0]?.name || "No Headliner",
                          editable: false,
                        },
                        {
                          header: "SUPPORT 1",
                          value: (
                            <select
                              value={selectedSupport1 || ""}
                              onChange={(e) => {
                                const value = e.target.value;
                                setSelectedSupport1(value);
                                updateAdvanceMutation.mutate({
                                  ...advance.data,
                                  support_1_id: value ? parseInt(value) : null,
                                  support_1_value: value || null,
                                });
                              }}
                              className="w-full bg-transparent border-none focus:outline-none"
                            >
                              <option value="">None</option>
                              {event.data.artists
                                .filter(
                                  (artist) => artist.id !== selectedHeadliner
                                )
                                .map((artist) => (
                                  <option key={artist.id} value={artist.id}>
                                    {artist.name}
                                  </option>
                                ))}
                            </select>
                          ),
                          editable: true,
                        },
                        {
                          header: "SUPPORT 2",
                          value: (
                            <select
                              value={selectedSupport2 || ""}
                              onChange={(e) => {
                                const value = e.target.value;
                                setSelectedSupport2(value);
                                updateAdvanceMutation.mutate({
                                  ...advance.data,
                                  support_2_id: value ? parseInt(value) : null,
                                  support_2_value: value || null,
                                });
                              }}
                              className="w-full bg-transparent border-none focus:outline-none"
                            >
                              <option value="">None</option>
                              {event.data.artists
                                .filter(
                                  (artist) =>
                                    artist.id !== selectedHeadliner &&
                                    artist.id !== selectedSupport1
                                )
                                .map((artist) => (
                                  <option key={artist.id} value={artist.id}>
                                    {artist.name}
                                  </option>
                                ))}
                            </select>
                          ),
                          editable: true,
                        },
                        {
                          header: "DAY/DATE",
                          value: new Date(event.data.date).toDateString(),
                          editable: false,
                        },
                        {
                          header: "HEADLINER TIME",
                          value:
                            advance.data?.headliner_time ||
                            event.data.headliner_time ||
                            "",
                          editable: true,
                          accessor: "headliner_time",
                          valueAccessor: "headliner_time_value",
                          format: "time",
                        },
                        {
                          header: "VENUE",
                          value: event.data.venue?.name || "No Venue",
                          editable: false,
                        },
                        {
                          header: "ADDRESS",
                          value: event.data.venue?.address || "No Address",
                          editable: false,
                        },
                        {
                          header: "CITY/STATE/ZIP",
                          value: `${event.data.venue.city}, ${event.data.venue.state} ${event.data.venue.zipcode}`,
                          editable: false,
                        },
                        {
                          header: "MAIN PHONE",
                          value:
                            advance.data?.main_phone_value ||
                            event.data.venue?.phone ||
                            "",
                          editable: true,
                          accessor: "main_phone_value",
                        },
                        {
                          header: "CAPACITY",
                          value: event.data.venue.capacity,
                          editable: true,
                        },
                        {
                          header: "WEBSITE",
                          value:
                            advance.data?.venue_website ||
                            event.data.venue.website ||
                            "",
                          editable: true,
                          accessor: "venue_website",
                        },
                        {
                          header: "VENUE TYPE",
                          value: advance.data?.venue_type || "",
                          editable: true,
                          accessor: "venue_type",
                        },
                      ]}
                      columns={[
                        { header: "", accessor: "header" },
                        { header: "", accessor: "value" },
                      ]}
                      color="red"
                      showTable={showTable2}
                      showChevrons={false}
                      onBlur={handleTableInputBlur}
                      onKeyDown={handleTableInputKeyDown}
                      onUpdate={handleUpdateAdvanceData}
                    />
                  </div>
                  {/* Venue Contacts */}
                  <div
                    className={`col-start-4 col-span-9 row-start-1 row-span-1 ${styles.chevronAccordionDiv}`}
                    onClick={() => toggleTableSectionView("venueContacts")}
                  >
                    <ItemTable
                      header={{ value: "VENUE CONTACTS" }}
                      validationErrors={validationErrors}
                      rows={[
                        {
                          header: "GM",
                          name: advance.data?.contact_gm_name || "",
                          phone: advance.data?.contact_gm_phone || "",
                          cell: advance.data?.contact_gm_cell || "",
                          email: advance.data?.contact_gm_email || "",
                          editable: true,
                          type: "contact",
                          contactType: "gm",
                          fields: ["name", "phone", "cell", "email"],
                        },
                        {
                          header: "MARKETING",
                          name: advance.data?.contact_marketing_name || "",
                          phone: advance.data?.contact_marketing_phone || "",
                          cell: advance.data?.contact_marketing_cell || "",
                          email: advance.data?.contact_marketing_email || "",
                          editable: true,
                          type: "contact",
                          contactType: "marketing",
                          fields: ["name", "phone", "cell", "email"],
                        },
                        {
                          header: "BOX OFFICE",
                          name: advance.data?.contact_boxoffice_name || "",
                          phone: advance.data?.contact_boxoffice_phone || "",
                          cell: advance.data?.contact_boxoffice_cell || "",
                          email: advance.data?.contact_boxoffice_email || "",
                          editable: true,
                          type: "contact",
                          contactType: "boxoffice",
                          fields: ["name", "phone", "cell", "email"],
                        },
                        {
                          header: "PRODUCTION",
                          name: advance.data?.contact_production_name || "",
                          phone: advance.data?.contact_production_phone || "",
                          cell: advance.data?.contact_production_cell || "",
                          email: advance.data?.contact_production_email || "",
                          editable: true,
                          type: "contact",
                          contactType: "production",
                          fields: ["name", "phone", "cell", "email"],
                        },
                        {
                          header: "CATERING",
                          name: advance.data?.contact_catering_name || "",
                          phone: advance.data?.contact_catering_phone || "",
                          cell: advance.data?.contact_catering_cell || "",
                          email: advance.data?.contact_catering_email || "",
                          editable: true,
                          type: "contact",
                          contactType: "catering",
                          fields: ["name", "phone", "cell", "email"],
                        },
                      ]}
                      columns={[
                        { header: "", accessor: "header" },
                        { header: "Name", accessor: "name", type: "text" },
                        { header: "Phone", accessor: "phone", type: "phone" },
                        { header: "Cell", accessor: "cell", type: "phone" },
                        { header: "Email", accessor: "email", type: "email" },
                      ]}
                      color="red"
                      showTable={showTable2}
                      showChevrons={false}
                    />
                  </div>

                  {/* Tour Contacts */}
                  <div
                    className={`col-start-4 col-span-9 row-start-2 row-span-1  ${styles.chevronAccordionDiv}`}
                    onClick={() => toggleTableSectionView("tourContacts")}
                  >
                    <ItemTable
                      header={{ value: "TOUR CONTACTS" }}
                      validationErrors={validationErrors}
                      setValidationErrors={setValidationErrors}
                      getSchemaPath={getSchemaPath}
                      rows={[
                        {
                          header: "TOUR MGR",
                          name: advance.data?.tour_mgr_name || "",
                          phone: advance.data?.tour_mgr_phone || "",
                          cell: advance.data?.tour_mgr_cell || "",
                          email: advance.data?.tour_mgr_email || "",
                          editable: true,
                          type: "contact",
                          contactType: "tour_mgr",
                        },
                        {
                          header: "PRODUCTION MGR",
                          name: advance.data?.prod_mgr_name || "",
                          phone: advance.data?.prod_mgr_phone || "",
                          cell: advance.data?.prod_mgr_cell || "",
                          email: advance.data?.prod_mgr_email || "",
                          editable: true,
                          type: "contact",
                          contactType: "prod_mgr",
                        },
                        {
                          header: "TOUR ACCOUNTANT",
                          name: advance.data?.tour_acct_name || "",
                          phone: advance.data?.tour_acct_phone || "",
                          cell: advance.data?.tour_acct_cell || "",
                          email: advance.data?.tour_acct_email || "",
                          editable: true,
                          type: "contact",
                          contactType: "tour_acct",
                        },
                      ]}
                      columns={[
                        { header: "", accessor: "header" },
                        { header: "Name", accessor: "name", type: "text" },
                        { header: "Phone", accessor: "phone", type: "text" },
                        { header: "Cell", accessor: "cell", type: "text" },
                        { header: "Email", accessor: "email", type: "text" },
                        {
                          header: "Company",
                          accessor: "company",
                          type: "text",
                        },
                        {
                          header: "Address",
                          accessor: "address",
                          type: "text",
                        },
                        {
                          header: "Website",
                          accessor: "website",
                          type: "text",
                        },
                        {
                          header: "Emergency",
                          accessor: "emergency",
                          type: "text",
                        },
                        { header: "Role", accessor: "role", type: "text" },
                        {
                          header: "Department",
                          accessor: "department",
                          type: "text",
                        },
                      ]}
                      color="red"
                      showTable={showTable2}
                      showChevrons={false}
                      onBlur={handleTableInputBlur}
                      onKeyDown={handleTableInputKeyDown}
                      onUpdate={handleUpdateAdvanceData}
                    />
                  </div>

                  {/* Schedule */}
                  <div
                    className={`col-start-1 col-span-3 row-start-3 row-span-2 ${styles.chevronAccordionDiv}`}
                    onClick={() => toggleTableSectionView("schedule")}
                  >
                    <ItemTable
                      header={{ value: "SCHEDULE" }}
                      validationErrors={validationErrors}
                      rows={[
                        {
                          header: "VENUE ACCESS",
                          accessor: "schedule_venue_access",
                          value: advance.data?.schedule_venue_access || "",
                          format: "time",
                          editable: true,
                          type: "schedule",
                        },
                        {
                          header: "BREAKFAST",
                          accessor: "schedule_breakfast_time",
                          value: advance?.data?.schedule_breakfast_time || "",
                          format: "time",
                          editable: true,
                          type: "schedule",
                        },
                        {
                          header: "SECURITY MEETING",
                          accessor: "schedule_security_meeting",
                          value: advance?.data?.schedule_security_meeting || "",
                          format: "time",
                          editable: true,
                          type: "schedule",
                        },
                        {
                          header: "DINNER",
                          accessor: "schedule_dinner_time",
                          value: advance?.data?.schedule_dinner_time || "",
                          format: "time",
                          editable: true,
                          type: "schedule",
                        },
                        {
                          header: "DOORS",
                          accessor: "schedule_doors",
                          value: advance?.data?.schedule_doors || "",
                          format: "time",
                          editable: true,
                          type: "schedule",
                        },
                        {
                          header: "SET 1",
                          accessor: "schedule_set_1_time",
                          value: advance?.data?.schedule_set_1_time || "",
                          format: "time",
                          editable: true,
                          type: "schedule",
                        },
                        {
                          header: "INTERMISSION",
                          accessor: "schedule_intermission",
                          value: advance?.data?.schedule_intermission || "",
                          format: "time",
                          editable: true,
                          type: "schedule",
                        },
                        {
                          header: "SET 2",
                          accessor: "schedule_set_2_time",
                          value: advance?.data?.schedule_set_2_time || "",
                          format: "time",
                          editable: true,
                          type: "schedule",
                        },
                        {
                          header: "CALL BACK",
                          accessor: "schedule_call_back",
                          value: advance?.data?.schedule_call_back || "",
                          format: "time",
                          editable: true,
                          type: "schedule",
                        },
                        {
                          header: "CURFEW",
                          accessor: "schedule_curfew",
                          value: advance?.data?.schedule_curfew || "",
                          format: "time",
                          editable: true,
                          type: "schedule",
                        },
                        {
                          header: "LOAD OUT START",
                          accessor: "schedule_load_out_start",
                          value: advance?.data?.schedule_load_out_start || "",
                          format: "time",
                          editable: true,
                          type: "schedule",
                        },
                        {
                          header: "LOAD OUT END",
                          accessor: "schedule_load_out_end",
                          value: advance?.data?.schedule_load_out_end || "",
                          format: "time",
                          editable: true,
                          type: "schedule",
                        },
                        {
                          header: "REHEARSAL START",
                          accessor: "schedule_rehearsal_start",
                          value: advance?.data?.schedule_rehearsal_start || "",
                          format: "time",
                          editable: true,
                          type: "schedule",
                        },
                        {
                          header: "REHEARSAL END",
                          accessor: "schedule_rehearsal_end",
                          value: advance?.data?.schedule_rehearsal_end || "",
                          format: "time",
                          editable: true,
                          type: "schedule",
                        },
                        {
                          header: "VIP ENTRY",
                          accessor: "schedule_vip_entry",
                          value: advance?.data?.schedule_vip_entry || "",
                          format: "time",
                          editable: true,
                          type: "schedule",
                        },
                        {
                          header: "MEDIA ARRIVAL",
                          accessor: "schedule_media_arrival",
                          value: advance?.data?.schedule_media_arrival || "",
                          format: "time",
                          editable: true,
                          type: "schedule",
                        },
                        {
                          header: "MERCHANDISE SETUP",
                          accessor: "schedule_merchandise_setup",
                          value:
                            advance?.data?.schedule_merchandise_setup || "",
                          format: "time",
                          editable: true,
                          type: "schedule",
                        },
                        {
                          header: "CATERING SETUP",
                          accessor: "schedule_catering_setup",
                          value: advance?.data?.schedule_catering_setup || "",
                          format: "time",
                          editable: true,
                          type: "schedule",
                        },
                        {
                          header: "SECURITY BRIEFING",
                          accessor: "schedule_security_briefing",
                          value:
                            advance?.data?.schedule_security_briefing || "",
                          format: "time",
                          editable: true,
                          type: "schedule",
                        },
                        {
                          header: "STAFF ARRIVAL",
                          accessor: "schedule_staff_arrival",
                          value: advance?.data?.schedule_staff_arrival || "",
                          format: "time",
                          editable: true,
                          type: "schedule",
                        },
                        {
                          header: "PRODUCTION MEETING",
                          accessor: "schedule_production_meeting",
                          value:
                            advance?.data?.schedule_production_meeting || "",
                          format: "time",
                          editable: true,
                          type: "schedule",
                        },
                        {
                          header: "MEDIA CALL",
                          accessor: "schedule_media_call",
                          value: advance?.data?.schedule_media_call || "",
                          format: "time",
                          editable: true,
                          type: "schedule",
                        },
                        {
                          header: "MEET & GREET",
                          accessor: "schedule_meet_greet",
                          value: advance?.data?.schedule_meet_greet || "",
                          format: "time",
                          editable: true,
                          type: "schedule",
                        },
                        {
                          header: "PHOTO CALL",
                          accessor: "schedule_photo_call",
                          value: advance?.data?.schedule_photo_call || "",
                          format: "time",
                          editable: true,
                          type: "schedule",
                        },
                      ]}
                      columns={[
                        { header: "", accessor: "header" },
                        { header: "", accessor: "" },
                      ]}
                      color="red"
                      onUpdate={handleUpdateSchedule}
                      showTable={showTable2}
                      showChevrons={false}
                    />
                  </div>

                  {/* Outside Vendors */}
                  <div
                    className={`col-start-4 col-span-9 row-start-3 row-span-1  ${styles.chevronAccordionDiv}`}
                    onClick={() => toggleTableSectionView("outsideVendors")}
                  >
                    <ItemTable
                      header={{ value: "OUTSIDE VENDORS" }}
                      validationErrors={validationErrors}
                      rows={[
                        {
                          header: "SOUND",
                          name: advance.data?.vendor_sound_name || "",
                          contact: advance.data?.vendor_sound_contact || "",
                          phone: advance.data?.vendor_sound_phone || "",
                          cell: advance.data?.vendor_sound_cell || "",
                          email: advance.data?.vendor_sound_email || "",
                          company: advance.data?.vendor_sound_company || "",
                          address: advance.data?.vendor_sound_address || "",
                          website: advance.data?.vendor_sound_website || "",
                          emergency: advance.data?.vendor_sound_emergency || "",
                          role: advance.data?.vendor_sound_role || "",
                          department:
                            advance.data?.vendor_sound_department || "",
                          editable: true,
                          type: "vendor",
                          vendorType: "sound",
                          fields: [
                            "name",
                            "contact",
                            "phone",
                            "cell",
                            "email",
                            "company",
                            "address",
                            "website",
                            "emergency",
                            "role",
                            "department",
                          ],
                        },
                        {
                          header: "LIGHTS",
                          name: advance.data?.vendor_lights_name || "",
                          contact: advance.data?.vendor_lights_contact || "",
                          phone: advance.data?.vendor_lights_phone || "",
                          email: advance.data?.vendor_lights_email || "",
                          company: advance.data?.vendor_lights_company || "",
                          address: advance.data?.vendor_lights_address || "",
                          website: advance.data?.vendor_lights_website || "",
                          emergency:
                            advance.data?.vendor_lights_emergency || "",
                          role: advance.data?.vendor_lights_role || "",
                          department:
                            advance.data?.vendor_lights_department || "",
                          editable: true,
                          type: "vendor",
                          vendorType: "lights",
                          fields: [
                            "name",
                            "contact",
                            "phone",
                            "cell",
                            "email",
                            "company",
                            "address",
                            "website",
                            "emergency",
                            "role",
                            "department",
                          ],
                        },
                        {
                          header: "BACKLINE",
                          name: advance.data?.vendor_backline_name || "",
                          contact: advance.data?.vendor_backline_contact || "",
                          phone: advance.data?.vendor_backline_phone || "",
                          email: advance.data?.vendor_backline_email || "",
                          company: advance.data?.vendor_backline_company || "",
                          address: advance.data?.vendor_backline_address || "",
                          website: advance.data?.vendor_backline_website || "",
                          emergency:
                            advance.data?.vendor_backline_emergency || "",
                          role: advance.data?.vendor_backline_role || "",
                          department:
                            advance.data?.vendor_backline_department || "",
                          editable: true,
                          type: "vendor",
                          vendorType: "backline",
                          fields: [
                            "name",
                            "contact",
                            "phone",
                            "cell",
                            "email",
                            "company",
                            "address",
                            "website",
                            "emergency",
                            "role",
                            "department",
                          ],
                        },
                        {
                          header: "VIDEO",
                          name: advance.data?.vendor_video_name || "",
                          contact: advance.data?.vendor_video_contact || "",
                          phone: advance.data?.vendor_video_phone || "",
                          email: advance.data?.vendor_video_email || "",
                          company: advance.data?.vendor_video_company || "",
                          address: advance.data?.vendor_video_address || "",
                          website: advance.data?.vendor_video_website || "",
                          emergency: advance.data?.vendor_video_emergency || "",
                          role: advance.data?.vendor_video_role || "",
                          department:
                            advance.data?.vendor_video_department || "",
                          editable: true,
                          type: "vendor",
                          vendorType: "video",
                          fields: [
                            "name",
                            "contact",
                            "phone",
                            "email",
                            "company",
                            "address",
                            "website",
                            "emergency",
                            "role",
                            "department",
                          ],
                        },
                        {
                          header: "RIGGING",
                          name: advance.data?.vendor_rigging_name || "",
                          contact: advance.data?.vendor_rigging_contact || "",
                          phone: advance.data?.vendor_rigging_phone || "",
                          email: advance.data?.vendor_rigging_email || "",
                          company: advance.data?.vendor_rigging_company || "",
                          address: advance.data?.vendor_rigging_address || "",
                          website: advance.data?.vendor_rigging_website || "",
                          emergency:
                            advance.data?.vendor_rigging_emergency || "",
                          role: advance.data?.vendor_rigging_role || "",
                          department:
                            advance.data?.vendor_rigging_department || "",
                          editable: true,
                          type: "vendor",
                          vendorType: "rigging",
                          fields: [
                            "name",
                            "contact",
                            "phone",
                            "email",
                            "company",
                            "address",
                            "website",
                            "emergency",
                            "role",
                            "department",
                          ],
                        },
                        {
                          header: "POWER",
                          name: advance.data?.vendor_power_name || "",
                          contact: advance.data?.vendor_power_contact || "",
                          phone: advance.data?.vendor_power_phone || "",
                          email: advance.data?.vendor_power_email || "",
                          company: advance.data?.vendor_power_company || "",
                          address: advance.data?.vendor_power_address || "",
                          website: advance.data?.vendor_power_website || "",
                          emergency: advance.data?.vendor_power_emergency || "",
                          role: advance.data?.vendor_power_role || "",
                          department:
                            advance.data?.vendor_power_department || "",
                          editable: true,
                          type: "vendor",
                          vendorType: "power",
                          fields: [
                            "name",
                            "contact",
                            "phone",
                            "email",
                            "company",
                            "address",
                            "website",
                            "emergency",
                            "role",
                            "department",
                          ],
                        },
                        {
                          header: "BARRICADE",
                          name: advance.data?.vendor_barricade_name || "",
                          contact: advance.data?.vendor_barricade_contact || "",
                          phone: advance.data?.vendor_barricade_phone || "",
                          email: advance.data?.vendor_barricade_email || "",
                          company: advance.data?.vendor_barricade_company || "",
                          address: advance.data?.vendor_barricade_address || "",
                          website: advance.data?.vendor_barricade_website || "",
                          emergency:
                            advance.data?.vendor_barricade_emergency || "",
                          role: advance.data?.vendor_barricade_role || "",
                          department:
                            advance.data?.vendor_barricade_department || "",
                          editable: true,
                          type: "vendor",
                          vendorType: "barricade",
                          fields: [
                            "name",
                            "contact",
                            "phone",
                            "email",
                            "company",
                            "address",
                            "website",
                            "emergency",
                            "role",
                            "department",
                          ],
                        },
                        {
                          header: "FORKLIFT",
                          name: advance.data?.vendor_forklift_name || "",
                          contact: advance.data?.vendor_forklift_contact || "",
                          phone: advance.data?.vendor_forklift_phone || "",
                          email: advance.data?.vendor_forklift_email || "",
                          company: advance.data?.vendor_forklift_company || "",
                          address: advance.data?.vendor_forklift_address || "",
                          website: advance.data?.vendor_forklift_website || "",
                          emergency:
                            advance.data?.vendor_forklift_emergency || "",
                          role: advance.data?.vendor_forklift_role || "",
                          department:
                            advance.data?.vendor_forklift_department || "",
                          editable: true,
                          type: "vendor",
                          vendorType: "forklift",
                          fields: [
                            "name",
                            "contact",
                            "phone",
                            "email",
                            "company",
                            "address",
                            "website",
                            "emergency",
                            "role",
                            "department",
                          ],
                        },
                        {
                          header: "GENERATOR",
                          name: advance.data?.vendor_generator_name || "",
                          contact: advance.data?.vendor_generator_contact || "",
                          phone: advance.data?.vendor_generator_phone || "",
                          email: advance.data?.vendor_generator_email || "",
                          company: advance.data?.vendor_generator_company || "",
                          address: advance.data?.vendor_generator_address || "",
                          website: advance.data?.vendor_generator_website || "",
                          emergency:
                            advance.data?.vendor_generator_emergency || "",
                          role: advance.data?.vendor_generator_role || "",
                          department:
                            advance.data?.vendor_generator_department || "",
                          editable: true,
                          type: "vendor",
                          vendorType: "generator",
                          fields: [
                            "name",
                            "contact",
                            "phone",
                            "email",
                            "company",
                            "address",
                            "website",
                            "emergency",
                            "role",
                            "department",
                          ],
                        },
                        {
                          header: "TENT",
                          name: advance.data?.vendor_tent_name || "",
                          contact: advance.data?.vendor_tent_contact || "",
                          phone: advance.data?.vendor_tent_phone || "",
                          email: advance.data?.vendor_tent_email || "",
                          company: advance.data?.vendor_tent_company || "",
                          address: advance.data?.vendor_tent_address || "",
                          website: advance.data?.vendor_tent_website || "",
                          emergency: advance.data?.vendor_tent_emergency || "",
                          role: advance.data?.vendor_tent_role || "",
                          department:
                            advance.data?.vendor_tent_department || "",
                          editable: true,
                          type: "vendor",
                          vendorType: "tent",
                          fields: [
                            "name",
                            "contact",
                            "phone",
                            "email",
                            "company",
                            "address",
                            "website",
                            "emergency",
                            "role",
                            "department",
                          ],
                        },
                        {
                          header: "SECURITY",
                          name: advance.data?.vendor_security_name || "",
                          contact: advance.data?.vendor_security_contact || "",
                          phone: advance.data?.vendor_security_phone || "",
                          email: advance.data?.vendor_security_email || "",
                          company: advance.data?.vendor_security_company || "",
                          address: advance.data?.vendor_security_address || "",
                          website: advance.data?.vendor_security_website || "",
                          emergency:
                            advance.data?.vendor_security_emergency || "",
                          role: advance.data?.vendor_security_role || "",
                          department:
                            advance.data?.vendor_security_department || "",
                          editable: true,
                          type: "vendor",
                          vendorType: "security",
                          fields: [
                            "name",
                            "contact",
                            "phone",
                            "email",
                            "company",
                            "address",
                            "website",
                            "emergency",
                            "role",
                            "department",
                          ],
                        },
                      ]}
                      columns={[
                        { header: "", accessor: "header" },
                        { header: "Name", accessor: "name", type: "text" },
                        { header: "Phone", accessor: "phone", type: "text" },
                        { header: "Cell", accessor: "cell", type: "text" },
                        { header: "Email", accessor: "email", type: "text" },
                        {
                          header: "Company",
                          accessor: "company",
                          type: "text",
                        },
                        {
                          header: "Address",
                          accessor: "address",
                          type: "text",
                        },
                        {
                          header: "Website",
                          accessor: "website",
                          type: "text",
                        },
                        {
                          header: "Emergency",
                          accessor: "emergency",
                          type: "text",
                        },
                        { header: "Role", accessor: "role", type: "text" },
                        {
                          header: "Department",
                          accessor: "department",
                          type: "text",
                        },
                      ]}
                      color="red"
                      columnWidths={["150px", "300px", "200px"]}
                      showTable={showTable2}
                      showChevrons={false}
                    />
                  </div>

                  {/* Promoters */}
                  <div
                    className={`col-start-4 col-span-9 row-start-4 row-span-1  ${styles.chevronAccordionDiv}`}
                    onClick={() => toggleTableSectionView("promoters")}
                  >
                    <ItemTable
                      header={{ value: "PROMOTERS" }}
                      validationErrors={validationErrors}
                      rows={[
                        {
                          header: "BUYER",
                          value: variables.data.buyer_name,
                          editable: false,
                        },
                        {
                          header: "PRODUCTION ADV",
                          value:
                            event.data.offers[0].production_adv ||
                            "production@mammothlive.com",
                          editable: false,
                        },
                        {
                          header: "TICKETING",
                          value: event.data.offers[0].ticketing,
                          editable: false,
                        },
                        {
                          header: "REP/SETTLEMENT",
                          value: "-",
                          editable: true,
                        },
                        {
                          header: "TOUR MARKETING",
                          value: event.data.offers[0].tour_marketing,
                          editable: true,
                        },
                        {
                          header: "LOCAL MARKETING",
                          value: event.data.offers[0].local_marketing,
                          editable: true,
                        },
                      ]}
                      columns={[
                        { header: "", accessor: "header" },
                        { header: "Name", accessor: "value" },
                        { header: "Phone", accessor: "" },
                        { header: "Cell", accessor: "" },
                        { header: "Email", accessor: "" },
                      ]}
                      color="red"
                      showTable={showTable2}
                      showChevrons={false}
                    />
                  </div>
                </div>
                <div className="mt-[1rem]">
                  <Button
                    type="submit"
                    data-view="club"
                    disabled={submitAdvanceMutation.isLoading}
                  >
                    {submitAdvanceMutation.isLoading
                      ? "Submitting..."
                      : "Submit Advance"}
                  </Button>
                </div>
              </div>
            )}
          </div>
        )}

        {/* Arena/Ampitheater View Accordion Below Here*/}
        {shouldRenderArenaView && (
          <div>
            <button
              className={`lex justify-between items-center w-full px-4 py-2 bg-[blue] text-left text-lg font-semibold cursor-pointer rounded-[10px] ${styles.chevronAccordionDiv}`}
              onClick={toggleArenaViewSection}
            >
              <span className="m-auto dark:text-white">{arenaViewTitle}</span>
              {isArenaViewOpen ? (
                <ChevronDownIcon className={styles.chevronAccordionDown} />
              ) : (
                <ChevronUpIcon className={styles.chevronAccordionUp} />
              )}
            </button>

            {isArenaViewOpen && (
              <div className="grid grid-cols-12 grid-rows-48 gap-4 mt-[1rem]">
                <div
                  className={`col-start-1 col-span-3 row-start-1 row-span-2 ${styles.chevronAccordionDiv}`}
                  onClick={() => toggleTableSectionView("eventInfo")}
                >
                  <ItemTable
                    header={{ value: "EVENT INFO" }}
                    rows={[
                      {
                        header: "HEADLINER",
                        value: event.data.offer_headliner,
                        editable: false,
                      },
                      {
                        header: "SUPPORT 1",
                        value: event.data.offer_support_1,
                        editable: false,
                      },
                      {
                        header: "SUPPORT 2",
                        value: event.data.offer_support_2,
                        editable: true,
                      },
                      {
                        header: "DAY/DATE",
                        value: new Date(event.data.date).toDateString(),
                        editable: false,
                      },
                      {
                        header: "HEADLINER TIME",
                        value: event.data.headliner_time,
                        editable: true,
                        format: "time",
                      },
                      {
                        header: "VENUE",
                        value: event.data.venue.name,
                        editable: false,
                      },
                      {
                        header: "ADDRESS",
                        value: event.data.venue.address,
                        editable: false,
                      },
                      {
                        header: "CITY/STATE/ZIP",
                        value: `${event.data.venue.city}, ${event.data.venue.state} ${event.data.venue.zipcode}`,
                        editable: false,
                      },
                      {
                        header: "MAIN PHONE",
                        value: event.data.venue.phone,
                        editable: true,
                      },
                      {
                        header: "CAPACITY",
                        value: event.data.venue.capacity,
                        editable: true,
                      },
                      {
                        header: "WEBSITE",
                        value: event.data.venue.website,
                        editable: true,
                      },

                      { header: "VENUE TYPE", value: "", editable: true },
                    ]}
                    columns={[
                      { header: "", accessor: "header" },
                      { header: "", accessor: "value" },
                    ]}
                    color="red"
                    // showTable={openTable === "eventInfo"}
                    showTable={showTable2}
                    showChevrons={false}
                  />
                </div>

                <div
                  className={`col-start-4 col-span-9 row-start-1 row-span-1 ${styles.chevronAccordionDiv}`}
                  onClick={() => toggleTableSectionView("venueContacts")}
                >
                  <ItemTable
                    header={{ value: "VENUE CONTACTS" }}
                    rows={[
                      {
                        header: "GM",
                        name: advance.data?.contact_gm_name || "",
                        phone: advance.data?.contact_gm_phone || "",
                        cell: advance.data?.contact_gm_cell || "",
                        email: advance.data?.contact_gm_email || "",
                        editable: true,
                        type: "contact",
                        contactType: "gm",
                      },
                      {
                        header: "MARKETING",
                        name: advance.data?.contact_marketing_name || "",
                        phone: advance.data?.contact_marketing_phone || "",
                        cell: advance.data?.contact_marketing_cell || "",
                        email: advance.data?.contact_marketing_email || "",
                        editable: true,
                        type: "contact",
                        contactType: "marketing",
                      },
                      {
                        header: "BOX OFFICE",
                        name: advance.data?.contact_boxoffice_name || "",
                        phone: advance.data?.contact_boxoffice_phone || "",
                        cell: advance.data?.contact_boxoffice_cell || "",
                        email: advance.data?.contact_boxoffice_email || "",
                        editable: true,
                        type: "contact",
                        contactType: "boxoffice",
                      },
                      {
                        header: "PRODUCTION",
                        name: advance.data?.contact_production_name || "",
                        phone: advance.data?.contact_production_phone || "",
                        cell: advance.data?.contact_production_cell || "",
                        email: advance.data?.contact_production_email || "",
                        editable: true,
                        type: "contact",
                        contactType: "production",
                      },
                      {
                        header: "CATERING",
                        name: advance.data?.contact_catering_name || "",
                        phone: advance.data?.contact_catering_phone || "",
                        cell: advance.data?.contact_catering_cell || "",
                        email: advance.data?.contact_catering_email || "",
                        editable: true,
                        type: "contact",
                        contactType: "catering",
                      },
                    ]}
                    columns={[
                      { header: "", accessor: "header" },
                      { header: "Name", accessor: "name" },
                      { header: "Phone", accessor: "phone" },
                      { header: "Cell", accessor: "cell" },
                      { header: "Email", accessor: "email" },
                      { header: "Company", accessor: "company" },
                      { header: "Address", accessor: "address" },
                      { header: "Website", accessor: "website" },
                      { header: "Emergency", accessor: "emergency" },
                      { header: "Role", accessor: "role" },
                      { header: "Department", accessor: "department" },
                    ]}
                    color="red"
                    // showTable={openTable === "venueContacts"}
                    showTable={showTable2}
                    showChevrons={false}
                  />
                </div>

                <div
                  className={`col-start-4 col-span-9 row-start-2 row-span-1  ${styles.chevronAccordionDiv}`}
                  onClick={() => toggleTableSectionView("tourContacts")}
                >
                  <ItemTable
                    header={{ value: "TOUR CONTACTS" }}
                    validationErrors={validationErrors}
                    rows={[
                      {
                        header: "TOUR MGR",
                        name: advance?.data?.tour_mgr_name || "",
                        phone: advance?.data?.tour_mgr_phone || "",
                        cell: advance?.data?.tour_mgr_cell || "",
                        email: advance?.data?.tour_mgr_email || "",
                        company: advance.data?.tour_mgr_company || "",
                        address: advance.data?.tour_mgr_address || "",
                        website: advance.data?.tour_mgr_website || "",
                        emergency: advance.data?.tour_mgr_emergency || "",
                        role: advance.data?.tour_mgr_role || "",
                        department: advance.data?.tour_mgr_department || "",
                        editable: true,
                        type: "contact",
                        contactType: "tour_mgr",
                        fields: [
                          "name",
                          "phone",
                          "cell",
                          "email",
                          "company",
                          "address",
                          "website",
                          "emergency",
                          "role",
                          "department",
                        ],
                      },
                      {
                        header: "PRODUCTION MGR",
                        name: advance?.data?.prod_mgr_name || "",
                        phone: advance?.data?.prod_mgr_phone || "",
                        cell: advance?.data?.prod_mgr_cell || "",
                        email: advance?.data?.prod_mgr_email || "",
                        company: advance.data?.prod_mgr_company || "",
                        address: advance.data?.prod_mgr_address || "",
                        website: advance.data?.prod_mgr_website || "",
                        emergency: advance.data?.prod_mgr_emergency || "",
                        role: advance.data?.prod_mgr_role || "",
                        department: advance.data?.prod_mgr_department || "",
                        editable: true,
                        type: "contact",
                        contactType: "prod_mgr",
                      },
                      {
                        header: "TOUR ACCOUNTANT",
                        name: advance?.data?.tour_acct_name || "",
                        phone: advance?.data?.tour_acct_phone || "",
                        cell: advance?.data?.tour_acct_cell || "",
                        email: advance?.data?.tour_acct_email || "",
                        company: advance.data?.tour_acct_company || "",
                        address: advance.data?.tour_acct_address || "",
                        website: advance.data?.tour_acct_website || "",
                        emergency: advance.data?.tour_acct_emergency || "",
                        role: advance.data?.tour_acct_role || "",
                        department: advance.data?.tour_acct_department || "",
                        editable: true,
                        type: "contact",
                        contactType: "tour_acct",
                      },
                      {
                        header: "STAGE MANAGER",
                        name: advance.data?.stage_mgr_name || "",
                        phone: advance.data?.stage_mgr_phone || "",
                        cell: advance.data?.stage_mgr_cell || "",
                        email: advance.data?.stage_mgr_email || "",
                        company: advance.data?.stage_mgr_company || "",
                        address: advance.data?.stage_mgr_address || "",
                        website: advance.data?.stage_mgr_website || "",
                        emergency: advance.data?.stage_mgr_emergency || "",
                        role: advance.data?.stage_mgr_role || "",
                        department: advance.data?.stage_mgr_department || "",
                        editable: true,
                        type: "contact",
                        contactType: "stage_mgr",
                        fields: [
                          "name",
                          "phone",
                          "cell",
                          "email",
                          "company",
                          "address",
                          "website",
                          "emergency",
                          "role",
                          "department",
                        ],
                      },
                    ]}
                    columns={[
                      { header: "", accessor: "header" },
                      { header: "Name", accessor: "name", type: "text" },
                      { header: "Phone", accessor: "phone", type: "text" },
                      { header: "Cell", accessor: "cell", type: "text" },
                      { header: "Email", accessor: "email", type: "text" },
                      { header: "Company", accessor: "company", type: "text" },
                      { header: "Address", accessor: "address", type: "text" },
                      { header: "Website", accessor: "website", type: "text" },
                      {
                        header: "Emergency",
                        accessor: "emergency",
                        type: "text",
                      },
                      { header: "Role", accessor: "role", type: "text" },
                      {
                        header: "Department",
                        accessor: "department",
                        type: "text",
                      },
                    ]}
                    // rows={[
                    //   { header: "TOUR MGR", value: "" },
                    //   { header: "PRODUCTION MGR", value: "" },
                    //   { header: "TOUR ACCOUNTANT", value: "" },
                    //   { header: "", value: "" },
                    //   { header: "", value: "" },
                    // ]}
                    // rows={handleGetPersonRoles[
                    //   "Tour Manager",
                    //   "Production Manager",
                    //   "Tour Accountant",
                    //   "Tour Accountant",
                    //   "Tour Accountant",
                    // ]}
                    color="red"
                    // showTable={openTable === "tourContacts"}
                    showTable={showTable2}
                    showChevrons={false}
                  />
                </div>

                <div
                  className={`col-start-1 col-span-3 row-start-3 row-span-2 ${styles.chevronAccordionDiv}`}
                  onClick={() => toggleTableSectionView("schedule")}
                >
                  <ItemTable
                    header={{ value: "SCHEDULE" }}
                    rows={[
                      {
                        header: "VENUE ACCESS",
                        accessor: "schedule_venue_access",
                        value: advance.data?.schedule_venue_access || "",
                        format: "time",
                        editable: true,
                        type: "schedule",
                      },
                      {
                        header: "BREAKFAST",
                        accessor: "schedule_breakfast_time",
                        value: advance.data?.schedule_breakfast_time || "",
                        format: "time",
                        editable: true,
                        type: "schedule",
                      },
                      {
                        header: "LOCAL LOAD IN",
                        accessor: "schedule_local_load_in",
                        value: advance.data?.schedule_local_load_in || "",
                        format: "time",
                        editable: true,
                        type: "schedule",
                      },
                      {
                        header: "TOUR LOAD IN",
                        accessor: "schedule_tour_load_in",
                        value: advance.data?.schedule_tour_load_in || "",
                        format: "time",
                        editable: true,
                        type: "schedule",
                      },
                      {
                        header: "LUNCH",
                        accessor: "schedule_lunch_time",
                        value: advance.data?.schedule_lunch_time || "",
                        format: "time",
                        editable: true,
                        type: "schedule",
                      },
                      {
                        header: "SOUND CHECK",
                        accessor: "schedule_sound_check",
                        value: advance.data?.schedule_sound_check || "",
                        format: "time",
                        editable: true,
                        type: "schedule",
                      },
                      {
                        header: "SECURITY MEETING",
                        accessor: "security_meeting",
                        value: event.data.schedule?.security_meeting,
                        format: "time",
                        editable: true,
                      },
                      {
                        header: "DINNER",
                        accessor: "dinner",
                        value: event.data.schedule?.dinner,
                        format: "time",
                        editable: true,
                        isEditableHeader: true,
                      },
                      {
                        header: "DOORS",
                        accessor: "doors",
                        value: event.data.schedule?.doors,
                        format: "time",
                        editable: true,
                        isEditableHeader: true,
                      },
                      {
                        header: "SET 1",
                        accessor: "set_1",
                        value: event.data.schedule?.set_1,
                        format: "time",
                        editable: true,
                      },
                      {
                        header: "INTERMISSION",
                        accessor: "intermission",
                        value: event.data.schedule?.intermission,
                        format: "time",
                        editable: true,
                        isEditableHeader: true,
                      },
                      {
                        header: "SET 2",
                        accessor: "set_2",
                        value: event.data.schedule?.set_2,
                        format: "time",
                        editable: true,
                        isEditableHeader: true,
                      },
                      {
                        header: "CALL BACK",
                        accessor: "call_back",
                        value: event.data.schedule?.call_back,
                        format: "time",
                        editable: true,
                        isEditableHeader: true,
                      },
                      {
                        header: "CURFEW",
                        accessor: "curfew",
                        value: event.data.schedule?.curfew,
                        format: "time",
                        editable: true,
                        isEditableHeader: true,
                      },
                    ]}
                    columns={[
                      { header: "", accessor: "header" },
                      { header: "", accessor: "" },
                    ]}
                    color="red"
                    onUpdate={handleUpdateSchedule}
                    // showTable={openTable === "schedule"}
                    showTable={showTable2}
                    showChevrons={false}
                  />
                </div>

                <div
                  className={`col-start-4 col-span-9 row-start-3 row-span-1  ${styles.chevronAccordionDiv}`}
                  onClick={() => toggleTableSectionView("outsideVendors")}
                >
                  <ItemTable
                    header={{ value: "OUTSIDE VENDORS" }}
                    rows={[
                      {
                        header: "RACK/STACKS",
                        value: advance.data?.equip_rack_stacks || "",
                        editable: true,
                      },
                      {
                        header: "CONSOLES",
                        value: advance.data?.equip_consoles || "",
                        editable: true,
                      },
                      {
                        header: "LIGHTS",
                        value: advance.data?.equip_lights || "",
                        editable: true,
                      },
                      {
                        header: "VIDEO",
                        value: advance.data?.equip_video || "",
                        editable: true,
                      },
                      {
                        header: "SPOTLIGHTS",
                        value: advance.data?.equip_spotlights || "",
                        editable: true,
                      },
                      {
                        header: "CLEAR COMM",
                        value: advance.data?.equip_clear_comm || "",
                        editable: true,
                      },
                      {
                        header: "CURTAINS",
                        value: advance.data?.equip_curtains || "",
                        editable: true,
                      },
                      {
                        header: "BIKE RACK",
                        value: advance.data?.equip_bike_rack || "",
                        editable: true,
                      },
                      {
                        header: "BARRICADE",
                        value: advance.data?.equip_barricade || "",
                        editable: true,
                      },
                      {
                        header: "FORK LIFT",
                        value: advance.data?.equip_fork_lift || "",
                        editable: true,
                      },
                      {
                        header: "RISERS",
                        value: advance.data?.equip_risers || "",
                        editable: true,
                      },
                      {
                        header: "STROBES",
                        value: advance.data?.equip_strobes || "",
                        editable: true,
                      },
                      {
                        header: "HAZE",
                        value: advance.data?.equip_haze || "",
                        editable: true,
                      },
                    ]}
                    columns={[
                      { header: "", accessor: "header" },
                      { header: "Name", accessor: "name" },
                      { header: "Phone", accessor: "phone" },
                      { header: "Cell", accessor: "cell" },
                      { header: "Email", accessor: "email" },
                    ]}
                    // rows={handleGetPersonRoles(["Sound", "Lights"])}
                    color="red"
                    columnWidths={["150px", "300px", "200px"]}
                    // showTable={openTable === "outsideVendors"}
                    showTable={showTable2}
                    showChevrons={false}
                  />
                </div>

                <div
                  className={`col-start-4 col-span-9 row-start-4 row-span-1  ${styles.chevronAccordionDiv}`}
                  onClick={() => toggleTableSectionView("promoters")}
                >
                  <ItemTable
                    header={{ value: "PROMOTERS" }}
                    rows={[
                      {
                        header: "BUYER",
                        value: variables.data.buyer_name,
                        editable: false,
                      },
                      {
                        header: "PRODUCTION ADV",
                        value:
                          event.data.offers[0].production_adv ||
                          "production@mammothlive.com",
                        editable: false,
                      },
                      {
                        header: "TICKETING",
                        value: event.data.offers[0].ticketing,
                        editable: false,
                      },
                      {
                        header: "REP/SETTLEMENT",
                        value: "-",
                        editable: true,
                      },
                      {
                        header: "TOUR MARKETING",
                        value: event.data.offers[0].tour_marketing,
                        editable: true,
                      },
                      {
                        header: "LOCAL MARKETING",
                        value: event.data.offers[0].local_marketing,
                        editable: true,
                      },
                    ]}
                    columns={[
                      { header: "", accessor: "header" },
                      { header: "Name", accessor: "value" },
                      { header: "Phone", accessor: "" },
                      { header: "Cell", accessor: "" },
                      { header: "Email", accessor: "" },
                    ]}
                    color="red"
                    // showTable={openTable === "promoters"}
                    showTable={showTable2}
                    showChevrons={false}
                  />
                </div>

                <div
                  className={`col-start-1 col-span-6 row-start-8 row-span-7  ${styles.chevronAccordionDiv}`}
                  onClick={() => toggleTableSectionView("laborCalls")}
                >
                  <ItemTable
                    header={{ value: "LABOR CALLS" }}
                    rows={[
                      {
                        header: "STEWARD",
                        value: advance.data?.labor_call_steward || "",
                        editable: true,
                        accessor: "labor_call_steward",
                      },
                      {
                        header: "HANDS",
                        value: advance.data?.labor_call_hands || "",
                        editable: true,
                        accessor: "labor_call_hands",
                      },
                      {
                        header: "LOADERS",
                        value: advance.data?.labor_call_loaders || "",
                        editable: true,
                      },
                      {
                        header: "RIGGERS (UP)",
                        value: advance.data?.labor_call_riggers_up || "",
                        editable: true,
                      },
                      {
                        header: "RIGGERS (DOWN)",
                        value: advance.data?.labor_call_riggers_down || "",
                        editable: true,
                      },
                      {
                        header: "ELECTRICIAN",
                        value: advance.data?.labor_call_electrician || "",
                        editable: true,
                      },
                      {
                        header: "WARDROBE",
                        value: advance.data?.labor_call_wardrobe || "",
                        editable: true,
                      },
                      {
                        header: "SPOT OPS",
                        value: advance.data?.labor_call_spot_ops || "",
                        editable: true,
                      },
                      {
                        header: "HOUSE LIGHTS",
                        value: advance.data?.labor_call_house_lights || "",
                        editable: true,
                      },
                      {
                        header: "FLY MAN",
                        value: advance.data?.labor_call_fly_man || "",
                        editable: true,
                      },
                    ]}
                    columns={[
                      { header: "", accessor: "header" },
                      { header: "LOAD IN:", accessor: "name" },
                      { header: "SHOW CALL:", accessor: "phone" },
                      { header: "LOAD OUT:", accessor: "cell" },
                    ]}
                    // rows={handleGetPersonRoles([
                    // rows={["Tour Manager", "Production Manager", "Tour Accountant"]}
                    color="red"
                    // showTable={openTable === "laborCalls"}
                    showTable={showTable2}
                    showChevrons={false}
                  />
                </div>

                <div
                  className={`col-start-7 col-span-3 row-start-8 row-span-7  ${styles.chevronAccordionDiv}`}
                  onClick={() => toggleTableSectionView("laborRules1")}
                >
                  <ItemTable
                    header={{ value: "LABOR TYPE/RULES" }}
                    rows={[
                      {
                        header: "CREW TYPE",
                        value: advance.data?.labor_rule_crew_type || "",
                        editable: true,
                        accessor: "labor_rule_crew_type",
                      },
                      {
                        header: "STEWARD NAME",
                        value: advance.data?.labor_rule_steward_name || "",
                        editable: true,
                        accessor: "labor_rule_steward_name",
                      },
                      {
                        header: "MIN IN",
                        value: advance.data?.labor_rule_min_in || "",
                        editable: true,
                      },
                      {
                        header: "MIN SHOW",
                        value: advance.data?.labor_rule_min_show || "",
                        editable: true,
                      },
                      {
                        header: "MIN OUT",
                        value: advance.data?.labor_rule_min_out || "",
                        editable: true,
                      },
                      {
                        header: "CONVERT",
                        value: advance.data?.labor_rule_convert || "",
                        editable: true,
                      },
                      {
                        header: "FEED BY",
                        value: advance.data?.labor_rule_feed_by || "",
                        editable: true,
                      },
                      {
                        header: "MIN CREW",
                        value: advance.data?.labor_rule_min_crew || "",
                        editable: true,
                      },
                      {
                        header: "OVERTIME",
                        value: advance.data?.labor_rule_overtime || "",
                        editable: true,
                      },
                      {
                        header: "DARK",
                        value: advance.data?.labor_rule_dark || "",
                        editable: true,
                      },
                    ]}
                    columns={[
                      { header: "", accessor: "header" },
                      { header: "", accessor: "" },
                    ]}
                    // rows={handleGetPersonRoles([
                    //   "Tour Manager",
                    //   "Production Manager",
                    //   "Tour Accountant",
                    // ])}
                    color="red"
                    // showTable={openTable === "laborRules1"}
                    showTable={showTable2}
                    showChevrons={false}
                  />
                </div>

                <div
                  className={`col-start-10 col-span-3 row-start-8 row-span-7  ${styles.chevronAccordionDiv}`}
                  onClick={() => toggleTableSectionView("laborRules2")}
                >
                  <ItemTable
                    header={{ value: "LABOR TYPE/RULES" }}
                    rows={[
                      { header: "CREW TYPE", value: "", editable: true },
                      { header: "STEWARD NAME", value: "", editable: true },
                      { header: "MIN IN", value: "", editable: true },
                      { header: "MIN SHOW", value: "", editable: true },
                      { header: "MIN OUT", value: "", editable: true },
                      { header: "CONVERT", value: "", editable: true },
                      { header: "FEED BY", value: "", editable: true },
                      { header: "MIN CREW", value: "", editable: true },
                      { header: "OVERTIME", value: "", editable: true },
                      { header: "DARK", value: "", editable: true },
                    ]}
                    columns={[
                      { header: "", accessor: "header" },
                      { header: "", accessor: "" },
                    ]}
                    // rows={handleGetPersonRoles([
                    //   "Tour Manager",
                    //   "Production Manager",
                    //   "Tour Accountant",
                    // ])}
                    color="red"
                    // showTable={openTable === "laborRules2"}
                    showTable={showTable2}
                    showChevrons={false}
                  />
                </div>

                <div
                  className={`col-start-1 col-span-4 row-start-8 row-span-3  ${styles.chevronAccordionDiv}`}
                  onClick={() =>
                    toggleTableSectionView("productionRequirements")
                  }
                >
                  <ItemTable
                    header={{ value: "PRODUCTION REQUIREMENTS" }}
                    rows={[
                      {
                        header: "SOUND",
                        name: advance.data?.vendor_sound_name || "",
                        contact: advance.data?.vendor_sound_contact || "",
                        phone: advance.data?.vendor_sound_phone || "",
                        cell: advance.data?.vendor_sound_cell || "",
                        email: advance.data?.vendor_sound_email || "",
                        company: advance.data?.vendor_sound_company || "",
                        address: advance.data?.vendor_sound_address || "",
                        website: advance.data?.vendor_sound_website || "",
                        emergency: advance.data?.vendor_sound_emergency || "",
                        role: advance.data?.vendor_sound_role || "",
                        department: advance.data?.vendor_sound_department || "",
                        editable: true,
                        type: "vendor",
                        vendorType: "sound",
                        fields: [
                          "name",
                          "contact",
                          "phone",
                          "cell",
                          "email",
                          "company",
                          "address",
                          "website",
                          "emergency",
                          "role",
                          "department",
                        ],
                      },
                    ]}
                    columns={[
                      { header: "", accessor: "header" },
                      { header: "", accessor: "" },
                    ]}
                    // rows={handleGetPersonRoles([
                    //   "Tour Manager",
                    //   "Production Manager",
                    //   "Tour Accountant",
                    // ])}
                    color="red"
                    // showTable={openTable === "tourContacts"}
                    showTable={showTable2}
                    showChevrons={false}
                  />
                </div>

                <div
                  className={`col-start-5 col-span-4 row-start-8 row-span-3 ${styles.chevronAccordionDiv}`}
                  onClick={() => toggleTableSectionView("venueTechStageInfo")}
                >
                  <ItemTable
                    header={{ value: "VENUE TECH/STAGE INFO" }}
                    rows={[
                      {
                        header: "POWER #1",
                        value: advance.data?.tech_power_one || "",
                        editable: true,
                        accessor: "tech_power_one",
                      },
                      {
                        header: "POWER #2",
                        value: advance.data?.tech_power_two || "",
                        editable: true,
                        accessor: "tech_power_two",
                      },
                      {
                        header: "SHORE POWER",
                        value: advance.data?.tech_shore_power || "",
                        editable: true,
                        accessor: "tech_shore_power",
                      },
                      {
                        header: "STAGE SIZE",
                        value: advance.data?.tech_stage_dimensions || "",
                        editable: true,
                        accessor: "tech_stage_dimensions",
                      },
                      {
                        header: "STAGE LEFT WING",
                        value: advance.data?.tech_stage_left_wing || "",
                        editable: true,
                        accessor: "tech_stage_left_wing",
                      },
                      {
                        header: "STAGE RIGHT WING",
                        value: advance.data?.tech_stage_right_wing || "",
                        editable: true,
                        accessor: "tech_stage_right_wing",
                      },
                      {
                        header: "MIX AREA",
                        value: advance.data?.tech_mix_area || "",
                        editable: true,
                        accessor: "tech_mix_area",
                      },
                    ]}
                    columns={[
                      { header: "", accessor: "header" },
                      { header: "", accessor: "" },
                    ]}
                    // rows={handleGetPersonRoles([
                    //   "Tour Manager",
                    //   "Production Manager",
                    //   "Tour Accountant",
                    // ])}
                    color="red"
                    // showTable={openTable === "venuTechStageInfo"}
                    showTable={showTable2}
                    showChevrons={false}
                  />
                </div>

                <div
                  className={`col-start-9 col-span-4 row-start-8 row-span-1  ${styles.chevronAccordionDiv}`}
                  onClick={() => toggleTableSectionView("cateringInfo")}
                >
                  <ItemTable
                    header={{ value: "CATERING INFO" }}
                    rows={[
                      {
                        header: "BREAKFAST",
                        value: advance.data?.breakfast_info || "",
                        editable: true,
                      },
                      {
                        header: "LUNCH",
                        value: advance.data?.lunch_info || "",
                        editable: true,
                      },
                      {
                        header: "DRESSING ROOMS",
                        value: advance.data?.dressing_room_catering || "",
                        editable: true,
                      },
                      {
                        header: "DINNER",
                        value: advance.data?.dinner_info || "",
                        editable: true,
                      },
                    ]}
                    columns={[
                      { header: "", accessor: "header" },
                      { header: "", accessor: "" },
                    ]}
                    // rows={handleGetPersonRoles([
                    //   "Tour Manager",
                    //   "Production Manager",
                    //   "Tour Accountant",
                    // ])}
                    color="red"
                    // showTable={openTable === "cateringinfo"}
                    showTable={showTable2}
                    showChevrons={false}
                  />
                </div>

                <div
                  className={`col-start-9 col-span-4 row-start- row-span-7  ${styles.chevronAccordionDiv}`}
                  onClick={() => toggleTableSectionView("phonesInternet")}
                >
                  <ItemTable
                    header={{ value: "PHONES/INTERNET" }}
                    rows={[
                      {
                        header: "PHONE NEEDS",
                        value: advance.data?.comm_phone_needs || "",
                        editable: true,
                        accessor: "comm_phone_needs",
                      },
                      {
                        header: "INTERNET",
                        value: advance.data?.comm_internet || "",
                        editable: true,
                        accessor: "comm_internet",
                      },
                    ]}
                    columns={[
                      { header: "", accessor: "header", editable: true },
                      { header: "", accessor: "", editable: true },
                    ]}
                    // rows={handleGetPersonRoles([
                    //   "Tour Manager",
                    //   "Production Manager",
                    //   "Tour Accountant",
                    // ])}
                    color="red"
                    // showTable={openTable === "phonesInternet"}
                    showTable={showTable2}
                    showChevrons={false}
                  />
                </div>

                <div
                  className={`col-start-9 col-span-4 row-start-8 row-span-7  ${styles.chevronAccordionDiv}`}
                  onClick={() => toggleTableSectionView("dressingRoomNeeds")}
                >
                  <ItemTable
                    header={{ value: "DRESSING ROOM NEEDS" }}
                    rows={[
                      {
                        header: "ROOM #1",
                        value: advance.data?.room_one || "",
                        editable: true,
                      },
                      {
                        header: "ROOM #2",
                        value: advance.data?.room_two || "",
                        editable: true,
                      },
                      {
                        header: "ROOM #3",
                        value: advance.data?.room_three || "",
                        editable: true,
                      },
                      {
                        header: "FURNITURE",
                        value: advance.data?.furniture || "",
                        editable: true,
                      },
                    ]}
                    columns={[
                      { header: "", accessor: "header" },
                      { header: "", accessor: "" },
                    ]}
                    // rows={handleGetPersonRoles([
                    //   "Tour Manager",
                    //   "Production Manager",
                    //   "Tour Accountant",
                    // ])}
                    color="red"
                    // showTable={openTable === "dressingRoomNeeds"}
                    showTable={showTable2}
                    showChevrons={false}
                  />
                </div>

                <div
                  className={`col-start-1 col-span-4 row-start-8 row-span-7  ${styles.chevronAccordionDiv}`}
                  onClick={() => toggleTableSectionView("parking")}
                >
                  <ItemTable
                    header={{ value: "PARKING" }}
                    rows={[
                      {
                        header: "TRUCKS",
                        value: advance.data?.parking_truck || "",
                        editable: true,
                        accessor: "parking_truck",
                      },
                      {
                        header: "BUSES",
                        value: advance.data?.parking_bus || "",
                        editable: true,
                        accessor: "parking_bus",
                      },
                      {
                        header: "VANS",
                        value: advance.data?.parking_van || "",
                        editable: true,
                        accessor: "parking_van",
                      },
                      {
                        header: "CARS",
                        value: advance.data?.parking_car || "",
                        editable: true,
                        accessor: "parking_car",
                      },
                    ]}
                    columns={[
                      { header: "", accessor: "header" },
                      { header: "", accessor: "" },
                    ]}
                    // rows={handleGetPersonRoles([
                    //   "Tour Manager",
                    //   "Production Manager",
                    //   "Tour Accountant",
                    // ])}
                    color="red"
                    // showTable={openTable === "parking"}
                    showTable={showTable2}
                    showChevrons={false}
                  />
                </div>

                <div
                  className={`col-start-5 col-span-4 row-start-8 row-span-7  ${styles.chevronAccordionDiv}`}
                  onClick={() => toggleTableSectionView("towelsShowersLaundry")}
                >
                  <ItemTable
                    header={{ value: "TOWELS/SHOWERS/LAUNDRY" }}
                    rows={[
                      {
                        header: "LAUNDRY ON-SITE",
                        value: advance.data?.laundry_onsite ? "Yes" : "No",
                        editable: true,
                      },
                      {
                        header: "SHOWERS ON-SITE",
                        value: advance.data?.showers_onsite ? "Yes" : "No",
                        editable: true,
                      },
                      {
                        header: "BATH TOWELS",
                        value: (advance.data?.bath_towels || 0).toString(),
                        editable: true,
                        accessor: "bath_towels",
                        format: "number",
                      },
                      {
                        header: "HAND TOWELS",
                        value: (advance.data?.hand_towels || 0).toString(),
                        editable: true,
                        accessor: "hand_towels",
                        format: "number",
                      },
                    ]}
                    columns={[
                      { header: "", accessor: "header" },
                      { header: "", accessor: "" },
                    ]}
                    // rows={handleGetPersonRoles([
                    //   "Tour Manager",
                    //   "Production Manager",
                    //   "Tour Accountant",
                    // ])}
                    color="red"
                    // showTable={openTable === "towelsShowersLaundry"}
                    showTable={showTable2}
                    showChevrons={false}
                  />
                </div>

                <div
                  className={`col-start-9 col-span-4 row-start-8 row-span-7  ${styles.chevronAccordionDiv}`}
                  onClick={() => toggleTableSectionView("estimates")}
                >
                  <ItemTable
                    header={{ value: "ESTIMATES" }}
                    rows={[
                      {
                        header: "CATERING",
                        value: parseFloat(
                          advance.data?.catering_estimate ?? 0
                        ).toFixed(2),
                        editable: true,
                      },
                      {
                        header: "HANDS",
                        value: parseFloat(
                          advance.data?.hands_estimate ?? 0
                        ).toFixed(2),
                        editable: true,
                      },
                      {
                        header: "PRODUCTION",
                        value: parseFloat(
                          advance.data?.production_estimate ?? 0
                        ).toFixed(2),
                        editable: true,
                      },
                    ]}
                    columns={[
                      { header: "", accessor: "header" },
                      { header: "", accessor: "" },
                    ]}
                    // rows={handleGetPersonRoles([
                    //   "Tour Manager",
                    //   "Production Manager",
                    //   "Tour Accountant",
                    // ])}
                    color="red"
                    // showTable={openTable === "estimates"}
                    showTable={showTable2}
                    showChevrons={false}
                  />
                </div>

                <div
                  className={`col-start-1 col-span-6 row-start-8 row-span-7  ${styles.chevronAccordionDiv}`}
                  onClick={() => toggleTableSectionView("notes")}
                >
                  <ItemTable
                    header={{ value: "NOTES" }}
                    rows={[
                      {
                        header: "LOAD IN NOTES",
                        value: advance.data?.notes_load_in || "",
                        editable: true,
                        accessor: "notes_load_in",
                      },
                      {
                        header: "CATERING NOTES",
                        value: advance.data?.catering_notes || "",
                        editable: true,
                        accessor: "catering_notes",
                      },
                      {
                        header: "HOTEL NOTES",
                        value: advance.data?.hotel_notes || "",
                        editable: true,
                        accessor: "hotel_notes",
                      },
                      {
                        header: "PARKING NOTES",
                        value: advance.data?.parking_notes || "",
                        editable: true,
                        accessor: "parking_notes",
                      },
                      {
                        header: "MERCH NOTES",
                        value: advance.data?.merch_notes || "",
                        editable: true,
                        accessor: "merch_notes",
                      },
                      {
                        header: "GENERAL NOTES",
                        value: advance.data?.general_notes || "",
                        editable: true,
                        accessor: "general_notes",
                      },
                      {
                        header: "SECURITY NOTES",
                        value: advance.data?.security_notes || "",
                        editable: true,
                        accessor: "security_notes",
                      },
                      {
                        header: "VENUE NOTES",
                        value: advance.data?.venue_notes || "",
                        editable: true,
                        accessor: "venue_notes",
                      },
                      {
                        header: "TECHNICAL NOTES",
                        value: advance.data?.technical_notes || "",
                        editable: true,
                        accessor: "technical_notes",
                      },
                      {
                        header: "SCHEDULE NOTES",
                        value: advance.data?.schedule_notes || "",
                        editable: true,
                        accessor: "schedule_notes",
                      },
                      {
                        header: "GUEST LIST NOTES",
                        value: advance.data?.guest_list_notes || "",
                        editable: true,
                        accessor: "guest_list_notes",
                      },
                    ]}
                    columns={[
                      { header: "", accessor: "header" },
                      { header: "", accessor: "value" },
                    ]}
                    showTable={true}
                    showChevrons={false}
                    onUpdate={(accessor, value) =>
                      console.log(`Updated ${accessor}: ${value}`)
                    }
                    color="red"
                  />
                </div>

                <div
                  className={`col-start-7 col-span-6 row-start-8 row-span-7  ${styles.chevronAccordionDiv}`}
                  onClick={() => toggleTableSectionView("cashNeeds")}
                >
                  <ItemTable
                    header={{ value: "CASH NEEDS" }}
                    rows={[
                      {
                        header: "CASH NEEDS",
                        value: advance.data?.cash_needs || "",
                        editable: true,
                        accessor: "cash_needs",
                      },
                      {
                        header: "SETTLEMENT NOTES",
                        value: advance.data?.settlement_notes || "",
                        editable: true,
                        accessor: "settlement_notes",
                      },
                    ]}
                    columns={[
                      { header: "", accessor: "header" },
                      { header: "", accessor: "" },
                    ]}
                    // rows={handleGetPersonRoles([
                    //   "Tour Manager",
                    //   "Production Manager",
                    //   "Tour Accountant",
                    // ])}
                    color="red"
                    // showTable={openTable === "cashNeeds"}
                    showTable={showTable2}
                    showChevrons={false}
                  />
                </div>
                <div className="mt-[1rem]">
                  <Button
                    type="submit"
                    data-view="arena"
                    disabled={submitAdvanceMutation.isLoading}
                  >
                    {submitAdvanceMutation.isLoading
                      ? "Submitting..."
                      : "Submit Advance"}
                  </Button>
                </div>
              </div>
            )}
          </div>
        )}
      </div>
    </form>
  );
}; // End of EventAdvance component

export default EventAdvance;
