import React, { useState, useEffect } from "react";
import { useMutation, useQueryClient, useQuery } from "@tanstack/react-query";
import { createTask, updateTask, getUsers, getTasks } from "@/queries/tasks";

export const TaskForm = ({ existingTask, onClose }) => {
  const queryClient = useQueryClient();
  const { data: users = [] } = useQuery({
    queryKey: ["users"],
    queryFn: getUsers,
    staleTime: 30000, // Cache for 30 seconds
  });

  const { data: allTasks = [] } = useQuery({
    queryKey: ["tasks"],
    queryFn: getTasks,
    staleTime: 30000,
  });

  const [formData, setFormData] = useState({
    description: existingTask?.description || "",
    assigned_to_id: existingTask?.assigned_to_id || "",
    due_date: existingTask?.due_date
      ? new Date(existingTask.due_date).toISOString().split("T")[0]
      : "",
    parent_task_id: existingTask?.parent_task_id || "",
    status: existingTask?.status || "PENDING",
    priority: existingTask?.priority || "MEDIUM",
  });

  // Add these new states for autocomplete
  const [searchTerm, setSearchTerm] = useState("");
  const [isDropdownOpen, setIsDropdownOpen] = useState(false);
  const [filteredUsers, setFilteredUsers] = useState([]);

  // Update filtered users when search term changes
  useEffect(() => {
    if (!searchTerm.trim()) {
      setFilteredUsers(users);
      return;
    }

    const searchTermLower = searchTerm.toLowerCase();
    const filtered = users.filter((user) => {
      const fullName = `${user.first_name} ${user.last_name}`.toLowerCase();
      const company = (user.company_name || "").toLowerCase();
      const email = (user.email || "").toLowerCase();

      return (
        fullName.includes(searchTermLower) ||
        company.includes(searchTermLower) ||
        email.includes(searchTermLower)
      );
    });

    setFilteredUsers(filtered);
  }, [searchTerm, users]);

  // Add click outside handler
  useEffect(() => {
    const handleClickOutside = (event) => {
      if (!event.target.closest(".autocomplete-container")) {
        setIsDropdownOpen(false);
      }
    };

    document.addEventListener("mousedown", handleClickOutside);
    return () => {
      document.removeEventListener("mousedown", handleClickOutside);
    };
  }, []);

  const mutation = useMutation({
    mutationFn: existingTask
      ? (data) => updateTask(existingTask.id, data)
      : createTask,
    onSuccess: () => {
      queryClient.invalidateQueries(["tasks"]);
      onClose();
    },
  });

  const handleSubmit = (e) => {
    e.preventDefault();

    // Format the data properly
    const submitData = {
      ...formData,
      assigned_to_id: formData.assigned_to_id
        ? parseInt(formData.assigned_to_id, 10)
        : null,
      parent_task_id: formData.parent_task_id
        ? parseInt(formData.parent_task_id, 10)
        : null,
      due_date: formData.due_date
        ? new Date(formData.due_date).toISOString()
        : null,
    };

    mutation.mutate(submitData);
  };

  return (
    <form onSubmit={handleSubmit} className="bg-purple-900 p-4 rounded-lg">
      <div className="mb-4">
        <label className="block text-purple-200 mb-2">Description *</label>
        <textarea
          required
          className="w-full p-2 rounded bg-purple-800 text-white"
          value={formData.description}
          onChange={(e) =>
            setFormData({ ...formData, description: e.target.value })
          }
        />
      </div>

      <div className="autocomplete-container">
        <div className="mb-4 relative">
          <label className="block text-purple-200 mb-2">Assign To</label>
          <input
            type="text"
            className="w-full p-2 rounded bg-purple-800 text-white"
            value={searchTerm}
            onChange={(e) => {
              setSearchTerm(e.target.value);
              setIsDropdownOpen(true);
            }}
            onFocus={() => setIsDropdownOpen(true)}
            placeholder="Search for a person..."
          />
          {isDropdownOpen && filteredUsers.length > 0 && (
            <div className="absolute z-50 w-full mt-1 bg-purple-800 rounded-lg shadow-lg max-h-60 overflow-y-auto">
              {filteredUsers.map((person) => (
                <div
                  key={person.id}
                  className="p-2 hover:bg-purple-700 cursor-pointer text-white"
                  onClick={() => {
                    setFormData({
                      ...formData,
                      assigned_to_id: person.id,
                    });
                    setSearchTerm(
                      person.first_name && person.last_name
                        ? `${person.first_name} ${person.last_name}`
                        : person.company_name || person.email
                    );
                    setIsDropdownOpen(false);
                  }}
                >
                  <div>
                    {person.first_name && person.last_name
                      ? `${person.first_name} ${person.last_name}`
                      : person.company_name}
                  </div>
                  {person.email && (
                    <div className="text-sm text-purple-300">
                      {person.email}
                    </div>
                  )}
                </div>
              ))}
            </div>
          )}
        </div>
      </div>

      <div className="mb-4">
        <label className="block text-purple-200 mb-2">Due Date</label>
        <input
          type="date"
          className="w-full p-2 rounded bg-purple-800 text-white"
          value={formData.due_date}
          onChange={(e) =>
            setFormData({ ...formData, due_date: e.target.value })
          }
        />
      </div>

      <div className="mb-4">
        <label className="block text-purple-200 mb-2">Parent Task</label>
        <select
          className="w-full p-2 rounded bg-purple-800 text-white"
          value={formData.parent_task_id || ""}
          onChange={(e) =>
            setFormData({
              ...formData,
              parent_task_id: e.target.value
                ? parseInt(e.target.value, 10)
                : null,
            })
          }
        >
          <option value="">Select Parent Task</option>
          {allTasks
            .filter((task) => task.id !== existingTask?.id) // Exclude current task if editing
            .map((task) => (
              <option key={task.id} value={task.id}>
                {task.description.substring(0, 50)}
                {task.description.length > 50 ? "..." : ""}
              </option>
            ))}
        </select>
      </div>

      <div className="mb-4">
        <label className="block text-purple-200 mb-2">Status</label>
        <select
          className="w-full p-2 rounded bg-purple-800 text-white"
          value={formData.status}
          onChange={(e) => setFormData({ ...formData, status: e.target.value })}
        >
          <option value="PENDING">Pending</option>
          <option value="IN_PROGRESS">In Progress</option>
          <option value="COMPLETED">Completed</option>
          <option value="ON_HOLD">On Hold</option>
        </select>
      </div>

      <div className="mb-4">
        <label className="block text-purple-200 mb-2">Priority</label>
        <select
          className="w-full p-2 rounded bg-purple-800 text-white"
          value={formData.priority}
          onChange={(e) =>
            setFormData({ ...formData, priority: e.target.value })
          }
        >
          <option value="LOW">Low</option>
          <option value="MEDIUM">Medium</option>
          <option value="HIGH">High</option>
          <option value="URGENT">Urgent</option>
        </select>
      </div>

      <div className="flex justify-end gap-2">
        <button
          type="button"
          onClick={onClose}
          className="px-4 py-2 rounded bg-purple-700 text-purple-200 hover:bg-purple-600"
        >
          Cancel
        </button>
        <button
          type="submit"
          className="px-4 py-2 rounded bg-purple-500 text-white hover:bg-purple-400"
        >
          {existingTask ? "Update" : "Create"} Task
        </button>
      </div>
    </form>
  );
};

export default TaskForm;
