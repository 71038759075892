import React, { useState, useEffect } from "react";
import TaskActions from "./TaskActions";
import TaskCalendar from "./TaskCalendar";
import styles from "./TaskBoard.module.css";
import {
  getTasks,
  createTask,
  updateTask,
  deleteTask,
  getTaskSettings,
} from "@/queries/tasks";
import TaskDependencyView from "./TaskDependencyView";
import TaskDetails from "./TaskDetails";
import LoadingPulse from "@/components/shared/LoadingPulse";
import { usePageTitle } from "@/utils/pagetitle";
import { useQuery, useMutation } from "@tanstack/react-query";
import { useQueryClient } from "@tanstack/react-query";
import TaskForm from "./TaskForm";

export const TaskBoard = () => {
  usePageTitle("Task Board");
  const queryClient = useQueryClient();

  // State declarations
  const [showForm, setShowForm] = useState(false);
  const [editingTask, setEditingTask] = useState(null);
  const [filterStatus, setFilterStatus] = useState("ALL");
  const [filterPriority, setFilterPriority] = useState("ALL");
  const [sortBy, setSortBy] = useState("due_date");
  const [searchTerm, setSearchTerm] = useState("");
  const [groupBy, setGroupBy] = useState("none"); // 'none', 'status', 'priority', 'parent'
  const [selectedTasks, setSelectedTasks] = useState(new Set());
  const [showBulkActions, setShowBulkActions] = useState(false);
  const [viewingTaskDetails, setViewingTaskDetails] = useState(null);
  const [viewingDependencies, setViewingDependencies] = useState(null);
  const [showTaskActions, setShowTaskActions] = useState(false);
  const [showCalendar, setShowCalendar] = useState(false);

  // Fetch tasks
  const {
    data: tasks = [], // Default to empty array
    isLoading,
    error,
  } = useQuery({
    queryKey: ["tasks"],
    queryFn: getTasks,
    ...getTaskSettings,
    // Add error handling
    onError: (error) => {
      console.error("Error fetching tasks:", error);
    },
  });

  // Keyboard shortcut handler
  useEffect(() => {
    const handleKeyPress = (e) => {
      // Ignore if user is typing in an input
      if (e.target.tagName === "INPUT" || e.target.tagName === "TEXTAREA") {
        return;
      }

      switch (e.key.toLowerCase()) {
        case "n":
          handleAddNew();
          break;
        case "s":
          if (e.ctrlKey || e.metaKey) {
            e.preventDefault();
            if (showForm) {
              // Trigger form save
              document.querySelector('form button[type="submit"]')?.click();
            }
          }
          break;
        case "delete":
        case "backspace":
          if (selectedTasks.size > 0) {
            e.preventDefault();
            if (confirm(`Delete ${selectedTasks.size} selected tasks?`)) {
              bulkDeleteMutation.mutate(Array.from(selectedTasks));
            }
          }
          break;
        case "d":
          if (selectedTasks.size === 1) {
            const taskId = Array.from(selectedTasks)[0];
            const task = tasks?.find((t) => t.id === taskId);
            if (task) {
              setViewingTaskDetails(task);
            }
          }
          break;
        case "c":
          setShowCalendar((prev) => !prev);
          break;
        case "k":
          if (e.ctrlKey || e.metaKey) {
            e.preventDefault();
            document
              .querySelector('input[placeholder="Search tasks..."]')
              ?.focus();
          }
          break;
      }
    };

    window.addEventListener("keydown", handleKeyPress);
    return () => window.removeEventListener("keydown", handleKeyPress);
  }, [selectedTasks, tasks, showForm]);

  const handleSelectTask = (taskId) => {
    setSelectedTasks((prev) => {
      const newSet = new Set(prev);
      if (newSet.has(taskId)) {
        newSet.delete(taskId);
      } else {
        newSet.add(taskId);
      }
      return newSet;
    });
  };

  const handleSelectAll = () => {
    if (selectedTasks.size === tasks?.length) {
      setSelectedTasks(new Set());
    } else {
      setSelectedTasks(new Set(tasks?.map((t) => t.id)));
    }
  };

  const bulkDeleteMutation = useMutation({
    mutationFn: async (taskIds) => {
      await Promise.all(taskIds.map((id) => deleteTask(id)));
    },
    onSuccess: () => {
      queryClient.invalidateQueries(["tasks"]);
      setSelectedTasks(new Set());
    },
  });

  const bulkUpdateMutation = useMutation({
    mutationFn: async ({ taskIds, updates }) => {
      await Promise.all(taskIds.map((id) => updateTask(id, updates)));
    },
    onSuccess: () => {
      queryClient.invalidateQueries(["tasks"]);
      setSelectedTasks(new Set());
      setShowBulkActions(false);
    },
  });

  const createTaskMutation = useMutation({
    mutationFn: createTask,
    onSuccess: () => {
      queryClient.invalidateQueries(["tasks"]);
    },
  });

  const updateTaskMutation = useMutation({
    mutationFn: updateTask,
    onSuccess: () => {
      queryClient.invalidateQueries(["tasks"]);
    },
  });

  const deleteTaskMutation = useMutation({
    mutationFn: deleteTask,
    onMutate: async (taskId) => {
      // Cancel any outgoing refetches
      await queryClient.cancelQueries(["tasks"]);

      // Snapshot the previous value
      const previousTasks = queryClient.getQueryData(["tasks"]);

      return { previousTasks };
    },
    onError: (err, taskId, context) => {
      // If the mutation fails, use the context returned from onMutate to roll back
      queryClient.setQueryData(["tasks"], context.previousTasks);
      console.error("Failed to delete task:", err);
    },
    onSettled: () => {
      // Always refetch after error or success
      queryClient.invalidateQueries(["tasks"]);
    },
  });

  const handleAddNew = () => {
    setEditingTask(null);
    setShowForm(true);
  };

  const handleEdit = (task) => {
    setEditingTask(task);
    setShowForm(true);
  };

  const handleCloseForm = () => {
    setShowForm(false);
    setEditingTask(null);
  };

  const getTaskStatistics = () => {
    if (!tasks || tasks.length === 0) {
      return {
        total: 0,
        byStatus: {
          PENDING: 0,
          IN_PROGRESS: 0,
          COMPLETED: 0,
          ON_HOLD: 0,
        },
        byPriority: {
          URGENT: 0,
          HIGH: 0,
          MEDIUM: 0,
          LOW: 0,
        },
        dueSoon: 0,
        overdue: 0,
      };
    }

    return {
      total: tasks.length,
      byStatus: {
        PENDING: tasks.filter((t) => t.status === "PENDING").length,
        IN_PROGRESS: tasks.filter((t) => t.status === "IN_PROGRESS").length,
        COMPLETED: tasks.filter((t) => t.status === "COMPLETED").length,
        ON_HOLD: tasks.filter((t) => t.status === "ON_HOLD").length,
      },
      byPriority: {
        URGENT: tasks.filter((t) => t.priority === "URGENT").length,
        HIGH: tasks.filter((t) => t.priority === "HIGH").length,
        MEDIUM: tasks.filter((t) => t.priority === "MEDIUM").length,
        LOW: tasks.filter((t) => t.priority === "LOW").length,
      },
      dueSoon: tasks.filter((t) => {
        if (!t.due_date) return false;
        const dueDate = new Date(t.due_date);
        const today = new Date();
        const diffDays = Math.ceil((dueDate - today) / (1000 * 60 * 60 * 24));
        return diffDays <= 7 && diffDays >= 0;
      }).length,
      overdue: tasks.filter((t) => {
        if (!t.due_date) return false;
        return new Date(t.due_date) < new Date() && t.status !== "COMPLETED";
      }).length,
    };
  };

  const getFilteredAndSortedTasks = () => {
    if (!tasks) return groupBy === "none" ? [] : {};

    let filteredTasks = [...tasks];

    // Apply search
    if (searchTerm) {
      const searchLower = searchTerm.toLowerCase();
      filteredTasks = filteredTasks.filter(
        (task) =>
          task.description.toLowerCase().includes(searchLower) ||
          (task.assigned_to_name &&
            task.assigned_to_name.toLowerCase().includes(searchLower))
      );
    }

    // Apply filters
    if (filterStatus !== "ALL") {
      filteredTasks = filteredTasks.filter(
        (task) => task.status === filterStatus
      );
    }

    if (filterPriority !== "ALL") {
      filteredTasks = filteredTasks.filter(
        (task) => task.priority === filterPriority
      );
    }

    // Apply sorting
    const priorityOrder = { URGENT: 0, HIGH: 1, MEDIUM: 2, LOW: 3 };
    const statusOrder = {
      IN_PROGRESS: 0,
      PENDING: 1,
      ON_HOLD: 2,
      COMPLETED: 3,
    };

    filteredTasks.sort((a, b) => {
      switch (sortBy) {
        case "priority":
          return priorityOrder[a.priority] - priorityOrder[b.priority];
        case "status":
          return statusOrder[a.status] - statusOrder[b.status];
        case "due_date":
          if (!a.due_date) return 1;
          if (!b.due_date) return -1;
          return new Date(a.due_date) - new Date(b.due_date);
        case "created":
          return new Date(b.created_at) - new Date(a.created_at);
        default:
          return 0;
      }
    });

    // Apply grouping
    if (groupBy === "none") {
      return filteredTasks;
    }

    // Group tasks
    const groupedTasks = {};
    filteredTasks.forEach((task) => {
      let groupKey;
      switch (groupBy) {
        case "status":
          groupKey = task.status;
          break;
        case "priority":
          groupKey = task.priority;
          break;
        case "parent":
          groupKey = task.parent_task_id || "No Parent";
          break;
        default:
          groupKey = "Other";
      }
      if (!groupedTasks[groupKey]) {
        groupedTasks[groupKey] = [];
      }
      groupedTasks[groupKey].push(task);
    });

    return groupedTasks;
  };

  if (isLoading) return <LoadingPulse />;
  if (error) return <div>Error loading tasks.</div>;

  return (
    <div className={styles.taskBoard}>
      <div className={styles.header}>
        <p className={styles.headerText}>Task Board</p>
        <div className="ml-4 text-purple-300 text-sm">
          <span className="mr-2">Shortcuts:</span>
          <kbd className="px-1 py-0.5 bg-purple-700 rounded">n</kbd>
          <span className="mx-1">new</span>
          <kbd className="px-1 py-0.5 bg-purple-700 rounded">d</kbd>
          <span className="mx-1">details</span>
          <kbd className="px-1 py-0.5 bg-purple-700 rounded">c</kbd>
          <span className="mx-1">calendar</span>
          <kbd className="px-1 py-0.5 bg-purple-700 rounded">⌘K</kbd>
          <span className="mx-1">search</span>
        </div>
        <div className="ml-auto flex gap-2">
          <button
            onClick={handleAddNew}
            className="px-4 py-1 rounded bg-purple-600 text-white hover:bg-purple-500"
          >
            Add Task
          </button>
          <button
            onClick={() => setShowCalendar(true)}
            className="px-4 py-1 rounded bg-purple-600 text-white hover:bg-purple-500"
          >
            Calendar
          </button>
        </div>
      </div>

      <div className="bg-purple-900 p-4 border-b border-purple-700">
        {(() => {
          const stats = getTaskStatistics();
          if (!stats) return null;

          return (
            <div className="grid grid-cols-1 md:grid-cols-2 lg:grid-cols-4 gap-4">
              <div className="bg-purple-800 rounded-lg p-3">
                <h4 className="text-purple-200 text-sm font-medium">
                  Task Overview
                </h4>
                <div className="mt-2 grid grid-cols-2 gap-2">
                  <div>
                    <p className="text-white text-2xl font-bold">
                      {stats.total}
                    </p>
                    <p className="text-purple-300 text-sm">Total Tasks</p>
                  </div>
                  <div>
                    <p className="text-white text-2xl font-bold">
                      {stats.dueSoon}
                    </p>
                    <p className="text-purple-300 text-sm">Due Soon</p>
                  </div>
                </div>
              </div>

              <div className="bg-purple-800 rounded-lg p-3">
                <h4 className="text-purple-200 text-sm font-medium">
                  Status Breakdown
                </h4>
                <div className="mt-2 grid grid-cols-2 gap-2">
                  <div>
                    <p className="text-blue-400 text-lg font-bold">
                      {stats.byStatus.IN_PROGRESS}
                    </p>
                    <p className="text-purple-300 text-sm">In Progress</p>
                  </div>
                  <div>
                    <p className="text-yellow-400 text-lg font-bold">
                      {stats.byStatus.PENDING}
                    </p>
                    <p className="text-purple-300 text-sm">Pending</p>
                  </div>
                  <div>
                    <p className="text-green-400 text-lg font-bold">
                      {stats.byStatus.COMPLETED}
                    </p>
                    <p className="text-purple-300 text-sm">Completed</p>
                  </div>
                  <div>
                    <p className="text-orange-400 text-lg font-bold">
                      {stats.byStatus.ON_HOLD}
                    </p>
                    <p className="text-purple-300 text-sm">On Hold</p>
                  </div>
                </div>
              </div>

              <div className="bg-purple-800 rounded-lg p-3">
                <h4 className="text-purple-200 text-sm font-medium">
                  Priority Distribution
                </h4>
                <div className="mt-2 grid grid-cols-2 gap-2">
                  <div>
                    <p className="text-red-400 text-lg font-bold">
                      {stats.byPriority.URGENT}
                    </p>
                    <p className="text-purple-300 text-sm">Urgent</p>
                  </div>
                  <div>
                    <p className="text-orange-400 text-lg font-bold">
                      {stats.byPriority.HIGH}
                    </p>
                    <p className="text-purple-300 text-sm">High</p>
                  </div>
                  <div>
                    <p className="text-yellow-400 text-lg font-bold">
                      {stats.byPriority.MEDIUM}
                    </p>
                    <p className="text-purple-300 text-sm">Medium</p>
                  </div>
                  <div>
                    <p className="text-green-400 text-lg font-bold">
                      {stats.byPriority.LOW}
                    </p>
                    <p className="text-purple-300 text-sm">Low</p>
                  </div>
                </div>
              </div>

              <div className="bg-purple-800 rounded-lg p-3">
                <h4 className="text-purple-200 text-sm font-medium">
                  Attention Needed
                </h4>
                <div className="mt-2 grid grid-cols-1 gap-2">
                  <div>
                    <p className="text-red-400 text-2xl font-bold">
                      {stats.overdue}
                    </p>
                    <p className="text-purple-300 text-sm">Overdue Tasks</p>
                  </div>
                  <div className="mt-2">
                    <div className="h-2 bg-purple-700 rounded-full overflow-hidden">
                      <div
                        className="h-full bg-green-500"
                        style={{
                          width: `${
                            (stats.byStatus.COMPLETED / stats.total) * 100
                          }%`,
                        }}
                      />
                    </div>
                    <p className="text-purple-300 text-sm mt-1">
                      {Math.round(
                        (stats.byStatus.COMPLETED / stats.total) * 100
                      )}
                      % Complete
                    </p>
                  </div>
                </div>
              </div>
            </div>
          );
        })()}
      </div>
      <div className="bg-purple-800 p-4 flex gap-4 items-center flex-wrap">
        <div>
          <label className="text-purple-200 mr-2">Status:</label>
          <select
            className="bg-purple-700 text-white p-1 rounded"
            value={filterStatus}
            onChange={(e) => setFilterStatus(e.target.value)}
          >
            <option value="ALL">All</option>
            <option value="PENDING">Pending</option>
            <option value="IN_PROGRESS">In Progress</option>
            <option value="COMPLETED">Completed</option>
            <option value="ON_HOLD">On Hold</option>
          </select>
        </div>

        <div>
          <label className="text-purple-200 mr-2">Priority:</label>
          <select
            className="bg-purple-700 text-white p-1 rounded"
            value={filterPriority}
            onChange={(e) => setFilterPriority(e.target.value)}
          >
            <option value="ALL">All</option>
            <option value="URGENT">Urgent</option>
            <option value="HIGH">High</option>
            <option value="MEDIUM">Medium</option>
            <option value="LOW">Low</option>
          </select>
        </div>

        <div>
          <label className="text-purple-200 mr-2">Sort by:</label>
          <select
            className="bg-purple-700 text-white p-1 rounded"
            value={sortBy}
            onChange={(e) => setSortBy(e.target.value)}
          >
            <option value="due_date">Due Date</option>
            <option value="priority">Priority</option>
            <option value="status">Status</option>
            <option value="created">Created Date</option>
          </select>
        </div>

        <div className="flex-grow">
          <input
            type="text"
            placeholder="Search tasks..."
            className="w-full p-2 rounded bg-purple-700 text-white placeholder-purple-300"
            value={searchTerm}
            onChange={(e) => setSearchTerm(e.target.value)}
          />
        </div>

        <div>
          <label className="text-purple-200 mr-2">Group by:</label>
          <select
            className="bg-purple-700 text-white p-1 rounded"
            value={groupBy}
            onChange={(e) => setGroupBy(e.target.value)}
          >
            <option value="none">No Grouping</option>
            <option value="status">Status</option>
            <option value="priority">Priority</option>
            <option value="parent">Parent Task</option>
          </select>
        </div>
      </div>

      {selectedTasks.size > 0 && (
        <div className="bg-purple-700 p-4 border-t border-purple-600">
          <div className="flex items-center gap-4">
            <span className="text-purple-200">
              {selectedTasks.size} task{selectedTasks.size !== 1 ? "s" : ""}{" "}
              selected
            </span>

            <div className="flex gap-2">
              <select
                className="bg-purple-800 text-white p-2 rounded"
                onChange={(e) => {
                  const action = e.target.value;
                  if (!action) return;

                  switch (action) {
                    case "status":
                      setShowBulkActions("status");
                      break;
                    case "priority":
                      setShowBulkActions("priority");
                      break;
                    case "delete":
                      if (confirm(`Delete ${selectedTasks.size} tasks?`)) {
                        bulkDeleteMutation.mutate(Array.from(selectedTasks));
                      }
                      break;
                  }
                  e.target.value = "";
                }}
              >
                <option value="">Bulk Actions...</option>
                <option value="status">Update Status</option>
                <option value="priority">Update Priority</option>
                <option value="delete">Delete Selected</option>
              </select>

              {showBulkActions === "status" && (
                <select
                  className="bg-purple-800 text-white p-2 rounded"
                  onChange={(e) => {
                    const status = e.target.value;
                    if (status) {
                      bulkUpdateMutation.mutate({
                        taskIds: Array.from(selectedTasks),
                        updates: { status },
                      });
                    }
                  }}
                >
                  <option value="">Select Status...</option>
                  <option value="PENDING">Pending</option>
                  <option value="IN_PROGRESS">In Progress</option>
                  <option value="COMPLETED">Completed</option>
                  <option value="ON_HOLD">On Hold</option>
                </select>
              )}

              {showBulkActions === "priority" && (
                <select
                  className="bg-purple-800 text-white p-2 rounded"
                  onChange={(e) => {
                    const priority = e.target.value;
                    if (priority) {
                      bulkUpdateMutation.mutate({
                        taskIds: Array.from(selectedTasks),
                        updates: { priority },
                      });
                    }
                  }}
                >
                  <option value="">Select Priority...</option>
                  <option value="LOW">Low</option>
                  <option value="MEDIUM">Medium</option>
                  <option value="HIGH">High</option>
                  <option value="URGENT">Urgent</option>
                </select>
              )}
            </div>
          </div>
        </div>
      )}

      <div className={styles.taskContainer}>
        {groupBy === "none"
          ? getFilteredAndSortedTasks().map((task) => (
              <div key={task.id} className={styles.taskItem}>
                <div className="flex items-center justify-between">
                  <div className="flex items-center flex-grow">
                    <input
                      type="checkbox"
                      className="mr-4 h-4 w-4 rounded border-purple-300 text-purple-600 focus:ring-purple-500"
                      checked={selectedTasks.has(task.id)}
                      onChange={() => handleSelectTask(task.id)}
                    />
                    <div className="flex items-center gap-2">
                      <p className="text-white font-medium">
                        {task.description}
                      </p>
                      <span
                        className={`px-2 py-0.5 rounded text-xs ${
                          task.priority === "URGENT"
                            ? "bg-red-500"
                            : task.priority === "HIGH"
                            ? "bg-orange-500"
                            : task.priority === "MEDIUM"
                            ? "bg-yellow-500"
                            : "bg-green-500"
                        }`}
                      >
                        {task.priority}
                      </span>
                      <span
                        className={`px-2 py-0.5 rounded text-xs ${
                          task.status === "COMPLETED"
                            ? "bg-green-600"
                            : task.status === "IN_PROGRESS"
                            ? "bg-blue-600"
                            : task.status === "ON_HOLD"
                            ? "bg-orange-600"
                            : "bg-gray-600"
                        }`}
                      >
                        {task.status}
                      </span>
                    </div>
                    <div className="text-purple-200 text-sm">
                      {task.assigned_to_name && (
                        <span>Assigned to: {task.assigned_to_name}</span>
                      )}
                      {task.due_date && (
                        <span className="ml-4">
                          Due: {new Date(task.due_date).toLocaleDateString()}
                        </span>
                      )}
                      {task.parent_task_id && (
                        <span className="ml-4">
                          Parent Task: {task.parent_task_id}
                        </span>
                      )}
                    </div>
                  </div>
                  <div className="flex items-center">
                    <button
                      onClick={() => setViewingTaskDetails(task)}
                      className="px-3 py-1 text-purple-200 hover:text-white mr-2"
                    >
                      Details
                    </button>
                    <button
                      onClick={() => setViewingDependencies(task)}
                      className="px-3 py-1 text-purple-200 hover:text-white mr-2"
                    >
                      Dependencies
                    </button>
                    <button
                      onClick={() => handleEdit(task)}
                      className="px-3 py-1 text-purple-200 hover:text-white"
                    >
                      Edit
                    </button>
                    <button
                      onClick={() => {
                        if (
                          window.confirm(
                            "Are you sure you want to delete this task?"
                          )
                        ) {
                          deleteTaskMutation.mutate(task.id);
                        }
                      }}
                      className={styles.deleteButton}
                    >
                      ×
                    </button>
                  </div>
                </div>
              </div>
            ))
          : Object.entries(getFilteredAndSortedTasks()).map(
              ([group, tasks]) => (
                <div key={group} className="mb-6">
                  <h3 className="text-purple-200 font-semibold mb-3 text-lg">
                    {group}
                  </h3>
                  <div className="space-y-4">
                    {tasks.map((task) => (
                      <div key={task.id} className={styles.taskItem}>
                        <div className="flex items-center justify-between">
                          <div className="flex-grow">
                            <div className="flex items-center gap-2">
                              <p className="text-white font-medium">
                                {task.description}
                              </p>
                              <span
                                className={`px-2 py-0.5 rounded text-xs ${
                                  task.priority === "URGENT"
                                    ? "bg-red-500"
                                    : task.priority === "HIGH"
                                    ? "bg-orange-500"
                                    : task.priority === "MEDIUM"
                                    ? "bg-yellow-500"
                                    : "bg-green-500"
                                }`}
                              >
                                {task.priority}
                              </span>
                              <span
                                className={`px-2 py-0.5 rounded text-xs ${
                                  task.status === "COMPLETED"
                                    ? "bg-green-600"
                                    : task.status === "IN_PROGRESS"
                                    ? "bg-blue-600"
                                    : task.status === "ON_HOLD"
                                    ? "bg-orange-600"
                                    : "bg-gray-600"
                                }`}
                              >
                                {task.status}
                              </span>
                            </div>
                            <div className="text-purple-200 text-sm">
                              {task.assigned_to_name && (
                                <span>
                                  Assigned to: {task.assigned_to_name}
                                </span>
                              )}
                              {task.due_date && (
                                <span className="ml-4">
                                  Due:{" "}
                                  {new Date(task.due_date).toLocaleDateString()}
                                </span>
                              )}
                              {task.parent_task_id && (
                                <span className="ml-4">
                                  Parent Task: {task.parent_task_id}
                                </span>
                              )}
                            </div>
                          </div>
                          <div className="flex items-center">
                            <button
                              onClick={() => handleEdit(task)}
                              className="px-3 py-1 text-purple-200 hover:text-white"
                            >
                              Edit
                            </button>
                            <button
                              onClick={() => {
                                if (
                                  window.confirm(
                                    "Are you sure you want to delete this task?"
                                  )
                                ) {
                                  deleteTaskMutation.mutate(task.id);
                                }
                              }}
                              className={styles.deleteButton}
                            >
                              ×
                            </button>
                          </div>
                        </div>
                      </div>
                    ))}
                  </div>
                </div>
              )
            )}
      </div>

      {showForm && (
        <div className="fixed inset-0 bg-black bg-opacity-50 flex items-center justify-center z-50">
          <div className="w-full max-w-md m-4">
            <TaskForm existingTask={editingTask} onClose={handleCloseForm} />
          </div>
        </div>
      )}

      {viewingTaskDetails && (
        <div className="fixed inset-0 bg-black bg-opacity-50 flex items-center justify-center z-50">
          <div className="w-full max-w-4xl m-4">
            <TaskDetails
              task={viewingTaskDetails}
              onClose={() => setViewingTaskDetails(null)}
            />
          </div>
        </div>
      )}

      {viewingDependencies && (
        <div className="fixed inset-0 bg-black bg-opacity-50 flex items-center justify-center z-50">
          <TaskDependencyView
            task={viewingDependencies}
            onClose={() => setViewingDependencies(null)}
          />
        </div>
      )}

      {showTaskActions && (
        <div className="fixed inset-0 bg-black bg-opacity-50 flex items-center justify-center z-50">
          <div className="w-full max-w-4xl m-4">
            <TaskActions onClose={() => setShowTaskActions(false)} />
          </div>
        </div>
      )}

      {showCalendar && (
        <div className="fixed inset-0 bg-black bg-opacity-50 flex items-center justify-center z-50">
          <TaskCalendar
            onClose={() => setShowCalendar(false)}
            onTaskClick={(task) => {
              setViewingTaskDetails(task);
              setShowCalendar(false);
            }}
          />
        </div>
      )}
    </div>
  );
};

export default TaskBoard;
