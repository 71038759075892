export class RequestError extends Error {
  constructor(message, statusCode, data) {
    super(message);
    this.name = "RequestError";
    this.statusCode = statusCode;
    this.data = data;
  }
}

export const getCSRFToken = async () => {
  const resp = await fetch("/api/csrf/", {
    method: "GET",
    credentials: "same-origin",
    headers: {
      Accept: "application/json",
      "Content-Type": "application/json",
    },
  });
  const data = await resp.json();
  return data.token;
};

export const jsonHeaders = async (includeCsrf) => {
  const headers = new Headers();

  headers.append("Accept", "application/json");
  headers.append("Content-Type", "application/json");

  if (includeCsrf === true) {
    headers.append("X-CSRFToken", await getCSRFToken());
  }

  return headers;
};

export const makeRequest = async (url, method, headers, body) => {
  headers = headers || new Headers();
  body = body || null;

  try {
    const resp = await fetch(url, {
      method: method,
      credentials: "same-origin",
      headers: headers,
      body: body,
    });

    if (!resp.ok) {
      let error = new RequestError(
        resp.statusText,
        resp.status,
        await resp.json()
      );
      throw error;
    }
    return resp;
  } catch (error) {
    console.error("Error making request:", error);
    throw error;
  }
};

export const getApiRequest = async (url, options = {}) => {
  const headers = await jsonHeaders();
  // Add no-cache headers
  headers.append("Cache-Control", "no-cache, no-store, must-revalidate");
  headers.append("Pragma", "no-cache");
  headers.append("Expires", "0");

  // Merge any additional headers
  if (options && options.headers) {
    Object.keys(options.headers).forEach((key) => {
      headers.append(key, options.headers[key]);
    });
  }

  // Add timestamp to prevent caching
  const urlWithTimestamp = url.includes("?")
    ? `${url}&_=${Date.now()}`
    : `${url}?_=${Date.now()}`;

  const resp = await makeRequest(urlWithTimestamp, "GET", headers);
  return resp.json();
};

export const postApiRequest = async (url, data, additionalHeaders = {}) => {
  const headers = await jsonHeaders(true);

  // Add no-cache headers
  headers.append("Cache-Control", "no-cache, no-store, must-revalidate");
  headers.append("Pragma", "no-cache");
  headers.append("Expires", "0");

  // Add any additional headers
  Object.keys(additionalHeaders).forEach((key) => {
    headers.append(key, additionalHeaders[key]);
  });

  // Add timestamp to prevent caching
  const urlWithTimestamp = url.includes("?")
    ? `${url}&_=${Date.now()}`
    : `${url}?_=${Date.now()}`;

  const resp = await makeRequest(
    urlWithTimestamp,
    "POST",
    headers,
    JSON.stringify(data)
  );
  return resp.json();
};

export const putApiRequest = async (url, data, additionalHeaders = {}) => {
  const headers = await jsonHeaders(true);

  // Add no-cache headers
  headers.append("Cache-Control", "no-cache, no-store, must-revalidate");
  headers.append("Pragma", "no-cache");
  headers.append("Expires", "0");

  // Add any additional headers
  Object.keys(additionalHeaders).forEach((key) => {
    headers.append(key, additionalHeaders[key]);
  });

  // Add timestamp to prevent caching
  const urlWithTimestamp = url.includes("?")
    ? `${url}&_=${Date.now()}`
    : `${url}?_=${Date.now()}`;

  const resp = await makeRequest(
    urlWithTimestamp,
    "PUT",
    headers,
    JSON.stringify(data)
  );
  return resp.json();
};

export const deleteApiRequest = async (url, additionalHeaders = {}) => {
  const headers = await jsonHeaders(true);

  // Add no-cache headers
  headers.append("Cache-Control", "no-cache, no-store, must-revalidate");
  headers.append("Pragma", "no-cache");
  headers.append("Expires", "0");

  // Add any additional headers
  Object.keys(additionalHeaders).forEach((key) => {
    headers.append(key, additionalHeaders[key]);
  });

  // Add timestamp to prevent caching
  const urlWithTimestamp = url.includes("?")
    ? `${url}&_=${Date.now()}`
    : `${url}?_=${Date.now()}`;

  const resp = await makeRequest(urlWithTimestamp, "DELETE", headers);
  if (resp.status != 204) {
    return resp.json();
  }
  return {};
};

export const patchApiRequest = async (url, data, additionalHeaders = {}) => {
  const headers = await jsonHeaders(true);

  // Add no-cache headers
  headers.append("Cache-Control", "no-cache, no-store, must-revalidate");
  headers.append("Pragma", "no-cache");
  headers.append("Expires", "0");

  // Add any additional headers
  Object.keys(additionalHeaders).forEach((key) => {
    headers.append(key, additionalHeaders[key]);
  });

  // Add timestamp to prevent caching
  const urlWithTimestamp = url.includes("?")
    ? `${url}&_=${Date.now()}`
    : `${url}?_=${Date.now()}`;

  const resp = await makeRequest(
    urlWithTimestamp,
    "PATCH",
    headers,
    JSON.stringify(data)
  );
  return resp.json();
};

export const getSystemMessage = async () => {
  try {
    console.log("Fetching system message...");
    const response = await getApiRequest("/api/people/system-message/");
    console.log("System message response:", response);
    if (!response) {
      console.warn("No system message response received");
      return { active: false, message: "" };
    }
    return response;
  } catch (error) {
    console.error("Error fetching system message:", error);
    // Re-throw the error so React Query can handle it
    throw error;
  }
};
